import React from 'react';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { menuOptions } from './menuOptions';
import StyledLink from '../StyledLink';
import { wipeReduxState } from '../../../reducers/auth';
// import PaymentBannerSpacer from '../PaymentBannerSpacer';

const SecondaryPanel = props => {
  const flags = useFlags();

  return (
    <div
      className={`secondary-panel rem-pad-width`}
      style={{
        top: '48px',
        paddingBottom: '100px',
        ...props.style
      }}
    >
      {props.overhead_title ? <div className="overhead_title">{props.overhead_title}</div> : null}
      {props.title.map(title => {
        const menuOptionsVar = menuOptions({ ...props, flags })[title];
        return (
          <div
            className={`'multiNavHeader_div' ${props.title.length == 1 ? 'm-top-30' : ''}`}
            key={Math.random() + props.current}
          >
            {props.title.length > 1 && menuOptionsVar.length > 0 ? <p className="multiNavHeader_p">{title}</p> : null}
            <ul className="secondary-menu-short hide-scrollbar">
              {menuOptionsVar.map((opt, i) => {
                let linkClass = 'expanded-nav-item';
                let liClass = ' ';
                if (props.current === opt.title) {
                  linkClass += ' SPlink_Active';
                  liClass += 'SPlink_Active';
                }
                if (opt.link) {
                  return (
                    <div className="vertNavDiv" key={opt.link + Math.random()}>
                      <li key={i}>
                        <div className="vertNavInnerDiv">
                          <StyledLink
                            classprops={linkClass}
                            id={opt.title.split(' ').join('')}
                            styleprops={{
                              height: '25px',
                              display: 'inline-block',
                              width: '150px',
                              paddingLeft: '15px',
                              paddingTop: '5px',
                              marginLeft: '-17px',
                              fontSize: '12px',
                            }}
                            to={opt.link}
                          >
                            {opt.title}
                          </StyledLink>
                        </div>
                      </li>
                    </div>
                  );
                }
                return (
                  <div key={i} className="vertNavDiv pointer" style={{ marginLeft: '22px' }}>
                    <li key={i} className={`${liClass} 'pos-abs'`}>
                      <div className="vertNavInnerDiv" style={{ marginLeft: '10px', height: '35px' }}>
                        <p
                          className={linkClass}
                          style={{
                            height: '27px',
                            display: 'inline-block',
                            width: '131px',
                          }}
                          onClick={() => {
                            menuOptionsVar[1].onClick();
                          }}
                        >
                          {opt.title}
                        </p>
                      </div>
                    </li>
                  </div>
                );
              })}
            </ul>
            {title !== 'PROFILE' && props.title.length > 1 ? (
              <hr className="m-left-21 w-160 m-btm-15 m-top-20" style={{ border: '1px solid #d1d2d7' }} />
            ) : null}
          </div>
        );
      })}
      {/* </ul> */}
    </div>
  );
};

const mapStateToProps = state => ({ ...state.auth, paymentBannerActive: state.paymentBanner.active });
const mapDispatchToProps = dispatch => ({
  logout() {
    dispatch({
      type: 'LOGOUT',
      payload: true,
    });
    wipeReduxState();
    localStorage.setItem('account', '');
    localStorage.setItem('jwtToken', '');
    localStorage.setItem('newNav', '');
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SecondaryPanel);
