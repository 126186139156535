
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { postRequest } from '../../core/apiRequests';

export const LayoutsContext = createContext({});

const LayoutsContextProvider = ({ children, auth, inboxList }) => {

  const [newMessagesId, setNewMessagesId] = useState([]);
  const hasNewMessages = useMemo(() => newMessagesId.length > 0, [newMessagesId]);

  const checkNewMessages = useCallback(() => {
    if(!auth.merchantId) return;
    const reqObj = {
      params: ['chats', 'getMyInboxByStore'],
      data: {
        page: 1,
        limit: 10,
        storeId: auth.selectedStore,
        licenseKey: auth.licenseKey,
        unread: true,
      },
      loadingStatusOff: true,
    };
    try {
      postRequest(reqObj).then((res) => {
        if (res.data && res.data.length > 0) {
          const newMessages = res.data.filter((message) => message.isUnread);
          setNewMessagesId(newMessages.map((message) => message.client.id));
        } else {
          setNewMessagesId([]);
        }
      })
    } catch (error) {
      console.error('Error while fetching new messages', error);
    }
  }, [auth]);

  const addNewMessageId = useCallback((id) => {
    setNewMessagesId([...newMessagesId, id]);
  }, [newMessagesId]);

  useEffect(() => {
    if (inboxList && inboxList.length > 0) {
      const newMessages = inboxList.filter((message) => message.isUnread);
      setNewMessagesId(newMessages.map((message) => message.client.id));
    } else {
      checkNewMessages();
    }
  }, [inboxList, auth.merchantId])

  return (
    <LayoutsContext.Provider
      value={{
        hasNewMessages,
        addNewMessageId
      }}
    >
      {children}
    </LayoutsContext.Provider>
  );
};

export function useLayout() {
  const context = useContext(LayoutsContext);

  if (!context) {
    throw new Error('useLayoutsContext must be used within an LayoutsContext');
  }
  return context;
}

const mapStateToProps = state => ({
  auth: state.auth,
  inboxList: state.messaging.inboxList,
});
export default connect(mapStateToProps)(LayoutsContextProvider);

