import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StandardLayout from '../../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../../Layout/SecondaryPanel';

import AutoMessageSettings from '../../../../../Layout/AutoMessages/AutoMessageSettings';

const CustomAutoMessage = props => {
  const history = useHistory();

  const setRedirectBack = () => {
    history.push(`/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}`);
  };

  return (
    <StandardLayout
      title={`${props.autoMessages.autoMessageAddOrEdit} Auto Message`}
      description="Write a message to send automatically."
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Client Activity', to: '/Configuration/client-activity' },
        {
          title: 'Edit Client Activity',
          to: `/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}`,
        },
        { title: `${props.autoMessages.autoMessageAddOrEdit} Auto Message` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <AutoMessageSettings
        setRedirectBack={setRedirectBack}
        cancelRoute={`/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}`}
        popConfirmDesc={'This will remove the auto message from this Client Activity'}
        type="clientEvent" //// REMEMBER THIS
        match={props.match}
        editRoute={`/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}`}
        selectAutoMessageTime="After creating a client activity, how soon should the message send?"
      />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({ autoMessages: state.autoMessages });
export default connect(mapStateToProps, {})(CustomAutoMessage);
