import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { menuOptions } from '../../Layout/SecondaryPanel/menuOptions.js';
import StyledLink from '../../Layout/StyledLink';
import { wipeReduxState } from '../../../reducers/auth.js';

const MobileMultiNav = props => {
  const [showListMobile, setShowListMobile] = useState('');
  const [mobileTitles] = useState(
    Object.keys(menuOptions(props)).filter(title => {
      return title == 'SETTINGS' || title == 'HELP' || title == 'PROFILE';
    }),
  );
  const order = { SETTINGS: 1, HELP: 2, PROFILE: 3 };
  const [mobileData] = useState(
    Object.entries(menuOptions(props))
      .filter(title => {
        return title[0] == 'SETTINGS' || title[0] == 'HELP' || title[0] == 'PROFILE';
      })
      .sort(function (a, b) {
        return order[a[0]] - order[b[0]];
      }),
  );

  return (
    <div>
      <div className={`${props.paymentBannerActive ? 'multiNavOutterDivBanner' : 'multiNavOutterDiv'}`}>
        <FontAwesomeIcon
          className={`pointer ${
            props.paymentBannerActive ? 'multi-mobile-side-pane-x-banner' : 'multi-mobile-side-pane-x'
          }`}
          onClick={() => props.setShowMenu(!props.showMenu)}
          icon={['far', 'times']}
          color="white"
          size="sm"
        />
        {mobileData.map((key, i) => {
          const DropData = key[1];
          return (
            <div className="multiNavHeader_div" key={i}>
              <p className="expandHeaderMobile">{key[0]}</p>
              <ul className="secondary-menu-short hide-scrollbar p-left-6">
                {DropData.map((opt, i) => {
                  const linkClass = 'expanded-nav-item';
                  if (opt.link) {
                    return (
                      <div className="vertNavDivMobile" key={i}>
                        <li key={i} className=" subMenuHover pos-abs align-left">
                          {/* <div className='vertNavInnerDiv'> */}
                          <StyledLink
                            //   classprops={linkClass}
                            styleprops={{
                              height: '25px',
                              display: 'inline-block',
                              width: '150px',
                              paddingLeft: '20px',
                              paddingTop: '5px',
                              marginLeft: '0px',
                              fontWeight: '500',
                              fontSize: '12px',
                              color: '#45484b',
                            }}
                            to={opt.link}
                          >
                            {opt.title}
                          </StyledLink>
                          {/* </div> */}
                        </li>
                      </div>
                    );
                  }
                  return (
                    <div className="vertNavDivMobile pointer" style={{ marginTop: '-8px' }} key={i}>
                      <li key={i} className=" subMenuHover pos-abs align-left">
                        <div style={{ marginLeft: '20px', height: '35px' }}>
                          <p
                            style={{
                              height: '25px',
                              display: 'inline-block',
                              width: '150px',
                              fontWeight: '500',
                              fontSize: '12px',
                              color: '#45484b',
                              lineHeight: '6px',
                            }}
                            onClick={() => {
                              DropData[1].onClick();
                            }}
                          >
                            {opt.title}
                          </p>
                        </div>
                      </li>
                    </div>
                  );
                })}
              </ul>
              {key[0] !== 'PROFILE' ? (
                <hr className="m-left-21 w-160 m-btm-15 m-top-20" style={{ border: '1px solid #d1d2d7' }} />
              ) : null}
            </div>
          );
        })}
      </div>
      <div className="mobile-side-panel-bg-closer-multi" onClick={() => props.setShowMenu(!props.showMenu)} />
    </div>
  );
};
const mapStateToProps = state => ({
  ...state.navigation,
  ...state.auth,
  paymentBannerActive: state.paymentBanner.active,
});
const mapDispatchToProps = dispatch => ({
  logout() {
    dispatch({
      type: 'LOGOUT',
      payload: true,
    });
    wipeReduxState();
    localStorage.setItem('account', '');
    localStorage.setItem('jwtToken', '');
    localStorage.setItem('newNav', '');
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMultiNav);
