import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Segmented, Tabs } from 'antd';
import yn from 'yn';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SearchAndFilter from './Helpers/SearchAndFilter';
import InboxChatList from './Helpers/InboxChatList';
import AssignChatPopup from './Helpers/AssignChatPopup';
import { getRequest, deleteRequest, postRequest } from '../../../../../../core/apiRequests';
import { eventSubject } from '../../../../../../core/events';
import {
  setFilterAssoc,
  setFilterStore,
  setInboxList,
  setMsgAllAsctsList,
  setMsgResetClient,
  setStoreInboxList,
  updateConversationLists,
  setMsgSearchText,
  setInboxType,
  setShowSearchResults,
  setClosedInboxList,
  newObjCreator,
} from '../../../../../../reducers/messaging';
import { setNewMessages } from '../../../../../../reducers/navigation';

const DEFAULT_LIMIT = 25;

const ChatListBar = props => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [storePage, setStorePage] = useState(1);
  const [ClosedPage, setClosedPage] = useState(1);
  const [isThereMoreInbox, setIsThereMoreInbox] = useState(true);
  const [isThereMoreStore, setIsThereMoreStore] = useState(true);
  const [isThereMoreClosed, setIsThereMoreClosed] = useState(true);
  const [assignPopup, setAssignPopup] = useState(false);
  const [hoverAssignId, setHoverAssignId] = useState(0);
  const [followers, setFollowers] = useState([]);
  const [clientOnAssignPopup, setClientOnAssignPopup] = useState({});
  const [unreadMsg, setUnreadMsg] = useState(false);
  const [typeFilter, setTypeFilter] = useState(null);
  const [showStoreFilter, setShowStoreFilter] = useState(false);
  const [zeroResults, setZeroResults] = useState(false);
  const [mobileInvalid, setMobileInvalid] = useState(false);
  const [unreadMsgMatching, setUnreadMsgMatching] = useState({});
  const [inboxMenu, setInboxMenu] = useState([]);

  const flags = useFlags();

  const limitAssociateVisibility =
    yn(props?.auth?.merchantConfig?.LIMIT_ASSOCIATE_VISIBILITY_ENABLED) && props.auth.role === 'ASSOCIATE';

  const { chatMoreRefresh, setChatMoreRefresh } = props;

  const isMounted = useRef(null);
  const { pathname } = window.location;

  const { inboxList, storeInboxList, closedInboxList, filterAssoc, client } = props.messaging;

  useEffect(() => {
    const list = [
      {
        label: <div style={{ padding: '10px 0' }}>My inbox</div>,
        value: 'my_inbox',
      },
      {
        label: <div style={{ padding: '10px 0' }}>Store inbox</div>,
        value: 'store_inbox',
      },
    ];
    if (yn(props.auth.merchantConfig.DECLUTTERED_INBOX)) {
      list.push({
        label: <div style={{ padding: '10px 0' }}>Closed</div>,
        value: 'closed',
      });
    }
    setInboxMenu(list);
  }, []);

  const updateClosedInboxList = (client_id, event) => {
    if (closedInboxList.filter(list => list.client.id === client_id).length > 0) {
      const newList = closedInboxList.filter(list => list.client.id !== client_id);
      let clientEvent = closedInboxList.find(list => list.client.id === client_id);
      clientEvent = [{ ...clientEvent, lastMessage: event.msg }];
      newList.unshift(...clientEvent);
      setClosedInboxList(newList);
    } else {
      closedInboxList.unshift(newObjCreator({ ...event, isOpen: false }));
      setClosedInboxList(closedInboxList);
    }
  };

  useEffect(() => {
    const subscription = eventSubject.subscribe(event => {
      const findSelectedStore = props.auth.stores.find(store => store.id === props.auth.selectedStore);
      if (findSelectedStore.name === event.store_name || findSelectedStore.id === event.storeId) {
        if (!yn(event.isOpen) && !yn(event.chatIsOpen)) {
          updateClosedInboxList(event.client_id, event);
        } else {
          const { client_id, observers, client_associate_id } = event;
          const isMyInboxMessage = observers?.includes(props.auth.userId) || client_associate_id === props.auth.userId;
          if (props.messaging.showSearchResults) {
            if ((event.client_name ?? event.facebookUserName)?.includes(props.messaging.searchText)) {
              if (client.client_id === client_id || client?.chat_id === event?.chatId) {
                updateConversationLists({ ...event, lastReadDateTime: client.lastSentTimestamp });
              } else {
                updateConversationLists(event);
              }
            }
          } else if (event?.facebookUserId) {
            const obj = {
              assignedAssociates: [],
              chat_id: event.chatId,
              client: {
                id: null,
                name: event.facebookUserName,
                assignedAssociates: [],
                type: 'F',
                chat_id: event.chatId,
              },
              id: event.chatId,
              clientReplyDateTime: null,
              client_id: null,
              isAnonymous: false,
              isOpen: event.chatIsOpen,
              lastMessage: event.messageText,
              lastReadDateTime: null,
              lastSentTimestamp: event.sentTimestamp,
              merchantId: event.merchantId,
              name: event.facebookUserName,
              storeId: event.storeId,
              store_id: event.storeId,
              type: 'F',
              observers: [],
            };
            updateConversationLists({
              ...obj,
              lastReadDateTime: client?.chat_id === event?.chatId ? props.messaging?.client?.lastSentTimestamp : null,
              isUnread: client?.chat_id !== event?.chatId,
            });
          } else if (event?.instagramMessageId) {
            const obj = {
              assignedAssociates: [],
              chat_id: event.chatId,
              client: {
                id: null,
                name: event.instagramUserName,
                assignedAssociates: [],
                type: 'I',
                chat_id: event.chatId,
              },
              id: event.chatId,
              clientReplyDateTime: null,
              client_id: null,
              isAnonymous: false,
              isOpen: event.chatIsOpen,
              lastMessage: event.messageText,
              lastReadDateTime: null,
              lastSentTimestamp: event.sentTimestamp,
              merchantId: event.merchantId,
              name: event.instagramUserName,
              storeId: event.storeId,
              store_id: event.storeId,
              type: 'I',
              observers: [],
            };
            updateConversationLists({
              ...obj,
              lastReadDateTime: client?.chat_id === event?.chatId ? props.messaging?.client?.lastSentTimestamp : null,
              isUnread: client?.chat_id !== event?.chatId,
            });
          } else {
            if (client.client_id !== client_id) {
              updateConversationLists({ ...event, isUnread: true });
            } else {
              updateConversationLists({...event, isUnread: false});
            }
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [client.chat_id, props.messaging.searchText]);

  useEffect(() => {
    if (!inboxList.length) {
      return;
    }
    const unreadConversations = new Set();
    inboxList.forEach(conversation => {
      if (conversation.isUnread) {
        unreadConversations.add(conversation.id);
      }
    });
    setNewMessages([...unreadConversations]);
  }, [inboxList]);

  const MyInboxAPI = async (search, pg, list, unread) => {
    isMounted.current = true;

    if (props.auth.merchantId) {
      if (loading) {
        return;
      }

      setLoading(true);

      const reqObj = {
        params: ['chats', 'getMyInboxByStore'],
        data: { page: pg, limit: DEFAULT_LIMIT, storeId: props.auth.selectedStore, licenseKey: props.auth.licenseKey },
      };

      if (search) {
        reqObj.data.searchText = search;
      }

      if (unread) {
        reqObj.data.unread = unread;
      }

      const response = await postRequest(reqObj);
      const respData = response.data;
      const mobileInvalidTrue = respData.map(x => x.mobile_invalid === 1);

      let messageList = [...respData];
      if (limitAssociateVisibility) {
        messageList = respData.map(x => {
          if (Array.isArray(x?.client?.assignedAssociates)) {
            return {
              ...x,
              disabled: !x?.client?.assignedAssociates.includes(props.auth.userId),
            };
          }
          return x;
        });
      }

      if (mobileInvalidTrue) {
        setMobileInvalid(true);
      }

      setInboxList([...list, ...messageList]);
      if (response.total > response.limit + response.offset) {
        setIsThereMoreInbox(true);
      } else {
        setIsThereMoreInbox(false);
      }

      setLoading(false);

      return () => {
        isMounted.current = false;
      };
    }
  };

  const StoreInboxAPI = async (search, pg, list, unread, associates) => {
    isMounted.current = true;

    if (props.auth.merchantId) {
      const { role, selectedStore } = props.auth;
      const reqObj = {
        params: ['chats', 'getStoreInboxByStore'],
        data: {
          page: pg,
          limit: DEFAULT_LIMIT,
          role,
          licenseKey: props.auth.licenseKey,
          storeId: selectedStore,
        },
      };

      if (search) {
        reqObj.data.searchText = search;
      }

      if (unread) {
        reqObj.data.unread = unread;
      }
      if (typeFilter) {
        reqObj.data.type = [typeFilter];
      }

      if (associates.length > 0) {
        if (associates.includes('NO')) {
          reqObj.data.isAssigned = false;
        } else {
          reqObj.data.associates = associates;
        }
      }

      const response = await postRequest(reqObj);
      const respData = response.data;
      const mobileInvalidTrue = respData.map(x => x.mobile_invalid === 1);

      if (mobileInvalidTrue) {
        setMobileInvalid(true);
      }

      let newList = [...respData];
      if (limitAssociateVisibility) {
        newList = newList.filter(
          msg => msg.client.assignedAssociates.includes(props.auth.userId) || msg.followers.includes(props.auth.userId),
        );
      }
      setStoreInboxList([...list, ...newList]);
      if (response.total > response.limit + response.offset) {
        setIsThereMoreStore(true);
      } else {
        setIsThereMoreStore(false);
      }

      return () => {
        isMounted.current = false;
      };
    }
  };

  const ClosedInboxAPI = async (search, pg, list, unread, associates) => {
    isMounted.current = true;
    if (!yn(props.auth.merchantConfig.DECLUTTERED_INBOX)) {
      return;
    }

    if (props.auth.merchantId) {
      const { role, selectedStore } = props.auth;
      const reqObj = {
        params: ['chats', 'getClosedInboxByStore'],
        data: {
          page: pg,
          limit: DEFAULT_LIMIT,
          role,
          licenseKey: props.auth.licenseKey,
          storeId: selectedStore,
        },
      };

      if (search) {
        reqObj.data.searchText = search;
      }

      if (unread) {
        reqObj.data.unread = unread;
      }
      if (typeFilter) {
        reqObj.data.type = [typeFilter];
      }

      if (associates.length > 0) {
        if (associates.includes('NO')) {
          reqObj.data.isAssigned = false;
        } else {
          reqObj.data.associates = associates;
        }
      }

      const response = await postRequest(reqObj);
      const respData = response.data;
      const mobileInvalidTrue = respData.map(x => x.mobile_invalid === 1);

      if (mobileInvalidTrue) {
        setMobileInvalid(true);
      }

      let newList = [...respData];
      if (limitAssociateVisibility) {
        newList = newList.filter(
          msg => msg.client.assignedAssociates.includes(props.auth.userId) || msg.followers.includes(props.auth.userId),
        );
      }
      setClosedInboxList([...list, ...newList]);
      if (response.total > response.limit + response.offset) {
        setIsThereMoreClosed(true);
      } else {
        setIsThereMoreClosed(false);
      }

      return () => {
        isMounted.current = false;
      };
    }
  };

  useEffect(() => {
    if (unreadMsgMatching.inbox === 'my_inbox' || unreadMsgMatching.inbox === 'store_inbox') {
      setInboxList([
        ...inboxList.map(convo => ({
          ...convo,
          lastReadDateTime: convo.id === unreadMsgMatching.chatId ? 5 : convo.lastReadDateTime,
          isUnread: convo.id === unreadMsgMatching.chatId ? false : convo.isUnread,
        })),
      ]);

      setStoreInboxList([
        ...storeInboxList.map(convo => ({
          ...convo,
          lastReadDateTime: convo.id === unreadMsgMatching.chatId ? 5 : convo.lastReadDateTime,
          isUnread: convo.id === unreadMsgMatching.chatId ? false : convo.isUnread,
        })),
      ]);
    }

    if (unreadMsgMatching.inbox === 'closed') {
      setClosedInboxList([
        ...closedInboxList.map(convo => ({
          ...convo,
          lastReadDateTime: convo.id === unreadMsgMatching.chatId ? 5 : convo.lastReadDateTime,
          isUnread: convo.id === unreadMsgMatching.chatId ? false : convo.isUnread,
        })),
      ]);
    }
  }, [unreadMsgMatching]);

  useEffect(() => {
    MyInboxAPI(props.messaging.searchText, 1, [], unreadMsg);
    StoreInboxAPI(props.messaging.searchText, 1, [], unreadMsg, []);
    ClosedInboxAPI(props.messaging.searchText, 1, [], unreadMsg, []);
  }, [props.auth.selectedStore]);

  useEffect(() => {
    if (chatMoreRefresh) {
      MyInboxAPI(props.messaging.searchText, 1, [], unreadMsg).then(() => {
        setChatMoreRefresh(false);
      });
    } else if (unreadMsg) {
      MyInboxAPI(props.messaging.searchText, 1, [], unreadMsg);
    } else if (page === 1) {
      MyInboxAPI(props.messaging.searchText, 1, [], unreadMsg);
    } else {
      MyInboxAPI(props.messaging.searchText, page, inboxList, unreadMsg);
    }
  }, [props.auth.merchantId, page, chatMoreRefresh, unreadMsg, props.messaging.triggerRefresh]);

  useEffect(() => {
    if (chatMoreRefresh) {
      StoreInboxAPI(props.messaging.searchText, 1, [], unreadMsg, []).then(() => {
        setChatMoreRefresh(false);
      });
    } else if (unreadMsg || filterAssoc.length > 0) {
      if (storePage === 1) {
        StoreInboxAPI(props.messaging.searchText, 1, [], unreadMsg, filterAssoc);
      } else {
        StoreInboxAPI(props.messaging.searchText, storePage, storeInboxList, unreadMsg, filterAssoc);
      }
    } else if (storePage === 1) {
      StoreInboxAPI(props.messaging.searchText, 1, [], unreadMsg, filterAssoc);
    } else {
      StoreInboxAPI(props.messaging.searchText, storePage, storeInboxList, unreadMsg, []);
    }
  }, [
    props.auth.merchantId,
    storePage,
    chatMoreRefresh,
    filterAssoc,
    unreadMsg,
    typeFilter,
    props.messaging.triggerRefresh,
  ]);

  useEffect(() => {
    if (chatMoreRefresh) {
      ClosedInboxAPI(props.messaging.searchText, 1, [], unreadMsg, []).then(() => {
        setChatMoreRefresh(false);
      });
    } else if (unreadMsg || filterAssoc.length > 0) {
      if (ClosedPage === 1) {
        ClosedInboxAPI(props.messaging.searchText, 1, [], unreadMsg, filterAssoc);
      } else {
        ClosedInboxAPI(props.messaging.searchText, ClosedPage, closedInboxList, unreadMsg, filterAssoc);
      }
    } else if (ClosedPage === 1) {
      ClosedInboxAPI(props.messaging.searchText, 1, [], unreadMsg, filterAssoc);
    } else {
      ClosedInboxAPI(props.messaging.searchText, ClosedPage, closedInboxList, unreadMsg, []);
    }
  }, [
    props.auth.merchantId,
    ClosedPage,
    chatMoreRefresh,
    filterAssoc,
    unreadMsg,
    typeFilter,
    props.messaging.triggerRefresh,
  ]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      setMsgResetClient();
    };
  }, []);

  const updateUnreadInInboxes = chat_id => {
    setInboxList([
      ...inboxList.map(convo => ({
        ...convo,
        lastReadDateTime: convo.id === chat_id ? null : convo.lastReadDateTime,
        isUnread: convo.id === chat_id ? true : convo.isUnread,
      })),
    ]);
    setStoreInboxList([
      ...storeInboxList.map(convo => ({
        ...convo,
        lastReadDateTime: convo.id === chat_id ? null : convo.lastReadDateTime,
        isUnread: convo.id === chat_id ? true : convo.isUnread,
      })),
    ]);
    setClosedInboxList([
      ...closedInboxList.map(convo => ({
        ...convo,
        lastReadDateTime: convo.id === chat_id ? null : convo.lastReadDateTime,
        isUnread: convo.id === chat_id ? true : convo.isUnread,
      })),
    ]);
  };

  const handleSearchChange = async value => {
    if (typeof value !== 'object') {
      setMsgSearchText(value);
    }
    // setStorePage(1);
    if (value.length > 0) {
      MyInboxAPI(value, 1, [], unreadMsg);
      StoreInboxAPI(value, 1, [], unreadMsg, filterAssoc);
      ClosedInboxAPI(value, 1, [], unreadMsg, filterAssoc);
      setShowSearchResults(true);
    } else if (value.length === 0) {
      MyInboxAPI(value, 1, [], false);
      StoreInboxAPI(value, 1, [], unreadMsg, []);
      ClosedInboxAPI(value, 1, [], unreadMsg, []);
      setShowSearchResults(false);
    }
  };

  useEffect(() => {
    if (props.auth.merchantId) {
      const reqObj = {
        params: ['chats', 'getAsctList'],
      };
      (async () => {
        const data = await getRequest(reqObj);
        const sortedAscts = data?.sort((a, b) => {
          if (`${a.first_name || ''}${a.last_name || ''}` > `${b.first_name || ''}${b.last_name}`) {
            return 1;
          }
          if (`${a.first_name || ''}${a.last_name || ''}` < `${b.first_name || ''}${b.last_name}`) {
            return -1;
          }
          return 0;
        });
        setMsgAllAsctsList(sortedAscts);
      })();
    }
  }, [props.auth.merchantId]);

  const selectArray = () => {
    const dataArr = props?.messaging?.allAsctsList.map(asct => ({
      label: `${asct.first_name || ''} ${asct.last_name || ''}`,
      value: asct.id,
    }));
    dataArr.unshift({ label: 'No Assigned Associates', value: 'NO' });
    dataArr.unshift({ label: 'All Associates', value: '' });
    return dataArr;
  };

  const setAllPages = index => {
    setStorePage(index);
    setClosedPage(index);
    if (index === 1) {
      document?.getElementById('scrollableDivChat')?.scrollTo(0, 0);
    }
  };
  const clearInboxLists = () => {
    setStoreInboxList([]);
    setClosedInboxList([]);
  };

  return (
    <div style={{ borderRight: '1px solid #EBEBEB' }} className=" h-100-P" id="search-filter">
      <SearchAndFilter
        handleSearchChange={handleSearchChange}
        searchText={props.messaging.searchText}
        setSearchText={setMsgSearchText}
        setNewMessage={props.setNewMessage}
        type={props.messaging.inboxType}
        unreadMsg={unreadMsg}
        setUnreadMsg={setUnreadMsg}
        asctFilterList={selectArray()}
        stores={props.auth.stores}
        filterAssoc={filterAssoc}
        setFilterAssoc={setFilterAssoc}
        setFilterStore={setFilterStore}
        inboxList={inboxList}
        showStoreFilter={showStoreFilter}
        setShowStoreFilter={setShowStoreFilter}
        zeroResults={zeroResults}
        setZeroResults={setZeroResults}
        setAllPages={setAllPages}
        typeFilter={typeFilter}
        setTypeFilter={setTypeFilter}
        page={storePage}
        clearInboxLists={clearInboxLists}
        setChatMoreRefresh={setChatMoreRefresh}
        associateId={props.auth.userId}
      />
      <div>
        {/* eslint-disable-next-line no-nested-ternary */}
        {props.messaging.showSearchResults ? (
          <div style={{ borderTop: '0.5px solid #EBEBEB', marginTop: '8px' }}>
            <InboxChatList
              associateId={props.auth.userId}
              selectedClient={props?.messaging?.client?.id}
              setSelectedClient={props.setSelectedClient}
              setNewMessage={props.setNewMessage}
              inboxList={storeInboxList}
              loadMoreData={StoreInboxAPI}
              isThereMore={isThereMoreStore}
              setPage={setStorePage}
              page={storePage}
              type={props.messaging.inboxType}
              setChatMoreRefresh={setChatMoreRefresh}
              setAssignPopup={setAssignPopup}
              setHoverAssignId={setHoverAssignId}
              setFollowers={setFollowers}
              setClientOnAssignPopup={setClientOnAssignPopup}
              updateUnreadInInboxes={updateUnreadInInboxes}
              role={props.auth.role}
              setChatIconColor={props.setChatIconColor}
              searchText={props.messaging.searchText}
              paymentBanner={props.paymentBanner}
              mobileInvalid={mobileInvalid}
              setUnreadMsgMatching={setUnreadMsgMatching}
            />
          </div>
        ) : limitAssociateVisibility ? (
          <div style={{ borderTop: '0.5px solid #EBEBEB', marginTop: '8px' }}>
            <InboxChatList
              associateId={props.auth.userId}
              selectedClient={props?.messaging?.client?.id}
              setSelectedClient={props.setSelectedClient}
              setNewMessage={props.setNewMessage}
              inboxList={inboxList}
              loadMoreData={MyInboxAPI}
              isThereMore={isThereMoreInbox}
              setPage={setPage}
              page={page}
              type={props.messaging.inboxType}
              setChatMoreRefresh={setChatMoreRefresh}
              setAssignPopup={setAssignPopup}
              setHoverAssignId={setHoverAssignId}
              setFollowers={setFollowers}
              setClientOnAssignPopup={setClientOnAssignPopup}
              updateUnreadInInboxes={updateUnreadInInboxes}
              role={props.auth.role}
              setChatIconColor={props.setChatIconColor}
              searchText={props.messaging.searchText}
              paymentBanner={props.paymentBanner}
              mobileInvalid={mobileInvalid}
              setUnreadMsgMatching={setUnreadMsgMatching}
            />
          </div>
        ) : (
          <Tabs
            activeKey={props.messaging.inboxType}
            size="large"
            tabBarStyle={{ backgroundColor: 'cyan' }}
            // eslint-disable-next-line react/jsx-boolean-value
            destroyInactiveTabPane={true}
            id="inbox-tabs"
            renderTabBar={tab => (
              <div className="p-10">
                <Segmented
                  block
                  defaultValue="my_inbox"
                  value={props.messaging.inboxType}
                  options={inboxMenu}
                  onChange={key => {
                    setInboxType(key);
                    setPage(1);
                    setStorePage(1);
                  }}
                />
              </div>
            )}
            items={[
              {
                key: 'my_inbox',
                children: (
                  <InboxChatList
                    associateId={props.auth.userId}
                    selectedClient={props?.messaging?.client?.id}
                    setSelectedClient={props.setSelectedClient}
                    setNewMessage={props.setNewMessage}
                    inboxList={inboxList}
                    loadMoreData={MyInboxAPI}
                    isThereMore={isThereMoreInbox}
                    setPage={setPage}
                    page={page}
                    type={props.messaging.inboxType}
                    setChatMoreRefresh={setChatMoreRefresh}
                    setAssignPopup={setAssignPopup}
                    setHoverAssignId={setHoverAssignId}
                    setFollowers={setFollowers}
                    setClientOnAssignPopup={setClientOnAssignPopup}
                    updateUnreadInInboxes={updateUnreadInInboxes}
                    role={props.auth.role}
                    setChatIconColor={props.setChatIconColor}
                    searchText={props.messaging.searchText}
                    paymentBanner={props.paymentBanner}
                    mobileInvalid={mobileInvalid}
                    setUnreadMsgMatching={setUnreadMsgMatching}
                  />
                ),
              },
              {
                key: 'store_inbox',
                children: (
                  <InboxChatList
                    associateId={props.auth.userId}
                    selectedClient={props?.messaging?.client?.id}
                    setSelectedClient={props.setSelectedClient}
                    setNewMessage={props.setNewMessage}
                    inboxList={storeInboxList}
                    loadMoreData={StoreInboxAPI}
                    isThereMore={isThereMoreStore}
                    setPage={setStorePage}
                    page={storePage}
                    type={props.messaging.inboxType}
                    setChatMoreRefresh={setChatMoreRefresh}
                    setAssignPopup={setAssignPopup}
                    setHoverAssignId={setHoverAssignId}
                    setFollowers={setFollowers}
                    setClientOnAssignPopup={setClientOnAssignPopup}
                    updateUnreadInInboxes={updateUnreadInInboxes}
                    role={props.auth.role}
                    setChatIconColor={props.setChatIconColor}
                    searchText={props.messaging.searchText}
                    paymentBanner={props.paymentBanner}
                    mobileInvalid={mobileInvalid}
                    setUnreadMsgMatching={setUnreadMsgMatching}
                  />
                ),
              },
              {
                key: 'closed',
                children: (
                  <InboxChatList
                    associateId={props.auth.userId}
                    selectedClient={props?.messaging?.client?.id}
                    setSelectedClient={props.setSelectedClient}
                    setNewMessage={props.setNewMessage}
                    inboxList={closedInboxList}
                    loadMoreData={ClosedInboxAPI}
                    isThereMore={isThereMoreClosed}
                    setPage={setClosedPage}
                    page={ClosedPage}
                    type={props.messaging.inboxType}
                    setChatMoreRefresh={setChatMoreRefresh}
                    setAssignPopup={setAssignPopup}
                    setHoverAssignId={setHoverAssignId}
                    setFollowers={setFollowers}
                    setClientOnAssignPopup={setClientOnAssignPopup}
                    updateUnreadInInboxes={updateUnreadInInboxes}
                    role={props.auth.role}
                    setChatIconColor={props.setChatIconColor}
                    searchText={props.messaging.searchText}
                    paymentBanner={props.paymentBanner}
                    mobileInvalid={mobileInvalid}
                    setUnreadMsgMatching={setUnreadMsgMatching}
                  />
                ),
              },
            ]}
          />
        )}
      </div>
      {assignPopup ? (
        <AssignChatPopup
          associateList={props.messaging.allAsctsList}
          setAssignPopup={setAssignPopup}
          asctFilterList={selectArray()}
          setChatMoreRefresh={setChatMoreRefresh}
          hoverAssignId={hoverAssignId}
          followers={followers}
          staticAssoc={
            clientOnAssignPopup?.assignedAssociates.length > 0 ? clientOnAssignPopup.assignedAssociates[0] : null
          }
          source="hoverAssign"
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  messaging: state.messaging,
  newMessages: state.navigation.newMessages,
});

export default connect(mapStateToProps)(ChatListBar);
