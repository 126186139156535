import React, { useCallback, useMemo, useRef, useState } from 'react';
import { EllipsisOutlined, InfoCircleFilled, LoadingOutlined, PlusCircleFilled } from '@ant-design/icons';
import FakeDisabledInput from './children/FakeDisabledInput';
import { Dropdown } from 'antd-v5';
import useGetClients from '../../../../../../hooks/query/useGetClients';
import useDebounce from '../../../../../../hooks/useDebounce';
import AddClientModal from '../../../../../../componentsV2/Layout/AddClientModal';
import { notificationError } from '../../../../../../core/actions';

const NewMessage = props => {
  const isMounted = useRef(null);
  //const [searchList, setSearchList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const debounceSearch = useDebounce(searchValue);
  const stores = useMemo(() => props.associateStores.filter(st => st.id !== 0).map(st => st.id), [props.associateStores]);
  const [openNewClientModal, setOpenNewClientModal] = useState(false);
  const [newClientName, setNewClientName] = useState('');

  const { data: { results: searchList }, isFetching } = useGetClients({
    query: {
      search: debounceSearch,
      page: 1,
      pageSize: 10,
      selectedStores: JSON.stringify(stores),
    },
    loadingStatusOff: true
  });

  const items = useMemo(() => {
    let list = searchList || [];
    let addClient = props.flags.addClientFromConversation ? [{
      key: 'add-client',
      label: <div
        className='flex-row-center w-100-P'
        style={{ color: '#33AAFC'}}
      >
        <PlusCircleFilled style={{marginRight: '8px'}}/>
        {`Add Client "${searchValue}"`}
      </div>,
    }] : [];
    return [
      ...addClient,
      ...list?.map(item => ({
         key: item.id,
         label: item.name,
        }))
    ]
  }, [searchList, searchValue, props.flags.addClientFromConversation]);

  const handleSearchChange = useCallback(({key}) => {
    if(key === 'add-client') {
      setNewClientName(searchValue)
      setSearchValue('')
      setOpenNewClientModal(true)
    } else {
      props.setSelectedClient({ id: key, client_id: key, chat_id: null, isOpen: true, type: 'C' });
      props.setNewMessage(false);
    }
  }, [searchValue])


  return (
    <div
      className="w-100-P flex-col-center"
      id="client-search-wrapper"
      style={{ justifyContent: 'space-between', height: 'calc(100% - 70px)' }}
    >
      <div
        className="conversation-window-header w-100-P "
        id="client-search-section"
        style={{ borderBottom: '0.5px solid #EBEBEB', height: '78px' }}
      >
        <div className="pos-rel add-edit-wrap" style={{ width: '50%' }} id="client-search">
          <Dropdown
            menu={{
              items,
              onClick: handleSearchChange,
              className: 'fw-500',

            }}
            open={searchValue?.length > 0}
          >
            <div className="flex-row-nospacebetween-nowrap">
              <input
                //onKeyDown={refetch()}
                className="clientSearchInbox h-45"
                id="client-search-input"
                placeholder="Client Search..."
                style={{ width: '50%', marginLeft: '37px' }}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onChange={e => setSearchValue(e.target.value)}
                value={searchValue}
                autoComplete='off'

              />
              {isFetching && <LoadingOutlined />}
            </div>
          </Dropdown>
        </div>
        <div
          // className="conversation-window-header-heading-icons"
          style={{ width: '50%', justifyContent: 'flex-end', display: 'flex' }}
        >
          <EllipsisOutlined style={{ fontSize: '23px', marginRight: '12px', marginBottom: '3px', color: '#EBEBEB' }} />
          <InfoCircleFilled style={{ fontSize: '16px', color: '#EBEBEB', marginTop: '2px', marginRight: '16px' }} />
        </div>
      </div>
      <FakeDisabledInput />
      {props.flags.addClientFromConversation && (
        <AddClientModal
          open={openNewClientModal}
          setOpen={setOpenNewClientModal}
          initialValues={{
            storeId: props?.selectedStore || stores[0],
            [/^\d+$/.test(newClientName) ? 'mobile' : 'name']: /^\d+$/.test(newClientName) ? newClientName.charAt(0) === 1 ?
            newClientName : `1${newClientName}`
            : newClientName,
            preferredContactType: 'SMS' }}
          hiddenFields={{
            storeId: true,
            email: true,
            preferredContactType: true,
          }}
          onSuccess={(response) => {
            props.setSelectedClient({ id: response.id, client_id: response.id, chat_id: null, isOpen: true, type: 'C' });
            setNewClientName('');
          }}
          requiredFields={['name', 'mobile']}
          onError={(error) => {
            notificationError('Error creating client');
            setOpenNewClientModal(false);
          }}
        />
      )}
    </div>
  );
};

export default NewMessage;
