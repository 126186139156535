import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import StandardLayout from '../../../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../../../Layout/SecondaryPanel';
import AutoMessageSettings from '../../../../../../Layout/AutoMessages/AutoMessageSettings';

const SalesAutoMessage = props => {
  const history = useHistory();

  const pipelineId = props.match.params.pipelineId;
  const listItemId = props.match.params.listItemId;

  const backUrl = `/Configuration/sales-pipelines/edit-sales-pipeline/${pipelineId}/edit-pipeline-stage/${listItemId}`;

  const setRedirectBack = () => {
    history.push(backUrl);
  };

  return (
    <StandardLayout
      title={`${props.autoMessageAddOrEdit} Auto Message`}
      description="Write a message to send automatically."
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Sales Opportunities', to: '/Configuration/sales-pipelines' },
        // do we want editPipeline here as well?  that'll make a huge breadcrumb
        { title: 'Edit Pipeline', to: backUrl },
        { title: `${props.autoMessageAddOrEdit} Auto Message` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <AutoMessageSettings
        setRedirectBack={setRedirectBack}
        popConfirmDesc={'This will remove the Auto Message from this Sales Pipeline'}
        cancelRoute={backUrl}
        editRoute={backUrl}
        type={'salesOpp'}
        match={props.match}
        selectAutoMessageTime="After creating a sales pipeline, how soon should the message send?"
      />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({ ...state.autoMessages });
export default connect(mapStateToProps, {})(SalesAutoMessage);
