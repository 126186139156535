import React, { useState, useEffect, useRef } from 'react';
import yn from 'yn';
import { connect } from 'react-redux';
import Upload from '../MessageSettings/Upload';
import ContUpload from '../MessageSettings/ContUpload';
import Button from '../../../Layout/Button';
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import TitleDescription from '../../../Layout/DescriptionBox';
import CheckboxDesc from '../../../Layout/CheckboxDesc';
import DropdownSelect from '../../../Layout/DropdownSelect/index';

// TODO: possibly replace the SVGs below with FAwesome
import { bodyFormatterKiosk, messageDispatcher } from '../MessageSettings/largeMsgSettingFns';
import { saveMessageSettings, getMessageSettings } from '../../../../core/apiRequests';
import {
  setRoundLogo,
  setBGColor,
  setBGImage,
  setIntroTitle,
  setFormTitle,
  setTopSectHeader,
  setBtmSectHeader,
  setOptInTitle,
  setOptInHelp,
  setOptInLegal,
  setMsgButtonText,
  setCompleteTitle,
  setCompleteSubtitle,
  setLogoUrl,
  setMsgShowAnniversary,
  setMsgShowBirthday,
  setMsgShowAddress,
  setMsgShowEmail,
} from '../../../../reducers/messageSettings';

import '../MessageSettings/messageSettings.css';

const KioskSettings = props => {
  const [uploadButtonText, setUploadButtonText] = useState('Upload');
  const [bgType, setBgType] = useState('Solid Color');
  const [uploadButtonImageText, setUploadButtonImageText] = useState('Upload');
  const isMounted = useRef(null);

  const flags = props.merchantFlags;
  const ENABLE_CUSTOM_KIOSK_MODE = flags?.kiosk?.enabled && flags?.kiosk?.value === 'custom' ;

  useEffect(() => {
    isMounted.current = true;
    if (props.merchantId) {
      (async () => {
        const data = await getMessageSettings('merchant', props.merchantId);
        if (data) {
          messageDispatcher(data);
          if (data.background_url && data.background_url.length > 0) {
            setBgType('Image');
          }
        }
      })();
    }
    return () => {
      isMounted.current = false;
    };
  }, [props.merchantId]);

  const saveSettings = () => {
    const { merchantId } = props;
    const bodyData = bodyFormatterKiosk(props);
    saveMessageSettings('merchant', merchantId, bodyData, 'kioskConfig');
  };

  const bgTypeHandler = val => {
    setBgType(val);
    if (val === 'Image') {
      setBGColor('');
    } else {
      setBGImage('');
      setUploadButtonImageText('Upload');
    }
  };

  const divStyle = {
    width: '866px',
  };

  const dis_style = {
    opacity: '1',
  };

  const dis_style_active = {
    opacity: '0.5',
  };

  // Select Option arrays
  const shapeItems = [
    { value: false, label: 'Square' },
    { value: true, label: 'Circle' },
  ];
  const backgroundType = [
    { value: 'Solid Color', label: 'Solid Color' },
    { value: 'Image', label: 'Image' },
  ];

  return (
    <Layout title="Kiosk Settings" classProps="standard-width-with-sidebar">
      {yn(ENABLE_CUSTOM_KIOSK_MODE) && (
        <SettingSegment
          title="Optional Kiosk Fields"
          withDivider={false}
          img="https://dashboard-v2-images.s3.amazonaws.com/list.png"
          description="Below are optional fields that can be added to show in kiosk. Check the box to add the field and uncheck it to remove."
        >
          <CheckboxDesc
            checkBoxId="Birthday"
            checked={props.show_birthday}
            onclick={val => setMsgShowBirthday(val)}
            text="Birthday"
            classProps="fs-13"
            checkButtonProps="m-btm-15 w-85"
          />
          <CheckboxDesc
            checkBoxId="Anniversary"
            checked={props.show_anniversary}
            onclick={val => setMsgShowAnniversary(val)}
            text="Anniversary"
            classProps="fs-13"
            checkButtonProps="m-btm-15 w-85"
          />
          <CheckboxDesc
            checkBoxId="Email"
            checked={props.show_email}
            onclick={val => setMsgShowEmail(val)}
            text="Email"
            classProps="fs-13"
            checkButtonProps="m-btm-15 w-85"
          />
          <CheckboxDesc
            checkBoxId="Address"
            checked={props.show_address}
            onclick={val => setMsgShowAddress(val)}
            text="Address"
            classProps="fs-13"
            checkButtonProps="m-btm-15 w-85"
          />
          <div className="p-btm-26" />
        </SettingSegment>
      )}
      <SettingSegment
        title="KIOSK SETTINGS"
        withDivider={false}
        img="https://dashboard-v2-images.s3.amazonaws.com/ic-kiosk-gray.svg"
      >
        <TitleDescription
          title="Brand Logo"
          description="Upload a social media friendly image (JPG or PNG)."
          input={
            <Upload
              uploadButtonText={uploadButtonText}
              setUploadButtonText={setUploadButtonText}
              setUploadImg={setLogoUrl}
              // disabled={uploadButtonText == 'Upload' ? false : true}
              upload_label={
                <label className="w-267 upload_label pointer" id="file" htmlFor="file" value={props.logoUrl}>
                  {uploadButtonText}
                </label>
              }
              logoUrl={props.logoUrl}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Logo Shape"
          description="How do you want your logo displayed?"
          input={
            <DropdownSelect
              id="LogoShapeDropdown"
              classProps="w-267 align-left"
              options={shapeItems}
              placeholder="Circle"
              value={props.roundLogo}
              onChange={obj => {
                setRoundLogo(obj.value);
              }}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Background Type" // TODO: Set this to wipe out the opposite of what it chooses
          description="Do you want a solid color or an image to appear in the background of the intro page?"
          input={
            <DropdownSelect
              id="SolidColorDropdown"
              classProps="w-267 align-left"
              options={backgroundType}
              placeholder="Image"
              value={bgType}
              onChange={obj => {
                bgTypeHandler(obj.value);
              }}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Background Color"
          description="Enter a hex color code to change the background color"
          input={
            <input
              disabled={bgType !== 'Solid Color'}
              id="BackgroundColorInput"
              className=" w-267 inputHeight common_inputStyle rem-pad-width"
              placeholder="Hex Color Code"
              value={props.backgroundColor}
              onChange={event => setBGColor(event.target.value)}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Background Image"
          description="Upload a background image that represents your brand"
          input={
            <ContUpload
              uploadButtonText={uploadButtonImageText}
              setUploadButtonText={setUploadButtonImageText}
              imgUrl={props.backgroundUrl}
              setBgImage={setBGImage}
              disabled={bgType !== 'Image'}
              // bgType = {bgType}
              upload_label={
                <label
                  className={`w-267 upload_label ${bgType !== 'Image' ? 'not-allowed' : 'pointer'}`}
                  id="fileTwo"
                  htmlFor="fileTwo"
                  value={props.backgroundUrl}
                  style={bgType !== 'Image' ? dis_style_active : dis_style}
                >
                  {uploadButtonImageText}
                </label>
              }
              backgroundUrl={props.backgroundUrl}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Intro Title"
          description="What would you like your introductory phrase to be? Maximum 60 Characters"
          input={
            <input
              id="IntroTitleInput"
              className=" w-267 common_inputStyle inputHeight rem-pad-width"
              placeholder="Tap to get started"
              value={props.introTitle}
              onChange={event => setIntroTitle(event.target.value)}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Form Title"
          description="This is the title at the top of the form your client will fill out. Maximum 60 Characters"
          input={
            <input
              id="FormTitleInput"
              className="  w-267 common_inputStyle inputHeight rem-pad-width"
              placeholder="Welcome"
              value={props.formTitle}
              onChange={event => setFormTitle(event.target.value)}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Top Section Header"
          description="This is the header for the top section of the form that includes first/last name, mobile number, and the optional birthday and anniversary fields. Maximum 130 Characters"
          input={
            <input
              id="TopSectionHeaderInput"
              className=" w-267 common_inputStyle inputHeight rem-pad-width"
              placeholder="Tell us about yourself"
              value={props.topSectionHeader}
              onChange={event => setTopSectHeader(event.target.value)}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Bottom Section Header"
          description="This is the header for the bottom section of the form that includes tags the client can choose from. Maximum 60 Characters"
          input={
            <input
              id="BottomSectionHeaderInput"
              className=" w-267 common_inputStyle inputHeight rem-pad-width"
              placeholder="Choose a few favorites"
              value={props.bottomSectionHeader}
              onChange={event => setBtmSectHeader(event.target.value)}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Opt-in Title"
          description="Choose a title for the opt-in checkbox. Maximum 60 Characters"
          input={
            <input
              id="OptInTitleInput"
              className="  w-864 common_inputStyle inputHeight rem-pad-width"
              placeholder="Check the box to get the inside scoop!"
              value={props.optInTitle}
              onChange={event => setOptInTitle(event.target.value)}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Opt-in Help"
          description="Maximum 60 Characters"
          input={
            <input
              id="OptInHelpInput"
              className=" w-864 common_inputStyle inputHeight rem-pad-width"
              placeholder="May we send you occasional text messages? We'd love to stay in touch!"
              value={props.optInHelp}
              onChange={event => setOptInHelp(event.target.value)}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Opt-in Legal Terms"
          description="Maximum 60 Characters"
          input={
            <textarea
              id="OptInLegalTermsInput"
              className="msgSettTextarea focus-input-outline-blue"
              placeholder="By participating, you consent to receive recurring autodialed SMS/MMS marketing messages. No purchase required. Message & data rates may apply.  Terms & Conditions apply www.brand.com/sms-terms."
              value={props.optInLegal}
              onChange={event => setOptInLegal(event.target.value)}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Button Text"
          description="Choose the text for the submit button on the form. Maximum 20 Characters"
          input={
            <input
              id="ButtonTextInput"
              className=" w-267 common_inputStyle inputHeight rem-pad-width"
              placeholder="Sign Up"
              value={props.buttonText}
              onChange={event => setMsgButtonText(event.target.value)}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Complete Title"
          description="After the form is complete, what title would you like on the closing page? Maximum 40 Characters"
          input={
            <input
              id="CompletTitleInput"
              className=" w-267 common_inputStyle inputHeight  rem-pad-width"
              placeholder="Thank You"
              value={props.completeTitle}
              onChange={event => setCompleteTitle(event.target.value)}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Complete Subtitle"
          description="What would you like your closing page subtitle to say? Ex: You will hear back from us soon! Maximum 130 Characters"
          input={
            <input
              id="CompleteSubtitleInput"
              className=" w-864 common_inputStyle inputHeight  rem-pad-width"
              placeholder="You will receive a welcome text shortly."
              value={props.completeSubtitle}
              onChange={event => setCompleteSubtitle(event.target.value)}
            />
          }
          style={divStyle}
        />
      </SettingSegment>

      <div className="settingSave" style={{ width: '863px' }}>
        <Button onclick={saveSettings} id="KioskSettingsSaveButton" classname="darkBlueButton">
          Save
        </Button>
      </div>
    </Layout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  ...state.messageSettings,
});
export default connect(mapStateToProps, {})(KioskSettings);

const SettingSegment = props => {
  return (
    <div>
      <div className="msgSetSegmentTitle">
        <img src={props.img} />
        <span>{props.title}</span>
      </div>
      {props.description && (
        <span className="fs-12 m-btm-15 m-top--15 gray max-w-560 align-left d-block">{props.description}</span>
      )}
      <div className="">{props.children}</div>
      {props.withDivider ? <div className="divider" /> : ''}
    </div>
  );
};
