import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  setClientFilterAssociates,
  setClientFilterTags,
  setPaymentFilterStatuses,
  setTransactionFilterBankAccounts,
} from '../../../reducers/Filters';
import { setAuthStoreId } from '../../../reducers/auth';

const Chip = ({ onClick, children, hiddenClose = false }) => (
  <div
    className="border-r3 CBdarkBlue rem-pad-width m-right-8 flex-row-nospacebetween-nowrap "
    style={{
      height: '33px',
      border: 'solid 1px #33aafc',
      backgroundColor: '#e8f4ff',
      maxWidth: '250px',
      alignItems: 'center',
      marginBottom: 8,
    }}
  >
    <span className="d-inline-block fs-12 m-left-10 m-right-8">{children}</span>
    {!hiddenClose && (
      <button onClick={onClick} className="transparent border-none m-left-10">
        <FontAwesomeIcon color="#1769af " icon={['far', 'times']} size="1x" />
      </button>
    )}
  </div>
);

const FilterChips = props => {
  // TAGS
  const valueMatch = () => {
    if (props.filterTags.length > 0) {
      const x = props.tagList.filter(tag => props.filterTags.includes(tag.id));
      if (x.length > 0) {
        return x.map((tag, i) => {
          return (
            <Chip
              key={i}
              onClick={() => {
                deleteFilterTag(tag.id);
              }}
            >
              Tag:{' '}
              <span
                style={{
                  display: 'inline-block',
                  backgroundColor: tag.color,
                  height: '8px',
                  width: '8px',
                  marginLeft: 8,
                  marginRight: 8,
                }}
                className="circle"
              />
              {tag.name}
            </Chip>
          );
        });
      }
    }
  };

  const deleteFilterTag = id => {
    const tempFilterArr = props.filterTags.filter(tagId => Number(tagId) !== id);
    if (props.setFilterTags) {
      props.setFilterTags([...tempFilterArr]);
    }
    setClientFilterTags([...tempFilterArr]);
  };

  // ASSOCIATES
  const associateMatch = () => {
    if (props.filterAssociates.length > 0 || props.staticAssoc) {
      let x = props.asctFilterList
        .filter(assoc => props.filterAssociates.includes(assoc.value))
        .map(assoc => ({
          ...assoc,
          hiddenClose: props.startAssoc?.includes(assoc?.value)
        }));

      const staticAssoc = props.asctFilterList.find(assoc => assoc.value === props.staticAssoc);
      x = staticAssoc ? [{...staticAssoc, hiddenClose: true}, ...x] : x;
      if (x.length > 0) {
        return (
          x
            // .filter(fltStr => fltStr.value !== 0)
            .map((asct, i) => {
              return (
                <Chip
                  key={i}
                  onClick={() => {
                    deleteFilterAssociate(asct.value);
                  }}
                  hiddenClose={asct.hiddenClose}
                >
                  {asct.label}
                  {/* {asct.value === 'none' || asct.value === 0 ? asct.label : asct.label} */}
                </Chip>
              );
            })
        );
      }
    }
  };

  const deleteFilterAssociate = id => {
    const tempFilterArr = props.filterAssociates.filter(asct => asct != id);
    if (props.setTempAssoc) {
      props.setTempAssoc([...tempFilterArr]);
    }
    setClientFilterAssociates([...tempFilterArr]);

    if (typeof props.setFilterAssociates === 'function') {
      props.setFilterAssociates(tempFilterArr);
    }
  };

  // STATUS
  const statusMatch = () => {
    if (props.filterStatus.length > 0) {
      const x = props.statusFilterList.filter(status => props.filterStatus.includes(status.value));
      if (x.length > 0) {
        return x
          .filter(fltStr => fltStr.value !== 0)
          .map((status, i) => {
            return (
              <Chip
                key={i}
                onClick={() => {
                  deleteFilterStatus(status.value);
                }}
              >
                {status.value === 'none' || status.value === 0 ? status.label : `Status: ${status.label}`}
              </Chip>
            );
          });
      }
    }
  };

  const deleteFilterStatus = id => {
    const tempFilterArr = props.filterStatus.filter(status => status != id);
    if (props.setTempStatus) {
      props.setTempStatus([...tempFilterArr]);
    }
    setPaymentFilterStatuses([...tempFilterArr]);
  };

  // BANK
  const bankMatch = () => {
    if (props.filterBank.length) {
      const account = props.bankFilterList.filter(bank => props.filterBank.includes(bank.bankName));
      return account.length
        ? account
            .filter(({ value }) => value !== 0)
            .map((bank, i) => {
              return (
                <Chip
                  key={i}
                  onClick={() => {
                    deleteFilterBank(bank.bankName);
                  }}
                >
                  {['none', 0].includes(bank.bankName) ? bank.name : `Account: ${bank.bankName}`}
                </Chip>
              );
            })
        : null;
    }
  };

  const deleteFilterBank = id => {
    const tempFilterArr = props.filterBank.filter(bank => bank != id);
    if (props.setTempBank) {
      props.setTempBank(tempFilterArr);
    }
    setTransactionFilterBankAccounts(tempFilterArr);
  };

  // DATE RANGE
  const dateRangeMatch = () => {
    const { label } = props.dateRangeList.find(({ value }) => value === props.filterDateRange);

    return (
      <Chip
        onClick={() => {
          props.setFilterDateRange(null);
        }}
      >
        Date Range: {label}
      </Chip>
    );
  };

  // DATES
  const startDateMatch = () => (
    <Chip onClick={() => props.setFilterStartDate(null)}>Start Date: {props.startDate}</Chip>
  );

  const endDateMatch = () => <Chip onClick={() => props.setFilterEndDate(null)}>End Date: {props.endDate}</Chip>;

  // STORES
  const storeMatch = () => {
    if (props.storeId) {
      const filteredStoreList = props.storeFilterList.filter(str => Number(str.id) == Number(props.storeId));
      if (filteredStoreList.length > 0) {
        return filteredStoreList.map((str, i) => {
          return (
            <Chip
              key={i}
              onClick={() => {
                deleteFilterStore();
              }}
            >
              {str.value === 'All Stores' ? str.name : `Store: ${str.name}`}
            </Chip>
          );
        });
      }
    }
  };

  const deleteFilterStore = () => {
    setAuthStoreId(0);
  };

  return (
    <div className="flex-row-nospacebetween-wrap m-top-20">
      {props.filterDateRange ? dateRangeMatch() : null}
      {props.startDate ? startDateMatch() : null}
      {props.endDate ? endDateMatch() : null}
      {props.filterTags ? valueMatch() : null}
      {props.filterStatus ? statusMatch() : null}
      {(!!props?.filterAssociates?.length || !!props?.staticAssoc) ? associateMatch() : null}
      {props.filterStores ? storeMatch() : null}
      {props.filterBank ? bankMatch() : null}
    </div>
  );
};
const mapStateToProps = state => ({ ...state.auth });
export default connect(mapStateToProps)(FilterChips);
