import React, { useState, useEffect, useCallback } from 'react';
import { Tabs } from 'antd';
import ClientHeader from '../components/ClientHeader';
import QuickView from '../components/QuickView';
import Collections from '../components/Collections';
import Activity from '../components/Activity';
import AllClientAutoMessages from '../components/QuickView/AutoMessages/AllClientAutoMessages';
import AllClientReminders from '../components/QuickView/Reminders/AllClientReminders';
import AllClientPaymentRequests from '../components/QuickView/PaymentRequest/AllClientPaymentRequests';
import AllClientSalesOpportunities from '../components/QuickView/SalesOpportunities/AllClientSalesOpportunities';
import getClientPaymentStatus from '../../../Layout/GetClientPaymentStatus';
import { getClientChat, setMsgClient, resetAutoReminderId } from '../../../../reducers/messaging';
import { getRequest } from '../../../../core/apiRequests';
import { useOnce } from '../../../../hooks/useOnce';
import OpportunityDetails from '../components/QuickView/SalesOpportunities/components/OpportunityDetails';
import './styles.scss';
import useSearchParams from '../../../../hooks/useSearchParams';
import { useQuery } from 'react-query';
import { setMergeClientDetailsFromAPI } from '../../../../reducers/client';
// import './styles.css';

const ClientsV2Details = ({
  setAssignPopup,
  setDrawerToggle,
  toggleDrawer,
  chatIconColor,
  type,
  client,
  auth,
  paymentBanner,
  activityList,
  associateRefObj,
  getClientData,
}) => {
  const [viewAll, setViewAll] = useState(false);
  const [viewAllType, setViewAllType] = useState('');
  const [dismissPopup, setDismissPopup] = useState(false);
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [allAssoc, setAllAssoc] = useState([]);
  const [associate, setAssociate] = useState(0);
  const [reviewLink, setReviewLink] = useState('');
  const [selectedSalesOpp, setSelectedSalesOpp] = useState({});
  const { setValue, getValue: getSearchParam } = useSearchParams();
  const clientTabParams = getSearchParam('clientTab');

  const handleViewAll = useCallback(value => {
    if (value && value !== '') {
      setValue('viewAllTab', value);
    } else {
      setValue('viewAllTab', '');
    }
  }, []);

  const showMessageHandler = async reminder => {
    setMsgClient({
      ...client,
      ...reminder,
    });
    getClientPaymentStatus({ client, auth });
    await getClientChat(client);
    setShowPopConfirm(true);
  };

  function closeChat () {
    setShowPopConfirm(false);
    // resetMessaging(); I removed this because at the safari we are getting an 404 page error
    resetAutoReminderId();
  };

  useEffect(() => {
    if (auth.merchantId) {
      const reqObj = {
        params: ['report', auth.merchantId, auth.storeId || 0, 'clientByAction'],
        query: {
          userId: auth.userId,
          role: auth.role,
        },
      };

      const assoc_data = async () => {
        const data = await getRequest(reqObj);
        const sortedAscts = data?.sort((a, b) => {
          if (`${a.first_name || ''}${a.last_name || ''}` > `${b.first_name || ''}${b.last_name}`) {
            return 1;
          }
          if (`${a.first_name || ''}${a.last_name || ''}` < `${b.first_name || ''}${b.last_name}`) {
            return -1;
          }
          return 0;
        });
        setAllAssoc([...sortedAscts]);
        setAssociate(auth.userId);
      };

      assoc_data();
    }
  }, [auth.merchantId, auth.storeId]);

  const getReviewLink = useCallback(async () => {
    if (auth.selectedStore) {
      const reqObj = {
        params: ['chats', auth.selectedStore, 'getReviewLink'],
      };
      const linkData = await getRequest(reqObj);
      if (linkData.length > 0) {
        const { review_link } = linkData[0];
        setReviewLink(review_link);
      }
    } else {
      console.error('Unable to getReviewLink, NO store ID!');
    }
  }, [auth.selectedStore]);

  const { call } = useOnce(getReviewLink);

  useEffect(() => {
    call();
  }, [auth.selectedStore]);

  const { refetch: refetchReminders } = useQuery(['getReminders', client.client_id, auth.merchantId], async () => {
    const reqObj = {
      params: ['clients', auth.merchantId, client.client_id, 'getClientReminders'],
      query: {
        limit: 4,
      },
      loadingStatusOff: true,
    };
    const data = await getRequest(reqObj);
    if (data) {
      setMergeClientDetailsFromAPI({ reminders: data?.reminders });
      return data.reminders;
    } else {
      return [];
    }
  }, {
    enabled: !!client.client_id && !!auth.merchantId,
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const { refetch: refetchAutoMessages } = useQuery(['getAutoMessages', client.client_id, auth.merchantId], async () => {
    const reqObj = {
      params: ['clients', auth.merchantId, client.client_id, 'getClientAutoMessages'],
      query: {limit : 5},
    }
    const data = await getRequest(reqObj);
    if(data) {
      setMergeClientDetailsFromAPI({ autoMessages: data?.autoMessages });
      return data.autoMessages;
    } else {
      return []
    }
  }, {
    enabled: !!client.client_id && !!auth.merchantId,
    initialData: [],
    refetchOnWindowFocus: false,
  })

  const tabList = [
    {
      key: 'quick-view',
      label: (
        <span className="fw-500 fs-14 d-inline-block" style={{ padding: '8px 0px' }}>
          Quick View
        </span>
      ),
      children: (
        <QuickView
          setViewAllType={handleViewAll}
          client={client}
          auth={auth}
          showPopConfirm={showPopConfirm}
          closeChat={closeChat}
          showMessageHandler={showMessageHandler}
          setDismissPopup={setDismissPopup}
          dismissPopup={dismissPopup}
          allAssoc={allAssoc}
          setAllAssoc={setAllAssoc}
          associate={associate}
          setAssociate={setAssociate}
          reviewLink={reviewLink}
          setSelectedSalesOpp={setSelectedSalesOpp}
          getClientData={getClientData}
          refetchReminders={refetchReminders}
          refetchAutoMessages={refetchAutoMessages}
        />
      ),
    },
    {
      key: 'activity',
      label: (
        <span className="fw-500 fs-14 d-inline-block" style={{ padding: '8px 0px' }}>
          Activity
        </span>
      ),
      children: (
        <Activity
          setViewAllType={handleViewAll}
          associateRefObj={associateRefObj}
          client={client}
          activityList={activityList}
          auth={auth}
          refetchReminders={refetchReminders}
          refetchAutoMessages={refetchAutoMessages}
        />
      ),
    },
    {
      key: 'collections',
      label: (
        <span className="fw-500 fs-14 d-inline-block" style={{ padding: '8px 0px' }}>
          Collections
        </span>
      ),
      children: <Collections setViewAllType={handleViewAll} client={client} auth={auth} />,
    },
  ];

  // set default tab
  useEffect(() => {
    const isCollectionId = getSearchParam('collectionId');
    const activityFilter = getSearchParam('activityFilter');
    const oppId = getSearchParam('oppId');
    const oppLocation = getSearchParam('oppLocation');
    if (isCollectionId) {
      setValue('clientTab', 'collections');
    } else if (activityFilter) {
      setValue('clientTab', 'activity');
    } else if (oppId) {
      setValue('clientTab', 'quick-view');
      setSelectedSalesOpp({ id: oppId, oppLocation: oppLocation || 'quickViewSalesOpp' });
      setViewAll(true);
      setViewAllType('salesOpportunityCardFromQV');
    } else if (!getSearchParam('clientTab')) {
      setValue('clientTab', 'quick-view');
      setValue('collectionId', '');
      setValue('activityFilter', '');
    }
  }, []);

  const oppId = getSearchParam('oppId');
  const oppLocation = getSearchParam('oppLocation');
  const viewAllTab = getSearchParam('viewAllTab');
  useEffect(() => {
    if (oppId) {
      setSelectedSalesOpp({ id: oppId, oppLocation: oppLocation || 'quickViewSalesOpp' });
      setViewAll(true);
      setViewAllType('salesOpportunityCardFromQV');
    } else if (viewAllTab) {
      setViewAll(true);
      setViewAllType(viewAllTab);
    } else {
      setViewAll(false);
      setViewAllType('');
    }
  }, [oppId, oppLocation, viewAllTab]);

  // clean params if clientId is null
  useEffect(() => {
    if (!getSearchParam('client')) {
      setValue('clientTab', 'quick-view');
      setValue('collectionId', '');
    }
  }, []);

  const selectViewAll = allType => {
    switch (allType) {
    case 'allAutoMessages':
      return (
          <AllClientAutoMessages
            setViewAllType={handleViewAll}
            autoMessages={client?.autoMessages}
            client_name={client?.preferred_name || client?.name}
            associate_name={`${auth?.firstName || ''} ${auth?.lastName || ''}`}
            review_link={reviewLink}
          />
      );
    case 'allPaymentRequests':
      return <AllClientPaymentRequests setViewAllType={handleViewAll} />;
    case 'allSalesOpportunities':
      return (
          <AllClientSalesOpportunities
            setViewAllType={handleViewAll}
            client={client}
            auth={auth}
            setSelectedSalesOpp={setSelectedSalesOpp}
          />
      );
    case 'allReminders':
      return (
          <AllClientReminders
            setViewAllType={handleViewAll}
            client={client}
            auth={auth}
            setDismissPopup={setDismissPopup}
            dismissPopup={dismissPopup}
            allAssoc={allAssoc}
            setAllAssoc={setAllAssoc}
            associate={associate}
            setAssociate={setAssociate}
            showPopConfirm={showPopConfirm}
            closeChat={closeChat}
            showMessageHandler={showMessageHandler}
          />
      );
    case 'salesOpportunityCardFromQV':
      return (
          <OpportunityDetails
            selectedSalesOpp={selectedSalesOpp}
            setViewAllType={handleViewAll}
            client={client}
            auth={auth}
          />
      );
    default:
      // code block
    }
  };

  const handleChangeTab = key => {
    setValue('clientTab', key);
    setValue('collectionId', '');
  };

  return client.id ? (
    <div>
      {viewAll ? (
        selectViewAll(viewAllType)
      ) : (
        <>
          <ClientHeader
            setAssignPopup={setAssignPopup}
            setDrawerToggle={setDrawerToggle}
            toggleDrawer={toggleDrawer}
            chatIconColor={chatIconColor}
            type={type}
            showMessageHandler={showMessageHandler}
            closeChat={closeChat}
            showPopConfirm={showPopConfirm}
            setShowPopConfirm={setShowPopConfirm}
          />
          <div
            className="flex-col-left p-btm-7 cd-tabs"
            style={{
              flex: 1,
              overflow: 'hidden',
              alignItems: 'stretch',
              maxHeight: `calc(100vh - ${paymentBanner ? '215' : '150'}px)`,
            }}
          >
            <Tabs
              centered
              size="large"
              tabBarStyle={{ marginTop: '20px' }}
              type="card"
              items={tabList}
              style={{ maxHeight: `calc(100vh - ${paymentBanner ? '220' : '155'}px)` }}
              defaultActiveKey="quick-view"
              onChange={handleChangeTab}
              activeKey={clientTabParams || 'quick-view'}
            />
          </div>
        </>
      )}
    </div>
  ) : (
    <div />
  );
};
export default ClientsV2Details;
