import React from 'react';
import SecondaryPanel from '../../../../../../Layout/SecondaryPanel';
import StandardLayout from '../../../../../../Layout/StandardLayout';
import { connect } from 'react-redux';
import ReminderMessageTemplates from '../../../../../../Layout/AutoReminders/AutoReminderSettings/ReminderMessageTemplates';

const TagMsgTemplate = props => {
  const listItemId = props.match.params.listItemId;
  const reminderId = props.match.params.reminderId;

  return (
    <StandardLayout
      title={`${props.msgTempAddOrEdit} Message Template for Auto Reminder`}
      description={'Create message templates that appear with the auto reminder'}
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Tags', to: '/Configuration/tags' },
        { title: 'Edit Tag', to: `/Configuration/tags/edit-tag/${listItemId}` },
        {
          title: `Auto Reminder`,
          to: `/Configuration/tags/edit-tag/${listItemId}/edit-auto-reminder/${reminderId}`,
        },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <ReminderMessageTemplates
        popConfirmDesc={'This will remove the template from this Reminder'}
        cancelRoute={`/Configuration/tags/edit-tag/${listItemId}/edit-auto-reminder/${reminderId}`}
        type={'tags'} //// REMEMBER THIS
        match={props.match}
      />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  ...state.autoRemMsgs,
});
export default connect(mapStateToProps, {})(TagMsgTemplate);
