import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './CBRoute';
// import Home from '../../components/Routes/Home';
import Home from '../../components/Routes/HomeDashboardMetrics';

import ContactSupport from '../../components/Routes/Help/ContactSupport';
import TrainingVideos from '../../components/Routes/Help/TrainingVideos';
import Login from '../../components/Routes/Login/MainLogin';
import ConsoleLogin from '../../components/Routes/Login/ConsoleLogin';
import ResetPassword from '../../components/Routes/Login/ResetPassword';
import ServiceLogin from '../../components/Routes/Login/ServiceLogin';


// Today
import Today from '../../components/Routes/Today';
import TodayAllReminders from '../../components/Routes/Today/Reminders';
import TodayAllAllSalesOpps from '../../components/Routes/Today/SalesOpp';
import TodayAllPayments from '../../components/Routes/Today/Payments';

// Payment Activity
import PaymentActivity from '../../components/Routes/Payments/Activity';
import PaymentDetails from '../../components/Routes/Payments/Details';

// products
import Products from '../../components/Routes/Products';

// clients
import Clients from '../../components/Routes/ClientsV2';

// Edit Profile
import EditProfile from '../../components/Routes/EditProfile';
import ChangePassword from '../../components/Routes/EditProfile/ChangePassword';

// Messaging
import Messaging from '../../components/Routes/Messaging';
import WhatsApp from '../../components/Routes/Messaging/WhatsApp';
import ChatInbox from '../../components/Routes/Messaging/ChatInbox';
import NotFound from '../../components/Routes/NotFound';
import FeatureSuggestion from "../../components/Routes/Help/FeatureSuggestion";
import AddEditProducts from '../../components/Routes/Products/AddEditProduct';
import ProductPage from '../../components/Routes/Products/ProductPage';
import ClientRedirect from '../../components/Routes/ClientsV2/ClientRedirect';

export default () => (
  <Switch>
    <Route path="/Home" exact component={Home} displayName="Home" />
    <Route path="/Login" exact component={Login} displayName="Login" />
    <Route path="/Login/reset-password" component={ResetPassword} displayName="ResetPassword" />
    <Route
      path="/Login/console-login"
      component={ConsoleLogin}
      displayName="ConsoleLogin"
    />
    <Route path="/service-login/:serviceType/:serviceId" component={ServiceLogin} />
    {/* Payment Activity */}
    <Route path="/Payments/activity" exact component={PaymentActivity} displayName="PaymentActivity" />
    <Route path="/Payments/details/:id" exact component={PaymentDetails} displayName="PaymentDetails" />
    {/* Clients */}
    <Route
      path="/Clients/client-details/:clientId/opp-details/:oppId"
      exact
      component={ClientRedirect}
      displayName="OpportunityDetails"
    />
    <Route
      path="/Clients/client-details/:clientId/reminders"
      exact
      component={ClientRedirect}
      displayName="ViewAllReminders"
    />
    <Route
      path="/Clients/client-details/:clientId/salesopps"
      exact
      component={ClientRedirect}
      displayName="ViewAllSalesOpps"
    />
    <Route
      path="/Clients/client-details/:clientId/activities"
      exact
      component={ClientRedirect}
      displayName="ViewAllActivities"
    />
    <Route
      path="/Clients/client-details/:clientId/client-purchases"
      exact
      component={ClientRedirect}
      displayName="ViewAllClientPurchases"
    />
    <Route
      path="/Clients/client-details/:clientId/collection-details/:collectionId"
      exact
      component={ClientRedirect}
      displayName="ViewAllCollections"
    />
    <Route path="/Clients/client-details/:clientId" component={ClientRedirect} displayName="ClientDetails" />
    <Route path="/Clients/edit-client-tags" component={ClientRedirect} displayName="EditClientTags" />
    <Route path="/Clients/edit-client-associates" component={ClientRedirect} displayName="EditClientAssociates" />
    <Route path="/Clients/add-client" exact component={ClientRedirect} displayName="AddEditClient" />
    <Route path="/Clients/edit-client-info/:clientId" exact component={ClientRedirect} displayName="AddEditClient" />
    <Route path="/Clients" exact component={Clients} displayName="Clients" />

    {/* Edit Profile */}
    <Route path="/EditProfile" exact component={EditProfile} displayName="EditProfile" />
    <Route path="/EditProfile/change-password" exact component={ChangePassword} displayName="ChangePassword" />
    {/* Help */}
    <Route path="/Help/training-videos" exact component={TrainingVideos} displayName="TrainingVideos" />
    <Route path="/Help/contact-support" exact component={ContactSupport} displayName="ContactSupport" />
    <Route path="/Help/feature-suggestion" exact component={FeatureSuggestion} displayName="FeatureSuggestion" />
    {/* Products */}
    <Route path="/Products" exact component={Products} displayName="Products" />
    <Route path="/Products/add-product" exact component={AddEditProducts} displayName="Products"/>
    <Route path="/Products/edit-product/:productId" exact component={AddEditProducts} displayName="Products"/>
    <Route path="/Products/:productId" exact component={ProductPage} displayName="Product Details" />
    {/* Today */}
    <Route path="/" exact component={Today} displayName="Today" />
    <Route path="/all-reminders" exact component={TodayAllReminders} displayName="TodayAllReminders" />
    <Route path="/all-sales-opp" exact component={TodayAllAllSalesOpps} displayName="TodayAllAllSalesOpps" />
    <Route path="/all-payments" exact component={TodayAllPayments} displayName="TodayAllPayments" />
    {/* Messaging */}
    <Route path="/Messaging" exact component={Messaging} displayName="Messaging" />
    <Route path="/Messaging/WhatsApp" exact component={WhatsApp} displayName="WhatsApp" />
    <Route path="/Messaging/inbox" exact component={ChatInbox} displayName="Inbox" />
  </Switch>
);
