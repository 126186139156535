import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Menu } from 'antd-v5';
import { CloudDownloadOutlined, ControlOutlined, DollarCircleOutlined, MessageOutlined, ShoppingOutlined, TeamOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router';


const SideBarV2 = ({ auth }) => {

  const [width, setWidth] = useState(window.innerWidth)
  const responsiveMenuItem = useMemo(() => width < 950, [width])

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const history = useHistory();
  const items = useMemo(() => [
    {
      key: 'setup',
      label: 'Setup',
      icon: <ControlOutlined />,
      children: [
        {
          key: 'integrations',
          label: 'Integrations',
          path: '/Help/integrations',
          hidden: auth?.role !== 'ADMIN'
        },
        {
          key: 'stores',
          label: 'Stores',
          path: '/Stores',
          hidden: auth?.role === 'ASSOCIATE'
        },
        {
          key: 'associates',
          label: 'Associates',
          path: '/Associates',
          hidden: auth?.role === 'ASSOCIATE'
        },
        {
          key: 'settings',
          label: 'General Settings',
          path: '/Configuration/app-settings',
          hidden: auth?.role !== 'ADMIN'
        }
      ],
    },
    {
      key: 'messaging',
      label: 'Messaging',
      icon: <MessageOutlined />,
      children: [
        {
          key: 'templates',
          label: 'Templates',
          path: '/Messaging/all-templates',
          hidden: auth?.role === 'ASSOCIATE'
        },
        {
          key: 'webchat',
          label: 'Webchat',
          path: '/Help/web-chat-setup',
          hidden: auth?.role !== 'ADMIN'
        },
        {
          key: 'google-review',
          label: 'Google Review',
          path: '/Help/integrations/google',
          hidden: !auth.launchFlagsForNav?.googleIntegration || auth?.role !== 'ADMIN'
        },
        {
          key: 'facebook-messenger',
          label: 'Facebook Messenger',
          path: '/Help/integrations/facebook',
          hidden: !auth.launchFlagsForNav?.facebookIntegration || auth?.role !== 'ADMIN',
        },
        {
          key: 'msg-settings',
          label: 'Settings',
          path: '/Configuration/message-settings',
          hidden: auth?.role !== 'ADMIN'
        },
      ],
    },
    {
      key: 'clients',
      label: 'Clients',
      icon: <TeamOutlined />,
        hidden: auth?.role !== 'ADMIN',
      children: [
        {
          key: 'custom-fields',
          label: 'Custom Fields',
          path: '/Configuration/client-preferences',
        },
        {
          key: 'relationship-type',
          label: 'Relationship Type',
          path: '/Configuration/relationship-types',
        },
        {
          key: 'life-events',
          label: 'Life Events',
          path: '/Configuration/life-events',
        },
        {
          key: 'tags',
          label: 'Tags',
          path: '/Configuration/tags',
        },
        {
          key: 'kiosk',
          label: 'Kiosk',
          path: '/Configuration/kiosk-settings',
        },
      ],
    },
    {
      key: 'sales',
      label: 'Sales',
      icon: <ShoppingOutlined />,
      hidden: auth?.role !== 'ADMIN',
      children: [
        {
          key: 'sales-triggers',
          label: 'Sales Triggers',
          path: '/Configuration/sales-triggers',
          hidden: !auth.pos_type
        },
        {
          key: 'sales-opportunities',
          label: 'Sales Opportunities',
          path: '/Configuration/sales-pipelines'
        },
        {
          key: 'client-activity',
          label: 'Client Activity',
          path: '/Configuration/client-activity'
        },
      ],
    },
    {
      key: 'payments',
      label: 'Payments Links',
      icon: <DollarCircleOutlined />,
      hidden: auth?.role !== 'ADMIN',
      children: [
        {
          key: 'bank-account',
          label: 'Bank Account',
          path: '/BankAccount/list'
        },
        {
          key: 'payment-settings',
          label: 'Payment Settings',
          path: '/Configuration/payment-settings'
        },
      ],
    },
    {
      key: 'data',
      label: 'Data',
      icon: <CloudDownloadOutlined />,
      hidden: auth?.role !== 'ADMIN',
      children: [
        {
          key: 'upload',
          label: 'Upload',
          path: '/Configuration/upload-data'
        },
        {
          key: 'download',
          label: 'Download',
          path: '/Help/export-data'
        },
      ],
    }
  ], [auth]);

  const selectedKeys = useMemo(() => {

    const { pathname } = window.location;
    let key = ['', ''];
    items.forEach(item => {
      item.children.forEach(child => {
        if (child.path === pathname) {
          key = [item.key, child.key];
        }
      });
    });
    return key;
  }, [items, window.location.pathname]);

  const handleClick = useCallback((e) => {
    const item = items.find(item => item.key === e.keyPath[1]);
    if (!item) return;
    if (item?.children) {
      const path = item.children.find(child => child.key === e.key);
      if (path) {
        history.push(path.path);
      }
    } else {
      history.push(item.path);
    }
    return;
  }, [items]);

  return (
    <div
      className="w-200 flex-row"
      style={{
        minWidth: responsiveMenuItem ? '56px' : '200px',
        borderInlineEnd: '1px solid rgba(5, 5, 5, 0.06);',
        maxHeight: '100vh',
        paddingBottom: '120px',
        boxSizing: 'border-box',
      }}
    >
      <Menu
        mode="inline"
        items={items}
        defaultOpenKeys={[selectedKeys[0]]}
        className='h-100-P scrollbar-y'
        style={{ marginBottom: '60px', textAlign: 'left' }}
        onClick={handleClick}
        selectedKeys={[selectedKeys[1]]}
        inlineCollapsed={responsiveMenuItem}
      />
    </div>
  );
}



const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SideBarV2);
