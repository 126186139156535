import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import { getProducts, getProductsBrands, getProductsCategories, getProductsElasticSearch } from '../../../api/products';
import usePaginatedRequest from '../../../hooks/usePaginatedRequest';
import useProductDetails from '../../../hooks/useProductDetails';
import ChecklistDropdown from '../../CustomizedAntDesign/ChecklistDropdown';
import PaginationV2 from '../../CustomizedAntDesign/PaginationV2';
import SearchBar from '../../CustomizedAntDesign/SearchBar';
import Tags from '../../CustomizedAntDesign/Tags';
import FullScreenModal from '../FullScreenModal';
import ProductsGridTable from './components/GridTable';
import ProductsListTable from './components/ListTable';
import StockFilters, { FILTERS } from './components/StockFilters';
import { Link } from 'react-router-dom';

import ProductDetailsBody from '../../Routes/ProductDetails/components';
import './styles.css';
import FullScreenModalTitleWithBack from '../FullScreenModal/FullScreenModalTitleWithBack';
import IconAndTextButton from '../IconAndTextButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PaginationV3 from '../../CustomizedAntDesign/PaginationV3';

const PRODUCTS_LIMIT = 25;

const ProductOverview = ({
  toggleProductSelection,
  selectedProducts = new Set(), // has product_ids
  selectedAndDisabledProducts = new Set(), // has product_ids
  tableView = 'list',
  customToolbar,
  productDetailSelectIcon = ['fas', 'plus'],
  productDetailsSelectText = 'Select',
  auth,
  onProductDetail,
}) => {
  const [stockFilters, setStockFilters] = useState(FILTERS.in_stock);

  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showBrandDropdown, setShowBrandDropdown] = useState(false);
  const [showCategoriesDropdown, setShowCategoriesDropdown] = useState(false);

  const {
    page,
    pageSize: limit,
    total,
    results: products,
    search,
    setPage,
    setPageSize: setLimit,
    setSearch,
    setFilters,
  } = usePaginatedRequest({
    defaultFilters: {
      qty: {
        $notNull: true,
        $gt: 0,
      }
    },
    limit: PRODUCTS_LIMIT, // I dont think this is being used - but Im not 100 percent sure
    defaultPageSize: PRODUCTS_LIMIT,
    requestMethod: getProductsElasticSearch,
  });

  const [productDetailModal, setProductDetailModal] = useState({
    open: false,
    productId: -1,
  });

  const getBrandFilters = () => {
    const brandFilters = {};

    if (brands.length) {
      brandFilters.brand = {
        $in: brands,
      };
    }

    return brandFilters;
  };

  const getCategoryFilters = () => {
    const categoryFilters = {};

    if (categories.length) {
      categoryFilters.category = {
        $in: categories,
      };
    }

    return categoryFilters;
  };

  const handleSetFilters = () => {
    const brandFilters = getBrandFilters();
    const categoryFilters = getCategoryFilters();

    setFilters({
      ...stockFilters,
      ...brandFilters,
      ...categoryFilters,
    });
  };

  const handleCloseTag = ({ group, value }) => {
    if (group === 'brands') {
      const filteredBrands = brands.filter(e => e !== value);
      setBrands(filteredBrands);
    }

    if (group === 'categories') {
      const filteredCategories = categories.filter(e => e !== value);
      setCategories(filteredCategories);
    }
  };

  useEffect(() => {
    handleSetFilters();
  }, [stockFilters, brands, categories]);

  useEffect(() => {
    if (showBrandDropdown && showCategoriesDropdown) {
      setShowCategoriesDropdown(false);
    }
  }, [showBrandDropdown]);

  useEffect(() => {
    if (showCategoriesDropdown && showBrandDropdown) {
      setShowBrandDropdown(false);
    }
  }, [showCategoriesDropdown]);

  const product = useProductDetails(productDetailModal.productId);
  const onProductDetailClick = useCallback(productId => {
    setProductDetailModal({ open: true, productId });
  });
  const onCloseProductDetail = useCallback(() => setProductDetailModal(d => ({ ...d, open: false })));

  return (
    <div className="w-100-P h-100-P">
      <FullScreenModal open={productDetailModal.open}>
        <Row justify="space-between" style={{ marginBottom: 16 }}>
          <Col>
            <FullScreenModalTitleWithBack onBack={onCloseProductDetail}>Product Details</FullScreenModalTitleWithBack>
          </Col>
          <Col>
            {selectedAndDisabledProducts.has(productDetailModal.productId) ||
              (selectedProducts.has(productDetailModal.productId) && (
                <IconAndTextButton
                  icon={productDetailSelectIcon}
                  onClick={() => {
                    toggleProductSelection(productDetailModal.productId);
                    onCloseProductDetail();
                  }}
                  disabled={
                    selectedAndDisabledProducts.has(productDetailModal.productId) ||
                    selectedProducts.has(productDetailModal.productId)
                  }
                >
                  {productDetailsSelectText}
                </IconAndTextButton>
              ))}
          </Col>
        </Row>
        <ProductDetailsBody product={product} />
      </FullScreenModal>
      {(!window.location.href.includes('clientTab=collections') && !window.location.href.includes('Clients')) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: '12px',
          }}
        >
          <Link
            id="add-client-button"
            style={{ color: '#1769AF', fontWeight: 600, fontSize: 13, cursor: 'pointer', gap: 8 }}
            role="button"
            tabIndex={0}
            to="/Products/add-product"
            className="flex-row-start-center m-btm-5"
          >
            <ProductAddIcon />
            Add Product
          </Link>
        </div>
      )}
      <SearchBar value={search} onChange={setSearch} placeholder="Search Products…" />
      <div className="cb-products-page__table-options">
        <div className="cb-products-page__table-filters">
          <StockFilters white={!!toggleProductSelection} value={stockFilters} onChange={setStockFilters} />

          <ChecklistDropdown
            label="Brands"
            actionButtonLabel="Add Filter"
            visible={showBrandDropdown}
            value={brands}
            onChange={setBrands}
            setVisible={setShowBrandDropdown}
            requestMethod={getProductsBrands}
          />

          {auth.pos_type === null ? null : (
            <ChecklistDropdown
              label="Categories"
              actionButtonLabel="Add Filter"
              visible={showCategoriesDropdown}
              value={categories}
              onChange={setCategories}
              setVisible={setShowCategoriesDropdown}
              requestMethod={getProductsCategories}
            />
          )}
        </div>

        <div className="cb-products-page__table-actions">{customToolbar}</div>
      </div>
      <div style={{ display: 'flex' }}>
        <Tags items={{ brands, categories }} onClose={handleCloseTag} closable />
      </div>
      <div style={{ display: 'flex', borderBottom: '1px solid #BDBDBD', marginBottom: '16px' }}>
        {tableView === 'list' ? (
          <ProductsListTable
            selectedAndDisabledProducts={selectedAndDisabledProducts}
            selectedProducts={selectedProducts}
            toggleProductSelection={toggleProductSelection}
            onProductDetailClick={onProductDetail ? onProductDetail : (item) => onProductDetailClick(item.id)}
            items={products}
          />
        ) : (
          <ProductsGridTable items={products} />
        )}
      </div>
      <div style={{ display: 'flex', display: productDetailModal.open ? 'none' : undefined }}>
        <PaginationV3 page={page} pageSize={limit} total={total} setPage={setPage} setPageSize={setLimit} showLastPage={total < 10000} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(ProductOverview);

const ProductAddIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2315_576)">
      <path
        d="M9.11918 6.03972C9.11918 4.3636 7.75555 3 6.07946 3C4.40334 3 3.03974 4.3636 3.03974 6.03972H0.759949V13.2591C0.759949 14.3083 1.61052 15.1589 2.65977 15.1589H9.49914C10.5484 15.1589 11.399 14.3083 11.399 13.2591V6.03972H9.11918ZM6.07946 4.13989C7.12702 4.13989 7.97928 4.99216 7.97928 6.03972H4.17963C4.17963 4.99216 5.03189 4.13989 6.07946 4.13989ZM10.2591 13.2591C10.2591 13.6781 9.91817 14.019 9.49914 14.019H2.65977C2.24074 14.019 1.89984 13.6781 1.89984 13.2591V7.17961H3.03974V8.12953C3.03974 8.4443 3.29491 8.69947 3.60969 8.69947C3.92446 8.69947 4.17963 8.4443 4.17963 8.12953V7.17961H7.97928V8.12953C7.97928 8.4443 8.23445 8.69947 8.54923 8.69947C8.86401 8.69947 9.11918 8.4443 9.11918 8.12953V7.17961H10.2591V13.2591Z"
        fill="#1769AF"
      />
    </g>
    <path
      d="M15.5677 2.37749H13.6225V0.432272C13.6225 0.194522 13.428 0 13.1902 0H12.7579C12.5202 0 12.3257 0.194522 12.3257 0.432272V2.37749H10.3805C10.1427 2.37749 9.94818 2.57202 9.94818 2.80977V3.24204C9.94818 3.47979 10.1427 3.67431 10.3805 3.67431H12.3257V5.61953C12.3257 5.85728 12.5202 6.0518 12.7579 6.0518H13.1902C13.428 6.0518 13.6225 5.85728 13.6225 5.61953V3.67431H15.5677C15.8055 3.67431 16 3.47979 16 3.24204V2.80977C16 2.57202 15.8055 2.37749 15.5677 2.37749Z"
      fill="#1769AF"
    />
    <defs>
      <clipPath id="clip0_2315_576">
        <rect width="12.1589" height="12.1589" fill="white" transform="translate(0 3)" />
      </clipPath>
    </defs>
  </svg>
);
