import { dispatcher } from '../core/actions';

const initialState = {
  userId: '',
  authenticated: false,
  firstName: '',
  email: '',
  mobile: '',
  image: '',
  handle: '',
  role: '',
  jwtToken: '',
  lastName: '',
  loggedOut: false,
  merchantId: '',
  brandName: '',
  brandImage: '',
  storeId: 0,
  uuid: '',
  licenseKey: '',
  merchantConfig: {},
  merchantFlags: {},
  planCode: null,
  merchantPassword: '',
  messageLimit: '',
  iso_country_code: '',
  enabled_email: '',
  enabled_sms: '',
  enabled_whatsapp: '',
  pos_type: '',
  sync_clients: true,
  sync_products: true,
  sync_purchase_history: true,
  pos_clients_last_updated_date: null,
  pos_products_last_updated_date: null,
  pos_purchase_history_last_updated_date: null,
  license_count: null,
  pipelineEnabled: false,
  elasticSearchEnabled: false,
  webKioskMode: false,
  elasticSearchEnabledProducts: false,
  // stores this user has access to:
  stores: [],
  selectedStore: 0,
  survey: {},
  satisfaction: false,
  payments: 0,
  schedulingLink: '',
  allStoreConversationHistory: true, // merchantConfig
  declutteredInbox: false, // merchantConfig
  launchFlagsForNav: {},
  meta_integration: false,
  instagram_integration: false,
  edison_view_date: null,
  message_notifications: false,
  google_integration: false,
};

const loggedOutAccount = {
  userId: '',
  authenticated: false,
  firstName: '',
  email: '',
  mobile: '',
  image: '',
  handle: '',
  role: 'ASSOCIATE',
  jwtToken: '',
  lastName: '',
  loggedOut: false,
  merchantId: '',
  brandName: '',
  brandImage: '',
  storeId: '',
  uuid: '',
  licenseKey: '',
  merchantConfig: {},
  merchantPassword: '',
  planCode: null,
  merchantFlags: {},
  // messageLimit: '', //deprecated
  stores: [],
  selectedStore: 0,
  iso_country_code: '',
  enabled_email: '',
  enabled_sms: '',
  enabled_whatsapp: '',
  pos_type: '',
  sync_clients: true,
  sync_products: true,
  sync_purchase_history: true,
  pos_clients_last_updated_date: null,
  pos_products_last_updated_date: null,
  pos_purchase_history_last_updated_date: null,
  license_count: null,
  pipelineEnabled: false,
  elasticSearchEnabled: false,
  webKioskMode: false,
  elasticSearchEnabledProducts: false,
  survey: {},
  payments: 0,
  schedulingLink: '',
  allStoreConversationHistory: true, // merchantConfig
  declutteredInbox: false, // merchantConfig
  launchFlagsForNav: {},
  meta_integration: false,
  instagram_integration: false,
  edison_view_date: null,
  message_notifications: true,
  google_integration: false,
};

export default function reducer(state = initialState, action) {
  const state2 = state;
  switch (action.type) {
    case 'SET_AUTH':
      return { ...state2, authenticated: true, ...action.payload.account, jwtToken: action.payload.token };
    case 'SET_PLAN_FLAGS':
      return { ...state2, merchantFlags: action.payload };
    case 'SET_MERCHANT_ID':
      return { ...state2, merchantId: boolParse(action.payload) };
    case 'SET_AUTH_ROLE':
      return { ...state2, role: action.payload };
    case 'SET_AUTH_FIRSTNAME':
      return { ...state2, firstName: action.payload };
    case 'SET_AUTH_BRAND_NAME':
      return { ...state2, brandName: action.payload };
    case 'SET_AUTH_LASTNAME':
      return { ...state2, lastName: action.payload };
    case 'SET_AUTH_EMAIL':
      return { ...state2, email: action.payload };
    case 'SET_AUTH_MOBILE':
      return { ...state2, mobile: action.payload };
    case 'SET_AUTH_LICENSE_COUNT':
      return { ...state2, license_count: action.payload };
    case 'SET_AUTH_IMAGE':
      return { ...state2, image: action.payload };
    case 'SET_AUTH_STORE_ID_OBJ':
      return { ...state2, storeIdObj: action.payload };
    case 'SET_AUTH_STORE_ID':
      return { ...state2, storeId: action.payload };
    case 'SET_AUTH_SELECTED_STORE':
      return { ...state2, selectedStore: action.payload };
    case 'SET_AUTH_STORES':
      return { ...state2, stores: [...action.payload] };
    case 'SET_AUTH_UUID':
      return { ...state2, uuid: action.payload };
    case 'SET_AUTH_EDISON':
      return { ...state2, edison_view_date: action.payload };
    case 'SET_MESSAGE_NOTIFICATIONS':
      return { ...state2, message_notifications: action.payload };
    case 'LOGOUT':
      return { ...state2, loggedOut: true, ...loggedOutAccount };
    case 'SET_AUTH_FINALIZATION':
      return { ...state2, authFinalization: action.payload };
    case 'SET_ALL_STORE_CONVERSATION_HISTORY':
      return { ...state2, allStoreConversationHistory: boolParse(action.payload) };
    case 'SET_DECLUTTERED_INBOX':
      return { ...state2, merchantConfig: { ...state2.merchantConfig, DECLUTTERED_INBOX: boolParse(action.payload) } };
    case 'SET_META_INTEGRATION':
      return { ...state2, meta_integration: boolParse(action.payload) };
    case 'SET_INTAGRAM_INTEGRATION': {
      return { ...state2, instagram_integration: boolParse(action.payload) };
    }
    case 'SET_AUTH_ENABLED_EMAIL':
      return { ...state2, iso_country_code: action.enabled_email };
    case 'SET_AUTH_ENABLED_SMS':
      return { ...state2, iso_country_code: action.enabled_sms };
    case 'SET_AUTH_ENABLED_WHATSAPP':
      return { ...state2, iso_country_code: action.enabled_whatsapp };
    case 'SET_SURVEY':
      return { ...state2, survey: action.payload };
    case 'SET_PAYMENTS':
      return { ...state2, payments: action.payload };
    case 'SET_POS_TYPE':
      return { ...state2, pos_type: action.payload };
    case 'SET_STORES':
      return { ...state2, stores: action.payload };
    case 'SET_LAUNCH_FLAGS_FOR_NAV':
      return { ...state2, launchFlagsForNav: action.payload };
    case 'SET_SYNC_CLIENTS':
      return { ...state2, sync_clients: action.payload };
    case 'SET_SYNC_PRODUCTS':
      return { ...state2, sync_products: action.payload };
    case 'SET_SYNC_PURCHASE_HISTORY':
      return { ...state2, sync_purchase_history: action.payload };
    case 'SET_POS_CLIENTS_LAST_UPDATED_DATE':
      return { ...state2, pos_clients_last_updated_date: action.payload };
    case 'SET_POS_PRODUCTS_LAST_UPDATED_DATE':
      return { ...state2, pos_products_last_updated_date: action.payload };
    case 'SET_POS_PURCHASE_HISTORY_LAST_UPDATED_DATE':
      return { ...state2, pos_purchase_history_last_updated_date: action.payload };
    case 'SET_SCHEDULING_LINK':
      return { ...state2, schedulingLink: action.payload };
    case 'SET_LIMIT_ASSOCIATE_VISIBILITY_ENABLED':
      return {
        ...state2,
        merchantConfig: { ...state2.merchantConfig, LIMIT_ASSOCIATE_VISIBILITY_ENABLED: action.payload },
      };
    case 'SET_HIDE_PRODUCT_COST':
      return { ...state2, merchantConfig: { ...state2.merchantConfig, HIDE_PRODUCT_COST: action.payload } };
    case 'SET_GOOGLE_INTEGRATION':
      return { ...state2, google_integration: action.payload };
    default:
      break;
  }
  return state2;
}

export const setPlanFlags = plan => {
  dispatcher('SET_PLAN_FLAGS', plan);
};

function boolParse(val) {
  if (val === 1 || val === '1' || val === 'true' || val === true) return true;
  return false;
}

export const wipeReduxState = () => {
  dispatcher('RESET_MSG_SETTINGS', null);
  dispatcher('RESET_MASS_MSG', null);
  dispatcher('RESET_MESSAGING', null);
  dispatcher('RESET_CUSTOM_ACTIONS', null);
  dispatcher('RESET_NAVIGATION', null);
};

export const setAuth = (account, token) => {
  dispatcher('SET_AUTH', { account, token });
};

export const setAuthFirstName = val => {
  dispatcher('SET_AUTH_FIRSTNAME', val);
};
export const setMerchantPosType = val => {
  dispatcher('SET_POS_TYPE', val);
};
export const setAuthBrandName = val => {
  dispatcher('SET_AUTH_BRAND_NAME', val);
};
export const setAuthLastName = val => {
  dispatcher('SET_AUTH_LASTNAME', val);
};
export const setAuthEmail = val => {
  dispatcher('SET_AUTH_EMAIL', val);
};
export const setAuthMobile = val => {
  dispatcher('SET_AUTH_MOBILE', val);
};
export const setAuthImage = val => {
  dispatcher('SET_AUTH_IMAGE', val);
};
export const setLimitAssociateVisibilityEnabled = val => {
  dispatcher('SET_LIMIT_ASSOCIATE_VISIBILITY_ENABLED', val);
};
export const setHideProductCost = val => {
  dispatcher('SET_HIDE_PRODUCT_COST', val);
};
export const setAuthLicenseCount = val => {
  dispatcher('SET_AUTH_LICENSE_COUNT', val);
};
export const setAuthRole = val => {
  dispatcher('SET_AUTH_ROLE', val);
};
export const setAuthStoreId = val => {
  dispatcher('SET_AUTH_STORE_ID', val);
};
export const setAuthStoreIdObj = val => {
  dispatcher('SET_AUTH_STORE_ID_OBJ', val);
};
//
export const setAuthSelStore = val => {
  dispatcher('SET_AUTH_SELECTED_STORE', val);
};
export const setAuthStores = val => {
  dispatcher('SET_AUTH_STORES', val);
};
export const setAuthUUID = val => {
  dispatcher('SET_AUTH_UUID', val);
};
export const setAuthEdison = val => {
  dispatcher('SET_AUTH_EDISON', val);
};
export const setMessageNotifications = val => {
  dispatcher('SET_MESSAGE_NOTIFICATIONS', val);
};
export const setAuthFinalization = val => {
  dispatcher('SET_AUTH_FINALIZATION', val);
};
export const setISOcountryCode = val => {
  dispatcher('SET_ISO_COUNTRY_CODE', val);
};
export const setAuthEnabled_Email = val => {
  dispatcher('SET_AUTH_ENABLED_EMAIL', val);
};
export const setAuthEnabled_SMS = val => {
  dispatcher('SET_AUTH_ENABLED_SMS', val);
};
export const setAuthEnabled_WhatsApp = val => {
  dispatcher('SET_AUTH_ENABLED_WHATSAPP', val);
};
export const setAuthSurvey = val => {
  dispatcher('SET_SURVEY', val);
};
export const setAuthSchedulingLink = val => {
  dispatcher('SET_SCHEDULING_LINK', val);
};
export const setAuthGoogleIntegration = val => {
  dispatcher('SET_GOOGLE_INTEGRATION', val);
};
export const setAuthPayments = val => {
  dispatcher('SET_PAYMENTS', val);
};
export const setLaunchFlagsForNav = val => {
  dispatcher('SET_LAUNCH_FLAGS_FOR_NAV', val);
};
export const setAllStoreConversationHistory = val => {
  dispatcher('SET_ALL_STORE_CONVERSATION_HISTORY', val);
};
export const setDeclutteredInbox = val => {
  dispatcher('SET_DECLUTTERED_INBOX', val);
};
export const setMetaIntegration = val => {
  dispatcher('SET_META_INTEGRATION', val);
};
export const setInstagramIntegration = val => {
  dispatcher('SET_INTAGRAM_INTEGRATION', val);
};
