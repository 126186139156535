import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { components } from 'react-select';
import { Tooltip } from 'antd-v5';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import DropdownSelect from '../../../../../../Layout/DropdownSelect';
import PopConfirm from '../../../../../../Layout/PopConfirm';
import { deleteRequest, getRequest, postRequest } from '../../../../../../../core/apiRequests';
import StandardLayout from '../../../../../../Layout/StandardLayout';
import Chip from '../../../../../../Layout/Chip';
import IntegrationsInstagramPopConfirm from '../../Details/components/InstagramPopConfirm';
import { notificationError } from '../../../../../../../core/actions';
import { setInstagramIntegration } from '../../../../../../../reducers/auth';
import { useQuery } from 'react-query';

const IntegrationsInstagramSettings = ({ auth }) => {
  const history = useHistory();
  const installed = useMemo(() => auth.instagram_integration, [auth]);
  const instagramAuthUrl = useMemo(() => {

      const rootUrl = 'https://www.instagram.com/oauth/authorize';

      const options = {
        enable_fb_login: 0,
        force_authentication: 1,
        client_id: process.env.REACT_APP_INSTAGRAM_CLIENT_ID,
        redirect_uri: `${process.env.REACT_APP_CLIENTBOOK_API_URL}/api/v1/meta/oauth2/instagram`,
        state: auth.uuid,
        response_type: 'code',
        scope: 'instagram_business_basic,instagram_business_manage_messages'
      };

      const qs = new URLSearchParams(options);

      return `${rootUrl}?${qs}`;
    }, [auth.uuid]);

  const [showInstagramConfirmModal, setShowInstagramConfirmModal] = useState(false);
  const [optionsPages, setOptionsPages] = useState([]);
  const [values, setValues] = useState({});
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const [showUninstallConfirm, setShowUninstallConfirm] = useState(false);

  const handleAddAccount = useCallback(() => {
    setShowInstagramConfirmModal(true);

    setTimeout(() => {
      window.open(instagramAuthUrl, '_blank');
    }, 5000);
  }, []);

  const { data: pages, isFetching } = useQuery(['instagram-pages'], async () => {
    const reqObj = {
      params: ['integrations', 'instagram', 'pages'],
      loadingStatusOff: true,
    };
    return await getRequest(reqObj);
  }, {
    enabled: installed,
    refetchOnWindowFocus: false,
    initialData: []
  })

  useEffect(() => {
    let options = [
      {
        options: [
          {
            label: 'Unmapped',
            value: 'unmapped',
          },
        ],
      },
    ];
    if (pages?.length) {
      pages.forEach(page => {
        options.push({
          label: page?.name || '',
          value: page?.extRefId,
          disabled: page.storeId !== null,
        })
      });
    }
    options = [
      ...options,
      {
        label: (
          <button type="button" className="link-button">
            Add account
          </button>
        ),
        value: 'add-account',
      },
    ];
    setOptionsPages(options);
    const storesTemp = {};
    auth.stores.filter(st => st.id !== 0).forEach(store => {
      const findPage = pages.find(page => page.storeId === store.id);
      storesTemp[store.id] = findPage
        ? {
          label: findPage?.name || '',
          value: findPage?.extRefId,
          disabled: findPage.storeId !== null,
        }
        : null;
    });
    setValues(storesTemp);
  }, [pages, auth]);

  const handleChange = useCallback(
    (value, storeId) => {
      if (value.disabled) return;
      if (value.value === 'add-account') {
        handleAddAccount();
      } else if (values.hasOwnProperty(storeId)) {
        setValues(prevState => ({
          ...prevState,
          [storeId]: value,
        }));
      }
    },
    [values, handleAddAccount],
  );

  useEffect(() => {
    const updateOptions = [...optionsPages];
    const valuesTemp = [];
    Object.keys(values).forEach(key => {
      if (values[key]?.value && values[key]?.value !== 'unmapped' && values[key]?.value !== 'add-account') {
        valuesTemp.push(values[key]?.value);
      }
    });
    updateOptions.forEach((opt, index) => {
      if (opt.extRefId) {
        updateOptions[index].options = updateOptions[index].options.map(o => ({
          ...o,
          disabled: valuesTemp.includes(o.value),
        }));
      }
    });
    setOptionsPages(updateOptions);
  }, [values]);

  const handleSave = useCallback(async () => {
    const data = [];
    Object.keys(values).forEach(key => {
      data.push({
        storeId: key,
        extRefId: values[key]?.value || null,
      });
    });
    const reqObj = {
      params: ['integrations', 'instagram', 'pages'],
      data,
    };
    await postRequest(reqObj);
    setShowSubmitPopup(false);
  }, [values]);

  const handleSubmit = useCallback(() => {
    let countSynced = 0;
    Object.keys(values).forEach(key => {
      if (values[key]?.value && values[key]?.value !== 'unmapped' && values[key]?.value !== 'add-account') {
        countSynced += 1;
      }
    });
    if (countSynced !== auth.stores.filter(store => store.id !== 0).length) {
      setShowSubmitPopup(true);
    } else {
      handleSave();
    }
  }, [handleSave, values, auth.stores]);

  const handleUninstall = useCallback(async () => {
    try {
      const reqObj = {
        params: ['integrations', 'instagram', 'uninstall'],
      };
      await deleteRequest(reqObj);
      setInstagramIntegration(false);
      history.push('/Help/integrations');
    } catch (error) {
      console.log(error);
      notificationError('Error', 'Something went wrong. Please try again later.');
    }
  }, []);

  return installed ? (
    <div className="cb-integrations-settings">
      <StandardLayout
        title="Instagram Messenger"
        titleImage="https://dashboard-v2-images.s3.amazonaws.com/messenger.png"
        actions={<Chip>Installed</Chip>}
        backLink="/help/integrations"
        classProps="standard-width-with-sidebar"
      >
        <div className="cb-integrations-settings__body">
          <section className="cb-integrations-details__section">
            <h3 className="cb-integrations-details__title">Store Mapping</h3>
            <p className="cb-integrations-details-google__p">
              At least one store MUST be mapped in order to sync your data
            </p>
          </section>
          <section className="cb-integrations-details__section">
            <div className="cb-integrations-details-google__container">
              {isFetching ? (
                <div className="flex-col-center">
                  <FontAwesomeIcon
                    style={{ color: '#919191' }}
                    icon={['far', 'spinner-third']}
                    spin
                    className="fs-24 m-btm-16 d-grid-stack"
                  />
                  <p className="cb-integrations-details-google__p">Loading store info...</p>
                </div>
              ) : (
                <div className="flex-col-center-start h-100-P w-100-P">
                  <div style={{ gap: 16 }} className="flex-row-center-center w-100-P m-btm-16">
                    <div className="cb-integrations-details-google__items">Stores in Clientbook</div>
                    <div className="cb-integrations-details-google__items">Instagram Messenger</div>
                  </div>
                  {auth.stores
                    .filter(s => s.id !== 0)
                    .map(store => (
                      <div key={store.id} style={{ gap: 16 }} className="flex-row-center-center w-100-P m-btm-8">
                        <div className="cb-integrations-details-google__items">
                          {store.name}
                          <FontAwesomeIcon icon={['far', 'arrow-right']} />
                        </div>
                        <div className="cb-integrations-details-google__items">
                          <DropdownSelect
                            classProps="w-100-P"
                            placeholder="Unmapped"
                            componentProps={{ Option: optionsProps => CustomOption({ ...optionsProps }) }}
                            options={optionsPages}
                            onChange={val => handleChange(val, store.id)}
                            value={values[store.id]?.value || null}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </section>
          <section className="cb-integrations-details__section">
            <div className="w-100-P flex-col-center">
              <button type="button" className="save-button-v2 m-btm-8" disabled={isFetching} onClick={handleSubmit}>
                Submit
              </button>
              <button
                type="button"
                className="error-button m-btm-8"
                disabled={isFetching}
                onClick={() => setShowUninstallConfirm(true)}
              >
                Uninstall
              </button>
            </div>
          </section>
          <IntegrationsInstagramPopConfirm
            visible={showInstagramConfirmModal}
            authUrl={instagramAuthUrl}
            onClose={() => setShowInstagramConfirmModal(false)}
          />
          {showSubmitPopup && (
            <PopConfirm
              popStyle={{
                maxWidth: '540px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              confirm="Are you sure you want to proceed?"
              description="You have not chosen a mapping for each store location."
              onCancel={() => setShowSubmitPopup(false)}
              onConfirm={handleSave}
              descriptionStyle={{
                margin: '0 0 8px',
                textAlign: 'center',
                fontStyle: 'italic',
                fontWeight: 400,
                fontSize: '12px',
              }}
              confirmButtonText="Continue"
            >
              <div className="fs-13 fw-500">Doing so is not recommended and may lead to future data issues.</div>
            </PopConfirm>
          )}
        </div>
      </StandardLayout>
      {showUninstallConfirm && (
        <PopConfirm
          confirm="Are you sure you want to uninstall Instagram Messenger Integration?"
          onCancel={() => setShowUninstallConfirm(false)}
          onConfirm={handleUninstall}
        />
      )}
    </div>
  ) : (
    <Redirect to="/help/integrations" />
  );
};

const { Option } = components;

const CustomOption = ({ isDisabled, data, ...rest }) => {
  return !data?.disabled ? (
    <Option
      isDisabled={data?.disabled}
      {...rest}
      className={`${rest.selectProps.className} fw-500 fs-13 ${
        !['unmapped', 'add-account'].includes(rest.value) > 0 ? 'p-left-30-imp' : ''
      }`}
    />
  ) : (
    <Tooltip
      placement="bottomLeft"
      zIndex={99999999}
      title="This store is already mapped. Please unmap to use."
    >
      <div>
        <Option
          {...rest}
          isDisabled={data?.disabled}
          className={`${rest.selectProps.className} fw-500 fs-13 ${
            !['unmapped', 'add-account'].includes(rest.value) > 0 ? 'p-left-30-imp' : ''
          }`}
        />
      </div>
    </Tooltip>
  );
};

const mapStateToProps = state => ({
  uuid: state.auth.uuid,
  pos_type: state.auth.pos_type,
  auth: state.auth,
});

export default connect(mapStateToProps, {})(IntegrationsInstagramSettings);
