import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { wipeReduxState } from '../../../../reducers/auth';

const UserProfileMenu = props => {
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const full = `${props.firstName || ''} ${props.lastName || ''}`;
  const initials = `${full}`
    .split(' ')
    .filter((v, i, a) => i === 0 || i === a.length - 1)
    .map(word => word.substr(0, 1))
    .join(''); // initials icon

  useEffect(() => {
    if (props.showMenu) {
      document.addEventListener('click', e => {
        const scrollWatchEle = document?.getElementById('scrollWatchId');
        const chatInboxEle = document?.getElementById('ChatInboxId');
        if (scrollWatchEle?.contains(e.target) || chatInboxEle?.contains(e.target)) {
          props.setShowMenu(false);
        }
      });
    } else {
      document.removeEventListener('click', () => {});
    }
    return () => {
      document.removeEventListener('click', () => {});
    };
  }, [props.showMenu]);

  return (
    <div id="UserIconParentDiv" className="userMenuPic pointer m-top-5 pos-rel">
      {props.image == null ? (
        <div
          style={{
            objectFit: 'cover',
            paddingTop: '2px',
          }}
          className="h-40 w-40 circle bg-lightgray rem-pad-width m-top-3"
          onClick={() => {
            props.setShowMenu(!props.showMenu);
            props.setShowList('');
          }}
        >
          <span
            style={{
              fontSize: '20px',
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '4px',
            }}
            className="white"
          >
            {initials}
          </span>
        </div>
      ) : (
        <div id="UserIconDiv" className="h-45 w-46 circle pos-rel highlightPhoto">
          <img
            src={props.image}
            onClick={() => {
              props.setShowMenu(!props.showMenu);
              props.setShowList('');
            }}
            alt="#"
            tabIndex="-1"
            id="UserIconImage"
            className="h-38 w-38 circle photoFit pos-abs"
            style={{ outline: 'none', boxShadow: '0 0 0pt 2pt #231f20', top: '4px', right: '4px' }}
          />
        </div>
      )}
      {props.showMenu ? (
        <div id="profileDropDown_nav" className="profileDropDown_nav" style={{ zIndex: '102' }}>
          <ul className="">
            <li
              className="SPlink ul-no-style align-left"
              id="EditProfile"
              key="Edit Profile"
              onClick={() => props.setShowMenu(!props.showMenu)}
            >
              <Link
                className="dropDown-nav-item"
                style={{
                  textDecoration: 'none',
                  position: 'absolute',
                  top: '-4px',
                  display: 'inline-block',
                  width: '100%',
                }}
                to="/EditProfile"
              >
                Edit Profile
              </Link>
            </li>
            {props.role == 'ADMIN' ? (
              <li
                className="SPlink ul-no-style align-left"
                id="Settings"
                key="Settings"
                onClick={() => props.setShowMenu(!props.showMenu)}
              >
                <Link
                  className="dropDown-nav-item"
                  style={{ textDecoration: 'none', height: '48px', display: 'inline-block', width: '100%' }}
                  to="/Configuration/client-preferences"
                >
                  Settings
                </Link>
              </li>
            ) : null}
            <li
              className="SPlink ul-no-style align-left"
              id="Help"
              key="Help"
              onClick={() => props.setShowMenu(!props.showMenu)}
            >
              <Link
                className="dropDown-nav-item"
                style={{ textDecoration: 'none', height: '48px', display: 'inline-block', width: '100%' }}
                to="/Help/training-videos"
              >
                Help
              </Link>
            </li>
            {props.webKioskMode && (
              <li
                className="SPlink ul-no-style align-left"
                id="qrCode"
                key="qrCode"
                onClick={() => props.setShowQr(!props.showQr)}
              >
                Show QR
              </li>
            )}
            <li
              id="Logout"
              className="SPlink ul-no-style align-left "
              onClick={() => props.logout()}
              style={{ paddingBottom: '7px' }}
            >
              <p className="d-block left-0 dropDown-nav-item align-left" style={{ margin: '0px' }}>
                Logout
              </p>
            </li>
          </ul>
        </div>
      ) : (
        ''
      )}
      {/* {props.authenticated ? '' : <Redirect to="/login" />} */}
    </div>
  );
};
const mapStateToProps = state => state.auth;
const mapDispatchToProps = dispatch => ({
  logout() {
    dispatch({
      type: 'LOGOUT',
      payload: true,
    });
    wipeReduxState();
    localStorage.setItem('account', '');
    localStorage.setItem('jwtToken', '');
    localStorage.setItem('newNav', '');
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(UserProfileMenu);
