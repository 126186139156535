/* eslint-disable no-nested-ternary */
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import SecondaryPanel from '../../Layout/SecondaryPanel';
import InfoDrawer from '../../Layout/InfoDrawer';
import useSearchParams from '../../../hooks/useSearchParams';
import ClientsV2Details from './ClientV2Details';
import { clientDetailsEndpoints, detailsRequestHandler } from '../Clients/ClientDetails/clientDetailsAPI';
import {
  setClientAssig,
  setClientId,
  setClientPreferences,
  setClientPurchases,
  setClientTags,
  setMergeClientDetailsFromAPI,
  setName,
  setClearClient,
  setClearClientInfo,
} from '../../../reducers/client';
import { setLaunchFlagsForNav } from '../../../reducers/auth';
import { notificationError } from '../../../core/actions';
import { getRequest } from '../../../core/apiRequests';
import { useOnce } from '../../../hooks/useOnce';
import ClientList from './ClientList/index';

const ClientsV2 = props => {
  const { getValue: getSearchParam, setValue } = useSearchParams();
  const params = getSearchParam('client');

  const [associateRefObj, setAssociateRefObj] = useState({});
  const [notesEnabled, setNotesEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleDrawer, setDrawerToggle] = useState(false);
  const [assignPopup, setAssignPopup] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const [clientFilterOpen, setClientFilterOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const flags = useFlags();

  useEffect(() => {
    getClientData(params);
    if (params) {
      setDrawerToggle(true);
    } else {
      setDrawerToggle(false);
    }
  }, [params]);

  useEffect(() => {
    setLaunchFlagsForNav(flags);
  }, [flags?.projectEdison]);

  useEffect(() => {
    if (props.auth.merchantId && props.client.client_id) {
      (async () => {
        const reqObjClient = {
          params: ['clients', props.client.client_id, props.auth.merchantId, 'clientDetailsPreferences'],
        };
        const dataClient = await getRequest(reqObjClient);
        const reqObjMerchant = {
          params: ['clients', props.auth.merchantId, 0, 'clientPreferences'],
        };
        const data = await getRequest(reqObjMerchant);
        const formattedData = data[0]?.preferenceData?.map(item => {
          const prefClient = dataClient.find(i => i.config_id == item.config_id);
          if (prefClient) {
            return {
              ...item,
              value: prefClient.value,
              personalization_id: prefClient.personalization_id,
            };
          }
          return { ...item };
        });
        setClientPreferences(formattedData);
      })();
    }
  }, [props.auth.merchantId, props.client.client_id]);

  const getActivityList = async() => {
    const { merchantId } = props?.auth;
    setActivityList([]);
    const reqObj = {
      params: ['client', 'activityList', merchantId],
    };
    const data = await getRequest(reqObj);
    if (data) {
      setActivityList(data);
    } else {
      // notificationError('Error getting activity list');
    }
  };

  const getAssociateList = async() => {
    const { merchantId } = props?.auth;
    setActivityList([]);
    const reqObj = {
      params: ['associates', merchantId],
      query: {
        singleStore: 0
      }
    };
    const data = await getRequest(reqObj);
    if (Array.isArray(data) && data.length > 0) {
      let asctDataObj = {};
      data.forEach(asct => {
        if(asct.pos_ref_num) {
          asctDataObj[asct.pos_ref_num] = asct.fullName;
        }
      });
      setAssociateRefObj(asctDataObj);
    } else {
      console.warn('No associates found for mapping names from refnums');
    }
  }

  const { call: callGetActivityList } = useOnce(getActivityList);
  const { call: callGetAssociateList } = useOnce(getAssociateList)

  useEffect(() => {
    if(props.auth.merchantId) {
      callGetActivityList();
      callGetAssociateList();
    }
  }, [props.auth.merchantId]);

  const getClientPurchases = () => {
    const {
      auth: { merchantId },
      client: { client_id: clientId },
    } = props;
    if (merchantId && clientId) {
      (async () => {
        const reqObj = {
          params: ['client', merchantId, clientId, 'getAllClientPurchases'],
        };
        const data = await getRequest(reqObj);
        if (data) {
          setClientPurchases(data);
        } else {
          // notificationError('Error getting client purchases');
        }
      })();
    }
  };
  const { call: callGetClientPurchases } = useOnce(getClientPurchases);

  useEffect(() => {
    callGetClientPurchases();
  }, [props.auth.merchantId, props.client.client_id]);

  const clientDetailsAPI = async (clientId, merchantId) => {
    clientDetailsEndpoints.forEach(async eP => {
      const data = await detailsRequestHandler(clientId, merchantId, eP, eP === 'getClientRecentActivity' ? 9999 : 5);
      setMergeClientDetailsFromAPI({ ...data });
    });
  };

  const getClientData = useCallback(
    async clientId => {
      setLoading(true);
      const {
        auth: { merchantId },
      } = props;
      if (merchantId && clientId) {
        clientDetailsAPI(clientId, merchantId);
        (async () => {
          const reqObj = {
            params: ['clients', 'details', merchantId, clientId],
            query: { limit: 5 },
          };
          const data = await getRequest(reqObj);
          if (data.id) {
            const { id, name, associate_ref, count, relationships, allRelationships } = data;
            const mixedRelationshipObj = {};
            allRelationships?.forEach(rel => {
              mixedRelationshipObj[rel.id] = {
                originalId: null,
                client_id: null,
                client_name: null,
                related_client_name: null,
                relationship_type_id: rel.id,
                related_client_id: null,
                relationship: rel.name,
                relatedTo: false,
              };
              relationships.forEach(clientRel => {
                if (rel.id === clientRel.relationship_type_id) {
                  mixedRelationshipObj[rel.id] = {
                    originalId: clientRel.id,
                    client_id: clientRel.client_id,
                    client_name: clientRel.client_name,
                    related_client_name: clientRel.related_client_name,
                    relationship_type_id: clientRel.relationship_type_id,
                    related_client_id: clientRel.related_client_id,
                    relationship: clientRel.relationship,
                    relatedTo: id == clientRel.related_client_id,
                  };
                }
                if (!clientRel.relationship_type_id) {
                  mixedRelationshipObj[clientRel.id] = {
                    originalId: clientRel.id,
                    client_id: clientRel.client_id,
                    client_name: clientRel.client_name,
                    related_client_name: clientRel.related_client_name,
                    relationship_type_id: clientRel.relationship_type_id,
                    related_client_id: clientRel.related_client_id,
                    relationship: clientRel.relationship,
                    relatedTo: id == clientRel.related_client_id,
                    uniqueRel: true,
                  };
                }
              });
            });

            setMergeClientDetailsFromAPI({ ...data, mixedRelationships: mixedRelationshipObj });
            setClientId(id);
            setName(name);
            setClientAssig(associate_ref);
            setClientTags(count);
          } else {
            // handle error here
            notificationError('Error getting client details');
          }
        })();
        // Check to see if notes are enabled
        (async () => {
          const reqObj = {
            params: ['client', 'notes', merchantId],
          };
          const data = await getRequest(reqObj);
          if (data.length > 0 && data[0].config === 'true') {
            setNotesEnabled(true);
          } else {
            setNotesEnabled(false);
          }
        })();
        setLoading(false);
      } else {
        setClearClient();
        setClearClientInfo();
      }
    },
    [props.auth],
  );

  const panels = useMemo(() => ['7', '1', '2', '6', '8', '9'], []);

  const parentGridClassName = () => {
    let theClassName = 'three-column-parent-grid  rem-pad-width ';
    theClassName += 'three-column-parent-3-client';
    return theClassName;
  };

  const selectClient = useCallback(id => {
    setValue('client', id);
    setValue('clientTab', '');
    setValue('collectionId', '');
    setValue('activityFilter', '');
  }, []);

  const removeClientOfList = useCallback(
    id => {
      const newTableData = tableData.filter(client => client.id !== id);
      setTableData(newTableData);
    },
    [tableData],
  );

  return (
    <div
      id="ChatInboxId"
      className={`rem-pad-width`}
      style={
        clientFilterOpen
          ? { overflow: 'hidden', height: '100vh', scroll: 'none', paddingLeft: '350px',  width: '100%' }
          : { overflow: 'hidden', height: '100vh', scroll: 'none', width: '100%' }
      }
    >
      <div
        className={parentGridClassName()}
        style={{ height: `calc(100vh - ${props?.launchFlagsForNav?.newWebNavigation ? '102px' : '55px'})` }}
      >
        <ClientList
          getClientData={selectClient}
          client={props.client}
          filters={props.filters}
          pagination={props.pagination}
          auth={props.auth}
          setClientFilterOpen={setClientFilterOpen}
          clientFilterOpen={clientFilterOpen}
          tableData={tableData}
          setTableData={setTableData}
        />
        <ClientsV2Details
          setDrawerToggle={setDrawerToggle}
          toggleDrawer={toggleDrawer}
          setAssignPopup={setAssignPopup}
          chatIconColor="#479C83"
          type="full"
          client={props.client}
          auth={props.auth}
          associateRefObj={associateRefObj}
          paymentBanner={props.paymentBanner.active}
          activityList={activityList}
          getClientData={getClientData}
        />
        {toggleDrawer ? (
          <InfoDrawer
            chatIconColor="#479C83"
            panels={panels}
            client={props.client}
            location="clientDetails"
            removeClientOfList={removeClientOfList}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ClientsV2);
