import React, { useEffect } from 'react';
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContactSupport = () => {
  return (
    <Layout
      title="Support"
      description="Questions? Concerns? We are here to help!"
      breadcrumb="Help >> Support"
      breadCrumbs={[{ title: 'Help' }, { title: 'Support' }]}
      classProps={'standard-width-with-sidebar'}
    >
      <div className="maw-1200 flex-row-spacebetween-wrap">
        <div className="flex-row-spacebetween-wrap m-right-30">
          <div className="flex-col-left" style={{ marginBottom: '20px', width: '40%' }}>
            <div className="flex-row-nospacebetween-nowrap">
              <img className="m-right-15" style={{ marginTop: '12px' }} src="https://dashboard-v2-images.s3.amazonaws.com/letterHelp.svg" />
              <p className="align-left" style={{ marginBottom: '0px' }}>
                <a
                  id="EmailSupportButton"
                  className="blue-link no-decoration fw-600 fs-14 mw-150 align-left no-wrap"
                  href="mailto: support@clientbook.com"
                >
                  Email support@clientbook.com
                </a>
              </p>
            </div>
            <div style={{ height: '35px' }} className="flex-row-nospacebetween-nowrap">
              <p className="fs-14 gray align-left m-top-20">Email a question to our team</p>
            </div>
          </div>
          <div className="flex-col-left" style={{ marginBottom: '20px', width: '40%' }}>
            <div className="flex-row-nospacebetween-nowrap">
              <FontAwesomeIcon icon="fa-light fa-headset" style={{ marginTop: '12px', fontSize: 16 }} className="m-right-15 blue-link"/>
              <p className="align-left" style={{ marginBottom: '0px' }}>
                <a
                  id="HelpCenterButton"
                  className="blue-link no-decoration fw-600 fs-14 mw-150 align-left no-wrap"
                  href="https://clientbook.zendesk.com/hc/en-us"
                  target='_blank'
                >
                  Visit our Help Center
                </a>
              </p>
            </div>
            <div style={{ height: '35px' }} className="flex-row-nospacebetween-nowrap">
              <p className="fs-14 gray align-left m-top-20">Visit our help center to search for how-to articles</p>
            </div>
          </div>
          <div className="flex-col-left">
            <div className="flex-row-nospacebetween-nowrap">
              <img className="m-right-15" style={{ marginTop: '12px' }} src="https://dashboard-v2-images.s3.amazonaws.com/phoneHelp.svg" />
              <p className="align-left w-174" style={{ marginBottom: '0px', width: '40%' }}>
                <a href="tel:+13853523518" id="CallUsButton" className="blue-link no-decoration fw-600 fs-14 no-wrap">
                  Call us at (385) 352-3518
                </a>
              </p>
            </div>
            <div style={{ height: '35px' }} className="flex-row-nospacebetween-nowrap">
              <p className="fs-14 gray align-left m-top-20 miw-250">Talk with a member of our Support Team</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ContactSupport;
