import React from 'react';
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from "../../../Layout/SecondaryPanel";

const FeatureSuggestion = () => {
  return (
    <Layout
      title="Feature Suggestion"
      description="A critical piece of Clientbook is getting user feedback. Use the section below to submit your own ideas or vote on ideas by other retailers."
      breadcrumb="Help >> Feature Suggestions"
      breadCrumbs={[{ title: 'Help' }, { title: 'Feature Suggestion' }]}
      classProps={'standard-width-with-sidebar'}
    >
      <iframe src="https://portal.productboard.com/tgt3zkdeadun4xefiwz3bbdu" frameBorder="0" width={'100%'} height={'700px'}></iframe>
    </Layout>
  );
};
export default FeatureSuggestion;
