import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Table from '../../../Layout/Table';
import Button from '../../../Layout/Button';
import StyledLink from '../../../Layout/StyledLink';
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import { getTablePagList } from '../../../../core/apiRequests';
import { getTablePaginationData, resetTablePagination } from '../../../../core/actions';
import { setCliPrefAddEdit, setCliPrefId, setCliPrefName } from '../../../../reducers/clientPreference';
import SidePanel from '../../../Navigation/SidePanel';

const tableName = 'CLIENT_PREFERENCES';

const ClientPreferences = props => {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [first, setFirst] = useState(true);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    if (props.auth.merchantId) {
      const { page, pageSize, sortColumn, ascendingColumn, searchText } = props.pagination;
      let data;
      setTableData([]);
      if (first) {
        resetTablePagination({ sortColumn: 'name' }, tableName);
        data = async () => {
          const dataArr = await getTablePagList({
            tableName,
            type: 'personalizations',
            merchantId: props.auth.merchantId,
            sortColumn: 'name',
          });

          setTableData(dataArr);
        };
        setFirst(false);
      } else {
        data = async () => {
          const dataArr = await getTablePagList({
            tableName,
            type: 'personalizations',
            merchantId: props.auth.merchantId,
            page,
            pageSize,
            sortColumn,
            ascendingColumn,
            searchText,
          });
          setTableData(dataArr);
        };
      }
      data();
      return () => {
        isMounted.current = false;
      };
    }
  }, [
    props.auth.merchantId,
    props.pagination.sortColumn,
    props.pagination.pageSize,
    props.pagination.page,
    props.pagination.ascendingColumn,
    props.pagination.searchText,
  ]);

  const tableHeaders = [
    // this is a configuration array. It specifies how each column will be structured as well as the column header info
    {
      keyName: 'name',
      style: { maxWidth: '50%', minWidth: '120px' },
      rowClassName: 'col-p-l-25 darkBlue',
      headerClassName: 'col-p-l-25',
      title: 'Preference',
      sortable: true, // sort allows gives us the ability to sort the table
    },
    {
      keyName: 'created_date',
      style: { width: '8%', minWidth: '160px' },
      headerClassName: 'col-p-l-25 align-right rem-pad-width',
      rowClassName: 'align-center col-p-l-25 rem-pad-width',
      title: 'Created Date',
      sortable: true,
      render: (t, o) => {
        return moment(t, 'YYYYMMDD').format('MM/DD/YYYY');
      },
    },
    {
      keyName: 'created_by',
      style: { width: '8%', minWidth: '130px', paddingRight: '22px' },
      headerClassName: 'align-left rem-pad-width',
      rowClassName: 'align-left col-p-r-70 rem-pad-width no-wrap',
      title: 'Created by',
      render: t => {
        if (t) {
          if (t.length > 20) {
            return `${t.slice(0, 20)}...`;
          }
          return t;
        }
        return '';
      },
      sortable: true,
    },
  ];

  const rowClickHandler = data => {
    const { id, name } = data;
    setCliPrefId(id);
    setCliPrefName(name);
    setCliPrefAddEdit('Edit');
    history.push(`client-preferences/edit-preference/${id}`);
    // setRedirect(true);
  };

  const clearPref = () => {
    setCliPrefId('');
    setCliPrefName('');
    setCliPrefAddEdit('Add');
  };

  return (
    <Layout
      title="Client Preferences"
      description="Customize client preferences to further organize your clients"
      classProps="standard-width-with-sidebar rem-pad-width"
    >
      <Table
        tableClassName="lg-table-sidebar rem-pad-width"
        data={tableData}
        tableHeaders={tableHeaders}
        searchable
        paginationV2
        optionalButton={
          <StyledLink to="/Configuration/client-preferences/add-preference">
            <Button id="AddPreferenceButton" onclick={clearPref} classname="darkBlueButton newMessageButton">
              <FontAwesomeIcon
                style={{ width: '14px', height: '14px' }}
                icon={['far', 'ballot']}
                className="h-50-P"
                size="1x"
                color="white"
              />{' '}
              Add Preference
            </Button>
          </StyledLink>
        }
        onRowClick={rowClickHandler}
        tableName={tableName}
      />
      {/* {redirect ? (
        <Redirect to="/Configuration/client-preferences/preference" />
      ) : null} */}
      <div style={{ marginTop: '3px' }} />
    </Layout>
  );
};
const mapStateToProps = state => ({
  ...state,
  pagination: getTablePaginationData(state, tableName),
});
export default connect(mapStateToProps, {})(ClientPreferences);
