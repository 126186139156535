import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import SecondaryPanel from '../../../../../Layout/SecondaryPanel';
import StandardLayout from '../../../../../Layout/StandardLayout';
import IntegrationsActions from './components/Actions';
import IntegrationsKWI from './components/KWI';
import IntegrationsBig from './components/Big';
import IntegrationsTheEdge from './components/TheEdge';
import IntegrationsShopify from './components/Shopify';
import IntegrationsRain from './components/Rain';
import IntegrationsJewel360 from './components/Jewel360';
import IntegrationsMusicShop360 from './components/MusicShop360';
import IntegrationsLikeSew from './components/LikeSew';
import IntegrationsLightspeed from './components/Lightspeed';
import IntegrationsLightspeedX from './components/LightspeedX';
import IntegrationsKwiModal from './components/KwiModal';
import IntegrationsProfitSystems from './components/ProfitSystems';
import IntegrationsShopifyModal from './components/ShopifyModal';
import IntegrationsLightspeedPopConfirm from './components/LightspeedPopConfirm';
import IntegrationsGooglePopConfirm from './components/GooglePopConfirm';
import IntegrationsFacebookPopConfirm from './components/FacebookPopConfirm';
import IntegrationsInstagramPopConfirm from './components/InstagramPopConfirm';
import Button from '../../../../../CustomizedAntDesign/Button';
import './style.css';
import IntegrationsGoogle from './components/Google';
import GoogleButton from './components/GoogleButton';
import FacebookButton from './components/FacebookButton';
import IntegrationsFacebook from './components/Facebook';
import IntegrationsInstagram from './components/Instagram';
import InstagramButton from './components/InstagramButton';

const INTEGRATIONS = {
  lightspeed: {
    id: 'lightspeed',
    title: 'Lightspeed',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/lightspeed-logo.png',
    content: <IntegrationsLightspeed />,
    installable: true,
    cover: 'https://dashboard-v2-images.s3.amazonaws.com/integrations-cover.png',
  },
  lightspeedx: {
    id: 'lightspeedx',
    title: 'LightspeedX',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/lightspeed-logo.png',
    content: <IntegrationsLightspeedX />,
    installable: true,
    cover: 'https://dashboard-v2-images.s3.amazonaws.com/integrations-cover.png',
  },
  big: {
    id: 'big',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/big-logo.png',
    content: <IntegrationsBig />,
    installable: false,
    cover: 'https://dashboard-v2-images.s3.amazonaws.com/integrations-cover.png',
  },
  kwi: {
    id: 'kwi',
    title: 'KWI',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/kwi-logo.png',
    content: <IntegrationsKWI />,
    installable: true,
    cover: 'https://dashboard-v2-images.s3.amazonaws.com/integrations-cover.png',
  },
  profitsystems: {
    id: 'profitsystems',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/profitsystems-logo.png',
    content: <IntegrationsProfitSystems />,
    installable: true,
    cover: 'https://dashboard-v2-images.s3.amazonaws.com/integrations-cover.png',
  },
  'the-edge': {
    id: 'the-edge',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/the-edge-logo.jpg',
    content: <IntegrationsTheEdge />,
    installable: false,
    cover: 'https://dashboard-v2-images.s3.amazonaws.com/integrations-cover.png',
  },
  shopify: {
    id: 'shopify',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/shopify-logo.png',
    content: <IntegrationsShopify />,
    installable: true,
    cover: 'https://dashboard-v2-images.s3.amazonaws.com/integrations-cover.png',
  },
  rain: {
    id: 'rain',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/rain-logo.png',
    content: <IntegrationsRain />,
    installable: false,
    cover: 'https://dashboard-v2-images.s3.amazonaws.com/integrations-cover.png',
  },
  'jewel-360': {
    id: 'jewel-360',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/jewel-360-logo.png',
    content: <IntegrationsJewel360 />,
    installable: false,
    cover: 'https://dashboard-v2-images.s3.amazonaws.com/integrations-cover.png',
  },
  'music-shop-360': {
    id: 'music-shop-360',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/music-shop-360-logo.png',
    content: <IntegrationsMusicShop360 />,
    installable: false,
    cover: 'https://dashboard-v2-images.s3.amazonaws.com/integrations-cover.png',
  },
  'like-sew': {
    id: 'like-sew',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/like-sew-logo.png',
    content: <IntegrationsLikeSew />,
    installable: false,
    cover: 'https://dashboard-v2-images.s3.amazonaws.com/integrations-cover.png',
  },
  google: {
    id: 'google',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/google-logo.png',
    content: <IntegrationsGoogle />,
    installable: true,
    cover: 'https://dashboard-v2-images.s3.amazonaws.com/integrations-cover.png',
  },
  google: {
    id: 'google',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/google-logo.png',
    content: <IntegrationsGoogle />,
    installable: true,
    cover: 'https://dashboard-v2-images.s3.amazonaws.com/integrations-google-cover.png',
    titleImageSize: 'xl',
  },
  facebook: {
    id: 'facebook',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/messenger.png',
    title: 'Facebook Messenger',
    content: <IntegrationsFacebook />,
    installable: true,
    cover: 'https://dashboard-v2-images.s3.amazonaws.com/integrations-facebook-cover.png',
  },
  instagram: {
    id: 'instagram',
    title: 'Instagram',
    image: 'https://dashboard-v2-images.s3.us-east-1.amazonaws.com/instragram-logo.png',
    content: <IntegrationsInstagram />,
    installable: true,
    cover: 'https://dashboard-v2-images.s3.amazonaws.com/integrations-cover.png',
  }
};

const getButton = (slug, googleButton, facebookButton, instagramButton, defaultButton) => {
  switch (slug) {
    case 'google':
      return googleButton;
    case 'facebook':
      return facebookButton;
    case 'instagram':
      return instagramButton;
    default:
      return defaultButton;
  }
};

const IntegrationsDetails = ({ uuid, pos_type, auth }) => {
  const { slug } = useParams();
  const integration = INTEGRATIONS[slug];

  if (!integration) {
    return <Redirect to="/help/integrations" />;
  }

  const [showFacebookConfirmModal, setShowFacebookConfirmModal] = useState(false);
  const [showInstagramConfirmModal, setShowInstagramConfirmModal] = useState(false);
  const [showGoogleConfirmModal, setShowGoogleConfirmModal] = useState(false);
  const [showLightspeedConfirmModal, setShowLightspeedConfirmModal] = useState(false);
  const [showLightspeedXConfirmModal, setShowLightspeedXConfirmModal] = useState(false);
  const [showKwiConfirmModal, setShowKwiConfirmModal] = useState(false);
  const [showShopifyConfirmModal, setShowShopifyConfirmModal] = useState(false);

  const installed = useMemo(() => {
    switch (slug) {
      case 'facebook':
        return auth?.meta_integration;
      case 'google':
        return auth?.google_integration;
      case 'instagram':
        return auth?.instagram_integration;
      default:
        return pos_type && pos_type.toLocaleLowerCase() === slug;
    }
  }, [pos_type, slug, auth]);

  const showInstallButton = useMemo(
    () => (!pos_type || slug === 'google' || slug === 'facebook' || slug === 'instagram') && !installed && integration.installable,
    [pos_type, installed, integration.installable],
  );

  const lightspeedAuthUrl = useMemo(() => {
    const responseType = 'code';
    const clientId = process.env.REACT_APP_LIGHTSPEED_R_CLIENT_ID;
    const scope = 'employee:customers+employee:admin_employees+employee:inventory_read+employee:register_read';

    return `https://cloud.lightspeedapp.com/auth/oauth/authorize?response_type=${responseType}&client_id=${clientId}&scope=${scope}&state=${uuid}`;
  }, [uuid]);

  const facebookAuthUrl = useMemo(() => {
    const rootUrl = 'https://www.facebook.com/v18.0/dialog/oauth';

    const options = {
      redirect_uri: `${process.env.REACT_APP_CLIENTBOOK_API_URL}/api/v1/meta/oauth2/facebook`,
      client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID?.toString(),
      config_id: process.env.REACT_APP_FACEBOOK_CONFIG_ID?.toString(),
      state: uuid,
      scope: 'pages_messaging,pages_show_list',
      response_type: 'code',
    };

    const qs = new URLSearchParams(options);

    return `${rootUrl}?${qs}`;
  }, [uuid]);

  const instagramAuthUrl = useMemo(() => {

    const rootUrl = 'https://www.instagram.com/oauth/authorize';

    const options = {
      enable_fb_login: 0,
      force_authentication: 1,
      client_id: process.env.REACT_APP_INSTAGRAM_CLIENT_ID,
      redirect_uri: `${process.env.REACT_APP_CLIENTBOOK_API_URL}/api/v1/meta/oauth2/instagram`,
      state: uuid,
      response_type: 'code',
      scope: 'instagram_business_basic,instagram_business_manage_messages'
    };

    const qs = new URLSearchParams(options);

    return `${rootUrl}?${qs}`;
  }, [uuid]);

  const googleAuthUrl = useMemo(() => {
    const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth';

    const options = {
      redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
      scope: 'https://www.googleapis.com/auth/business.manage',
      state: uuid,
    };

    const qs = new URLSearchParams(options);

    return `${rootUrl}?${qs}`;
  }, [uuid]);

  const lightspeedXAuthUrl = useMemo(() => {
    const responseType = 'code';
    const redirectUri = `${process.env.REACT_APP_CLIENTBOOK_API_URL}/api/v1/oauth2/lightspeedx/callback`;
    return `https://secure.vendhq.com/connect?response_type=${responseType}&client_id=${process.env.REACT_APP_LIGHTSPEEDX_CLIENT_ID}&redirect_uri=${redirectUri}&state=${uuid}`;
  }, [uuid]);

  const handleInstallLightspeed = () => {
    setShowLightspeedConfirmModal(true);

    setTimeout(() => {
      window.open(lightspeedAuthUrl, '_blank');
    }, 5000);
  };

  const handleClosePop = useCallback(() => {
    setShowGoogleConfirmModal(false);
    setShowFacebookConfirmModal(false);
  }, []);

  const handleInstallGoogle = () => {
    setShowGoogleConfirmModal(true);

    setTimeout(() => {
      window.open(googleAuthUrl, '_blank');
    }, 5000);
  };

  const handleInstallFacebook = () => {
    setShowFacebookConfirmModal(true);

    setTimeout(() => {
      window.open(facebookAuthUrl, '_blank');
    }, 5000);
  };

  const handleInstallInstagram = () => {
    setShowInstagramConfirmModal(true);

    setTimeout(() => {
      window.open(instagramAuthUrl, '_blank');
    }, 5000);
  };
  const handleInstallLightspeedX = () => {
    setShowLightspeedXConfirmModal(true);

    setTimeout(() => {
      window.open(lightspeedXAuthUrl, '_blank');
    }, 5000);
  };

  const handleClickInstall = () => {
    if (slug === 'lightspeed') {
      handleInstallLightspeed();
    }

    if (slug === 'kwi') {
      setShowKwiConfirmModal(true);
    }

    if (slug === 'shopify') {
      setShowShopifyConfirmModal(true);
    }
    if (slug === 'lightspeedx') {
      handleInstallLightspeedX();
    }
  };

  return (
    <div className="cb-integrations-details">
      <StandardLayout
        title={integration?.title || ''}
        titleImage={integration.image}
        titleImageSize={integration?.titleImageSize || (!integration?.title && 'lg')}
        actions={getButton(
          slug,
          <GoogleButton installed={installed} onClick={handleInstallGoogle} />,
          <FacebookButton installed={installed} onClick={handleInstallFacebook} />,
          <InstagramButton installed={installed} onClick={handleInstallInstagram} />,
          <IntegrationsActions
            installed={installed}
            showInstallButton={showInstallButton}
            onClick={handleClickInstall}
          />,
        )}
        backLink="/help/integrations"
        classProps="standard-width-with-sidebar"
      >
        <div className="cb-integrations-details__body">

          {integration.id === 'big' && !installed && !integration.installable && (
            <div className="cb-integrations-details__helper">
              <img src="https://dashboard-v2-images.s3.amazonaws.com/help-icon.svg" alt="help" />
              <span>Revamp the way you clientele by combining your Buyers Intelligence Account with Clientbook. </span>
            </div>
          )}

          <img
            src={integration.cover || 'https://dashboard-v2-images.s3.amazonaws.com/integrations-cover.png'}
            alt="integrations cover"
            height="auto"
            width="100%"
            className="cb-integrations-details__header"
          />

          {integration.content}

          {showInstallButton &&
            getButton(
              slug,
              <div style={{ width: 'fit-content' }}>
                <GoogleButton onClick={handleInstallGoogle} />
              </div>,
              <div style={{ width: 'fit-content' }}>
                <FacebookButton installed={installed} onClick={handleInstallFacebook} />
              </div>,
              <div style={{ width: 'fit-content' }}>
                <InstagramButton installed={installed} onClick={handleInstallInstagram} label={"Connect with us on Instagram"}/>
              </div>,
              <Button type="primary" onClick={handleClickInstall}>
                Install
              </Button>,
            )}
        </div>

        <IntegrationsFacebookPopConfirm
          visible={showFacebookConfirmModal}
          authUrl={facebookAuthUrl}
          onClose={handleClosePop}
        />
        <IntegrationsInstagramPopConfirm
          visible={showInstagramConfirmModal}
          authUrl={instagramAuthUrl}
          onClose={handleClosePop}
        />
        <IntegrationsGooglePopConfirm
          visible={showGoogleConfirmModal}
          authUrl={googleAuthUrl}
          onClose={handleClosePop}
        />
        <IntegrationsLightspeedPopConfirm visible={showLightspeedConfirmModal} authUrl={lightspeedAuthUrl} />
        <IntegrationsLightspeedPopConfirm visible={showLightspeedXConfirmModal} authUrl={lightspeedXAuthUrl} />
        <IntegrationsKwiModal visible={showKwiConfirmModal} setVisible={setShowKwiConfirmModal} />
        <IntegrationsShopifyModal
          visible={showShopifyConfirmModal}
          setVisible={setShowShopifyConfirmModal}
          merchantUUID={uuid}
        />
      </StandardLayout>
    </div>
  );
};

const mapStateToProps = state => ({
  uuid: state.auth.uuid,
  pos_type: state.auth.pos_type,
  auth: state.auth,
});

export default connect(mapStateToProps, {})(IntegrationsDetails);
