import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Layout from '../../Layout/StandardLayout';
import SecondaryPanel from '../../Layout/SecondaryPanel';
import DropdownSelect from '../../Layout/DropdownSelect';
import { setAuthStoreId, setAuthSelStore } from '../../../reducers/auth';
import './dashboardMetrics.css';
import CardComponent from './CardComponent';
import Card from './Card';
import SmallCard from './SmallCard';
import { getRequest } from '../../../core/apiRequests';

const HomeDashboardMetrics = props => {
  const [stores, setStores] = useState([]);

  const [automationMsgCnt, setAutomationMsgCnt] = useState(0);
  const [automationMsgCntPast, setAutomationMsgCntPast] = useState(0);

  const [totalMsgCnt, setTotalMsgCnt] = useState(0);
  const [totalMassMessage, setTotalMassMessage] = useState(0);
  const [msgLoad, setMsgLoad] = useState(false);

  const [pastTotalOutbound, setPastTotalOutbound] = useState(0);

  const [inboundMessaging, setInboundMessaging] = useState(0);
  const [pastInboundMessaging, setPastInboundMessaging] = useState(0);
  const [inboundLoad, setInboundLoad] = useState(false);

  const [associateMessage, setAssociateMessage] = useState(0);

  const [contacted, setContacted] = useState(0);
  const [pastContacted, setPastContacted] = useState(0);
  const [contactedLoad, setContactedLoad] = useState(false);

  const [conversation, setConversation] = useState(0);
  const [pastConversation, setPastConversation] = useState(0);
  const [conversationLoad, setConversationLoad] = useState(false);

  const [timeRespond, setTimeRespond] = useState(0);
  const [pastTimeRespond, setPastTimeRespond] = useState(0);
  const [timeRespondMetric, setTimeToRespondMetric] = useState(0);
  const [timeRespondLoad, setTimeRespondLoad] = useState(false);

  const [reminderComplete, setReminderComplete] = useState(0);
  const [pastReminderComplete, setPastReminderComplete] = useState(0);
  const [remCompleteLoad, setRemCompleteLoad] = useState(false);

  const [reminderDue, setReminderDue] = useState(0);
  const [pastReminderDue, setPastReminderDue] = useState(0);
  const [remDueLoad, setRemDueLoad] = useState(false);

  const [attributedSales, setAttributedSales] = useState(0);
  const [pastAttributedSales, setPastAttributedSales] = useState(0);
  const [attSaleLoad, setAttSaleLoad] = useState(false);

  const [amountProcessed, setAmountProcessed] = useState(0);
  const [pastAmountProcessed, setPastAmountProcessed] = useState(0);
  const [amtProcessed, setAmtProcessed] = useState(false);

  const [amountPaid, setAmountPaid] = useState(0);
  const [pastAmountPaid, setPastAmountPaid] = useState(0);
  const [amtPaid, setAmtPaid] = useState(false);

  const [timeToPay, setTimeToPay] = useState(0);
  const [pastTimeToPay, setPastTimeToPay] = useState(0);
  const [timeToPayMetric, setTimeToPayMetric] = useState(0);
  const [timePay, setTimePay] = useState(false);

  const [apiComplete, setApiComplete] = useState(false);

  const [ring, setRing] = useState('dashSpinOff');

  const [isShown, setIsShown] = useState('');
  const isMounted = useRef(null);

  const requestObject = endpoint => {
    const { merchantId, licenseKey: merchantName } = props.auth;
    const allStores = props.auth.role === 'ADMIN';
    const reqObj = {
      params: ['home', merchantId, endpoint],
      query: {
        merchantName,
        storeAccess: props.auth.stores.filter(st => st.id !== 0).map(st => st.id),
        allStores,
        // storeId : props.auth.storeId == 0 ? null : props.auth.storeId,
        storeId: props.auth.selectedStore == 0 ? null : props.auth.selectedStore,
        posType: props.auth.pos_type,
      },
      loadingStatusOff: true,
    };
    return reqObj;
  };

  const homeOther = () => {
    const { merchantId, licenseKey: merchantName } = props.auth;
    const allStores = props.auth.role === 'ADMIN';
    const reqObj = {
      params: ['home', merchantId],
      query: {
        merchantName,
        storeAccess: props.auth.stores.filter(st => st.id !== 0).map(st => st.id),
        allStores,
        // storeId : props.auth.storeId == 0 ? null : props.auth.storeId,
        storeId: props.auth.selectedStore == 0 ? null : props.auth.selectedStore,
      },
      loadingStatusOff: true,
    };
    if (props.auth.merchantId) {
      let data;
      data = async () => {
        const data = await getRequest(reqObj);
        const storeList = data || [];
        const storesObj = {};
        setStores(storeList);
        storeList.forEach(st => (storesObj[st.name] = 0)); // build stores obj
      };
      data();
    }
  };

  const homeMessaging = () => {
    setRing('dashSpinOn');
    setMsgLoad(true);
    if (props.auth.merchantId) {
      (async () => {
        const data = await getRequest(requestObject('updatedHomeMessaging'));
        if (data) {
          // outbound messaging
          const marketingMessaging = data[0].marketingMessaging[0].total_sent || 0;
          setTotalMassMessage(marketingMessaging);
          const totalMessagesSent = data[0].totalMessagesSent || [];
          const automationCount = totalMessagesSent.reduce((acc, curr) => {
            if (curr.associate === 'Clientbook') {
              return (acc += 1);
            }
            return acc;
          }, 0);
          setAutomationMsgCnt(automationCount);
          const total = Number(totalMessagesSent.length) + Number(marketingMessaging);
          setTotalMsgCnt(total);
          // associate calculation
          const associateMsg = total - automationCount - marketingMessaging;
          setAssociateMessage(associateMsg);
          // past outbound
          const pastTotalOutbound = data[0].totalMessagesSentPast || 0;
          const automationCountPast = pastTotalOutbound.reduce((acc, curr) => {
            if (curr.associate === 'Clientbook') {
              return (acc += 1);
            }
            return acc;
          }, 0);
          setAutomationMsgCntPast(automationCountPast);
          const marketingMessagingPast = data[0].marketingMessagingPast[0].total_sent || 0;
          const totalPast = Number(pastTotalOutbound.length) + Number(marketingMessagingPast);
          setPastTotalOutbound(totalPast);
          setRing('dashSpinOff');
          setMsgLoad(false);
          setApiComplete(true);
        }
      })();
    }
  };

  const homeInboundMsg = () => {
    setRing('dashSpinOn');
    setInboundLoad(true);
    if (props.auth.merchantId) {
      (async () => {
        const data = await getRequest(requestObject('updatedHomeInbound'));
        if (data) {
          // inbound messaging
          const inboundMsg = data[0].inboundMessaging[0].inboundCnt;
          setInboundMessaging(inboundMsg);
          const inboundMsgPast = data[0].inboundMessagingPast[0].inboundCnt;
          setPastInboundMessaging(inboundMsgPast);
          setRing('dashSpinOff');
          setInboundLoad(false);
        }
      })();
    }
  };

  const homeContacted = () => {
    setRing('dashSpinOn');
    setContactedLoad(true);
    if (props.auth.merchantId) {
      (async () => {
        const data = await getRequest(requestObject('updatedHomeContacted'));
        if (data) {
          // contacted
          const contact = data[0]?.contacted[0]?.sumContact || 0;
          setContacted(contact);
          const contactPast = data[0].contactedPast.sumContactPast || 0;
          setPastContacted(contactPast);
          setRing('dashSpinOff');
          setContactedLoad(false);
        }
      })();
    }
  };

  const homeConversations = () => {
    setRing('dashSpinOn');
    setConversationLoad(true);
    if (props.auth.merchantId) {
      (async () => {
        const data = await getRequest(requestObject('updatedHomeConversations'));
        if (data) {
          // conversations
          const conversations = data[0].conversation[0].cnt || 0;
          setConversation(conversations);
          const conversationsPast = data[0].conversationPast[0].cnt || 0;
          setPastConversation(conversationsPast);
          setRing('dashSpinOff');
          setConversationLoad(false);
        }
      })();
    }
  };
  const homeTimeToResponse = () => {
    setRing('dashSpinOn');
    setTimeRespondLoad(true);
    if (props.auth.merchantId) {
      (async () => {
        const data = await getRequest(requestObject('updatedHomeTimeToResponse'));
        if (data) {
          // timeToRespond
          const responseTime = Math.round(data[0].timeToRespond[0].average) || 0;
          let hours = Math.floor(responseTime / 60);
          let minutes = responseTime % 60;
          if (responseTime < 50) {
            // const time_to_respond = data[0].timeToRespond[0].average || []
            setTimeRespond(`${responseTime} min`);
          } else if (responseTime > 50 && responseTime <= 60) {
            setTimeRespond('1 hr');
          } else {
            if (hours.toString().length < 2) {
              hours = `0${hours}`;
            }
            if (minutes.toString().length < 2) {
              minutes = `0${minutes}`;
            }
            setTimeRespond(`${hours} hr ${minutes} min`);
          }
          const time_to_respond_past = data[0].timeToRespondPast[0].average || 0;
          setPastTimeRespond(time_to_respond_past);
          const time_to_respond_metric = Math.round(data[0].timeToRespond[0].average) || 0;
          setTimeToRespondMetric(time_to_respond_metric);
          setRing('dashSpinOff');
          setTimeRespondLoad(false);
        }
      })();
    }
  };

  const homeReminderComplete = () => {
    setRing('dashSpinOn');
    setRemCompleteLoad(true);
    if (props.auth.merchantId) {
      (async () => {
        const data = await getRequest(requestObject('updatedHomeReminderComplete'));
        if (data) {
          // reminder complete
          const complete_reminders = data[0].reminderComplete[0].cnt || 0;
          setReminderComplete(complete_reminders);
          const complete_reminders_past = data[0].reminderCompletePast[0].cnt || 0;
          setPastReminderComplete(complete_reminders_past);
          setRing('dashSpinOff');
          setRemCompleteLoad(false);
        }
      })();
    }
  };

  const homeReminderDue = () => {
    setRing('dashSpinOn');
    setRemDueLoad(true);
    if (props.auth.merchantId) {
      (async () => {
        const data = await getRequest(requestObject('updatedHomeReminderDue'));
        if (data) {
          // reminder due
          const due_reminders = data[0].remindersDue[0].remindersDueCnt || 0;
          setReminderDue(due_reminders);
          //  const due_reminders_past = data[0].remindersDuePast[0].remindersDueCnt || []
          // setPastReminderDue(due_reminders_past)
          setRing('dashSpinOff');
          setRemDueLoad(false);
        }
      })();
    }
  };

  const homeAttributedSales = () => {
    setRing('dashSpinOn');
    setAttSaleLoad(true);
    if (props.auth.merchantId) {
      (async () => {
        const data = await getRequest(requestObject('updatedHomeAttributedSales'));
        if (data) {
          // attributedSales
          const attributed_sales = Math.round(Number(data[0].attributedSales[0].salesSum)) || 0;
          setAttributedSales(attributed_sales);
          const attributed_sales_past = Math.round(Number(data[0].attributedSalesPast[0].salesSum)) || 0;
          setPastAttributedSales(attributed_sales_past);
          setRing('dashSpinOff');
          setAttSaleLoad(false);
        }
      })();
    }
  };

  const homeAmountProcessed = () => {
    setRing('dashSpinOn');
    setAmtProcessed(true);
    if (props.auth.merchantId) {
      (async () => {
        const data = await getRequest(requestObject('homeAmountProcessed'));
        if (data) {
          // attributedSales
          const amount_processed = Math.round(Number(data[0].amountProcessed[0].salesSum)) || 0;
          setAmountProcessed(amount_processed);
          const amount_processed_past = Math.round(Number(data[0].amountProcessedPast[0].salesSum)) || 0;
          setPastAmountProcessed(amount_processed_past);
          setRing('dashSpinOff');
          setAmtProcessed(false);
        }
      })();
    }
  };

  const homeAmountPaid = () => {
    setRing('dashSpinOn');
    setAmtPaid(true);
    if (props.auth.merchantId) {
      (async () => {
        const data = await getRequest(requestObject('homeAmountPaid'));
        if (data) {
          // attributedSales
          const amount_paid = Math.round(Number(data[0].amountPaid[0].salesSum)) || 0;
          setAmountPaid(amount_paid);
          const amount_paid_past = Math.round(Number(data[0].amountPaidPast[0].salesSum)) || 0;
          setPastAmountProcessed(amount_paid_past);
          setRing('dashSpinOff');
          setAmtPaid(false);
        }
      })();
    }
  };

  const homeTimeToPay = () => {
    setRing('dashSpinOn');
    setTimePay(true);
    if (props.auth.merchantId) {
      (async () => {
        const data = await getRequest(requestObject('homeTimeToPay'));
        if (data) {
          // time to pay is returned in seconds
          const time_to_pay = Math.round(Number(data[0].timeToPay[0].timeSeconds)) || 0;
          let hours = Math.floor(time_to_pay / 3600);
          let minutes = Math.floor((time_to_pay - hours * 3600) / 60);
          const seconds = Math.floor((time_to_pay - hours * 3600) % 60);
          if (minutes < 50) {
            if (minutes == 0) {
              setTimeToPay(`${minutes}.${seconds} sec`);
            } else {
              setTimeToPay(`${minutes} min`);
            }
          } else if (minutes > 50 && minutes <= 60) {
            setTimeToPay('1 hr');
          } else {
            if (hours.toString().length < 2) {
              hours = `0${hours}`;
            }
            if (minutes.toString().length < 2) {
              minutes = `0${minutes}`;
            }
            setTimeToPay(`${hours} hr ${minutes} min`);
          }

          const time_to_pay_past = Math.round(Number(data[0].timeToPayPast[0].timeSeconds)) || 0;
          setPastTimeToPay(time_to_pay_past);
          // without minutes or seconds (wording) added for metric comparison
          setTimeToPayMetric(time_to_pay);
          setRing('dashSpinOff');
          setTimePay(false);
        }
      })();
    }
  };

  // this calls all the separate API calls
  useEffect(() => {
    isMounted.current = true;
    homeOther();
    homeMessaging();
    homeInboundMsg();
    homeContacted();
    homeConversations();
    homeTimeToResponse();
    homeReminderComplete();
    homeReminderDue();
    homeAttributedSales();
    homeAmountProcessed();
    homeAmountPaid();
    homeTimeToPay();
    return () => (isMounted.current = false);
  }, [props.auth.merchantId, props.auth.stores, props.auth.selectedStore]);


  return (
    <Layout
      title="Reports"
      description=" "
      classProps={
        props.auth.role === 'ADMIN' || props.auth.role === 'MANAGER'
          ? 'standard-width-with-sidebar'
          : 'standard-width-wo-sidebar'
      }
      styleProps={props?.auth?.launchFlagsForNav?.reportsPage ? { marginTop: '60px'} : {}}
    >
      {props?.auth?.launchFlagsForNav?.reportsPage && (
        <div
          style={{
            position: 'absolute',
            top: '48px',
            left: '0',
            width: '100%',
            height: '65px',
            background: '#FFFBE6',
            zIndex: '2',
            display: 'flex',
            alignItems: 'center',
            padding: '0 44px',
            fontWeight: 500
          }}
        >
          Please note: These reports are no longer being maintained, which may affect their accuracy. We encourage you to use our new reports for the latest information.
        </div>
      )}
      <div className="dashboardMetrics_outerDiv">
        <DropdownSelect
          classProps="mq-w-200 w-267 align-left"
          options={props.auth.stores.map((st, i) => ({
            label: st.name,
            value: st.id,
          }))}
          placeholder="Select a Store..."
          // value={props.auth.storeId}
          value={props.auth.selectedStore}
          // onChange={obj => {
          //   setAuthStoreId(obj.value);
          // }}
          onChange={obj => {
            setAuthSelStore(obj.value);
          }}
        />
      </div>
      <CardComponent
        // metrics_card={{ marginTop: '-90px' }}
        metrics_cardClass="metrics_card_margin"
        cardHeader="Messaging"
        cards={[
          <SmallCard
            key="Outbound"
            isShown={isShown}
            setIsShown={setIsShown}
            viewReportLink="/Reports/associate-messages"
            ring={msgLoad ? ring : 'dashSpinOff'}
            small_card_class="small_card_margin_top"
            header="Outbound"
            arrow={{ prev: pastTotalOutbound || 0, curr: totalMsgCnt || 0, id: 'totalMsgCnt' }}
            graphContent={{
              associate: associateMessage || 0,
              marketing: totalMassMessage || 0,
              automation: automationMsgCnt || 0,
              text: 'Last 30 days',
            }}
            apiComplete={apiComplete}
            //  numberData={{number: `${automationMsgCnt}`, subHeader: 'Sent', text: 'Last 30 days'}}
          />,
          <SmallCard
            key="Inbound"
            isShown={isShown}
            setIsShown={setIsShown}
            viewReportLink="/Reports/outbound-vs-inbound"
            ring={inboundLoad ? ring : 'dashSpinOff'}
            small_card_class="small_card_margin_top"
            header="Inbound"
            arrow={{ prev: pastInboundMessaging, curr: inboundMessaging, id: 'inboundMessaging' }}
            numberData={{ number: `${inboundMessaging}`, subHeader: 'Customer messages', text: 'Last 30 days' }}
          />,
          <SmallCard
            key="Contacted"
            isShown={isShown}
            setIsShown={setIsShown}
            viewReportLink="/Reports/client-contacts"
            ring={contactedLoad ? ring : 'dashSpinOff'}
            small_card_class="small_card_margin_top"
            header="Contacted"
            arrow={{ prev: pastContacted, curr: contacted, id: 'contacted' }}
            numberData={{
              number: `${contacted ? (contacted * 100).toFixed(2) : 0}%`,
              subHeader: props.auth.pos_type ? 'of customers who made a purchase in the last 5 years' : 'of customers',
              text: 'Last 30 days',
            }}
          />,
        ]}
      />
      {/* automation */}
      <CardComponent
        metrics_cardClass="m-top-20"
        cardHeader="Automation"
        cards={[
          <SmallCard
            key="Auto messages"
            isShown={isShown}
            setIsShown={setIsShown}
            viewReportLink="/Reports/auto-messages"
            ring={msgLoad ? ring : 'dashSpinOff'}
            small_card_class="small_card_margin_top"
            header="Auto Messages"
            arrow={{ prev: Number(automationMsgCntPast), curr: automationMsgCnt, id: 'automationMsgCnt' }}
            numberData={{ number: `${automationMsgCnt}`, subHeader: 'Sent', text: 'Last 30 days' }}
          />,
          <SmallCard
            key="Conversations"
            isShown={isShown}
            setIsShown={setIsShown}
            viewReportLink="/Reports/conversations"
            ring={conversationLoad ? ring : 'dashSpinOff'}
            small_card_class="small_card_margin_top"
            header="Conversations"
            arrow={{ prev: pastConversation, curr: conversation, id: 'conversation' }}
            numberData={{
              number: `${conversation || 0}`,
              subHeader: 'Customer conversations started by messaging',
              text: 'Last 30 days',
            }}
          />,
          <SmallCard
            key="Time to response"
            isShown={isShown}
            setIsShown={setIsShown}
            ring={timeRespondLoad ? ring : 'dashSpinOff'}
            small_card_class="small_card_margin_top"
            header="Time to Response"
            arrow={{ prev: pastTimeRespond, curr: timeRespondMetric, id: 'timeRespond' }}
            numberData={{ number: timeRespond, subHeader: 'Average customer response time', text: 'Last 30 days' }}
          />,
          <SmallCard
            key="Reminders due"
            isShown={isShown}
            setIsShown={setIsShown}
            viewReportLink="/Reports/active-reminders"
            ring={remDueLoad ? ring : 'dashSpinOff'}
            small_card_class="small_card_margin_bottom"
            header="Reminders Due"
            arrow={{ prev: '', curr: 0, id: 'reminderDue' }}
            //  arrow={{prev: pastReminderDue, curr: reminderDue}}
            numberData={{
              number: `${reminderDue || 0}`,
              subHeader: 'Due this month',
              text: 'Last 30 days',
            }}
          />,
          <SmallCard
            key="Reminders complete"
            isShown={isShown}
            setIsShown={setIsShown}
            ring={remCompleteLoad ? ring : 'dashSpinOff'}
            small_card_class="small_card_margin_bottom"
            header="Reminders Complete"
            arrow={{ prev: pastReminderComplete, curr: reminderComplete, id: 'reminderComplete' }}
            numberData={{
              number: `${reminderComplete || reminderComplete.length > 0 ? reminderComplete : 0}`,
              subHeader: 'Completed this month',
              text: 'Last 30 days',
            }}
          />,
          <SmallCard
            key="attributedSales"
            isShown={isShown}
            setIsShown={setIsShown}
            viewReportLink={props.auth.pos_type ? '/Reports/attributed-sales' : '/Reports/timeliness'}
            ring={attSaleLoad ? ring : 'dashSpinOff'}
            small_card_class="small_card_margin_bottom"
            header={props.auth.pos_type ? 'Attributed Sales' : 'Timeliness'}
            arrow={{
              prev: pastAttributedSales,
              curr: attributedSales,
              id: props.auth.pos_type ? 'attributedSales' : 'timeliness',
            }}
            numberData={{
              number: props.auth.pos_type
                ? `$${attributedSales || 0}`
                : `${attributedSales ? Math.round(attributedSales) : 0} days`,
              subHeader: props.auth.pos_type ? 'Sales driven by automation' : 'Average days late',
              text: 'Last 30 days',
            }}
          />,
        ]}
      />

      {/* payments */}
      <CardComponent
        metrics_cardClass="m-top-20"
        cardHeader="Payments"
        cards={[
          <SmallCard
            key="Amount requested"
            isShown={isShown}
            setIsShown={setIsShown}
            viewReportLink="/Reports/amount-requested"
            ring={amtProcessed ? ring : 'dashSpinOff'}
            small_card_class="small_card_margin_top"
            header="Amount Requested"
            arrow={{ prev: Number(pastAmountProcessed), curr: amountProcessed, id: 'amountProcessed' }}
            numberData={{
              number: `$${amountProcessed || 0}`,
              subHeader: 'Total requested',
              text: 'Last 30 days',
            }}
          />,
          <SmallCard
            key="Amount paid"
            isShown={isShown}
            setIsShown={setIsShown}
            viewReportLink="/Reports/amount-paid"
            ring={amtPaid ? ring : 'dashSpinOff'}
            small_card_class="small_card_margin_top"
            header="Amount Paid"
            arrow={{ prev: pastAmountPaid, curr: amountPaid, id: 'amountPaid' }}
            numberData={{
              number: `$${amountPaid || 0}`,
              subHeader: 'Completed payments',
              text: 'Last 30 days',
            }}
          />,
          <SmallCard
            key="Time to pay"
            isShown={isShown}
            setIsShown={setIsShown}
            viewReportLink="/Reports/time-to-pay"
            ring={timePay ? ring : 'dashSpinOff'}
            small_card_class="small_card_margin_top"
            header="Time to Pay"
            arrow={{ prev: pastTimeToPay, curr: timeToPayMetric, id: 'timeToPayMetric' }}
            numberData={{ number: timeToPay, subHeader: 'Average time to completion', text: 'Last 30 days' }}
          />,
        ]}
      />
      {props.auth.role === 'ADMIN' || props.auth.role === 'MANAGER' ? (
        <SecondaryPanel
          overhead_title="Reports"
          title={['CLIENTELING', 'MESSAGING', 'Google Reviews', 'PAYMENTS', 'AUTOMATION', 'SALES OPPORTUNITY']}
          current=""
          style={
            props?.auth?.launchFlagsForNav?.reportsPage ? {
              marginTop: props?.launchFlagsForNav?.newWebNavigation ? '75px' : '50px',
            } : {}}
        />
      ) : null}
    </Layout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  stores: state.stores,
  paymentBannerActive: state.paymentBanner.active,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(HomeDashboardMetrics);
