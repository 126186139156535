import React, { useState, useEffect, useRef } from 'react';
import { oneOfType, number, string } from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

// global
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import { setLoadingStatus, setSavingStatus } from '../../../../reducers/notification';

// local
import { load as loadBluesnap, init as initBluesnap } from './bluesnap';
import { updateData, getPostValues, updateDataBeforeSubmission, updateDataAfterSubmission, showErrors } from './utils';
import Wizard from './WizardMulti';
import { BusinessDetails, Owners, GenInformation, BusinessAndOwnership } from './StepsMulti';
import BluesnapFields from './BluesnapFields';
import { getBluesnapToken, addBankAccount, getBankAccountFormData } from '../apiRequests';
import { notificationError } from '../../../../core/actions';

const getBusinessDetails = data => {
  const retrieve = field => data[field]?.value;
  const retrieveAddress = prefix => ({
    line1: retrieve(`${prefix}Address`),
    city: retrieve(`${prefix}City`),
    state: retrieve(`${prefix}State`),
    zip: retrieve(`${prefix}Zip`),
  });
  const retrievePerson = prefix => ({
    firstName: retrieve(`${prefix}FirstName`),
    lastName: retrieve(`${prefix}LastName`),
    dateOfBirth: retrieve(`${prefix}DateOfBirth`),
    tel: retrieve(`${prefix}Phone`),
    address: retrieveAddress(prefix),
  });
  return {
    nickname: retrieve('bankAccountNickname'),
    dba: retrieve('businessName'),
    name: retrieve('businessLegalName'),
    description: retrieve('businessProductAndServiceDesc'),
    tel: retrieve('businessPhone'),
    website: retrieve('businessWebsite'),
    type: retrieve('businessType'),
    address: retrieveAddress('business'),
    representative: retrievePerson('companyRep'),
    owners: ['owner1', 'owner2', 'owner3', 'owner4']
      .map(retrievePerson)
      .filter(person => person.firstName !== undefined),
    stores: retrieve('stores'),
  };
};

const setupBluesnapSuccess = (id, setData, history, onError, retrieveData) => pfToken => {
  return addBankAccount(id, {
    ...getPostValues(),
    pfToken,
    businessDetails: getBusinessDetails(retrieveData()),
  })
    .then(() => {
      updateDataAfterSubmission(setData);
      setSavingStatus(false);
      history.push('/BankAccount/list');
    })
    .catch(errors => {
      updateDataAfterSubmission(setData);
      onError(errors, setData);
      setSavingStatus(false);
    });
};

const setupBluesnapError =
  (setData, onError) =>
  (errors = []) => {
    updateDataAfterSubmission(setData);
    onError(errors, setData);
    setSavingStatus(false);
    notificationError('Bank account was not added');
  };

const AddAccount = ({ merchantId, bankAccountList }) => {
  const [showAll, setShowAll] = useState(false);
  const [data, setData] = useState(null);
  const history = useHistory();
  // used for bluesnap errors (both FE & BE)
  const [errors, setErrors] = useState([]);

  const [addBankAccountCrumb, setAddBankAccountCrumb] = useState({
    stores: {
      crumb: 'General Information',
      title: 'Stores',
      Component: GenInformation,
    },
    bizAndOwnership: {
      crumb: 'Business and Ownership Details',
      title: 'Business Details',
      Component: BusinessAndOwnership,
    },
  });

  // changing steps/pages
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const finalStepIndex = Object.keys(addBankAccountCrumb).length - 1;

  const previous = () => {
    setActiveStepIndex(Math.max(activeStepIndex - 1, 0));
  };
  const next = () => {
    setActiveStepIndex(Math.min(activeStepIndex + 1, finalStepIndex));
  };

  const [disableContinue, setDisableContinue] = useState(false);
  const wizardForm = useRef(null);

  const dataRef = useRef();
  dataRef.current = data;

  // get fields
  const getFields = () => {
    const fields = Array.from(wizardForm.current.elements)
      .filter(({ id }) => id)
      .map(el => {
        return {
          id: el.id,
          bluesnap: el.id,
          value: el.value,
          active: el.getAttribute('data-active') === 'true',
          optional: el.getAttribute('data-optional') === 'true',
          forget: el.getAttribute('data-forget') === 'true',
          minLength: el.getAttribute('minlength'),
          maxLength: el.getAttribute('maxlength'),
          inputMode: el.getAttribute('inputmode'),
          errorMessage: el.getAttribute('data-error-message'),
        };
      });

    return fields;
  };

  // helper to get values from fields
  const getValues = (fields, isSaving) =>
    fields.reduce((obj, { id, value, forget }) => {
      return {
        ...obj,
        [id]: isSaving && forget ? null : value,
      };
    }, {});

  // handler to update values by saving in the parent component
  // use instead of setData for changes that cause re-render
  const updateValues = (optionalValues = {}) => {
    const allFields = getFields();
    const allFieldsValues = getValues(allFields);

    updateData({ ...allFieldsValues, ...optionalValues }, setData);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    document.getElementById('scrollWatchId').scrollTo(0, 0);
  };

  // common actions between next/prev step changes
  const handleStepChange = () => {
    // save values so we don't lose them on re-render
    updateValues();
    // reset the page
    setErrors([]);
    setDisableContinue(false);
    scrollToTop();
  };

  const handleErrors = (errs, set) => {
    if (errs && errs.length) {
      setErrors(list => [...list, ...errs]);
      showErrors(errs, set);
      setShowAll(true);
    }
  };

  useEffect(() => {
    getBankAccountFormData(merchantId);
  }, []);

  useEffect(() => {
    if (bankAccountList.formList) {
      let fakeObj = {};
      Object.keys(bankAccountList.formList).forEach(key => {
        fakeObj = {
          ...fakeObj,
          [key]: {
            hasError: false,
            value: bankAccountList.formList[key],
          },
        };
      });
      fakeObj = {
        ...fakeObj,
        owner1Address: fakeObj.businessAddress ? fakeObj.businessAddress : { hasError: false, value: '' },
        companyRepAddress: fakeObj.businessAddress ? fakeObj.businessAddress : { hasError: false, value: '' },
        companyRepCity: fakeObj.businessCity ? fakeObj.businessCity : { hasError: false, value: '' },
        companyRepCountry: { hasError: false, value: 'US' },
        companyRepDateOfBirth: fakeObj.owner1DateOfBirth ? fakeObj.owner1DateOfBirth : { hasError: false, value: '' },
        companyRepFirstName: fakeObj.owner1FirstName ? fakeObj.owner1FirstName : { hasError: false, value: '' },
        companyRepGovID: { hasError: false, value: '' },
        companyRepLastName: fakeObj.owner1LastName ? fakeObj.owner1LastName : { hasError: false, value: '' },
        companyRepPhone: fakeObj.businessPhone ? fakeObj.businessPhone : { hasError: false, value: '' },
        companyRepState: fakeObj.businessState ? fakeObj.businessState : { hasError: false, value: '' },
        companyRepZip: fakeObj.businessZip ? fakeObj.businessZip : { hasError: false, value: '' },
        stores: [],
      };
      setData(fakeObj);
    }
  }, [bankAccountList.formList]);

  useEffect(() => {
    setLoadingStatus(true);

    // get everything we need, async
    Promise.all([getBluesnapToken(merchantId), loadBluesnap()])
      .then(([token]) => {
        // set initial values
        updateData({}, setData);

        // init bluesnap
        const getData = () => dataRef.current;
        initBluesnap(
          token,
          () => getBluesnapToken(merchantId),
          setupBluesnapSuccess(merchantId, setData, history, handleErrors, getData),
          setupBluesnapError(setData, handleErrors),
        );
      })
      .catch(err => {
        console.log('### error loading BankAccount/Add', { err });
        notificationError('Page did not load properly. Please refresh.');
      })
      .finally(() => setLoadingStatus(false));
  }, [setData, merchantId, history]);

  const handleSubmit = () => {
    setErrors([]);
    setSavingStatus(true);
    updateDataBeforeSubmission(setData);
  };

  const handleClick = (name, add) => {
    setShowAll(false);
    const theTempest = addBankAccountCrumb;
    if (name === 'business' && add) {
      if (add) {
        theTempest.business = {
          crumb: 'Business Details',
          title: 'Business Details',
          Component: BusinessDetails,
        };
        setActiveStepIndex(Math.min(activeStepIndex + 1, Object.keys(theTempest).length - 1));
      } else {
        delete theTemptest.business;
        previous();
      }
    }
    if (name === 'ownership' && add) {
      if (add) {
        theTempest.ownership = {
          crumb: 'Ownership',
          title: 'Ownership',
          Component: Owners,
        };
        setActiveStepIndex(Math.min(activeStepIndex + 1, Object.keys(theTempest).length - 1));
      } else {
        delete theTemptest.ownership;
        previous();
      }
    }

    if (!name && !add) {
      delete theTempest.business;
      delete theTempest.ownership;
      previous();
    }
    handleStepChange();
    setAddBankAccountCrumb(theTempest);
  };

  return (
    <Layout title="Add Bank Account" classProps="standard-width-with-sidebar align-left">
      {data && (
        <Wizard
          activeStepIndex={activeStepIndex}
          setActiveStepIndex={setActiveStepIndex}
          finalStepIndex={finalStepIndex}
          previous={previous}
          next={next}
          data={data}
          errors={errors}
          setErrors={setErrors}
          setData={setData}
          onSubmit={handleSubmit}
          showAll={showAll}
          handleClick={handleClick}
          steps={addBankAccountCrumb}
          handleStepChange={handleStepChange}
          wizardForm={wizardForm}
          getFields={getFields}
          updateValues={updateValues}
          disableContinue={disableContinue}
          bankAccountStores={bankAccountList.formStores}
        />
      )}
      {data && <BluesnapFields data={data} />}
    </Layout>
  );
};

AddAccount.propTypes = {
  merchantId: oneOfType([number, string]).isRequired,
};

AddAccount.defaultProps = {};

const mapStateToProps = state => ({
  merchantId: state.auth.merchantId,
  bankAccountList: state.bankAccounts,
});

export default connect(mapStateToProps)(AddAccount);
