import React from 'react';

const IntegrationsInstagram = () => {

  return (
    <>
      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Overview</h3>

        <p>
        Connect your Instagram business account with Clientbook. Effortlessly sign into your Instagram business account with easy redirect right to Instagram.
        Once you are connected, never miss a message again. Respond to customers directly in the Clientbook Web application or mobile devices.
        Features like using generative AI will help give you the best response to the customers needs.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Key Features</h3>

        <p>By adding your instagram information to Clientbook, you can:</p>

        <ul className="cb-integrations-details__list">
          <li>Easily connect your Instagram business account to Clientbook.</li>

          <li>Respond directly to messages from Clientbook.</li>

          <li>Have multiple lines of communications with clients.</li>

          <li>Get historical chats to keep track of past conversations.</li>
        </ul>
      </section>
    </>
  );
};

export default IntegrationsInstagram;
