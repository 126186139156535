import React, { useCallback, useEffect, useState } from 'react';
import useGetClients from '../../../hooks/query/useGetClients';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import useDebounce from '../../../hooks/useDebounce';
import { connect } from 'react-redux';
import Select from '../Select';

const SearchClientsInput = ({ posType, stores, onSelect, className, requireCollections=false, style, placeholder = 'Search client by name' }) => {

  const [value, setValue] = useState(null);
  const [search, setSearch] = useState('');
  const debounceSearch = useDebounce(search);

  const { data: { results }, isLoading, isFetching } = useGetClients({
    query: {
      search: debounceSearch,
      page: 1,
      pageSize: 50,
      selectedStores: JSON.stringify(stores.filter(st => st.id !== 0).map(st => st.id)),
    },
    loadingStatusOff: true,
  });

  const handleSelect = useCallback((id) => {
    setValue(id);
    const selectedOption = results.find(e => e.id.toString() === id);
    onSelect(selectedOption);
  }, [results])


  return (
    <Select
      filterOption={false}
      placeholder={placeholder}
      defaultActiveFirstOption={false}
      onSearch={(value) => setSearch(value)}
      notFoundContent={isFetching ? <LoadingOutlined /> : null}
      options={results?.map(item => ({
        value: item?.id?.toString(),
        label: item?.name || item?.preferred_name || item?.mobile,
        suffix: (posType ? item?.pos_ref_num : item?.mobile) || item?.mobile,
      }))}
      prefixIcon={<SearchOutlined />}
      suffixIcon={(isFetching && !isLoading) ? <LoadingOutlined /> : null}
      showSearch
      onSelect={handleSelect}
      searchValue={search}
      value={value}
      optionRender={(option) => {
        return (
        <div className='flex-row-nospacebetween-nowrap'>
          <div>{option.label}</div>
            {(
              option?.data?.suffix &&
              option?.data?.suffix !== 'null' &&
              option?.data?.suffix !== '') &&
                <span className="m-left-4 opacity-50 italic fs-12">
                  ({option.data.suffix})
                </span>
            }
        </div>
      )}}
    />
  );
};

const mapStateToProps = state => ({
  posType: state.auth.pos_type,
  stores: state.auth.stores,
});

export default connect(mapStateToProps, {})(SearchClientsInput);
