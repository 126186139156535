import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StandardLayout from '../../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../../Layout/SecondaryPanel';
import AutoMessageSettings from '../../../../../Layout/AutoMessages/AutoMessageSettings';

const LifeEventAutoMessage = props => {
  const history = useHistory();

  const setRedirectBack = () => {
    history.push(`/Configuration/life-events/edit-life-events/${props.match.params.listItemId}`);
  };

  return (
    <StandardLayout
      title={`${props.autoMessageAddOrEdit} Auto Message`}
      description="Write a message to send automatically."
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Life Events', to: '/Configuration/life-events' },
        {
          title: 'Edit Life Event',
          to: `/Configuration/life-events/edit-life-events/${props.match.params.listItemId}`,
        },
        { title: `${props.autoMessageAddOrEdit} Auto Message` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <AutoMessageSettings
        setRedirectBack={setRedirectBack}
        cancelRoute={`/Configuration/life-events/edit-life-events/${props.match.params.listItemId}`}
        popConfirmDesc={'This will remove the auto message from this Life Event'}
        editRoute={`/Configuration/life-events/edit-life-events/${props.match.params.listItemId}`}
        type="lifeEvent"
        match={props.match}
        selectAutoMessageTime="How far in advance of the life event should the message send?"
      />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({ ...state.autoMessages });
export default connect(mapStateToProps, {})(LifeEventAutoMessage);
