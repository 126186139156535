import React, { useState, useEffect, useCallback } from 'react';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller } from 'react-hook-form';
import { Tooltip } from 'antd';
import { Buttons } from './Buttons';
import { notificationError, notificationSuccess } from '../../../../core/actions';
import {
  setClientAssociateId,
  setClientEmail,
  setClientMobile,
  setDNC,
  setName,
  setPrefContactType,
  setPrefName,
} from '../../../../reducers/client';
import { setTriggerRefresh, setMsgDNC } from '../../../../reducers/messaging';
import { getRequest, postRequest } from '../../../../core/apiRequests';
import StyledLink from '../../StyledLink';
import { EditableField } from './EditableField';
import DropdownSelect from '../../DropdownSelect';
import KioskSlider from '../../KioskSlider';
import { updateClient } from '../../../../services/clients';

const ClientInfo = props => {
  const clientId = props.client.client_id ? props.client.client_id : props.client.id;
  const clientObj = props.clientObj || [];
  const [openEdit, setOpenEdit] = useState(false);
  const [methods, setMethods] = useState([]);

  const nameShorten = useCallback(name => {
    if (name) {
      const shortName = [];
      const splitName = name.split(' ');
      splitName.forEach(nm => {
        if (nm && nm.length > 15) {
          shortName.push(`${nm.substr(0, 15)}...`);
        } else {
          shortName.push(`${nm}`);
        }
      });
      return shortName.join(' ');
    }
    return '';
  }, []);

  const setEditButton = useCallback(value => {
    setOpenEdit(value);
  }, []);

  const handleCancel = useCallback(() => {
    setOpenEdit(false);
    props.resetField('clientObj');
  }, []);

  const handleSave = useCallback(async () => {
    if (!props.dirtyFields.clientObj) return;

    const dataObj = {
      clientObj: {},
      client_id: props.client.client_id,
    };
    // client obj
    const parsingProps = {
      mobile: 'mobile',
      email: 'email',
      store_id: 'storeId',
      name: 'name',
      preferred_contact_type: 'preferredContactType',
      preferred_name: 'preferredName',
      do_not_contact: 'doNotContact',
      associate_id: 'associateId'
    }
    if (clientObj) {
      Object.keys(clientObj).forEach(key => {
        if(props.dirtyFields?.clientObj[key]) {
          dataObj.clientObj[parsingProps[key]] = clientObj[key];
        }
      });
    }
    try {
      const response = await updateClient({
        data: dataObj.clientObj,
        clientId: props.client.client_id,
      });
      notificationSuccess('Client Updated');
      setClientMobile(clientObj?.mobile);
      setClientEmail(clientObj?.email);
      setName(clientObj?.name);
      setPrefContactType(clientObj?.preferred_contact_type);
      setDNC(clientObj?.do_not_contact ? 1 : 0);
      setMsgDNC(clientObj?.do_not_contact ? 1 : 0);
      setPrefName(clientObj?.preferred_name);
      setClientAssociateId(clientObj?.associate_id);
      props.resetField('clientObj', { defaultValue: clientObj, keepDirty: true });
      setTriggerRefresh(!props.messaging.triggerRefresh);
    } catch (err) {
      console.log({ err });
      notificationError('Error Updating Client');
    } finally {
      setOpenEdit(false);
    }
  }, [props.dirtyFields, clientId, clientObj]);

  const getStoreMethods = storeId => {
    const { merchantId } = props;
    if (merchantId && storeId) {
      (async () => {
        const reqObj = {
          params: ['stores', 'contact-methods', merchantId, storeId],
          query: {},
        };
        const data = await getRequest(reqObj);
        setMethods(sortMethods(data));
      })();
    }
  };

  const sortMethods = methods => {
    const filteredmethods = methods?.filter(method => method.visible === 1);
    filteredmethods.sort((a, b) => {
      return b.isDefault - a.isDefault;
    });
    filteredmethods.sort((a, b) => {
      return b.enabled - a.enabled;
    });
    return filteredmethods;
  };

  useEffect(() => {
    getStoreMethods(props.client.store_id);
  }, [props.client.store_id]);

  const handleKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') {
        if (clientObj) {
          Object.keys(clientObj).forEach(key => {
            if (props.dirtyFields?.clientObj[key]) {
              props.resetField(`clientObj.${key}`, { defaultValue: clientObj[key], keepDirty: true });
              // props.setValue(`clientObj.${key}`, clientObj[key]);
            }
          });
        }
        handleSave();
      }
    },
    [handleSave, props.dirtyFields, clientId, clientObj, props.resetField],
  );

  const verificationObjFormat = () => {
    if (!props.client.mobile_validated) {
      return null;
    }

    if (props.client.mobile_invalid === 0) {
      return {
        verificationText: 'Verified Number',
        verificationColor: '#52C41A',
        verificationIcon: 'check',
      };
    }

    return {
      verificationText: 'Invalid Number',
      verificationColor: '#FF4D4F',
      verificationIcon: 'times',
    };
  };

  const smsStoreNum = props.stores.map(st => st.sms_number).filter(store => store !== undefined);
  const smsMobile = phone => {
    if (!phone) {
      return true;
    }
    if (phone !== undefined) {
      const smsCompare = smsStoreNum.filter(number => {
        if (number.replace(/[^0-9]+/g, '') == phone.replace(/[^0-9]+/g, '')) {
          return true;
        }
        return false;
      });
      if (smsCompare.length > 0) {
        notificationError('Client number cannot match store number!');
      } else return true;
    }
  };

  return (
    <div id="client-details-client-info-box" style={{ position: 'relative' }} className="flex-col-left m-left-13">
      <Buttons
        open={openEdit}
        setOpen={setEditButton}
        onSave={handleSave}
        onCancel={handleCancel}
        id="client-details-client-info-buttons"
        parentId="client-details-client-info-box"
      />
      {openEdit ? (
        <>
          <input
            className="fw-500 fs-13 outline-none client-input"
            style={{
              width: 'calc(100% - 145px)',
            }}
            placeholder="Name"
            {...props.register('clientObj.name')}
            onKeyDown={handleKeyDown}
          />
          <input
            className="fw-500 fs-13 outline-none client-input m-top-18"
            style={{
              width: 'calc(100% - 15px)',
            }}
            placeholder="Preferred name"
            {...props.register('clientObj.preferred_name')}
            onKeyDown={handleKeyDown}
          />
          <Controller
            name="clientObj.mobile"
            control={props.control}
            render={({ field }) => (
              <PhoneInput
                placeholder="Phone number"
                value={field.value}
                onChange={e => {
                  if (e !== undefined) {
                    field.onChange(e);
                  } else field.onChange('');
                  smsMobile(e);
                }}
                onKeyDown={handleKeyDown}
                defaultCountry={props.iso_country_code}
                countryOptionsOrder={[props.iso_country_code, '|']}
                countrySelectProps={{ backgroundcolor: 'rgb(129, 129, 129' }}
                className="w-100-P relationshipNewInput m-btm-12 fw-500 fs-13 outline-none client-input m-top-18"
              />
            )}
          />
          <input
            className="fw-500 fs-13 outline-none client-input m-top-18"
            style={{
              width: 'calc(100% - 15px)',
            }}
            placeholder="Email"
            {...props.register('clientObj.email')}
            onKeyDown={handleKeyDown}
          />
          <div
            className="align-left flex-col-left pos-rel p-top-15 m-btm-8"
            style={{
              width: 'calc(100% - 15px)',
            }}
          >
            <span className="fs-12 fw-500 gray">Preferred Contact Method</span>
            <div className="flex-row-center w-100-P">
              <Controller
                name="clientObj.preferred_contact_type"
                control={props.control}
                render={({ field }) => (
                  <DropdownSelect
                    classProps="w-100-P align-left m-top-8"
                    options={methods.map(val => ({
                      label: val.type,
                      value: val.type.toUpperCase(),
                    }))}
                    value={field.value}
                    onChange={val => field.onChange(val.value)}
                    ref={field.ref}
                  />
                )}
              />
            </div>
          </div>
          <div
            className="flex-row-nospacebetween-nowrap w-100-P m-top-15 m-btm-15"
            style={{ alignItems: 'center', gap: 16 }}
          >
            <Controller
              name="clientObj.do_not_contact"
              control={props.control}
              render={({ field }) => <KioskSlider onChange={val => field.onChange(val)} value={field.value} />}
            />
            <span className="fs-12 fw-500" style={{ color: '#818181' }}>
              Do not contact
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="flex-row-spacebetween-nowrap m-btm-8">
            {props.location === 'clientDetails' ? (
              <div className="flex-row-nospacebetween-nowrap">
                <div className="flex-col-left align-left">
                  <span className="fs-16 fw-400">{nameShorten(clientObj?.name)}</span>
                  <div className="fs-12 fw-400 gray m-top-13">
                    <span>{clientObj?.preferred_name || ''} </span>
                  </div>
                </div>
              </div>
            ) : (
              <StyledLink to={`/Clients?client=${clientId}`}>
                <div className="flex-row-nospacebetween-nowrap">
                  <div className="flex-col-left align-left">
                    <span className="fs-16 fw-400">{nameShorten(clientObj?.name)}</span>
                    <div className="fs-12 fw-400 gray m-top-10">
                      <span>{clientObj?.preferred_name || ''} </span>
                    </div>
                  </div>
                </div>
              </StyledLink>
            )}
          </div>
          <div className="flex-row-center w-100-P p-top-8">
            <FontAwesomeIcon className="fs-16 m-right-12" size="2x" icon={['far', 'mobile']} />
            <span className="fs-12 fw-500 black">
              {formatPhoneNumberIntl(clientObj?.mobile?.includes('+') ? clientObj?.mobile : `+${clientObj?.mobile}`) || 'No phone number available'}
            </span>
            {verificationObjFormat()?.verificationText ? (
              <div className="m-left-10">
                <Tooltip
                  placement="bottom"
                  title={verificationObjFormat().verificationText}
                  overlayInnerStyle={{
                    borderRadius: '10px',
                    padding: '10px 17px 0px',
                    backgroundColor: '#454545',
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      className="fs-15 pointer"
                      color={verificationObjFormat().verificationColor}
                      icon={['fas', verificationObjFormat().verificationIcon]}
                    />
                  </span>
                </Tooltip>
              </div>
            ) : null}
          </div>
          <div className="flex-row-center w-100-P p-top-8">
            <FontAwesomeIcon className="fs-16 m-right-12" size="2x" icon={['far', 'envelope']} />
            <span className="fs-12 fw-500 black">{clientObj?.email || 'No email available'}</span>
          </div>
          <div className="align-left flex-col-left pos-rel p-top-15 m-btm-8">
            <span className="fs-12 fw-500 gray">Preferred Contact Method</span>
            <div className="flex-row-center w-100-P">
              <span className="fs-12 fw-500 black">{clientObj?.preferred_contact_type}</span>
            </div>
          </div>
          <EditableField
            label="Point of Sale ID"
            value={props.client.pos_ref_num || 'No ID available'}
            containerClassName="m-top-8 m-btm-8"
            editable={false}
          />
        </>
      )}
    </div>
  );
};

export default ClientInfo;
