import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import './clientList.css';
import yn from 'yn';
import { CSVLink } from 'react-csv';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formatPhoneNumber } from 'react-phone-number-input';
import ListOfClients from './ListOfClients';
import ClientHeader from './ClientHeader';
import { getRequest } from '../../../../core/apiRequests';
import useSearchParams from '../../../../hooks/useSearchParams';
import { setTablePaginationRowCount, setTablePaginationPageCount } from '../../../../core/actions';
import { setSelectedClientList } from '../../../../reducers/client';
import { setClientFilterMyClients } from '../../../../reducers/Filters';
import { addLoadingList, removeLoadingList, setLoadingDescription, setLoadingStatus } from '../../../../reducers/notification';
import dateFormat from './HelperFunctions/dateFormat';
import { headers } from './HelperFunctions/CSVheaders';
import PopConfirm from '../../../Layout/PopConfirm';
import ClientsFilter, { filters } from '../../../Layout/ClientsFilter';
import useDebounce from '../../../../hooks/useDebounce';

const ClientList = props => {
  const [search, setSearch] = useState('');
  const debounceSearch = useDebounce(search);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [clientAscts, setClientAscts] = useState({});
  const [checkedClient, setCheckedClient] = useState(false);
  const [allClientsChecked, setAllClientsChecked] = useState([]);
  const [asctFilterList, setAsctFilterList] = useState([]);
  const [tagFilterList, setTagFilterList] = useState([
    {
      label: (
        <span className="p-top-4 left" style={{ lineHeight: '30px' }}>
          All Tags
        </span>
      ),
      value: 0,
    },
  ]);
  const [prefHeaders, setPrefHeaders] = useState([...headers]);
  const [downloadData, setDownloadData] = useState([]);
  const [allTags, setAllTags] = useState(false);
  const [page, setPage] = useState(1);
  const [isThereMoreClient, setIsThereMoreClient] = useState(true);
  const [hasFilter, setHasFilter] = useState(false);
  const [contactMethod, setContactMethod] = useState([
    { label: 'Email', value: 'EMAIL' },
    { label: 'SMS', value: 'SMS' },
  ]);
  const [exportClientListModal, setExportClientListModal] = useState(false);

  const { tableData, setTableData } = props;
  const isMounted = useRef(null);
  const flags = useFlags();
  const axiosSource = useRef(null);
  const csvInstance = useRef();

  const { getAll } = useSearchParams();
  const params = getAll();

  const tableName = 'CLIENTS';

  useEffect(() => {
    if (props?.auth?.enabled_whatsapp) {
      setContactMethod([
        { label: 'Email', value: 'EMAIL' },
        { label: 'SMS', value: 'SMS' },
        { label: 'WhatsApp', value: 'WHATSAPP' },
      ]);
    }
  }, [props.auth]);

  //   API CALLS
  const getClients = useCallback(
    async searchText => {
      const {
        pagination: { pageSize, sortColumn, ascendingColumn },
        auth: { merchantId },
      } = props;

      let selectedContactMethods = [];
      if (!params?.filterContactMethods || params?.filterContactMethods === 'all') {
        selectedContactMethods = 'all';
      } else {
        selectedContactMethods = params?.filterContactMethods;
      }

      let selectedStores = [];
      if (!params?.stores || params?.stores === 'all') {
        selectedStores = [0];
      } else {
        selectedStores = params?.stores;
      }

      let selectedAssociates = [];
      if (!params?.associates || params?.associates === 'all') {
        selectedAssociates = [];
      } else if (params?.associates === 'none') {
        selectedAssociates = ['none'];
      } else {
        selectedAssociates = params?.associates;
      }

      let selectedTags = [];
      if (!params?.tags || params?.tags === 'all') {
        selectedTags = [];
      } else if (params?.tags === 'none') {
        selectedTags = 'none';
      } else {
        selectedTags = params?.tags.map(item => +item);
      }
      if (selectedStores.length > 0) {
        await new Promise(resolve => {
          setTimeout(resolve, 300);
        });

        setLoadingStatus(true);

        const reqObj = {
          params: ['clients', merchantId, 'elasticSearchPaginatedList'],
          query: {
            associateId: props.auth.userId,
            selectedStores: JSON.stringify(selectedStores),
            filterTags: JSON.stringify(selectedTags),
            myClients: props.filters.myClients,
            filterAssociates: JSON.stringify(selectedAssociates),
            page,
            pageSize,
            sort_order: sortColumn,
            sort_direction: ascendingColumn,
            search: debounceSearch,
          },
          loadingStatusOff: true,
        };
        // eslint-disable-next-line no-unused-expressions
        flags.clientSegmentation &&
          Object.assign(reqObj.query, {
            filterBirthdayDateRange: params?.filterBirthdayDateRange
              ? JSON.stringify([
                params?.filterBirthdayDateRange[0] ? params?.filterBirthdayDateRange[0] : null,
                params?.filterBirthdayDateRange[1] ? params?.filterBirthdayDateRange[1] : null,
              ])
              : JSON.stringify([null, null]),
            filterHasBirthday: !!params?.filterHasBirthday,
            filterHasDoesNotHaveBirthday: !!params?.filterHasDoesNotHaveBirthday,
            filterAnniversaryDateRange: params?.filterAnniversaryDateRange
              ? JSON.stringify([
                params?.filterAnniversaryDateRange[0] ? params?.filterAnniversaryDateRange[0] : null,
                params?.filterAnniversaryDateRange[1] ? params?.filterAnniversaryDateRange[1] : null,
              ])
              : JSON.stringify([null, null]),
            filterHasAnniversary: !!params?.filterHasAnniversary,
            filterHasDoesNotHaveAnniversary: !!params?.filterHasDoesNotHaveAnniversary,
            filterCreatedDateRange: params?.filterCreatedDateRange
              ? JSON.stringify([
                params?.filterCreatedDateRange[0] ? params?.filterCreatedDateRange[0] : null,
                params?.filterCreatedDateRange[1] ? params?.filterCreatedDateRange[1] : null,
              ])
              : JSON.stringify([null, null]),
            filterContactMethods: JSON.stringify(selectedContactMethods),
            filterLastMessageSentDateRange: params?.filterLastMessageSentDateRange
              ? JSON.stringify([
                params?.filterLastMessageSentDateRange[0] ? params?.filterLastMessageSentDateRange[0] : null,
                params?.filterLastMessageSentDateRange[1] ? params?.filterLastMessageSentDateRange[1] : null,
              ])
              : JSON.stringify([null, null]),
            filterLastMessageReceivedDateRange: params?.filterLastMessageReceivedDateRange
              ? JSON.stringify([
                params?.filterLastMessageReceivedDateRange[0] ? params?.filterLastMessageReceivedDateRange[0] : null,
                params?.filterLastMessageReceivedDateRange[1] ? params?.filterLastMessageReceivedDateRange[1] : null,
              ])
              : JSON.stringify([null, null]),
            filterLastMessageNeverSent: !!params?.filterLastMessageNeverSent,
            filterLastMessageNeverReceived: !!params?.filterLastMessageNeverReceived,
          });

        const respObj = await getRequest(reqObj);

        if (respObj?.results && respObj?.pageInfo) {
          const {
            results,
            assignedAssociates,
            pageInfo: { rowCount, pageCount },
            clientIds,
          } = respObj;
          let newResults = [...results];
          if (yn(props.auth.merchantConfig.LIMIT_ASSOCIATE_VISIBILITY_ENABLED) && props.auth.role === 'ASSOCIATE') {
            newResults = results.map(client => {
              const newClient = { ...client };

              if (
                assignedAssociates[newClient.id] &&
                assignedAssociates[newClient.id].find(item => item.id === props.auth.userId)
              ) {
                newClient.disabled = false;
              } else {
                newClient.disabled = true;
              }
              return newClient;
            });
          }
          setTablePaginationRowCount(rowCount, tableName);
          setTablePaginationPageCount(pageCount, tableName);
          if ((searchText || params) && page === 1) {
            setTableData(newResults);
            setClientAscts(assignedAssociates);
          } else {
            setTableData([...tableData, ...newResults]);
            setClientAscts({
              ...clientAscts,
              ...assignedAssociates,
            });
          }
          setClientFilterMyClients(null);

          const includes = props.client.selected_client_list?.includes(results[0]?.id);
          if (props.client.selected_client_list.length > 0 && includes) {
            setAllTags(true);
          } else setAllTags(false);

          // here
          if (newResults.length > 0) {
            setIsThereMoreClient(true);
          } else {
            setIsThereMoreClient(false);
            // if (showStoreFilter) {
            //   setZeroResults(true);
            // }
          }

          if (newResults.length < 25) {
            setIsThereMoreClient(false);
          }
        }
        setLoadingStatus(false);
      }
    },
    [getRequest, params, props, clientAscts],
  );

  const exportClients = useCallback(
    async searchText => {
      setExportClientListModal(false);
      setLoadingDescription("Sit tight! We're compiling your list.");
      addLoadingList('export-client-list');
      const {
        pagination: { pageSize, sortColumn, ascendingColumn },
        auth: { merchantId },
      } = props;

      let selectedContactMethods = [];
      if (!params?.filterContactMethods || params?.filterContactMethods === 'all') {
        selectedContactMethods = 'all';
      } else {
        selectedContactMethods = params?.filterContactMethods;
      }

      let selectedStores = [];
      if (!params?.stores || params?.stores === 'all') {
        selectedStores = [0];
      } else {
        selectedStores = params?.stores;
      }

      let selectedAssociates = [];
      if (!params?.associates || params?.associates === 'all') {
        selectedAssociates = [];
      } else if (params?.associates === 'none') {
        selectedAssociates = ['none'];
      } else {
        selectedAssociates = params?.associates;
      }

      let selectedTags = [];
      if (!params?.tags || params?.tags === 'all') {
        selectedTags = [];
      } else if (params?.tags === 'none') {
        selectedTags = 'none';
      } else {
        selectedTags = params?.tags.map(item => +item);
      }
      if (selectedStores.length > 0) {
        await new Promise(resolve => {
          setTimeout(resolve, 300);
        });

        const reqObj = {
          params: ['clients', 'export'],
          query: {
            selectedStores: JSON.stringify(selectedStores),
            filterTags: JSON.stringify(selectedTags),
            myClients: props.filters.myClients,
            filterAssociates: JSON.stringify(selectedAssociates),
            page,
            pageSize,
            sort_order: sortColumn,
            sort_direction: ascendingColumn,
            search: debounceSearch,
          },
          loadingStatusOff: true,
        };
        // eslint-disable-next-line no-unused-expressions
        flags.clientSegmentation &&
          Object.assign(reqObj.query, {
            filterBirthdayDateRange: params?.filterBirthdayDateRange
              ? JSON.stringify([
                params?.filterBirthdayDateRange[0] ? params?.filterBirthdayDateRange[0] : null,
                params?.filterBirthdayDateRange[1] ? params?.filterBirthdayDateRange[1] : null,
              ])
              : JSON.stringify([null, null]),
            filterHasBirthday: !!params?.filterHasBirthday,
            filterHasDoesNotHaveBirthday: !!params?.filterHasDoesNotHaveBirthday,
            filterAnniversaryDateRange: params?.filterAnniversaryDateRange
              ? JSON.stringify([
                params?.filterAnniversaryDateRange[0] ? params?.filterAnniversaryDateRange[0] : null,
                params?.filterAnniversaryDateRange[1] ? params?.filterAnniversaryDateRange[1] : null,
              ])
              : JSON.stringify([null, null]),
            filterHasAnniversary: !!params?.filterHasAnniversary,
            filterHasDoesNotHaveAnniversary: !!params?.filterHasDoesNotHaveAnniversary,
            filterCreatedDateRange: params?.filterCreatedDateRange
              ? JSON.stringify([
                params?.filterCreatedDateRange[0] ? params?.filterCreatedDateRange[0] : null,
                params?.filterCreatedDateRange[1] ? params?.filterCreatedDateRange[1] : null,
              ])
              : JSON.stringify([null, null]),
            filterContactMethods: JSON.stringify(selectedContactMethods),
            filterLastMessageSentDateRange: params?.filterLastMessageSentDateRange
              ? JSON.stringify([
                params?.filterLastMessageSentDateRange[0] ? params?.filterLastMessageSentDateRange[0] : null,
                params?.filterLastMessageSentDateRange[1] ? params?.filterLastMessageSentDateRange[1] : null,
              ])
              : JSON.stringify([null, null]),
            filterLastMessageReceivedDateRange: params?.filterLastMessageReceivedDateRange
              ? JSON.stringify([
                params?.filterLastMessageReceivedDateRange[0] ? params?.filterLastMessageReceivedDateRange[0] : null,
                params?.filterLastMessageReceivedDateRange[1] ? params?.filterLastMessageReceivedDateRange[1] : null,
              ])
              : JSON.stringify([null, null]),
            filterLastMessageNeverSent: !!params?.filterLastMessageNeverSent,
            filterLastMessageNeverReceived: !!params?.filterLastMessageNeverReceived,
          });

        const dataArray = await getRequest(reqObj);
          if (dataArray.length > 0) {
            const dataObj = dataArray[0];
            const { prefData, clientData } = dataObj;

            const prefObj = {};
            const newPrefHeaders = [...headers];
            prefData.forEach(prod => {
              prefObj[prod.name] = '';
              newPrefHeaders.push({ label: prod.name, key: prod.name });
            });
            setPrefHeaders(newPrefHeaders);
            const clientObj = {};

            clientData.forEach(cl => {
              if (!clientObj?.[`${cl.id}`]) {
                clientObj[`${cl.id}`] = {
                  id: cl.id,
                  name: cl.name ? cl.name : '',
                  email: cl.email ? cl.email : '',
                  mobile: cl.mobile ? formatPhoneNumber(cl.mobile) : '',
                  address_1: cl.address_1 ? `${cl.address_1}` : '',
                  city: cl.city ? `${cl.city}` : '',
                  address_2: cl.address_2 ? `${cl.address_2}` : '',
                  postal_code: cl.postal_code ? `${cl.postal_code}` : '',
                  state: cl.state ? `${cl.state}` : '',
                  birthday: cl.birthday ? dateFormat(cl.birthday) : '', // moment format these 2 dates
                  anniversary: cl.anniversary ? dateFormat(cl.anniversary) : '',
                  pos_ref_num: cl.pos_ref_num,
                  associates: cl.associates,
                  tags: cl.tags,
                  opted_out: cl.opted_out,
                  ...prefObj,
                };
              }
              cl.personalizations.forEach(({ name, value }) => {
                clientObj[`${cl.id}`][name] = value;
              });
              // clientObj[`${cl.id}`][`${cl.personalization_name}`] = cl.personalization_value;
            });

            const newDataObj = Object.keys(clientObj).map(key => {
              return clientObj[key];
            });
            setDownloadData([...newDataObj]);
          }
          removeLoadingList('export-client-list');
          setLoadingDescription(null);
      }
    },
    [getRequest, params, props, clientAscts],
  );

  // update client list when filters change
  const watchFilters = useMemo(() => {
    return filters.reduce((acc, param) => {
      const paramValue = new URLSearchParams(window.location.search).get(param) || '';
      return { ...acc, [param]: paramValue };
    }, {});
  }, [window.location.search]);
  useEffect(() => {
    setPage(1)
    document.getElementById('scrollableDivChat')?.scrollTo(0, 0);
    getClients();
    setSelectedClientList([]);
    setAllTags(false);
  }, [
    watchFilters.filterBirthdayDateRange,
    watchFilters.filterHasBirthday,
    watchFilters.filterHasDoesNotHaveBirthday,
    watchFilters.filterHasDoesNotHaveAnniversary,
    watchFilters.filterHasAnniversary,
    watchFilters.filterAnniversaryDateRange,
    watchFilters.filterLastMessageNeverSent,
    watchFilters.filterLastMessageSentDateRange,
    watchFilters.filterLastMessageNeverReceived,
    watchFilters.filterLastMessageReceivedDateRange,
    watchFilters.filterCreatedDateRange,
    watchFilters.filterContactMethods,
    watchFilters.stores,
    watchFilters.associates,
    watchFilters.tags,
  ]);

  const getTagsAndAssociates = async () => {
    const {
      auth: { userId, role },
    } = props;
    if (props.auth.merchantId) {
      const storeIds = [];
      props.auth.stores.forEach(store => {
        if (store.id !== 0) {
          storeIds.push(store.id);
        }
      });
      const reqObj = {
        params: ['clients', props.auth.merchantId, 'tagsAndAssociates'],
        query: {
          userId,
          role,
          storeIds: [...storeIds],
        },
      };

      await new Promise(resolve => {
        setTimeout(resolve, 500);
      });

      setLoadingStatus(true);

      const responseData = await getRequest(reqObj);

      if (responseData && responseData.length > 0) {
        const dataObj = responseData[0];
        const { tags, associates } = dataObj;
        const sortedTags = tags.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (b.name.toLowerCase() > a.name.toLowerCase()) {
            return -1;
          }
          return 0;
        });
        const dropDownTags = sortedTags.map(tag => ({
          label: tag.name,
          tagColor: tag.color,
          value: tag.id,
        }));
        const dropDownAscts = associates.map(asct => ({
          label: `${asct.first_name || ''} ${asct.last_name || ''}`,
          value: asct.id,
        }));
        setTagFilterList([...dropDownTags]);
        setAsctFilterList([...dropDownAscts]);
      }

      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    if (downloadData.length > 0) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setDownloadData([]);
      });
    }
  }, [downloadData]);

  useEffect(() => {
    isMounted.current = true;
    const {
      auth: { merchantId },
    } = props;
    if (merchantId) {
      getTagsAndAssociates();
      getClients();
    }
    return () => {
      isMounted.current = false;
    };
  }, [
    props.auth.stores,
    props.filters.clientFilterTags,
    props.filters.clientFilterAssociates,
    props.filters.myClients,
    props.auth.storeId,
    page,
  ]);


  useEffect(() => {
    if (debounceSearch !== '') {
      setPage(1);
      setSelectedClientList([]);
      setAllTags(false);
      setLoadingSearch(true);
      getClients('search').finally(() => setLoadingSearch(false));
      document.getElementById('scrollableDivChat')?.scrollTo(0, 0);
    } else {
      setPage(1);
      setSelectedClientList([]);
      setAllTags(false);
      setLoadingSearch(true);
      getClients().finally(() => setLoadingSearch(false));
      document.getElementById('scrollableDivChat')?.scrollTo(0, 0);
    }
  }, [debounceSearch]);

  const listAdder = id => {
    const tempArr = props.client.selected_client_list;
    const clicked = tempArr.includes(id);

    if (clicked) {
      const spliceArray = tempArr.indexOf(id);
      if (spliceArray !== -1) {
        tempArr.splice(spliceArray, 1);
        setSelectedClientList(tempArr);
      }
      setAllTags(false);
    } else if (!clicked) {
      tempArr.push(id);
      setSelectedClientList(tempArr);
      if (tempArr.length === tableData.length) {
        setAllClientsChecked(true);
      } else {
        setAllClientsChecked(false);
      }
    }
  };

  return (
    <div className="" style={{ borderRight: '1px solid #EBEBEB' }}>
      {/* client filter */}
      <ClientsFilter
        layoutClass="clientFilter"
        merchantId={props.auth?.merchantId}
        userId={props.auth?.userId}
        role={props.auth?.role}
        open={props.clientFilterOpen}
        contactMethodOptions={contactMethod}
        associates={asctFilterList}
        stores={props.auth.stores.filter(s => s.id !== 0)}
        tags={tagFilterList}
        setOpen={value => props.setClientFilterOpen(value)}
        setHasFilter={value => setHasFilter(value)}
      />
      {/* client list portion */}
      <div className="m-top-15">
        <ClientHeader
          setSearch={setSearch}
          search={search}
          setClientFilterOpen={props.setClientFilterOpen}
          clientFilterOpen={props.clientFilterOpen}
          getCSVData={() => setExportClientListModal(true)}
          auth={props.auth}
          client={props.client}
          stores={props.auth.stores}
          getClientData={props.getClientData}
          hasFilter={hasFilter}
          loadingSearch={loadingSearch}
        />
        <ListOfClients
          getClientData={props.getClientData}
          setAllTags={setAllTags}
          pagination={props.pagination}
          allTags={allTags}
          clientAscts={clientAscts}
          tableData={tableData}
          setTableData={setTableData}
          search={search}
          listAdder={listAdder}
          setCheckedClient={setCheckedClient}
          checkedClient={checkedClient}
          setAllClientsChecked={setAllClientsChecked}
          setSelectedClientList={setSelectedClientList}
          selected_client_list={props.client.selected_client_list}
          client={props.client}
          auth={props.auth}
          setPage={setPage}
          page={page}
          isThereMoreClient={isThereMoreClient}
          getClients={getClients}
        />
      </div>
      {downloadData.length > 0 ? (
        <CSVLink data={[...downloadData]} headers={prefHeaders} ref={csvInstance} filename="ClientList.csv" />
      ) : null}
      {exportClientListModal && (
        <PopConfirm
          confirm="Would you like to export your client list?"
          onCancel={() => setExportClientListModal(false)}
          onConfirm={() => exportClients(search)}
        />
      )}
    </div>
  );
};

export default ClientList;
