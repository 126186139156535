import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StandardLayout from '../../../../../Layout/StandardLayout';
import Button from '../../../../../Layout/Button';
import StyledLink from '../../../../../Layout/StyledLink';
import SecondaryPanel from '../../../../../Layout/SecondaryPanel';
import PopConfirm from '../../../../../Layout/PopConfirm';
import { notificationSuccess, notificationError } from '../../../../../../core/actions';
import { postRequest, deleteRequest, getRequest } from '../../../../../../core/apiRequests';
import { setListId } from '../../../../../../reducers/autoRemMsgs';
import { setStageName, setStageId, setStageWeight, setAddEditStage } from '../../../../../../reducers/salesPipeline';
import AutomationTable from '../../../../../Layout/AutomationTable';
import DropdownSelect from '../../../../../Layout/DropdownSelect';

const AddEditPipelineStage = props => {
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  // const [redirectBack, setRedirectBack] = useState(false);
  const history = useHistory();
  const { pipelineId } = props.match.params;

  useEffect(() => {
    if (window.location.href.includes('add-pipeline-stage')) {
      if (props.preferenceId) {
        setStageId('');
        setStageName('');
        setStageWeight('0.00');
        setListId('');
        setAddEditStage('Add');
      }
    } else if (window.location.href.includes('edit-pipeline-stage') && props.merchantId) {
      // edit-sales-pipeline
      const { listItemId } = props.match.params;
      setAddEditStage('Edit');
      (async () => {
        const reqObj = {
          params: ['salesOpp', props.merchantId, pipelineId, 'stage'],
          query: { stageId: listItemId },
        };
        const data = await getRequest(reqObj);
        if (data && data.length > 0) {
          const { name, id, weight } = data[0];
          setStageName(name);
          setStageId(id);
          setStageWeight(weight);
        } else {
          history.push('/Configuration/sales-pipelines');
        }
      })();
    }
  }, [props.merchantId]);

  const selectArray = () => {
    const arr = [];
    let i = 0;
    while (i < 1.01) {
      arr.push({
        label: `${Math.round(i.toFixed(2) * 100)}%`,
        value: i.toFixed(2),
      });
      i += 0.05;
    }
    return arr;
  };

  const confirmDelete = () => {
    const { firstName, lastName } = props;
    const fn = firstName || '';
    const ln = lastName || '';
    (async () => {
      const reqObj = {
        params: ['salesOpp', props.merchantId, pipelineId, 'stage', props.spStageId],
        query: { deletedBy: `${fn} ${ln}`.trim() },
      };

      const data = await deleteRequest(reqObj);
      if (data) {
        notificationSuccess('Stage Removed');
        history.push('/Configuration/sales-pipelines');
      }
    })();
  };

  const editPref = () => {
    const { spStageName, spStageWeight, firstName, lastName } = props;
    const fn = firstName || '';
    const ln = lastName || '';
    if (!spStageName) {
      notificationError('Pipeline Name Required');
    } else if (!spStageWeight) {
      notificationError('Default Weight Required');
    } else {
      (async () => {
        const dataObj = {
          name: spStageName,
          weight: spStageWeight,
        };
        if (props.spStageId) {
          dataObj.id = props.spStageId;
        }
        const reqObj = {
          params: ['salesOpp', props.merchantId, props.pipelineId, 'stage'],
          data: dataObj,
          query: {
            createdBy: `${fn} ${ln}`.trim(),
          },
        };
        const data = await postRequest(reqObj);
        if (data.id) {
          if (props.spStageId) {
            notificationSuccess('Opportunity Stage Updated');
          } else {
            notificationSuccess('Opportunity Stage Created');
            history.push(
              `/Configuration/sales-pipelines/edit-sales-pipeline/${props.pipelineId}/edit-pipeline-stage/${data.id}`,
            );
          }
          setStageId(data.id);
          setListId(data.id);
          setAddEditStage('Edit');
        } else {
          // error handling?
        }
      })();
    }
  };

  return (
    <StandardLayout
      title={`${props.spStageAddOrEdit} Opportunity Stage`}
      description={
        props.spStageAddOrEdit === 'Add'
          ? 'Add an opportunity stage to track client sales processes'
          : 'Edit this opportunity stage'
      }
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Sales Opportunities', to: '/Configuration/sales-pipelines' },
        {
          title: 'Edit Sales Opportunity',
          to: `/Configuration/sales-pipelines/edit-sales-pipeline/${props.match.params.pipelineId}`,
        },
        { title: `${props.spStageAddOrEdit} Opportunity Stage` },
      ]}
      classProps="standard-width-with-sidebar"
    >
      <div className="align-left flex-col-left w-600">
        <input
          className="m-btm-15 m-top-15 fs-13 outline-none rem-pad-width max-w-600 w-100-P input-height p-left-17"
          value={props.spStageName}
          onChange={e => setStageName(e.target.value)}
          placeholder="Stage Name"
        />
        <div className=" max-w-600 w-100-P inputHeight" style={{ position: 'relative' }}>
          <DropdownSelect
            classProps="w-100-P align-left"
            options={selectArray()}
            placeholder="Probability of closing when in this stage"
            value={props.spStageWeight}
            onChange={obj => {
              setStageWeight(obj.value);
            }}
          />
        </div>
      </div>
      <div className="max-w-554" />
      <div className="flex-col-center m-top-37  w-600">
        <button className="save-button-v2 m-btm-8" onClick={editPref}>
          Save
        </button>
        {window.location.href.includes('edit-pipeline-stage') ? (
          <Button
            classname="deleteButton-v2 m-btm-8"
            style={{ marginBottom: '15px' }}
            onclick={() => setShowPopConfirm(true)}
          >
            Delete
          </Button>
        ) : (
          <div />
        )}
        <StyledLink to={`/Configuration/sales-pipelines/edit-sales-pipeline/${props.match.params.pipelineId}`}>
          <button className="cancel-button-v2 ">Cancel</button>
        </StyledLink>
      </div>
      {props.spStageAddOrEdit === 'Edit' ? (
        <AutomationTable
          match={props.match}
          redirectToReminderSettings={`/Configuration/sales-pipelines/edit-sales-pipeline/${props.match.params.pipelineId}/edit-pipeline-stage/${props.match.params.listItemId}`}
          redirectToAutoMsgSettings={`/Configuration/sales-pipelines/edit-sales-pipeline/${props.match.params.pipelineId}/edit-pipeline-stage/${props.match.params.listItemId}`}
          redirectToAutoWhatsappSettings={`/Configuration/sales-pipelines/edit-sales-pipeline/${props.match.params.pipelineId}/edit-pipeline-stage/${props.match.params.listItemId}`}
          type="salesOpp"
        />
      ) : null}
      {showPopConfirm ? (
        <PopConfirm
          confirm={`Are you sure you want to remove "${props.spStageName}" stage?`}
          description="This will permanently delete this Opportunity Stage"
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={confirmDelete}
        />
      ) : null}
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.salesPipeline,
  ...state.auth,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AddEditPipelineStage);
