import { deleteRequest, postRequest, putRequest } from "../core/apiRequests";


export const createSalesTrigger = async ({ data, loadingStatusOff = false, axiosConfig = {}, query = {} }) => {
  const reqObj = {
    params: ['salesTrigger'],
    data,
    loadingStatusOff,
    axiosConfig,
    query
  };

  return await postRequest(reqObj);
};

export const updateSalesTrigger = async ({ data, loadingStatusOff = false, axiosConfig = {}, query = {} }) => {
  const reqObj = {
    params: ['salesTrigger', data.id],
    data,
    loadingStatusOff,
    axiosConfig,
    query
  };

  return await putRequest(reqObj);
}

export const deleteSalesTrigger = async ({ id, loadingStatusOff = false, axiosConfig = {}, query = {} }) => {
  const reqObj = {
    params: ['salesTrigger', 0, 0, id],
    loadingStatusOff,
    axiosConfig,
    query
  };

  return await deleteRequest(reqObj);
}
