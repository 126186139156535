import Axios from 'axios';
import {
  notificationSuccess,
  notificationError,
  setTablePagination,
  setTablePaginationRowCount,
  setTablePaginationPageCount,
} from './actions';
import { addLoadingList, removeLoadingList, setSavingStatus, setSurveyStatus } from '../reducers/notification';
import store from './store.js';
import { API_URL } from '../constants';
import { v4 } from 'uuid'

const moment = require('moment');

export const importLargeData = (type, merchantId, data, getOrSave, extraQuery) => {
  return new Promise((resolve, reject) => {
    Axios.post(`${API_URL}/xa/${type}/${merchantId}/${getOrSave || 'save'}${extraQuery || ''}`, data)
      .then(res => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          resolve(false);
        }
      })
      .catch(err => {
        resolve(false);
      });
  });
};

export const getList = (type, primaryId, endpoint, extraId, query) => {
  if (primaryId) {
    return new Promise(resolve => {
      Axios.get(`${API_URL}/xa/${type}/${primaryId}/${extraId}/${endpoint}${query || ''}`)
        .then(resp => {
          // TODO I also need to authenticate these requests with the token eventually
          if (Array.isArray(resp.data) && resp.data.length > 0) {
            resolve(resp.data);
          } else {
            resolve([]);
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data &&
            typeof err.response.data === 'string' &&
            err.response.data.length < 50
          ) {
            // notificationError(err.response.data);
          } else if (err.response && err.response.statusText && typeof err.response.statusText === 'string') {
            // notificationError(err.response.statusText);
          }
          resolve([]);
          console.error(`Error making ${type} ${extraId} [getList] request`, err);
        });
    });
  }
  console.warn('[getList API]: No merchant ID detected, perhaps waiting on auth confirmation');
  return [];
};

export const getAll = (type, primaryId, query) => {
  // TODO: revert this
  return new Promise(resolve => {
    Axios.get(`${API_URL}/xa/${type}/${primaryId}${query ? `${query}` : ''}`)
      .then(resp => {
        // TODO I also need to authenticate these requests with the token eventually
        if (Array.isArray(resp.data) && resp.data.length > 0) {
          resolve(resp.data);
        } else {
          resolve([]);
        }
      })
      .catch(err => {
        console.error(`Error making ${type} list request`, err);
        // TODO error handling
      });
  });
};

export const getTablePagList = async ({
  tableName,
  type,
  merchantId,
  page = 1,
  count = 25,
  sortColumn,
  ascendingColumn: ascending = true,
  searchText = '',
  additionalQuery = '',
}) => {
  if (!merchantId) {
    console.warn('[getTablePagList API]: No merchant ID detected, perhaps waiting on auth confirmation');
    return [];
  }
  try {
    const { data } = await Axios.get(
      `${API_URL}/xa/${type}/${merchantId}/paginatedList?page=${page}&count=${count}&sortColumn=${sortColumn}&ascending=${ascending}&searchText=${searchText}${
        additionalQuery || ''
      }`,
    );
    console.log(`[getTablePagList API]${type}`, { data });
    if (!Array.isArray(data?.results)) {
      return [];
    }
    const {
      results,
      pageInfo: { rowCount, pageCount },
    } = data;
    setTablePagination({ rowCount, pageCount }, tableName);
    return results;
  } catch (e) {
    console.error(`Error making ${type} paginated list request`, e);
    throw e;
  }
};

export const saveListItem = (type, merchantId, data, createdBy) => {
  setSavingStatus(true);
  return new Promise(resolve => {
    Axios.post(`${API_URL}/xa/${type}/${merchantId}/save?createdBy=${createdBy}`, {
      // Axios.post(`${API_URL}/xa/${type}/${merchantId}`, {
      ...data,
    })
      .then(resp => {
        setSavingStatus(false);
        if (resp.data && resp.data.id) {
          resolve(resp.data);
        } else {
          resolve({});
        }
      })
      .catch(err => {
        setSavingStatus(false);
        console.error('Error making actionConfig get request', err);
        console.error(err.response);
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string' &&
          err.response.data.length < 50
        ) {
          // notificationError(err.response.data);
        } else if (err.response && err.response.statusText && typeof err.response.statusText === 'string') {
          // notificationError(err.response.statusText);
        }
        resolve({}); // TODO proper error handling
      });
  });
};

export const deleteListItem = (type, merchantId, itemId, deletedBy, param) => {
  setSavingStatus(true);
  return new Promise(resolve => {
    Axios.delete(`${API_URL}/xa/${type}/${merchantId}/${param || 'remove'}/${itemId}?deletedBy=${deletedBy}`)
      .then(resp => {
        setSavingStatus(false);
        if (resp.data == 'OK') {
          resolve(true);
        }
      })
      .catch(err => {
        setSavingStatus(false);
        console.error(`Error making ${type} delete request`, err);
        resolve(false); // TODO proper error handling
      });
  });
};

export const getActionConfig = (type, merchantId, listItemId, configType, optionalId) => {
  return new Promise(resolve => {
    const uri = `${API_URL}/xa/${type}/${merchantId}/${listItemId}/${configType}${
      optionalId ? `?optionalId=${optionalId}` : ''
    }`;
    console.log({ uri });
    Axios.get(uri)
      .then(resp => {
        if (Array.isArray(resp.data) && resp.data.length > 0) {
          resolve(resp.data);
        } else {
          resolve([]);
        }
      })
      .catch(err => {
        console.error(`Error making ${configType} get request`, err);
        resolve([]); // TODO proper error handling
      });
  });
};

export const delActMsgConfig = (type, merchantId, tagId, msgConfigId, configType, deletedBy) => {
  setSavingStatus(true);
  return new Promise(resolve => {
    Axios.delete(`${API_URL}/xa/${type}/${merchantId}/${tagId}/${configType}/${msgConfigId}?deletedBy=${deletedBy}`)
      .then(resp => {
        setSavingStatus(false);
        if (resp.data == 'OK') {
          resolve(true);
        }
      })
      .catch(err => {
        setSavingStatus(false);
        console.error('Error making tag delete request', err);
        resolve(false); // TODO proper error handling
        if (err.response && err.response.data && typeof err.response.data === 'string') {
          // notificationError(err.response.data);
        }
      });
  });
};
//
export const getSettingList = (type, merchantId, settingId, endpoint, sortColumn, ascending, stageId) => {
  return new Promise(resolve => {
    Axios.get(
      `${API_URL}/xa/${type}/${merchantId}/${settingId}/${endpoint}?sortColumn=${sortColumn}&ascending=${ascending}${
        stageId ? `&stageId=${stageId}` : ''
      }`,
    )
      .then(resp => {
        if (Array.isArray(resp.data) && resp.data.length > 0) {
          resolve(resp.data);
        } else {
          resolve([]);
        }
      })
      .catch(err => {
        console.error(`Error making ${type} list request`, err);
        // notificationError('Internal Server Error'); //TODO error handling
      });
  });
};

export const saveSetting = (type, merchantId, settingId, endpoint, data, createdBy) => {
  setSavingStatus(true);
  return new Promise(resolve => {
    Axios.post(`${API_URL}/xa/${type}/${merchantId}/${settingId}/${endpoint}?createdBy=${createdBy}`, {
      ...data,
    })
      .then(resp => {
        setSavingStatus(false);
        if (resp.data) {
          resolve(resp.data);
        } else {
          resolve(false);
          // notificationError('Error saving ', type);
        }
      })
      .catch(err => {
        setSavingStatus(false);
        console.error(`Error saving setting ${type} request`, err);
        resolve([]);
        // notificationError('Error saving');
      });
  });
};
// '/xa/salesOpp/:merchantId/:pipelineId/stage/:stageId'
export const deleteSetting = (type, merchantId, listId, endpoint, settingId, deletedBy) => {
  setSavingStatus(true);
  return new Promise(resolve => {
    Axios.delete(`${API_URL}/xa/${type}/${merchantId}/${listId}/${endpoint}/${settingId}?deletedBy=${deletedBy}`)
      .then(resp => {
        setSavingStatus(false);
        if (resp.data) {
          resolve(resp.data);
        } else {
          // notificationError('Error saving ', type);
        }
      })
      .catch(err => {
        setSavingStatus(false);
        console.error(`Error deleting setting ${type} request: `, err);
        resolve([]);
        // notificationError('Error saving');
      });
  });
};

export const saveMessageTemplate = (type, merchantId, reminderId, data) => {
  setSavingStatus(true);
  return new Promise(resolve => {
    Axios.post(`${API_URL}/xa/${type}/${merchantId}/${reminderId}/messageTemplates`, {
      ...data,
    })
      .then(resp => {
        setSavingStatus(false);
        resolve(resp.data);
      })
      .catch(err => {
        setSavingStatus(false);
        resolve({});
        // notificationError('Error ');
        console.log('Error saving message template: ', err);
      });
  });
};

export const saveMessageSettings = (type, merchantId, data, param) => {
  setSavingStatus(true);
  const imageFormObj = new FormData();
  imageFormObj.append('text_data', JSON.stringify(data));
  imageFormObj.append('logo_url', data.logo_url);
  imageFormObj.append('background_url', data.background_url);
  if (data?.REVIEW_IMAGES_URL?.videos?.files?.length > 0) {
    for (const [i, vFile] of data?.REVIEW_IMAGES_URL?.videos?.files?.entries()) {
      const imFile = data?.REVIEW_IMAGES_URL?.videos?.videoFrames?.files[i];
      imageFormObj.append(`review_video_${i}`, vFile);
      imageFormObj.append(`review_video_frame_${i}`, imFile);
    }
  }
  if (data?.REVIEW_IMAGES_URL?.images?.files?.length > 0) {
    data?.REVIEW_IMAGES_URL?.images?.files?.forEach((imFile, i) => {
      if (imFile) {
        imageFormObj.append(`review_image_${i}`, imFile);
      }
    });
  }
  const images_urls = [];
  if (data?.REVIEW_IMAGES_URL?.images?.urls?.length > 0) {
    data?.REVIEW_IMAGES_URL?.images?.urls.forEach(url => {
      if (!url?.includes('blob:')) {
        images_urls.push(url);
      }
    });
  }
  if (data?.REVIEW_IMAGES_URL?.videos?.urls?.length > 0) {
    data?.REVIEW_IMAGES_URL?.videos?.urls.forEach(url => {
      if (!url?.includes('blob:')) {
        images_urls.push(url);
      }
    });
  }
  imageFormObj.append('images_urls', images_urls.join(','));
  return new Promise(resolve => {
    Axios.post(`${API_URL}/xa/${type}/${merchantId}/${param || 'config'}`, imageFormObj)
      .then(resp => {
        setSavingStatus(false);
        resolve(resp);
        notificationSuccess('Settings Saved!');
        console.log('Save message settings resp: ', resp);
      })
      .catch(err => {
        setSavingStatus(false);
        if (err.response && err.response.data) {
          // notificationError(err.response.data);
        }
        console.error(`Error saving Message Settings: ${err}`);
      });
  });
};

export const getMessageSettings = (type, merchantId) => {
  return new Promise(resolve => {
    Axios.get(`${API_URL}/xa/${type}/${merchantId}/config`)
      .then(resp => {
        if (resp.data) {
          resolve(resp.data);
        } else {
          resolve(false);
        }
      })
      .catch(err => {
        console.error('Error getting message settings: ', err);
        resolve(false);
        // notificationError('Error Getting Config');
      });
  });
};

export const getSingleDetails = (type, merchantId, singleId, customParam) => {
  return new Promise(resolve => {
    Axios.get(`${API_URL}/xa/${type}/${customParam || 'details'}/${merchantId}/${singleId}`)
      .then(resp => {
        if (resp.data) {
          resolve(resp.data);
        } else {
          resolve(false);
        }
      })
      .catch(err => {
        console.error('Error getting client details: ', err);
        resolve(false);
        // notificationError('Error Getting Client Details');
      });
  });
};

export const getPagAssocDetails = (
  tableName,
  associateId,
  merchantId,
  page,
  count,
  sortColumn,
  ascending,
  searchText,
) => {

  const uuid = v4();
  addLoadingList(uuid);
  return new Promise(resolve => {
    Axios.get(
      `${API_URL}/xa/associates/allAssociateDetails/${merchantId}/${associateId}?page=${page}&count=${count}&sortColumn=${sortColumn}&ascending=${ascending}&searchText=${searchText}`,
    ) // TODO wrap this up on backend
      .then(resp => {
        if (resp.data && Array.isArray(resp.data.results) && resp.data.results) {
          const {
            results,
            pageInfo: { page, pageSize, rowCount, pageCount, type },
          } = resp.data;
          resolve(results);
          setTablePaginationRowCount(rowCount, tableName);
          setTablePaginationPageCount(pageCount, tableName);
        } else {
          resolve([]);
        }
      })
      .catch(err => {
        console.error('[getPagClientDetails] Error making getPagClientDetails paginated list request', err);
        resolve([]); // TODO proper error handling
      }).finally(() => {
        removeLoadingList(uuid);
      });
  });
};

export const getVideos = (type, merchantId, endpoint) => {
  return new Promise((resolve, reject) => {
    Axios.get(`${API_URL}/xa/${type}/${merchantId}/${endpoint}`)
      .then(res => {
        if (res.status === 200) {
          const sections = {};
          for (const row of res.data) {
            if (!sections[row.section_name]) {
              sections[row.section_name] = [];
            }
            sections[row.section_name].push(row);
          }
          resolve(sections);
        }
      })
      .catch(err => {
        reject();
      });
  });
};

export const getAllAssocDetails = (type, primaryId) => {
  // TODO: revert this
  return new Promise(resolve => {
    Axios.get(`${API_URL}/xa/${type}/${primaryId}/associateGraph`)
      .then(resp => {
        // TODO I also need to authenticate these requests with the token eventually
        if (Array.isArray(resp.data) && resp.data.length > 0) {
          resolve(resp.data);
        } else {
          resolve([]);
        }
      })
      .catch(err => {
        console.error(`Error making ${type} list request`, err);
        // TODO error handling
      });
  });
};

export const getRequest = configObj => {
  const {
    params, // ['today', merchantId, someId, 'update'] (will look like: 'today/:merchantId/:someId/update')
    query, // {created_by: 'christophe fosterro', end: false, page: 1, searchText: 'whatever', etc}
    loadingStatusOff,
    axiosConfig,
  } = configObj;
  const uuid = v4();
  if(!loadingStatusOff) {
    addLoadingList(uuid)
  }


  return new Promise(resolve => {
    Axios.get(`${API_URL}/xa/${params.join('/')}${queryMaker(query)}`, axiosConfig)
      .then(resp => {
        resolve(resp.data);
      })
      .catch(err => {
        console.error(`[getRequest] Error making ${params[0]} GET request`, err);
        if (err.response && err.response.status === 401) {
          window.location.replace('/login');
        }
        resolve(false);
        // notificationError(`Error Getting ${params[0]} Data`);
      })
      .finally(() => {
        removeLoadingList(uuid)
      });
  });
};

export const postRequest = configObj => {
  const {
    params, // ['today', merchantId, someId, 'update'] (will look like: 'today/:merchantId/:someId/update')
    data, // NOT for GET requests ///// {repeats: 1, actionScale: 12, actionInterval: 'WEEKS', associatePassowrd: "test", etc}
    query, // {created_by: 'christophe fosterro', end: false, page: 1, searchText: 'whatever', etc}
    errors,
    delay,
    cancelToken,
    loadingStatusOff,
  } = configObj;
  let dataObj = {};
  if (
    (configObj.images && configObj.images.files && configObj.images.files.length > 0) ||
    (configObj.videos && configObj.videos.files && configObj.videos.files.length > 0)
  ) {
    dataObj = new FormData();
    Object.keys(data).forEach(key => {
      // dataObj.append(key, JSON.stringify(data[key]));
      dataObj.append(key, data[key]);
    });
    if (configObj.videos) {
      for (const [i, vFile] of configObj.videos.files.entries()) {
        const imFile = configObj.videos.videoFrames.files[i];
        dataObj.append(`video_${i}`, vFile);
        dataObj.append(`video_frame_${i}`, imFile);
      }
    }
    if (configObj.images) {
      configObj.images.files.forEach((imFile, i) => {
        dataObj.append(`image_${i}`, imFile);
      });
    }
  } else {
    dataObj = data;
  }

  const axiosConfigObj = {};

  if (configObj.hasOwnProperty('cancelToken')) {
    axiosConfigObj.cancelToken = cancelToken;
  }

  if (loadingStatusOff) {
    setSavingStatus(false);
  } else setSavingStatus(true);
  return new Promise((resolve, reject) => {
    Axios({
      method: 'post',
      url: `${API_URL}/xa/${params.join('/')}${queryMaker(query)}`,
      data: dataObj,
      config: { ...axiosConfigObj },
      ...axiosConfigObj,
    })
      .then(resp => {
        findSatisfaction();
        setTimeout(() => {
          setSavingStatus(false);
          resolve(resp.data);
        }, delay || 0);
      })
      .catch(err => {
        setSavingStatus(false);
        console.error(`[postRequest] Error making ${params[0]} POST request`, err);
        if (err && configObj.hasOwnProperty('errors') && err.response && errors.hasOwnProperty(err.response.data)) {
          // notificationError(configObj.errors[err.response.data]);
        } else {
          // notificationError(`Error Sending ${params[0]} Data`);
        }
        reject(err);
      });
  });
};

export const putRequest = configObj => {
  const {
    params, // ['today', merchantId, someId, 'update'] (will look like: 'today/:merchantId/:someId/update')
    data, // NOT for GET requests ///// {repeats: 1, actionScale: 12, actionInterval: 'WEEKS', associatePassowrd: "test", etc}
    query, // {created_by: 'christophe fosterro', end: false, page: 1, searchText: 'whatever', etc}
    errors,
    delay,
    cancelToken,
    loadingStatusOff,
  } = configObj;

  const axiosConfigObj = {};

  if (configObj.hasOwnProperty('cancelToken')) {
    axiosConfigObj.cancelToken = cancelToken;
  }

  if (loadingStatusOff) {
    setSavingStatus(false);
  } else setSavingStatus(true);
  return new Promise((resolve, reject) => {
    Axios({
      method: 'put',
      url: `${API_URL}/xa/${params.join('/')}${queryMaker(query)}`,
      data,
      config: { ...axiosConfigObj },
      ...axiosConfigObj,
    })
      .then(resp => {
        findSatisfaction();
        setTimeout(() => {
          setSavingStatus(false);
          resolve(resp.data);
        }, delay || 0);
      })
      .catch(err => {
        setSavingStatus(false);
        console.error(`[putRequest] Error making ${params[0]} PUT request`, err);
        if (err && configObj.hasOwnProperty('errors') && err.response && errors.hasOwnProperty(err.response.data)) {
          // notificationError(configObj.errors[err.response.data]);
        } else {
          // notificationError(`Error Sending ${params[0]} Data`);
        }
        reject(err);
      });
  });
};

export const patchRequest = configObj => {
  const {
    params, // ['today', merchantId, someId, 'update'] (will look like: 'today/:merchantId/:someId/update')
    data, // NOT for GET requests ///// {repeats: 1, actionScale: 12, actionInterval: 'WEEKS', associatePassowrd: "test", etc}
    query, // {created_by: 'christophe fosterro', end: false, page: 1, searchText: 'whatever', etc}
    errors,
    delay,
    cancelToken,
    loadingStatusOff,
  } = configObj;

  const axiosConfigObj = {};

  if (configObj.hasOwnProperty('cancelToken')) {
    axiosConfigObj.cancelToken = cancelToken;
  }

  if (loadingStatusOff) {
    setSavingStatus(false);
  } else setSavingStatus(true);
  return new Promise((resolve, reject) => {
    Axios({
      method: 'patch',
      url: `${API_URL}/xa/${params.join('/')}${queryMaker(query)}`,
      data,
      config: { ...axiosConfigObj },
      ...axiosConfigObj,
    })
      .then(resp => {
        findSatisfaction();
        setTimeout(() => {
          setSavingStatus(false);
          resolve(resp.data);
        }, delay || 0);
      })
      .catch(err => {
        setSavingStatus(false);
        console.error(`[patchRequest] Error making ${params[0]} PATCH request`, err);
        if (err && configObj.hasOwnProperty('errors') && err.response && errors.hasOwnProperty(err.response.data)) {
          // notificationError(configObj.errors[err.response.data]);
        } else {
          // notificationError(`Error Sending ${params[0]} Data`);
        }
        reject(err);
      });
  });
};

const findSatisfaction = () => {
  const stateAuth = store.getState().auth;
  const { survey } = stateAuth;
  if (survey.hasOwnProperty('lastSnoozed') && survey.hasOwnProperty('lastAnswered')) {
    const lastSentCompare = moment(new Date()).subtract(4, 'months').format('YYYYMMDDHHmmss');
    const lastSnoozeCompare = moment(new Date()).subtract(60, 'day').format('YYYYMMDDHHmmss');
    const snoozed = survey.lastSnoozed ? survey.lastSnoozed : null;
    const lastAnswered = survey.lastAnswered ? survey.lastAnswered : null;
    if (snoozed && lastAnswered) {
      if (
        moment(lastAnswered, 'YYYYMMDDHHmmss').isBefore(moment(lastSentCompare, 'YYYYMMDDHHmmss')) &&
        moment(snoozed, 'YYYYMMDDHHmmss').isBefore(moment(lastSnoozeCompare, 'YYYYMMDDHHmmss'))
      ) {
        setSurveyStatus(true);
      } else {
      }
    } else if (snoozed && !lastAnswered) {
      if (moment(snoozed, 'YYYYMMDDHHmmss').isBefore(moment(lastSnoozeCompare, 'YYYYMMDDHHmmss'))) {
        setSurveyStatus(true);
      }
    } else if (lastAnswered && !snoozed) {
      if (moment(lastAnswered, 'YYYYMMDDHHmmss').isBefore(moment(lastSentCompare, 'YYYYMMDDHHmmss'))) {
        setSurveyStatus(true);
      }
    } else if (!snoozed && !lastAnswered) {
      setSurveyStatus(true);
    }
  }
};

export const deleteRequest = configObj => {
  const {
    params, // ['today', merchantId, someId, 'update'] (will look like: 'today/:merchantId/:someId/update')
    /// data, // NOT for DELETE requests ///// {repeats: 1, actionScale: 12, actionInterval: 'WEEKS', associatePassowrd: "test", etc}
    query, // {created_by: 'christophe fosterro', end: false, page: 1, searchText: 'whatever', etc}
    delay,
  } = configObj;
  return new Promise(resolve => {
    setSavingStatus(true);
    Axios.delete(`${API_URL}/xa/${params.join('/')}${queryMaker(query)}`)
      .then(resp => {
        setTimeout(() => {
          setSavingStatus(false);
          resolve(resp.data);
        }, delay || 0);
      })
      .catch(err => {
        setSavingStatus(false);
        console.error(`[deleteRequest] Error making ${params[0]} DELETE request`, err);
        // notificationError(`Error Deleting ${params[0]} Data`);
        resolve(false);
      });
  });
};

export function queryMaker(queryObj) {
  let queryString = '';
  if (queryObj) {
    if (Object.keys(queryObj).length > 0) {
      queryString += '?';
      queryString += Object.keys(queryObj)
        .map(qObj => {
          return `${qObj}=${queryObj[qObj]}`;
        })
        .join('&');
    }
  }
  return queryString;
}
