/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Drawer, Dropdown, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import ViewAll from '../../../ViewAll';
import PaginationV2 from '../../../../../../CustomizedAntDesign/PaginationV2';
import PaymentRequestCard from '../components/PaymentRequestCard';
import { API_URL } from '../../../../../../../constants';
import ConversationWindow from '../../../../../../Layout/chat/chat';
import { getClientChat, resetMessaging } from '../../../../../../../reducers/messaging';
import getClientPaymentStatus from '../../../../../../Layout/GetClientPaymentStatus';
import './styles.scss';
import { resetToday } from '../../../../../../../reducers/today';

const statusOptions = ['CANCELED', 'REFUNDED', 'PAID', 'CHARGEBACK', 'SENT', 'PAYMENT_FAILED', 'REFUND_FAILED'];

const AllClientPaymentRequestsDropdown = ({ currentValues, onChange }) => {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState([]);

  const handleSelectStatus = useCallback(
    e => {
      const { value } = e.target;

      if (status.includes(value)) {
        setStatus(status.filter(item => item !== value));
      } else {
        setStatus([...status, value]);
      }
    },
    [status],
  );

  const handleApply = useCallback(() => {
    onChange(status);
    setOpen(false);
  }, [status]);

  const handleClearStatus = useCallback(() => {
    onChange([]);
    setOpen(false);
  }, []);

  useEffect(() => {
    setStatus(currentValues);
  }, [open, currentValues]);

  const menuItems = useMemo(
    () =>
      statusOptions.map(item => ({
        value: item,
        label: (
          <Checkbox value={item} checked={status.includes(item)} onChange={handleSelectStatus}>
            {item.replace(/_/g, ' ')}
          </Checkbox>
        ),
      })),
    [status, handleSelectStatus],
  );

  return (
    <Dropdown
      open={open}
      menu={{ items: menuItems }}
      overlayClassName="cb-all-client-payment-requests--dropdown"
      dropdownRender={menu => (
        <div className="cb-all-client-payment-requests--dropdown-content">
          <div className="cb-all-client-payment-requests--header">
            <span className="cb-all-client-payment-requests--filter-title">Filter By</span>

            <Button type="text" className="cb-all-client-payment-requests--filter-clear" onClick={handleClearStatus}>
              Clear All
            </Button>
          </div>

          {React.cloneElement(menu, { style: { boxShadow: 'none' } })}

          <div className="cb-all-client-payment-requests--footer">
            <Button type="primary" onClick={handleApply} className="cb-all-client-payment-requests--filter-apply">
              APPLY FILTER
            </Button>
          </div>
        </div>
      )}
    >
      <Button type="text" size="large" icon={<FontAwesomeIcon icon="far fa-filter" />} onClick={() => setOpen(!open)} />
    </Dropdown>
  );
};

const AllClientPaymentRequests = props => {
  const { auth, client } = props;

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const clientId = useMemo(() => urlParams.get('client') || '', [urlParams]);

  const [loading, setLoading] = useState(false);
  const [paymentRequests, setPaymentRequests] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState([]);
  const [total, setTotal] = useState(0);
  const [showChatModal, setShowChatModal] = useState(false);

  const showPagination = useMemo(() => total > 10, [total]);

  const fetchPaymentRequests = useCallback(async () => {
    setTotal(0);
    setLoading(true);

    try {
      const params = {
        page,
        pageSize,
      };

      if (status.length) {
        params.status = JSON.stringify(status);
      }

      const response = await axios.get(`${API_URL}/xa/clients/${clientId}/payment-requests`, {
        params,
      });

      setPage(response.data.page);
      setPageSize(response.data.pageSize);
      setTotal(response.data.total);
      setPaymentRequests(response.data.results);
    } catch (error) {
      message.error('Error Fetching Payment Requests');
    } finally {
      setLoading(false);
    }
  }, [clientId, page, pageSize, status]);

  const handleOpenChat = useCallback(() => {
    getClientPaymentStatus({ client, auth });
    getClientChat(client);
    setShowChatModal(true);
  }, []);

  const handleCloseChat = useCallback(() => {
    setShowChatModal(false);
    resetMessaging();
    resetToday();
  }, []);

  useEffect(() => {
    fetchPaymentRequests();
  }, [clientId, page, pageSize, status]);

  return (
    <ViewAll
      title="All Payment Requests"
      setViewAllType={props.setViewAllType}
      actionsRight={<AllClientPaymentRequestsDropdown currentValues={status} onChange={setStatus} />}
    >
      <div className="cb-all-client-payment-requests">
        <div className="cb-all-client-payment-requests--container">
          <div className="cb-all-client-payment-requests--items">
            {loading && <div className="cb-all-client-payment-requests--subtitle">Loading...</div>}

            {!loading && paymentRequests.length === 0 && (
              <div className="cb-all-client-payment-requests--subtitle">No PaymentRequests</div>
            )}

            {!loading &&
              paymentRequests.length > 0 &&
              paymentRequests.map(item => (
                <PaymentRequestCard key={item.id} paymentRequest={item} onClick={handleOpenChat} />
              ))}
          </div>

          {showPagination && (
            <PaginationV2
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              total={total}
              showSizeChanger={false}
              className="cb-all-client-payment-requests--pagination"
            />
          )}
        </div>
      </div>

      <Drawer
        bodyStyle={{
          padding: 0,
          overflow: 'hidden',
        }}
        closable={false}
        push={false}
        destroyOnClose
        width={750}
        open={showChatModal}
        onClose={handleCloseChat}
      >
        <ConversationWindow setDrawerToggle={handleCloseChat} chatIconColor="#EBEBEB" type="right" />
      </Drawer>
    </ViewAll>
  );
};

const mapStateToProps = state => state;

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AllClientPaymentRequests);
