import { Button, Card, Checkbox, Form, InputNumber, Radio, Select, Tag, Tooltip } from "antd-v5";
import { FormItemVertical } from "../../../../../CustomizedAntDesign/FormItemVertical";
import { DeleteFilled, EditFilled, QuestionCircleOutlined, UndoOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const intervalOptions = [
  { label: 'Days', value: 'DAY' },
  { label: 'Weeks', value: 'WEEK' },
  { label: 'Months', value: 'MONTH' },
];

const getIntervalLabel = (value) => {
  return intervalOptions.find(interval => interval.value === value)?.label || '';
}

export default function ActionCard({
  edit,
  action,
  index,
  options,
  loading,
  setSelectedAction,
  triggerId,
  Controller,
  control,
  resetField,
  setValue,
  watch
}) {

  const {
    type,
    action_repeat: repeat,
    action_expires: expires,
    action_expires_overdue: overdue,
    isDeleted,
    action_id,
    action_scale,
    action_interval,
    repeat_scale,
    repeat_interval,
    expire_scale,
    expire_interval,
    assign_type,
  } = useMemo(() =>
    watch(
      `actions[${index}]`,
    ) || {},
  [watch(`actions[${index}]`), index]);


  const handleTypeChange = useCallback((e) => {
    const opt = options?.actions
    .filter(act => act.type === e.target.value)
    .map(act => ({
      label: act?.title,
      value: act?.action_id,
    }))
    setValue(`actions[${index}].action_id`, opt[0]?.value);
    setValue(`actions[${index}].type`, e.target.value);
  }, [options, index]);

  const contentOptions = useMemo(() => {
    return options?.actions
    .filter(act => act.type === type)
    .map(act => ({
      label: act?.title,
      value: act?.action_id,
    }))
  }, [options, type])

  const handleSave = useCallback(() => {
    setSelectedAction(null);
  }, []);

  const handleCancel = useCallback(() => {
    if(action?.id?.toString()?.includes('new')) {
      setValue(`actions[${index}].isDeleted`, true);
    } else {
      const findTrigger = options?.triggers?.find(tr => tr.trigger_id === parseInt(triggerId));
      if(!findTrigger) return;
      const findAction = findTrigger?.actionConfigs[action?.id];
      if(!findAction) return;
      resetField(`actions[${index}]`, findAction);
    }
    setSelectedAction(null);
  }, [index, action, options, triggerId]);

  const getAction = useMemo(() => {
    return options?.actions.find(act => act.action_id === action_id);
  }, [options, action_id]);

  const getTags = useMemo(() => {
    let tags = [];
    if(action_scale === 0) {
      tags.push('Same day');
    } else {
      tags.push(`After ${action_scale} ${getIntervalLabel(action_interval)}`);
    }
    if(repeat) {
      tags.push(`Repeats every ${repeat_scale} ${getIntervalLabel(repeat_interval)}`);
    }
    if(expires) {
      if(overdue) {
        tags.push(`Expires when overdue`);
      } else {
        tags.push(`Expires after ${expire_scale} ${getIntervalLabel(expire_interval)}`);
      }
    }
    return tags;
  }, [
    action_scale,
    action_interval,
    repeat,
    repeat_scale,
    repeat_interval,
    expires,
    expire_scale,
    expire_interval,
    overdue,
    type,
    assign_type
  ])

  const handleDeleteAction = useCallback(() => {
    setValue(`actions[${index}].isDeleted`, true);
    setSelectedAction(null);
  }, []);

  const handleUndoDeleteAction = useCallback(() => {
    setValue(`actions[${index}].isDeleted`, false);
  }, []);

  const handleRepeat = useCallback((e) => {
    setValue(`actions[${index}].action_repeat`, e.target.checked);
    if(e?.target?.checked) {
      setValue(`actions[${index}].repeat_scale`, 1);
      setValue(`actions[${index}].repeat_interval`, 'DAY');

    } else {
      setValue(`actions[${index}].repeat_scale`, null);
      setValue(`actions[${index}].repeat_interval`, null);
      setValue(`actions[${index}].action_expires`, null);
      setValue(`actions[${index}].expire_scale`, null);
      setValue(`actions[${index}].expire_interval`, null);
    }
  }, [index]);

  const handleExpires = useCallback((e) => {
    setValue(`actions[${index}].action_expires`, e.target.checked);
    if(e?.target?.checked) {
      setValue(`actions[${index}].expire_scale`, 1);
      setValue(`actions[${index}].expire_interval`, 'DAY');
    } else {
      setValue(`actions[${index}].expire_scale`, null);
      setValue(`actions[${index}].expire_interval`, null);
    }
  }, [index]);

  const handleExpireOverdue = useCallback((e) => {
    setValue(`actions[${index}].action_expires_overdue`, e.target.checked);
    if(e?.target?.checked) {
      setValue(`actions[${index}].expire_scale`, 0);
      setValue(`actions[${index}].expire_interval`, 'DAY');
    } else {
      setValue(`actions[${index}].expire_scale`, 1);
      setValue(`actions[${index}].expire_interval`, 'DAY');
    }
  }, [index]);

  return (edit ? (
      <Card
        style={{ width: '100%', marginBottom: 16 }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
            gap: 16,
          }}
        >
          <FormItemVertical
              label="Type"
            >
              <Controller
                  name={`actions[${index}].type`}
                  control={control}
                  render={({ field }) => (
                    <Radio.Group
                      options={[
                        { label: 'Reminder', value: 'REMINDER' },
                        { label: 'Message', value: 'MESSAGE' },
                      ]}
                      {...field}
                      onChange={e => handleTypeChange(e)}
                    />
                  )}
                />
            </FormItemVertical>
            <FormItemVertical
              label="Content"
            >
              <Form.Item
                noStyle
                name={["actions", index, "action_id"]}
                rules={[{ required: true, message: 'Please select an action' }]}
              >
              <Controller
                  name={`actions[${index}].action_id`}
                  control={control}
                  rules={{ required: 'Please select an action' }}
                  render={({ field }) => (
                    <Select
                      style={{ textAlign: 'left', width: '100%' }}
                      options={contentOptions}
                      loading={loading}
                      {...field}
                    />
                  )}
                />
              </Form.Item>
            </FormItemVertical>
            {type === 'REMINDER' ? (
              <>
                <FormItemVertical label="Reminder is due">
                  <div className="flex-row-center gap-8 w-100-P">
                    <div style={{ diplay: 'flex', maxWidth: 48}}>
                        <Controller
                          name={`actions[${index}].action_scale`}
                          control={control}
                          render={({ field }) => (
                            <InputNumber style={{ width: '100%'}} defaultValue={3} {...field} />
                          )}
                        />
                    </div>
                    <div style={{ diplay: 'flex', minWidth: '140px'}}>
                      <Controller
                        name={`actions[${index}].action_interval`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            style={{  width: '100%'}}
                            options={intervalOptions}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <span className="fs-12">after the sales occurs</span>
                  </div>
                </FormItemVertical>
                <FormItemVertical
                  label="Reminder is assigned to"
                >
                  <Controller
                    name={`actions[${index}].assign_type`}
                    control={control}
                    rules={{ required: 'Please select who assigned the reminder' }}
                    render={({ field }) => (
                      <Select
                        style={{ minWidth: '55%', textAlign: 'left' }}
                        defaultValue={'assignment'}
                        options={[
                          {
                            value: 'assignment',
                            label: 'Assigned Associate'
                          },
                          {
                            value: 'sales',
                            label: 'Associate that sold the item'
                          }
                        ]}
                        loading={loading}
                        {...field}
                      />
                    )}
                  />
                </FormItemVertical>
                <Controller
                  name={`actions[${index}].action_expires`}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      onChange={e => handleExpires(e)}
                      defaultChecked={action?.action_expires}
                    >
                      Auto dismiss reminders
                    </Checkbox>
                  )}
                />
                {expires && (
                  <FormItemVertical>
                    <div className="flex-row-center gap-8 w-100-P">
                      <div style={{ diplay: 'flex', maxWidth: 48}}>
                        <Controller
                          name={`actions[${index}].expire_scale`}
                          control={control}
                          render={({ field }) => (
                            <InputNumber
                              style={{ width: '100%'}}
                              defaultValue={3}
                              disabled={overdue}
                              {...field}
                            />
                          )}
                        />
                      </div>
                      <div style={{ diplay: 'flex', minWidth: '140px'}}>
                        <Controller
                          name={`actions[${index}].expire_interval`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              style={{  width: '100%'}}
                              options={intervalOptions}
                              defaultValue={intervalOptions[0].value}
                              disabled={overdue}
                              {...field}
                            />
                          )}
                        />
                      </div>
                      <span className="fs-12">after the due date</span>
                    </div>
                  </FormItemVertical>
                )}
                <Controller
                  name={`actions[${index}].action_repeat`}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      defaultChecked={action?.action_repeat}
                      onChange={e => handleRepeat(e)}
                    >
                      Repeat this reminder
                    </Checkbox>
                  )}
                />
                {repeat && (
                  <FormItemVertical>
                    <div className="flex-row-center gap-8 w-100-P">
                      <div style={{ diplay: 'flex', maxWidth: 48}}>
                        <Form.Item noStyle name={["actions", index, "repeat_scale"]} >
                          <Controller
                            name={`actions[${index}].repeat_scale`}
                            control={control}
                            render={({ field }) => (
                              <InputNumber style={{ width: '100%'}} defaultValue={3} {...field} />
                            )}
                          />
                        </Form.Item>
                      </div>
                      <div style={{ diplay: 'flex', minWidth: '140px'}}>
                        <Controller
                          name={`actions[${index}].repeat_interval`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              style={{  width: '100%'}}
                              options={intervalOptions}
                              {...field}
                            />
                          )}
                        />
                      </div>
                      <span className="fs-12">after the first reminder</span>
                    </div>
                  </FormItemVertical>
                )}
              </>
            ) : (
              <>
                <FormItemVertical label="Message is sent">
                  <div className="flex-row-center gap-8 w-100-P">
                    <div style={{ diplay: 'flex', maxWidth: 48}}>
                        <Controller
                          name={`actions[${index}].action_scale`}
                          control={control}
                          render={({ field }) => (
                            <InputNumber style={{ width: '100%'}} defaultValue={3} {...field} />
                          )}
                        />
                    </div>
                    <div style={{ diplay: 'flex', minWidth: '140px'}}>
                      <Controller
                        name={`actions[${index}].action_interval`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            style={{  width: '100%'}}
                            options={intervalOptions}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div style={{ diplay: 'flex'}}>
                      <Controller
                        name={`actions[${index}].action_repeat`}
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            defaultChecked={action?.action_repeat}
                            onChange={e => handleRepeat(e)}
                          >
                            Repeat
                          </Checkbox>
                        )}
                      />
                    </div>
                    <Tooltip title="Use 0 days for same day">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                </FormItemVertical>
                <span className="fs-12">after the sales occurs</span>
                {repeat && (
                  <FormItemVertical label="Repeat this message">
                    <div className="flex-row-center gap-8 w-100-P">
                      <div style={{ diplay: 'flex', maxWidth: 48}}>
                        <Form.Item noStyle name={["actions", index, "repeat_scale"]} >
                          <Controller
                            name={`actions[${index}].repeat_scale`}
                            control={control}
                            render={({ field }) => (
                              <InputNumber style={{ width: '100%'}} defaultValue={3} {...field} />
                            )}
                          />
                        </Form.Item>
                      </div>
                      <div style={{ diplay: 'flex', minWidth: '140px'}}>
                        <Controller
                          name={`actions[${index}].repeat_interval`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              style={{  width: '100%'}}
                              options={intervalOptions}
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </FormItemVertical>
                )}
              </>
            )}
            <div className="flex-row-spacebetween-center w-100-P m-top-8">
              <Button type="primary" onClick={handleSave}>
                Save
              </Button>
              <Button onClick={handleCancel}>
                Cancel
              </Button>
            </div>
            <Controller
              name={`actions[${index}].isDeleted`}
              control={control}
              render={({ field }) => (<></>)}
            />
        </div>
      </Card>
    ) : (
      <Card
        style={{
          width: '100%',
          marginBottom: 16,
        }}
        key={action?.id}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <div className="flex-row-spacebetween-center w-100-P">
            <span className="fs-14 fw-400 line-clamp-1" style={{ opacity: isDeleted ? 0.45 : 1 }}>
              <FontAwesomeIcon
                icon={type === 'MESSAGE' ? ['fal', 'comment-alt-lines'] : ['fal', 'bell']}
                className="m-right-8"
              />
              {type}
            </span>
            <div className="flex-row-center gap-8">
              {isDeleted && (
                <Button
                  onClick={() => handleUndoDeleteAction()}
                  style={{ padding: '4px 8px'}}
                >
                  <UndoOutlined />
                </Button>
              )}
              <Button
                onClick={() => setSelectedAction(action?.id)}
                style={{ padding: '4px 8px'}}
                disabled={isDeleted}
              >
                <EditFilled />
              </Button>
              <Button
                onClick={() => handleDeleteAction()}
                style={{ padding: '4px 8px'}}
                disabled={isDeleted}
              >
                <DeleteFilled />
              </Button>
            </div>
          </div>
          <span className="fs-16 fw-600 line-clamp-1" style={{ opacity: isDeleted ? 0.45 : 1 }}>{getAction.title}</span>
          <span className="flex-row-center">
            "
            <span className="fs-14 fw-400 line-clamp-1 align-text-left" style={{ opacity: isDeleted ? 0.45 : 1 }}>{getAction?.description.trim()}</span>
            "
          </span>
          {getTags.length > 0 && (
            <div className="flex-row-center m-top-15" style={{ opacity: isDeleted ? 0.45 : 1, maxWidth: '100%', flexWrap: 'wrap', gap: 8 }}>
              {getTags?.map(tag => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </div>
          )}
        </div>
      </Card>
    )
  )
}
