import React from 'react';
import StandardLayout from '../../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../../Layout/SecondaryPanel';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AutoWhatsAppSettings from '../../../../../Layout/AutoWhatsAppTemplate/AutoWhatsAppSettings';

const CustomAutoWhatsapp = props => {
  const history = useHistory();
  const listItemId = props.match.params.listItemId;

  const setRedirectBack = () => {
    history.push(`/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}`);
  };

  return (
    <StandardLayout
      title={`${props.autoRemMsgs.reminderAddOrEdit} WhatsApp Auto Template`}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Client Activity', to: '/Configuration/client-activity' },
        {
          title: 'Edit Client Activity',
          to: `/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}`,
        },
        { title: `${props.autoRemMsgs.reminderAddOrEdit} WhatsApp Auto Template` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <AutoWhatsAppSettings
        setRedirectBack={setRedirectBack}
        cancelRoute={`/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}`}
        descriptionText={'Select how soon after creating a client activity that you want this message to send. '}
        popConfirmDesc={'This will remove the auto message from this Client Activity'}
        editRoute={`/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}`}
        type={'clientEvent'}
        match={props.match}
      />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  autoRemMsgs: state.autoRemMsgs,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(CustomAutoWhatsapp);
