import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import Layout from '../../../Layout/StandardLayout';
import ImportData from '../../Clients/ImportModal/ImportData';
import tagImportOptions from '../../Clients/ImportModal/tagImportOptions.json';
import clientImportOptions from '../../Clients/ImportModal/clientImportOptions.json';
import productImportOptions from '../../Clients/ImportModal/productImportOptions.json';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import ChooseFile from './ChooseFile';
import ConfirmHeaders from './ConfirmHeaders';
import Submitted from './Submitted';
import Match from './Match';
import Review from './Review';
import './index.css';
import { getRequest } from '../../../../core/apiRequests';
import { notificationSuccess } from '../../../../core/actions';
import { API_URL } from '../../../../constants';

const UploadData = props => {
  const [showUploadModal, setUploadModal] = useState('');
  const [selStore, setSelStore] = useState('');
  const [endPoint, setEndPoint] = useState('');
  const [columns, setColumns] = useState({});
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [headersConfirmed, setHeadersConfirmed] = useState(false);

  const importData = (data, storeId) => {
    return new Promise((resolve, reject) => {
      const timeOffset = moment(new Date()).utcOffset();
      const fn = props.firstName || '';
      const ln = props.lastName || '';
      const createdBy = `${fn} ${ln}`.trim();

      const storageHeadersStr = localStorage.getItem(`${endPoint}Headers`); // he should be in string format
      let storageHeaders = {};
      if (storageHeadersStr) {
        // if the string is even "{}", let's parse to make sure
        storageHeaders = JSON.parse(storageHeadersStr);
      }
      Object.values(columns).forEach(col => {
        if (col.originalName) {
          const lcOriginalName = col.originalName.toLowerCase();
          if (`${lcOriginalName}`) {
            storageHeaders[lcOriginalName] = {};
            storageHeaders[lcOriginalName].value = col.selector;
            storageHeaders[lcOriginalName].label = col.displayName;
            storageHeaders[lcOriginalName].ignore = col.ignore;
            storageHeaders[lcOriginalName].existingPref = col.existingPref;
          }
        }
      });
      localStorage.setItem(`${endPoint}Headers`, JSON.stringify(storageHeaders));
      resolve();
      // axios.post(`${API_URL}/clients/${props.userId}/clients`,data,{headers:{associate:this.props.auth.account.associateId}}).then((res) => {
      const formattedData = cleanUpData();
      axios
        .post(
          `${API_URL}/xa/import/${props.merchantId}/${endPoint}?storeId=${storeId}&associateId=${props.userId}&createdBy=${createdBy}&timeOffset=${timeOffset}`,
          formattedData,
        )
        .then(res => {
          if (
            !Array.isArray(formattedData) &&
            typeof formattedData === 'object' &&
            formattedData.hasOwnProperty('newPrefs')
          ) {
            notificationSuccess('Upload in progress...');
          } else {
            notificationSuccess('Upload in progress...');
          }
          if (res.status === 200) {
            resolve();
          } else {
            reject();
          }
          //   clicked = false;
        })
        .catch(err => {
          reject();
        });
    });
  };

  const cleanUpData = () => {
    const newPrefs = {};
    const cleanedData = data.map(dataObj => {
      const buildObj = {};
      Object.keys(dataObj).forEach(key => {
        if (!columns[key].ignore) {
          // buildObj[columns[key].selector] = dataObj[key] // once was
          if (endPoint === 'clients') {
            if (columns[key].newPref) {
              newPrefs[columns[key].displayName] = true;
              buildObj[`PREF___${columns[key].displayName}`] = dataObj[key];
            } else if (columns[key].existingPref) {
              buildObj[`PREF___${columns[key].selector}`] = dataObj[key];
            } else {
              buildObj[columns[key].selector] = dataObj[key];
            }
          } else {
            buildObj[columns[key].selector] = dataObj[key];
          }
        }
      });
      return buildObj;
    });
    if (endPoint === 'clients') {
      return { clientList: cleanedData, newPrefs: Object.keys(newPrefs) };
    }
    return cleanedData;
  };

  const resetData = useCallback(() => {
    setColumns({});
    setData([]);
    setRawData([]);
    setHeadersConfirmed(false);
  }, [])

  return (
    <Layout
      title="Upload Data"
      description="Merge your client and product data into Clientbook"
      classProps="standard-width-with-sidebar"
    >
      <div className="flex-col-left" style={{ justifyContent: 'space-around' }}>
        <div className="p-btm-6 p-right-21 p-left-21 flex-row-spacebetween-wrap p-top-36 align-left w-866 maw-98-P">
          <div className="max-w-554 m-btm-6">
            <span className="black fs-16 fw-500">Upload Clients</span>
            <p className="gray fs-12">
              Upload a spreadsheet of client data and quickly add many clients at once. <br />
              <br />
              If a POS reference number is provided, existing clients will be matched and combined using this number.
              Existing profiles will also be merged using phone numbers and email addresses. If none of these entities
              match a client profile, then a new client will be created in Clientbook. <br />
              <br />
              Supported data fields include:
            </p>
            <ul className="w-100-P align-left p-left-15">
              <li className="gray m-left-0 fs-12">Name</li>
              <li className="gray m-left-0 fs-12">Phone Number</li>
              <li className="gray m-left-0 fs-12">Email Address</li>
              <li className="gray m-left-0 fs-12">Birthday</li>
              <li className="gray m-left-0 fs-12">Anniversary</li>
              <li className="gray m-left-0 fs-12">Mailing Address</li>
              <li className="gray m-left-0 fs-12">Preferences (eg. Sizes, colors)</li>
              <li className="gray m-left-0 fs-12">Unique POS Reference Number</li>
            </ul>
          </div>
          <ImportData
            importUploadButton="ClientImportButton"
            importOptions={clientImportOptions}
            disabled={props.pos_type}
            endPoint="clients"
            setEndPoint={setEndPoint}
            selStore={selStore}
            setSelStore={setSelStore}
            setUploadModal={setUploadModal}
          />
          <div className="m-top-15 w-100-P-divider w-98-P" />
        </div>
        <div className="p-btm-6 p-right-21 p-left-21 flex-row-spacebetween-wrap p-top-36 align-left w-866 maw-98-P">
          <div className="max-w-554 m-btm-6">
            <span className="black fs-16 fw-500">Upload Tags</span>
            <p className="gray fs-12">
              Upload a spreadsheet of tags to quickly upload many tags at once. <br />
              If a POS reference number is provided, existing client records will be matched and combined using this
              number. Existing profiles will also be matched with new ones that contain the same phone numbers or email
              addresses. Before uploading new tags, make sure that your client list has been updated. Any clients not
              found in your list will be ignored. <br /> Your spreadsheet must contain a tag name and one or more of the
              following fields:
            </p>
            <ul className="w-100-P align-left p-left-15">
              <li className="gray fs-12">Name</li>
              <li className="gray fs-12">Phone Number</li>
              <li className="gray fs-12">Email Address</li>
              <li className="gray fs-12">Unique POS Reference Number</li>
            </ul>
          </div>
          <ImportData
            importUploadButton="ClientTagsButton"
            importOptions={tagImportOptions}
            endPoint="tags"
            setEndPoint={setEndPoint}
            selStore={selStore}
            setSelStore={setSelStore}
            setUploadModal={setUploadModal}
          />
          <div className="m-top-15 w-100-P-divider w-98-P" />
        </div>
        <div className="p-btm-6 p-right-21 p-left-21 flex-row-spacebetween-wrap p-top-36 align-left w-866 maw-98-P">
          <div className="max-w-554 m-btm-6">
            <span className="black fs-16 fw-500">Upload Products</span>
            <p className="gray fs-12">
              Upload a spreadsheet of product data to quickly add a multitude of products at once. <br /> If a POS
              reference number is provided, existing products will be matched and combined using this number. Existing
              products in your account will also be combined using their product name, or SKU. If no matching product
              names, SKUs or POS numbers are found, a new product will be listed in Clientbook. <br /> Supported data
              fields include:
            </p>
            <ul style={{ paddingLeft: '15px' }} className="w-100-P align-left">
              <li className="gray fs-12">Name</li>
              <li className="gray fs-12">Description</li>
              <li className="gray fs-12">Brand</li>
              <li className="gray fs-12">Price</li>
              <li className="gray fs-12">Website URL</li>
              <li className="gray fs-12">Image URL</li>
              <li className="gray fs-12">SKU</li>
              <li className="gray fs-12">Unique POS Reference Number</li>
            </ul>
          </div>
          <ImportData
            importUploadButton="ProductDataButton"
            importOptions={productImportOptions}
            endPoint="products"
            setEndPoint={setEndPoint}
            selStore={selStore}
            setSelStore={setSelStore}
            setUploadModal={setUploadModal}
          />
          <div className="m-top-15 w-100-P-divider w-98-P" />
        </div>
      </div>
      <div>
        {showUploadModal === 'chooseFile' ? (
          <ChooseFile
            setSelStore={setSelStore}
            setUploadModal={setUploadModal}
            setData={setData}
            setRawData={setRawData}
            setColumns={setColumns}
            columns={columns}
            data={data}
            endPoint={endPoint}
          />
        ) : null}
        {showUploadModal === 'confirmHeaders' ? (
          <ConfirmHeaders
            setUploadModal={setUploadModal}
            headersConfirmed={headersConfirmed}
            setHeadersConfirmed={setHeadersConfirmed}
            columns={columns}
            rawData={rawData}
            data={data}
            setColumns={setColumns}
            setData={setData}
            endPoint={endPoint}
          />
        ) : null}
        {showUploadModal === 'match' ? (
          <Match
            merchantId={props.merchantId}
            setUploadModal={setUploadModal}
            columns={columns}
            setColumns={setColumns}
            data={data}
            endPoint={endPoint}
          />
        ) : null}
        {showUploadModal === 'review' ? (
          <Review
            setUploadModal={setUploadModal}
            columns={columns}
            data={data}
            importData={importData}
            selStore={selStore}
            endPoint={endPoint}
          />
        ) : null}
        {showUploadModal === 'submitted' ? <Submitted setUploadModal={setUploadModal} data={data} endPoint={endPoint} resetData={resetData} /> : null}
      </div>
    </Layout>
  );
};
const mapStateToProps = state => state.auth;
export default connect(mapStateToProps, {})(UploadData);
