import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Space } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import Layout from '../../../Layout/StandardLayout';
import '../associates.css';
import Button from '../../../Layout/Button';
import StyledLink from '../../../Layout/StyledLink';
import { saveSetting, getSingleDetails } from '../../../../core/apiRequests';
import PopConfirm from '../../../Layout/PopConfirm';
import { notificationSuccess, notificationError } from '../../../../core/actions';
import AssociateGraphs from './AssociateGraphs';
import { viewActivity, viewMessages, viewReminders } from './AssociateTables/AssocTableHeaders';
import AssocPagTable from './AssociateTables/AssocPagTable';
import TabbedHeaderCard from '../../../Layout/TabbedHeaderCard';

import {
  setFirstName,
  setLastName,
  setMobileNum,
  setEmailAddress,
  setSendInvite,
  setAllowManagerFeatures,
  setAssociateId,
  setAssociateStore,
  setAssociateImage,
  setAssociatePin,
  setAssociateStoreId,
  setAssocAddEdit,
  setAssociateContactGoal,
  setAssociateCount,
  setAssociatePassword,
  setSchedulingLink,
} from '../../../../reducers/associate';

const reminders = [...viewReminders];
reminders.pop();
const completeReminders = [...reminders];
const dismissedReminders = [...reminders];
dismissedReminders.splice(1, 0, {
  keyName: 'archive_date',
  title: 'Dismissed On',
  headerClassName: 'col-p-l-25 rem-pad-width',
  rowClassName: 'col-p-l-25 rem-pad-width',
  style: { width: '1%', minWidth: '110px', paddingRight: '22px' },
  sortable: true,
  render: t => moment(t, 'YYYYMMDDhhmmss').format('MM/DD/YYYY'),
});
completeReminders.splice(1, 0, {
  keyName: 'complete_date',
  title: 'Completed On',
  headerClassName: 'col-p-l-25 rem-pad-width',
  rowClassName: 'col-p-l-25 rem-pad-width',
  style: { width: '1%', minWidth: '110px', paddingRight: '22px' },
  sortable: true,
  render: t => moment(t, 'YYYYMMDDhhmmss').format('MM/DD/YYYY'),
});

const AssociateDetails = props => {
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [assocRole, setRole] = useState('');
  const [stores, setStores] = useState([]);
  const flags = useFlags();
  const history = useHistory();
  const full = `${props.associate.firstName || ''} ${props.associate.lastName || ''}`;
  const initials = `${full}`
    .split(' ')
    .map(word => word.substr(0, 1))
    .join(''); // initials icon

  useEffect(() => {
    setAssociateId('');
    setFirstName('');
    setLastName('');
    setMobileNum('');
    setAssociateId('');
    setEmailAddress('');
    setSendInvite('');
    setAllowManagerFeatures(false);
    setAssociateStore('');
    setAssociateStoreId('');
    setAssociateImage('');
    setAssociatePin('');
    setAssociatePassword('');
    setAssociateContactGoal(0);
    setAssociateCount(0);
    setRole('');
    setSchedulingLink('');
    if (props.merchantId && props.match.params.associateId) {
      (async () => {
        const data = await getSingleDetails('associates', props.merchantId, props.match.params.associateId);
        if (data && data.length > 0) {
          const {
            first_name,
            last_name,
            mobile,
            id,
            email,
            sendInvit,
            manager,
            location,
            image,
            pin,
            password,
            store_id,
            daily_contact_goal,
            count,
            role,
            stores,
            scheduling_link,
          } = data[0];
          setFirstName(first_name);
          setLastName(last_name);
          setMobileNum(mobile);
          setAssociateId(id);
          setEmailAddress(email);
          setSendInvite(sendInvit);
          setAllowManagerFeatures(manager);
          setAssociateStore(location);
          setAssociateStoreId(store_id);
          setAssociateImage(image);
          setAssociatePin(pin);
          setAssociatePassword(password);
          setAssociateContactGoal(daily_contact_goal);
          setAssociateCount(count);
          setRole(role);
          setSchedulingLink(scheduling_link);
          formatStores(stores);
        } else {
          notificationError('Error Fetching Associate Details');
          history.push('/Associates')
        }
      })();
    }
  }, [props.merchantId, props.match.params.associateId]);

  const formatStores = stores => {
    const storeNames = [];
    stores.map(store => storeNames.push(store.store_name));
    setStores(storeNames);
  };
  const resendInvitation = () => {
    setDisableResend(true);
    const { mobileNum, emailAddress, firstName, lastName, associateId, associateStoreId, associatePassword } =
      props.associate;
    if (emailAddress || (mobileNum && (props.iso_country_code == 'US' || 'CA'))) {
      const dataObj = {
        creatorAsct: props.firstName, // the current logged in associate's first name, for SMS purposes
        associateId,
        email: emailAddress,
        mobile: mobileNum,
        storeId: associateStoreId,
        merchantName: props.brandName,
        firstName,
        lastName,
        password: associatePassword,
      };
      (async () => {
        const data = await saveSetting('associates', props.merchantId, associateId, 'resendInvite', dataObj, '');
        if (data && data.id) {
          setDisableResend(false);
          notificationSuccess('Invitation Sent');
        } else {
          // notificationError('Error Sending Invitation');
          setDisableResend(false);
        }
      })();
    } else {
      notificationError('No eligible email or mobile number');
      setDisableResend(false);
    }
  };

  const ASAppend = props => {
    const ASArr = [];
    const { pathname } = window.location;
    if (pathname.includes('associate-messages')) {
      ASArr.push(
        { title: 'Associates', to: '/Associates' },
        {
          title: `${full}`,
          to: `/Associates/associate-details/${props.match.params.associateId}`,
        },
        { title: 'View Messages' },
      );
    } else if (pathname.includes('associate-reminders')) {
      ASArr.push(
        { title: 'Associates', to: '/Associates' },
        {
          title: `${full}`,
          to: `/Associates/associate-details/${props.match.params.associateId}`,
        },
        { title: 'View Reminders' },
      );
    } else if (pathname.includes('associate-activity')) {
      ASArr.push(
        { title: 'Associates', to: '/Associates' },
        {
          title: `${full}`,
          to: `/Associates/associate-details/${props.match.params.associateId}`,
        },
        { title: 'View Activity' },
      );
    } else ASArr.push({ title: 'Associates', to: '/Associates' }, { title: `${full}` });
    return ASArr;
  };

  const changePin = () => {
    const { associateId, associatePin } = props.associate;

    if (associatePin == 'hello') {
      notificationError('Please Enter 4 digits');
    } else {
      (async () => {
        const dataObj = {
          pin: associatePin,
        };
        if (associateId) {
          dataObj.id = associateId;
        }
        const data = async () => {
          const data = await saveSetting(
            'associates',
            props.merchantId,
            props.associate.associateId,
            'pin',
            dataObj,
            '',
          );
        };
        if (dataObj.pin) {
          notificationSuccess('Pin Updated');
          setShowPopConfirm(false);
        }
        data();
      })();
    }
  };

  const formatRole = () => {
    switch (assocRole) {
      case 'MANAGER':
        return 'Manager';
      case 'ASSOCIATE':
        return 'Associate';
      case 'ADMIN':
        return 'Admin';
      default:
        return 'No Data';
    }
  };

  const { pathname } = window.location;

  return (
    <Layout
      title="Associate Details"
      description={`View ${props.associate.firstName}'s data to analyze their clienteling progress`}
      breadCrumbs={ASAppend({ match: props.match })}
      // breadCrumbs={[{ title: 'Associates' }, { title: `${full}` }]}
      classProps="standard-width-wo-sidebar"
    >
      <div className="associate_header">
        <div className="associate_details">
          {props.associate.associateImage == null ? (
            <div
              style={{
                marginLeft: '25px',
                objectFit: 'cover',
                paddingTop: '4px',
              }}
              className="h-108 w-108 bg-lightgray circle rem-pad-width"
            >
              <span
                style={{
                  fontSize: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '15px',
                }}
                className="white"
              >
                {initials}
              </span>
            </div>
          ) : (
            <img className="assoc_image" src={props.associate.associateImage} />
          )}
          <table className="pic_details flex-col-left">
            <tbody>
              <tr className="w-230 h-28 fs-18 fw-500 darkest-gray h-35 align-vert-middle">{full}</tr>
              <tr className="w-167 h-23 gray fs-13 h-35 p-top-15">
                <td>Assigned Clients: {props.associate.associateAssignedCount}</td>
              </tr>
              <tr className="w-167 h-23 gray fs-13 h-35 p-top-15">
                <td>
                  {stores.length > 1 ? 'Stores' : 'Store'}: {stores.length > 1 ? stores.join(', ') : stores[0]}
                </td>
              </tr>
              <tr className="w-167 h-23 gray fs-13 h-35 p-top-15">
                <td>Role: {formatRole()}</td>
              </tr>
              <tr className="w-167 h-23 gray fs-13 h-35 p-top-15">
                <td>
                  Daily Contact Goal:
                  <span onClick={() => setShowModal(true)} className="pointer blue-link fw-500 fs-13 h-35 p-top-15">
                    {' '}
                    {props.associate.associateContactGoal}
                  </span>
                </td>
              </tr>
              {props?.merchantFlags?.scheduling_link?.enabled && (
                <tr className="w-167 h-23 gray fs-13 h-35 p-top-15">
                  <td className="w-600">
                    <span>
                      Scheduling Link: {props.associate.schedulingLink ? props.associate.schedulingLink : 'Not Set'}
                    </span>
                  </td>
                </tr>
              )}
              <tr className="h-23 gray fs-13 fw-500 h-35" style={{ width: '95px', padding: 0, cursor: 'pointer' }}>
                <td>
                  <StyledLink
                    id="EditAssociate"
                    onClick={() => setAssocAddEdit('Edit')}
                    to={`/Associates/edit-associate/${props.associate.associateId}`}
                    styleprops={{ width: '95px', color: '#007bd0' }}
                  >
                    Edit Associate
                  </StyledLink>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex-row-nospacebetween-nowrap" style={{ alignItems: 'flex-start' }}>
          <ViewMore match={props.match} />
          <Button
            disabled={disableResend}
            onclick={resendInvitation}
            classname="transparentButton standardButtonWidth "
          >
            <FontAwesomeIcon
              onclick={resendInvitation}
              className="CBdarkBlue pointer"
              style={{ marginBottom: '-1px', marginRight: '10px' }}
              size="1x"
              icon={['far', 'envelope']}
            />
            Resend Invitation
          </Button>
          <Button classname="transparentButton standardButtonWidth" onclick={() => setShowPopConfirm(true)}>
            <FontAwesomeIcon
              onclick={() => setShowPopConfirm(true)}
              className="CBdarkBlue pointer"
              style={{ marginBottom: '-1px', marginRight: '10px' }}
              size="1x"
              icon={['far', 'lock']}
            />
            Change Pin
          </Button>
        </div>
      </div>
      <div>
        {pathname.includes('associate-activity') ? (
          <div>
            <p className="pathActivity">Activity</p>
          </div>
        ) : null}
        {pathname.includes('associate-messages') ? (
          <div>
            <p className="pathActivity">Messages</p>
          </div>
        ) : null}
        {pathname.includes('associate-reminders') ? (
          <div>
            <p className="pathActivity"> Reminders</p>
          </div>
        ) : null}
      </div>
      <Switch>
        <Route
          path="/Associates/associate-details/:associateId"
          exact
          render={() => <AssociateGraphs match={props.match} />}
        />
        <Route
          path="/Associates/associate-details/:associateId/associate-messages"
          render={() => (
            <AssocPagTable tableHeaders={viewMessages} match={props.match} firstSort="sent" type="viewMessages" />
          )}
        />
        <Route
          path="/Associates/associate-details/:associateId/associate-activity"
          render={() => (
            <AssocPagTable
              tableHeaders={viewActivity}
              match={props.match}
              firstSort="created_date"
              type="viewActivity"
            />
          )}
        />
        <Route
          path="/Associates/associate-details/:associateId/associate-reminders"
          render={() => (
            <TabbedHeaderCard
              headerTitles={['Open', 'Complete', 'Dismissed']}
              tabbed_container_class="tabbed_three_header"
              content={[
                <AssocPagTable
                  key="openActions"
                  tableHeaders={viewReminders}
                  firstSort="due_date"
                  match={props.match}
                  type="openActions"
                />,
                <AssocPagTable
                  tableHeaders={completeReminders}
                  firstSort="complete_date"
                  match={props.match}
                  type="completeActions"
                  key="completeActions"
                />,
                <AssocPagTable
                  tableHeaders={dismissedReminders}
                  firstSort="due_date"
                  match={props.match}
                  type="dismissedActions"
                  key="dismissedActions"
                  firstAscend
                />,
              ]}
            />
          )}
        />
      </Switch>
      {showPopConfirm ? (
        <PopConfirm
          confirm="Enter new 4-digit pin"
          // input= {e => setPin(e.target.value)}
          description={
            <div>
              <input
                className="pop_input h-46 p-left-15"
                type="text"
                pattern="[0-9]*"
                onChange={e => setAssociatePin(e.target.value)}
              />
            </div>
          }
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={() => changePin()}
        />
      ) : null}
      {showModal ? (
        <ContactGoalModal
          goal={props.associate.associateContactGoal}
          closeModal={() => setShowModal(false)}
          associateId={props.associate.associateId}
          merchantId={props.merchantId}
        />
      ) : null}
    </Layout>
  );
};

const mapStateToProps = state => ({
  associate: state.associate,
  ...state.auth,
  ...state.notification,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AssociateDetails);

const ContactGoalModal = props => {
  const onConfirmHandler = () => {
    const { associateId, merchantId } = props;
    (async () => {
      const dataObj = {
        contactGoal: props.goal,
      };
      if (associateId) {
        dataObj.id = associateId;
      }
      (async () => {
        const data = await saveSetting('associates', merchantId, associateId, 'contactGoal', dataObj, '');
        if (data && data.contactGoal) {
          notificationSuccess('Contact Goal Updated');
          props.closeModal();
        } else {
          // notificationError('Error Saving Contact Goal');
        }
      })();
    })();
  };

  const backgroundCloseHandler = e => {
    if (e.target.id === 'modal-background') {
      props.closeModal();
    }
  };

  const handleContactGoalChange = e => {
    setAssociateContactGoal(`${e.target.value}`.replaceAll(/\D/g, ''));
  };

  return (
    <div className="pop-confirm-background" id="modal-background" onClick={backgroundCloseHandler}>
      <div className="pop-confirm-container rem-pad-width" style={{ width: '388px', height: '294px' }}>
        <div className="flex-col-center h-100-P" style={{ justifyContent: 'flex-start' }}>
          <div className="flex-row-spacebetween-nowrap w-100-P m-top-15">
            <div />
            <FontAwesomeIcon
              onClick={props.closeModal}
              className="light-gray m-right-15 pointer"
              size="2x"
              icon={['far', 'times']}
            />
          </div>
          <span className="fs-20 fw-500" style={{ marginTop: '0px' }}>
            Daily Contact Goal
          </span>
          <input
            placeholder="5"
            className="h-50 w-160 align-center m-top-37"
            value={props.goal}
            onChange={handleContactGoalChange}
          />
          <button onClick={onConfirmHandler} style={{ width: '160px' }} className="m-top-37 save-button">
            UPDATE
          </button>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

const ViewMore = props => {
  const [showList, setShowList] = useState(false);
  const history = useHistory();
  const items = [
    {
      label: 'View Reminders',
      key: 'associate-reminders',
    },
    {
      type: 'divider',
    },
    {
      label: 'View Activity',
      key: 'associate-activity',
    },
    {
      type: 'divider',
    },
    {
      label: 'View Messages',
      key: 'associate-messages',
    },
  ];

  const routeHandler = endpoint => {
    setShowList(false);
    history.push(`/Associates/associate-details/${props.match.params.associateId}/${endpoint.key}`);
  };

  return (
    <div
      className="flex-row-nospacebetween-nowrap pointer width-auto h-24 w-142 pos-rel m-top-10 "
      style={{ minWidth: '142px' }}
    >
      <Dropdown
        trigger="click"
        arrow
        menu={{
          items,
          onClick: routeHandler,
          style: { borderRadius: '10px' },
          mode: 'inline',
        }}
        onOpenChange={setShowList}
        open={showList}
        placement="bottomRight"
      >
        <Space>
          <FontAwesomeIcon className="fs-20 m-right-8 gray " icon={['far', 'ellipsis-h']} />
          VIEW MORE
        </Space>
      </Dropdown>
    </div>
  );
};
