import React, { useEffect, useState, useCallback } from 'react';
import { number, string, oneOfType } from 'prop-types';
import { connect } from 'react-redux';

// layout
import Button from '../../../Layout/Button';
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';

// local
import SettingSegment from './SettingSegment';
import Input from './Input';
import Preview from './Preview';
import { getPaymentSettings, savePaymentSettings } from '../../BankAccount/apiRequests';
import './styles.css';

export const DEFAULT = {
  REQUEST: ':link:',
  REMINDER: 'Hey :name:! Just wanted to send you a friendly reminder about this payment request. :link:',
  CONFIRMATION: 'Thanks for your payment, :name:. View your receipt from :merchant: for $:amount:: :link:',
};

const WRAP_ID = 'payment-settings-wrap';

const PaymentSettings = ({ brandName, merchantId }) => {
  const [displayName, setDisplayName] = useState(brandName);
  const [requestMessage, setRequestMessage] = useState(DEFAULT.REQUEST);
  const [reminderMessage, setReminderMessage] = useState(DEFAULT.REMINDER);
  const [disabled, setDisabled] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(DEFAULT.CONFIRMATION);
  const [showPreview, setShowPreview] = useState(false);

  const handleSave = useCallback(() => {
    savePaymentSettings(merchantId, {
      displayName,
      requestMessage,
      reminderMessage,
      confirmationMessage,
    });
  }, [merchantId, displayName, requestMessage, reminderMessage, confirmationMessage]);

  const handleRequest = function (e) {
    const message = requestMessage.split(' ');
    if (!message.includes(':link:')) {
      setDisabled(true);
      return setRequestMessage('Message must include :link:');
    }
    setDisabled(false);
  };

  const handleReminder = function (e) {
    const message = reminderMessage.split(' ');
    if (!message.includes(':link:')) {
      setDisabled(true);
      return setReminderMessage('Message must include :link:');
    }
    setDisabled(false);
  };

  const handleConfirmation = function (e) {
    const message = confirmationMessage.split(' ');
    if (!message.includes(':link:')) {
      setDisabled(true);
      return setConfirmationMessage('Message must include :link:');
    }
    setDisabled(false);
  };

  useEffect(() => {
    if (!merchantId) return;

    getPaymentSettings(merchantId).then(settings => {
      if (settings) {
        if (settings.displayName) {
          setDisplayName(settings.displayName);
        }
        if (settings.requestMessage) {
          setRequestMessage(settings.requestMessage);
        }
        if (settings.reminderMessage) {
          setReminderMessage(settings.reminderMessage);
        }
        if (settings.confirmationMessage) {
          setConfirmationMessage(settings.confirmationMessage);
        }
      }
    });
  }, [merchantId]);

  return (
    <Layout
      title="Payment Settings"
      classProps="standard-width-with-sidebar"
      description="Change your display name and configure payment messages"
    >
      <div
        id={WRAP_ID}
        style={{
          position: 'relative',
          maxWidth: 900,
        }}
      >
        <div className={`w-402 maw-100-P ${showPreview ? 'hide-under-1100' : ''}`}>
          <SettingSegment title="Business Details" icon="id-card" border>
            <Input
              title="Business Display Name"
              placeholder="Business Display Name"
              value={displayName}
              handleChange={setDisplayName}
            />
          </SettingSegment>

          <SettingSegment title="Messaging" icon="comments">
            <Input
              title="Payment Requests"
              placeholder="Payment Request Message"
              value={requestMessage}
              handleChange={setRequestMessage}
              id="PaymentRequestsInputBox"
              className="payment-settings-textarea"
              onBlur={handleRequest}
              textarea
            />
            <Input
              title="Payment Reminder"
              placeholder="Business Address"
              value={reminderMessage}
              handleChange={setReminderMessage}
              className="payment-settings-textarea"
              onBlur={handleReminder}
              textarea
            />
            <Input
              title="Payment Confirmation"
              placeholder="PaymentConfirmation"
              value={confirmationMessage}
              handleChange={setConfirmationMessage}
              className="payment-settings-textarea"
              onBlur={handleConfirmation}
              textarea
              noMargin
            />
            <Button
              onclick={() => {
                setRequestMessage(DEFAULT.REQUEST);
                setReminderMessage(DEFAULT.REMINDER);
                setConfirmationMessage(DEFAULT.CONFIRMATION);
              }}
              id="RestoreDefaultMessagingButton"
              classname="blue-link align-left no-wrap"
              styleProps={{ height: 30, width: 'auto' }}
            >
              Restore Default Messaging
            </Button>
          </SettingSegment>
        </div>

        {/* show preview as modal for mobile (always show for desktop) */}
        <Preview show={showPreview} close={() => setShowPreview(false)} name={displayName} />

        <div className="m-top-40 flex-row-spacebetween-nowrap">
          <a
            href={`#${WRAP_ID}`}
            onClick={() => setShowPreview(!showPreview)}
            id="PreviewInvoice"
            className="display-under-1100 cancel-button m-right-15 no-decoration d-inline-block rem-pad-width"
            style={{
              width: 'fit-content',
              padding: '12px 16px',
              height: 'auto',
            }}
          >
            {showPreview ? 'Close Preview' : 'Preview Invoice'}
          </a>
           <button className="save-button-v2 m-btm-8" onClick={handleSave} disabled={disabled} id="PaymentSettingsSaveChangesButton">
            Save Changes
          </button>
        </div>
      </div>

      {/* clear for copyright footer */}
      <div style={{ clear: 'both' }} />
    </Layout>
  );
};

PaymentSettings.propTypes = {
  brandName: string,
  merchantId: oneOfType([number, string]),
};

const mapStateToProps = state => ({
  brandName: state.auth.brandName,
  merchantId: state.auth.merchantId,
});

export default connect(mapStateToProps)(PaymentSettings);
