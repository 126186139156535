import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { differenceInDays, endOfDay, format, formatISO, startOfDay, subDays } from 'date-fns';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import Report from '../../../../componentsV2/Layout/Report';
import { filterList } from './helpers/filterList';
import useSearchParams from '../../../../hooks/useSearchParams';
import AssociateReportModal from './components/AssociateReportModal';
import { getRequest } from '../../../../core/apiRequests';
import { addLoadingList, removeLoadingList, setLoadingDescription } from '../../../../reducers/notification';
import { useReport } from '../../../../context/ReportContext';
import { ACTIVITIES_TYPES_ASSO_REPORT } from '../../../../constants';

const CSVcolumns = [
  {
    label: 'Associate',
    key: 'associate_name',
  },
  {
    label: 'Store',
    key: 'store_name',
  },
  {
    label: 'Messages',
    key: 'chatMessagesLength',
  },
  {
    label: 'Reminders Completed',
    key: 'remindersCompleted',
  },
  {
    label: 'Reminders',
    key: 'remindersLength',
  },
  {
    label: 'Activities',
    key: 'clientActivitiesLength',
  },
  {
    label: 'New Clients',
    key: 'newClientsLength',
  },
  {
    label: 'Sales Opps',
    key: 'salesOppsLength',
  },
  {
    label: 'Ai Insights Completed',
    key: 'aiInsightsCompleted',
  },
  {
    label: 'Ai Insights',
    key: 'aiInsightsLength',
  },
  {
    label: 'Total',
    key: 'total',
  },
];

const AssociatePerformance = ({ auth }) => {
  const [filters, setFilters] = useState({
    store: [],
    associate: [],
  });

  const { associatesPerformance, isLoadingAssociatesPerformance } = useReport();


  const { data: associates } = useQuery('associates-list', async () => {
        const reqObj = {
          params: ['associates'],
          loadingStatusOff: true,
        };
        return await getRequest(reqObj);
    }, {
      enabled: !!auth.merchantId,
      refetchOnWindowFocus: false,
      initialData: []
    });

    const data = useMemo(() => {
      return {
        [ACTIVITIES_TYPES_ASSO_REPORT.aiInsights]: associatesPerformance[ACTIVITIES_TYPES_ASSO_REPORT.aiInsights]
          .filter(item => associates.find(associate => associate.id.toString() === item?.associate_id?.toString())),
        [ACTIVITIES_TYPES_ASSO_REPORT.activities]: associatesPerformance[ACTIVITIES_TYPES_ASSO_REPORT.activities]
          .filter(item => associates.find(associate => associate.id.toString() === item?.associate_id?.toString())),
        [ACTIVITIES_TYPES_ASSO_REPORT.chatMessages]: associatesPerformance[ACTIVITIES_TYPES_ASSO_REPORT.chatMessages]
          .filter(item => associates.find(associate => associate.id.toString() === item?.associate_id?.toString())),
        [ACTIVITIES_TYPES_ASSO_REPORT.newClients]: associatesPerformance[ACTIVITIES_TYPES_ASSO_REPORT.newClients]
          .filter(item => associates.find(associate => associate.id.toString() === item?.associate_id?.toString())),
        [ACTIVITIES_TYPES_ASSO_REPORT.reminders]: associatesPerformance[ACTIVITIES_TYPES_ASSO_REPORT.reminders]
          .filter(item => associates.find(associate => associate.id.toString() === item?.associate_id?.toString())),
        [ACTIVITIES_TYPES_ASSO_REPORT.salesOpps]: associatesPerformance[ACTIVITIES_TYPES_ASSO_REPORT.salesOpps]
          .filter(item => associates.find(associate => associate.id.toString() === item?.associate_id?.toString())),
      };
    }, [associates, associatesPerformance]);

  const { data: allStores } = useQuery(
    ['stores'],
    async () => {
      return await getRequest({
        params: ['stores'],
        loadingStatusOff: true,
      });
    },
    {
      refetchOnWindowFocus: false,
      initialData: [],
    },
  );

  const history = useHistory();
  useEffect(() => {
    if (auth?.launchFlagsForNav?.associatePerformanceReport !== undefined) {
      if (!auth?.launchFlagsForNav?.associatePerformanceReport) {
        history.push('/Reports');
      }
    }
  }, [auth?.launchFlagsForNav?.associatePerformanceReport]);

  useEffect(() => {
    if (isLoadingAssociatesPerformance) {
      setLoadingDescription("Sit tight! We're compiling your report.");
      addLoadingList('associatesReport');
    } else {
      removeLoadingList('associatesReport');
      setLoadingDescription(null);
    }
  }, [isLoadingAssociatesPerformance]);

  const { setValue: setSearchParams, getAll } = useSearchParams();
  const params = getAll();

  useEffect(() => {
    const paramsFilters = {
      store: params?.store ? params?.store : [],
      associate: params?.associate ? params?.associate : [],
    };
    setFilters(paramsFilters);
    if (params?.startDate && params?.endDate) {
      setDateRange([new Date(params.startDate), new Date(params.endDate)]);
    } else {
      setDateRange([startOfDay(subDays(new Date(), 90)), endOfDay(new Date())]);
    }
  }, [window.location.search]);

  const [dateRange, setDateRange] = useState([]);
  const [pageSizeTable, setPageSizeTable] = useState(10);
  const [page, setPage] = useState(1);
  const [activityModal, setActivityModal] = useState({
    type: null,
    associate_id: null,
  });
  const [period, setPeriod] = useState('');

  const handleActivityModal = useCallback((type, associate_id) => {
    setActivityModal({
      type,
      associate_id,
    });
  }, []);

  const getDefaultPeriod = useMemo(() => {
    if (params?.startDate && params?.endDate) {
      if (differenceInDays(new Date(params.endDate), new Date(params.startDate)) === 90) {
        return '90days';
      }
      return `${format(new Date(params.startDate), 'MM/dd/yyyy')} - ${format(new Date(params.endDate), 'MM/dd/yyyy')}`;
    }
    return '90days';
  }, []);

  const handleOnDateRangeChange = useCallback(dates => {
    setSearchParams('startDate', formatISO(dates[0]));
    setSearchParams('endDate', formatISO(dates[1]));
  }, []);

  const filteredData = useMemo(() => {
    setPage(1);
    return {
      [ACTIVITIES_TYPES_ASSO_REPORT.aiInsights]: filterList(
        data[ACTIVITIES_TYPES_ASSO_REPORT.aiInsights],
        filters,
        dateRange[0],
        dateRange[1],
        auth.stores,
      ),
      [ACTIVITIES_TYPES_ASSO_REPORT.activities]: filterList(
        data[ACTIVITIES_TYPES_ASSO_REPORT.activities],
        filters,
        dateRange[0],
        dateRange[1],
        auth.stores,
      ),
      [ACTIVITIES_TYPES_ASSO_REPORT.chatMessages]: filterList(
        data[ACTIVITIES_TYPES_ASSO_REPORT.chatMessages],
        filters,
        dateRange[0],
        dateRange[1],
        auth.stores,
      ),
      [ACTIVITIES_TYPES_ASSO_REPORT.newClients]: filterList(
        data[ACTIVITIES_TYPES_ASSO_REPORT.newClients],
        filters,
        dateRange[0],
        dateRange[1],
        auth.stores,
      ),
      [ACTIVITIES_TYPES_ASSO_REPORT.reminders]: filterList(
        data[ACTIVITIES_TYPES_ASSO_REPORT.reminders],
        filters,
        dateRange[0],
        dateRange[1],
        auth.stores,
      ),
      [ACTIVITIES_TYPES_ASSO_REPORT.salesOpps]: filterList(
        data[ACTIVITIES_TYPES_ASSO_REPORT.salesOpps],
        filters,
        dateRange[0],
        dateRange[1],
        auth.stores,
      ),
    };
  }, [filters, dateRange, data]);

  const handleFilterChange = useCallback(value => {
    const updatedFilters = {
      store: [],
      associate: [],
    };
    value.forEach(item => {
      if (item[0] && item[1]) {
        updatedFilters[item[0]] = [...updatedFilters[item[0]], item[1]];
      } else {
        updatedFilters[item[0]] = [];
      }
    });
    Object.keys(updatedFilters).forEach(key => {
      setSearchParams(key, updatedFilters[key].join(','));
    });
  }, []);

  const removeFilter = useCallback(
    (key, value) => {
      const updatedFilters = { ...filters };
      updatedFilters[key] = updatedFilters[key].filter(filter => filter !== value);
      Object.keys(updatedFilters).forEach(key => {
        setSearchParams(key, updatedFilters[key].join(','));
      });
    },
    [filters],
  );

  const stats = useMemo(() => {
    const chatMessages = filteredData[ACTIVITIES_TYPES_ASSO_REPORT.chatMessages].reduce(
      (acc, item) => {
        acc.total += 1;
        if (!acc.clients.includes(item.client_id)) {
          acc.clients.push(item.client_id);
        }
        return acc;
      },
      {
        total: 0,
        clients: [],
      },
    );

    const reminders = filteredData[ACTIVITIES_TYPES_ASSO_REPORT.reminders].reduce(
      (acc, item) => {
        acc.total += 1;
        if (item.complete_date) {
          acc.completed += 1;
        } else {
          acc.skipped += 1;
        }
        return acc;
      },
      {
        total: 0,
        completed: 0,
        skipped: 0,
      },
    );

    const activities = filteredData[ACTIVITIES_TYPES_ASSO_REPORT.activities].reduce(
      (acc, item) => {
        acc.total += 1;
        if (!acc.clients.includes(item.client_id)) {
          acc.clients.push(item.client_id);
        }
        return acc;
      },
      {
        total: 0,
        clients: [],
      },
    );

    const newClients = filteredData[ACTIVITIES_TYPES_ASSO_REPORT.newClients].reduce(
      (acc, item) => {
        acc.total += 1;
        acc.kiosk += item.created_src === 'KIOSK' ? 1 : 0;
        return acc;
      },
      {
        total: 0,
        kiosk: 0,
      },
    );

    const salesOpps = filteredData[ACTIVITIES_TYPES_ASSO_REPORT.salesOpps].reduce(
      (acc, item) => {
        acc.total += 1;
        acc.total_amount += parseFloat(item.amount);
        return acc;
      },
      {
        total: 0,
        total_amount: 0,
      },
    );

    const aiInsights = filteredData[ACTIVITIES_TYPES_ASSO_REPORT.aiInsights].reduce(
      (acc, item) => {
        acc.total += 1;
        if (item.result_cd === 'COMPLETE') {
          acc.completed += 1;
        } else if (item.result_cd === 'SKIP') {
          acc.skipped += 1;
        }
        return acc;
      },
      {
        total: 0,
        completed: 0,
        skipped: 0,
      },
    );

    return [
      {
        title: 'Outbound Direct Messages',
        value: chatMessages.total,
        description: (
          <span className="fs-14 mid-gray m-top-3">{`Sent across ${chatMessages.clients.length} clients`}</span>
        ),
      },
      {
        title: 'Reminders Completed',
        value: `${reminders.completed} of ${reminders.total}`,
        suffix: (
          <span className="fs-14 mid-gray fw-400">{`(${
            reminders.total === 0 ? 0 : Math.round((reminders.completed / reminders.total) * 100)
          }%)`}</span>
        ),
        description: (
          <span className="fs-14 mid-gray m-top-3">{`${reminders.skipped} skipped (${
            reminders.total === 0 ? 0 : Math.round((reminders.skipped / reminders.total) * 100)
          }%)`}</span>
        ),
      },
      {
        title: 'Client Activities',
        value: `${activities.total}`,
        description: <span className="fs-14 mid-gray m-top-3">{`Across ${activities.clients.length} clients`}</span>,
      },
      {
        title: 'New Clients Manually Added',
        value: `${newClients.total}`,
        description: <span className="fs-14 mid-gray m-top-3">{`${newClients.kiosk} via Kiosk`}</span>,
      },
      {
        title: 'Sales Opportunities created',
        value: salesOpps.total,
        description: (
          <span className="fs-14 mid-gray m-top-3">{`$${salesOpps.total_amount.toFixed(2)} of sales pipeline`}</span>
        ),
      },
      {
        title: 'Ai Insights Completed',
        value: `${aiInsights.completed} of ${aiInsights.total}`,
        suffix: (
          <span className="fs-14 mid-gray fw-400">{`(${
            aiInsights.total === 0 ? 0 : Math.round((aiInsights.completed / aiInsights.total) * 100)
          }%)`}</span>
        ),
        description: (
          <span className="fs-14 mid-gray m-top-3">{`${aiInsights.skipped} skipped (${
            aiInsights.total === 0 ? 0 : Math.round((aiInsights.skipped / aiInsights.total) * 100)
          }%)`}</span>
        ),
      },
    ];
  }, [filteredData]);

  const getStoresNames = useCallback(
    store_ids => {
      return allStores
        .filter(store => store_ids.includes(store?.id?.toString()))
        .map(store => store.name)
        .join(', ');
    },
    [allStores],
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'Associate',
        dataIndex: 'associate_name',
        key: 'associate_name',
        sorter: (a, b) => a.associate_name.localeCompare(b.associate_name),
      },
      {
        title: 'Store',
        dataIndex: 'store_name',
        key: 'store_name',
        hidden: auth.stores.filter(store => store.id !== 0).length === 1,
      },
      {
        title: 'Messages',
        dataIndex: 'chatMessages',
        key: 'chatMessages',
        sorter: (a, b) => a.chatMessages.length - b.chatMessages.length,
        render: (text, record) => (
          <div
            className={record?.chatMessages.length ? 'text-clickable' : 'text-clickable-disable'}
            onClick={() =>
              record?.chatMessages.length
                ? handleActivityModal(ACTIVITIES_TYPES_ASSO_REPORT.chatMessages, record.key)
                : {}
            }
          >
            {text.length}
          </div>
        ),
      },
      {
        title: 'Reminders',
        dataIndex: 'reminders',
        key: 'reminders',
        sorter: (a, b) => a.reminders.length - b.reminders.length,
        render: (text, record) => (
          <div
            className={record?.reminders.length ? 'text-clickable' : 'text-clickable-disable'}
            onClick={() =>
              record?.reminders.length ? handleActivityModal(ACTIVITIES_TYPES_ASSO_REPORT.reminders, record.key) : {}
            }
          >
            {`${record?.remindersCompleted} of ${record?.reminders.length} (${
              record?.reminders.length === 0
                ? 0
                : Math.round((record?.remindersCompleted / record?.reminders.length) * 100)
            }%)`}
          </div>
        ),
      },
      {
        title: 'Activities',
        dataIndex: 'clientActivities',
        key: 'clientActivities',
        sorter: (a, b) => a.clientActivities.length - b.clientActivities.length,
        render: (text, record) => (
          <div
            className={record?.clientActivities.length ? 'text-clickable' : 'text-clickable-disable'}
            onClick={() =>
              record?.clientActivities.length
                ? handleActivityModal(ACTIVITIES_TYPES_ASSO_REPORT.activities, record.key)
                : {}
            }
          >
            {text.length}
          </div>
        ),
      },
      {
        title: 'New Clients',
        dataIndex: 'newClients',
        key: 'newClients',
        sorter: (a, b) => a.newClients.length - b.newClients.length,
        render: (text, record) => (
          <div
            className={record?.newClients.length ? 'text-clickable' : 'text-clickable-disable'}
            onClick={() =>
              record?.newClients.length ? handleActivityModal(ACTIVITIES_TYPES_ASSO_REPORT.newClients, record.key) : {}
            }
          >
            {text.length}
          </div>
        ),
      },
      {
        title: 'Sales Opps',
        dataIndex: 'salesOpps',
        key: 'salesOpps',
        sorter: (a, b) => a.salesOpps.length - b.salesOpps.length,
        render: (text, record) => (
          <div
            className={record?.salesOpps.length ? 'text-clickable' : 'text-clickable-disable'}
            onClick={() =>
              record?.salesOpps.length ? handleActivityModal(ACTIVITIES_TYPES_ASSO_REPORT.salesOpps, record.key) : {}
            }
          >
            {text.length}
          </div>
        ),
      },
      {
        title: 'Ai Insights',
        dataIndex: 'aiInsights',
        key: 'aiInsights',
        sorter: (a, b) => a.aiInsights.length - b.aiInsights.length,
        render: (text, record) => (
          <div
            className={record?.aiInsights.length ? 'text-clickable' : 'text-clickable-disable'}
            onClick={() =>
              record?.aiInsights.length ? handleActivityModal(ACTIVITIES_TYPES_ASSO_REPORT.aiInsights, record.key) : {}
            }
          >
            {`${record?.aiInsightsCompleted} of ${record?.aiInsights.length} (${
              record?.aiInsights.length === 0
                ? 0
                : Math.round((record?.aiInsightsCompleted / record?.aiInsights.length) * 100)
            }%)`}
          </div>
        ),
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        sorter: (a, b) => a.total - b.total,
      },
    ];
  }, [auth.stores, getStoresNames, handleActivityModal]);

  const tableData = useMemo(() => {
    return associates
      .filter(
        filters?.associate?.length ? associate => filters.associate.includes(associate.id?.toString()) : () => true,
      )
      .map(associate => {
        const chatMessages = filteredData[ACTIVITIES_TYPES_ASSO_REPORT.chatMessages].filter(
          item => item.associate_id?.toString() === associate.id?.toString(),
        );
        const chatMessagesLength = chatMessages.length;
        const reminders = filteredData[ACTIVITIES_TYPES_ASSO_REPORT.reminders].filter(
          item => item.associate_id?.toString() === associate.id?.toString(),
        );
        const remindersLength = reminders.length;
        const remindersCompleted = reminders
          .filter(item => item.associate_id?.toString() === associate.id?.toString())
          .filter(item => item.complete_date).length;
        const clientActivities = filteredData[ACTIVITIES_TYPES_ASSO_REPORT.activities].filter(
          item => item.associate_id?.toString() === associate.id?.toString(),
        );
        const clientActivitiesLength = clientActivities.length;
        const newClients = filteredData[ACTIVITIES_TYPES_ASSO_REPORT.newClients].filter(
          item => item.associate_id?.toString() === associate.id?.toString(),
        );
        const newClientsLength = newClients.length;
        const salesOpps = filteredData[ACTIVITIES_TYPES_ASSO_REPORT.salesOpps].filter(
          item => item.associate_id?.toString() === associate.id?.toString(),
        );
        const salesOppsLength = salesOpps.length;
        const aiInsights = filteredData[ACTIVITIES_TYPES_ASSO_REPORT.aiInsights].filter(
          item => item.associate_id?.toString() === associate.id?.toString(),
        );
        const aiInsightsLength = aiInsights.length;
        const aiInsightsCompleted = aiInsights
          .filter(item => item.associate_id?.toString() === associate.id?.toString())
          .filter(item => item.result_cd === 'COMPLETE').length;
        const total =
          chatMessages.length +
          reminders.length +
          clientActivities.length +
          newClients.length +
          salesOpps.length +
          aiInsights.length;
        return {
          key: associate.id,
          associate_name: `${associate.first_name} ${associate.last_name}`,
          store_name: getStoresNames(associate.store_ids),
          chatMessages,
          reminders,
          remindersCompleted,
          clientActivities,
          newClients,
          salesOpps,
          aiInsights,
          aiInsightsCompleted,
          total,
          remindersLength,
          chatMessagesLength,
          clientActivitiesLength,
          newClientsLength,
          salesOppsLength,
          aiInsightsLength,
        };
      });
  }, [associates, filteredData, filters, getStoresNames]);

  return (
    <>
      <Report
        title="Associate Performance"
        stats={stats}
        maxStatsPerLine={3}
        options={[]}
        hasStoreFilter={false}
        hasCSVExport
        CSVFilename="associates.csv"
        handleFilterChange={handleFilterChange}
        filters={filters}
        removeFilter={removeFilter}
        onDateRangeChange={handleOnDateRangeChange}
        dataRange={dateRange}
        defaultPeriodValue={getDefaultPeriod}
        columns={columns}
        CSVcolumns={CSVcolumns}
        table={tableData}
        stacked
        tablesProps={{
          pagination: {
            total: 0,
            showSizeChanger: true,
            showTotal: total => `Total ${total} items`,
            onChange: (page, pageSize) => {
              setPage(page);
              setPageSizeTable(pageSize);
            },
            page,
            pageSize: pageSizeTable,
          },
        }}
        getPeriodLabel={value => setPeriod(value)}
      />
      {!!activityModal.type && (
        <AssociateReportModal
          open={!!activityModal}
          onClose={() => handleActivityModal(null)}
          type={activityModal.type}
          associate_id={activityModal.associate_id}
          period={period}
          filteredData={filteredData}
          associates={associates}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AssociatePerformance);
