import moment from 'moment';

export const sourceCalc = dataObj => {
  const {
    tag_action_config_id,
    tag_name,
    custom_event_type_id,
    custom_event_type_name,
    sales_opp_id,
    sales_opp_name,
    life_event_id,
    life_event_type,
    sales_trigger_action_id,
  } = dataObj;
  if (tag_action_config_id || tag_name) {
    if (tag_name) {
      return `Tag: ${dataObj.tag_name}`;
    }
    return 'Tag';
  }
  if (custom_event_type_id || custom_event_type_name) {
    if (custom_event_type_name) {
      return custom_event_type_name;
    }
    return 'Client Event';
  }
  if (sales_opp_id || sales_opp_name) {
    if (sales_opp_name) {
      return sales_opp_name;
    }
    return 'Sales Opp';
  }
  if (life_event_id || life_event_type) {
    if (life_event_type) {
      return life_event_type;
    }
    return 'Life Event';
  }
  if (sales_trigger_action_id) {
    return 'Sales Trigger';
  }
  return 'Manual Reminder';
};

export const sourceDateCalc = dataObj => {
  const {
    due_date,
    soac_action_interval,
    soac_action_scale,
    custom_event_date,
    life_event_date,
    sales_trans_date_time,
    tac_action_interval,
    tac_action_scale,
  } = dataObj;
  const actionScale = soac_action_scale || tac_action_scale;
  const actionInterval = soac_action_interval || tac_action_interval;
  if (actionScale && actionInterval && due_date) {
    switch (actionInterval) {
    case 'DAY':
      return moment(due_date, 'YYYYMMDD').subtract(actionScale, 'days').format('MM/DD/YYYY');
    case 'WEEK':
      return moment(due_date, 'YYYYMMDD').subtract(actionScale, 'weeks').format('MM/DD/YYYY');
    case 'MONTH':
      return moment(due_date, 'YYYYMMDD').subtract(actionScale, 'months').format('MM/DD/YYYY');
    default:
      return 'N/A';
    }
  } else {
    const dateToFormat = custom_event_date || life_event_date || sales_trans_date_time || due_date;
    if (dateToFormat) {
      if (`${dateToFormat}`.length === 14) {
        return moment(dateToFormat, 'YYYYMMDDHHmmss').format('MM/DD/YYYY');
      }
      if (`${dateToFormat}`.length === 8) {
        return moment(dateToFormat, 'YYYYMMDD').format('MM/DD/YYYY');
      }
      if (`${dateToFormat}`.length === 4 && due_date) {
        const month = parseInt(dateToFormat.slice(0, 2));
        const day = parseInt(dateToFormat.slice(2));
        const year = moment(due_date, 'YYYYMMDD').year();
        return moment(new Date(year, (month - 1), day)).format('MM/DD/YYYY');
      }
      if(`${dateToFormat}`.length === 4 && !due_date) {
        const month = parseInt(dateToFormat.slice(0, 2));
        const day = parseInt(dateToFormat.slice(2));
        const year = moment(dateToFormat, 'MMDD').isBefore(moment(), 'day') ? moment().year() + 1 : moment().year();
        return moment(new Date(year, (month - 1), day)).format('MM/DD/YYYY');
      }
      return moment(due_date, 'YYYYMMDD').format('MM/DD/YYYY');
    }
  }
};
