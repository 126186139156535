import yn from 'yn';
import { getNav } from '../../utils';

// PAYMENTS
const getPaymentsOptions = role =>
  getNav(
    {
      ASSOCIATE: [{ title: 'Payment Activity', link: '/Payments/activity' }],
      ADMIN: [
        { title: 'Bank Accounts', link: '/BankAccount/list' },
        { title: 'Payment Settings', link: '/Configuration/payment-settings' },
      ],
      MANAGER: [{ title: 'Bank Transactions', link: '/reports/bank-transactions' }],
    },
    role,
  );

const isSafe = props => {
  const { pos_type, enabled_whatsapp: whatsapp, role, pipelineEnabled } = props;
  let menuObj = {};
  const posType = pos_type ? pos_type.trim() : null;
  const showWebChatSetup = props?.merchantFlags?.webchat?.enabled || false;
  const showTags = props?.merchantFlags?.tags?.enabled || false;
  const showMassMessaging = props?.merchantFlags?.mass_messaging?.enabled || false;
  const showGoogleReviews = props?.flags?.googleReviewsReport || false;

  if (posType || process.env.REACT_APP_API_ENV === 'development' || process.env.REACT_APP_API_ENV === 'localhost') {
    menuObj = {
      Messaging: [
        { title: 'Inbox', link: '/Messaging/inbox' },
        // {
        //   title: 'My Inbox',
        //   link: '/Messaging/my-inbox',
        // },
        // { title: 'Store Inbox', link: '/Messaging/store-inbox' },
      ],
      Payments: getPaymentsOptions(role),
      Automation: [
        { title: 'Sales Triggers', link: '/Configuration/sales-triggers' },
        { title: 'Life Events', link: '/Configuration/life-events' },
        { title: 'Client Activity', link: '/Configuration/client-activity' },
        { title: 'Tags', link: '/Configuration/tags' },
      ],
      Manage: [
        { title: 'Clients', link: '/Clients' },
        { title: 'Products', link: '/Products' },
        // { title: "Products", link: "/Configuration/sales-triggers" }, not a thing yet
      ],
      HELP: [
        { title: 'Training Videos', link: '/Help/training-videos' },
        { title: 'Contact Support', link: '/Help/contact-support' },
        { title: 'Feature Suggestion', link: '/Help/feature-suggestion' },
      ],
      PROFILE: [
        { title: 'Edit Profile', link: '/EditProfile' },
        { title: 'Logout', onClick: () => props.logout() },
      ],
      // UPDATE THESE LINKS UPDATE THESE LINKS UPDATE THESE LINKS
      CLIENTELING: [
        { title: 'Leaderboard', link: '/Reports/client-engagement' },
        { title: 'Associate Activity', link: '/Reports/associate-activity' },
        { title: 'Outreach by Activity', link: '/Reports/engagement-by-activity' },
        { title: 'App Opens', link: '/Reports/app-opens-by-associate' },
        { title: 'Recent Notes', link: '/Reports/recent-notes' },
      ],
      MESSAGING: [
        { title: 'Outbound vs. Inbound', link: '/Reports/outbound-vs-inbound' },
        { title: 'Client Contacts', link: '/Reports/client-contacts' },
        { title: 'Associate Outbound', link: '/Reports/associate-messages' },
      ],
      'Google Reviews': [
        { title: 'Sent by Associate', link: '/Reports/google-review-requests' },
        { title: 'Sent vs Received', link: '/Reports/google-review-store' },
      ],
      AUTOMATION: [
        { title: 'Auto Messages', link: '/Reports/auto-messages' },
        { title: 'Conversations', link: '/Reports/conversations' },
        { title: 'Tags by Associate', link: '/Reports/by-associate' },
        { title: 'Active Reminders', link: '/Reports/active-reminders' },
      ],
      'SALES OPPORTUNITY': [
        { title: 'Associate Sales Pipeline', link: '/Reports/sales-pipelines-by-associate' },
        { title: 'Created Opportunities', link: '/Reports/created-opportunity-by-associate' },
        { title: 'Current Pipeline', link: '/Reports/sales-opportunity' },
      ],
      PAYMENTS: [
        { title: 'Amount Requested', link: '/Reports/amount-requested' },
        { title: 'Amount Paid', link: '/Reports/amount-paid' },
        { title: 'Time to Pay', link: '/Reports/time-to-pay' },
        // { title: 'Payment Requests', link: '/Reports/payment-requests' },
      ],
    };
  } else {
    menuObj = {
      Messaging: [{ title: 'Inbox', link: '/Messaging/inbox' }],
      Payments: getPaymentsOptions(role),
      Automation: [
        { title: 'Life Events', link: '/Configuration/life-events' },
        { title: 'Client Activity', link: '/Configuration/client-activity' },
        { title: 'Tags', link: '/Configuration/tags' },
      ],
      Manage: [
        { title: 'Clients', link: '/Clients' },
        { title: 'Products', link: '/Products' },
        // { title: "Products", link: "/Configuration/sales-triggers" }, not a thing yet
      ],
      HELP: [
        { title: 'Training Videos', link: '/Help/training-videos' },
        // { title: "Upload Data", link: "/Configuration/upload-data" },
        { title: 'Contact Support', link: '/Help/contact-support' },
        { title: 'Feature Suggestion', link: '/Help/feature-suggestion' },
      ],
      PROFILE: [
        { title: 'Edit Profile', link: '/EditProfile' },
        { title: 'Logout', onClick: () => props.logout() },
      ],
      CLIENTELING: [
        { title: 'Leaderboard', link: '/Reports/client-engagement' },
        { title: 'Associate Activity', link: '/Reports/associate-activity' },
        { title: 'Outreach by Activity', link: '/Reports/engagement-by-activity' },
        { title: 'App Opens', link: '/Reports/app-opens-by-associate' },
        { title: 'Recent Notes', link: '/Reports/recent-notes' },
      ],
      MESSAGING: [
        { title: 'Outbound vs. Inbound', link: '/Reports/outbound-vs-inbound' },
        { title: 'Client Contacts', link: '/Reports/client-contacts' },
        { title: 'Associate Outbound', link: '/Reports/associate-messages' },
      ],
      'Google Reviews': [
        { title: 'Sent by Associate', link: '/Reports/google-review-requests' },
        { title: 'Sent vs Received', link: '/Reports/google-review-store' },
      ],
      AUTOMATION: [
        { title: 'Auto Messages', link: '/Reports/auto-messages' },
        { title: 'Conversations', link: '/Reports/conversations' },
        { title: 'Tags by Associate', link: '/Reports/by-associate' },
        { title: 'Active Reminders', link: '/Reports/active-reminders' },
      ],
      'SALES OPPORTUNITY': [
        { title: 'Associate Sales Pipeline', link: '/Reports/sales-pipelines-by-associate' },
        { title: 'Created Opportunities', link: '/Reports/created-opportunity-by-associate' },
        { title: 'Current Pipeline', link: '/Reports/sales-opportunity' },
      ],
      PAYMENTS: [
        { title: 'Amount Requested', link: '/Reports/amount-requested' },
        { title: 'Amount Paid', link: '/Reports/amount-paid' },
        { title: 'Time to Pay', link: '/Reports/time-to-pay' },
        // { title: 'Payment Requests', link: '/Reports/payment-requests' },
      ],
    };
  }

  if (pipelineEnabled === true) {
    menuObj.Automation.splice(1, 0, { title: 'Sales Opportunities', link: '/Configuration/sales-pipelines' });
  }

  if (role == 'ADMIN') {
    menuObj.SETTINGS = [
      { title: 'Client Preferences', link: '/Configuration/client-preferences' },
      { title: 'Relationship Types', link: '/Configuration/relationship-types' },
      { title: 'Message Settings', link: '/Configuration/message-settings' },
      { title: 'Kiosk Settings', link: '/Configuration/kiosk-settings' },
      { title: 'General Settings', link: '/Configuration/app-settings' },
    ];
  } else {
    menuObj.SETTINGS = [];
  }
  if (role === 'MANAGER' || role === 'ADMIN') {
    menuObj.Messaging.push(
      { title: 'Mass Messaging', link: '/Messaging/mass-messaging' },
      { title: 'Templates', link: '/Messaging/all-templates' },
      { title: 'Auto Messages', link: '/Messaging/auto-messages' },
    );
  }
  if (posType) {
    menuObj.AUTOMATION.push({ title: 'Attributed Sales', link: '/Reports/attributed-sales' });
  } else {
    menuObj.AUTOMATION.push({ title: 'Timeliness', link: '/Reports/timeliness' });
  }
  if (whatsapp) {
    menuObj.Messaging.push({ title: 'WhatsApp', link: '/Messaging/WhatsApp' });
  }
  if (role == 'MANAGER' || role == 'ADMIN') {
    menuObj.Manage.push({ title: 'Associates', link: '/Associates' }, { title: 'Stores', link: '/Stores' });
  }
  if (role == 'ADMIN') {
    menuObj.HELP.unshift({ title: 'Integrations', link: '/Help/integrations' });
    menuObj.HELP.unshift({ title: 'Export Data', link: '/Help/export-data' });
    if(showWebChatSetup) {
      menuObj.HELP.unshift(
        { title: 'Web Chat Setup', link: '/Help/web-chat-setup' },
        { title: 'Upload Data', link: '/Configuration/upload-data' },
      );
    }
  }
  if(!showTags) {
    let newAutomation = menuObj.Automation.filter(item => item.title !== 'Tags')
    menuObj = {...menuObj, Automation: newAutomation}
  }

  if (!showMassMessaging) {
    const newMessaging = menuObj?.Messaging?.filter(item => item.title !== 'Mass Messaging');
    menuObj = { ...menuObj, Messaging: newMessaging };
  }

  if(!showGoogleReviews) {
    const newReports = menuObj['Google Reviews'].filter(item => item.title !== 'Sent vs Received')
    menuObj = {...menuObj, 'Google Reviews': newReports}
  }

  return menuObj;
};

export const menuOptions = props => {
  return isSafe(props);
};

// export const menuOptionsSalesTriggerStoreEvents = pos_type => { // TODO: commenting out b/c I think its not used, but I don't want to delete it forever
//   return {
//     Configuration: [
//       { title: 'Tags', link: '/Configuration/tags' },
//       { title: 'Client Preferences', link: '/Configuration/client-preferences' },
//       { title: 'Life Events', link: '/Configuration/life-events' },
//       { title: 'Sales Opportunities', link: '/Configuration/sales-pipelines' },
//       { title: 'Client Activity', link: '/Configuration/client-activity' },
//       { title: 'Sales Triggers', link: '/Configuration/sales-triggers' },
//       isSafe(pos_type) ? { title: 'Store Events', link: '/Configuration/store-events' } : null,
//       { title: 'Message Settings', link: '/Configuration/message-settings' },
//       { title: 'Kiosk Settings', link: '/Configuration/kiosk-settings' },
//       { title: 'General Settings', link: '/Configuration/app-settings' },
//       { title: 'Upload Data', link: '/Configuration/upload-data' },
//     ],
//     Help: [
//       { title: 'Integrations', link: '/Help/integrations' },
//       { title: 'Web Chat Setup', link: '/Help/web-chat-setup' },
//       { title: 'Contact Support', link: '/Help/contact-support' },
//       { title: 'Feature Suggestion', link: '/Help/feature-suggestion' },
//       { title: 'Training Videos', link: '/Help/training-videos' },
//     ],
//     Messaging: [
//       { title: 'Inbox', link: '/Messaging/inbox' },
//       { title: 'Store Inbox', link: '/Messaging/store-inbox' },
//       { title: 'Mass Messaging', link: '/Messaging/mass-messaging' },
//       { title: 'My Inbox', link: '/Messaging/my-inbox' },
//     ],
//   };
// };
