import moment from 'moment';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useMessageTemplates from '../../../../hooks/useMessageTemplates';
import {
  scrollToBottomOfChat,
  setMsgChatMessages,
  setMsgReminderId,
  setMsgClient,
  setMsgSearchText,
  setMsgResetClient,
  setShowSearchResults,
} from '../../../../reducers/messaging';
import PopMessagePayment from './PopMessagePayment';
import sendAPIChatMessage from '../Helpers/API/sendChatMessage';
import ChatTemplatePopup from './ChatTemplatePopup';
import MessageInputActions from './MessageInputActions';
import WhatsappMessageInput from './WhatsappMessageInput/index';
import { useDragAndDrop } from '../../../../context/DragDropContext';
import { getRequest, postRequest } from '../../../../core/apiRequests';
import ChatModal from './ChatModal';
import PopMessage from '../../PopMessage';
import { associateNameFormat } from '../../../../utils';
import { getReminderData } from './helpers';

const ChatInputFooter = props => {
  const timeOffset = moment(new Date()).utcOffset();
  const [messageTxt, setMessageTxt] = useState('');
  const { fileData, fileDispatch } = useDragAndDrop();
  const [paymentRequestID, setPaymentRequestID] = useState('');
  const [templatePopup, showTemplatePopup] = useState(false);
  const [paymentsPopup, showPaymentsPopup] = useState(false);
  const [relationships, setRelationships] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [showPopReminder, setShowPopReminder] = useState(false);
  const [showPopSuccessReminder, setShowPopSuccessReminder] = useState(false);
  const flags = useFlags();

  useEffect(() => {
    setMessageTxt(''); // this should be called any time the client_id changes
  }, [props?.messaging?.client?.client_id]);

  const whatsappTimeCheck = (preferred_contact_type, WA_receive) => {
    if (
      preferred_contact_type === 'WHATSAPP' &&
      (WA_receive ? !(moment(new Date()).diff(moment(WA_receive), 'hours') < 24) : true)
    ) {
      return false;
    }
    return true;
  };

  const handleAddProduct = useCallback(
    product => {
      const productArray = [...selectedProducts];
      productArray.push(product);
      setSelectedProducts(productArray);
    },
    [selectedProducts],
  );

  const handleDeleteProduct = useCallback(
    id => {
      const productArray = selectedProducts.filter(product => product.id !== id);
      setSelectedProducts(productArray);
    },
    [selectedProducts],
  );

  const handleAddCollection = useCallback(
    collection => {
      const productArray = [...selectedCollections, ...collection];
      setSelectedCollections(productArray);
    },
    [selectedCollections],
  );

  const handleDeleteCollection = useCallback(
    id => {
      const collectionArray = selectedCollections.filter(item => item.collection_id !== id);
      setSelectedCollections(collectionArray);
    },
    [selectedCollections],
  );

  const insertIntoConversationList = (selectedArr, respData) => {
    const tempChatList = props.messaging.chatMessages;
    let fakeChatObj = {};
    let fakeMessageObj = {};
    selectedArr.forEach(selection => {
      if (messageTxt) {
        fakeMessageObj = {
          clientId: props.messaging.client.client_id,
          id: `FAKE${props.messaging.client.client_id}${Math.floor(Math.random() * 9999)}`,
          chatId: respData.chatId,
          imageUrl: null,
          collectionId: null,
          collectionName: null,
          message: messageTxt.replace('[Review Link]', ':review:'),
          productId: null,
          productImage: null,
          productName: null,
          productPrice: null,
          sent: +moment(new Date()).subtract(timeOffset, 'minutes').format('YYYYMMDDHHmmss'),
          sentTimestamp: moment(new Date()),
          storeId: props.messaging.client.sendStoreId ? props.messaging.client.sendStoreId : props.auth.selectedStore,
          storeName: null,
          thumbnailUrl: null,
          userId: props.auth.userId,
          userName: `${props.auth.firstName || ''} ${props.auth.lastName || ''}`,
          userImage: `${props.auth.image || ''}`,
          videoUrl: null,
          uuid: respData.uuid,
        };
        tempChatList.unshift(fakeMessageObj);
      }
      if (respData.hasOwnProperty('productIds') && respData.productIds.length > 0) {
        fakeChatObj = {
          client_id: props.messaging.client.client_id,
          id: `FAKE${props.messaging.client.client_id}${Math.floor(Math.random() * 9999)}`,
          chatId: respData.chatId,
          imageUrl: null,
          collectionId: null,
          collectionName: null,
          message: messageTxt.replace('[Review Link]', ':review:'),
          productId: selection.id,
          productImage: selection.image,
          productName: selection.name,
          productPrice: null,
          sent: moment(new Date()).subtract(timeOffset, 'minutes').format('YYYYMMDDHHmmss'),
          sentTimestamp: moment(new Date()),
          storeName: null,
          thumbnailUrl: null,
          userId: props.auth.userId,
          userName: `${props.auth.firstName || ''} ${props.auth.lastName || ''}`,
          userImage: `${props.auth.image || ''}`,
          videoUrl: null,
          uuid: respData.uuid,
          fakeImage: true,
        };
      } else {
        fakeChatObj = {
          clientId: props.messaging.client.client_id,
          id: `FAKE${props.messaging.client.client_id}${Math.floor(Math.random() * 9999)}`,
          chatId: respData.chatId,
          imageUrl: null,
          collectionId: selection.clientCollectionId || selection.collection_id,
          collectionName: selection.name,
          message: `.app/${props.messaging.client.client_id}/${
            selection.clientCollectionId || selection.collection_id
          }`,
          productId: null,
          productImage: null,
          productName: null,
          productPrice: null,
          sent: +moment(new Date()).subtract(timeOffset, 'minutes').format('YYYYMMDDHHmmss'),
          sentTimestamp: moment(new Date()),
          storeName: null,
          thumbnailUrl: null,
          userId: props.auth.userId,
          userName: `${props.auth.firstName || ''} ${props.auth.lastName || ''}`,
          userImage: `${props.auth.image || ''}`,
          videoUrl: null,
          uuid: respData.uuid,
          fakeImage: true,
        };
      }

      fakeChatObj.storeId = props.messaging.client.sendStoreId
        ? props.messaging.client.sendStoreId
        : props.auth.selectedStore;
      tempChatList.unshift(fakeChatObj);
    });
    setMsgChatMessages([...tempChatList]);
    setMessageTxt('');
  };

  const sendMessage = async (completeReminder = false, sendAndArchive = null) => {
    let archive = sendAndArchive;
    if (archive === null && !props.messaging.chatMessages?.length) {
      archive = false;
    }
    if (selectedProducts.length > 0) {
      const productIds = selectedProducts.map(product => product.id);
      const bodyData = {
        archive,
        chatId: props?.messaging?.client?.chat_id,
        clientId: props?.messaging?.client?.client_id,
        msg: messageTxt || '',
        associateName: associateNameFormat(props.auth.firstName, props.auth.lastName),
        // storeId: props.messaging.client.sendStoreId ? props.messaging.client.sendStoreId : props.auth.selectedStore,
        s3Images: [],
        s3Videos: [],
        paymentRequestID,
        productIds,
        type: props.messaging.client.type,
      };
      bodyData.storeId = props.messaging.client.sendStoreId
        ? props.messaging.client.sendStoreId
        : props.auth.selectedStore;

      const reqObj = {
        params: ['chats', 'sendChatMessage'],
        query: {},
        data: bodyData,
        images: {
          urls: [],
          files: [],
        },
        videos: {
          urls: [],
          files: [],
          videoFrames: [],
        },
      };
      const respData = await postRequest(reqObj);
      if (respData.chatId) {
        insertIntoConversationList(selectedProducts, respData);
      }
      setSelectedProducts([]);
    } else if (selectedCollections.length > 0) {
      const bodyData = {
        archive,
        chatId: props?.messaging?.client?.chat_id,
        clientId: props?.messaging?.client?.client_id,
        msg: messageTxt || '',
        associateName: associateNameFormat(props.auth.firstName, props.auth.lastName),
        s3Images: [],
        s3Videos: [],
        paymentRequestID,
        collectionIds: selectedCollections.map(collection => collection.clientCollectionId),
        type: props.messaging.client.type,
      };
      bodyData.storeId = props.messaging.client.sendStoreId
        ? props.messaging.client.sendStoreId
        : props.auth.selectedStore;

      const reqObj = {
        params: ['chats', 'sendChatMessage'],
        query: {},
        data: bodyData,
        images: {
          urls: [],
          files: [],
        },
        videos: {
          urls: [],
          files: [],
          videoFrames: [],
        },
      };
      const respData = await postRequest(reqObj);
      if (respData.chatId) {
        insertIntoConversationList(selectedCollections, respData);
      }
      setSelectedCollections([]);
    } else {
      await sendAPIChatMessage({
        messageTxt,
        fileData,
        chatId: props?.messaging?.client?.chat_id,
        clientId: props?.messaging?.client?.client_id,
        paymentRequestID,
        timeOffset,
        setMessageTxt,
        fileDispatch,
        archive,
        type: props.messaging.client.type,
      });
    }
    if (completeReminder) {
      const reqObj = {
        params: ['today', props.auth.merchantId, 'save'],
        data: { selected: [props?.messaging?.client] },
        query: {
          storeId: props.auth.storeId,
          associateId: props.auth.userId,
          createdBy: `${props.auth.firstName || ''} ${props.auth.lastName || ''}`,
        },
      };
      const data = await postRequest(reqObj);
      if (data) {
        getReminderData(props?.messaging?.client, props.auth.merchantId);
        setShowPopReminder(false);
        setShowPopSuccessReminder(true);
        setMsgReminderId(null);
      }
    }
    setMsgReminderId(null);
    setShowPopReminder(false);
    scrollToBottomOfChat();
    setShowSearchResults(false);
    setMsgSearchText('');
  };

  const sendAndArchive = async () => {
    await sendMessage(false, true);
    setMsgClient({ isOpen: false });
    setMsgResetClient({});

    if (typeof props.setDrawerToggle === 'function') {
      props.setDrawerToggle(true);
    }
  };

  const getAutoReminderIdTemplate = useMemo(() => {
    if(props?.today?.todayClientChat?.temporaryConfigId && props?.today?.todayClientChat?.temporaryConfigId !== 0) {
      return props.today.todayClientChat.temporaryConfigId;
    }
    const {
      life_event_action_config_id,
      custom_event_action_config_id,
      sales_opp_action_config_id,
      tag_action_config_id } = props?.messaging?.client;
    return life_event_action_config_id ||
      custom_event_action_config_id ||
      sales_opp_action_config_id ||
      tag_action_config_id ||
      null;
  }, [props]);
  const templates = useMessageTemplates({
    togglePopup: () => showTemplatePopup(p => !p),
    merchantId: props.auth.merchantId,
    licenseKey: props.auth.licenseKey,
    fileDispatch,
    setMessageText: setMessageTxt,
    clientName: props.messaging.client.preferred_name || props.messaging.client.name,
    stores: props.auth.stores,
    storeId: props.auth.selectedStore,
    associateName: props.auth.firstName,
    clientId: props?.messaging?.client?.client_id,
    allTemplates: true,
    client: props?.messaging?.client,
    autoReminderId: getAutoReminderIdTemplate,
  });

  useEffect(() => {
    if (!props?.client?.client_id) {
      return;
    }
    if (props?.client?.relationships?.length > 0) {
      const cli_id = props?.client?.client_id;
      const formatData = props?.client?.relationships
        .filter(rel => !!rel.relationship)
        .map(item => {
          if (item.client_id === parseInt(cli_id)) {
            return {
              name: item.related_client_name.trim().split(' ')[0],
              id: item.related_client_id,
              relationship: item.relationship,
              relationship_id: item.id,
            };
          }
          if (item.related_client_id === parseInt(cli_id)) {
            return {
              name: item.client_name.trim().split(' ')[0],
              id: item.client_id,
              relationship: `${item.relationship} of`,
              relationship_id: item.id,
            };
          }
        });
      setRelationships(formatData);
    } else {
      setRelationships([]);
    }
  }, [props.client?.client_id]);

  const handleSendButton = useCallback(() => {
    if (props.messaging.client.reminderId) {
      setShowPopReminder(true);
    } else if (props.showOverlay && !props.sendAfterOverlay) {
      props.setOverlay(true);
    } else {
      sendMessage(false);
    }
  }, [sendMessage, props.messaging, props.sendAfterOverlay]);

  const inboxNoWhatsApp = () => {
    const { client_id, preferred_contact_type, WA_receive, type } = props.messaging.client;
    if ((client_id && whatsappTimeCheck(preferred_contact_type, WA_receive)) || type !== 'C') {
      return (
        <MessageInputActions
          sendMessage={handleSendButton}
          sendAndArchive={sendAndArchive}
          changeHandler={setMessageTxt}
          val={messageTxt}
          reviewLink={props.messaging.reviewLink}
          associate={props.auth.firstName}
          client={props.messaging.client}
          key={client_id}
          showTemplatePopup={showTemplatePopup}
          showPaymentsPopup={showPaymentsPopup}
          templates={templates}
          payments={Number(props.auth.payments) > 0}
          relationships={relationships}
          type={props.type}
          handleAddProduct={handleAddProduct}
          handleDeleteProduct={handleDeleteProduct}
          selectedProducts={selectedProducts}
          selectedCollections={selectedCollections}
          handleAddCollection={handleAddCollection}
          handleDeleteCollection={handleDeleteCollection}
          location="inbox"
        />
      );
    }
    if (client_id && !whatsappTimeCheck(preferred_contact_type, WA_receive)) {
      return (
        <WhatsappMessageInput
          sendMessage={sendMessage}
          changeHandler={setMessageTxt}
          associateName={props.auth.firstName || props.auth.lastName}
          clientName={props?.messaging?.client?.name}
          reviewLink
          merchantId={props?.auth?.merchantId}
          clientId={client_id}
        />
      );
    }
  };

  const getClientFirst = () => {
    if (props?.messaging?.client?.name) {
      if (props?.messaging?.client?.name.split(' ').length > 1) {
        return props.messaging.client.name.split(' ')[0];
      }
      return props.messaging.client.name;
    }
  };

  return (
    <div className="w-100-P" id="footer-icons">
      {inboxNoWhatsApp()}
      <ChatModal
        type={props.type}
        drawer={{
          bodyStyle: { padding: 0 },
          push: false,
          width: 500,
        }}
        open={paymentsPopup}
        onClose={() => showPaymentsPopup(false)}
      >
        <PopMessagePayment
          className={props.type === 'full' ? 'chat-payment-modal-container-full' : 'chat-payment-modal-container-right'}
          onCancel={() => {
            showPaymentsPopup(false);
          }}
          clientName={getClientFirst()}
          clientFullName={props.messaging.client.name}
          popStyle={{ overflowY: 'scroll' }}
          showPaymentsPopup={showPaymentsPopup}
          clientId={props.messaging.client.client_id}
          setMessageTxt={setMessageTxt}
          setPaymentRequestID={setPaymentRequestID}
        />
      </ChatModal>
      <ChatModal
        type={props.type}
        drawer={{
          bodyStyle: { padding: 0 },
          push: false,
          width: 600,
        }}
        open={templatePopup}
        onClose={() => showTemplatePopup(false)}
      >
        <ChatTemplatePopup
          type={props.type}
          setShowPopup={showTemplatePopup}
          showPopup={templatePopup}
          setShowPopConfirm={() => {}}
          templates={templates}
          tableData={templates.tableData}
          setMsgText={setMessageTxt}
          fileDispatch={fileDispatch}
          currentClientName={props.messaging.client?.preferred_name || props.messaging?.client?.name?.split(' ')[0] || ''}
          associateName={`${props.auth.firstName || ''}`}
          reviewLink={props.messaging.reviewLink}
          auth={props.auth}
          client={props.messaging.client}
        />
      </ChatModal>
      {showPopReminder ? (
        <PopMessage
          popStyle={{
            width: '375px',
          }}
          confirmationButtonText="YES"
          cancelButtonText="NO"
          confirm={
            props.messaging.client.description ? (
              <>
                {`Message sent from reminder "${props.messaging.client.description}".`}
                <br />
                Would you like to complete this reminder?
              </>
            ) : (
              'Would you like to complete this reminder?'
            )
          }
          description=""
          noButtonDisable
          confirmStyle={{ textAlign: 'center' }}
          onCancel={() => sendMessage(false)}
          onConfirm={() => sendMessage(true)}
        />
      ) : null}
      {showPopSuccessReminder ? (
        <PopMessage
          popStyle={{
            width: '375px',
          }}
          confirmationButtonText="Ok"
          confirmStyle={{ textAlign: 'center' }}
          confirm={
            props.messaging.client.description
              ? `Reminder "${props.messaging.client.description}" completed!`
              : 'Reminder completed!'
          }
          noButtonDisable
          onConfirm={() => setShowPopSuccessReminder(false)}
          hasCancelButton={false}
        />
      ) : null}
    </div>
  );
};
const mapStateToProps = state => ({
  messaging: state.messaging,
  auth: state.auth,
  today: state.today,
  client: state.client,
});
export default connect(mapStateToProps, {})(ChatInputFooter);
