import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';
import Layout from '../../../../Layout/StandardLayout';
import Table from '../../../../Layout/Table';
import { deleteRequest, getRequest, postRequest } from '../../../../../core/apiRequests';
import Button from '../../../../Layout/Button';
import StyledLink from '../../../../Layout/StyledLink';
import PopMessage from '../../../../Layout/PopMessage';
import PopConfirm from '../../../../Layout/PopConfirm';
import DropdownSelect from '../../../../Layout/DropdownSelect';
import TextAreaWithActions from '../../../../Layout/TextAreaWithActions';
import useActionTextAreaFiles, {
  actionTextAreaFileActions,
} from '../../../../Layout/TextAreaWithActions/useActionTextAreaFiles';
import KioskSlider from '../../../../Layout/KioskSlider';
import CheckboxDesc from '../../../../Layout/CheckboxDesc';
import NumberInput from '../../../../Layout/NumberInput';
import LargeImage from '../../../../Layout/LargeImage';

import { notificationSuccess, notificationError } from '../../../../../core/actions';
import { setLoadingStatus } from '../../../../../reducers/notification';
import { convertMessageTemplate } from '../../../../../utils/convertMessageTemplate';

const AllAutoMessages = props => {
  const [filterList, setFilterList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [messageObj, setMessageObj] = useState({});
  const [autoMessage, setAutoMessage] = useState({});
  const [add, setAdd] = useState(false);
  const [fileData, fileDispatch] = useActionTextAreaFiles();

  const [editMessageDisable, setEditMessageDisable] = useState(false);
  const [configObj, setConfigObj] = useState({});

  const intervalOptions = [
    { label: 'Days', value: 'DAY' },
    { label: 'Weeks', value: 'WEEK' },
    { label: 'Months', value: 'MONTH' },
  ];
  const [tempRepeats, setTempRepeats] = useState(0);
  const [tempSameDay, setTempSameDay] = useState(true);
  const [tableRowId, setTableRowId] = useState('');
  const [largeImagePopup, setLargeImagePopup] = useState(false);

  useEffect(() => {
    if (props.auth.merchantId) {
      getAutoMessages();
    }
  }, [props.auth.merchantId]);

  const getAutoMessages = () => {
    setLoadingStatus(true);
    (async () => {
      const reqObj = {
        params: ['chatMessages', props.auth.merchantId, 'getAllAutoMessages'],
      };
      const getData = await getRequest(reqObj);
      if (getData) {
        const dataObj = getData.map(obj => {
          let superSecretKey = '';
          if (obj.typeId) {
            superSecretKey = obj.autoMessageId + obj.typeId;
          } else superSecretKey = obj.autoMessageId;
          return {
            ...obj,
            onMouseOver: () => {
              setTableRowId(superSecretKey);
            },
            onMouseLeave: () => {
              setTableRowId('');
            },
          };
        });
        // here
        const configObj = {};
        dataObj.forEach(obj => {
          if (obj.scope === 'Tag' && !props.auth?.merchantFlags?.tags?.enabled) {
          } else if (configObj.hasOwnProperty(obj.scope)) {
            if (configObj[obj.scope].hasOwnProperty(obj.sales_pipeline_id)) {
              configObj[obj.scope][obj.sales_pipeline_id].sales_pipeline_id = obj.sales_pipeline_id;
              configObj[obj.scope][obj.sales_pipeline_id].sales_pipeline_name = obj.sales_pipeline_name;
              if (configObj[obj.scope][obj.sales_pipeline_id].hasOwnProperty(obj.typeId)) {
                configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].type = obj.type;
                configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].typeId = obj.typeId;
                if (configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].hasOwnProperty('msg')) {
                  // we confirmed that there is a property msg, now we are creating a list of messages
                  if (configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].msg.hasOwnProperty(obj.autoMessageId)) {
                    configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].msg[obj.autoMessageId].autoName = obj.msg;
                    configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].msg[obj.autoMessageId].autoMessageId =
                      obj.autoMessageId;
                    configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].msg[obj.autoMessageId].actionInterval =
                      obj.action_interval;
                    configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].msg[obj.autoMessageId].actionScale =
                      obj.action_scale;
                    configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].msg[obj.autoMessageId].repeats =
                      obj.repeats;
                    configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].msg[obj.autoMessageId].repeatScale =
                      obj.repeat_scale;
                    configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].msg[obj.autoMessageId].repeatInterval =
                      obj.repeat_interval;
                    if (
                      configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].msg[obj.autoMessageId].hasOwnProperty(
                        'active',
                      )
                    ) {
                      configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].msg[obj.autoMessageId].active =
                        obj.active;
                    } else {
                      configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].msg[obj.autoMessageId].active = {
                        [obj.active]: obj.active,
                      };
                    }
                  } else {
                    configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].msg[obj.autoMessageId] = {
                      autoName: obj.msg,
                      autoMessageId: obj.autoMessageId,
                      actionInterval: obj.action_interval,
                      actionScale: obj.action_scale,
                      repeats: obj.repeats,
                      repeatScale: obj.repeat_scale,
                      repeatInterval: obj.repeat_interval,
                    };
                  }
                } else {
                  configObj[obj.scope][obj.sales_pipeline_id][obj.typeId].msg = {
                    [obj.autoMessageId]: {
                      autoName: obj.msg,
                      autoMessageId: obj.autoMessageId,
                      actionInterval: obj.action_interval,
                      actionScale: obj.action_scale,
                      repeats: obj.repeats,
                      repeatScale: obj.repeat_scale,
                      repeatInterval: obj.repeat_interval,
                    },
                  };
                }
              } else {
                configObj[obj.scope][obj.sales_pipeline_id][obj.typeId] = {
                  type: obj.type,
                  typeId: obj.typeId,
                  msg: {
                    [obj.autoMessageId]: {
                      autoName: obj.msg,
                      autoMessageId: obj.autoMessageId,
                      actionInterval: obj.action_interval,
                      actionScale: obj.action_scale,
                      repeats: obj.repeats,
                      repeatScale: obj.repeat_scale,
                      repeatInterval: obj.repeat_interval,
                    },
                  },
                };
              }
            } else {
              configObj[obj.scope][obj.sales_pipeline_id] = {
                sales_pipeline_id: obj.sales_pipeline_id,
                sales_pipeline_name: obj.sales_pipeline_name,
                [obj.typeId]: {
                  type: obj.type,
                  typeId: obj.typeId,
                  msg: {
                    [obj.autoMessageId]: {
                      autoName: obj.msg,
                      autoMessageId: obj.autoMessageId,
                      actionInterval: obj.action_interval,
                      actionScale: obj.action_scale,
                      repeats: obj.repeats,
                      repeatScale: obj.repeat_scale,
                      repeatInterval: obj.repeat_interval,
                    },
                  },
                },
              };
            }
          } else {
            configObj[obj.scope] = {
              [obj.sales_pipeline_id]: {
                sales_pipeline_id: obj.sales_pipeline_id,
                sales_pipeline_name: obj.sales_pipeline_name,
                [obj.typeId]: {
                  type: obj.type,
                  typeId: obj.typeId,
                  msg: {
                    [obj.autoMessageId]: {
                      autoName: obj.msg,
                      autoMessageId: obj.autoMessageId,
                      actionInterval: obj.action_interval,
                      actionScale: obj.action_scale,
                      repeats: obj.repeats,
                      repeatScale: obj.repeat_scale,
                      repeatInterval: obj.repeat_interval,
                    },
                  },
                },
              },
            };
          }
        });
        const msgOnlyDataObj = dataObj.filter(data => data.msg !== null);
        setConfigObj(configObj);
        setFilterList(msgOnlyDataObj);
        setTableData(msgOnlyDataObj);
        setLoadingStatus(false);
      }
    })();
  };

  const stopEventBubbling = (event, obj) => {
    event.stopPropagation();
    setOpenPopup(true);
    setMessageObj(obj);
  };

  const deleteHandler = () => {
    const { firstName, lastName } = props.auth;
    const fn = firstName || '';
    const ln = lastName || '';
    if (props.auth.merchantId) {
      (async () => {
        const reqObj = {
          params: ['chatmessages', props.auth.merchantId, 'deleteAutoMessage'],
          query: {
            deleteBy: `${fn} ${ln}`.trim(),
            id: Number(messageObj.autoMessageId),
            scope: messageObj.scope,
          },
          delay: 2200,
        };
        const deleteReq = await deleteRequest(reqObj);
        if (deleteReq) {
          getAutoMessages();
        } else {
          console.error('Error deleting auto message');
        }
        setOpenPopup(false);
      })();
    }
  };

  const searchFunctionality = searchText => {
    if (searchText) {
      const searchArray = searchText.split(' ');
      const filteredArr = [];
      tableData.forEach(tmpl => {
        let hasIt = true;
        searchArray.forEach(sText => {
          const sTextFm = sText.toLowerCase().trim();
          const templateTxt = tmpl.msg || '';
          const subTxt = tmpl.substitution || '';
          if (!templateTxt.toLowerCase().includes(sTextFm) && !subTxt.toLowerCase().includes(sTextFm)) {
            hasIt = false;
          }
        });
        if (hasIt) {
          filteredArr.push(tmpl);
        }
      });
      setFilterList(filteredArr);
    } else {
      setFilterList(tableData);
    }
  };

  const tableHeaders = [
    {
      keyName: 'scope',
      style: { maxWidth: '25%', minWidth: '100px' },
      rowClassName: 'p-left-6 darkBlue',
      headerClassName: 'p-left-6',
      title: 'Scope',
    },
    {
      keyName: 'sales_pipeline_name',
      style: { maxWidth: '25%', minWidth: '110px' },
      title: 'Sales Opportunity',
      sortable: false,
      render: (salesPipelineName, obj) => {
        return obj.scope === 'Sales Opportunity' ? salesPipelineName : 'N/A';
      },
    },
    {
      keyName: 'type',
      style: { minWidth: '160px', width: '30%' },
      headerClassName: 'col-p-l-25 align-left rem-pad-width',
      rowClassName: 'align-left col-p-l-25 rem-pad-width',
      title: 'Type',
      sortable: false,
      render: text => {
        if (text) {
          return text;
        }
        return (
          <span className="gray" style={{ fontStyle: 'italic' }}>
            Not Applicable
          </span>
        );
      },
    },
    {
      keyName: 'msg',
      style: { width: '50%', minWidth: '130px', paddingRight: '22px' },
      headerClassName: 'align-left rem-pad-width',
      rowClassName: 'align-left col-p-r-25 rem-pad-width',
      title: 'Message',
      sortable: false,
      render: (msg, item) => {
        return <div className="line-clamp-1" dangerouslySetInnerHTML={{ __html: convertMessageTemplate(msg) }} />;
      },
    },
    {
      keyName: 'active',
      style: { width: '20%', minWidth: '100px' },
      rowClassName: 'p-left-6 darkBlue',
      headerClassName: 'p-left-6',
      title: 'Status',
      sortable: false,
      render: t => {
        const bubbleStyles = {
          padding: '4px 0 2px 0',
        };
        return (
          <div className="flex-col-left" style={{ justifyContent: 'space-around' }}>
            {Number(t) === 1 ? (
              <div
                style={{ ...bubbleStyles, backgroundColor: '#ecf8dd' }}
                className="rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 green align-center"
              >
                IN USE
              </div>
            ) : (
              <div
                style={{ ...bubbleStyles, backgroundColor: '#ebebeb' }}
                className="rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 gray align-center"
              >
                NOT IN USE
              </div>
            )}
          </div>
        );
      },
    },
    {
      keyName: 'image_url',
      style: { width: '30px', paddingRight: '10px' },
      title: 'Attachments',
      headerClassName: 'align-left',
      rowClassName: '',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        if (t) {
          return (
            <img
              style={{ objectFit: 'cover', borderRadius: '2px', width: '32px', height: '32px', marginTop: '4px' }}
              className="m-right-4"
              alt="#"
              src={t}
              onClick={e => {
                e.stopPropagation();
                setLargeImagePopup(true);
                setMessageObj(o);
              }}
              onError={e => {
                e.target.onerror = null;
                e.target.src = 'https://dashboard-v2-images.s3.amazonaws.com/image-placeholder@2x.png';
              }}
            />
          );
        }
        return (
          <span className="gray" style={{ fontStyle: 'italic' }}>
            None
          </span>
        );
      },
      isImg: 'image',
    },
    {
      keyName: 'id',
      style: { width: '25px' },
      headerClassName: 'align-left rem-pad-width',
      rowClassName: 'align-left rem-pad-width ',
      title: '',
      sortable: false,
      render: (text, obj) => {
        if (obj.autoMessageId + obj.typeId === tableRowId) {
          return (
            <div
              className="w-33 h-33 bg-white border-r4 pointer"
              style={{ marginRight: '5px' }}
              id="deleteTemplate"
              onMouseEnter={e => {
                e.target.style.backgroundColor = '#e8f6ff';
              }}
              onMouseLeave={e => {
                e.target.style.backgroundColor = '#ffffff';
              }}
              onClick={e => stopEventBubbling(e, obj)}
            >
              <FontAwesomeIcon
                icon={['far', 'trash']}
                size="1x"
                className="fs-16 pointer"
                style={{ color: 'black', marginTop: '8px', marginLeft: '10px' }}
              />
            </div>
          );
        }
        return <div className="w-33 h-33 border-r4 pointer" style={{ marginRight: '5px' }} />;
      },
    },
  ];

  const sendMessage = () => {
    const s3_images = fileData.images.urls.filter(image => image.includes('s3.amazonaws.com'));
    const cleanedURLs = fileData.images.urls.filter(image => image.includes('blob'));
    const cleanedImgObj = { urls: cleanedURLs, files: [...fileData.images.files] };
    if (props.auth.merchantId) {
      if (!autoMessage.scope) {
        notificationError('Please add a scope!');
      } else if (!autoMessage.typeId) {
        notificationError('Please add a type!');
      } else if (!autoMessage.msg) {
        notificationError('Please add message text!');
      } else if (autoMessage.repeats && (!autoMessage.repeat_interval || autoMessage.repeat_scale === null)) {
        notificationError('Please check repeating parameters');
      } else {
        (async () => {
          const dataObj = {
            scope: autoMessage.scope,
            autoMessageId: autoMessage.autoMessageId,
            msg: autoMessage.msg,
            typeId: autoMessage.typeId,
            actionInterval: autoMessage.action_interval,
            actionScale: autoMessage.action_scale,
            repeats: autoMessage.scope == 'Life Event' ? 1 : autoMessage.repeats,
            repeatInterval: autoMessage.repeat_interval ? autoMessage.repeat_interval : null,
            repeatScale: autoMessage.repeat_scale ? autoMessage.repeat_scale : null,
            createdBy: `${props.auth.firstName} ${props.auth.lastName}`,
            createdDate:
              autoMessage.scope == 'Life Event' ? moment().format('YYYYMMDDHHmm') : moment().format('YYYYMMDD'),
            active: autoMessage.active,
          };
          const reqObj = {
            params: ['chatMessages', props.auth.merchantId, 'updateOrSaveGlobalAutoMessages'],
            query: { s3Images: s3_images },
            data: dataObj,
            images: cleanedImgObj,
          };
          const data = await postRequest(reqObj);
          if (data.length > 0) {
            if (autoMessage.autoMessageId) {
              notificationSuccess('Auto Message Updated');
              setOpenPopup(false);
              setEditMessageDisable(false);
            } else {
              // adding table
              notificationSuccess('Auto Message Created');
              setOpenAdd(false);
              setEditMessageDisable(false);
            }
            //  setLoadingStatus(true);
            setAutoMessage({});
            fileDispatch({
              type: actionTextAreaFileActions.clear,
            });
            setTimeout(() => {
              getAutoMessages();
            }, 2000);
          } else {
            console.log('else hit during save');
          }
        })();
      }
    }
  };

  const rowClickHandler = (data, event) => {
    setOpenPopup(false);
    setEditMessageDisable(true);
    setAutoMessage({
      ...data,
      repeats: data.repeats === 1 ? true : false,
      repeat_scale: data?.action_scale,
      repeat_interval: data?.action_interval,
    });
    setAdd(false);
    if (data.image_url) {
      fileDispatch({
        type: actionTextAreaFileActions.setToSingleImageUrl,
        payload: data.image_url,
      });
    } else {
      fileDispatch({
        type: actionTextAreaFileActions.clear,
      });
    }
    if (data.action_scale > 0) {
      setTempSameDay(false);
    } else {
      setTempSameDay(true);
    }
  };

  const immediateFunction = () => {
    setTempSameDay(!tempSameDay);
    if (!tempSameDay) {
      setAutoMessage({ ...autoMessage, action_scale: 0, action_interval: 'DAY' });
    } else {
      setAutoMessage({
        ...autoMessage,
        action_scale: autoMessage.action_scale,
        action_interval: autoMessage.action_interval,
      });
    }
  };

  const repeatFunction = () => {
    setAutoMessage({ ...autoMessage, repeats: !autoMessage.repeats });
    if (autoMessage.repeats) {
      setAutoMessage({
        ...autoMessage,
        repeats: 1,
        repeatScale: autoMessage.repeat_scale,
        repeatInterval: autoMessage.repeat_interval,
      });
    } else {
      setAutoMessage({ ...autoMessage, repeats: 0, repeatScale: '', repeatInterval: '' });
    }
  };

  const setMessage = message => {
    if (autoMessage.autoMessageId) {
      setAutoMessage({ ...autoMessage, msg: message, autoMessageId: autoMessage.autoMessageId });
    } else {
      setAutoMessage({ ...autoMessage, msg: message });
    }
  };

  const addAutoMessage = () => {
    setOpenAdd(true);
    setAutoMessage({ ...autoMessage, action_scale: 0, action_interval: 'DAY', active: 1, repeats: 0 });
    setTempSameDay(true);
  };

  return (
    <Layout
      title="Auto Messages"
      description="View all Auto Messages and edit their configuration"
      classProps="standard-width-with-sidebar rem-pad-width"
      breadCrumbs={[{ title: 'Messaging' }, { title: 'Auto Messages' }]}
    >
      <div className="w-100-P flex-row-nospacebetween-wrap m-btm-28" style={{ gridGap: '15px' }}>
        <StyledLink classprops=" w-48-P" to="/Messaging/auto-msg-runtime" styleprops={{ minWidth: '380px' }}>
          <div className="flex-col-left border-r8-grey p-16 rem-pad-width w-100-P">
            <span className="CBdarkBlue fw-500 fs-12">Upcoming Messages</span>
            <span className="fs-12 gray fw-400">View all scheduled Auto Messages</span>
          </div>
        </StyledLink>
        <StyledLink classprops="w-48-P" to="/Messaging/auto-msg-history" styleprops={{ minWidth: '380px' }}>
          <div className="flex-col-left border-r8-grey p-16 rem-pad-width w-100-P">
            <span className="CBdarkBlue fw-500 fs-12">Historical Log</span>
            <span className="fs-12 gray fw-400">View all past Auto Messages</span>
          </div>
        </StyledLink>
      </div>
      <div className="w-100-P align-right">
        <input
          className="h-50 w-100-P m-btm-6 body-head-search rem-pad-width focus-input-outline-blue"
          style={{ width: '100%', height: '50px' }}
          placeholder="Search auto messages"
          onChange={e => searchFunctionality(e.target.value)}
        />
        <Button
          classname="transparentButton"
          onclick={() => {
            addAutoMessage();
          }}
          styleProps={{ width: '200px' }}
        >
          <FontAwesomeIcon
            icon={['far', 'comment-alt-medical']}
            size="1x"
            className="fs-16 m-right-15"
            style={{ paddingBottom: '2px' }}
          />
          Add Auto Message
        </Button>
      </div>
      <Table
        tableClassName="lg-table-sidebar rem-pad-width"
        data={filterList}
        tableHeaders={tableHeaders}
        searchable={false}
        onRowClick={rowClickHandler}
        styleProps={{ marginTop: '-8px' }}
        tableName="AUTO_MESSAGES"
      />
      {openAdd ? (
        <PopMessage
          popStyle={{
            maxWidth: '600px',
            width: '100%',
            minHeight: '300px',
            overflowY: 'scroll',
            margin: '0px 0px',
          }}
          confirm="Add Auto Message"
          customContent
          noButtonDisable
          confirmationButtonText="Save"
          onCancel={() => {
            setOpenAdd(false);
            setAutoMessage({});
            fileDispatch({
              type: actionTextAreaFileActions.clear,
            });
            setTempSameDay(true);
          }}
          onConfirm={() => sendMessage()}
          description={[
            <div key="description-key">
              <DropdownSelect
                classProps="maw-100-P w-100-P align-left "
                options={Object.keys(configObj).map((st, i) => ({
                  label: st,
                  value: st,
                }))}
                placeholder="Scope"
                value={autoMessage.scope}
                onChange={obj => {
                  setAutoMessage({ ...autoMessage, scope: obj.value });
                }}
              />
              {!autoMessage.scope ? null : autoMessage.scope === 'Sales Opportunity' ? (
                // SALES OPP SPECIAL DROP DOWN
                <div>
                  <DropdownSelect
                    classProps="maw-100-P w-100-P align-left m-top-10 "
                    options={Object.values(configObj[autoMessage.scope]).map(pipeline => ({
                      label: pipeline.sales_pipeline_name,
                      value: pipeline.sales_pipeline_id,
                    }))}
                    placeholder="Sales Pipeline"
                    value={autoMessage.pipelineId ? autoMessage.pipelineId : ''}
                    onChange={obj => {
                      setAutoMessage({
                        ...autoMessage,
                        pipelineId: obj.value,
                        pipeline: obj.label,
                        typeId: '',
                        type: '',
                        autoMessageId: '',
                        msg: '',
                      });
                    }}
                    optionalStyles={{
                      menuList: (provided, state) => ({
                        ...provided,
                        height: '180px',
                      }),
                    }}
                  />
                  {configObj[autoMessage.scope].hasOwnProperty(autoMessage.pipelineId) ? (
                    <DropdownSelect
                      classProps="maw-100-P w-100-P align-left m-top-10 "
                      options={Object.values(configObj[autoMessage.scope][autoMessage.pipelineId])
                        .filter(pipeline => typeof pipeline === 'object')
                        .map(type => ({
                          label: type.type,
                          value: type.typeId,
                        }))}
                      placeholder="Type"
                      value={autoMessage.typeId ? autoMessage.typeId : ''}
                      onChange={obj => {
                        setAutoMessage({
                          ...autoMessage,
                          typeId: obj.value,
                          type: obj.label,
                          autoMessageId: '',
                          msg: '',
                        });
                      }}
                      optionalStyles={{
                        menuList: (provided, state) => ({
                          ...provided,
                          height: '180px',
                          overflowY: 'scroll',
                        }),
                      }}
                    />
                  ) : null}
                  {/* checking to make sure .autoReminders values are there */}
                  {configObj[autoMessage.scope].hasOwnProperty(autoMessage.pipelineId) &&
                  configObj[autoMessage.scope][autoMessage.pipelineId].hasOwnProperty(autoMessage.typeId) ? (
                    <div className="m-top-30">
                      <div className="flex-row-spacebetween-nowrap">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                          <span style={{ fontSize: '13px', fontWeight: '500' }}>Turn On/Off Automation</span>
                          <span style={{ fontSize: '10px', color: '#979797' }}>
                            Enable or disable this auto-message and all linked automated actions
                          </span>
                        </div>
                        <KioskSlider
                          value={autoMessage.active}
                          onChange={() => {
                            setAutoMessage({ ...autoMessage, active: !autoMessage.active });
                          }}
                        />
                      </div>
                      <div>
                        <TextAreaWithActions
                          val={autoMessage.msg ? autoMessage.msg : ''}
                          changeHandler={setMessage}
                          fileData={fileData}
                          fileDispatch={fileDispatch}
                          containerStyleProps={{
                            marginTop: '16px',
                          }}
                          styleProps={{ height: '11vh' }}
                          autoMessageOrReminder
                          template
                          associate=":assigned_associate_name:"
                        />
                      </div>
                      {/* IMMEDIATELY */}
                      <span className="fs-10 gray d-block m-top-30 m-btm-15 align-left">
                        How soon should the message send?
                      </span>
                      <CheckboxDesc
                        checked={tempSameDay}
                        onclick={immediateFunction}
                        text="Same Day"
                        classProps="fs-13 m-top-10"
                        checkButtonProps="m-btm-15 w-85"
                      />
                      {!tempSameDay ? (
                        <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel m-btm-28">
                          <div className="w-48-P inputHeight align-left" style={{ position: 'relative' }}>
                            <NumberInput
                              onChange={val => setAutoMessage({ ...autoMessage, action_scale: val })}
                              min="1"
                              max="31"
                              step={1}
                              precision={1}
                              value={autoMessage.action_scale}
                              classProps=""
                              placeholder="1"
                            />
                          </div>
                          <div className=" w-48-P inputHeight" style={{ position: 'relative' }}>
                            <DropdownSelect
                              classProps="maw-100-P w-100-P align-left"
                              options={intervalOptions}
                              placeholder="Week"
                              value={autoMessage.action_interval}
                              onChange={obj => {
                                setAutoMessage({ ...autoMessage, action_interval: obj.value });
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                      {/* REPEATS */}
                      {autoMessage.scope !== 'Life Event' ? (
                        <div className="max-w-600 w-100-P">
                          <span className="fs-10 gray d-block m-btm-15 align-left">
                            Once the initial message is sent, how often would you like it to repeat?
                          </span>
                          <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel m-btm-28">
                            <CheckboxDesc
                              // checked={Number(tempRepeats)}
                              checked={autoMessage.repeats}
                              // onclick={repeatFunction}
                              onclick={() => setAutoMessage({ ...autoMessage, repeats: !autoMessage.repeats })}
                              text="Repeats"
                              classProps="fs-13"
                              checkButtonProps="m-btm-15"
                            />
                            {/* {Number(tempRepeats) ? ( */}
                            {autoMessage.repeats ? (
                              <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel">
                                <div className="w-48-P inputHeight align-left" style={{ position: 'relative' }}>
                                  <NumberInput
                                    onChange={val => setAutoMessage({ ...autoMessage, repeat_scale: val })}
                                    min="1"
                                    max="31"
                                    step={1}
                                    precision={1}
                                    value={autoMessage.repeat_scale || ''}
                                    classProps=""
                                    placeholder="6"
                                  />
                                </div>
                                <div className=" w-48-P inputHeight" style={{ position: 'relative' }}>
                                  <DropdownSelect
                                    classProps="maw-100-P w-100-P align-left"
                                    options={intervalOptions}
                                    placeholder="Months"
                                    value={autoMessage.repeat_interval || ''}
                                    onChange={obj => {
                                      setAutoMessage({ ...autoMessage, repeat_interval: obj.value });
                                    }}
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    ) : null}
                </div>
              ) : (
                // START OF CODE THAT IS NOT A SALES OPPORTUNITY
                <div>
                  <DropdownSelect
                    classProps="maw-100-P w-100-P align-left m-top-10 "
                    options={Object.values(configObj[autoMessage.scope][0]).map(type => ({
                      label: type.type,
                      value: type.typeId,
                    }))}
                    placeholder="Type"
                    value={autoMessage.typeId ? autoMessage.typeId : ''}
                    onChange={obj => {
                      setAutoMessage({
                        ...autoMessage,
                        typeId: obj.value,
                        type: obj.label,
                        autoMessageId: '',
                        msg: '',
                      });
                    }}
                    optionalStyles={{
                      menuList: (provided, state) => ({
                        ...provided,
                        height: '180px',
                      }),
                    }}
                  />
                  {configObj[autoMessage.scope][0].hasOwnProperty(autoMessage.typeId) &&
                  configObj[autoMessage.scope][0][autoMessage.typeId].hasOwnProperty('msg') ? (
                    <div className="m-top-30">
                      <div className="flex-row-spacebetween-nowrap">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                          <span style={{ fontSize: '13px', fontWeight: '500' }}>Turn On/Off Automation</span>
                          <span style={{ fontSize: '10px', color: '#979797' }}>
                            Enable or disable this auto-message and all linked automated actions
                          </span>
                        </div>
                        <KioskSlider
                          value={autoMessage.active}
                          onChange={() => {
                            setAutoMessage({ ...autoMessage, active: !autoMessage.active });
                          }}
                        />
                      </div>
                      <div>
                        <TextAreaWithActions
                          val={autoMessage.msg ? autoMessage.msg : ''}
                          changeHandler={setMessage}
                          fileData={fileData}
                          fileDispatch={fileDispatch}
                          containerStyleProps={{
                            marginTop: '16px',
                          }}
                          styleProps={{ height: '11vh' }}
                          autoMessageOrReminder
                          template
                          associate=":assigned_associate_name:"
                        />
                      </div>
                      {/* IMMEDIATELY */}
                      <span className="fs-10 gray d-block m-top-30 m-btm-15 align-left">
                        How soon should the message send?
                      </span>
                      <CheckboxDesc
                        checked={tempSameDay}
                        onclick={immediateFunction}
                        text="Same Day"
                        classProps="fs-13 m-top-10"
                        checkButtonProps="m-btm-15 w-85"
                      />
                      {!tempSameDay ? (
                        <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel m-btm-28">
                          <div className="w-48-P inputHeight align-left" style={{ position: 'relative' }}>
                            <NumberInput
                              onChange={val => setAutoMessage({ ...autoMessage, action_scale: val })}
                              min="1"
                              max="31"
                              step={1}
                              precision={1}
                              value={autoMessage.action_scale}
                              classProps=""
                              placeholder="1"
                            />
                          </div>
                          <div className=" w-48-P inputHeight" style={{ position: 'relative' }}>
                            <DropdownSelect
                              classProps="maw-100-P w-100-P align-left"
                              options={intervalOptions}
                              placeholder="Week"
                              value={autoMessage.action_interval}
                              onChange={obj => {
                                setAutoMessage({ ...autoMessage, action_interval: obj.value });
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                      {/* REPEATS */}
                      {autoMessage.scope !== 'Life Event' ? (
                        <div className="max-w-600 w-100-P">
                          <span className="fs-10 gray d-block m-btm-15 align-left">
                            Once the initial message is sent, how often would you like it to repeat?
                          </span>
                          <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel m-btm-28">
                            <CheckboxDesc
                              // checked={Number(tempRepeats)}
                              checked={autoMessage.repeats}
                              // onclick={repeatFunction}
                              onclick={() => setAutoMessage({ ...autoMessage, repeats: !autoMessage.repeats })}
                              text="Repeats"
                              classProps="fs-13"
                              checkButtonProps="m-btm-15"
                            />
                            {/* {Number(tempRepeats) ? ( */}
                            {autoMessage.repeats ? (
                              <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel">
                                <div className="w-48-P inputHeight align-left" style={{ position: 'relative' }}>
                                  <NumberInput
                                    onChange={val => setAutoMessage({ ...autoMessage, repeat_scale: val })}
                                    min="1"
                                    max="31"
                                    step={1}
                                    precision={1}
                                    value={autoMessage.repeat_scale || ''}
                                    classProps=""
                                    placeholder="6"
                                  />
                                </div>
                                <div className=" w-48-P inputHeight" style={{ position: 'relative' }}>
                                  <DropdownSelect
                                    classProps="maw-100-P w-100-P align-left"
                                    options={intervalOptions}
                                    placeholder="Months"
                                    value={autoMessage.repeat_interval || ''}
                                    onChange={obj => {
                                      setAutoMessage({ ...autoMessage, repeat_interval: obj.value });
                                    }}
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    ) : null}
                </div>
              )}
            </div>,
          ]}
        />
      ) : null}
      {editMessageDisable ? (
        <PopMessage
          popStyle={{
            maxWidth: '600px',
            width: '100%',
            minHeight: '300px',
            transform: 'translate(-50%, -50%)',
            marginTop: '0px',
            marginLeft: '0px',
          }}
          confirm={add ? 'Add Auto Message' : 'Edit Auto Message'}
          customContent
          noButtonDisable
          confirmationButtonText="Save"
          onCancel={() => {
            setEditMessageDisable(false);
            setAutoMessage({});
            fileDispatch({ type: actionTextAreaFileActions.clear });
            setTempSameDay(true);
          }}
          onConfirm={() => sendMessage()}
          description={[
            <div>
              <DropdownSelect
                classProps="maw-100-P w-100-P align-left "
                options={Object.keys(configObj).map((st, i) => ({
                  label: st,
                  value: st,
                }))}
                placeholder="Scope"
                value={autoMessage.scope}
                onChange={obj => {
                  setAutoMessage({ scope: obj.value });
                }}
                disabled={!!editMessageDisable}
              />
              {!autoMessage.scope ? null : autoMessage.scope === 'Sales Opportunity' ? (
                // SALES OPP SPECIAL DROP DOWN
                <div>
                  <DropdownSelect
                    classProps="maw-100-P w-100-P align-left m-top-10 "
                    options={Object.values(configObj[autoMessage.scope]).map(pipeline => ({
                      label: pipeline.sales_pipeline_name,
                      value: pipeline.sales_pipeline_id,
                    }))}
                    placeholder="Sales Pipeline"
                    value={autoMessage.pipelineId ? autoMessage.pipelineId : ''}
                    onChange={obj => {
                      setTempSameDay(true);
                      fileDispatch({
                        type: actionTextAreaFileActions.clear,
                      });
                      setAutoMessage({
                        ...autoMessage,
                        pipelineId: obj.value,
                        pipeline: obj.label,
                        typeId: '',
                        type: '',
                        autoMessageId: '',
                        msg: '',
                        repeats: 0,
                      });
                    }}
                    optionalStyles={{
                      menuList: (provided, state) => ({
                        ...provided,
                        height: '180px',
                      }),
                    }}
                  />
                  {configObj[autoMessage.scope].hasOwnProperty(autoMessage.pipelineId) ? (
                    <DropdownSelect
                      classProps="maw-100-P w-100-P align-left m-top-10 "
                      options={Object.values(configObj[autoMessage.scope][autoMessage.pipelineId])
                        .filter(pipeline => typeof pipeline === 'object')
                        .map(type => ({
                          label: type.type,
                          value: type.typeId,
                        }))}
                      placeholder="Type"
                      value={autoMessage.typeId ? autoMessage.typeId : ''}
                      onChange={obj => {
                        setAutoMessage({
                          ...autoMessage,
                          typeId: obj.value,
                          type: obj.label,
                          autoMessageId: '',
                          msg: '',
                        });
                      }}
                      optionalStyles={{
                        menuList: (provided, state) => ({
                          ...provided,
                          height: 'auto',
                        }),
                      }}
                    />
                  ) : null}
                  {/* checking to make sure .autoReminders values are there */}
                  {configObj[autoMessage.scope].hasOwnProperty(autoMessage.pipelineId) &&
                  configObj[autoMessage.scope][autoMessage.pipelineId].hasOwnProperty(autoMessage.typeId) ? (
                    <div className="m-top-30">
                      <div className="flex-row-spacebetween-nowrap">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                          <span style={{ fontSize: '13px', fontWeight: '500' }}>Turn On/Off Automation</span>
                          <span style={{ fontSize: '10px', color: '#979797' }}>
                            Enable or disable this auto-message and all linked automated actions
                          </span>
                        </div>
                        <KioskSlider
                          value={autoMessage.active}
                          onChange={() => {
                            setAutoMessage({ ...autoMessage, active: !autoMessage.active });
                          }}
                        />
                      </div>
                      <div>
                        <TextAreaWithActions
                          val={autoMessage.msg ? autoMessage.msg : ''}
                          changeHandler={setMessage}
                          fileData={fileData}
                          fileDispatch={fileDispatch}
                          containerStyleProps={{
                            marginTop: '16px',
                          }}
                          styleProps={{ height: '11vh' }}
                          autoMessageOrReminder
                          template
                          associate=":assigned_associate_name:"
                        />
                      </div>
                      {/* IMMEDIATELY */}
                      <span className="fs-10 gray d-block m-top-30 m-btm-15 align-left">
                        How soon should the message send?
                      </span>
                      <CheckboxDesc
                        checked={tempSameDay}
                        onclick={immediateFunction}
                        text="Same Day"
                        classProps="fs-13 m-top-10"
                        checkButtonProps="m-btm-15 w-85"
                      />
                      {!tempSameDay ? (
                        <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel m-btm-28">
                          <div className="w-48-P inputHeight align-left" style={{ position: 'relative' }}>
                            <NumberInput
                              onChange={val => setAutoMessage({ ...autoMessage, action_scale: val })}
                              min="1"
                              max="31"
                              step={1}
                              precision={1}
                              value={autoMessage.action_scale}
                              classProps=""
                              placeholder="1"
                            />
                          </div>
                          <div className=" w-48-P inputHeight" style={{ position: 'relative' }}>
                            <DropdownSelect
                              classProps="maw-100-P w-100-P align-left"
                              options={intervalOptions}
                              placeholder="Week"
                              value={autoMessage.action_interval}
                              onChange={obj => {
                                setAutoMessage({ ...autoMessage, action_interval: obj.value });
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                      {/* REPEATS */}
                      {autoMessage.scope !== 'Life Event' ? (
                        <div className="max-w-600 w-100-P">
                          <span className="fs-10 gray d-block m-btm-15 align-left">
                            Once the initial message is sent, how often would you like it to repeat?
                          </span>
                          <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel m-btm-28">
                            <CheckboxDesc
                              // checked={Number(tempRepeats)}
                              checked={autoMessage.repeats}
                              // onclick={repeatFunction}
                              onclick={() => setAutoMessage({ ...autoMessage, repeats: !autoMessage.repeats })}
                              text="Repeats"
                              classProps="fs-13"
                              checkButtonProps="m-btm-15"
                            />
                            {/* {Number(tempRepeats) ? ( */}
                            {autoMessage.repeats ? (
                              <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel">
                                <div className="w-48-P inputHeight align-left" style={{ position: 'relative' }}>
                                  <NumberInput
                                    onChange={val => setAutoMessage({ ...autoMessage, repeat_scale: val })}
                                    min="1"
                                    max="31"
                                    step={1}
                                    precision={1}
                                    value={autoMessage.repeat_scale || ''}
                                    classProps=""
                                    placeholder="6"
                                  />
                                </div>
                                <div className=" w-48-P inputHeight" style={{ position: 'relative' }}>
                                  <DropdownSelect
                                    classProps="maw-100-P w-100-P align-left"
                                    options={intervalOptions}
                                    placeholder="Months"
                                    value={autoMessage.repeat_interval || ''}
                                    onChange={obj => {
                                      setAutoMessage({ ...autoMessage, repeat_interval: obj.value });
                                    }}
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    ) : null}
                </div>
              ) : (
                // EDIT CODE NOT RELATED TO SALES OPPORTUNITIES
                <div>
                  <DropdownSelect
                    classProps="maw-100-P w-100-P align-left m-top-10 "
                    options={Object.values(configObj[autoMessage.scope][0]).map(type => ({
                      label: type.type,
                      value: type.typeId,
                    }))}
                    placeholder="Type"
                    value={autoMessage.typeId ? autoMessage.typeId : ''}
                    onChange={obj => {
                      setAutoMessage({ ...autoMessage, typeId: obj.value, type: obj.label });
                    }}
                  />

                  <div className="m-top-30">
                    <div className="flex-row-spacebetween-nowrap">
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <span style={{ fontSize: '13px', fontWeight: '500' }}>Turn On/Off Automation</span>
                        <span style={{ fontSize: '10px', color: '#979797' }}>
                          Enable or disable this auto-message and all linked automated actions
                        </span>
                      </div>
                      <KioskSlider
                        value={autoMessage.active}
                        onChange={() => {
                          setAutoMessage({ ...autoMessage, active: !autoMessage.active });
                        }}
                      />
                    </div>
                    <div>
                      <TextAreaWithActions
                        val={autoMessage.msg ? autoMessage.msg : ''}
                        changeHandler={setMessage}
                        fileData={fileData}
                        fileDispatch={fileDispatch}
                        containerStyleProps={{
                          marginTop: '16px',
                        }}
                        styleProps={{ height: '11vh' }}
                        autoMessageOrReminder
                        template
                        associate=":assigned_associate_name:"
                      />
                    </div>
                    {/* IMMEDIATELY */}
                    <span className="fs-10 gray d-block m-top-30 m-btm-15 align-left">
                      How far in advance should the message send?
                    </span>
                    <CheckboxDesc
                      checked={tempSameDay}
                      onclick={immediateFunction}
                      text="Same Day"
                      classProps="fs-13 m-top-10"
                      checkButtonProps="m-btm-15 w-85"
                    />
                    {!tempSameDay ? (
                      <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel m-btm-28">
                        <div className="w-48-P inputHeight align-left" style={{ position: 'relative' }}>
                          <NumberInput
                            onChange={val => setAutoMessage({ ...autoMessage, action_scale: val })}
                            min="1"
                            max="31"
                            step={1}
                            precision={1}
                            value={autoMessage.action_scale}
                            classProps=""
                            placeholder="1"
                          />
                        </div>
                        <div className=" w-48-P inputHeight" style={{ position: 'relative' }}>
                          <DropdownSelect
                            classProps="maw-100-P w-100-P align-left"
                            options={intervalOptions}
                            placeholder="Week"
                            value={autoMessage.action_interval}
                            onChange={obj => {
                              setAutoMessage({ ...autoMessage, action_interval: obj.value });
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                    {/* REPEATS */}
                    {autoMessage.scope !== 'Life Event' ? (
                      <div className="max-w-600 w-100-P">
                        <span className="fs-10 gray d-block m-btm-15 align-left">
                          Once the initial message is sent, how often would you like it to repeat?
                        </span>
                        <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel m-btm-28">
                          <CheckboxDesc
                            // checked={Number(tempRepeats)}
                            checked={autoMessage.repeats}
                            // onclick={repeatFunction}
                            onclick={() => setAutoMessage({ ...autoMessage, repeats: !autoMessage.repeats })}
                            text="Repeats"
                            classProps="fs-13"
                            checkButtonProps="m-btm-15"
                          />
                          {autoMessage.repeats ? (
                            <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel">
                              <div className="w-48-P inputHeight align-left" style={{ position: 'relative' }}>
                                <NumberInput
                                  onChange={val => setAutoMessage({ ...autoMessage, repeat_scale: val })}
                                  min="1"
                                  max="31"
                                  step={1}
                                  precision={1}
                                  value={autoMessage.repeat_scale || ''}
                                  classProps=""
                                  placeholder="6"
                                />
                              </div>
                              <div className=" w-48-P inputHeight" style={{ position: 'relative' }}>
                                <DropdownSelect
                                  classProps="maw-100-P w-100-P align-left"
                                  options={intervalOptions}
                                  placeholder="Months"
                                  value={autoMessage.repeat_interval || ''}
                                  onChange={obj => {
                                    setAutoMessage({ ...autoMessage, repeat_interval: obj.value });
                                  }}
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>,
          ]}
        />
      ) : null}
      {openPopup ? (
        <PopConfirm
          customContent={false}
          confirm="Cancel this auto message and all future instances?"
          onCancel={() => setOpenPopup(false)}
          onConfirm={() => deleteHandler()}
          noButtonDisable
        />
      ) : null}
      {largeImagePopup ? <LargeImage setLargeImagePopup={setLargeImagePopup} templateObj={messageObj} /> : null}
    </Layout>
  );
};
const mapStateToProps = state => state;
export default connect(mapStateToProps, {})(AllAutoMessages);
