import React from 'react';
import StandardLayout from '../../../../../../Layout/StandardLayout';
import { connect } from 'react-redux';
import ReminderMessageTemplates from '../../../../../../Layout/AutoReminders/AutoReminderSettings/ReminderMessageTemplates';
import SecondaryPanel from '../../../../../../Layout/SecondaryPanel';

const LifeEventMsgTemplate = props => {
  const listItemId = props.match.params.listItemId;
  const reminderId = props.match.params.reminderId;

  return (
    <StandardLayout
      title={`${props.msgTempAddOrEdit} Message Template for Auto Reminder`}
      description={'Create message templates that appear with the auto reminder'}
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Life Events', to: '/Configuration/life-events' },
        {
          title: 'Edit Life Event',
          to: `/Configuration/life-events/edit-life-events/${listItemId}`,
        },
        {
          title: `Auto Reminder`,
          to: `/Configuration/life-events/edit-life-events/${listItemId}/edit-auto-reminder/${reminderId}`,
        },
        { title: `${props.msgTempAddOrEdit} Message Template` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <ReminderMessageTemplates
        cancelRoute={`/Configuration/life-events/edit-life-events/${listItemId}/edit-auto-reminder/${reminderId}`}
        popConfirmDesc={'This will remove the template from this Reminder'}
        type={'lifeEvent'} //// REMEMBER THIS
        match={props.match}
      />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  ...state.autoRemMsgs,
});
export default connect(mapStateToProps, {})(LifeEventMsgTemplate);
