import { Rate } from 'antd-v5';
import React, { useMemo } from 'react';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ToastTitle = ({type, client_name}) => {

  const message = useMemo(() => {
    switch (type) {
      case 'sms':
        return <>New <span className='fw-500'>SMS</span> from <span className='fw-500'>{client_name}</span></>;
      case 'facebook':
        return <>New <span className='fw-500'>Facebook</span> message</>;
      case 'instagram':
        return <>New <span className='fw-500'>Instagram</span> message</>;
      case 'google':
        return <>New <span className='fw-500'>Google Review</span></>;
      case 'webchat':
        return <>New <span className='fw-500'>Webchat</span> conversation</>;
      default:
        return `New Message`;
    }
  }, [])

  return (
    <div className="fs-16 fw-400">
      {message}
    </div>
  );
};


export const ToastMessage = ({type, msg, rating = 0}) => {

  const message = useMemo(() => {
    switch (type) {
      case 'sms':
      case 'facebook':
      case 'webchat':
        return msg;
      case 'google':
        return <>
          <Rate
            allowHalf
            disabled
            defaultValue={2.5}
            style={{ fontSize: '12px', color: '#000', marginRight: '12px' }}
            className='notification-rate'
          />
          {msg}
        </>;
      default:
        return msg || '';
    }
  }, [])

  return (
    <div className="fs-14 fw-400 line-clamp-1">
      {message}
    </div>
  )
}

export const ToastActionBtn = ({onClick, onClose}) => {
  return (
    <div className="flex-row-nospacebetween-nowrap gap-8">
      <FontAwesomeIcon
        className="fs-16 pointer"
        color="#D0D0D0"
        icon={['far', 'external-link-alt']}
        onClick={onClick}
      />
      <FontAwesomeIcon
        className="fs-16 pointer"
        color="#D0D0D0"
        icon={['far', 'times']}
        onClick={onClose}
      />
    </div>
  );
}
