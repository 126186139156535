import React, { useMemo } from 'react';
import { format, parse } from 'date-fns';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import formatNumberAsCurrency from '../../../../../../../../utils/formatNumberAsCurrency';
import './styles.scss';

const formatAmount = value => {
  const parsedValue = parseFloat(value);

  if (!value && parsedValue !== 0) {
    return '-.--';
  }

  return formatNumberAsCurrency(value);
};

const SalesOpportunityCard = ({ salesOpportunity, onClick }) => {
  const dueDate = useMemo(() => {
    const dateString = `${salesOpportunity?.close_date}`.slice(0, 8);
    const parsedDate = parse(dateString, 'yMMdd', new Date());

    return format(parsedDate, 'MMMM d, y');
  }, [salesOpportunity.close_date]);

  return (
    <div className="cb-sales-opportunity-card">
      <h2 className="cb-sales-opportunity-card--amount">{formatAmount(salesOpportunity.amount)}</h2>

      <h3 className="cb-sales-opportunity-card--created-date">{`Due on ${dueDate}`}</h3>

      <h4 className="cb-sales-opportunity-card--name">{salesOpportunity.name}</h4>

      <span className="cb-sales-opportunity-card--stage">{salesOpportunity.stage}</span>

      <Button
        type="text"
        icon={<RightOutlined />}
        className="cb-sales-opportunity-card--next-button"
        onClick={onClick}
      />
    </div>
  );
};

export default SalesOpportunityCard;
