import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Avatar, Badge, Button, Drawer, Dropdown, Menu, Tooltip } from 'antd-v5';
import { MenuOutlined, UserOutlined, CloseOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { wipeReduxState } from '../../../reducers/auth';
import Edison from '../../../components/Navigation_V2/Edison';
import { BulbOnIcon } from '../Report/components/Icons';
import AIAssistantButton from '../AIAssistantButton';
import { useLayout } from '../../../context/Layout';


const TopNavigationV2 = ({ auth, logout }) => {

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const history = useHistory();
  const { hasNewMessages } = useLayout();
  const [collapsed, setCollapsed] = useState(false);
  const responsiveMenuItem = useMemo(() => width < 950, [width]);

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const onClickMenu = useCallback(
    ({ key }) => {
      if (key === 'none' || key === 'ai') return;
      history.push(key === 'today' ? '/' : key);
    },
    [history],
  );

  const menuItems = useMemo(
    () => [
      {
        key: 'none',
        label: <img src="https://dashboard-v2-images.s3.amazonaws.com/horizontal_nav_cb.svg" className="w-26 h-26" />,
        disabled: true,
        style: { cursor: 'default' },
        hidden: responsiveMenuItem,
      },
      {
        key: 'today',
        label: 'Today',
      },
      {
        key: '/Messaging/inbox',
        label: (
          <Badge offset={[8, 16]} color="#33AAFC" dot={hasNewMessages}>
            <span>Inbox</span>
          </Badge>
        ),
      },
      {
        key: '/Clients',
        label: 'Clients',
      },
      {
        key: '/Products',
        label: 'Products',
      },
      {
        key: '5',
        label: <div style={{ height: '32px', opacity: 0.06, margin: 0, background: '#000', width: '1px' }} />,
        style: { padding: '0 4px' },
        hidden: auth.role === 'ASSOCIATE' || responsiveMenuItem,
      },
      {
        key: auth.launchFlagsForNav?.reportsPage ? '/Reports' : '/Home',
        label: 'Reports',
        hidden: auth.role === 'ASSOCIATE',
      },
      {
        key: '/Messaging/mass-messaging',
        label: 'Mass Messages',
        hidden: auth.role === 'ASSOCIATE',
      },
      {
        key: auth.role === 'ADMIN' ? '/Configuration/app-settings' : '/Stores',
        label: 'Setup',
        hidden: auth.role === 'ASSOCIATE',
      },
      {
        key: 'ai',
        label: <AIAssistantButton isIntro={!auth?.edison_view_date} userId={auth.userId} />,
        hidden: !auth?.launchFlagsForNav?.projectEdison,
        disabled: true,
        style: { cursor: 'default' },
      },
    ],
    [auth, responsiveMenuItem, hasNewMessages],
  );

  const [key, setKey] = useState('/');

  const getKey = useCallback(
    (pathname = '') => {
      if (pathname.includes('/Reports')) {
        setKey('/Reports');
        return;
      }
      if (pathname.includes('/Messaging/mass-messaging')) {
        setKey('/Messaging/mass-messaging');
        return;
      }
      if (pathname.includes('/Configuration')) {
        setKey(auth.role === 'ADMIN' ? '/Configuration/app-settings' : '/Stores');
        return;
      }
      switch (pathname) {
        case '/':
        case '/all-reminders':
        case '/all-payments':
        case '/all-sales-opp':
          setKey('today');
          return;
      case '/Messaging/inbox':
          setKey('/Messaging/inbox');
          return;
        case '/Clients':
          setKey('/Clients');
          return;
        case '/Products':
          setKey('/Products');
        return;
        case '/Stores':
        case '/Associates':
        case '/Help/integrations':
        case '/Messaging/all-templates':
      case '/Help/web-chat-setup':
        case '/Help/integrations/google':
        case '/Help/integrations/facebook':
        case '/BankAccount/list':
      case '/Help/export-data':
          setKey(auth.role === 'ADMIN' ? '/Configuration/app-settings' : '/Stores');
      }
    },
    [auth.role],
  );

  useEffect(() => {
    getKey(window.location.pathname);
  }, []);

  history.listen(a => {
    getKey(a.pathname);
  });

  const profileItems = useMemo(
    () => [
      {
        key: '1',
        label: (
          <span className="fw-500 w-100-P h-100-P" style={{ cursor: 'default', color: '#000', display: 'flex' }}>
            Account
          </span>
        ),
      },
      {
        type: 'divider',
      },
      {
        key: '2',
        label: <Link to="/Help/training-videos">Training videos</Link>,
      },
      {
        type: 'divider',
      },
      {
        key: '3',
        label: <Link to="/Help/contact-support">Contact support</Link>,
      },
      {
        type: 'divider',
      },
      {
        key: '4',
        label: <Link to="/Help/feature-suggestion">Feature suggestion</Link>,
      },
      {
        type: 'divider',
      },
      {
        key: '5',
        label: <Link to="/EditProfile">Edit Profile</Link>,
      },
      {
        type: 'divider',
      },
      {
        key: '6',
        label: <div onClick={logout}>Signout</div>,
      },
    ],
    [logout],
  );

  const menu = useMemo(
    () => (
      <Menu
        mode={responsiveMenuItem ? 'inline' : 'horizontal'}
        className="h-100-P w-100-P transparent items-center border-none"
        items={menuItems}
        onClick={onClickMenu}
        selectedKeys={[key]}
      />
    ),
    [key, menuItems, onClickMenu, responsiveMenuItem],
  );

  return (
    <div className="topNavV2">
      <div className="w-100-P h-100-P flex-row-nospacebetween-nowrap gap-8">
        {responsiveMenuItem && (
          <Button onClick={toggleCollapsed}>{!collapsed ? <MenuOutlined /> : <CloseOutlined />}</Button>
        )}
        {responsiveMenuItem ? (
          <Drawer open={collapsed} onClose={() => setCollapsed(false)} placement="left">
            {menu}
          </Drawer>
        ) : (
          menu
        )}
      </div>
      <div className="h-100-P flex-row-nospacebetween-nowrap gap-8">
        <Dropdown menu={{ items: profileItems }} placement="bottomRight" trigger={['click']}>
          <Avatar src={auth.image} icon={<UserOutlined />} style={{ width: 32, height: 32, cursor: 'pointer' }} />
        </Dropdown>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    hasNewMessages: state.navigation.newMessages?.length > 0,
  };
};
const mapDispatchToProps = dispatch => ({
  logout() {
    dispatch({
      type: 'LOGOUT',
      payload: true,
    });
    wipeReduxState();
    localStorage.setItem('account', '');
    localStorage.setItem('jwtToken', '');
    localStorage.setItem('newNav', '');
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TopNavigationV2);
