import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './CBRoute';
// import Home from '../../components/Routes/Home';
import Home from '../../components/Routes/HomeDashboardMetrics';

// import Help from "../components/Routes/Help";
import WebChatSetup from '../../components/Routes/Help/WebChatSetup';
import ContactSupport from '../../components/Routes/Help/ContactSupport';
import FeatureSuggestion from '../../components/Routes/Help/FeatureSuggestion';
import TrainingVideos from '../../components/Routes/Help/TrainingVideos';
import ExportData from '../../components/Routes/Help/ExportData';
import Login from '../../components/Routes/Login/MainLogin';
import ConsoleLogin from '../../components/Routes/Login/ConsoleLogin';
import ResetPassword from '../../components/Routes/Login/ResetPassword';
import ServiceLogin from '../../components/Routes/Login/ServiceLogin';

// Today
import Today from '../../components/Routes/Today';
import TodayAllReminders from '../../components/Routes/Today/Reminders';
import TodayAllAllSalesOpps from '../../components/Routes/Today/SalesOpp';
import TodayAllPayments from '../../components/Routes/Today/Payments';

// bank account
import BankActivity from '../../components/Routes/BankAccount/Activity';
import AddBankAccount from '../../components/Routes/BankAccount/Add';
import AddMultiBankAccount from '../../components/Routes/BankAccount/AddMulti';
import ListBankAccounts from '../../components/Routes/BankAccount/List';
import BankTransactionReport from '../../components/Routes/Reports/BankTransaction';
import BankAccountDetails from '../../components/Routes/BankAccount/Details';
import PaymentSettings from '../../components/Routes/Configuration/PaymentSettings';

// Payment Activity
import PaymentActivity from '../../components/Routes/Payments/Activity';
import PaymentDetails from '../../components/Routes/Payments/Details';

// products
import Products from '../../components/Routes/Products';

// stores
import Stores from '../../components/Routes/Stores';
import EditStore from '../../components/Routes/Stores/EditStore';
import StoreDashboard from '../../components/Routes/Stores/StoreDashboard';

// associates
import Associates from '../../components/Routes/Associates';
import AddAssociate from '../../components/Routes/Associates/AddAssociate';
import AssociateDetails from '../../components/Routes/Associates/AssociateDetails';

// clients
import Clients from '../../components/Routes/ClientsV2';

// Edit Profile
import EditProfile from '../../components/Routes/EditProfile';
import ChangePassword from '../../components/Routes/EditProfile/ChangePassword';

// Messaging
import Messaging from '../../components/Routes/Messaging';
import MassMessaging from '../../components/Routes/Messaging/MassMessaging';
import MassMessageDetails from '../../components/Routes/Messaging/MassMessaging/MassMessageDetails';
import NewMassMessage from '../../components/Routes/Messaging/MassMessaging/NewMassMessage';
import WhatsApp from '../../components/Routes/Messaging/WhatsApp';
import AllTemplates from '../../components/Routes/Messaging/AllTemplates';
import ChatInbox from '../../components/Routes/Messaging/ChatInbox';

// Reports

import AssociateMessages from '../../components/Routes/Reports/AssociateMessages';
import ClientTagsbyAssoc from '../../components/Routes/Reports/ClientTagsbyAssoc';
import Leaderboard from '../../components/Routes/Reports/Leaderboard';
import ClientEngagementByActivity from '../../components/Routes/Reports/ClientEngagementByActivity';
import SalesPipelineByAssociate from '../../components/Routes/Reports/SalesPipelineByAssociate';
import CreatedOppByAssoc from '../../components/Routes/Reports/CreatedOppByAssoc';
import AppOpensByAssoc from '../../components/Routes/Reports/AppOpensByAssoc';
import AssociateActivity from '../../components/Routes/Reports/AssociateActivity';
import SalesOpportunity from '../../components/Routes/Reports/SalesOpportunity';
import GoogleReviewRequests from '../../components/Routes/Reports/GoogleReviewRequests';
import IncompleteReminders from '../../components/Routes/Reports/IncompleteReminders';
import OutboundVsInbound from '../../components/Routes/Reports/OutboundVsInbound';
import ClientContacts from '../../components/Routes/Reports/ClientContacts';
import AutoMessages from '../../components/Routes/Reports/AutoMessages';
import Conversations from '../../components/Routes/Reports/Conversations';
import AttributedSales from '../../components/Routes/Reports/AttributedSales';
import Timeliness from '../../components/Routes/Reports/Timeliness';
import RecentNotes from '../../components/Routes/Reports/RecentNotes';
import AmountProcessed from '../../components/Routes/Reports/AmountProcessed';
import AmountPaid from '../../components/Routes/Reports/AmountPaid';
import TimeToPay from '../../components/Routes/Reports/TimeToPay';
import GoogleReviews from '../../components/Routes/Reports/GoogleReviews';
import Automations from '../../components/Routes/Reports/Automations';
import SalesOpp from '../../components/Routes/Reports/SalesOpp';
import Payments from '../../components/Routes/Reports/Payments';
import NewClients from '../../components/Routes/Reports/NewClients';
import MassMessage from '../../components/Routes/Reports/MassMessage';
import Sales from '../../components/Routes/Reports/Sales';
import AssociatePerformance from '../../components/Routes/Reports/AssociatePerformance';
import LandingPage from '../../components/Routes/Reports/LandingPage';

// Configuration
import Configuration from '../../components/Routes/Configuration';
import Tags from '../../components/Routes/Configuration/Tags';
import AddEditTag from '../../components/Routes/Configuration/Tags/AddEditTag';
import TagAutoMessage from '../../components/Routes/Configuration/Tags/AddEditTag/TagAutoMessage';
import TagAutoReminder from '../../components/Routes/Configuration/Tags/AddEditTag/TagAutoReminder';
import TagAutoWhatsapp from '../../components/Routes/Configuration/Tags/AddEditTag/TagAutoWhatsapp';
import TagMsgTemplate from '../../components/Routes/Configuration/Tags/AddEditTag/TagAutoReminder/TagMsgTemplate';
//
import ClientPreferences from '../../components/Routes/Configuration/ClientPreferences';
import AddEditPreference from '../../components/Routes/Configuration/ClientPreferences/AddEditPreferences';
//
import RelationshipTypes from '../../components/Routes/Configuration/RelationshipTypes';
import AddEditRelationship from '../../components/Routes/Configuration/RelationshipTypes/AddEditRelationship';
//
import LifeEvents from '../../components/Routes/Configuration/LifeEvents';
import AddEditLifeEvent from '../../components/Routes/Configuration/LifeEvents/AddEditLifeEvent';
import LifeEventReminder from '../../components/Routes/Configuration/LifeEvents/AddEditLifeEvent/LifeEventReminder';
import LifeEventMsgTemplate from '../../components/Routes/Configuration/LifeEvents/AddEditLifeEvent/LifeEventReminder/LifeEventMsgTemplate';
import LifeEventAutoMessage from '../../components/Routes/Configuration/LifeEvents/AddEditLifeEvent/LifeEventAutoMessage';
//
import SalesPipelines from '../../components/Routes/Configuration/SalesPipelines';
import AddEditSalesPipeline from '../../components/Routes/Configuration/SalesPipelines/AddEditSalesPipeline';
import AddEditPipelineStage from '../../components/Routes/Configuration/SalesPipelines/AddEditSalesPipeline/AddEditPipelineStage';
import SalesAutoReminder from '../../components/Routes/Configuration/SalesPipelines/AddEditSalesPipeline/AddEditPipelineStage/SalesAutoReminder';
import SalesAutoMessage from '../../components/Routes/Configuration/SalesPipelines/AddEditSalesPipeline/AddEditPipelineStage/SalesAutoMessage';
import SalesMsgTemplate from '../../components/Routes/Configuration/SalesPipelines/AddEditSalesPipeline/AddEditPipelineStage/SalesAutoReminder/SalesMsgTemplate';
import ViewPipeline from '../../components/Routes/Configuration/SalesPipelines/ViewPipeline';
//
import SalesTriggers from '../../components/Routes/Configuration/SalesTriggers';
import EditWATriggerMsg from '../../components/Routes/Configuration/SalesTriggers/EditWATriggerMsg';
import EditActions from '../../components/Routes/Configuration/SalesTriggers/EditActionAutoReminder';
import AutoReminderMsgTemplate from '../../components/Routes/Configuration/SalesTriggers/EditActionAutoReminder/autoReminderMsgTemplate';
import AutoReminderWATemplate from '../../components/Routes/Configuration/SalesTriggers/EditActionAutoReminder/autoReminderWATemplate';
import EditActionMessage from '../../components/Routes/Configuration/SalesTriggers/EditActionAutoMessage';
import AddEditTrigger from '../../componentsV2/Routes/Sales/SalesTriggers/AddEditSalesTrigger';

//
import ClientActivity from '../../components/Routes/Configuration/CustomActions';
import AddEditClientActivity from '../../components/Routes/Configuration/CustomActions/AddEditCustomActions';
import CustomAutoReminder from '../../components/Routes/Configuration/CustomActions/AddEditCustomActions/CustomAutoReminder';
import CustomAutoMessage from '../../components/Routes/Configuration/CustomActions/AddEditCustomActions/CustomAutoMessage';
import CustomMsgTemplate from '../../components/Routes/Configuration/CustomActions/AddEditCustomActions/CustomAutoReminder/CustomMsgTemplate';

import MessageSettings from '../../components/Routes/Configuration/MessageSettings';

import KioskSettings from '../../components/Routes/Configuration/KioskSettings';

import AppSettings from '../../components/Routes/Configuration/AppSettings';

import UploadData from '../../components/Routes/Configuration/UploadData';
import LifeEventAutoWhatsapp from '../../components/Routes/Configuration/LifeEvents/AddEditLifeEvent/LifeEventAutoWhatsapp';
import CustomAutoWhatsapp from '../../components/Routes/Configuration/CustomActions/AddEditCustomActions/CustomAutoWhatsapp';
import SalesAutoWhatsapp from '../../components/Routes/Configuration/SalesPipelines/AddEditSalesPipeline/AddEditPipelineStage/SalesAutoWhatsapp';
import CustomAutoWhatsappTemplate from '../../components/Routes/Configuration/CustomActions/AddEditCustomActions/CustomAutoReminder/CustomAutoWhatsappTemplate';
import LifeEventAutoWhatsappTemplate from '../../components/Routes/Configuration/LifeEvents/AddEditLifeEvent/LifeEventReminder/LifeEventAutoWhatsappTemplate';
import SalesAutoWhatsappTemplate from '../../components/Routes/Configuration/SalesPipelines/AddEditSalesPipeline/AddEditPipelineStage/SalesAutoReminder/SalesAutoWhatsappTemplate';
import TagAutoWhatsappTemplate from '../../components/Routes/Configuration/Tags/AddEditTag/TagAutoReminder/TagAutoWhatsappTemplate';
import SalesTransHistory from '../../components/Routes/Configuration/SalesTriggers/SalesTransHistory';
import AutoMessageRuntime from '../../components/Routes/Messaging/AutoMessages/AutoMessageRuntime';
import AutoMessageHistory from '../../components/Routes/Messaging/AutoMessages/AutoMessageHistory';
import AllAutoMessages from '../../components/Routes/Messaging/AutoMessages/AllAutoMessages';

import Integrations from '../../components/Routes/Help/Integrations';
import IntegrationDetails from '../../components/Routes/Help/Integrations/components/Details';
import IntegrationSettings from '../../components/Routes/Help/Integrations/components/Settings';
import IntegrationsGoogleSettings from '../../components/Routes/Help/Integrations/components/Settings/Google';
import IntegrationsInstagramSettings from '../../components/Routes/Help/Integrations/components/Settings/Instagram';
import IntegrationsFacebookSettings from '../../components/Routes/Help/Integrations/components/Settings/Facebook';
import AddEditProducts from '../../components/Routes/Products/AddEditProduct';
import ProductPage from '../../components/Routes/Products/ProductPage';
import ClientRedirect from '../../components/Routes/ClientsV2/ClientRedirect';
import GoogleReviewStore from '../../components/Routes/Reports/GoogleReviewStore';

export default () => (
  <Switch>
    <Route path="/Home" exact component={Home} displayName="Home" />
    <Route path="/Login" exact component={Login} displayName="Login" />
    <Route path="/Login/reset-password" component={ResetPassword} displayName="ResetPassword" />
    <Route path="/Login/console-login" component={ConsoleLogin} displayName="ConsoleLogin" />
    <Route path="/service-login/:serviceType/:serviceId" component={ServiceLogin} />
    {/* Associates */}
    <Route path="/Associates" exact component={Associates} displayName="Associates" />
    <Route path="/Associates/add-associate" exact component={AddAssociate} displayName="AddAssociate" />
    <Route path="/Associates/edit-associate/:associateId" exact component={AddAssociate} displayName="AddAssociate" />
    <Route
      path="/Associates/associate-details/:associateId"
      component={AssociateDetails}
      displayName="AssociateDetails"
    />
    {/* Bank Account */}
    <Route path="/BankAccount/activity" exact component={BankActivity} displayName="BankActivity" />
    <Route path="/BankAccount/add" exact component={AddBankAccount} displayName="AddBankAccount" />
    <Route path="/BankAccount/add-multi" exact component={AddMultiBankAccount} displayName="AddMultiBankAccount" />
    <Route path="/BankAccount/list" exact component={ListBankAccounts} displayName="ListBankAccounts" />
    <Route path="/BankAccount/details/:id" exact component={BankAccountDetails} displayName="BankAccountDetails" />
    <Route path="/Configuration/payment-settings" exact component={PaymentSettings} displayName="PaymentSettings" />
    {/* Payment Activity */}
    <Route path="/Payments/activity" exact component={PaymentActivity} displayName="PaymentActivity" />
    <Route path="/Payments/details/:id" exact component={PaymentDetails} displayName="PaymentDetails" />
    {/* Clients */}
    <Route
      path="/Clients/client-details/:clientId/opp-details/:oppId"
      exact
      component={ClientRedirect}
      displayName="OpportunityDetails"
    />
    <Route
      path="/Clients/client-details/:clientId/reminders"
      exact
      component={ClientRedirect}
      displayName="ViewAllReminders"
    />
    <Route
      path="/Clients/client-details/:clientId/salesopps"
      exact
      component={ClientRedirect}
      displayName="ViewAllSalesOpps"
    />
    <Route
      path="/Clients/client-details/:clientId/activities"
      exact
      component={ClientRedirect}
      displayName="ViewAllActivities"
    />
    <Route
      path="/Clients/client-details/:clientId/client-purchases"
      exact
      component={ClientRedirect}
      displayName="ViewAllClientPurchases"
    />
    <Route
      path="/Clients/client-details/:clientId/collection-details/:collectionId"
      exact
      component={ClientRedirect}
      displayName="ViewAllCollections"
    />
    <Route path="/Clients/client-details/:clientId" component={ClientRedirect} displayName="ClientDetails" />
    <Route path="/Clients/edit-client-tags" component={ClientRedirect} displayName="EditClientTags" />
    <Route path="/Clients/edit-client-associates" component={ClientRedirect} displayName="EditClientAssociates" />
    <Route path="/Clients/add-client" exact component={ClientRedirect} displayName="AddEditClient" />
    <Route path="/Clients/edit-client-info/:clientId" exact component={ClientRedirect} displayName="AddEditClient" />
    <Route path="/Clients" exact component={Clients} displayName="Clients" />

    {/* Edit Profile */}
    <Route path="/EditProfile" exact component={EditProfile} displayName="EditProfile" />
    <Route path="/EditProfile/change-password" exact component={ChangePassword} displayName="ChangePassword" />
    {/* Help */}
    <Route path="/Help/integrations" exact component={Integrations} displayName="Integrations" />
    <Route path="/Help/integrations/:slug" exact component={IntegrationDetails} displayName="Integration Details" />
    <Route
      path="/Help/integrations/facebook/settings"
      exact
      component={IntegrationsFacebookSettings}
      displayName="Integration Settings"
    />
    <Route
      path="/Help/integrations/google/settings"
      exact
      component={IntegrationsGoogleSettings}
      displayName="Integration Settings"
    />
    <Route
      path="/Help/integrations/instagram/settings"
      exact
      component={IntegrationsInstagramSettings}
      displayName="Integration Settings"
    />
    <Route
      path="/Help/integrations/:slug/settings"
      exact
      component={IntegrationSettings}
      displayName="Integration Settings"
    />
    <Route path="/Help/web-chat-setup" exact component={WebChatSetup} displayName="WebChatSetup" />
    <Route path="/Help/training-videos" exact component={TrainingVideos} displayName="TrainingVideos" />
    <Route path="/Help/contact-support" exact component={ContactSupport} displayName="ContactSupport" />
    <Route path="/Help/feature-suggestion" exact component={FeatureSuggestion} displayName="FeatureSuggestion" />
    <Route path="/Help/export-data" exact component={ExportData} displayName="ExportData" />
    {/* Reports */}

    <Route path="/Reports/associate-messages" exact component={AssociateMessages} displayName="AssociateMessages" />
    <Route path="/Reports/by-associate" exact component={ClientTagsbyAssoc} displayName="ClientTagsbyAssoc" />
    <Route path="/Reports/client-engagement" exact component={Leaderboard} displayName="Leaderboard" />
    <Route
      path="/Reports/engagement-by-activity"
      exact
      component={ClientEngagementByActivity}
      displayName="ClientEngagementByActivity"
    />
    <Route
      path="/Reports/sales-pipelines-by-associate"
      exact
      component={SalesPipelineByAssociate}
      displayName="SalesPipelineByAssociate"
    />
    <Route
      path="/Reports/created-opportunity-by-associate"
      exact
      component={CreatedOppByAssoc}
      displayName="CreatedOppByAssoc"
    />
    <Route path="/Reports/app-opens-by-associate" exact component={AppOpensByAssoc} displayName="AppOpensByAssoc" />
    <Route path="/Reports/associate-activity" exact component={AssociateActivity} displayName="AssociateActivity" />
    <Route path="/Reports/sales-opportunity" exact component={SalesOpportunity} displayName="SalesOpportunity" />
    <Route
      path="/Reports/google-review-requests"
      exact
      component={GoogleReviewRequests}
      displayName="GoogleReviewRequests"
    />
    <Route path="/Reports/google-review-store" exact component={GoogleReviewStore} />
    <Route path="/Reports/active-reminders" exact component={IncompleteReminders} displayName="IncompleteReminders" />
    <Route path="/Reports/outbound-vs-inbound" exact component={OutboundVsInbound} displayName="OutboundVsInbound" />
    <Route path="/Reports/client-contacts" exact component={ClientContacts} displayName="ClientContacts" />
    <Route path="/Reports/auto-messages" exact component={AutoMessages} displayName="AutoMessages" />
    <Route path="/Reports/conversations" exact component={Conversations} displayName="Conversations" />
    <Route path="/Reports/attributed-sales" exact component={AttributedSales} displayName="AttributedSales" />
    <Route path="/Reports/timeliness" exact component={Timeliness} displayName="Timeliness" />
    <Route path="/Reports/recent-notes" exact component={RecentNotes} displayName="RecentNotes" />
    <Route
      path="/Reports/bank-transactions"
      exact
      component={BankTransactionReport}
      displayName="BankTransactionReport"
    />
    <Route path="/Reports/amount-requested" exact component={AmountProcessed} displayName="AmountRequested" />
    <Route path="/Reports/amount-paid" exact component={AmountPaid} displayName="AmountPaid" />
    <Route path="/Reports/time-to-pay" exact component={TimeToPay} displayName="TimeToPay" />


    {/* New Reports */}
    <Route path="/Reports" exact component={LandingPage} displayName="Reports" />
    <Route path="/Reports/payment-requests" exact component={Payments} displayName="PaymentRequests" />
    <Route path="/Reports/google-review" exact component={GoogleReviews} displayName="GoogleReviews" />
    <Route path="/Reports/automations" exact component={Automations} displayName="Automations" />
    <Route path="/Reports/sales-opp" exact component={SalesOpp} displayName="SalesOpp" />
    <Route path="/Reports/new-clients" exact component={NewClients} displayName="NewClients" />
    <Route path="/Reports/mass-message" exact component={MassMessage} displayName="MassMessage" />
    <Route path="/Reports/sales" exact component={Sales} displayName="Sales" />
    <Route path="/Reports/associate-performance" exact component={AssociatePerformance} displayName="AssociatePerformance" />

    {/* Products */}
    <Route path="/Products" exact component={Products} displayName="Products" />
    <Route path="/Products/add-product" exact component={AddEditProducts} displayName="Products" />
    <Route path="/Products/edit-product/:productId" exact component={AddEditProducts} displayName="Products" />
    <Route path="/Products/:productId" exact component={ProductPage} displayName="Product Details" />
    {/* Stores */}
    <Route path="/Stores" exact component={Stores} displayName="Stores" />
    <Route path="/Stores/edit-store/:storeId" exact component={EditStore} displayName="EditStore" />
    <Route path="/Stores/store-dashboard/:storeId" exact component={StoreDashboard} displayName="StoreDashboard" />
    {/* Today */}
    <Route path="/" exact component={Today} />
    <Route path="/all-reminders" exact component={TodayAllReminders} displayName="TodayAllReminders" />
    <Route path="/all-sales-opp" exact component={TodayAllAllSalesOpps} displayName="TodayAllAllSalesOpps" />
    <Route path="/all-payments" exact component={TodayAllPayments} displayName="TodayAllPayments" />
    {/* Messaging */}
    <Route path="/Messaging" exact component={Messaging} displayName="Messaging" />
    <Route path="/Messaging/inbox" exact component={ChatInbox} displayName="Inbox" />{' '}
    {/* the new chat inbox component  */}
    <Route path="/Messaging/mass-messaging" exact component={MassMessaging} displayName="MassMessaging" />
    <Route
      path="/Messaging/mass-messaging/message-details/:messageId"
      exact
      component={MassMessageDetails}
      displayName="MassMessageDetails"
    />
    <Route path="/Messaging/mass-messaging/new-message" exact component={NewMassMessage} displayName="NewMassMessage" />
    <Route path="/Messaging/WhatsApp" exact component={WhatsApp} displayName="WhatsApp" />
    <Route path="/Messaging/all-templates" exact component={AllTemplates} displayName="AllTemplates" />
    <Route path="/Messaging/auto-messages" exact component={AllAutoMessages} displayName="AllAutoMessages" />
    <Route path="/Messaging/auto-msg-runtime" exact component={AutoMessageRuntime} displayName="AutoMessageRuntime" />
    <Route path="/Messaging/auto-msg-history" exact component={AutoMessageHistory} displayName="AutoMessageHistory" />
    {/* Configuration */}
    <Route path="/Configuration" exact component={Configuration} displayName="Configuration" />
    <Route path="/Configuration/tags" exact component={Tags} displayName="Tags" />
    <Route path="/Configuration/tags/add-tag" exact component={AddEditTag} displayName="AddEditTag" />
    <Route path="/Configuration/tags/edit-tag/:listItemId" exact component={AddEditTag} displayName="AddEditTag" />
    <Route
      path="/Configuration/tags/edit-tag/:listItemId/add-auto-reminder"
      exact
      component={TagAutoReminder}
      displayName="TagAutoReminder"
    />
    <Route
      path="/Configuration/tags/edit-tag/:listItemId/edit-auto-reminder/:reminderId"
      exact
      component={TagAutoReminder}
      displayName="TagAutoReminder"
    />
    <Route
      path="/Configuration/tags/edit-tag/:listItemId/edit-auto-reminder/:reminderId/add-message-template"
      exact
      component={TagMsgTemplate}
      displayName="TagMsgTemplate"
    />
    <Route
      path="/Configuration/tags/edit-tag/:listItemId/edit-auto-reminder/:reminderId/edit-message-template/:templateId"
      exact
      component={TagMsgTemplate}
      displayName="TagMsgTemplate"
    />
    <Route
      path="/Configuration/tags/edit-tag/:listItemId/edit-auto-reminder/:reminderId/edit-whatsapp-template/:templateId"
      exact
      component={TagAutoWhatsappTemplate}
      displayName="TagAutoWhatsappTemplate"
    />
    <Route
      path="/Configuration/tags/edit-tag/:listItemId/add-auto-message"
      exact
      component={TagAutoMessage}
      displayName="TagAutoMessage"
    />
    <Route
      path="/Configuration/tags/edit-tag/:listItemId/edit-auto-message/:messageId"
      exact
      component={TagAutoMessage}
      displayName="TagAutoMessage"
    />
    <Route
      path="/Configuration/tags/edit-tag/:listItemId/add-auto-whatsapp"
      exact
      component={TagAutoWhatsapp}
      displayName="TagAutoWhatsapp"
    />
    <Route
      path="/Configuration/tags/edit-tag/:listItemId/edit-auto-whatsapp/:whatsappId"
      exact
      component={TagAutoWhatsapp}
      displayName="TagAutoWhatsapp"
    />
    <Route path="/Configuration/client-activity" exact component={ClientActivity} displayName="ClientActivity" />
    <Route
      path="/Configuration/client-activity/add-client-activity"
      exact
      component={AddEditClientActivity}
      displayName="AddEditClientActivity"
    />
    <Route
      path="/Configuration/client-activity/edit-client-activity/:listItemId"
      exact
      component={AddEditClientActivity}
      displayName="AddEditClientActivity"
    />
    <Route
      path="/Configuration/client-activity/edit-client-activity/:listItemId/add-auto-reminder"
      exact
      component={CustomAutoReminder}
      displayName="CustomAutoReminder"
    />
    <Route
      path="/Configuration/client-activity/edit-client-activity/:listItemId/edit-auto-reminder/:reminderId"
      exact
      component={CustomAutoReminder}
      displayName="CustomAutoReminder"
    />
    <Route
      path="/Configuration/client-activity/edit-client-activity/:listItemId/edit-auto-reminder/:reminderId/add-message-template"
      exact
      component={CustomMsgTemplate}
      displayName="CustomMsgTemplate"
    />
    <Route
      path="/Configuration/client-activity/edit-client-activity/:listItemId/edit-auto-reminder/:reminderId/edit-message-template/:templateId"
      exact
      component={CustomMsgTemplate}
      displayName="CustomMsgTemplate"
    />
    <Route
      path="/Configuration/client-activity/edit-client-activity/:listItemId/edit-auto-reminder/:reminderId/edit-whatsapp-template/:templateId"
      exact
      component={CustomAutoWhatsappTemplate}
      displayName="CustomAutoWhatsappTemplate"
    />
    <Route
      path="/Configuration/client-activity/edit-client-activity/:listItemId/add-auto-message"
      exact
      component={CustomAutoMessage}
      displayName="CustomAutoMessage"
    />
    <Route
      path="/Configuration/client-activity/edit-client-activity/:listItemId/edit-auto-message/:messageId"
      exact
      component={CustomAutoMessage}
      displayName="CustomAutoMessage"
    />
    <Route
      path="/Configuration/client-activity/edit-client-activity/:listItemId/add-auto-whatsapp"
      exact
      component={CustomAutoWhatsapp}
      displayName="CustomAutoWhatsapp"
    />
    <Route
      path="/Configuration/client-activity/edit-client-activity/:listItemId/edit-auto-whatsapp/:whatsappId"
      exact
      component={CustomAutoWhatsapp}
      displayName="CustomAutoWhatsapp"
    />
    <Route path="/Configuration/message-settings" exact component={MessageSettings} displayName="MessageSettings" />
    <Route path="/Configuration/kiosk-settings" exact component={KioskSettings} displayName="KioskSettings" />
    <Route path="/Configuration/app-settings" exact component={AppSettings} displayName="AppSettings" />
    <Route path="/Configuration/upload-data" exact component={UploadData} displayName="UploadData" />
    {/*  */}
    <Route
      path="/Configuration/client-preferences"
      exact
      component={ClientPreferences}
      displayName="ClientPreferences"
    />
    <Route
      path="/Configuration/client-preferences/add-preference"
      exact
      component={AddEditPreference}
      displayName="AddEditPreference"
    />
    <Route
      path="/Configuration/client-preferences/edit-preference/:preferenceId"
      exact
      component={AddEditPreference}
      displayName="AddEditPreference"
    />
    {/*  */}
    <Route
      path="/Configuration/relationship-types"
      exact
      component={RelationshipTypes}
      displayName="RelationshipTypes"
    />
    <Route
      path="/Configuration/relationship-types/add-relationship"
      exact
      component={AddEditRelationship}
      displayName="AddEditRelationship"
    />
    <Route
      path="/Configuration/relationship-types/edit-relationship/:relationshipUUId"
      exact
      component={AddEditRelationship}
      displayName="AddEditRelationship"
    />
    {/*  */}
    <Route path="/Configuration/life-events" exact component={LifeEvents} displayName="LifeEvents" />
    <Route
      path="/Configuration/life-events/add-life-events"
      exact
      component={AddEditLifeEvent}
      displayName="AddEditLifeEvent"
    />
    <Route
      path="/Configuration/life-events/edit-life-events/:listItemId"
      exact
      component={AddEditLifeEvent}
      displayName="AddEditLifeEvent"
    />
    <Route
      path="/Configuration/life-events/edit-life-events/:listItemId/add-auto-reminder"
      exact
      component={LifeEventReminder}
      displayName="LifeEventReminder"
    />
    <Route
      path="/Configuration/life-events/edit-life-events/:listItemId/edit-auto-reminder/:reminderId"
      exact
      component={LifeEventReminder}
      displayName="LifeEventReminder"
    />
    <Route
      path="/Configuration/life-events/edit-life-events/:listItemId/add-auto-whatsapp"
      exact
      component={LifeEventAutoWhatsapp}
      displayName="LifeEventAutoWhatsapp"
    />
    <Route
      path="/Configuration/life-events/edit-life-events/:listItemId/edit-auto-whatsapp/:whatsappId"
      exact
      component={LifeEventAutoWhatsapp}
      displayName="LifeEventAutoWhatsapp"
    />
    <Route
      path="/Configuration/life-events/edit-life-events/:listItemId/edit-auto-reminder/:reminderId/add-message-template"
      exact
      component={LifeEventMsgTemplate}
      displayName="LifeEventMsgTemplate"
    />
    <Route
      path="/Configuration/life-events/edit-life-events/:listItemId/edit-auto-reminder/:reminderId/edit-message-template/:templateId"
      exact
      component={LifeEventMsgTemplate}
      displayName="LifeEventMsgTemplate"
    />
    <Route
      path="/Configuration/life-events/edit-life-events/:listItemId/edit-auto-reminder/:reminderId/edit-whatsapp-template/:templateId"
      exact
      component={LifeEventAutoWhatsappTemplate}
      displayName="LifeEventAutoWhatsappTemplate"
    />
    <Route
      path="/Configuration/life-events/edit-life-events/:listItemId/add-auto-message"
      exact
      component={LifeEventAutoMessage}
      displayName="LifeEventAutoMessage"
    />
    <Route
      path="/Configuration/life-events/edit-life-events/:listItemId/edit-auto-message/:messageId"
      exact
      component={LifeEventAutoMessage}
      displayName="LifeEventAutoMessage"
    />
    {/*  */}
    <Route path="/Configuration/sales-pipelines" exact component={SalesPipelines} displayName="SalesPipelines" />
    <Route
      path="/Configuration/sales-pipelines/view-pipeline/:pipelineId"
      exact
      component={ViewPipeline}
      displayName="ViewPipeline"
    />
    <Route
      path="/Configuration/sales-pipelines/edit-sales-pipeline/:pipelineId"
      exact
      component={AddEditSalesPipeline}
      displayName="AddEditSalesPipeline"
    />
    <Route
      path="/Configuration/sales-pipelines/add-sales-pipeline"
      exact
      component={AddEditSalesPipeline}
      displayName="AddEditSalesPipeline"
    />
    <Route
      path="/Configuration/sales-pipelines/edit-sales-pipeline/:pipelineId/add-pipeline-stage"
      exact
      component={AddEditPipelineStage}
      displayName="AddEditPipelineStage"
    />
    <Route
      path="/Configuration/sales-pipelines/edit-sales-pipeline/:pipelineId/edit-pipeline-stage/:listItemId"
      exact
      component={AddEditPipelineStage}
      displayName="AddEditPipelineStage"
    />
    <Route
      path="/Configuration/sales-pipelines/edit-sales-pipeline/:pipelineId/edit-pipeline-stage/:listItemId/add-auto-reminder"
      exact
      component={SalesAutoReminder}
      displayName="SalesAutoReminder"
    />
    <Route
      path="/Configuration/sales-pipelines/edit-sales-pipeline/:pipelineId/edit-pipeline-stage/:listItemId/edit-auto-reminder/:reminderId"
      exact
      component={SalesAutoReminder}
      displayName="SalesAutoReminder"
    />
    <Route
      path="/Configuration/sales-pipelines/edit-sales-pipeline/:pipelineId/edit-pipeline-stage/:listItemId/edit-auto-reminder/:reminderId/edit-message-template/:templateId"
      exact
      component={SalesMsgTemplate}
      displayName="SalesMsgTemplate"
    />
    <Route
      path="/Configuration/sales-pipelines/edit-sales-pipeline/:pipelineId/edit-pipeline-stage/:listItemId/edit-auto-reminder/:reminderId/edit-whatsapp-template/:templateId"
      exact
      component={SalesAutoWhatsappTemplate}
      displayName="SalesAutoWhatsappTemplate"
    />
    <Route
      path="/Configuration/sales-pipelines/edit-sales-pipeline/:pipelineId/edit-pipeline-stage/:listItemId/edit-auto-reminder/:reminderId/add-message-template"
      exact
      component={SalesMsgTemplate}
      displayName="SalesMsgTemplate"
    />
    <Route
      path="/Configuration/sales-pipelines/edit-sales-pipeline/:pipelineId/edit-pipeline-stage/:listItemId/add-auto-message"
      exact
      component={SalesAutoMessage}
      displayName="SalesAutoMessage"
    />
    <Route
      path="/Configuration/sales-pipelines/edit-sales-pipeline/:pipelineId/edit-pipeline-stage/:listItemId/edit-auto-message/:messageId"
      exact
      component={SalesAutoMessage}
      displayName="SalesAutoMessage"
    />
    <Route
      path="/Configuration/sales-pipelines/edit-sales-pipeline/:pipelineId/edit-pipeline-stage/:listItemId/add-auto-whatsapp"
      exact
      component={SalesAutoWhatsapp}
      displayName="SalesAutoWhatsapp"
    />
    <Route
      path="/Configuration/sales-pipelines/edit-sales-pipeline/:pipelineId/edit-pipeline-stage/:listItemId/edit-auto-whatsapp/:whatsappId"
      exact
      component={SalesAutoWhatsapp}
      displayName="SalesAutoWhatsapp"
    />
    {/*  */}
    <Route path="/Configuration/sales-triggers" exact component={SalesTriggers} displayName="SalesTriggers" />
    <Route
      path="/Configuration/sales-triggers/sales-history"
      exact
      component={SalesTransHistory}
      displayName="SalesTransHistory"
    />
    <Route
      path="/Configuration/sales-triggers/add-trigger"
      exact
      component={AddEditTrigger}
      displayName="AddEditTrigger"
    />
    <Route
      path="/Configuration/sales-triggers/edit-trigger/:triggerId"
      exact
      component={AddEditTrigger}
      displayName="AddEditTrigger"
    />
    <Route
      path="/Configuration/sales-triggers/edit-trigger-auto-message/:actionId"
      exact
      component={EditActionMessage}
      displayName="EditActionMessage"
    />
    <Route
      path="/Configuration/sales-triggers/edit-trigger-whatsapp-auto-message/:actionId"
      exact
      component={EditWATriggerMsg}
      displayName="EditWATriggerMsg"
    />
    <Route
      path="/Configuration/sales-triggers/edit-trigger-auto-reminder/:actionId"
      exact
      component={EditActions}
      displayName="EditActions"
    />
    <Route
      path="/Configuration/sales-triggers/edit-trigger-auto-reminder/:reminderId/add-message-template"
      exact
      component={AutoReminderMsgTemplate}
    />
    <Route
      path="/Configuration/sales-triggers/edit-trigger-auto-reminder/:reminderId/edit-message-template/:templateId"
      exact
      component={AutoReminderMsgTemplate}
    />
    <Route
      path="/Configuration/sales-triggers/edit-trigger-auto-reminder/:reminderId/add-whatsapp-template"
      exact
      component={AutoReminderWATemplate}
    />
    <Route
      path="/Configuration/sales-triggers/edit-trigger-auto-reminder/:reminderId/edit-whatsapp-template/:templateId"
      exact
      component={AutoReminderWATemplate}
    />
  </Switch>
);
