export async function isUrlImageValid (url=""){
  return new Promise((resolve, reject) => {
    var image = new Image();
    image.src = url;
    image.onload = function() {
      if (this.width > 0) {
        resolve(true);
      } else {
        resolve(false);
      }
    }
    image.onerror = function() {
      resolve(false);
    }
  });
}
