import moment from 'moment';

export const filterList = ({ data = [], filters, startDate, endDate, assocStores }) => {
  const allStores = assocStores.filter(store => store.id !== 0).map(item => item.id.toString());
  return data?.filter(item => {
    const filterStore =
      filters.store.length === 0
        ? allStores.includes(item?.activity_store_id?.toString())
        : filters.store.includes(item?.activity_store_id?.toString());
    const filterAssociate =
      filters.associate.length === 0 || filters.associate.includes(item?.activity_associate_id?.toString());
    const filterTimeSpan = moment(item.sales_transaction_date || item.sales_transaction_date_time).isBetween(
      moment(startDate),
      moment(endDate),
      null,
      '[]',
    );
    return filterStore && filterAssociate && filterTimeSpan;
  });
};
