import React, { useState } from 'react';
import { connect } from 'react-redux';
import StyledLink from '../../../Layout/StyledLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SubMenu from '../MN_subMenu';
import { sub } from 'date-fns';

const MVButton = props => {
  const [subMenuToggle, setSubMenuToggle] = useState('');
  if (props.menuOptions && props.menuOptions.length > 0) {
    return (
      <div
        className="sp-button-container"
        style={props.showListMobile === props.title ? { opacity: '1' } : {}}
        // style={{ backgroundColor: 'red'}}
      >
        <div
          className="sp-button"
          onClick={() => {
            props.expandMNav(!props.collapseMNav);
            props.setShowListMobile(props.showList === props.title ? '' : props.title);
            setSubMenuToggle('');
          }}
        >
          <span className="sp-button-mobile">{props.title}</span>
        </div>
        {props.showListMobile === props.title ? (
          <SubMenu
            showListMobile={props.showListMobile}
            setShowListMobile={props.setShowListMobile}
            menuOptions={props.menuOptions}
            title={props.title}
            subMenuToggle={subMenuToggle}
            setSubMenuToggle={setSubMenuToggle}
          ></SubMenu>
        ) : null}
      </div>
    );
  } else {
    const menuRoute = props.title === 'Dashboard' ? '' : props.title;
    return (
      <div className="sp-button-container">
        <StyledLink onClick={() => props.toggleMobileMenu(false)} classprops="sp-button" to={`/${menuRoute}`}>
          <span className="sp-button-mobile">{props.title}</span>
        </StyledLink>
      </div>
    );
  }
};
const mapStateToProps = state => state.navigation;
const mapDispatchToProps = dispatch => ({
  expandMNav(val) {
    dispatch({
      type: 'COLLAPSE_MOBILE_NAV',
      payload: val,
    });
  },
  toggleMobileMenu(val) {
    dispatch({
      type: 'SHOW_MOBILE_NAV',
      payload: val,
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(MVButton);
