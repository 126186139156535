import React from 'react';
import Select from 'react-select';
import './dropdownSelect.css';

const DropdownSelect = props => {
  const isValid = typeof props.isValid !== 'undefined' ? props.isValid : true;

  const defaultStyles = {
    control: (styles, state) => {
      return {
        ...styles,
        height: '50px',
        borderRadius: '3px',
        fontSize: '13px',
        outline: 'none',
        border: isValid ? 'solid 1px #818181' : 'solid 1px red',
        paddingLeft: '7px',
        '&:hover': {
          border: isValid ? 'solid 1px #818181' : 'solid 1px red',
        },
        ...props.optionalStyles,
      };
    },
    option: (styles, state) => ({
      ...styles,
      fontSize: '12px',
    }),
    menu: (styles, state) => ({
      ...styles,
      zIndex: 5,
    }),
    menuPortal: base => ({
      ...base,
      zIndex: 9999,
    }),
    indicatorSeparator: () => null,
    multiValue: (styles, state) => {
      return {
        ...styles,
        backgroundColor: '#EAF4FE',
        border: state.data.isFixed ? '1px solid #EBEBEB' : '1px solid #33AAFC',
      };
    },
    multiValueLabel: (styles, state) => {
      return state.data.isFixed
        ? {
            ...styles,
            color: '#BDBDBD',
            fontSize: '13px',
            fontWeight: 500,
            backgroundColor: '#EBEBEB',
          }
        : {
            ...styles,
            color: '#1769AF',
            fontSize: '13px',
            fontWeight: 500,
          };
    },
    multiValueRemove: (styles, state) => {
      return state.data.isFixed
        ? { ...styles, display: 'none' }
        : {
            ...styles,
            color: '#1769AF',
            ':hover': {
              backgroundColor: '#33AAFC',
              color: '#fff',
            },
          };
    },
    // hideSelectedOptions: () => true,
    // dropdownIndicator: (provided, state) => {
    //   // return (<span> no</span>)
    //   return null
    //   },
    //   menu:  (provided, state) => ({
    //     ...provided,
    //     height: '800px',
    //     overflowY: 'visible'
    //   }),
    //   menuList:  (provided, state) => ({
    //     ...provided,
    //     height: '800px',
    //     minHeight: '800px',
    //     overflowY: 'scroll'
    //   })

    // singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = "opacity 300ms";

    //   return { ...provided, opacity, transition };
    // }
  };

  const getObj = () => {
    if(props.isGroup) {
      return props.value;
    }
    if (props.isMulti && typeof props.value[0] !== 'object') {
      return props.options.filter(e => props.value.includes(e.value));
    }
    if (Array.isArray(props.value)) {
      return props.value;
    }
    const item = props.value;
    const list = props.options;
    const filteredItem = list.filter(lI => lI.value === item);
    if (filteredItem.length > 0) {
      return {
        label: filteredItem[0].label,
        value: item,
        ...filteredItem[0],
      };
    }
    return props.isMulti ? [] : null;
  };
  // defaultStyles.theme.primary25 = "F00000";
  return (
    <Select
      {...props.selectProps}
      menuPlacement={props.selectProps?.menuPlacement || 'auto'}
      components={props.componentProps}
      options={props.options.map(opt => ({
        value: opt.value,
        label: opt.label || opt.text,
        ...opt,
      }))}
      id={props.id}
      isLoading={props?.isLoading}
      menuPortalTarget={document.body}
      className={`dropdown-select ${props.classProps}`}
      isMulti={props.isMulti || false}
      isSearchable={props.isSearchable || false}
      isClearable={props.isClearable || false}
      // controlShouldRenderValue = { false }
      minMenuHeight={props.minMenuHeight || 200}
      maxMenuHeight={props.maxMenuHeight || 200}
      value={getObj()}
      ref={props?.ref}
      styles={{ ...defaultStyles, ...props.optionalStyles }}
      placeholder={props.placeholder}
      onChange={props.onChange}
      isDisabled={props.disabled}
      theme={theme => {
        return {
          ...theme,
          colors: { ...theme.colors, primary25: '#E8F4FF', primary: ' #33AAFC' },
        };
      }}
    />
  );
};
export default DropdownSelect;
