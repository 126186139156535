import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Layout, PageHeader, Avatar, Drawer, Modal } from 'antd';
import { CloseOutlined, InfoCircleFilled, QrcodeOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { queryMaker } from '../../../../core/apiRequests';
import ConversationWindow from '../../../Layout/chat/chat';
import OtpInQr from '../../../Layout/optInQr/opt-in-qr';
import { useQueryClient } from 'react-query'
import { Button, message, Tooltip } from 'antd-v5';
import { updateClient } from '../../../../services/clients';
import { setClientAssociateId } from '../../../../reducers/client';

const Header = props => {
  const [showQr, setShowQr] = useState(false);
  const [loading, setLoading] = useState(false);

  const initials = props.client?.name
    ?.split(' ')
    .map(e => e.charAt(0))
    .slice(0, 2)
    .join('');

  const nameShorten = name => {
    if (name) {
      if (window.innerWidth < 1515 && name.length > 30) {
        return `${name.substr(0, 30)}...`;
      }
      if (window.innerWidth < 1642 && name.length > 58) {
        return `${name.substr(0, 58)}...`;
      }
      if (name.length > 80) {
        return `${name.substr(0, 80)}...`;
      }
    }
    return name || '';
  };

  const queryClient = useQueryClient()
  const associates_names = queryClient.getQueryData('associates')
  const associateName = useMemo(() => {
    if(!props.client.associate_id) {
      return 'Unassigned';
    } else {
      if(props.client.associate_id !== props.auth.userId) {
        const associate = associates_names?.find(asct => asct.value === props.client.associate_id);
        return `Assigned to ${associate?.label}`;
      }
    }
    return ''
  }, [props.client.associate_id, props.auth.userId, associates_names]);

  const assignToMe = useCallback(async () => {
    try {
      setLoading(true);
      await updateClient({
        data: {
          associateId: props.auth.userId,
        },
        clientId: props.client.client_id,
      });
      setClientAssociateId(props.auth.userId);
    } catch (error) {
      message.error('Failed to assign client to you');
    } finally {
      setLoading(false);
    }
  }, [updateClient, props.client.client_id, props.auth.userId]);

  return (
    <div style={{ marginTop: '-5px' }}>
      <Layout className="conversation-window-layout" style={{ maxHeight: '100%', background: '#f0f2f5' }}>
        <div className="conversation-window-header flex-row-spacebetween-nowrap">
          <div className="add-edit-wrap">
            <Avatar
              className="conversation-window-header-user-icon"
              id="user-icon"
              size="medium"
              key={initials}
              style={{ backgroundColor: '#BDBDBD', color: '#FFF', width: '48px', height: '48px', marginTop: '18px', lineHeight: '48px' }}
            >
              {initials}
            </Avatar>
            <PageHeader
              className="conversation-window-header-heading-sub"
              id="middle-panel-header"
              title={nameShorten(props.client?.name)}
              subTitle={`${props.client?.preferred_name || ''} ${props.client?.preferred_name ? '|' : ''} ${
                props.client?.preferred_contact_type
              } ${associateName}`}
            />
            {props.auth.webKioskMode && (
              <span
                style={{ fontSize: '28px', marginLeft: '-30px' }}
                className="pointer m-top-18"
                onClick={() => setShowQr(!showQr)}
              >
                &nbsp; {<QrcodeOutlined /> ?? ''}
              </span>
            )}
          </div>
          {/* <StoreModal auth={props.auth} hideSelector={props.type === 'full'} /> */}
          <div className="headingIcons" id="heading-icons">
            {/* eslint-disable-next-line no-nested-ternary */}
            {props.type === 'right' ? (
              <CloseOutlined
                title="Close"
                style={{ fontSize: '16px' }}
                id="more-info-icon"
                onClick={() => props.setDrawerToggle(true)}
              />
            ) : (
              <>
                {!props.client.associate_id && (
                  <Button type="link" onClick={assignToMe} loading={loading}>
                    Assign to Me
                  </Button>
                )}
                <FontAwesomeIcon
                  className="m-x-12 fs-19 gray pointer"
                  color="#818181"
                  icon={['far', 'comment-alt-lines']}
                  onClick={props.showMessageHandler}
                />
                <Tooltip title="Client Info" placement='bottom'>
                  <InfoCircleFilled className="fs-16 pointer" id="more-info-icon" />
                </Tooltip>
              </>
            )}
          </div>
        </div>
        <Drawer
          bodyStyle={{
            padding: 0,
            overflow: 'hidden',
          }}
          closable={false}
          push={false}
          destroyOnClose
          width={750}
          open={props.showPopConfirm}
          onClose={props.closeChat}
        >
          <ConversationWindow setDrawerToggle={props.closeChat} chatIconColor="#EBEBEB" type="right" />
        </Drawer>
        <Modal
          open={showQr}
          centered
          onOk={() => setShowQr(!showQr)}
          onCancel={() => setShowQr(!showQr)}
          footer={null}
          maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.95)' }}
        >
          <OtpInQr
            url={`${process.env.REACT_APP_OPT_IN_FORM_URL}${queryMaker({
              merchantUuid: props.auth.uuid,
              clientUuid: props.client.uuid,
              associateUuid: props.auth.associateUuid,
            })}`}
          />
        </Modal>
      </Layout>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  client: state.client,
});
export default connect(mapStateToProps)(Header);
