import React, { useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { EditableField } from './EditableField';
import { getRequest } from '../../../../core/apiRequests';
import { useQuery } from 'react-query';
import yn from 'yn';

const Associates = ({control,
  isSubmitting,
  saveClientInfo,
  getValues,
  merchantId,
  register,
  handleSubmit,
  merchantConfig,
  setValue,
  auth,
  resetField,
  client,
  watch,
  ...props
}) => {

  const limitAssociateVisibility =
    (yn(merchantConfig?.LIMIT_ASSOCIATE_VISIBILITY_ENABLED) && auth.role === 'ASSOCIATE');

  const { data } = useQuery('associates', async () => {
    const reqObj = {
      params: ['chats', 'getAsctList'],
    };
    const data = await getRequest(reqObj);
    const sortedAscts = data?.sort((a, b) => {
      if (`${a.first_name || ''}${a.last_name || ''}` > `${b.first_name || ''}${b.last_name}`) {
        return 1;
      }
      if (`${a.first_name || ''}${a.last_name || ''}` < `${b.first_name || ''}${b.last_name}`) {
        return -1;
      }
      return 0;
    }).map(asct => ({
      value: asct?.id,
      label: `${asct?.first_name || ''} ${asct?.last_name || ''}`,
    }));
    return [
      { value: null, label: 'Unassigned' },
      ...sortedAscts,
    ]
  }, {
    enabled: !!merchantId,
    refetchOnWindowFocus: false,
  })

  const getAssociateName = useCallback(() => {
    if(data) {
      const associate = data.find(asct => asct.value === getValues('clientObj.associate_id'));
      return associate?.label;
    } else {
      return null;
    }
  }, [data, getValues('clientObj.associate_id'), watch('clientObj.associate_id')]);

  const getCustomButton = useMemo(() => {
    if(!limitAssociateVisibility) {
      return null;
    }
    if(auth.userId === getValues('clientObj.associate_id')) {
      return 'Unassign Me';
    }
    if(!getValues('clientObj.associate_id')) {
      return 'Assign Me';
    }
  }, [limitAssociateVisibility, auth.userId, getValues('clientObj.associate_id')])

  const customButtonClosedAction = useCallback(() => {
    if(!limitAssociateVisibility) {
      return;
    }
    if(auth.userId === getValues('clientObj.associate_id')) {
      setValue('clientObj.associate_id', null, { shouldDirty: true });
      handleSubmit();
      return;
    }
    if(!getValues('clientObj.associate_id')) {
      setValue('clientObj.associate_id', auth.userId, { shouldDirty: true });
      handleSubmit();
      return;
    }
    return
  }, [limitAssociateVisibility, auth.userId, getValues('clientObj.associate_id'), setValue])

  return (
    <EditableField
      label="Assigned Associate"
      value={getAssociateName() || 'Unassigned'}
      valueClassName={!getValues('clientObj.associate_id') ? 'color-bdbdbd' : ''}
      containerClassName="p-top-8 m-btm-8"
      type="select"
      control={control}
      register={register('clientObj.associate_id')}
      reset={() => resetField('clientObj.associate_id')}
      onSave={handleSubmit}
      selectProps={{
        options: data,
      }}
      isSubmitting={isSubmitting}
      customButtonClosed={getCustomButton}
      customButtonClosedAction={customButtonClosedAction}
      editable={!(limitAssociateVisibility && (auth.userId !== getValues('clientObj.associate_id') && getValues('clientObj.associate_id') !== null))}
    />
  );
};

const mapStateToProps = state => ({
  client: state.client,
});
export default connect(mapStateToProps)(Associates);
