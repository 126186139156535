import React from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import EdisonLogo from '../../LottieAnimations/edisonLogo.json';

const CenteredEdisonInitial = ({ headerText, headerSubTxt, leadObj, setEdisonTile }) => {
  const handleEventPlayer = () => {
    if (Object.keys(leadObj).length > 0) {
      setEdisonTile('edisonAI');
    }
  };

  return (
    <div role="button" className="pop-confirm-background flex-col-center" style={{ zIndex: 40 }} tabIndex={0}>
      <div className="leadOuterCompDiv">
        <div style={{ marginTop: '30px' }}>
          <Player
            onEvent={handleEventPlayer}
            autoplay
            loop
            src={EdisonLogo}
            style={{ height: '300px', width: '300px' }}
          />
          <div style={{ width: '450px', margin: '0px 40px' }}>
            <span className="fw-600 fs-16">{headerText}</span>
            <p className="fw-500 fs-13 align-center m-top-0">{headerSubTxt}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CenteredEdisonInitial;
