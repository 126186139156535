import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getTablePaginationData, setTablePagination } from '../../../../../core/actions';
import { getRequest } from '../../../../../core/apiRequests';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';
import Layout from '../../../../Layout/StandardLayout';
import StyledLink from '../../../../Layout/StyledLink';
import Table from '../../../../Layout/Table';
import { convertMessageTemplate } from '../../../../../utils/convertMessageTemplate';

const tableName = 'AUTO_MESSAGE_HISTORY';

const AutoMessageHistory = props => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (props.auth.merchantId) {
      (async () => {
        const reqObj = {
          params: ['chatMessages', props.auth.merchantId, 'getAutoMsgHistory'],
          query: {
            count: props.pagination.pageSize,
            page: props.pagination.page,
          },
        };
        const dataReq = await getRequest(reqObj);
        if (dataReq) {
          const {
            results,
            pageInfo: { rowCount, pageCount },
          } = dataReq;
          setTableData(results);
          setTablePagination(
            {
              rowCount,
              pageCount,
            },
            tableName,
          );
        }
      })();
    }
  }, [props.auth.merchantId, props.pagination.pageSize, props.pagination.page]);

  const tableHeaders = [
    {
      keyName: 'completed_date_time',
      style: { maxWidth: '2%', minWidth: '110px' }, // we can apply all styles to the header now.
      headerClassName: 'no-text-select', // This targets the header's className
      title: 'Sent Date',
      sortable: false,
      rowClassName: 'no-text-select',
      render: (t, o) => moment(t, 'YYYYMMDDHHmmss').format('MMM DD, YYYY'),
    },
    {
      keyName: 'client_name',
      style: { width: '15%', paddingRight: '30px', minWidth: '200px' },
      headerClassName: 'row4 no-text-select',
      title: 'Client Name',
      rowClassName: 'blue-link',
      sortable: false,
      render: (t, o) => {
        return <StyledLink to={`/Clients?client=${o.client_id}`}>{t}</StyledLink>;
      },
    },
    {
      keyName: 'message',
      style: { width: '20%', paddingRight: '30px', minWidth: '130px' },
      headerClassName: 'row4 no-text-select',
      title: 'Message',
      sortable: false,
      render: (msg, item) => {
        return <div className='line-clamp-1' dangerouslySetInnerHTML={{ __html: convertMessageTemplate(msg)}} />
      }
    },
    {
      keyName: 'image_url',
      style: { width: '15%', paddingRight: '30px', minWidth: '130px' },
      headerClassName: 'row4 no-text-select',
      title: 'Attachments',
      sortable: false,
      render: t => {
        if (t) {
          return <img key={t} className="border-r4 photoFit h-167 w-167 m-right-15" src={t} alt="#" />;
        }
      },
    },
    {
      keyName: 'scope',
      style: { width: '15%', paddingRight: '30px', minWidth: '130px' },
      headerClassName: 'row4 no-text-select',
      title: 'Scope',
      sortable: false,
      render: (t, o) => {
        const { life_event_type, tag_type, custom_event_type, sales_opp_type, sales_trigger_type } = o;
        if (life_event_type) {
          return 'Life Event';
        }
        if (tag_type) {
          return 'Tag';
        }
        if (custom_event_type) {
          return 'Custom Event';
        }
        if (sales_opp_type) {
          return 'Sales Opp';
        }
        if (sales_trigger_type) {
          return 'Sales Trigger';
        }
      },
    },
    {
      keyName: 'type',
      style: { width: '20%', paddingRight: '30px', minWidth: '130px' },
      headerClassName: 'row4 no-text-select',
      title: 'Type',
      sortable: false,
      render: (t, o) => {
        const { life_event_type, tag_type, custom_event_type, sales_opp_type, sales_trigger_type } = o;
        const msgText = life_event_type || tag_type || custom_event_type || sales_opp_type || sales_trigger_type;
        if (msgText?.length > 60) {
          return `${msgText.substr(0, 60)}...`;
        }
        return msgText;
      },
    },
    {
      keyName: 'repeats',
      style: { width: '15%', paddingRight: '30px', minWidth: '30px' },
      headerClassName: 'row4 no-text-select align-center',
      title: 'Repeats',
      sortable: false,
      render: (t, o) => {
        if (Number(t)) {
          return (
            <label className="tags-checkmark pos-rel">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </label>
          );
        }
      },
    },
  ];

  return (
    <Layout
      title="Historical Log"
      description="View all past Auto Messages"
      classProps="standard-width-with-sidebar rem-pad-width"
      breadCrumbs={[{ title: 'Messaging' }, { title: 'Historical Log', to: '/Messaging/auto-msg-history' }]}
    >
      <Table
        tableClassName="lg-table-sidebar rem-pad-width"
        data={tableData}
        tableHeaders={tableHeaders}
        searchable={false}
        paginationV2
        tableName={tableName}
      />
    </Layout>
  );
};
const mapStateToProps = state => ({
  ...state,
  pagination: getTablePaginationData(state, tableName),
});
export default connect(mapStateToProps, {})(AutoMessageHistory);
