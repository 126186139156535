import React, { useEffect } from 'react';
import { string, bool, array } from 'prop-types';
import { connect } from 'react-redux';

import { getTablePaginationData, resetTablePagination } from '../../../../core/actions';
// redux & api
import { getBankAuditLog } from '../apiRequests';

// layout & images
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';

// local
import Table, { CHANGE_AT } from './Table';

const tableName = 'BANK_ACTIVITY';

const BankActivity = ({ activityLog = [], merchantId, sortColumn, ascendingColumn }) => {
  // Initial load
  useEffect(() => {
    resetTablePagination({ sortColumn: CHANGE_AT, ascendingColumn: true }, tableName);
  }, []);

  useEffect(() => {
    const isValidSortColumn = sortColumn === CHANGE_AT;
    if (isValidSortColumn && merchantId) {
      getBankAuditLog(merchantId, {
        ascending: ascendingColumn,
      });
    }
  }, [merchantId, ascendingColumn, sortColumn]);

  return (
    <Layout
      title="Activity Log"
      breadCrumbs={[{ title: 'Bank Accounts', to: '/BankAccount/List' }, { title: 'Activity Log' }]}
      classProps={'standard-width-with-sidebar'}
    >
      <div className="bank-accounts-list__header-actions"></div>
      {activityLog.length ? (
        <Table
          data={activityLog}
          merchantId={merchantId}
          sortColumn={sortColumn}
          ascendingColumn={ascendingColumn}
          tableName={tableName}
        />
      ) : null}
    </Layout>
  );
};

BankActivity.propTypes = {
  activityLog: array,
  sortColumn: string,
  ascendingColumn: bool,
};

const mapStateToProps = state => ({
  activityLog: state.bankAccounts.activityLog,
  ...getTablePaginationData(state, tableName),
  merchantId: state.auth.merchantId,
});

export default connect(mapStateToProps)(BankActivity);
