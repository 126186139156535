import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import colors from 'nice-color-palettes';
import { CSVLink } from 'react-csv';
import { getRequest } from '../../../../core/apiRequests';
import Table from '../../../Layout/Table';
import Layout from '../../../Layout/StandardLayout';
import Button from '../../../Layout/Button';
import TitleDescription from '../../../Layout/DescriptionBox';
import { setReminderIntv, setReportAssoc } from '../../../../reducers/reports';
import { setAuthStoreId } from '../../../../reducers/auth';
import ReusableChart from '../../../Layout/Chart';
import DropdownSelect from '../../../Layout/DropdownSelect';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import BulletedList from '../../../Layout/BulletedList.js';
import RangePicker from '../../../CustomizedAntDesign/RangePicker';
import '../reports.css';

const disabledDate = current => {
  return current && current > moment().endOf('day');
};

const ClientEngagementByActivity = props => {
  const [dateRange, setDateRange] = useState([moment().subtract(1, 'months'), moment()]);
  const [tableDef, setTableDef] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [allAssoc, setAllAssoc] = useState([]);
  const [chartConfigObj, setChartConfigObj] = useState({});

  const CC_Colors = [
    ['#fc8333', '#feb32c', '#7fbd31', '#009470', '#1769af'],
    ['#803e9f', '#fd0062', '#fe99c0', '#a50087', '#55a4e7'],
    ['#6ee5ea', '#ff6363', '#9ddfff', '#ffd495', '#dcff66'],
    ['#0eb3ba'],
  ];
  const clientContactColors = CC_Colors.concat(colors);

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  useEffect(() => {
    if (props.auth.merchantId) {
      const allAssociateOption = { id: 0, fullName: 'All Associates' };

      const reqObj = {
        params: ['report', props.auth.merchantId, props.auth.storeId || 0, 'clientByAction'],
        query: {
          userId: props.auth.userId,
          role: props.auth.role,
        },
      };

      const assoc_data = async () => {
        const data = await getRequest(reqObj);
        const sortedAscts = data?.sort((a, b) => {
          if (`${a.first_name || ''}${a.last_name || ''}` > `${b.first_name || ''}${b.last_name}`) {
            return 1;
          }
          if (`${a.first_name || ''}${a.last_name || ''}` < `${b.first_name || ''}${b.last_name}`) {
            return -1;
          }
          return 0;
        });
        setAllAssoc([allAssociateOption, ...sortedAscts]);
        setReportAssoc(0);
      };

      assoc_data();
    }
  }, [props.auth.merchantId, props.auth.storeId]);

  const runReport = e => {
    e.preventDefault();

    const reportEnd =
      props.reports.reminderIntv === 'monthly'
        ? `${moment(dateRange[1]).endOf('month').format('YYYY-MM-DDT00:00:00.000')}Z`
        : moment(dateRange[1]).endOf('day').toISOString();

    const reportStart =
      props.reports.reminderIntv === 'monthly'
        ? `${moment(dateRange[0]).startOf('month').format('YYYY-MM-DDT12:00:00.000')}Z`
        : moment(dateRange[0]).startOf('day').toISOString();

    const reqObj = {
      params: ['report', 'clientEngagementByActivity'],
      query: {
        licenseKey: props.auth.licenseKey,
        interval: props.reports.reminderIntv,
        startDt: reportStart,
        endDt: reportEnd,
        storeAccess: props.auth.stores.filter(st => st.id !== 0).map(st => st.id),
      },
    };

    if (props.auth.storeId) {
      reqObj.query.storeId = props.auth.storeId;
    }
    if (props.reports.associate) {
      reqObj.query.assoc = props.reports.associate;
    }

    (async () => {
      const res = {};
      res.data = await getRequest(reqObj);

      const labels = res.data.rows.map(row => {
        if (res.data.interval === 'monthly') {
          return monthNames[row.month - 1];
        }

        if (res.data.interval === 'daily') {
          return `${row.month}/${row.day}`;
        }

        return row.week;
      });

      const tempTableHeaders = [
        {
          keyName: 'actionType',
          headerClassName: 'row1',
          rowClassName: 'col-p-l-25',
          title: 'Action Type',
          style: {
            width: '5%',
            paddingLeft: '20px',
            paddingTop: '80px',
            paddingRight: '20px',
          },
          render: t => (t !== 'Total' ? t : <span className="fw-600 fs-16">{t}</span>),
        },
      ];
      const assocData = [];
      const assocBottomGraph = {};
      const assocDict = {};
      let maxVal = 0;

      res.data.rows.forEach(rowDate => {
        let startDt = '';

        if (res.data.interval === 'monthly') {
          startDt = monthNames[rowDate.month - 1];
        } else if (res.data.interval === 'daily') {
          startDt = `${rowDate.month}/${rowDate.day}`;
        } else {
          startDt = rowDate.week;
        }

        tempTableHeaders.push({
          keyName: startDt,
          style: { width: '5%', paddingTop: '80px', paddingRight: '10px' },
          headerClassName: 'row4',
          title: startDt,
          sortable: false,
        });

        rowDate.data.forEach(rowAssociate => {
          const { name } = rowAssociate;
          if (!assocDict[name]) {
            assocDict[name] = [];
            assocBottomGraph[name] = {
              actionType: name,
              [startDt]: rowAssociate.value,
            };
          }
          assocBottomGraph[name][startDt] = rowAssociate.value;
          if (rowAssociate.value > maxVal) {
            maxVal = rowAssociate.value;
          }
          assocDict[name].push(rowAssociate.value);
        });
      });

      const tempData = [];
      Object.keys(assocBottomGraph).forEach(ascName => {
        assocData.push(assocBottomGraph[ascName]);
        tempData.push(assocBottomGraph[ascName]);
      });
      setDownloadData(tempData);
      setTableHeaders(tempTableHeaders);
      const totals = assocData.reduce(
        (acc, curr) => {
          Object.keys(curr).forEach(key => {
            if (key !== 'actionType') {
              acc[key] = acc[key] ? acc[key] + parseInt(curr[key], 10) : parseInt(curr[key], 10);
            }
          });
          return acc;
        },
        { actionType: 'Total', className: 'fw-600 fs-16' },
      );
      assocData.push(totals);
      setTableDef(assocData);

      const datasets = [];

      Object.keys(assocDict).forEach(key => {
        const paletteIdx = Math.trunc(datasets.length / 5);
        const colorIdx = datasets.length % 5;

        datasets.push({
          label: key,
          data: assocDict[key],
          fill: false,
          borderColor: clientContactColors[paletteIdx][colorIdx],
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: clientContactColors[paletteIdx][colorIdx],
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: clientContactColors[paletteIdx][colorIdx],
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
        });
      });

      setChartConfigObj({
        type: 'line',
        data: {
          labels,
          datasets,
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        },
      });
    })();
    setShowReport(true);
  };

  return (
    <Layout
      title="Outreach by Activity"
      backArrow="/Home"
      description="Set your parameters and click on 'Run Report.'"
      classProps="standard-width-with-sidebar flex-col-center"
      styleProps={{ justifyContent: 'flex-start' }}
    >
      <div className="report-smaller-description">
        {' '}
        Outreach by Activity reports allow you to view the performance of each of your associates. Each report measures
        the amount of Client Activities logged by each of your associates. Each activity counts as 1 in the report.{' '}
      </div>
      <BulletedList
        title="This report measures the following activities:"
        items={['Clients Added', 'Calls Logged', 'Clients Messaged']}
      />
      <div className="pos-rel w-100-P " style={{ maxWidth: '866px' }}>
        <TitleDescription
          title="Reporting Location"
          description="What store location would you like to run a report on?"
          input={
            <DropdownSelect
              isSearchable
              classProps="mq-w-200 w-267 align-left"
              options={props.auth.stores.map(st => ({
                label: st.name,
                value: st.id,
              }))}
              placeholder="Select a Store..."
              value={props.auth.storeId}
              onChange={obj => {
                setAuthStoreId(obj.value);
              }}
            />
          }
        />
        <TitleDescription
          title="Associate"
          description="Which associate's report would you like to run?"
          input={
            <DropdownSelect
              classProps="mq-w-200 w-267 align-left"
              options={allAssoc.map(item => ({
                label: item.fullName ? item.fullName : item.first_name,
                value: item.id,
              }))}
              placeholder="Select an Associate..."
              value={props.reports.associate}
              onChange={obj => {
                setReportAssoc(obj.value);
              }}
            />
          }
        />
        <TitleDescription
          title="Frequency"
          description="Want to run a report based on month, week, or day?"
          input={
            <DropdownSelect
              classProps="mq-w-200 w-267 align-left"
              options={[
                { label: 'Days', value: 'daily' },
                { label: 'Weeks', value: 'weekly' },
                { label: 'Months', value: 'monthly' },
              ]}
              placeholder="Select a time interval..."
              value={props.reports.reminderIntv}
              onChange={obj => {
                setReminderIntv(obj.value);
              }}
            />
          }
        />

        <TitleDescription
          title="Date Range"
          input={
            <RangePicker
              format="MM/DD/YYYY"
              disabledDate={disabledDate}
              value={dateRange}
              onChange={setDateRange}
              ranges={{
                'Last 7 Days': [moment().subtract(7, 'd'), moment()],
                'Last 30 Days': [moment().subtract(30, 'd'), moment()],
                'Last 60 Days': [moment().subtract(60, 'd'), moment()],
                'Last 90 Days': [moment().subtract(90, 'd'), moment()],
              }}
              showToday
            />
          }
          className="report-date-picker"
        />

        <div className="report-asct-msg-row" style={{ maxWidth: '866px' }}>
          <Button id="RunOutreachByActivityReport" classname="darkBlueButton" onclick={e => runReport(e)}>
            Run Report
          </Button>
        </div>
      </div>

      {showReport ? (
        <div>
          <div
            className="w-100-P h-100-P maw-1200"
            style={{
              border: 'solid 1.0px #979797',
              borderRadius: '8px',
              width: '100%',
              position: 'relative',
            }}
          >
            <div>
              <h5 style={{ textAlign: 'center' }}>Outreach by Type</h5>
            </div>
            <div style={{ minHeight: '200px' }}>
              <ReusableChart width="100%" height="400px" configObj={chartConfigObj} />
            </div>
          </div>
          <div
            className="import_export_group"
            style={{
              justifyContent: 'flex-end',
              maxWidth: '1105px',
              zIndex: '100',
              marginTop: '100px',
              marginRight: '30px',
            }}
          >
            <img alt="#" src="https://dashboard-v2-images.s3.amazonaws.com/ic-export.svg" />
            <CSVLink
              data={downloadData}
              filename="ClientEngagementbyActivity.csv"
              id="DownloadOutreachByActivityReport"
              className="exportButton m-right-15"
            >
              Download
            </CSVLink>
          </div>
          <Table
            tableClassName="maw-1200 rem-pad-width m-top-15 overflowX hide-scrollbar w-100-P"
            tableContainerClass="w-100-P rem-pad-width "
            style={{ marginTop: '-100px' }}
            data={tableDef}
            tableRowClassName="col-p-l-25"
            tableHeaders={tableHeaders}
            tableName="CLIENT_ENGAGEMENT_BY_ACTIVITY_REPORT"
          />
        </div>
      ) : null}
      <SecondaryPanel
        overhead_title="Reports"
        title={['CLIENTELING', 'MESSAGING', 'Google Reviews', 'PAYMENTS', 'AUTOMATION', 'SALES OPPORTUNITY']}
        current="Outreach by Activity"
      />
    </Layout>
  );
};

const mapStateToProps = state => ({
  reports: state.reports,
  auth: state.auth,
  stores: state.stores,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ClientEngagementByActivity);
