import React, { useState, useEffect, useCallback } from 'react';
import { string, bool, array, number, shape, oneOfType } from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  getTablePaginationData,
  resetTablePagination,
  setTablePaginationPageCount,
  setTablePaginationRowCount,
} from '../../../../core/actions';
import {
  setPaymentFilterStatuses,
  setPaymentFilterDateRange,
  setPaymentFilterAssociates,
  setPaymentFilterStartDate,
  setPaymentFilterEndDate,
  setTransactionFilterBankAccounts,
} from '../../../../reducers/Filters';

// Layout & images
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import GlobalHeader from '../../../Layout/GlobalHeader';
import FilterSetter from '../../../Layout/FilterSetter';
import FilterChips from '../../../Layout/FilterChips';

// local
import { getPaymentActivityReport, loadAssociatesList, getPaymentActivityReportCSV } from '../apiRequests';
import { DATE_RANGE, PAYMENTS_COLUMN_KEY, PAYMENT_STATUS } from '../const';
import Table from './Table';
import { getDateRange } from '../utils';
import { setLoadingStatus } from '../../../../reducers/notification';
import { getBankAccountsList } from '../../Reports/BankTransaction/apiRequests';
import { associateNameFormat } from '../../../../utils';
import Button from '../../../Layout/Button';

export const ALL_ASSOCIATES_OPTION = { label: 'All Associates', value: 0 };

const tableName = 'PAYMENTS';

const PaymentActivity = ({
  payments,
  filters,
  // auth
  merchantId,
  userId,
  stores,
  storeId,
  authenticated,
  role,
  // pagination
  page: pageNumber,
  pageSize,
  sortColumn,
  ascendingColumn,
  fullName,
  bankAccounts = [],
}) => {
  const [isAssociate, setIsAssociate] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [asctFilterList, setAsctFilterList] = useState([]);
  const [getParams, setGetParams] = useState({});

  // initial load
  useEffect(() => {
    setLoadingStatus(true);
    resetTablePagination(
      {
        sortColumn: PAYMENTS_COLUMN_KEY.UPDATED,
        ascendingColumn: false,
      },
      tableName,
    );

    if (authenticated) {
      loadAssociatesList({ stores, role, userId, merchantId }, setAsctFilterList);
      setIsAssociate(role === 'ASSOCIATE');
    }
  }, [authenticated, stores, role, userId, merchantId]);

  // update getParams whenever filters change
  useEffect(() => {
    const { paymentFilterDateRange, paymentFilterStartDate, paymentFilterEndDate, ...rest } = filters;
    const { startDate, endDate } = paymentFilterDateRange
      ? getDateRange(paymentFilterDateRange)
      : {
        startDate: paymentFilterStartDate,
        endDate: paymentFilterEndDate,
      };

    setGetParams(params => ({
      ...params,
      ...rest,
      startDate,
      endDate,
    }));
  }, [filters]);

  // update getParams when store changes (store is set differently than other filters)
  useEffect(() => {
    setGetParams(params => ({
      ...params,
      storeId,
    }));
  }, [storeId]);

  useEffect(() => {
    if (merchantId) {
      getBankAccountsList(merchantId);
    }
  }, [merchantId]);

  // callback to refresh results (updates with getParams & pagination)
  const refresh = useCallback(() => {
    const isValidSortColumn = Object.values(PAYMENTS_COLUMN_KEY).includes(sortColumn);

    if (authenticated && isValidSortColumn) {
      getPaymentActivityReport({
        ...getParams,
        bankAccounts,
        page: pageNumber,
        count: pageSize,
        sortColumn,
        ascending: ascendingColumn,
      }).then(({ totalRecords }) => {
        setTablePaginationRowCount(totalRecords, tableName);
        setTablePaginationPageCount(Math.ceil(totalRecords / pageSize), tableName);
      });
    }
  }, [getParams, authenticated, pageNumber, pageSize, sortColumn, ascendingColumn]);

  // refresh results whenever callback updates
  useEffect(() => {
    refresh();
  }, [refresh]);

  /*  FILTERS  */
  const dateRangeList = Object.entries(DATE_RANGE).map(([value, label]) => ({
    label,
    value,
  }));

  const statusFilterList = Object.entries(PAYMENT_STATUS).map(([value, { text }]) => ({ label: text, value }));

  // Bank Filter
  const bankFilterList = bankAccounts.map(val => ({
    id: val.id,
    bankName: val.name,
    status: val.status,
  }));

  const saveFilters = ({ tempBank, tempAssoc, tempStatus, tempDateRange, tempStartDate, tempEndDate }) => {
    setShowFilters(false);

    setPaymentFilterDateRange(tempDateRange);
    setPaymentFilterStartDate(tempStartDate);
    setPaymentFilterEndDate(tempEndDate);

    if (tempStatus?.[0] !== 0) {
      setPaymentFilterStatuses(tempStatus);
    }
    if (tempAssoc?.[0] !== 0) {
      setPaymentFilterAssociates(tempAssoc);
    }

    if (tempBank?.[0] !== 0) {
      setTransactionFilterBankAccounts(tempBank);
    }
  };

  const exportCSV = useCallback(() => {
    if (authenticated) {
      getPaymentActivityReportCSV({
        ...getParams,
        page: pageNumber,
        count: pageSize,
        sortColumn,
        ascending: ascendingColumn,
      });
    }
  }, [authenticated, getParams, pageNumber, pageSize, sortColumn, ascendingColumn]);

  const csvButton = () => {
    return payments?.length ? (
      <Button classname="transparentButton" type="button" onClick={exportCSV}>
        <FontAwesomeIcon
          size="1x"
          className="fs-16 m-right-15"
          style={{ paddingBottom: '2px' }}
          icon={['far', 'file-excel']}
        />
        Export
      </Button>
    ) : null;
  };

  return (
    <Layout
      title="Payment Activity"
      classProps="standard-width-with-sidebar"
      maxWidth="none"
      description="All payments and requests are listed here"
    >
      <div
        onClick={() => setShowFilters(false)}
        className={showFilters ? 'pos-abs w-100-vw h-100-vh left-0 align-top  nav-z-100' : 'pos-rel '}
      />
      <div className="w-100-P h-100-P">
        <GlobalHeader
          setShowFilters={setShowFilters}
          showFilters={showFilters}
          search={[
            <input
              key={1}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();

                  setGetParams(params => ({
                    ...params,
                    searchQuery: e.target.value,
                  }));
                }
                return false;
              }}
              placeholder="Search client name or phone number…"
              id="PaymentActivitySearch"
              className="h-50 w-100-P m-btm-6 body-head-search rem-pad-width focus-input-outline-blue"
              style={{ width: '100%', height: '50px' }}
            />,
          ]}
          filter={
            <FilterSetter
              setShowFilters={setShowFilters}
              showFilters={showFilters}
              saveFilters={saveFilters}
              // Status
              filterStatus={filters.paymentFilterStatuses}
              statusFilterList={statusFilterList}
              // Date
              filterDateRange={filters.paymentFilterDateRange}
              dateRangeList={dateRangeList}
              startDate={filters.paymentFilterStartDate}
              endDate={filters.paymentFilterEndDate}
              // Associates
              filterAssociates={isAssociate ? undefined : filters.paymentFilterAssociates}
              asctFilterList={isAssociate ? undefined : asctFilterList}
              // Bank Accounts
              filterBank={filters.transactionFilterBankAccounts}
              bankFilterList={bankFilterList}
            />
          }
          additionalButtons={csvButton}
        />

        <FilterChips
          // Store
          storeFilterList={stores}
          storeId={storeId}
          // Status
          filterStatus={filters.paymentFilterStatuses}
          setFilterStatus={setPaymentFilterStatuses}
          statusFilterList={statusFilterList}
          // Associate
          asctFilterList={isAssociate ? undefined : asctFilterList}
          filterAssociates={isAssociate ? undefined : filters.paymentFilterAssociates}
          setFilterAssociates={isAssociate ? undefined : setPaymentFilterAssociates}
          // Date
          filterDateRange={filters.paymentFilterDateRange}
          dateRangeList={dateRangeList}
          setFilterDateRange={setPaymentFilterDateRange}
          startDate={filters.paymentFilterStartDate}
          setFilterStartDate={setPaymentFilterStartDate}
          endDate={filters.paymentFilterEndDate}
          setFilterEndDate={setPaymentFilterEndDate}
          // Bank Account
          filterBank={filters.transactionFilterBankAccounts}
          setFilterBank={setTransactionFilterBankAccounts}
          bankFilterList={bankFilterList}
        />
        {payments?.length ? (
          <Table
            data={payments}
            refresh={refresh}
            isAssociate={isAssociate}
            sortColumn={sortColumn}
            ascendingColumn={ascendingColumn}
            merchantId={merchantId}
            userId={userId}
            fullName={fullName}
            storeId={storeId}
            tableName={tableName}
          />
        ) : (
          <div className="payment-activity-list__empty">
            <img
              src="https://dashboard-v2-images.s3.amazonaws.com/empty-payments.svg"
              alt=""
              role="presentation"
              style={{ width: 240 }}
            />
            <h3 className="fs-16 fw-600">No payment activity yet</h3>
            <p className="fs-13 gray">When you create payment requests, they will appear here.</p>
          </div>
        )}
      </div>
    </Layout>
  );
};

PaymentActivity.propTypes = {
  payments: array,
  filters: shape({
    paymentFilterDateRange: string,
    paymentFilterStartDate: string,
    paymentFilterEndDate: string,
    paymentFilterStatuses: array,
  }),
  // auth
  merchantId: oneOfType([number, string]),
  userId: oneOfType([number, string]),
  stores: array,
  storeId: oneOfType([number, string]),
  authenticated: bool,
  role: string,
  // pagination
  page: oneOfType([number, string]),
  pageSize: oneOfType([number, string]),
  sortColumn: string,
  ascendingColumn: bool,
  fullName: string,
};

const mapStateToProps = state => ({
  payments: state.payments.list,
  filters: state.filters,
  merchantId: state.auth.merchantId,
  userId: state.auth.userId,
  authenticated: state.auth.authenticated,
  role: state.auth.role,
  stores: state.auth.stores,
  storeId: state.auth.storeId,
  pageNumber: state.pagination.page,
  pageSize: state.pagination.pageSize,
  sortColumn: state.pagination.sortColumn,
  ascendingColumn: state.pagination.ascendingColumn,
  fullName: associateNameFormat(state.auth.firstName, state.auth.lastName),
  bankAccounts: state.bankAccounts.fullList,
  ...getTablePaginationData(state, tableName),
});

export default connect(mapStateToProps)(PaymentActivity);
