import React from 'react';
import { connect } from 'react-redux';
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import TitleAndExport from './components/titleAndExport';
import { clientList, chatList, salesList } from './components/exportList';
import { postRequest } from '../../../../core/apiRequests';
import { notificationSuccess } from '../../../../core/actions';
import { associateNameFormat } from '../../../../utils';

const ExportData = props => {
  const { auth } = props;
  const sendExportRequest = type => {
    (async () => {
      const reqObj = {
        params: ['help', 'exportData'],
        query: {
          dataType: type,
          emailAddress: auth.email,
          associateName: associateNameFormat(props.auth.firstName, props.auth.lastName),
        },
      };
      await postRequest(reqObj);
      notificationSuccess('An email will be sent to you soon!');
    })();
  };
  return (
    <Layout
      title="Export Data"
      description="Export your client, chat, and sales opportunity data to a CSV."
      classProps="standard-width-with-sidebar pos-rel flex-col-center"
      styleProps={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}
    >
      <div className="maw-1200 flex-col-left pos-rel">
        <button
          type="button"
          className="save-button-v2 m-btm-8 exportAllButton"
          onClick={() => sendExportRequest(['Client Data', 'Chat History', 'Sales Opportunities'])}
          style={{ width: '208px' }}
        >
          Export All
        </button>
        <div className="flex-col-left">
          <TitleAndExport
            title="Client Data"
            list={clientList}
            type="Client Data"
            sendExportRequest={sendExportRequest}
          />
          <TitleAndExport
            title="Chat History"
            list={chatList}
            type="Chat History"
            sendExportRequest={sendExportRequest}
          />
          <TitleAndExport
            title="Sales Opportunities"
            list={salesList}
            type="Sales Opportunities"
            sendExportRequest={sendExportRequest}
          />
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(ExportData);
