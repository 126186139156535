import store from '../core/store';
import { dispatcher } from '../core/actions';

const initialState = {
  // client
  // addOrEdit: "Add", // I don't think t his is used?
  client_id: '',
  name: '',
  assigned: '',
  preferred_name: '',
  last_activity: '',
  client_tags: '',
  selected_client_list: [],
  // client details
  email: '',
  mobile: '',
  store_name: '',
  store_id: '',
  opt_in_req_date: '',
  opt_in_date: '',
  opt_out_date: '',
  WA_receive: '',
  mobile_invalid: '',
  mobile_validated: '',
  preferred_contact_type: '',
  pos_ref_num: '',
  uuid: '',
  associates: [],
  associateIds: [],
  associate_id: null,
  associate: null,
  details_tag_list: [],
  relationships: [],
  mixedRelationships: {},
  lifeEvents: [],
  reminders: [],
  salesOpps: [],
  salesPipelines: [],
  collections: [],
  singleCollection: [],
  autoMessages: [],
  images: {},
  recentActivity: [],
  // address section
  addresses: [], // read only
  salesStages: [],
  clientPurchases: [],
  preferences: [],
  //
};

// THESE ARE GLOBAL SO WATCH THE NAMES
// EX: actFirstName
export default function reducer(state = initialState, action) {
  const state2 = state;
  const { payload } = action;
  switch (action.type) {
  case 'CLEAR_CLIENT_INFO':
    return {
      ...state2,
      client_id: '',
      name: '',
      preferred_name: '',
      email: '',
      mobile: '',
      store_name: '',
      store_id: '',
    };
  case 'CLEAR_CLIENT':
    return {
      ...initialState,
    }
  case 'SET_CLIENT_DATA_OBJ':
    return { ...state2, ...action.payload };
  case 'SET_CLIENT_ADD_EDIT':
    return { ...state2, addOrEdit: action.payload };
  case 'SET_CLIENT_ID':
    return { ...state2, client_id: action.payload };
  case 'SET_CLIENT_NAME':
    return { ...state2, name: action.payload };
  case 'SET_CLIENT_PREF_NAME':
    return { ...state2, preferred_name: action.payload };
  case 'SET_CLIENT_DNC':
    return { ...state2, do_not_contact: action.payload };
  case 'SET_CLIENT_PREF_CONT_TYPE':
    return { ...state2, preferred_contact_type: action.payload };
  case 'SET_CLIENT_DNC':
    return { ...state2, do_not_contact: action.payload };
  case 'SET_CLIENT_ADDRESSES':
    return { ...state2, addresses: action.payload };
  case 'SET_CLIENT_ASSIGNED':
    return { ...state2, assigned: action.payload };
  case 'SET_CLIENT_LAST_ACT':
    return { ...state2, last_activity: action.payload };
  case 'SET_CLIENT_STORE':
    return { ...state2, store_id: action.payload };
  case 'SET_CLIENT_EMAIL':
    return { ...state2, email: action.payload };
  case 'SET_CLIENT_MOBILE':
    return { ...state2, mobile: action.payload };
  case 'SET_CLIENT_RELATION':
    return { ...state2, relation: action.payload };
  case 'SET_CLIENT_TAGS':
    return { ...state2, client_tags: action.payload };
  case 'SET_CLIENT_OPT_IN_REQ_DATE':
    return { ...state2, opt_in_req_date: action.payload };
  case 'SET_CLIENT_COLLECTIONS':
    return { ...state2, collections: action.payload };
  case 'SET_SELECTED_CLIENT_LIST':
    return { ...state2, selected_client_list: [...action.payload] };
  case 'SET_CLIENT_LIFE_EVENTS':
    return { ...state2, lifeEvents: [...action.payload] };
  case 'SET_CLIENT_MIXED_RELATIONSHIPS':
    return { ...state2, mixedRelationships: { ...action.payload } };
  case 'SET_CLIENT_AUTO_MESSAGES':
    return { ...state2, autoMessages: [...action.payload] };
  case 'SET_CLIENT_PREFERENCES':
    return { ...state2, preferences: [...action.payload] };
  case 'SET_CLIENT_PURCHASES':
    return { ...state2, clientPurchases: [...action.payload] };
  case 'SET_CLIENT_ASSOCIATE':
    return { ...state2, associate: action.payload };
  case 'SET_CLIENT_ASSOCIATE_IDS':
    return { ...state2, associate_id: action.payload };
  case 'SET_CLIENT_FROM_API':
    return {
      ...state,
      preferred_name: payload.preferred_name,
      email: payload.email,
      mobile: payload.mobile,
      store_name: payload.store_name,
      store_id: payload.store_id,
      do_not_contact: payload.do_not_contact,
      preferred_contact_type: payload.preferred_contact_type,
      WA_receive: payload.WA_receive,
      mobile_invalid: payload.mobile_invalid,
      mobile_validated: payload.mobile_validated,
      opt_in_date: payload.opt_in_date,
      opt_in_req_date: payload.opt_in_req_date,
      opt_out_date: payload.opt_out_date,
      notes: payload.notes,
      pos_ref_num: payload.pos_ref_num,
      details_tag_list: [...payload.tags],
      relationships: [...payload.relationships],
      mixedRelationships: payload.mixedRelationships,
      associates: [...payload.associates],
      associateIds: payload.associateIds,
      associate: payload?.associate,
      associate_id: payload?.associate_id,
      lifeEvents: [...payload.lifeEvents],
      reminders: [...payload.reminders],
      salesOpps: [...payload.salesOpps],
      collections: [...payload.collections],
      autoMessages: [...payload.autoMessages],
      images: payload.images,
      salesPipelines: payload.salesPipelines,
      recentActivity: [...payload.recentActivity],
      addresses: [...payload.addresses],
      salesStages: [...payload.salesStages],
      clientPurchases: [...payload.clientPurchases],
      // recentMessages: [...payload.recentMessages],
      // purchaseHistory: [...payload.purchaseHistory]
    };
  case 'ADD_RECENT_ACTIVITY':
    return {
      ...state2,
      recentActivity: [payload, ...state2.recentActivity],
    };
  case 'SET_RECENT_ACTIVITY':
    return {
      ...state2,
      recentActivity: payload,
    };
  case 'SET_MERGE_CLIENT_FROM_API':
    return {
      ...state2,
      ...payload,
    };
  case 'SET_CLIENT_DETAIL_FROM_EDIT':
    return { ...state2, ...action.payload };
  case 'SET_CLIENT_DETAIL_TAG_LIST':
    return {
      ...state2,
      details_tag_list: [...payload],
    };
  default:
    break;
  }
  return state2;
}
export const setClientDataObj = val => {
  dispatcher('SET_CLIENT_DATA_OBJ', val);
};
export const setClearClientInfo = () => {
  dispatcher('CLEAR_CLIENT_INFO', null);
};
export const setClearClient = () => {
  dispatcher('CLEAR_CLIENT', null);
}
export const setClientDetailsFromEdit = val => {
  dispatcher('SET_CLIENT_DETAIL_FROM_EDIT', val);
};
export const setClientAddEdit = val => {
  dispatcher('SET_CLIENT_ADD_EDIT', val);
};
export const setClientId = val => {
  dispatcher('SET_CLIENT_ID', val);
};
export const setName = val => {
  dispatcher('SET_CLIENT_NAME', val);
};
export const setDNC = val => {
  dispatcher('SET_CLIENT_DNC', val);
};
export const setPrefContactType = val => {
  dispatcher('SET_CLIENT_PREF_CONT_TYPE', val);
};
export const setPrefName = val => {
  dispatcher('SET_CLIENT_PREF_NAME', val);
};
export const setClientAddresses = val => {
  dispatcher('SET_CLIENT_ADDRESSES', val);
};
export const setClientAssig = val => {
  dispatcher('SET_CLIENT_ASSIGNED', val);
};
export const setClientLast = val => {
  dispatcher('SET_CLIENT_LAST_ACT', val);
};
export const setClientTags = val => {
  dispatcher('SET_CLIENT_TAGS', val || '');
};
export const setClientOptReqInDate = val => {
  dispatcher('SET_CLIENT_OPT_IN_REQ_DATE', val);
};
export const setClientCollections = val => {
  dispatcher('SET_CLIENT_COLLECTIONS', val || []);
};
export const setClientPreferences = val => {
  dispatcher('SET_CLIENT_PREFERENCES', val || []);
};
export const setClientDetailsTagList = val => {
  dispatcher('SET_CLIENT_DETAIL_TAG_LIST', val || []);
};
export const setSelectedClientList = val => {
  dispatcher('SET_SELECTED_CLIENT_LIST', val || []);
};
export const setClientLifeEvents = val => {
  dispatcher('SET_CLIENT_LIFE_EVENTS', val || []);
};
export const setClientMixedRelationships = val => {
  dispatcher('SET_CLIENT_MIXED_RELATIONSHIPS', val || []);
};
export const setClientAutoMessages = val => {
  dispatcher('SET_CLIENT_AUTO_MESSAGES', val || []);
};
export const setClientDetailsFromAPI = val => {
  dispatcher('SET_CLIENT_FROM_API', val);
};
export const setClientPurchases = val => {
  dispatcher('SET_CLIENT_PURCHASES', val);
};
export const setClientAssociate = val => {
  dispatcher('SET_CLIENT_ASSOCIATE', val);
};
export const setClientAssociateId = val => {
  dispatcher('SET_CLIENT_ASSOCIATE_IDS', val);
};
export const setMergeClientDetailsFromAPI = val => {
  dispatcher('SET_MERGE_CLIENT_FROM_API', val);
};
export const addRecentActivity = val => {
  dispatcher('ADD_RECENT_ACTIVITY', val);
};
export const setRecentActivity = val => {
  dispatcher('SET_RECENT_ACTIVITY', val);
};
export const setClientStore = val => {
  dispatcher('SET_CLIENT_STORE', val);
};
export const setClientEmail = val => {
  dispatcher('SET_CLIENT_EMAIL', val);
};
export const setClientMobile = val => {
  dispatcher('SET_CLIENT_MOBILE', val);
};
export const setClientRelation = val => {
  dispatcher('SET_CLIENT_RELATION', val);
};
//
export const setAddEditClients = (data, addOrEdit) => {
  const { dispatch } = store;
  const { client_id, name, assigned, last_activity, client_tags, selected_client_list, collections } = data;
  const dispatchArr = [
    {
      type: 'SET_CLIENT_ADD_EDIT',
      payload: addOrEdit || 'Add',
    },
    {
      type: 'SET_CLIENT_ID',
      payload: client_id || '',
    },
    {
      type: 'SET_CLIENT_NAME',
      payload: name || '',
    },
    {
      type: 'SET_CLIENT_ASSIGNED',
      payload: assigned || '',
    },
    {
      type: 'SET_CLIENT_LAST_ACT',
      payload: last_activity || '',
    },
    {
      type: 'SET_CLIENT_TAGS',
      payload: client_tags || '',
    },
    {
      type: 'SET_CLIENT_COLLECTIONS',
      payload: collections || [],
    },
    {
      type: 'SET_SELECTED_CLIENT_LIST',
      payload: selected_client_list || [],
    },
  ];
  dispatchArr.forEach(obj => {
    dispatch(obj);
  });
};
