import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import reactStringReplace from 'react-string-replace';
import sanitizeHtml from 'sanitize-html-react';
import { connect } from 'react-redux';
import { notificationError } from '../../../core/actions';
import ReviewLinkCheck from './ReviewLinkCheck';
import PreviewCard from './PreviewCard';

import { actionTextAreaFileActions } from './useActionTextAreaFiles';

const MAX_IMAGE_FILE_SIZE = 1600000;
const MAX_IMAGE_FILE_SIZE_HUMAN = '1.5mb';
const MAX_VIDEO_FILE_SIZE = MAX_IMAGE_FILE_SIZE;
const MAX_VIDEO_FILE_SIZE_HUMAN = MAX_IMAGE_FILE_SIZE_HUMAN;

const TextAreaWithActions = ({
  val: propsVal = '',
  changeHandler,
  autoMessageOrReminder = false,
  containerStyleProps = {},
  styleProps = {},
  textAreaStyleProps = {},
  placeholder = 'Write a message...',
  showPaymentsPopup,
  showTemplatePopup,
  client,
  reviewLink,
  templates,
  template = false,
  salesTriggers,
  associateName,
  configObj,
  associate,
  payments,
  relationshipName,
  sendMessage,
  fileDispatch,
  fileData,
  auth,
  hideImages,
  reviewMessage
}) => {
  const [hovering, setHovering] = useState('');
  const flags = auth.merchantFlags;
  const relationshipAutomation = flags?.relationship_automation?.enabled;
  const refEditor = useRef();

  function auto_grow(field) {
    // Reset field height
    field.style.height = 'inherit';
    const computed = window.getComputedStyle(field);
    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      // + parseInt(computed.getPropertyValue('padding-top'), 10)
      field.scrollHeight +
      // + parseInt(computed.getPropertyValue('padding-bottom'), 10)
      parseInt(computed.getPropertyValue('border-bottom-width'), 10);
    field.style.height = `${height}px`;
  }

  const characterCount = () => {
    return (
      <span
        className="fs-10 align-vert-middle h-12"
        style={{
          color: setNotif().color,
          display: 'table-cell',
          verticalAlign: 'middle',
          marginRight: '12px',
        }}
      >
        {refEditor?.current?.innerText?.length} characters{' '}
      </span>
    );
    function setNotif() {
      if (refEditor?.current?.innerText?.length <= 120) {
        return { color: '#818181' };
      }
      return { color: '#ff6363' };
    }
  };
  const buttonStyles = {
    verticalAlign: 'middle',
    border: '1px solid #dedede',
    borderRadius: '4px',
    padding: '7px 13px 7px 11px',
    backgroundColor: '#f3f3f3',
    color: '#818181',
  };

  const hoverButtonStyles = {
    verticalAlign: 'middle',
    border: '1px solid #dedede',
    borderRadius: '4px',
    padding: '7px 13px 7px 11px',
    backgroundColor: '#d3d3d3',
    color: '#3d3d3d',
    cursor: 'pointer',
  };
  const getCursorXY = val => {
    const findOption = options.find(option => option.value === val);
    if (findOption) {
      insetMetaTag(findOption);
    } else {
      insetMetaTag({ value: val, label: val });
    }
  };

  const imageHandler = e => {
    e.preventDefault();

    const eventImageFiles = [];
    const eventVideoFiles = [];

    for (let i = 0; i < e.target.files.length; i += 1) {
      const file = e.target.files[i];
      if (file.type.includes('image/')) {
        eventImageFiles.push(file);
      } else if (file.type.includes('video/')) {
        eventVideoFiles.push(file);
      }
    }

    processImageFiles(eventImageFiles);
    processVideoFiles(eventVideoFiles);

    function processImageFiles(imageFiles) {
      // this is a prop for auto reminder msgs and auto messages that only allow 1 image
      const maxFiles = autoMessageOrReminder ? 1 : 5;
      fileDispatch({
        type: actionTextAreaFileActions.setImages,
        payload: processFiles({
          files: imageFiles,
          maxFiles,
          maxSize: MAX_IMAGE_FILE_SIZE,
          sizeError: `Image file size exceeds ${MAX_IMAGE_FILE_SIZE_HUMAN}`,
          currentData: fileData.images,
          overflowError: maxFiles === 1 ? '1 image allowed' : `A Maximum of ${maxFiles} Images Allowed.`,
        }),
      });
    }

    async function processVideoFiles(videoFiles) {
      const maxFiles = autoMessageOrReminder ? 0 : 1;
      const videoData = processFiles({
        files: videoFiles,
        maxFiles,
        maxSize: MAX_VIDEO_FILE_SIZE,
        sizeError: `Video file size exceeds ${MAX_VIDEO_FILE_SIZE_HUMAN}`,
        currentData: fileData.videos,
        overflowError: maxFiles === 0 ? 'Video is not supported here.' : `A Maximum of ${maxFiles} Video Is Allowed.`,
      });
      const videoFrames = {
        files: [],
        urls: [],
      };
      const container = document.createElement('div');
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');
      container.appendChild(canvas);
      container.appendChild(video);
      container.style.visibility = 'hidden';

      document.body.appendChild(container);

      const ctx = canvas.getContext('2d');
      for (const videoURL of videoData.urls) {
        const { file, url } = await videoURLToThumbnail(videoURL);
        videoFrames.files.push(file);
        videoFrames.urls.push(url);
      }

      // document.body.removeChild(container)

      function videoURLToThumbnail(url) {
        return new Promise(resolve => {
          const loadedMetaEvent = 'loadedmetadata';
          const loadedEvent = 'seeked';
          const onVideoMetaLoaded = () => {
            video.currentTime = 0.2;
          };
          const onVideoLoaded = () => {
            canvas.height = video.videoHeight;
            canvas.width = video.videoWidth;
            ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            canvas.toBlob(blob => {
              const file = new File([blob], 'videoThumbnail.jpg', { type: 'image/jpeg' });
              resolve({
                file,
                url: URL.createObjectURL(file),
              });
            }, 'image/jpeg');
            video.removeEventListener(loadedMetaEvent, onVideoMetaLoaded);
            video.removeEventListener(loadedEvent, onVideoLoaded);
          };
          video.addEventListener(loadedMetaEvent, onVideoMetaLoaded);
          video.addEventListener(loadedEvent, onVideoLoaded);
          video.src = url;
        });
      }
      fileDispatch({
        type: actionTextAreaFileActions.setVideos,
        payload: {
          ...videoData,
          videoFrames,
        },
      });
    }

    function processFiles({
      files: filesToProcess,
      maxFiles,
      maxSize,
      sizeError,
      currentData = {
        files: [],
        urls: [],
      },
      overflowError,
    }) {
      const [hadLargeFile, files, urls] = filesToProcess.reduce(
        ([hadLargeFile, files, urls], file) => {
          const fileIsLarge = file.size >= maxSize;
          if (files.length >= maxFiles) {
            return [hadLargeFile || fileIsLarge, files, urls];
          }
          if (fileIsLarge) {
            return [true, files, urls];
          }
          files.push(file);
          urls.push(URL.createObjectURL(file));
          return [hadLargeFile, files, urls];
        },
        [false, [], []],
      );
      if (hadLargeFile) {
        notificationError(sizeError);
      }

      if (files?.length + currentData?.urls?.length > maxFiles) {
        notificationError(overflowError);
      }

      return {
        urls: urls.concat(currentData?.urls).slice(0, maxFiles),
        files: files.concat(currentData?.files).slice(0, maxFiles),
      };
    }

    e.target.value = null;
  };

  const disabledSend =
    // client.mobile_invalid === 1 ||
    propsVal?.length === 0 && fileData?.images?.urls?.length === 0 && fileData?.videos?.urls?.length === 0;

  const imageDeleteHandler = useCallback(
    index => fileDispatch({ type: actionTextAreaFileActions.removeImage, payload: index }),
    [fileDispatch],
  );
  const videoDeleteHandler = useCallback(
    index => fileDispatch({ type: actionTextAreaFileActions.removeVideo, payload: index }),
    [fileDispatch],
  );

  const options = useMemo(() => [
    {
      value: ':name:',
      label: 'Client Name',
    },
    {
      value: ':associate:',
      label: 'Associate Name',
    },
    {
      value: ':assigned_associate_name:',
      label: 'Associate Name',
    },
    {
      value: ':review:',
      label: 'Review Link',
    },
    {
      value: ':store:',
      label: 'Store Name',
    },
    {
      value: ':category:',
      label: 'Product Category',
    },
    {
      value: ':product:',
      label: 'Product Name',
    },
    {
      value: ':relation_name:',
      label: 'Relationship Name',
    },
  ]);

  const isMounted = useRef(null);

  const handleChange = useCallback(
    valueString => {
      isMounted.current = true;
      changeHandler(parseToString(valueString));
    },
    [changeHandler],
  );

  const parseToHtml = useCallback(
    value => {
      const regex = new RegExp(`(${options.map(t => t.value).join('|')})`);
      const splitString = reactStringReplace(value, regex, (match, i) => {
        const find = options.find(t => t.value === match);
        if (find) {
          return `<span class="ant-tag-clientbook" contentEditable="false">${find.label}</span>`;
        }
        return match;
      });
      return splitString.join('');
    },
    [options],
  );

  const parseToString = useCallback(
    value => {
      // clean the tags
      let splitString = reactStringReplace(value, /<span[^>]*>(.*?)<\/span>/, (match, i) => {
        if(match === 'Associate Name') {
          return associate || ':associate:';
        }
        const find = options.find(t => t.label === match);
        if (find) {
          return `${find.value}`;
        }
        return match;
      });
      // clean divs and substitute with new lines
      splitString = reactStringReplace(splitString, /<div[^>]*>(.*?)<\/div>/, (match, i) => {
        return `${match}\n`;
      });
      // clean the rest of the tags
      splitString = sanitizeHtml(splitString.join(''), {
        allowedTags: [],
        allowedAttributes: [],
      });
      // make sure the tags are not in the string
      splitString = reactStringReplace(splitString, /<[^>]*>/, (match, i) => {
        return match;
      });
      return splitString.join('');
    },
    [options],
  );

  function insetMetaTag(option) {
    const { range, selection } = getSelect();

    const isTextArea = checkParentElement(selection?.anchorNode, 'textAreaInputWithActions');

    const isData = !!range?.commonAncestorContainer.parentElement?.getAttribute('data-replacement');

    if (isData || !isTextArea) {
      return;
    }

    const myMetaTag = document.createElement('span');
    myMetaTag.setAttribute('data-replacement', option.value);
    myMetaTag.setAttribute('contenteditable', 'false');
    myMetaTag.setAttribute('class', 'ant-tag-clientbook');
    myMetaTag.innerText = option.label;


    const textElement = document.createTextNode("");
    range?.insertNode(myMetaTag);
    range?.collapse(false);
    range?.insertNode(textElement);
    range?.collapse(false);
    const newSelection = window?.getSelection();
    newSelection?.removeAllRanges();
    newSelection?.addRange(range);
    changeHandler(parseToString(range.commonAncestorContainer.innerHTML));
  }

  function checkParentElement(element, id) {
    if (element?.id === id) {
      return true;
    }
    if (element?.parentElement) {
      return checkParentElement(element?.parentElement, id);
    }
    return false;
  }

  function getSelect() {
    const selection = window?.getSelection();
    const range = selection?.getRangeAt(0);

    const parent = range?.commonAncestorContainer;

    return { selection, range, parent };
  }

  const [defaultValue, setDefaultValue] = useState(parseToHtml(propsVal));

  useEffect(() => {
    if (!isMounted.current) {
      setDefaultValue(parseToHtml(propsVal));
    }
  }, [isMounted, propsVal]);

  document?.addEventListener('keypress', function (event) {
    // enter keycode is 13
    if (event.keyCode == 13 && event.shiftKey == false) {
      event.preventDefault();
    }
  });

  document?.addEventListener('keydown', function (event) {
    // enter keycode is 13
    if (event.keyCode == 13 && event.shiftKey == false) {
      event.preventDefault();
    }
  });
  document?.addEventListener('keyup', function (event) {
    // enter keycode is 13
    if (event.keyCode == 13 && event.shiftKey == false) {
      event.preventDefault();
    }
  });

  const preventEnter = e => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      document.execCommand('insertLineBreak');
    }
  };

  const handlePaste = e => {
    e.preventDefault();

    let paste = e.clipboardData.getData("text");
    paste = parseToHtml(paste)

    const { range } = getSelect();

    const pasteNode = document.createTextNode(paste);
    const textElement = document.createTextNode("");
    range?.insertNode(pasteNode);
    range?.collapse(false);
    range?.insertNode(textElement);
    range?.collapse(false);
    const newSelection = window?.getSelection();
    newSelection?.removeAllRanges();
    newSelection?.addRange(range);
    changeHandler(parseToString(range.commonAncestorContainer.innerHTML));
  }

  return (
    <div
      style={{
        width: '100%',
        ...containerStyleProps,
        border: '1px #818181 solid',
        borderRadius: '3px',
      }}
    >
      <div className="pos-rel w-100-P">
        <div className="pos-rel" style={{ height: '100%', minHeight: '50px', ...styleProps }}>
          <div
            id="textAreaInputWithActions"
            contentEditable
            style={{
              width: 'calc(100% - 25px)',
              caretColor: 'black',
              display: 'block',
              fontFamily: 'Poppins',
              letterSpacing: 0.5,
              wordSpacing: 1,
              whiteSpace: 'pre-wrap',
              resize: 'none',
              minHeight: '50px',
              maxHeight: 'calc(100% - 25px)',
              padding: '15px 5px 10px 15px',
              outline: 'none',
              textAlign: 'left',
              ...textAreaStyleProps,
            }}
            ref={refEditor}
            dangerouslySetInnerHTML={{
              __html: defaultValue,
            }}
            onInput={e => {
              handleChange(parseToString(e.target.innerHTML));
            }}
            className="textarea-w-actions scrollbar-y"
            data-ph={placeholder}
            onKeyDown={e => preventEnter(e)}
            onPaste={e => handlePaste(e)}
          />
        </div>
        <div className="align-left align-bottom w-100-P">
          <div className="flex-row-nospacebetween-wrap">
            {fileData?.videos?.videoFrames?.urls?.map((im, i) => {
              return im ? (
                <PreviewCard
                  imageUrl={im}
                  key={im}
                  ind={i}
                  onDelete={videoDeleteHandler}
                  hovering={hovering}
                  setHovering={setHovering}
                  isVideo
                />
              ) : null;
            })}
            {fileData?.images?.urls?.map((im, i) => {
              return im ? (
                <PreviewCard
                  imageUrl={im}
                  key={im}
                  ind={i}
                  onDelete={imageDeleteHandler}
                  hovering={hovering}
                  setHovering={setHovering}
                />
              ) : null;
            })}
          </div>
        </div>
      </div>
      <div className="flex-row-spacebetween-wrap w-100-P">
        <div
          className="flex-row-spacebetween-wrap align-vert-middle"
          style={{ width: '100%', height: '43px', backgroundColor: '#f3f3f3', borderTop: '1px solid #dedede' }}
        >
          {/* payments 07/14/21 */}
          <div>
            {payments ? (
              <div
                className="profile_upload_div pointer"
                title="Send payment request"
                onMouseEnter={() => setHovering('payments')}
                onMouseLeave={() => setHovering('')}
                onClick={() => {
                  showPaymentsPopup(true);
                }}
                style={
                  hovering === 'payments'
                    ? {
                      cursor: 'pointer',
                      backgroundColor: '#d3d3d3',
                      marginLeft: '7px',
                      width: '29px',
                      height: '29px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '4px',
                    }
                    : {
                      cursor: 'pointer',
                      backgroundColor: '#f3f3f3',
                      marginLeft: '7px',
                      width: '29px',
                      height: '29px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '4px',
                    }
                }
              >
                <FontAwesomeIcon
                  className="fs-15 pointer"
                  icon={['far', 'dollar-sign']}
                  style={
                    hovering === 'payments'
                      ? {
                        backgroundColor: '#d3d3d3',
                        color: '#3d3d3d',
                      }
                      : {
                        color: '#818181',
                        backgroundColor: '#f3f3f3',
                      }
                  }
                />
              </div>
            ) : null}
            {/* IMAGES */}
            {!hideImages && <div
              className="profile_upload_div pointer"
              title="Attach files"
              onMouseEnter={() => setHovering('paperclip')}
              onMouseLeave={() => setHovering('')}
              style={
                hovering === 'paperclip'
                  ? {
                    cursor: 'pointer',
                    backgroundColor: '#d3d3d3',
                    marginLeft: '7px',
                    width: '29px',
                    height: '29px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '4px',
                  }
                  : {
                    cursor: 'pointer',
                    backgroundColor: '#f3f3f3',
                    marginLeft: '7px',
                    width: '29px',
                    height: '29px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '4px',
                  }
              }
            >
              <input
                type="file"
                accept=".jpg, .png, .mp4"
                className="profile_upload pointer"
                multiple
                onChange={event => {
                  imageHandler(event);
                }}
                onMouseEnter={() => setHovering('paperclip')}
                onMouseLeave={() => setHovering('')}
                style={
                  hovering === 'paperclip'
                    ? {
                      width: '24px',
                      height: '29px',
                      cursor: 'pointer',
                      backgroundColor: '#d3d3d3',
                      color: '#3d3d3d',
                    }
                    : {
                      width: '24px',
                      height: '29px',
                      cursor: 'pointer',
                      backgroundColor: '#f3f3f3',
                    }
                }
              />
              <FontAwesomeIcon
                className="fs-15 pointer"
                icon={['far', 'paperclip']}
                style={
                  hovering === 'paperclip'
                    ? {
                      backgroundColor: '#d3d3d3',
                      color: '#3d3d3d',
                    }
                    : {
                      color: '#818181',
                      backgroundColor: '#f3f3f3',
                    }
                }
              />
            </div>}
            {/* templates */}
            {templates ? (
              <div
                className="profile_upload_div pointer"
                title="Templates"
                onClick={() => {
                  showTemplatePopup(true);
                }}
                onMouseEnter={() => setHovering('template')}
                onMouseLeave={() => setHovering('')}
                style={
                  hovering === 'template'
                    ? {
                      cursor: 'pointer',
                      backgroundColor: '#d3d3d3',
                      marginLeft: '7px',
                      width: '29px',
                      height: '29px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '4px',
                    }
                    : {
                      cursor: 'pointer',
                      backgroundColor: '#f3f3f3',
                      marginLeft: '7px',
                      width: '29px',
                      height: '29px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '4px',
                    }
                }
              >
                <FontAwesomeIcon
                  // size={'2x'}
                  className="fs-15 pointer"
                  icon={['far', 'comment-alt-lines']}
                  style={
                    hovering === 'template'
                      ? {
                        backgroundColor: '#d3d3d3',
                        color: '#3d3d3d',
                      }
                      : {
                        color: '#818181',
                        backgroundColor: '#f3f3f3',
                      }
                  }
                />
              </div>
            ) : null}

            <button
              type="button"
              onClick={() =>
                getCursorXY(
                  client ? (client.preferred_name ? client.preferred_name : client.name.split(' ')[0]) : ':name:',
                )
              }
              onMouseEnter={() => setHovering('client')}
              onMouseLeave={() => setHovering('')}
              style={hovering === 'client' ? hoverButtonStyles : buttonStyles}
              className="pointer m-left-10 rem-pad-width h-29 fs-10"
            >
              Client Name
            </button>
            {associateName ? (
              <button
                type="button"
                onClick={() => getCursorXY(':associate:')}
                onMouseEnter={() => setHovering('associate')}
                onMouseLeave={() => setHovering('')}
                style={hovering === 'associate' ? hoverButtonStyles : buttonStyles}
                className="pointer m-left-10 rem-pad-width h-29 fs-10"
              >
                Associate Name
              </button>
            ) : null}
            {relationshipName && relationshipAutomation ? (
              <button
                type="button"
                onClick={() => getCursorXY(':relation_name:')}
                onMouseEnter={() => setHovering('relationship')}
                onMouseLeave={() => setHovering('')}
                style={hovering === 'relationship' ? hoverButtonStyles : buttonStyles}
                className="pointer m-left-10 rem-pad-width h-29 fs-10"
              >
                Relationship
              </button>
            ) : null}
            <ReviewLinkCheck
              getCursorXY={getCursorXY}
              client={client}
              reviewLink={reviewLink}
              reviewMessage={reviewMessage}
              template={template}
              onMouseEnter={() => setHovering('review')}
              onMouseLeave={() => setHovering('')}
              buttonStyles={hovering === 'review' ? hoverButtonStyles : buttonStyles}
            />
            {salesTriggers ? (
              <button
                type="button"
                onClick={() => getCursorXY(':category:')}
                onMouseEnter={() => setHovering('category')}
                onMouseLeave={() => setHovering('')}
                style={hovering === 'category' ? hoverButtonStyles : buttonStyles}
                className="pointer m-left-10 rem-pad-width h-29 fs-10"
              >
                Product Category
              </button>
            ) : null}
            {salesTriggers ? (
              <button
                type="button"
                onClick={() => getCursorXY(':product:')}
                onMouseEnter={() => setHovering('product')}
                onMouseLeave={() => setHovering('')}
                style={hovering === 'product' ? hoverButtonStyles : buttonStyles}
                className="pointer m-left-10 rem-pad-width h-29 fs-10"
              >
                Product Name
              </button>
            ) : null}
            <button
              type="button"
              onClick={() => getCursorXY(':store:')}
              onMouseEnter={() => setHovering('store')}
              onMouseLeave={() => setHovering('')}
              style={hovering === 'store' ? hoverButtonStyles : buttonStyles}
              className="pointer m-left-10 rem-pad-width h-29 fs-10"
            >
              Store Name
            </button>
            {configObj && configObj.spouse ? (
              <button
                type="button"
                onClick={() => getCursorXY(configObj.spouse ? configObj.spouse : ':spouse:')}
                onMouseEnter={() => setHovering('spouse')}
                onMouseLeave={() => setHovering('')}
                style={hovering === 'spouse' ? hoverButtonStyles : buttonStyles}
                className="pointer m-left-10 rem-pad-width h-29 fs-10"
              >
                Spouse Name
              </button>
            ) : null}
            {associate ? (
              <button
                type="button"
                onClick={() => getCursorXY(`${associate}`)}
                onMouseEnter={() => setHovering('associate')}
                onMouseLeave={() => setHovering('')}
                style={hovering === 'associate' ? hoverButtonStyles : buttonStyles}
                className="pointer m-left-10 rem-pad-width h-29 fs-10"
              >
                Associate Name
              </button>
            ) : null}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
            <div
              className="align-vert-middle"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              {characterCount()}
            </div>
            {sendMessage ? (
              <button
                type="button"
                style={
                  !disabledSend
                    ? {
                      width: '29px',
                      height: '29px',
                      borderRadius: '4px',
                      backgroundColor: '#1769af',
                      marginRight: '15px',
                      border: 'none',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingRight: '8px',
                      paddingBottom: '2px',
                    }
                    : {
                      border: 'none',
                      marginRight: '15px',
                    }
                }
                disabled={disabledSend}
                onClick={sendMessage}
              >
                <FontAwesomeIcon
                  // size={'2x'}
                  className="fs-16 pointer"
                  icon={['fas', 'paper-plane']}
                  style={
                    !disabledSend
                      ? {
                        color: '#ffffff',
                      }
                      : {
                        color: '#818181',
                      }
                  }
                />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
  clientState: state.client,
});
export default connect(mapStateToProps, {})(TextAreaWithActions);
