import moment from 'moment';

export const filterList = ({ newClients = [], filters, startDate, endDate, assocStores }) => {
  const allStores = assocStores.filter(store => store.id !== 0).map(item => item.id);
  return newClients?.filter(item => {
    const filterStore =
      filters.store.length === 0
        ? allStores.includes(item?.activity_store_id)
        : filters.store.includes(item?.activity_store_id?.toString());
    const filterAssociate =
      filters.associate.length === 0 || filters.associate.includes(item?.activity_associate_id?.toString());
    const filterTimeSpan = moment(item.activity_date).isBetween(moment(startDate), moment(endDate));
    const filterSource =
      filters.sourceMethod.length === 0 || filters.sourceMethod.includes(item?.sourceMethod?.toString());
    return filterStore && filterAssociate && filterTimeSpan && filterSource;
  });
};
