/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Drawer } from 'antd-v5';
import './styles.scss';
import yn from 'yn';

const ProductsV2Drawer = props => {
  const { open, onClose, product, hideCost, ...rest } = props;

  return (
    <Drawer {...rest} title="Product Details" onClose={onClose} open={open} width={284} className="products-v2-drawer">
      {!product ? (
        <span>No product selected</span>
      ) : (
        <>
          {Object.entries(product.attributes).map(([key, value]) => {
            if (!value) return null;
            if (key === 'unit_cost' && yn(hideCost)) return null;

            return (
              <div className="products-v2-drawer__section" key={key}>
                <div className="products-v2-drawer__section-title">{key.replaceAll('_', ' ')}</div>
                <div className="products-v2-drawer__section-value">{value}</div>
              </div>
            );
          })}
        </>
      )}
    </Drawer>
  );
};

export default ProductsV2Drawer;
