import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import axios from 'axios';
import SecondaryPanel from '../../../../../Layout/SecondaryPanel';
import StandardLayout from '../../../../../Layout/StandardLayout';
import Chip from '../../../../../Layout/Chip';
import IntegrationsSettingsStores from './components/Stores';
import IntegrationsSettingsOptions from './components/Options';
import Button from '../../../../../CustomizedAntDesign/Button';
import PopConfirm from '../../../../../Layout/PopConfirm';
import { API_URL } from '../../../../../../constants';
import './style.css';

const INTEGRATIONS = {
  lightspeed: {
    title: 'Lightspeed',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/lightspeed-logo.png',
  },
  kwi: {
    title: 'KWI',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/kwi-logo.png',
  },
  lightspeedx: {
    title: 'LightspeedX',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/lightspeed-logo.png',
  },
  shopify: {
    title: 'Shopify',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/shopify-logo.png',
  },
  google: {
    title: 'Google',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/google-logo.png',
  },
  facebook: {
    title: 'Messenger',
    image: 'https://dashboard-v2-images.s3.amazonaws.com/messenger.png',
  },
  instagram: {
    title: 'Instagram',
    image: 'https://dashboard-v2-images.s3.us-east-1.amazonaws.com/instragram-logo.png',
  }
};

const IntegrationsSettings = props => {
  const {
    setLoading,
    pos_type,
    Stores,
    setPosType,
    setSyncClients,
    setSyncProducts,
    setSyncPurchaseHistory,
    setPosClientsLastUpdatedDate,
    setPosProductsLastUpdatedDate,
    setPosPurchaseHistoryLastUpdatedDate,
  } = props;

  const { slug } = useParams();
  const history = useHistory();

  const [stores, setStores] = useState([]);
  const [posStores, setPosStores] = useState([]);
  const [storesMap, setStoresMap] = useState({});

  const integration = INTEGRATIONS[slug];
  const installed = useMemo(() => pos_type && pos_type.toLocaleLowerCase() === slug, [pos_type, slug]);

  const [showUninstallConfirm, setShowUninstallConfirm] = useState(false);
  const [settingsEnabled, setSettingsEnabled] = useState(false);

  const handleUninstall = async () => {
    setLoading(true);

    try {
      const { data } = await axios.delete(`${API_URL}/xa/integrations`);

      setPosType(data.pos_type);
      setSyncClients(Boolean(data.sync_clients));
      setSyncProducts(Boolean(data.sync_products));
      setSyncPurchaseHistory(Boolean(data.sync_purchase_history));

      history.push('/Help/Integrations');
    } catch (error) {
      // ERROR HANDLER
    } finally {
      setLoading(false);
      setShowUninstallConfirm(false);
    }
  };

  const handleUpdate = async data => {
    setLoading(true);

    try {
      const response = await axios.put(`${API_URL}/xa/integrations/settings`, data);

      setSyncClients(response.data.sync_clients);
      setSyncProducts(response.data.sync_products);
      setSyncPurchaseHistory(response.data.sync_purchase_history);
      setPosClientsLastUpdatedDate(response.data.pos_clients_last_updated_date);
      setPosProductsLastUpdatedDate(response.data.pos_products_last_updated_date);
      setPosPurchaseHistoryLastUpdatedDate(response.data.pos_purchase_history_last_updated_date);
    } catch (error) {
      // ERROR HANDLER
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getPosSettings = async () => {
      setLoading(true);

      try {
        const { data } = await axios.get(`${API_URL}/xa/integrations/settings`);

        setSyncClients(Boolean(data.sync_clients));
        setSyncProducts(Boolean(data.sync_products));
        setSyncPurchaseHistory(Boolean(data.sync_purchase_history));
        setPosClientsLastUpdatedDate(data.pos_clients_last_updated_date);
        setPosProductsLastUpdatedDate(data.pos_products_last_updated_date);
        setPosPurchaseHistoryLastUpdatedDate(data.pos_purchase_history_last_updated_date);
      } catch (error) {
        // ERROR HANDLER
      } finally {
        setLoading(false);
      }
    };

    getPosSettings();
  }, []);

  useEffect(() => {
    const getPosStores = async () => {
      setLoading(true);

      try {
        let newPosStores;
        if (Stores.stores) {
          newPosStores = Stores.stores.map(item => ({
            value: item.id,
            label: item.name,
          }));
        } else {
          const response = await axios.get(`${API_URL}/xa/pos-stores`);

          newPosStores = response.data.map(item => ({
            value: item.id,
            label: item.name,
          }));
        }
        setPosStores(newPosStores);
      } catch (error) {
        // ERROR HANDLER
      } finally {
        setLoading(false);
      }
    };

    getPosStores();
  }, []);

  useEffect(() => {
    const getStores = async () => {
      setLoading(true);

      try {
        const response = await axios.get(`${API_URL}/xa/stores`);

        const newStores = [];
        const newStoresMap = {};

        response.data.forEach(item => {
          newStores.push({
            value: item.id,
            label: item.name,
          });

          newStoresMap[item.id] = item.pos_ref_num;
        });

        setStores(newStores);
        setStoresMap(newStoresMap);
      } catch (error) {
        // ERROR HANDLER
      } finally {
        setLoading(false);
      }
    };

    getStores();
  }, []);

  useEffect(() => {
    setSettingsEnabled(stores.length && posStores.length && Object.values(storesMap).some(item => Boolean(item)));
  }, [storesMap, stores, posStores]);
  if (!integration || !installed) {
    return <Redirect to="/help/integrations" />;
  }

  return (
    <div className="cb-integrations-settings">
      <StandardLayout
        title={integration.title}
        titleImage={integration.image}
        actions={<Chip>Installed</Chip>}
        backLink="/help/integrations"
        classProps="standard-width-with-sidebar"
      >
        <div className="cb-integrations-settings__body">
          <h3 className="cb-integrations-settings__title">Store Mapping</h3>

          <p className="cb-integrations-settings__subtitle">
            At least one store MUST be mapped in order to sync your data
          </p>

          <IntegrationsSettingsStores
            integrationName={integration.title}
            stores={stores}
            posStores={posStores}
            storesMap={storesMap}
            setStoresMap={setStoresMap}
            onChange={handleUpdate}
          />

          <h3 className={`cb-integrations-settings__title ${!settingsEnabled && 'cb-integrations-settings__disabled'}`}>
            Sync Data
          </h3>

          <p className="cb-integrations-settings__subtitle">
            The initial sync may take up to 30 minutes. While the sync is ongoing, you can leave this page and return
            later.
          </p>

          <IntegrationsSettingsOptions disabled={!settingsEnabled} storesMap={storesMap} onChange={handleUpdate} />

          <Button type="primary" onClick={() => setShowUninstallConfirm(true)}>
            Uninstall
          </Button>
        </div>
      </StandardLayout>

      {showUninstallConfirm && (
        <PopConfirm
          confirm={`Are you sure you want to uninstall ${integration.title}?`}
          onCancel={() => setShowUninstallConfirm(false)}
          onConfirm={handleUninstall}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  Stores: state.auth.stores,
  pos_type: state.auth.pos_type,
});

const mapDispatchToProps = dispatch => ({
  setLoading(val) {
    dispatch({
      type: 'SET_SAVING_NOTIF',
      payload: val,
    });
  },
  setPosType(val) {
    dispatch({
      type: 'SET_POS_TYPE',
      payload: val,
    });
  },
  setSyncClients(val) {
    dispatch({
      type: 'SET_SYNC_CLIENTS',
      payload: val,
    });
  },
  setSyncProducts(val) {
    dispatch({
      type: 'SET_SYNC_PRODUCTS',
      payload: val,
    });
  },
  setSyncPurchaseHistory(val) {
    dispatch({
      type: 'SET_SYNC_PURCHASE_HISTORY',
      payload: val,
    });
  },
  setPosClientsLastUpdatedDate(val) {
    dispatch({
      type: 'SET_POS_CLIENTS_LAST_UPDATED_DATE',
      payload: val,
    });
  },
  setPosProductsLastUpdatedDate(val) {
    dispatch({
      type: 'SET_POS_PRODUCTS_LAST_UPDATED_DATE',
      payload: val,
    });
  },
  setPosPurchaseHistoryLastUpdatedDate(val) {
    dispatch({
      type: 'SET_POS_PURCHASE_HISTORY_LAST_UPDATED_DATE',
      payload: val,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsSettings);
