import { CheckOutlined } from '@ant-design/icons';
import { DatePicker, Select } from 'antd-v5';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';

const periodOptions = [
  { label: 'Month to date', value: 'month' },
  { label: 'Last 30 days', value: '30days' },
  { label: 'Last 90 days', value: '90days' },
  { label: 'Year to date', value: 'year' },
  { label: 'Last year', value: 'lastyear' },
  { label: 'Custom date range', value: 'custom' },
];

const futureOptions = [
  { label: 'Overdue', value: 'overdue' },
  { label: 'This month', value: 'month' },
  { label: 'Next 90 days', value: 'next-90-days' },
  { label: 'Custom date range', value: 'custom' },
];

const date = dayjs().subtract(2, 'day').toDate();

const ReportSelectDate = ({
  type = 'past-options',
  defaultValue = '90days',
  onChange = () => {},
  checkSelected = true,
  overdueDate = date,
  customOptions = [],
  getPeriodLabel = () => {},
}) => {
  const [period, setPeriod] = useState(defaultValue);
  const [open, setOpen] = useState(false);


  const options = useMemo(() => {
    switch (type) {
      case 'past-options':
        return periodOptions;
      case 'future-options':
        return futureOptions;
      case 'custom-options':
        return customOptions;
      default:
        return periodOptions;
    }
  }, [type]);

  useEffect(() => {

    const periodLabel = options.find(option => option.value === period)?.label;
    getPeriodLabel(periodLabel || period);

    switch (period) {
      case 'this-month':
        return onChange([dayjs().startOf('month').startOf('day').toDate(), dayjs().endOf('month').endOf('day').toDate()]);
      case 'next-90-days':
        return onChange([dayjs().startOf('day').toDate(), dayjs().add(90, 'days').endOf('day').toDate()]);
      case 'overdue':
        return onChange([overdueDate, dayjs().subtract(1, 'day').endOf('day').toDate()]);
      case 'month':
        return onChange([dayjs().startOf('month').startOf('day').toDate(), dayjs().endOf('month').endOf('day').toDate()]);
      case '30days':
        return onChange([dayjs().subtract(30, 'days').startOf('day').toDate(), dayjs().endOf('day').toDate()]);
      case '90days':
        return onChange([dayjs().subtract(90, 'days').startOf('day').toDate(), dayjs().endOf('day').toDate()]);
      case 'year':
        return onChange([dayjs().startOf('year').startOf('day').toDate(), dayjs().endOf('day').toDate()]);
      case 'lastyear':
        return onChange([
          dayjs().subtract(1, 'year').startOf('year').startOf('day').toDate(),
          dayjs().subtract(1, 'year').endOf('year').endOf('day').toDate(),
        ]);
      case 'custom':
        return setOpen(true);
      default:
        break;
    }
  }, [period, overdueDate, options]);

  const handleCustomDateChange = dates => {
    if (dates.length === 2) {
      onChange([dates[0].startOf('day').toDate(), dates[1].endOf('day').toDate()]);
      setPeriod(`${dates[0].format('MM/DD/YYYY')} - ${dates[1].format('MM/DD/YYYY')}`);
    }
    setOpen(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
      }}
    >
      <Select
        placeholder="Period"
        options={options}
        value={period}
        onChange={value => setPeriod(value)}
        popupMatchSelectWidth={false}
        placement="bottomLeft"
        menuItemSelectedIcon={checkSelected ? <CheckOutlined /> : null}
      />
      <div
        className={"h-100-vh w-100-vw pos-fixed left-0 top-0 z-index-999 " + (open ? 'd-block' : 'd-none')}
        onClick={() => setOpen(false)}
      />
      <DatePicker.RangePicker
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0,
          zIndex: -1,
        }}
        open={open}
        onChange={dates => handleCustomDateChange(dates)}
        />
    </div>
  );
};

export default ReportSelectDate;
