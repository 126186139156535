import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory, Redirect, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Table from '../../../Layout/Table';
import Button from '../../../Layout/Button';
import Layout from '../../../Layout/StandardLayout';
import StyledLink from '../../../Layout/StyledLink';
import { getRequest } from '../../../../core/apiRequests';
import {
  resetTablePagination,
  setTablePaginationRowCount,
  setTablePaginationPageCount,
  getTablePaginationData,
  setTablePaginationSearchText,
} from '../../../../core/actions';
import { setMassMessage, resetMassMessaging } from '../../../../reducers/massMessaging';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import GlobalHeader from '../../../Layout/GlobalHeader';
import { convertMessageTemplate } from '../../../../utils/convertMessageTemplate';

const tableName = 'MASS_MESSAGES';

const MassMessaging = props => {
  const [tableData, setTableData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [first, setFirst] = useState(false);
  const [monthAudience, setMonthAudience] = useState(0);
  const [isShown, setIsShown] = useState('');
  const [localSearchText, setLocalSearchText] = useState('');
  const history = useHistory();

  const flags = props?.auth?.merchantFlags;
  const messageLimit = Number(flags?.mass_messaging?.value) || 0;

  const componentMounted = useRef(true);

  useEffect(() => {
    if (props.auth.merchantId && Object.keys(flags).length > 0 && !flags?.mass_messaging?.enabled) {
      if (componentMounted.current) {
        history.push('/');
      }
      return () => {
        componentMounted.current = false;
      };
    }
  }, [flags, props.auth.merchantId]);

  useEffect(() => {
    setTableData([]);
    if (props.auth.merchantId) {
      const { merchantId } = props.auth;
      const { page, pageSize, searchText } = props.pagination;
      if (first) {
        resetTablePagination({ sortColumn: 'scheduled_date_time' }, tableName);
        setFirst(false);
        (async () => {
          const reqObj = {
            params: ['massMessaging', merchantId, 'paginatedList'],
            query: {
              page,
              count: pageSize,
              sortColumn: 'scheduled_date_time',
              ascending: true,
              searchText,
            },
          };
          const dataArr = await getRequest(reqObj); // after search text I need to do pageSize query here
          if (dataArr && Array.isArray(dataArr.results) && dataArr.results) {
            const {
              results,
              pageInfo: { page, pageSize, rowCount, pageCount },
            } = dataArr;
            setTablePaginationRowCount(rowCount, tableName);
            setTablePaginationPageCount(pageCount, tableName);
            if (results.length > 0) {
              const sortedDates = results.sort((a, b) =>
                moment(b.scheduled_date_time, 'YYYYMMDDhhmmss').diff(moment(a.scheduled_date_time, 'YYYYMMDDhhmmss')),
              );
              setTableData(sortedDates);
              setTempData(sortedDates);
              getMonthAudience();
            }
          }
        })();
      } else {
        (async () => {
          const reqObj = {
            params: ['massMessaging', merchantId, 'paginatedList'],
            query: {
              page,
              count: pageSize,
              sortColumn: 'scheduled_date_time',
              ascending: true,
              searchText,
            },
          };
          const dataArr = await getRequest(reqObj); // after search text I need to do pageSize query here
          if (dataArr && Array.isArray(dataArr.results) && dataArr.results) {
            const {
              results,
              pageInfo: { page, pageSize, rowCount, pageCount },
            } = dataArr;
            setTablePaginationRowCount(rowCount, tableName);
            setTablePaginationPageCount(pageCount, tableName);
            if (results.length > 0) {
              const sortedDates = results.sort((a, b) =>
                moment(b.scheduled_date_time, 'YYYYMMDDhhmmss').diff(moment(a.scheduled_date_time, 'YYYYMMDDhhmmss')),
              );
              setTableData(sortedDates);
              setTempData(sortedDates);
              getMonthAudience();
            }
          }
        })();
      }
    }
  }, [
    props.auth.merchantId,
    props.pagination.sortColumn,
    props.pagination.pageSize,
    props.pagination.page,
    props.pagination.ascendingColumn,
    props.pagination.searchText,
  ]);

  const getMonthAudience = () => {
    const { merchantId } = props.auth;
    (async () => {
      const reqObj = { params: ['massMessaging', merchantId, 'X', 'getMonthAudience'] };
      const monAud = await getRequest(reqObj);
      // const monAud = await getSettingList('massMessaging', merchantId, 'X', 'getMonthAudience');
      if (monAud && monAud.length > 0) {
        const respObj = monAud[0];
        setMonthAudience(respObj.monthAudience);
      }
    })();
  };

  const tableHeaders = [
    // This is a configuration array.
    {
      keyName: 'message',
      style: {
        width: '30%',
        paddingLeft: '40px',
        minWidth: '260px',
        maxWidth: '250px',
      },
      headerClassName: 'tags_row2 fs-12 fw-600 rem-pad-width',
      rowClassName: 'p-left-41 rem-pad-width blue-link',
      title: 'Message Preview',
      render: (t, o) => {
        const maxLength = !o.archive_date_time && !o.archive_by ? 120 : 60;
        if (t && t.length > maxLength) {
          // this method is to keep from splicing a :key: word in half using substr or some other method
          let wordLetterCount = 0;
          const wordList = [];
          t.split(' ').forEach(word => {
            if (wordLetterCount < maxLength) {
              wordLetterCount += word.length + 1; // +1 to account for a possible space after it.
              wordList.push(word);
            }
          });
          // const joinedWordList = wordList.join(' ');
          return (
            <div className="flex-col-left">
              <div dangerouslySetInnerHTML={{ __html: convertMessageTemplate(wordList.join(' '))}} />
              {o.archive_by || o.archive_date_time ? (
                <span className="gray fs-10 m-top-10">{`Canceled by ${o.archive_by_name || ''} ${cancelTimeFormatter(
                  o.archive_date_time,
                )}`}</span>
              ) : (
                ''
              )}
            </div>
          );
          // return `${t.substr(0, 120)}...` // here we need to split this thang.
        }
        return (
          <div className="flex-col-left">
            <div dangerouslySetInnerHTML={{ __html: convertMessageTemplate(t)}} />
            {o.archive_by || o.archive_date_time ? (
              <span className="gray fs-10 m-top-10">{`Canceled by ${o.archive_by_name || ''} ${cancelTimeFormatter(
                o.archive_date_time,
              )}`}</span>
            ) : (
              ''
            )}
          </div>
        );
        // return t; // here we'll just pass this into the formatter function
        function cancelTimeFormatter(archTm) {
          const timeOffset = moment(new Date()).utcOffset();
          return archTm ? moment(archTm, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('MM/DD/YYYY @ h:mmA') : '';
        }
      },
      // sortable: true,
    },
    {
      keyName: 'status',
      style: { width: '10%', minWidth: '110px' },
      headerClassName: '',
      rowClassName: 'align-center rem-pad-width',
      title: 'Status',
      render: (text, obj) => {
        const bubbleStyles = {
          padding: '4px 0 2px 0',
        };
        return (
          <div className="flex-col-left" style={{ justifyContent: 'space-around' }}>
            {formatStatus()}
            {obj.archive_by || obj.archive_date_time ? <div className="gray h-20 m-top-10" /> : ''}
          </div>
        );
        function formatStatus() {
          if (obj.archive_by || obj.archive_date_time) {
            return (
              <div
                style={{
                  ...bubbleStyles,
                  backgroundColor: '#f9e0e0',
                  color: '#ff7a7a',
                }}
                className="rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 blue"
              >
                CANCELED
              </div>
            );
          }
          if (obj.completed_date_time) {
            return (
              <div
                style={{ ...bubbleStyles, backgroundColor: '#e8f4ff' }}
                className="rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 blue"
              >
                COMPLETE
              </div>
            );
          }
          if (obj.started_date_time && !obj.completed_date_time) {
            return (
              <div
                style={{ ...bubbleStyles, backgroundColor: '#ecf8dd' }}
                className="rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 green"
              >
                SENDING
              </div>
            );
          }
          if (!obj.started_date_time && !obj.completed_date_time && obj.total_sent > 0) {
            return (
              <div className="d-inline-block pos-rel">
                {/* this is the tool tip */}
                <div
                  id={isShown === obj.id ? 'textBlock_active' : 'textBlock'}
                  className="pos-abs w-222 border-r12 textBlock"
                  style={{
                    backgroundColor: '#4e4e4e',
                    bottom: '40px',
                  }}
                >
                  <p className="white" style={{ padding: '11px 12px', margin: '0px' }}>
                    If the client has not opted-in after 24 hours, the status will move to "complete"
                  </p>
                  <img
                    src="https://dashboard-v2-images.s3.amazonaws.com/caret-down-solid.svg"
                    className="pos-abs"
                    style={{ bottom: '-15px', left: '17px' }}
                  />
                </div>
                <div
                  style={{ ...bubbleStyles, backgroundColor: '#FFE5D3', color: '#FC8333' }}
                  className="rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-108 "
                  key={obj.id}
                  onMouseEnter={() => setIsShown(obj.id)}
                  // onMouseEnter={mouseOverThings}
                  onMouseLeave={() => setIsShown('')}
                >
                  WAITING FOR OPT-IN
                </div>
                {/* end here */}
              </div>
            );
          }
          if (!obj.started_date_time && !obj.completed_date_time) {
            return (
              <div
                style={{ ...bubbleStyles, backgroundColor: '#ebebeb' }}
                className="rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 gray"
              >
                SCHEDULED
              </div>
            );
          }
        }
      },
      // sortable: true
    },
    {
      keyName: 'audience_size',
      style: { width: '10%', minWidth: '70px' },
      headerClassName: 'tags_row4 align-center',
      rowClassName: 'align-center rem-pad-width',
      title: 'Audience',
      render: (t, obj) => {
        return (
          <div className="flex-col-left" style={{ justifyContent: 'space-around', alignItems: 'center' }}>
            {t}
            {obj.archive_by || obj.archive_date_time ? <div className="gray fs-10 h-20 m-top-10" /> : ''}
          </div>
        );
      },
      // sortable: true,
    },
    {
      keyName: 'total_sent',
      style: { width: '8%', minWidth: '70px' },
      headerClassName: 'p-left-8 align-center rem-pad-width',
      rowClassName: 'align-center rem-pad-width',
      title: 'Total Sent',
      render: (t, obj) => {
        return (
          <div className="flex-col-left" style={{ justifyContent: 'space-around', alignItems: 'center' }}>
            {t}
            {obj.archive_by || obj.archive_date_time ? <div className="gray fs-10 h-20 m-top-10" /> : ''}
          </div>
        );
      },
      // sortable: true
    },
    {
      keyName: 'scheduled_date_time',
      style: { width: '14%', minWidth: '120px', paddingRight: '22px' },
      headerClassName: 'p-left-41 align-left',
      rowClassName: 'p-left-41 align-left rem-pad-width',
      title: 'Start Time',
      render: (t, o) => {
        const timeOffset = moment(new Date()).utcOffset();
        if (!o.archive_by && !o.archive_date_time) {
          return t ? moment(t, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('MM/DD/YYYY h:mmA') : '';
        }
        if (t) {
          return moment(t, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('MM/DD/YYYY h:mmA');
        }
        return (
          <div className="flex-col-left" style={{ justifyContent: 'space-around' }}>
            <span>Never</span>
            {o.archive_by || o.archive_date_time ? (
              <span
                className="gray fs-10 m-top-13"
                style={{
                  overflowX: 'visible',
                  whiteSpace: 'nowrap',
                  maxWidth: '80px',
                }}
              >{`Reason: ${o.archive_reason || ''}`}</span>
            ) : (
              ''
            )}
          </div>
        );
      },
      // sortable: true,
    },
    {
      keyName: 'completed_date_time',
      style: { width: '14%', minWidth: '120px', paddingRight: '22px' },
      headerClassName: 'align-left',
      rowClassName: 'align-left rem-pad-width',
      title: 'Completed',
      // sortable: true,
      render: (t, o) => {
        const timeOffset = moment(new Date()).utcOffset();
        if (!o.archive_by && !o.archive_date_time) {
          return t ? moment(t, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('MM/DD/YYYY h:mmA') : '';
        }
        if (t) {
          return moment(t, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('MM/DD/YYYY h:mmA');
        }
        return (
          <div className="flex-col-left" style={{ justifyContent: 'space-around' }}>
            <span>Never</span>
            {o.archive_by || o.archive_date_time ? <div className="gray fs-10 h-20 m-top-10" /> : ''}
          </div>
        );
      },
    },
    // {
    //     keyName: "created_by",
    //     style: { width: "40%", minWidth: "135px" },
    //     headerClassName: "p-left-41 p-right-41 align-left",
    //     rowClassName: "align-left p-left-41 p-right-41 col-p-r-25 rem-pad-width",
    //     title: "Created By",
    //     render: (t, o) => {
    //         return (
    //         <div className="flex-col-left" style={{justifyContent: 'space-around'}}>
    //             {t ? t : 'n/a'}
    //             {   o.archive_by || o.archive_date_time ?
    //                     <div className="gray fs-10 h-20 m-top-10" />
    //                     : ''
    //             }
    //         </div>)

    //     },
    //     sortable: true
    // }, {
    //     keyName: "source",
    //     style: { width: "40%", minWidth: "135px" },
    //     headerClassName: "p-left-41 p-right-41 align-left",
    //     rowClassName: "align-left p-left-41 p-right-41 col-p-r-25 rem-pad-width",
    //     title: "Source",
    //     render: (t, o) => {
    //         return (
    //             <div className="flex-col-left" style={{justifyContent: 'space-around'}}>
    //                 {t ? t : 'n/a'}
    //                 {   o.archive_by || o.archive_date_time ?
    //                         <div className="gray fs-10 h-20 m-top-10" />
    //                         : ''
    //                 }
    //             </div>
    //         )
    //     },
    //     sortable: true
    //   },
  ];

  const rowClickHandler = obj => {
    const {
      scheduled_date_time,
      completed_date_time,
      started_date_time,
      created_date_time,
      archive_date_time,
      require_opt_in,
      archive_reason,
      audience_size,
      associate_id,
      archive_by,
      image_urls,
      total_sent,
      send_as,
      message,
      intro,
    } = obj;

    setMassMessage({
      msgId: obj.id,
      message,
      createdDate: created_date_time,
      schedDate: scheduled_date_time,
      archiveDate: archive_date_time || '',
      archiveBy: archive_by || '',
      archiveReason: archive_reason || '',
      startDate: started_date_time,
      completeDate: completed_date_time,
      audienceSize: audience_size,
      totalSent: total_sent,
      associateId: associate_id,
      imageUrls: image_urls ? image_urls.split(',') : '',
      requireOptIn: !!require_opt_in,
      intro,
    });
    // setRedirect(true);
    history.push(`/Messaging/mass-messaging/message-details/${obj.id}`);
  };

  const clearPref = () => {
    resetMassMessaging();
  };

  const getMonthSent = () => {
    const thisMonth = moment(new Date()).format('YYYYMM');
    return tableData
      .filter(msg => {
        if (msg.completed_date_time) {
          return moment(msg.completed_date_time, 'YYYYMMDDHHmmss').format('YYYYMM') === thisMonth;
        }
        return false;
      })
      .reduce((acc, val) => {
        return acc + val.total_sent;
      }, 0);
  };

  const getOverages = () => {
    const overage = ((monthAudience - messageLimit) * 0.05).toFixed(2);
    if (overage >= 0) {
      return overage;
    }
    return 0;
  };

  const additionalMassButton = () => {
    return (
      <div className="align-right m-top-8">
        <StyledLink to="/Messaging/mass-messaging/new-message" styleprops={{ width: '15px' }} classprops="">
          <Button classname="transparentButton" onclick={clearPref}>
            <FontAwesomeIcon
              style={{ width: '15px', height: '15px' }}
              size="1x"
              className=" m-right-15"
              icon={['far', 'comment-alt-plus']}
            />{' '}
            New Message
          </Button>
        </StyledLink>
      </div>
    );
  };

  const handleSearch = e => {
    if (e.key === 'Enter') {
      setTablePaginationSearchText(localSearchText, tableName);
    }
  };

  return (
    <Layout
      title="Mass Messaging"
      description="Create scheduled mass messages for personalized groups of clients"
      classProps="standard-width-with-sidebar rem-pad-width"
      breadCrumbs={[{ title: 'Messaging' }, { title: 'Mass Messaging' }]}
    >
      <div className="maw-1200 h-100-P">
        <GlobalHeader
          additionalButtonStyle={{ justifyContent: 'flex-end', marginBottom: '20px' }}
          search={[
            <input
              key="mass-message-key"
              onKeyDown={handleSearch}
              value={localSearchText}
              onChange={e => setLocalSearchText(e.target.value)}
              placeholder="Mass Message Search..."
              className="h-50 w-100-P m-btm-6 body-head-search m-btm-6 rem-pad-width focus-input-outline-blue"
              style={{ width: '100%', height: '50px' }}
            />,
          ]}
          additionalButtons={additionalMassButton}
        />
        <div className="flex-row-spacebetween-nowrap maw-1200" style={{ marginBottom: '-35px' }}>
          <div className=" rem-pad-width max-w-554 add-edit-wrap m-left-4">
            <div className="align-left w-142 h-83 ">
              <span className="align-left fs-16 fw-500">Metrics</span>
              <div className="align-left fs-13 fw-500 light-gray ">This month</div>
            </div>
            <div className="add-edit-wrap  h-83 max-w-438 w-187">
              <div className="flex-col-left" style={{ marginRight: '30%' }}>
                <span className="fw-500 fs-13 gray w-67">Plan Limit</span>
                <span className="fs-24 fw-600">{messageLimit}</span>
              </div>
              <div className="flex-col-left" style={{ marginRight: '30%' }}>
                <span className="fw-500 fs-13 gray w-67">Total Sent</span>
                <span
                  className="fs-24 fw-600"
                  style={messageLimit >= monthAudience ? { color: '#60bb00' } : { color: '#ff6060' }}
                >
                  {monthAudience}
                </span>
              </div>
              {messageLimit < getMonthSent() ? (
                <div className="flex-col-left" style={{ marginRight: '30%' }}>
                  <span className="fw-500 fs-13 gray w-67">Overages</span>
                  <span className="fs-24 fw-600" style={{ color: '#ff6060' }}>{`$${getOverages()}`}</span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Table
          tableClassName="lg-table-sidebar rem-pad-width maw-1100 overflowY overflowX hide-scrollbar"
          // tableContainerClass={"maw-1100"}
          data={tableData}
          tableHeaders={tableHeaders}
          searchable={false}
          paginationV2
          onRowClick={rowClickHandler}
          tableName={tableName}
        />
      </div>
    </Layout>
  );
};
const mapStateToProps = state => ({
  pagination: getTablePaginationData(state, tableName),
  auth: state.auth,
});
export default connect(mapStateToProps, {})(MassMessaging);
