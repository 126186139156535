import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, Col, Input, Modal, Row } from "antd-v5";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from 'react-query';
import { getRequest } from '../../../../../../core/apiRequests';




export default function CategoryModal({open, onClose, allCategories, previewsSelectedCategories, triggerId, setValue}) {

  const {data: categoriesUsed, isLoading} = useQuery(['categoriesUsed'], async () => {
    const reqObj = {
      params: ['salesTrigger', 0, triggerId || 'none', 'getMerchantCategories'],
    };
    const response = await getRequest(reqObj);
    return response?.map(cat => cat.category);
  }, {
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const [selectedCategories, setSelectedCategories] = useState(previewsSelectedCategories || []);
  const [search, setSearch] = useState('');

  const handleSelectAll = useCallback((value) => {
    if(value) {
      setSelectedCategories(allCategories.map(cat => cat.category));
    } else {
      setSelectedCategories([]);
    }
  }, [allCategories]);

  const filteredCategories = useMemo(() => {
    if(search) {
      return allCategories.filter(cat => cat.category.toLowerCase().includes(search.toLowerCase()));
    } else {
      return allCategories;
    }
  }, [allCategories, search]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Categories"
      okText="Save"
      onCancel={onClose}
      onOk={() => {
        setValue('categories', selectedCategories);
        onClose();
      }}
      width={920}
      styles={{
        content: {
          maxHeight: '90vh',
        },
        body: {
          maxHeight: '70vh',
        }
      }}
      classNames={{ body: 'scrollbar-y'}}
    >
      <div className="flex-col-center-start">
        <div className="flex-row-spacebetween-center w-100-P m-btm-24">
          <div className="flex-row-center gap-8">
            <Input.Search
              placeholder="Search Categories"
              style={{ width: 400 }}
              onChange={value => setSearch(value.target.value)}
              value={search}
            />
            <Checkbox onChange={e => handleSelectAll(e.target.checked)}>Select all</Checkbox>
          </div>
          <span className="fs-12">
            <FontAwesomeIcon icon={['far', 'check']} color="#7fbd31" /> = Used in other trigger(s)
          </span>
        </div>
        {filteredCategories?.length && (
          <Checkbox.Group
            style={{ width: '100%' }}
            /* options={filteredCategories.map(cat => ({
              label: <span>
                {cat.category}
                {categoriesUsed?.includes(cat.category) && <FontAwesomeIcon className="m-left-4" icon={['far', 'check']} color="#7fbd31" />}
              </span>,
              value: cat.category,
            }))} */
            defaultValue={previewsSelectedCategories}
            onChange={values => setSelectedCategories(values)}
            value={selectedCategories}

          >
            <Row style={{ rowGap: 16 }}>
              {filteredCategories?.map(cat => (
                <Col span={6} key={cat.category}>
                  <Checkbox
                    key={cat.category}
                    value={cat.category}
                  >
                    {cat.category}
                    {categoriesUsed?.includes(cat.category) && <FontAwesomeIcon className="m-left-4" icon={['far', 'check']} color="#7fbd31" />}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        )}
      </div>

    </Modal>
  )
}
