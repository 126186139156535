import React from 'react';
import Chip from '../../../../../../../Layout/Chip';

const InstagramButton = (props) => {

  return (
    props.installed ? (
      <Chip>Installed</Chip>
    ) : (
      <div
        className='h-40 flex-row-center pointer'
        style={{
          borderRadius: '20px',
          background: '#F2F2F2',
          padding: '0 16px',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
        {...props}
      >
        <img src='https://dashboard-v2-images.s3.us-east-1.amazonaws.com/instragram-logo.png' alt='facebook-logo' style={{ marginRight: '10px', width: '20px', height: '20px' }} />
        {props?.label ?? 'Message us on Instagram'}
      </div>
    )
  );
};

export default InstagramButton;
