import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from '../../../Layout/Table';
import Button from '../../../Layout/Button';
import Layout from '../../../Layout/StandardLayout';
import StyledLink from '../../../Layout/StyledLink';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import FlaggedAddOppBtn from './FlaggedAddOppBtn';
import { getTablePagList } from '../../../../core/apiRequests';
import { setEditPipeline, setPipelineId, setPipelineName } from '../../../../reducers/salesPipeline';
import { setListId, setListType } from '../../../../reducers/autoRemMsgs';
import { getTablePaginationData, resetTablePagination } from '../../../../core/actions';

const tableName = 'SALES_PIPELINES';

const SalesPipelines = props => {
  // const [redirectViewPpln, setRedirectViewPpln] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [first, setFirst] = useState(true);
  const isMounted = useRef(null);
  const history = useHistory();

  useEffect(() => {
    isMounted.current = true;
    if (props.merchantId) {
      const { page, pageSize, sortColumn, ascendingColumn, searchText } = props.pagination;
      let data;
      if (first) {
        resetTablePagination({ sortColumn: 'name' }, tableName);
        setFirst(false);
        data = async () => {
          const dataArr = await getTablePagList({
            tableName,
            type: 'salesOpp',
            merchantId: props.merchantId,
            sortColumn: 'name',
          });
          setTableData(dataArr);
        };
      } else {
        data = async () => {
          const dataArr = await getTablePagList({
            type: 'salesOpp',
            merchantId: props.merchantId,
            page,
            pageSize,
            sortColumn,
            ascendingColumn,
            searchText,
          });
          setTableData(dataArr);
        };
      }
      data();
      return () => {
        isMounted.current = false;
      };
    }
  }, [
    props.merchantId,
    props.pagination.sortColumn,
    props.pagination.pageSize,
    props.pagination.page,
    props.pagination.ascendingColumn,
    props.pagination.searchText,
  ]);

  const tableHeaders = [
    // this is a configuration array. It specifies how each column will be structured as well as the column header info
    {
      keyName: 'name',
      style: { minWidth: '150px' },
      headerClassName: 'col-p-l-25',
      rowClassName: 'col-p-l-25',
      title: 'Opportunity Type',
      sortable: true, // sort allows gives us the ability to sort the table
    },
    {
      keyName: 'amount',
      style: { width: '13%', minWidth: '140px' },
      headerClassName: 'align-left col-p-l-25',
      rowClassName: 'align-center col-p-l-25 rem-pad-width',
      title: 'Default Amt.',
      sortable: true,
    },
    {
      keyName: 'days_to_close',
      style: { width: '20%', minWidth: '220px' },
      headerClassName: 'align-left col-p-l-25 rem-pad-width',
      rowClassName: 'align-center col-p-l-25',
      title: 'Expected Days to Close',
      sortable: true,
    },
    {
      keyName: 'action',
      style: { width: '15%', minWidth: '200px' },
      headerClassName: 'col-p-l-25',
      rowClassName: 'align-left col-p-r-25 col-p-l-25 rem-pad-width',
      title: 'Action',
      render: (t, o) => {
        // return <span style={{ padding: 0 }} className="blue-link fs-14" onClick={() => setViewPipeline(o)}>View Current Pipeline</span>
        return (
          <span style={{ padding: 0 }} className="blue-link fs-13">
            <Link className="blue-link no-decoration" to={`/Configuration/sales-pipelines/edit-sales-pipeline/${o.id}`}>
              Edit Details
            </Link>
          </span>
        );
      },
    },
  ];

  const setViewPipeline = data => {
    setPipelineName(data.name);
    setPipelineId(data.id);
    history.push(`/Configuration/sales-pipelines/view-pipeline/${data.id}`);
    // setRedirectViewPpln(true);
  };

  const rowClickHandler = (data, e) => {
    if (e.target.tagName === 'TD') {
      // to make sure they're not clicking the Action button
      setViewPipeline(data);
      // props.editPipeline(data, 'Edit')
      // history.push(`/Configuration/sales-pipelines/edit-sales-pipeline/${data.id}`)
      // setRedirectEdit(true)
    }
  };

  return (
    <Layout
      title="Sales Opportunities"
      description="Create and manage Sales Pipelines to track your client's sales processes"
      classProps="standard-width-with-sidebar"
    >
      <div>
        <Table
          tableClassName="lg-table-sidebar rem-pad-width"
          data={tableData.filter(td => Number(td.id) !== Number(props.deletedSalesPipeline))}
          tableHeaders={tableHeaders}
          searchable
          paginationV2
          optionalButton={
            <FlaggedAddOppBtn
              flags={props.merchantFlags}
              clickHandler={props.clearPipeline}
            />
          }
          onRowClick={rowClickHandler}
          tableName={tableName}
        />

        <div className="m-top-25 align-left">
          <p style={{ margin: 0, padding: 0, width: '175px' }}>
            <Link className="fs-13 no-decoration blue d-block" to="/Reports/sales-pipelines-by-associate">
              Sales Opportunities by Associate
            </Link>
          </p>
          <span className="fs-10">Current count of active opportunities by stage per associate</span>
        </div>
        <div className="m-top-25 align-left">
          <p style={{ margin: 0, padding: 0, width: '235px' }}>
            <Link className="fs-13 no-decoration blue d-block" to="/Reports/created-opportunity-by-associate">
              Created Opportunities by Associate
            </Link>
          </p>
          <span className="fs-10">Count of created sales opportunities by associate over the specified date range</span>
        </div>
      </div>
    </Layout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  ...state.salesPipeline,
  pagination: getTablePaginationData(state, tableName),
});
const mapDispatchToProps = dispatch => ({
  editPipeline(val) {
    setListId(val.id);
    setListType('salesOpp');
    setEditPipeline(val, 'Edit');
  },
  clearPipeline() {
    setEditPipeline(
      {
        id: '',
        name: '',
        amount: '',
        days_to_close: '',
      },
      'Add',
    );
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SalesPipelines);
