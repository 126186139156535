import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopConfirm from '../../../../../../Layout/PopConfirm';
import DropdownSelect from '../../../../../../Layout/DropdownSelect';
import FilterChips from '../../../../../../Layout/FilterChips';
import { putRequest } from '../../../../../../../core/apiRequests';
import { notificationSuccess } from '../../../../../../../core/actions';
import {
  setMsgClient,
  setMsgFollowers,
  updateFollowersConversation
} from '../../../../../../../reducers/messaging';

const AssignChatPopup = props => {
  // const [tempAssoc, setTempAssoc] = useState(props.associateList);
  const [tempAssoc, setTempAssoc] = useState(props?.followers || []);
  const startAssoc = useMemo(() => props?.followers || [], [props?.followers]);
  const [asctPlaceholder, setAsctPlaceholder] = useState('');

  const setFilterSettings = obj => {
    if (tempAssoc.includes('none') || tempAssoc.includes(0)) {
      tempAssoc.shift();
    }
    if (obj.value === 'none' || Number(obj.value) === 0) {
      if (obj.value === 'none') {
        setTempAssoc(['none']);
      } else {
        setTempAssoc([]);
      }
    } else {
      const filteredArr = tempAssoc.filter(aid => Number(aid) !== Number(obj.value));
      setTempAssoc([...filteredArr, obj.value]);
    }
  };

  const assignAssociate = async () => {
    props.setAssignPopup(false);
    const reqObj = {
      data: { associateIds: tempAssoc },
    };
    if (props.source === 'chatInbox') {
      reqObj.params = ['chats', 'followers', props.messaging.client.chat_id];
    } else if (props.source === 'hoverAssign') {
      reqObj.params = ['chats', 'followers', props.hoverAssignId];
    }
    const data = await putRequest(reqObj);
    if (data) {
      setMsgClient({ assignedAssociates: tempAssoc });
      setTempAssoc([]);
      notificationSuccess('Associate(s) Updated');
      if(props.source === 'chatInbox') {
        setMsgFollowers(data);
        updateFollowersConversation(props.messaging.client.chat_id, data);
      } else if (props.source === 'hoverAssign') {
        updateFollowersConversation(props.hoverAssignId, data);
        if(props.hoverAssignId === props.messaging.client.chat_id) {
          setMsgFollowers(data);
        }
      }
    }

  };

  const filteredAssociateList = useMemo(
    () =>
      props.asctFilterList
        .filter(item => !['', 'NO'].includes(item.value))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [props.asctFilterList],
  );

  useEffect(() => {
    if (tempAssoc.length > 0) {
      if (tempAssoc.length !== 0 && tempAssoc[0] !== 'none') {
        setAsctPlaceholder(`${tempAssoc.length} Selected Associate${tempAssoc.length > 1 ? 's' : ''}`);
      }
      if (tempAssoc.length === 0) {
        setAsctPlaceholder('Select Associates');
      }
      setAsctPlaceholder('No Assigned Associates');
    }
    setAsctPlaceholder('Select Associates');
  }, [tempAssoc]);

  return (
    <PopConfirm
      popStyle={{
        width: '380px',
      }}
      confirmButtonText="Save"
      cancelButtonText="Cancel"
      confirm="Invite to the Conversation"
      description={[
        <div key="primary" className="align-left m-top-10">
          <span className="dropDownDescriptionText">Associates</span>
          <DropdownSelect
            classProps="w-100-P w-222 align-left h-50 w-295"
            options={filteredAssociateList}
            placeholder={asctPlaceholder}
            value={null}
            isSearchable
            selectProps={{
              maxMenuHeight: 220,
              // eslint-disable-next-line react/no-unstable-nested-components
              formatOptionLabel: obj => {
                if (tempAssoc.includes(obj.value)) {
                  return (
                    <div style={{ alignItems: 'center' }} className="flex-row-nospacebetween-nowrap">
                      <FontAwesomeIcon icon={['fal', 'check']} className="fs-12 m-right-8" />
                      <span className="p-top-3">{obj.label}</span>
                    </div>
                  );
                }
                return obj.label;
              },
            }}
            onChange={obj => {
              setFilterSettings({
                ...obj,
              });
            }}
          />
          <div className="w-100-P">
            <FilterChips
              asctFilterList={filteredAssociateList}
              filterAssociates={tempAssoc}
              setFilterAssociates={setTempAssoc}
              setTempAssoc={setTempAssoc}
              staticAssoc={props.staticAssoc}
              startAssoc={startAssoc}
            />
          </div>
        </div>,
      ]}
      noButtonDisable
      onCancel={() => props.setAssignPopup(false)}
      onConfirm={assignAssociate}
    />
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  messaging: state.messaging,
});
export default connect(mapStateToProps, {})(AssignChatPopup);
