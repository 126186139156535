import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import DropdownSelect from '../DropdownSelect';
import FilterChips from '../FilterChips';
import { setClientFilterMyClients } from '../../../reducers/Filters';
import { getRequest } from '../../../core/apiRequests';
import { setAuthStores, setAuthStoreId } from '../../../reducers/auth';
import Button from '../Button';
import { DATE_FORMAT } from '../../Routes/Payments/const';

const FilterSetter = props => {
  const [tempAssoc, setTempAssoc] = useState(props.filterAssociates);
  const [tempStatus, setTempStatus] = useState(props.filterStatus);
  const [tempTags, setTempTags] = useState(props.filterTags);
  const [tempDateRange, setTempDateRange] = useState(props.filterDateRange);
  const [tempStartDate, setTempStartDate] = useState(props.startDate);
  const [tempEndDate, setTempEndDate] = useState(props.endDate);
  const [isDatePicker, setIsDatePicker] = useState(props.startDate || props.endDate);
  const [tempBank, setTempBank] = useState(props.filterBank);

  const setFilterSettings = obj => {
    if (obj.name.includes('tag')) {
      const filteredArr = tempTags.filter(tid => Number(tid) !== Number(obj.value));
      setTempTags([...filteredArr, obj.value]);
    }
    if (obj.name.includes('assoc')) {
      if (tempAssoc.includes('none') || tempAssoc.includes(0)) {
        tempAssoc.shift();
      }
      if (obj.value === 'none' || Number(obj.value) === 0) {
        if (obj.value === 'none') {
          setTempAssoc(['none']);
        } else {
          setTempAssoc([0]);
        }
      } else {
        const filteredArr = tempAssoc.filter(aid => Number(aid) !== Number(obj.value));
        setTempAssoc([...filteredArr, obj.value]);
      }
      if (props.checked) {
        props.setChecked(false);
        setClientFilterMyClients(null);
      }
    }
    if (obj.name.includes('status')) {
      const PLACEHOLDER_VALUES = ['none', 0];
      const { value } = obj;

      if (PLACEHOLDER_VALUES.includes(value)) {
        setTempStatus(value);
      } else {
        if (PLACEHOLDER_VALUES.includes(tempStatus[0])) {
          tempStatus.shift();
        }

        const statusList = tempStatus.includes(value) ? tempStatus : [...tempStatus, value];
        setTempStatus(statusList);
      }
      if (props.checked) {
        props.setChecked(false);
      }
    }
    if (obj.name.includes('bank')) {
      setTempBank([obj.bankName]);
    }
  };

  const tagsPlaceholder = () => {
    if (tempTags.length > 0) {
      return `${tempTags.length} Selected Tag${tempTags.length > 1 ? 's' : ''}`;
    }
    if (props.filterTags.length > 0) {
      return `${props.filterTags.length} Selected Tag${tempTags.length > 1 ? 's' : ''}`;
    }
    return 'All Tags';
  };
  const asctPlaceholder = () => {
    if (tempAssoc.length > 0) {
      if (tempAssoc[0] !== 0 && tempAssoc[0] !== 'none') {
        return `${tempAssoc.length} Selected Associate${tempAssoc.length > 1 ? 's' : ''}`;
      }
      if (tempAssoc[0] === 0) {
        return 'All Assigned Associates';
      }
      return 'No Assigned Associates';
    }
    // setTempAssoc([0]);
    return 'All Associates';
  };

  const statusPlaceholder = () => {
    const NONE = 0;
    const len = tempStatus?.length || 0;

    if (len === 1 && tempStatus[0] === NONE) {
      return 'All Statuses';
    }

    if (!len) {
      setTempStatus([NONE]);
    }

    return `${len} Selected Status${len > 1 ? 'es' : ''}`;
  };

  const bankPlaceholder = () => {
    const NONE = 0;
    const len = tempBank?.length || 0;

    if (len === 1 && tempBank[0] === NONE) {
      return 'Bank Accounts';
    }

    if (!len) {
      setTempBank([NONE]);
    }
    return tempBank;
  };

  // Date Stuff
  const PLACEHOLDER_DATE_RANGE = 'This Year';

  // store stuff
  const history = useHistory();
  useEffect(() => {
    if (props.merchantId && props.stores.length < 2) {
      const allStores = props.role === 'ADMIN';
      const allOption = { id: 0, name: 'All Stores' };
      (async () => {
        const reqObj = {
          params: ['merchant', props.merchantId, 'stores', props.userId],
          query: {
            allStores,
          },
        };
        const data = await getRequest(reqObj);
        if (data && data.length > 0) {
          const sortStores = data?.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          });
          setAuthStores([allOption, ...sortStores]);
          setAuthStoreId(0);
        }
      })();
    }
  }, [props.merchantId]);

  useEffect(() => {
    if (props.storeState.id) {
      if (
        window.location.pathname.toLowerCase().includes('stores/edit-store') ||
        window.location.pathname.toLowerCase().includes('stores/store-dashboard')
      ) {
        if (Number(props.storeState.id) && Number(props.storeState.id) !== Number(props.storeId)) {
          setAuthStoreId(Number(props.storeState.id));
        }
      }
    }
  }, [props.storeState.id]);

  const storeChangeHandler = val => {
    if (window.location.pathname.toLowerCase().includes('stores/edit-store')) {
      if (Number(props.storeState.id) !== Number(val)) {
        history.push(`/Stores/edit-store/${val}`);
      }
    }
    if (window.location.pathname.toLowerCase().includes('stores/store-dashboard')) {
      if (Number(props.storeState.id) !== Number(val)) {
        history.push(`/Stores/store-dashboard/${val}`);
      }
    }
    setAuthStoreId(val);
  };

  return (
    <div className="pos-abs" style={{ top: '26px' }}>
      <div
        className={
          props.showFilters
            ? 'filtersContainer pos-rel nav-z-101 bg-white m-top-50 border-r3'
            : 'filtersContainer pos-abs'
        }
      >
        <FontAwesomeIcon
          className="m-left-10"
          icon={['fas', 'caret-up']}
          color="#ffffff"
          size="3x"
          style={{ position: 'absolute', top: '-25px' }}
        />

        <div className="flex-col-left p-right-15 p-left-15 p-top-15">
          <div
            className="w-100-P flex-row-nospacebetween-wrap"
            style={{
              alignItems: 'baseline',
              gridGap: '15px',
              justifyContent: 'flex-start',
            }}
          >
            {props.dateRangeList ? (
              isDatePicker ? (
                <div className="align-left">
                  <h4 className="fs-12 fw-500">Date Last Updated</h4>

                  <div className="flex-row-spacebetween-wrap" style={{ gap: 16 }}>
                    <div className="flex-col-left">
                      <label htmlFor="filter-date-start" className="fs-12 fw-400">
                        Start Date
                      </label>
                      <DatePicker
                        id="filter-date-start"
                        className="input-height focus-input-outline-blue p-left-8 rem-pad-width common_dropdownStyle fs-14"
                        maxDate={tempEndDate ? moment(tempEndDate).toDate() : moment().toDate()}
                        selected={tempStartDate ? moment(tempStartDate).toDate() : undefined}
                        onChange={date => {
                          const startDate = moment(date).format(DATE_FORMAT.SHORT_DATE_SLASHES);
                          setTempStartDate(startDate);
                        }}
                        showMonthDropdown
                        showYearDropdown
                        todayButton="Today"
                        dropdownMode="select"
                      />
                    </div>

                    <div className="flex-col-left">
                      <label htmlFor="filter-date-end" className="fs-12 fw-400">
                        End Date
                      </label>
                      <DatePicker
                        id="filter-date-end"
                        className="input-height focus-input-outline-blue p-left-8 rem-pad-width common_dropdownStyle fs-14"
                        minDate={tempStartDate ? moment(tempStartDate).toDate() : undefined}
                        maxDate={moment().toDate()}
                        selected={tempEndDate ? moment(tempEndDate).toDate() : undefined}
                        onChange={date => {
                          const endDate = moment(date).format(DATE_FORMAT.SHORT_DATE_SLASHES);
                          setTempEndDate(endDate);
                        }}
                        showMonthDropdown
                        showYearDropdown
                        todayButton="Today"
                        dropdownMode="select"
                      />
                    </div>
                  </div>
                  <Button
                    classname="blue-link no-wrap fs-12 fw-400 padding-0"
                    styleProps={{ width: 'auto' }}
                    onclick={() => {
                      setTempStartDate(null);
                      setTempEndDate(null);
                      setIsDatePicker(false);
                    }}
                  >
                    Select quick filters
                  </Button>
                </div>
              ) : (
                <div className="align-left">
                  <h4 className="fs-12 fw-500">Date Last Updated</h4>
                  <DropdownSelect
                    classProps="w-100-P w-222 align-left h-50"
                    options={props.dateRangeList}
                    placeholder={PLACEHOLDER_DATE_RANGE}
                    value={tempDateRange}
                    onChange={({ value }) => {
                      setTempDateRange(value);
                    }}
                  />
                  <Button
                    classname="blue-link no-wrap fs-12 padding-0 margin-0"
                    styleProps={{ width: 'auto' }}
                    onclick={() => {
                      setTempDateRange(null);
                      setIsDatePicker(true);
                    }}
                  >
                    Select custom dates
                  </Button>
                </div>
              )
            ) : null}

            <div className="divider w-100-P" style={{ width: '100%' }} />

            {props.tagFilterList ? (
              <DropdownSelect
                classProps="w-100-P w-222 align-left h-50"
                options={props.tagFilterList}
                placeholder={tagsPlaceholder()}
                value={null}
                selectProps={{
                  formatOptionLabel: obj => {
                    if (tempTags.includes(obj.value)) {
                      return (
                        <div style={{ alignItems: 'center' }} className="flex-row-nospacebetween-nowrap">
                          <FontAwesomeIcon icon={['fal', 'check']} className="fs-12 m-right-9 m-left-4" />
                          <span className="p-top-3">
                            {obj.label.length > 28 ? `${obj.label.substr(0, 28)}...` : obj.label}
                          </span>
                        </div>
                      );
                    }
                    if (obj.tagColor) {
                      return (
                        <div style={{ alignItems: 'center' }} className="flex-row-nospacebetween-nowrap">
                          <div style={{ backgroundColor: obj.tagColor }} className="circle h-20 w-20 m-right-4" />
                          <span className="p-top-3">
                            {obj.label.length > 28 ? `${obj.label.substr(0, 28)}...` : obj.label}
                          </span>
                        </div>
                      );
                    }
                    return obj.label.length > 28 ? `${obj.label.substr(0, 28)}...` : obj.label;
                  },
                }}
                onChange={obj => {
                  setFilterSettings({
                    ...obj,
                    name: 'tag',
                  });
                }}
              />
            ) : null}
            {props.asctFilterList ? (
              <DropdownSelect
                classProps="w-100-P w-222 align-left h-50"
                options={props.asctFilterList}
                placeholder={asctPlaceholder()}
                value={null}
                selectProps={{
                  formatOptionLabel: obj => {
                    if (tempAssoc.includes(obj.value)) {
                      return (
                        <div style={{ alignItems: 'center' }} className="flex-row-nospacebetween-nowrap">
                          <FontAwesomeIcon icon={['fal', 'check']} className="fs-12 m-right-8" />
                          <span className="p-top-3">{obj.label}</span>
                        </div>
                      );
                    }
                    return obj.label;
                  },
                }}
                onChange={obj => {
                  setFilterSettings({
                    ...obj,
                    name: 'assoc',
                  });
                }}
              />
            ) : null}
            {props.statusFilterList ? (
              <DropdownSelect
                classProps="w-100-P w-222 align-left h-50"
                options={props.statusFilterList}
                placeholder={statusPlaceholder()}
                value={null}
                selectProps={{
                  formatOptionLabel: obj => {
                    if (tempStatus.includes(obj.value)) {
                      return (
                        <div style={{ alignItems: 'center' }} className="flex-row-nospacebetween-nowrap">
                          <FontAwesomeIcon icon={['fal', 'check']} className="fs-12 m-right-8" />
                          <span className="p-top-3">{obj.label}</span>
                        </div>
                      );
                    }
                    return obj.label;
                  },
                }}
                onChange={obj => {
                  setFilterSettings({
                    ...obj,
                    name: 'status',
                  });
                }}
              />
            ) : null}
            {props.bankFilterList ? (
              <div className="align-left">
                {/* <h4 className="m-top-0 fs-12 fw-500">Bank Account</h4> */}

                <DropdownSelect
                  classProps="w-100-P w-222 align-left h-50"
                  options={props.bankFilterList}
                  placeholder={bankPlaceholder()}
                  value={null}
                  selectProps={{
                    formatOptionLabel: obj => {
                      if (tempBank.includes(obj.bankName)) {
                        return (
                          <div style={{ alignItems: 'center' }} className="flex-row-nospacebetween-nowrap">
                            <FontAwesomeIcon icon={['fal', 'check']} className="fs-12 m-right-8" />
                            <span className="p-top-3">{obj.bankName}</span>
                          </div>
                        );
                      }
                      return obj.bankName;
                    },
                  }}
                  onChange={obj => {
                    setFilterSettings({
                      ...obj,
                      name: 'bank',
                    });
                  }}
                />
              </div>
            ) : null}
            {/* store dropdown */}
            {!props.hideStoreFilter ? (
              props.stores.length > 0 ? (
                <DropdownSelect
                  classProps="w-100-P w-222 align-left h-50"
                  options={props.stores.map((st, i) => ({
                    label: st.name,
                    value: st.id,
                  }))}
                  value={props.storeId}
                  onChange={obj => {
                    storeChangeHandler(obj.value);
                  }}
                  placeholder="Select a Store..."
                />
              ) : (
                <div className="w-267 inputHeight rem-pad-width common_inputStyle align-left p-top-10">
                  <span className="gray fs-14">No Store Access</span>
                </div>
              )
            ) : null}
          </div>
          <div className="w-100-P">
            <FilterChips
              tagList={props.tagList}
              tagFilterList={props.tagFilterList}
              filterTags={tempTags}
              setFilterTags={setTempTags}
              setTempTag={setTempTags}
              asctFilterList={props.asctFilterList}
              filterAssociates={tempAssoc}
              setFilterAssociates={setTempAssoc}
              setTempAssoc={setTempAssoc}
              myClients={props.myClients}
              setMyClients={setClientFilterMyClients}
              storeFilterList={props.stores}
              storeId={props.storeId}
              statusFilterList={props.statusFilterList}
              filterStatus={tempStatus}
              setFilterStatus={setTempStatus}
              setTempStatus={setTempStatus}
              dateRangeList={props.dateRangeList}
              filterDateRange={tempDateRange}
              setFilterDateRange={setTempDateRange}
              startDate={tempStartDate}
              setFilterStartDate={setTempStartDate}
              endDate={tempEndDate}
              setFilterEndDate={setTempEndDate}
              bankFilterList={props.bankFilterList}
              filterBank={tempBank}
              setFilterBank={setTempBank}
              setTempBank={setTempBank}
            />
          </div>
          <div className="m-top-30 divider w-100-P" style={{ width: '100%' }} />
          <div
            className="w-100-P flex-row-nospacebetween-wrap m-btm-25"
            style={{
              bottom: '30px',
              right: '15px',
              justifyContent: 'flex-end',
              gridGap: '15px',
            }}
          >
            <button className="cancel-button" onClick={() => props.setShowFilters(!props.showFilters)}>
              Cancel
            </button>
            <button
              className="save-button"
              onClick={() => {
                props.saveFilters({
                  tempTags,
                  tempAssoc,
                  tempDateRange,
                  tempStartDate,
                  tempEndDate,
                  tempStatus,
                  tempBank,
                });
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  storeState: state.stores,
});

export default connect(mapStateToProps, {})(FilterSetter);
