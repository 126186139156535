import { PauseCircleOutlined } from '@ant-design/icons';
import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import Axios from 'axios';
import { postRequest } from '../../../../../core/apiRequests';

const InputAIButton = ({
  chatMessages,
  merchantId,
  setInputText,
  inputValue,
  storeId,
  clientId,
  setShowEllipses,
  source,
  queryEdison,
  leadObj,
  setMsgRegen,
  chatType,
  chatId,
}) => {
  const [buttonState, setButtonState] = useState(null);
  const [lastChatGPTResponse, setLastChatGPTResponse] = useState(null);
  const [hovering, setHovering] = useState(false);
  const [querying, setQuerying] = useState(false); // this is the state of the button, not the state of the chatGPT response
  const cancelSource = useRef(null);

  const buttonStyles = hovering
    ? {
        backgroundColor: '#F2F3F6',
        color: '#818181',
      }
    : {
        color: '#818181',
      };
  const buttonStylesPause = hovering
    ? {
        backgroundColor: '#F2F3F6',
        color: '#BDBDBD',
      }
    : {
        color: '#BDBDBD',
      };

  const buttonStylesRegen = {
    color: '#818181',
  };

  const clickHandler = nextState => {
    if (!nextState) {
      setButtonState(null);
    } else {
      setButtonState(nextState); // set to 'querying' button
      setInputText(''); // this is actual input text
      setLastChatGPTResponse(null); // this this is previousState, that enables us to compare to currentState
      if (nextState === 'cancelQuery') {
        setShowEllipses('noShowEllipses');
        setQuerying(false);
        if (cancelSource.current) {
          cancelSource.current.cancel('User cancelled the request');
        }
      }
      if (nextState === 'querying') {
        setShowEllipses('showEllipses');
        if (source === 'inbox') {
          queryChatGPT(); // make the call to the Dashboard backend, that will query the chatGPT API
        } else {
          queryEdisonAI();
          setMsgRegen(true);
        }
      }
    }
  };

  const queryEdisonAI = () => {
    setQuerying(true);
    setTimeout(() => {
      queryEdison(leadObj, leadObj?.type.prompt)
        .then(response => {
          if (response === true) {
            const text = leadObj?.type.message;
            updateChatText(text);
            return response;
          }
          return false;
        })
        .catch(err => {
          console.error('No message in EDISON response', err);
        });
    }, 1000);
  };

  const getLast25Messages = () => {
    return chatMessages
      .filter(item => item.message && typeof item.message === 'string')
      .map(item => {
        if (item.client_id === item.user_id) {
          return {
            role: 'user',
            content: item.message,
          };
        }

        return {
          role: 'assistant',
          content: item.message,
        };
      })
      .slice(0, 15)
      .reverse();
  };

  const updateChatText = text => {
    setQuerying(false);
    const newText = text ? text.trim() : '';
    setInputText(newText || '');
    setLastChatGPTResponse(newText || '');
    setButtonState(newText ? 'querying' : null);
  };

  const queryChatGPT = async () => {
    try {
      cancelSource.current = Axios.CancelToken.source();
      const reqObj = {
        params: ['chatMessages', merchantId, 'generateChatResponse'],
        data: { chatMessages: getLast25Messages(), storeId, clientId, chatType, chatId },
        loadingStatusOff: true,
        cancelToken: cancelSource.current.token,
      };
      setQuerying(true);
      const data = await postRequest(reqObj);
      if (data?.message ?? data?.text) {
        setShowEllipses('noShowEllipses');
        const text = data?.message ?? data?.text;
        updateChatText(text);
      } else {
        throw new Error('No message in response');
      }
    } catch (e) {
      updateChatText(false);
      console.error('[queryChatGPT] ', e);
    }
  };

  const getComponent = () => {
    if (source === 'inbox') {
      if (!buttonState) {
        return passiveIcon;
      }
      if (buttonState === 'querying' && lastChatGPTResponse === inputValue) {
        return refreshIcon;
      }
      if (querying) {
        return queryingIcon;
      }
      return passiveIcon;
    }
    if (source === 'edison') {
      if (!buttonState) {
        return refreshIcon;
      }
      if (buttonState === 'querying' && lastChatGPTResponse === inputValue) {
        return refreshIcon;
      }
      if (querying) {
        return queryingIcon;
      }
      return refreshIcon;
    }
  };
  const getComponentColorState = () => {
    if (!buttonState) {
      return false;
    }
    if (buttonState === 'querying' && lastChatGPTResponse === inputValue) {
      return true;
    }
    if (querying) {
      return true;
    }
    return false;
  };

  const queryingIcon = (
    <Tooltip
      overlayInnerStyle={{ borderRadius: '10px', padding: '9px 6px 0px 6px', backgroundColor: '#454545' }}
      placement="bottom"
      title="Stop generating"
    >
      <PauseCircleOutlined
        className="fs-15 pointer"
        onClick={() => clickHandler('cancelQuery')}
        style={buttonStylesPause}
      />
    </Tooltip>
  );
  const passiveIcon = (
    <Tooltip
      overlayInnerStyle={{ borderRadius: '10px', padding: '9px 6px 0px 6px', backgroundColor: '#454545' }}
      placement="bottom"
      title="AI Response"
    >
      <img
        style={buttonStyles}
        className="fs-15 pointer"
        onClick={() => clickHandler('querying')}
        src="https://dashboard-v2-images.s3.amazonaws.com/gpt-button-1.svg"
      />
    </Tooltip>
  );
  const refreshIcon = (
    <Tooltip
      overlayInnerStyle={{ borderRadius: '10px', padding: '9px 6px 0px 6px' }}
      placement="bottom"
      title="Regenerate"
    >
      <FontAwesomeIcon
        style={buttonStylesRegen}
        className="fs-15 pointer"
        icon={['fa', 'refresh']}
        onClick={() => clickHandler('querying')}
      />
    </Tooltip>
  );

  return (
    <div
      className="profile_upload_div pointer"
      onAbort={clickHandler}
      style={
        hovering
          ? {
              cursor: 'pointer',
              backgroundColor: getComponentColorState() ? 'rgba(242, 243, 246, .5)' : '#F2F3F6',
              marginLeft: '7px',
              width: '29px',
              height: '29px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
            }
          : {
              cursor: 'pointer',
              marginLeft: '7px',
              width: '29px',
              height: '29px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
            }
      }
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {getComponent()}
    </div>
  );
};
export default InputAIButton;
