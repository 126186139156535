import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import Layout from '../../../Layout/StandardLayout';
import { getVideos } from '../../../../core/apiRequests';
import '../help.css';
// import outsideClick from "./outsideClick"
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import PopOverVideo from '../../../Layout/PopOverVideo';
import { useFlags } from 'launchdarkly-react-client-sdk';

const TrainingVideos = props => {
  const [trainingVideos, setTrainingVideos] = useState({});
  const [selectedVideo, setSelectedVideo] = useState('');
  const [selectedPoster, setSelectedPoster] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const flags = useFlags();
  const showTest = flags.testFlag;

  // const flags = props.auth.merchantFlags;
  // const showTest = !flags?.life_events_automation?.enabled;

  // get video data
  useEffect(() => {
    if (props.auth.merchantId) {
      let data;
      data = async () => {
        const data = await getVideos('merchant', props.auth.merchantId, 'trainingVideos');
        setTrainingVideos(data);
      };
      data();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.merchantId]);

  // load video and set styles for overlay
  const loadVideo = (url, poster) => {
    setSelectedVideo(url);
    setSelectedPoster(poster);
    setOpenModal(true);
  };

  const displayData = useMemo(() => {
    const displayData = [];
    for (const sectionName in trainingVideos) {
      const videoData = [];
      for (const video of trainingVideos[sectionName]) {
        const vidUrl = video.web_url;
        const posterUrl = video.thumb_url;
        const vidDuration = video.duration;
        const minutes = Math.floor(vidDuration / 60);
        let seconds = (vidDuration % 60).toString();
        if (seconds.length < 2) {
          seconds = `0${seconds}`;
        }
        videoData.push(
          <div className="border-r8-grey m-btm-15 m-right-15 videoData" key={`vrow${videoData.length}`}>
            {/* card border */}
            <div>
              <button
                className="pointer pos-rel videoBtn"
                title="Play Video"
                data-toggle="modal"
                data-target="#videoModal"
                type="button"
                onClick={() => {
                  loadVideo(vidUrl, posterUrl);
                }}
              >
                {/* img class style card top  */}
                <img className="thumbNail" src={video.thumb_url} alt="" />
                <img className="pos-abs videoBtnImg" src="https://dashboard-v2-images.s3.amazonaws.com/play.svg" />
              </button>
              {/* card body */}
              <div>
                <p className="fs-14 align-left m-left-10 videoTextHead">{video.name}</p>
                <p className="fs-13 align-left m-left-10 gray videoTextSub">{`${minutes}:${seconds}`}</p>
              </div>
            </div>
          </div>,
        );
      }
      displayData.push(
        <div key={`row${displayData.length}`}>
          <div>
            <div>
              <div>
                <p className="vidSectionHeader">{sectionName}</p>
              </div>
              {/* card body */}
              <div className="videoBody">
                <div className="mainVideoBody">{videoData}</div>
              </div>
            </div>
          </div>
        </div>,
      );
    }
    return displayData;
  }, [trainingVideos]);

  const onModalClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <Layout
      title="Training Videos"
      description={`Learn how to use Clientbook and brush up on some ${!showTest ? 'client interaction' : ''} basics`}
      breadcrumb="Help >> Training Videos"
      breadCrumbs={[{ title: 'Help' }, { title: 'Training Videos' }]}
      classProps="standard-width-with-sidebar"
    >
      <div>
        {displayData}
        <PopOverVideo
          open={openModal}
          videoSrc={selectedVideo}
          videoPosterSrc={selectedPoster}
          onClose={onModalClose}
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(TrainingVideos);
