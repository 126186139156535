import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setAuthEdison } from '../../../../reducers/auth';
import { differenceInHours, formatISO } from 'date-fns';
import { updatedAiAssistantViewDate } from '../apiRequests';

const EdisonToolTip = props => {
  const { leadObj, setShowLead, setShowToolTip, userId, AIActivity, vendorItemViewHandler } = props;

  function showEdisonToolTipFunc(e) {
    e.preventDefault();
    e.stopPropagation();
    setShowLead(true);
    setShowToolTip(false);
    updatedAiAssistantViewDate(userId, setAuthEdison);
    vendorItemViewHandler();
    AIActivity({ eventType: 'activity_viewed', msgRegen: false, msgEdit: false, resultCode: null });
  }

  function closeEdisonToolTip(e) {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem('aiToolTip', formatISO(new Date()));
    setShowToolTip(false);
  }

  const showEdisonToolTip = useMemo(() => {
    const lastClosed = localStorage.getItem('aiToolTip');
    if (lastClosed) {
      const lastClosedDate = new Date(lastClosed);
      const today = new Date();
      if (differenceInHours(today, lastClosedDate) > 24) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [localStorage])

  return (
    (leadObj?.client?.name && showEdisonToolTip ?
      <div className="pos-abs edisonToolTipOuterDiv" style={{ top: '50px' }}>
        <FontAwesomeIcon
          onClick={e => closeEdisonToolTip(e)}
          className="pos-abs pointer"
          icon="fa-solid fa-x"
          style={{ color: '#fff', right: '15px', top: '15px', height: '16px', width: '16px' }}
          id="edisonToolTipClose"
        />
        <div className="align-left">
          <span
            className="white fs-13px fw-500 d-block line-clamp-1"
            style={{ width: '235px' }}
          >{`New AI Insight for ${leadObj?.client?.name}`}</span>
        </div>
        <FontAwesomeIcon
          icon="fa-solid fa-caret-up"
          className="pos-rel"
          size="xl"
          style={{ color: '#000', left: 2, bottom: '48px' }}
        />
      </div>: ''
  )
  );
};

export default EdisonToolTip;
