import React from 'react';
import { notification } from 'antd';
import { noGoodDirtyRottenChatIdQuery as nGDRCQ } from './noGoodDirtyRottenChatIdQuery';
import { eventSubject, eventReadSubject, messageStatusSubject } from '../../../../core/events';

import { setMsgClient, getClientChat, updateConversationLists } from '../../../../reducers/messaging';

import { CLIENT_INBOX_PATH, LOGIN_PATH, MY_CLIENT_INBOX_PATH } from '../shared/constants';

import MessageNotificationMessage from '../components/MessageNotificationMessage';
import MessageNotificationDescription from '../components/MessageNotificationDescription';
import MessageNotificationButton from '../components/MessageNotificationButton';
import MessageNotificationCloseIcon from '../components/MessageNotificationCloseIcon';
import MessageNotificationIcon from '../components/MessageNotificationIcon';
import PaymentNotificationIcon from '../components/PaymentNotificationIcon';
import ConnectionNotificationCloseIcon from '../components/ConnectionNotificationCloseIcon';
import { getRequest } from '../../../../core/apiRequests';
import { ToastMessage, ToastTitle } from '../../../../context/Notifications/components/ToastComponents';
import { sendDesktopNotification } from '../../../../utils/sendDesktopNotification';
import { setAuthSelStore } from '../../../../reducers/auth';
import { setNewMessages } from '../../../../reducers/navigation';

const messageNotification = (messageObject, history, props) => {
  const { chat_message_id, client_id, store_id, store_name } = messageObject;
  const { merchantId } = props;

  const handleClick = async () => {
    const chatId = await nGDRCQ(chat_message_id, client_id, merchantId);

    const storeId = props.stores.find(store => store.name === store_name)?.id || store_id;

    if (chatId) {
      history.push(`${MY_CLIENT_INBOX_PATH}`);
      setMsgClient({ client_id, chat_id: chatId });
      getClientChat({ client_id, chat_id: chatId });
      setAuthSelStore(storeId);
      setTimeout(() => {
        updateConversationLists({ ...messageObject, clickOnSocket: true, lastReadDateTime: 5 });
      }, 2000);
    }
  };

  notification.config({ maxCount: 5 });

  notification.open({
    top: 60,
    message: <ToastTitle client_name={messageObject.client_name} type="sms" />,
    description: <ToastMessage msg={messageObject.msg} type="sms" />,
    btn: <MessageNotificationButton onClick={handleClick} />,
    closeIcon: <MessageNotificationCloseIcon />,
    icon: <MessageNotificationIcon />,
    duration: 5,
    style: {
      padding: '16px',
      fontFamily: 'Poppins',
      width: '430px',
      marginTop: '16px',
    },
    className: 'pos-rel pointer',
    onClick: handleClick,
  });
};
const instagramNotification = (messageObject, history, props) => {
  const { chatId } = messageObject;

  const handleClick = async () => {
    const obj = {
      assignedAssociates: [],
      chat_id: chatId,
      client: {
        id: null,
        name: messageObject.instagramUserName,
        assignedAssociates: [],
        type: 'I',
        chat_id: chatId,
      },
      assignedAssociates: [],
      id: messageObject.chatId,
      clientReplyDateTime: null,
      client_id: null,
      isAnonymous: false,
      isOpen: messageObject.chatIsOpen,
      isUnread: true,
      lastMessage: messageObject.messageText,
      lastReadDateTime: null,
      lastSentTimestamp: messageObject.sentTimestamp,
      merchantId: messageObject.merchantId,
      name: messageObject.instagramUserName,
      storeId: messageObject.storeId,
      store_id: messageObject.storeId,
      type: 'I',
      clickOnSocket: true,
    };

    if (chatId) {
      history.push(`${MY_CLIENT_INBOX_PATH}`);
      getClientChat(obj);
      setAuthSelStore(messageObject.storeId);
      setTimeout(() => {
        updateConversationLists({
          ...obj,
          observers: messageObject?.observers || [],
          clickOnSocket: true,
          lastReadDateTime: 5,
        });
      }, 2000);
    }
  };

  notification.config({ maxCount: 5 });

  notification.open({
    top: 60,
    message: <ToastTitle type="facebook" />,
    description: <ToastMessage msg={messageObject.messageText} type="sms" />,
    btn: <MessageNotificationButton onClick={handleClick} />,
    closeIcon: <MessageNotificationCloseIcon />,
    icon: <MessageNotificationIcon />,
    duration: 5,
    style: {
      padding: '16px',
      fontFamily: 'Poppins',
      width: '430px',
      marginTop: '16px',
    },
    className: 'pos-rel pointer',
    onClick: handleClick,
  });
};

const facebookNotification = (messageObject, history, props) => {
  const { chatId } = messageObject;

  const handleClick = async () => {
    const obj = {
      assignedAssociates: [],
      chat_id: chatId,
      client: {
        id: null,
        name: messageObject.facebookUserName,
        assignedAssociates: [],
        type: 'F',
        chat_id: chatId,
      },
      assignedAssociates: [],
      id: messageObject.chatId,
      clientReplyDateTime: null,
      client_id: null,
      isAnonymous: false,
      isOpen: messageObject.chatIsOpen,
      isUnread: true,
      lastMessage: messageObject.messageText,
      lastReadDateTime: null,
      lastSentTimestamp: messageObject.sentTimestamp,
      merchantId: messageObject.merchantId,
      name: messageObject.facebookUserName,
      storeId: messageObject.storeId,
      store_id: messageObject.storeId,
      type: 'F',
      clickOnSocket: true,
    };

    if (chatId) {
      history.push(`${MY_CLIENT_INBOX_PATH}`);
      getClientChat(obj);
      setAuthSelStore(messageObject.storeId);
      setTimeout(() => {
        updateConversationLists({
          ...obj,
          observers: messageObject?.observers || [],
          clickOnSocket: true,
          lastReadDateTime: 5,
        });
      }, 2000);
    }
  };

  notification.config({ maxCount: 5 });

  notification.open({
    top: 60,
    message: <ToastTitle type="facebook" />,
    description: <ToastMessage msg={messageObject.messageText} type="sms" />,
    btn: <MessageNotificationButton onClick={handleClick} />,
    closeIcon: <MessageNotificationCloseIcon />,
    icon: <MessageNotificationIcon />,
    duration: 5,
    style: {
      padding: '16px',
      fontFamily: 'Poppins',
      width: '430px',
      marginTop: '16px',
    },
    className: 'pos-rel pointer',
    onClick: handleClick,
  });
};

const googleNotification = (messageObject, history, props) => {
  const { reviewId } = messageObject;

  const handleClick = async () => {
    const obj = {
      assignedAssociates: [],
      chat_id: reviewId,
      client: {
        id: null,
        name: messageObject.googleReviewDisplayName,
        assignedAssociates: [],
        type: 'G',
        chat_id: reviewId,
      },
      assignedAssociates: [],
      id: messageObject.reviewId,
      clientReplyDateTime: null,
      client_id: null,
      isAnonymous: false,
      isOpen: messageObject.isOpen,
      isUnread: true,
      lastMessage: messageObject.comment,
      lastReadDateTime: null,
      lastSentTimestamp: messageObject.sentTimestamp,
      merchantId: messageObject.merchantId,
      name: messageObject.googleReviewDisplayName,
      storeId: messageObject.storeId,
      store_id: messageObject.storeId,
      type: 'G',
      clickOnSocket: true,
    };

    if (reviewId) {
      history.push(`${MY_CLIENT_INBOX_PATH}`);
      getClientChat(obj);
      setAuthSelStore(messageObject.storeId);
      setTimeout(() => {
        updateConversationLists({
          ...obj,
          observers: messageObject?.observers || [],
          clickOnSocket: true,
          lastReadDateTime: 5,
        });
      }, 2000);
    }
  };

  notification.config({ maxCount: 5 });

  notification.open({
    top: 60,
    message: <ToastTitle type="google" />,
    description: <ToastMessage msg={messageObject.comment} type="google" rating={messageObject.rating} />,
    btn: <MessageNotificationButton onClick={handleClick} />,
    closeIcon: <MessageNotificationCloseIcon />,
    icon: <MessageNotificationIcon />,
    duration: 5,
    style: {
      padding: '16px',
      fontFamily: 'Poppins',
      width: '430px',
      marginTop: '16px',
    },
    className: 'pos-rel pointer',
    onClick: handleClick,
  });
};

const paymentNotification = messageObject => {
  const handleClick = () => {
    window.location.replace(messageObject.payUrl);
  };

  notification.config({ maxCount: 5 });

  notification.open({
    top: 60,
    duration: 5,
    message: <MessageNotificationMessage message={messageObject.title} />,
    description: <MessageNotificationDescription description={messageObject.body} />,
    btn: <MessageNotificationButton onClick={handleClick} />,
    closeIcon: <MessageNotificationCloseIcon />,
    icon: <PaymentNotificationIcon />,
    style: {
      padding: '16px',
      fontFamily: 'Poppins',
      width: '385px',
      marginTop: '16px',
    },
    className: 'pos-rel pointer',
    onClick: handleClick,
  });
};

export const messageHandler = async (msgObj, history, props) => {
  try {
    const messageObject = msgObj;
    const { pathname } = window.location;
    const { newMessages, setNewMessages, userId, addNewMessageId } = props;
    const { client_id, observers, direction, store_id, store_name, client_associate_id } = messageObject;

    if (messageObject.direction === 'INBOUND') {
      // this is for weird edge cases where stores may have the same phone number
      // or duplicate clients with a shared phone number
      const configObj = {
        params: ['chats', 'getChatClientStoreId', client_id],
        loadingStatusOff: true,
      };
      const storeObj = await getRequest(configObj); // a really fast query to get the clients store_id
      if (storeObj && storeObj.store_id) {
        // if one is found,  use it. Else use the default one
        messageObject.store_id = storeObj.store_id;
        messageObject.inbound_notify_all = storeObj.inbound_notify_all;
        messageObject.notifyAssociates = storeObj.notifyAssociates;
      }
    }

    if (pathname) {
      const currentPath = pathname;

      if (currentPath.includes(LOGIN_PATH)) return;

      eventSubject.next(msgObj);

      let clientObservers = [];
      if (observers) clientObservers = [...observers];
      if (client_associate_id) clientObservers.push(client_associate_id);

      let sendNotification = false;
      if (clientObservers.length === 0) {
        if (messageObject.inbound_notify_all === 0) {
          if (messageObject.notifyAssociates.includes(userId)) {
            sendNotification = true;
          }
        } else {
          sendNotification = true;
        }
      } else if (clientObservers.includes(userId)) {
        sendNotification = true;
      }

      if(clientObservers.includes(userId) && direction === 'INBOUND') {
        addNewMessageId(client_id);
      }

      if (sendNotification && ((direction === 'INBOUND' && pathname !== CLIENT_INBOX_PATH) || window.document.visibilityState === 'hidden')) {
        if (Array.isArray(newMessages)) {
          setNewMessages([...newMessages, client_id]);
        }
        const timeout = setTimeout(() => {
          messageNotification(messageObject, history, props);
          const notification = sendDesktopNotification({
            title: `New SMS from ${messageObject.client_name}`,
            message: messageObject.msg,
          });
          if (notification) {
            notification.onclick = () => {
              nGDRCQ(messageObject.chat_message_id, client_id, props.merchantId).then(chatId => {
                const storeId = props.stores.find(store => store.name === store_name)?.id || store_id;
                if (chatId) {
                  history.push(`${MY_CLIENT_INBOX_PATH}`);
                  setMsgClient({ client_id, chat_id: chatId });
                  getClientChat({ client_id, chat_id: chatId });
                  setAuthSelStore(storeId);
                  setTimeout(() => {
                    updateConversationLists({ ...messageObject, clickOnSocket: true, lastReadDateTime: 5 });
                  }, 2000);
                }
              });
            };
          }
        }, 1000);
        eventReadSubject.subscribe(clear => clear && clearTimeout(timeout));
      }
    }
  } catch (e) {
    console.error('[socketRouter] Error: ', e);
  }
};

export const facebookHandler = async (msgObj, history, props) => {
  try {
    const messageObject = msgObj;
    const { pathname } = window.location;
    const { newMessages } = props;
    const { chatId, direction } = messageObject;

    if (pathname) {
      const currentPath = pathname;

      if (currentPath.includes(LOGIN_PATH)) return;

      eventSubject.next(msgObj);

      if ((direction === 'INBOUND' && pathname !== CLIENT_INBOX_PATH) || window.document.visibilityState === 'hidden') {
        if (Array.isArray(newMessages)) {
          setNewMessages([...newMessages, chatId]);
        }
        const timeout = setTimeout(() => {
          facebookNotification(messageObject, history, props);
          const notification = sendDesktopNotification({
            title: 'New Facebook message',
            message: messageObject.messageText,
          });
          if (notification) {
            notification.onclick = () => {
              const obj = {
                assignedAssociates: [],
                chat_id: chatId,
                client: {
                  id: null,
                  name: messageObject.facebookUserName,
                  assignedAssociates: [],
                  type: 'F',
                  chat_id: chatId,
                },
                assignedAssociates: [],
                id: messageObject.chatId,
                clientReplyDateTime: null,
                client_id: null,
                isAnonymous: false,
                isOpen: messageObject.chatIsOpen,
                isUnread: true,
                lastMessage: messageObject.messageText,
                lastReadDateTime: null,
                lastSentTimestamp: messageObject.sentTimestamp,
                merchantId: messageObject.merchantId,
                name: messageObject.facebookUserName,
                storeId: messageObject.storeId,
                store_id: messageObject.storeId,
                type: 'F',
                clickOnSocket: true,
              };
              if (chatId) {
                history.push(`${MY_CLIENT_INBOX_PATH}`);
                getClientChat(obj);
                setAuthSelStore(messageObject.storeId);
                setTimeout(() => {
                  updateConversationLists({
                    ...obj,
                    observers: messageObject?.observers || [],
                    clickOnSocket: true,
                    lastReadDateTime: 5,
                  });
                }, 2000);
              }
            };
          }
        }, 1000);
        eventReadSubject.subscribe(clear => clear && clearTimeout(timeout));
      }
    }
  } catch (e) {
    console.error('[socketRouter] Error: ', e);
  }
};

export const instagramHandler = async (msgObj, history, props) => {
  try {
    const messageObject = msgObj;
    const { pathname } = window.location;
    const { newMessages } = props;
    const { chatId, direction } = messageObject;

    if (pathname) {
      const currentPath = pathname;

      if (currentPath.includes(LOGIN_PATH)) return;

      eventSubject.next(msgObj);

      if ((direction === 'INBOUND' && pathname !== CLIENT_INBOX_PATH) || window.document.visibilityState === 'hidden') {
        if (Array.isArray(newMessages)) {
          setNewMessages([...newMessages, chatId]);
        }
        const timeout = setTimeout(() => {
          instagramNotification(messageObject, history, props);
          const notification = sendDesktopNotification({
            title: 'New Instagram message',
            message: messageObject.messageText,
          });
          if (notification) {
            notification.onclick = () => {
              const obj = {
                assignedAssociates: [],
                chat_id: chatId,
                client: {
                  id: null,
                  name: messageObject.instagramUserName,
                  assignedAssociates: [],
                  type: 'I',
                  chat_id: chatId,
                },
                assignedAssociates: [],
                id: messageObject.chatId,
                clientReplyDateTime: null,
                client_id: null,
                isAnonymous: false,
                isOpen: messageObject.chatIsOpen,
                isUnread: true,
                lastMessage: messageObject.messageText,
                lastReadDateTime: null,
                lastSentTimestamp: messageObject.sentTimestamp,
                merchantId: messageObject.merchantId,
                name: messageObject.instagramUserName,
                storeId: messageObject.storeId,
                store_id: messageObject.storeId,
                type: 'I',
                clickOnSocket: true,
              };
              if (chatId) {
                history.push(`${MY_CLIENT_INBOX_PATH}`);
                getClientChat(obj);
                setAuthSelStore(messageObject.storeId);
                setTimeout(() => {
                  updateConversationLists({
                    ...obj,
                    observers: messageObject?.observers || [],
                    clickOnSocket: true,
                    lastReadDateTime: 5,
                  });
                }, 2000);
              }
            };
          }
        }, 1000);
        eventReadSubject.subscribe(clear => clear && clearTimeout(timeout));
      }
    }
  } catch (e) {
    console.error('[socketRouter] Error: ', e);
  }
};

export const googleHandler = async (msgObj, history, props) => {
  try {
    const messageObject = msgObj;
    const { pathname } = window.location;
    const { newMessages } = props;
    const { chatId } = messageObject;

    if (pathname) {
      const currentPath = pathname;

      if (currentPath.includes(LOGIN_PATH)) return;

      eventSubject.next(msgObj);

      if (pathname !== CLIENT_INBOX_PATH || window.document.visibilityState === 'hidden') {
        if (Array.isArray(newMessages)) {
          setNewMessages([...newMessages, chatId]);
        }
        const timeout = setTimeout(() => {
          googleNotification(messageObject, history, props);
          sendDesktopNotification({
            title: 'New Google Review',
            message: `messageObject.comment ${messageObject?.rating ? `Rating: ${messageObject.rating}/5` : ''}`,
          });
        }, 1000);
        eventReadSubject.subscribe(clear => clear && clearTimeout(timeout));
      }
    }
  } catch (e) {
    console.error('[socketRouter] Error: ', e);
  }
};

const connectionNotification = (type, message) => {
  const durationVar = 5;
  notification.config({ maxCount: 1 });
  notification[type]({
    message,
    closeIcon: <ConnectionNotificationCloseIcon />,
    top: 60,
    style: {
      padding: '16px',
      fontFamily: 'Poppins',
      width: '385px',
      marginTop: '16px',
    },
    className: 'pos-rel pointer',
    duration: durationVar,
  });
};

export const connectionHandler = (type, message) => {
  const { pathname } = window.location;

  if (pathname) {
    const currentPath = pathname;
    if (currentPath.includes(LOGIN_PATH)) return;

    const isInboxOpen = currentPath === CLIENT_INBOX_PATH;

    if (isInboxOpen) {
      connectionNotification(type, message);
    }
  }
};

export const notificationHandler = messageObject => {
  const { category } = messageObject;

  switch (category) {
  case 'PAY_MSG':
    paymentNotification(messageObject);
    break;
  default:
    break;
  }
};

export function modifyMessageStatus(data) {
  messageStatusSubject.next(data);
}

export const socketRouter = {
  messageHandler,
  notificationHandler,
  connectionHandler,
};
