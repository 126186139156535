import React, { useState, useEffect } from 'react';

import yn from 'yn';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledLink from '../StyledLink';

import {
  setRemindersBoolean,
  setReminderAddEdit,
  setReminderActionId,
  setReminderInterval,
  setReminderCount,
  setReminderRepeat,
  setReminderName,
  setReminderActive,
  setRemTempMsgImg,
  setRemRepeatCount,
  setRemRepeatInterval,
  setExpireScale,
  setExpireInterval,
  setExpires,
} from '../../../reducers/autoRemMsgs';

import {
  setMsgLayoutDesc,
  setMsgListType,
  setMsgGoBack,
  resetAutoMessages,
  setMessageAddEdit,
  setMessageId,
  setAutoMessageText,
  setMessageCount,
  setMessageInterval,
  setMessageRepeat,
  setMessageActive,
} from '../../../reducers/autoMessages';
import { getActionConfig, getTablePagList } from '../../../core/apiRequests';
import LargeImage from '../LargeImage';
import whatsAppTemplateFormatter from '../WhatsAppTemplateFormatter';
import AutomationTableHeaders from './AutomationTableHeaders';
import FlaggedAutomationButtons from './FlaggedAutomationButtons';
import { convertMessageTemplate } from '../../../utils/convertMessageTemplate';

const AutomationTable = props => {
  const flags = props.auth.merchantFlags;
  const relationshipAutomation = flags?.relationship_automation?.enabled;

  const [dataList, setDataList] = useState([]);
  const [showWhatsApp, setShowWhatsApp] = useState('');
  const history = useHistory();
  const [editObj, setEditObj] = useState({});
  const [largeImagePopup, setLargeImagePopup] = useState(false);
  const [typedRelationships, setTypedRelationships] = useState([]);

  useEffect(() => {
    (async () => {
      const dataArr = await getTablePagList({
        tableName: 'CLIENT_PREFERENCES',
        type: 'typed-relationships',
        merchantId: props.auth.merchantId,
        sortColumn: 'name',
        count: 1000,
      });
      setTypedRelationships(dataArr);
    })();
  }, [props.merchantId]);

  useEffect(() => {
    const { listItemId } = props.match.params;
    const {
      type,
      auth: { merchantId },
    } = props;
    // setDataList([])
    if (merchantId) {
      if (listItemId && type) {
        (async () => {
          const data = await getActionConfig(type, merchantId, listItemId, 'automationLists');
          if (data) {
            const sortedList = data?.sort((a, b) => {
              const adate = dateSwitcher(a);
              const bdate = dateSwitcher(b);
              if (adate > bdate) {
                return -1;
              }
              if (bdate > adate) {
                return 1;
              }
              return 0;
              function dateSwitcher(listObj) {
                switch (listObj.action_interval) {
                  case 'DAY':
                    return moment(new Date()).subtract(listObj.action_scale, 'days');
                  case 'WEEK':
                    return moment(new Date()).subtract(listObj.action_scale, 'weeks');
                  case 'MONTH':
                    return moment(new Date()).subtract(listObj.action_scale, 'months');
                  default:
                    break;
                }
              }
            });
            setDataList([...sortedList]);
            setShowWhatsApp(props.auth.enabled_whatsapp);
          } else {
            // notificationError('Error Loading Content');
          }
        })();
      }
    }
  }, [props.auth.merchantId, props.match.params.listItemId, props.type]);

  const clearActionConfig = () => {
    setReminderAddEdit('Add');
    setReminderActionId('');
    setReminderName('');
    setReminderInterval('WEEK');
    setReminderCount(2);
    setReminderRepeat('');
    prepLayout();
    setRemindersBoolean(true);
  };

  const clearActionConfigMessage = () => {
    setMessageCount(0);
    setMessageInterval('DAY');
    setMessageRepeat('');
    resetAutoMessages();
    prepLayout();
    setRemindersBoolean(true);
  };

  const prepLayout = () => {
    setMsgGoBack(props.redirectBack);
    setMsgLayoutDesc(props.layoutDesc);
    setMsgListType(props.type);
  };

  const onRowClick = (row, event) => {
    if (event.target.tagName === 'IMG') {
      setLargeImagePopup(true);
      setEditObj({ image_url: row.image_url, id: row.id });
    }
    // TODO: WHATSAPP IF TYPE STUFF
    if (row.type === 'Whatsapp') {
      const { id, description, action_interval, action_scale, repeats } = row;
      setReminderAddEdit('Edit');
      setReminderActionId(id);
      setReminderName(description);
      setReminderInterval(action_interval);
      setReminderCount(action_scale);
      setReminderRepeat(repeats);
      prepLayout();
      history.push(`${props.redirectToReminderSettings}/edit-auto-whatsapp/${id}`);
    }
    if (row.type === 'Auto-Reminder' && event.target.tagName !== 'IMG') {
      const {
        id,
        description,
        action_interval,
        action_scale,
        repeats,
        active,
        image_url,
        repeat_scale,
        repeat_interval,
        expire_interval,
        expire_scale,
      } = row;
      setReminderAddEdit('Edit');
      setReminderActionId(id);
      setReminderName(description);
      setReminderActive(active);
      setReminderInterval(action_interval);
      setReminderCount(action_scale);
      setReminderRepeat(repeats);
      setRemRepeatCount(repeat_scale);
      setRemRepeatInterval(repeat_interval);
      setRemTempMsgImg(image_url);
      setExpireScale(expire_scale);
      setExpireInterval(expire_interval);

      if (expire_interval || expire_scale) {
        setExpires(true);
      } else {
        setExpires(false);
      }

      prepLayout();
      history.push(`${props.redirectToReminderSettings}/edit-auto-reminder/${id}`);
    }
    if (row.type === 'Auto-Message' && event.target.tagName !== 'IMG') {
      const { id, msg, action_interval, action_scale, repeats, active } = row;
      setMessageAddEdit('Edit');
      setMessageId(id);
      setAutoMessageText(msg);
      setMessageActive(active);
      setMessageCount(action_scale);
      setMessageInterval(action_interval);
      setMessageRepeat(repeats);
      prepLayout();
      history.push(`${props.redirectToAutoMsgSettings}/edit-auto-message/${id}`);
    }
  };

  const descriptionSnippet = desc => {
    if (desc && desc.length > 150) {
      return `${desc.substr(0, 150)}...`;
    }
    return desc;
  };

  const tableRowBuilder = (itemObj, rowKey) => {
    const rowArr = [];
    const lowerCaseInterval = itemObj.action_interval.toLowerCase();
    // Some of the client activity things are saved in the database as DAYS/WEEKS/MONTHS so that avoids a double s bug
    const IntervalArr = lowerCaseInterval.split('').filter(letter => letter.toLowerCase() !== 's');
    const capitalLetter = IntervalArr[0].toUpperCase();
    const formattedInterval = `${capitalLetter}${IntervalArr.slice(1).join('')}`;
    const bubbleStyles = {
      padding: '4px 0 2px 0',
    };
    rowArr.push(
      <td className="fs-12 blue-link p-left-15 p-right-15" key={`td${rowKey}description`} style={{ width: '625px' }}>
        <div dangerouslySetInnerHTML={{
          __html: convertMessageTemplate(descriptionSnippet(itemObj.description || itemObj.msg || whatsAppTemplateFormatter(itemObj)))
        }} />
      </td>,
    );
    if (props.type === 'lifeEvent' && relationshipAutomation) {
      rowArr.push(
        <td className="fs-12 miw-100" key={`td${rowKey}Tmedia`} style={{ width: '200px' }}>
          {itemObj.type === 'Auto-Reminder' ? (
            <span className="gray">Not Applicable</span>
          ) : (
            <span className="gray">
              {typedRelationships.find(item => item.id === itemObj.relationship_type_id)?.name ?? 'Not Applicable'}
            </span>
          )}
        </td>,
      );
    }
    // images
    rowArr.push(
      <td className="fs-12 miw-100" key={`td${rowKey}media`} style={{ width: '200px' }}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {itemObj.type == 'Auto-Reminder' ? (
          <span className="gray" style={{ fontStyle: 'italic' }}>
            Not Applicable
          </span>
        ) : itemObj.image_url ? (
          <img
            style={{ objectFit: 'cover', borderRadius: '2px', width: '32px', height: '32px', marginTop: '4px' }}
            className="m-right-4"
            alt="#"
            src={itemObj.image_url}
            onError={e => {
              e.target.onerror = null;
              e.target.src = 'https://dashboard-v2-images.s3.amazonaws.com/image-placeholder@2x.png';
            }}
            //  onClick={() => {
            //       setEditObj({image_url : itemObj.image_url, id : rowKey});
            //  }}
            onClick={e => onRowClick(itemObj, e)}
          />
        ) : (
          <span className="gray" style={{ fontStyle: 'italic' }}>
            None
          </span>
        )}
      </td>,
    );
    rowArr.push(
      <td className="fs-12 miw-100" key={`td${rowKey}type`} style={{ width: '150px' }}>
        {itemObj.type}
      </td>,
    );
    if (itemObj.action_scale == 0 && itemObj.action_interval == 'DAY') {
      rowArr.push(
        <td className="fs-12" key={`td${rowKey}schedule`} style={{ width: '75px' }}>
          Same Day
        </td>,
      );
    } else {
      rowArr.push(
        <td className="fs-12" key={`td${rowKey}schedule`} style={{ width: '75px' }}>
          {itemObj.action_scale} {formattedInterval}
          {itemObj.action_scale > 1 ? 's' : ''}
        </td>,
      );
    }
    if (props.type !== 'lifeEvent') {
      const checkMark = itemObj.repeats ? <FontAwesomeIcon icon={['far', 'check']} /> : null;
      rowArr.push(
        <td className="align-center" style={{ width: '75px' }} key={`td${rowKey}repeats`}>
          {checkMark}
        </td>,
      );
    }
    if (itemObj.active === 1) {
      rowArr.push(
        <td className="align-center" style={{ width: '75px' }} key={`td${rowKey}active`}>
          <span
            style={{
              // ...bubbleStyles,
              backgroundColor: '#ecf8dd',
              color: '#7fbd31',
            }}
            className="align-vert-middle align-center h-24 fs-10 fw-600 w-72"
          >
            ACTIVE
          </span>
        </td>,
      );
    } else {
      rowArr.push(
        <td className="align-center" style={{ width: '75px' }} key={`td${rowKey}active`}>
          <div
            style={{
              // ...bubbleStyles,
              backgroundColor: '#ebebeb',
              color: '#9c9c9c',
            }}
            className="rem-pad-width align-vert-middle align-center h-24 fs-10 fw-600 w-72"
          >
            DISABLED
          </div>
        </td>,
      );
    }
    return rowArr;
  };

  return (
    <div style={{ width: '1200px', ...props.automationStyleProps }}>
      <div className="flex-row-spacebetween-wrap">
        <span className="fs-16 fw-500 p-top-6">Automation</span>
        <FlaggedAutomationButtons
          flags={flags}
          showWhatsApp={showWhatsApp}
          redirectToAutoWhatsappSettings={props.redirectToAutoWhatsappSettings}
          clearActionConfigMessage={clearActionConfigMessage}
          redirectToAutoMsgSettings={props.redirectToAutoMsgSettings}
          redirectToReminderSettings={props.redirectToReminderSettings}
          clearActionConfig={clearActionConfig}
          dataLength={dataList.length}
        />
      </div>
      <table className="real-small-table w-100-P">
        <AutomationTableHeaders flags={flags} classname={props.classname || 'table-head'} type={props.type} />
        <tbody className="table-row">
          {dataList.map(function (item, key) {
            return (
              <tr
                className="body-row pointer fs-12 border-btm-l-gray "
                key={`tr${key}`}
                onClick={e => onRowClick(item, e)}
                style={{ height: '42px' }}
              >
                {tableRowBuilder(item, key)}
              </tr>
            );
          })}
        </tbody>
      </table>
      {largeImagePopup ? <LargeImage setLargeImagePopup={setLargeImagePopup} templateObj={editObj} /> : null}
    </div>
  );
};
const mapStateToProps = state => ({
  autoMessages: state.autoMessages,
  auth: state.auth,
  autoRemMsgs: state.autoRemMsgs,
});
export default connect(mapStateToProps, {})(AutomationTable);
