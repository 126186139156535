import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import Layout from '../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';

import { setTriggerActionObj } from '../../../../../reducers/salesTriggers';
import AutoMessTriggerSettings from '../../../../Layout/AutoMessageSalesTrigger/AutoMessTriggerSettings';
import { getAll } from '../../../../../core/apiRequests';

const EditActionAutoMessage = props => {
  const history = useHistory();

  const setRedirectBack = () => {
    history.push('/Configuration/sales-triggers');
  };

  useEffect(() => {
    if (props.auth.merchantId) {
      (async () => {
        const dataSet = await getAll('salesTrigger', props.auth.merchantId, `?actionId=${props.match.params.actionId}`);
        if (dataSet && dataSet.length > 0) {
          const dataObj = dataSet[0];
          const { title, description, action_id, msgs, image_url } = dataObj;
          const stateObj = {
            actionTitle: title,
            actionDescription: description,
            actionId: action_id,
            actionImg: image_url,
            // actionMsgTemplates: [...msgs],
          };
          setTriggerActionObj(stateObj);
        }
      })();
    }
  }, [props.auth.merchantId]);

  return (
    <Layout
      title={`Edit "${props.salesTriggers.actionTitle || ''}" `}
      description="Edit content for this Auto Message"
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Sales Triggers', to: '/Configuration/sales-triggers' },
        { title: `Edit "${props.salesTriggers.actionTitle || ''}"` },
      ]}
      classProps="standard-width-with-sidebar"
    >
      <AutoMessTriggerSettings
        setRedirectBack={setRedirectBack}
        cancelRoute="/Configuration/sales-triggers"
        popConfirmDesc="This will remove the Auto Message from Sales Triggers"
        editRoute="/Configuration/sales-triggers"
        type="sales-triggers"
        match={props.match}
      />
    </Layout>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
  salesTriggers: state.salesTriggers,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(EditActionAutoMessage);
