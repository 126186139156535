import React from 'react'
import { useLocation, useParams, Redirect } from 'react-router-dom'

const pathToClients = [
  'edit-client-tags',
  'edit-client-associates',
  'add-client',
  'edit-client-info'
]

const pathToViewAll = [
  'reminders',
  'salesopps',
  'activities',
]
const ClientRedirect = () => {
  const location = useLocation()
  const params = useParams();
  const pathname = location.pathname.split('/')
  let path =  ''
  if(pathname.filter(p => pathToClients.includes(p)).length > 0) {
    path = `/Clients`
  } else {
    const { clientId, oppId, collectionId } = params;
    const viewAllPage = pathname?.filter(p => pathToViewAll.includes(p))
    if(clientId) {
      if(viewAllPage.length > 0) {
        switch(viewAllPage[0]) {
          case 'reminders':
            path = `/Clients?client=${clientId}&viewAllTab=allReminders`
            break;
          case 'salesopps':
            path = `/Clients?client=${clientId}&viewAllTab=allSalesOpportunities`
            break;
          case 'activities':
            path = `/Clients?client=${clientId}&clientTab=activities`
            break;
          default:
            path = `/Clients/client-details/${clientId}`
        }
      } else {
        if(oppId) {
          path = `/Clients?client=${clientId}&oppId=${oppId}`
        } else if (collectionId) {
          path = `/Clients?client=${clientId}&collectionId=${collectionId}`
        } else {
          path = `/Clients?client=${clientId}`
        }
      }
    } else {
      path = `/Clients`
    }
  }

  return (
    <Redirect
      to={path}
    />
  )
}

export default ClientRedirect
