import React from 'react';
import { connect } from 'react-redux';
import StandardLayout from '../../../../Layout/StandardLayout';
import WhatasppReminderTemplates from '../../../../Layout/AutoReminders/AutoReminderSettings/WhatasppReminderTemplates';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';

const LifeEventMsgTemplate = props => {
  const { reminderId } = props.match.params;
  const { templateId } = props.match.params;

  return (
    <StandardLayout
      title={`${props.msgTempAddOrEdit} Message Template for Auto Reminder`}
      description="Create message templates that appear with the auto reminder"
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Sales Triggers', to: '/Configuration/sales-triggers' },
        {
          title: `Edit "${props.salesTriggers.actionTitle}"`,
          to: `/Configuration/sales-triggers/edit-trigger-auto-reminder/${reminderId}`,
        },
        { title: `${props.msgTempAddOrEdit} Message Template` },
      ]}
      classProps="standard-width-with-sidebar"
    >
      <WhatasppReminderTemplates
        cancelRoute={`/Configuration/sales-triggers/edit-trigger-auto-reminder/${reminderId}/edit-message-template/${templateId}`}
        popConfirmDesc="This will remove the template from this Reminder"
        type="salesTrigger" /// / REMEMBER THIS
        match={props.match}
      />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  ...state.autoRemMsgs,
  salesTriggers: state.salesTriggers,
});
export default connect(mapStateToProps, {})(LifeEventMsgTemplate);
