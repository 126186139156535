import React, { useState, useEffect } from 'react';
import tableHeaders from './tableHeaders';
import Button from '../../../../Layout/Button';
import StyledLink from '../../../../Layout/StyledLink';
import PopConfirm from '../../../../Layout/PopConfirm';
import SmallTable from '../../../../Layout/SmallTable';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';
import StandardLayout from '../../../../Layout/StandardLayout';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setPipelineStage, setEditPipeline, setDeletedSalesPipeline } from '../../../../../reducers/salesPipeline';
import { notificationSuccess, notificationError } from '../../../../../core/actions';
import { getRequest, postRequest, deleteRequest } from '../../../../../core/apiRequests';
import { setListId, setListType } from '../../../../../reducers/autoRemMsgs';
import NumberInput from '../../../../Layout/NumberInput';
import DropdownSelect from '../../../../Layout/DropdownSelect';

const AddEditSalesPipeline = props => {
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [stageData, setStateData] = useState([]);
  const [triggerData, setTriggerData] = useState([]);
  const [selectedTrigger, setSelectedTrigger] = useState('');
  const history = useHistory();

  useEffect(() => {
    setListId('');
    if (window.location.href.includes('add-sales-pipeline')) {
      if (props.preferenceId) {
        props.setPrefId('');
        props.setPrefName('');
        props.setPipeAddEdit('Add');
      }
      getData();
    } else if (window.location.href.includes('edit-sales-pipeline') && props.merchantId) {
      //edit-sales-pipeline
      props.setPipeAddEdit('Edit');
      getData();
    }
  }, [props.merchantId]);

  const getData = () => {
    const pipelineId = props.match.params.pipelineId;
    (async () => {
      const reqObj = {
        params: ['salesOpp', 'details', props.merchantId, pipelineId || 'X'],
      };
      const requestData = await getRequest(reqObj);
      if (
        requestData &&
        requestData.hasOwnProperty('stages') &&
        requestData.hasOwnProperty('pipeline') &&
        requestData.hasOwnProperty('triggers')
      ) {
        const { stages, pipeline, triggers } = requestData;

        if (pipeline.length > 0) {
          const pipelineObj = pipeline[0];
          setListId(pipelineObj.id);
          setListType('salesOpp');
          setEditPipeline(pipelineObj, 'Edit');
          if (pipelineObj.sales_trigger_id) {
            setSelectedTrigger(pipelineObj.sales_trigger_id);
          }
        }

        if (triggers.length > 0) {
          setTriggerData([...triggers]);
        }

        if (stages.length > 0) {
          setStateData([...stages]);
        }
      } else {
        history.push(`/Configuration/sales-pipelines`);
      }
    })();
  };

  const confirmDelete = () => {
    const { firstName, lastName } = props;
    const fn = firstName ? firstName : '';
    const ln = lastName ? lastName : '';
    (async () => {
      const reqObj = {
        params: ['salesOpp', props.merchantId, 'remove', props.pipelineId],
        query: {
          deletedBy: `${fn} ${ln}`.trim(),
        },
      };
      const data = deleteRequest(reqObj);
      if (data) {
        setTimeout(() => {
          setDeletedSalesPipeline(props.pipelineId);
          notificationSuccess('Sales Opportunity Removed');
          history.push('/Configuration/sales-pipelines');
        }, 2000);
      }
    })();
  };

  const editPref = () => {
    const { pipelineName, pipelineAvgCycle, pipelineDefAmt, firstName, lastName } = props;
    const fn = firstName ? firstName : '';
    const ln = lastName ? lastName : '';
    if (!pipelineName) {
      // TODO: a demo on error handling and edge cases
      notificationError('Opportunity Name Required');
    } else if (!pipelineDefAmt) {
      notificationError('Default Amount Required');
    } else if (!pipelineAvgCycle) {
      notificationError('Days to Close Required');
    } else {
      (async () => {
        const dataObj = {
          name: pipelineName,
          days_to_close: pipelineAvgCycle,
          amount: pipelineDefAmt,
          salesTriggerId: selectedTrigger,
        };
        if (props.pipelineId) {
          dataObj.id = props.pipelineId;
        }
        const reqObj = {
          params: ['salesOpp', props.merchantId, 'save'],
          data: { ...dataObj },
          query: {
            createdBy: `${fn} ${ln}`.trim(),
          },
        };
        const data = await postRequest(reqObj);
        if (data.id) {
          if (props.pipelineId) {
            notificationSuccess('Sales Opportunity Updated');
          } else {
            notificationSuccess('Sales Opportunity Created');
            history.push(`/Configuration/sales-pipelines/edit-sales-pipeline/${data.id}`);
          }
          props.setPipeId(data.id);
          props.setPipeAddEdit('Edit');
        } else {
          // error handling?
        }
      })();
    }
  };

  const rowClickHandler = data => {
    const pipelineId = props.match.params.pipelineId;
    props.setPipelineStage(data);
    setListId(data.id);
    // setRedirectToStage(true);
    history.push(`/Configuration/sales-pipelines/edit-sales-pipeline/${pipelineId}/edit-pipeline-stage/${data.id}`);
  };

  const isSafe = () => {
    // const safeMerchants = [11, 70, 2, 17, 350, 354, 363, 400, 402, 410, 418, 421];
    const posType = props.pos_type ? props.pos_type.trim() : null;
    if (posType || process.env.REACT_APP_API_ENV === 'development' || process.env.REACT_APP_API_ENV === 'localhost') {
      return true;
    }
    return false;
  };

  return (
    <StandardLayout
      title={`${props.addOrEdit} Opportunity Type`}
      description={
        props.addOrEdit === 'Add'
          ? "Add an opportunity to track a client's sales process"
          : 'Edit sales opportunity details and configure stages'
      }
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Sales Opportunities', to: '/Configuration/sales-pipelines' },
        { title: `${props.addOrEdit} Opportunity` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <div className="custom-action-wrapper">
        <div className="align-left">
          <input
            id="SalesPipelineNameInput"
            className={`m-btm-15 m-top-15 fs-13 outline-none common_inputStyle max-w-600 w-100-P left-0 input-height `}
            value={props.pipelineName}
            onChange={e => props.setName(e.target.value)}
            placeholder="Sales Opportunity Name"
          />
        </div>

        <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P">
          <div className=" w-48-P inputHeight align-left" style={{ position: 'relative' }}>
            <NumberInput
              id="DefaultAmountInput"
              onChange={props.setDefAmount}
              value={props.pipelineDefAmt}
              classProps=""
              placeholder="Default Amount"
              incrementButtonId="DefaultAmountIncrementButton"
              decrementButtonId="DefaultAmountDecrementButton"
              decorator={'$'}
            />
          </div>
          <div className=" w-48-P inputHeight align-left" style={{ position: 'relative' }}>
            <NumberInput
              id="PipelineAverageCycleInput"
              onChange={props.setAvgCycle}
              min="0"
              max="60"
              step={1}
              precision={1}
              value={props.pipelineAvgCycle}
              classProps=""
              placeholder="6"
              incrementButtonId="AverageCycleIncrementButton"
              decrementButtonId="AverageCycleDecrementButton"
            />
          </div>
        </div>
        {isSafe() ? (
          <div className="flex-col-left m-top-20">
            <span className="fw-500 fs-13">Link a Sales Trigger (optional)</span>
            <span className="gray fs-10 align-left m-top-4">
              A sale that matches this trigger will automatically transition that sales opportunity to the closed/won stage for this pipeline.
            </span>
            <DropdownSelect
              id="ChooseSalesTriggerDropdown"
              classProps="max-w-600 w-100-P m-top-18 align-left"
              placeholder="Choose a sales trigger..."
              options={[{ id: null, name: '< No Sales Trigger >' }, ...triggerData].map(tD => ({
                value: tD.id,
                label: tD.name,
              }))}
              value={selectedTrigger}
              onChange={obj => setSelectedTrigger(obj.value)}
            />
          </div>
        ) : null}
        <div className=" m-top-37">
          <div className="mq-w-100-vw custom-action-save-flex">
            <button id="AddPipelineSaveButton" className="save-button-v2 m-btm-8" onClick={editPref}>
              Save
            </button>
             {props.addOrEdit === 'Edit' ? (
            <Button
              id="DeleteSalesPipelineButton"
              classname="deleteButton-v2 "
              style={{ marginBottom: '8px' }}
              onclick={() => setShowPopConfirm(true)}
            >
              Delete
            </Button>
          ) : (
            <div />
          )}
           <StyledLink to="/Configuration/sales-pipelines">
              <button id="AddPipelineCancelButton" className="cancel-button-v2">
                Cancel
              </button>
            </StyledLink>
          </div>
        </div>
      </div>
      {props.addOrEdit === 'Edit' ? (
        <div>
          <div className="flex-row-spacebetween-wrap" style={{ maxWidth: '600px' }}>
            <p className="black fs-16 fw-600" style={{ textAlign: 'center' }}>
              Opportunity Stages
            </p>
            <StyledLink
              classprops=""
              to={`/Configuration/sales-pipelines/edit-sales-pipeline/${props.match.params.pipelineId}/add-pipeline-stage`}
              styleprops={{ color: '#33aafc', margin: 0, padding: 0, paddingTop: '5px' }}
            >
              <Button
                onclick={props.clearPipelineStage}
                classname="blue-link fs-13"
                styleProps={{ width: 'auto', padding: 0 }}
              >
                <FontAwesomeIcon
                  icon={['far', 'layer-plus']}
                  size="1x"
                  className="fs-16"
                  style={{ paddingBottom: '2px' }}
                />{' '}
                ADD STAGE
              </Button>
            </StyledLink>
          </div>
          <SmallTable
            tableHeaders={tableHeaders}
            data={stageData}
            tableStyle={{ marginRight: '0', maxWidth: '600px' }}
            tableClassName={'edit-tags-auto-table hide-scrollbar'}
            onRowClick={rowClickHandler}
            rowStyle={{ borderBottom: '1px solid #d0d0d0' }}
            classname="mid-gray salesPipelineSmallTable "
          />
        </div>
      ) : null}
      {showPopConfirm ? (
        <PopConfirm
          confirm={`Are you sure you want to remove "${props.pipelineName}" opportunity?`}
          description={'This will permanently delete this Sales Opportunity'}
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={confirmDelete}
        />
      ) : null}
    </StandardLayout>
  );
};
const mapStateToProps = state => ({ ...state.salesPipeline, ...state.auth });
const mapDispatchToProps = dispatch => ({
  setPipelineStage(val) {
    setPipelineStage(val, 'Edit');
  },
  clearPipelineStage() {
    setPipelineStage(
      {
        id: '',
        name: '',
        weight: '',
      },
      'Add',
    );
  },
  setName(val) {
    dispatch({
      type: 'SET_SALES_PIPE_NAME',
      payload: val,
    });
  },
  setAvgCycle(val) {
    dispatch({
      type: 'SET_SALES_PIPE_AVG_CYCLE',
      payload: val,
    });
  },
  setDefAmount(val) {
    dispatch({
      type: 'SET_SALES_PIPE_DEF_AMT',
      payload: val,
    });
  },
  setPipeId(val) {
    dispatch({
      type: 'SET_SALES_PIPE_ID',
      payload: val,
    });
  },
  setPipeAddEdit(val) {
    dispatch({
      type: 'SET_SALES_PIPE_ADD_EDIT',
      payload: val,
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AddEditSalesPipeline);
