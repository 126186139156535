/* eslint-disable no-unused-expressions */
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { differenceInDays, endOfDay, format, formatISO, startOfDay, subDays } from 'date-fns';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Report from '../../../../componentsV2/Layout/Report';
import ExpandedRow from './components/expandedRow';
import { filterList } from './helpers/filterList';
import useSearchParams from '../../../../hooks/useSearchParams';
import formatNumberAsCurrency from '../../../../utils/formatNumberAsCurrency';
import { addLoadingList, removeLoadingList, setLoadingDescription } from '../../../../reducers/notification';
import { useReport } from '../../../../context/ReportContext';

const automationTypes = {
  AI_ASSISTANT: 'Smart Assistant Activity',
  AUTO_MESSAGE: 'Auto Message',
  BATCH_MESSAGE: 'Batch Message',
  REMINDER: 'Auto Reminder',
};

const Automations = ({ auth }) => {
  const { setValue: setSearchParams, getAll } = useSearchParams();
  const params = getAll();

  const [dateRange, setDateRange] = useState([subDays(new Date(), 90), new Date()]);
  const [pageSizeTable, setPageSizeTable] = useState(10);
  const [reportData, setReportData] = useState([]);
  const [aiValue, setAiValue] = useState(0);
  const [autoMessagesTotal, setAutoMessagesTotal] = useState(0);
  const [autoMessagesValue, setAutoMessagesValue] = useState(0);
  const [remindersValue, setRemindersValue] = useState(0);
  const getDefaultPeriod = useMemo(() => {
    if (params?.startDate && params?.endDate) {
      if (differenceInDays(new Date(params.endDate), new Date(params.startDate)) === 90) {
        return '90days';
      }
      return `${format(new Date(params.startDate), 'MM/dd/yyyy')} - ${format(new Date(params.endDate), 'MM/dd/yyyy')}`;
    }
    return '90days';
  }, []);
  const options = useMemo(
    () => [
      {
        title: 'Automation Type',
        key: '0-0',
        value: 'automationType',
        children: [
          {
            key: '0-0-0',
            title: 'Smart Assistant Activity',
            value: automationTypes.AI_ASSISTANT,
          },
          {
            key: '0-0-1',
            title: 'Auto Message',
            value: automationTypes.AUTO_MESSAGE,
          },
          {
            key: '0-0-2',
            title: 'Auto Reminder',
            value: automationTypes.REMINDER,
          },
        ],
      },
    ],
    [],
  );
  const [filters, setFilters] = useState({
    automationType: [],
    store: [],
    associate: [],
  });

  const { chatMessages, isLoadingChatMessage, reminders, isLoadingReminder } = useReport();

  useEffect(() => {
    const paramsFilters = {
      automationType: params?.automationType ? params?.automationType : [],
      store: params?.store ? params?.store : [],
      associate: params?.associate ? params?.associate : [],
    };
    setFilters(paramsFilters);
    if (params?.startDate && params?.endDate) {
      setDateRange([new Date(params.startDate), new Date(params.endDate)]);
    } else {
      setDateRange([startOfDay(subDays(new Date(), 90)), endOfDay(new Date())]);
    }
  }, [window.location.search]);

  const handleOnDateRangeChange = useCallback(dates => {
    setSearchParams('startDate', formatISO(dates[0]));
    setSearchParams('endDate', formatISO(dates[1]));
  }, []);

  const handleFilterChange = useCallback(value => {
    const updatedFilters = {
      automationType: [],
      store: [],
      associate: [],
    };
    value.forEach(item => {
      if (item[0] && item[1]) {
        updatedFilters[item[0]] = [...updatedFilters[item[0]], item[1]];
      } else {
        updatedFilters[item[0]] = [];
      }
    });
    Object.keys(updatedFilters).forEach(key => {
      setSearchParams(key, updatedFilters[key].join(','));
    });
  }, []);

  const removeFilter = useCallback(
    (key, value) => {
      const updatedFilters = { ...filters };
      updatedFilters[key] = updatedFilters[key].filter(filter => filter !== value);
      Object.keys(updatedFilters).forEach(key => {
        setSearchParams(key, updatedFilters[key].join(','));
      });
    },
    [filters],
  );

  const columns = useMemo(() => {
    const relatedSales = auth?.pos_type
      ? [
          {
            title: 'Related Sales',
            dataIndex: 'related_sales',
            key: 'related_sales',
            sorter: (a, b) => a.related_sales - b.related_sales,
            render: amount => formatNumberAsCurrency(amount || 0),
          },
        ]
      : [];
    const storeColumn =
      auth.stores.filter(st => st.id !== 0).length > 1
        ? [
            {
              title: 'Store',
              dataIndex: 'activity_store_name',
              key: 'activity_store_id',
              sorter: (a, b) => {
                if (!a.activity_store_name) return -1;
                if (!b.activity_store_name) return 1;
                return a.activity_store_name.localeCompare(b.activity_store_name);
              },
            },
          ]
        : [];
    return [
      {
        title: 'Date',
        dataIndex: 'activity_date',
        key: 'activity_date',
        render: date => moment.utc(new Date(date)).format('MMM DD, yyyy'),
        sorter: (a, b) => {
          const dateA = new Date(a.activity_date);
          const dateB = new Date(b.activity_date);

          return dateA - dateB;
        },
        defaultSortOrder: 'descend',
      },
      {
        title: 'Performed by',
        dataIndex: 'activity_associate_name',
        key: 'activity_associate_name',
        sorter: (a, b) => {
          if (!a.activity_associate_name) return -1;
          if (!b.activity_associate_name) return 1;
          return a.activity_associate_name.localeCompare(b.activity_associate_name);
        },
      },
      ...storeColumn,
      {
        title: 'Client',
        dataIndex: 'client_name',
        key_export: 'client_name',
        key: 'client_id',
        sorter: (a, b) => {
          if (!a.client_name) return -1;
          if (!b.client_name) return 1;
          return a.client_name.localeCompare(b.client_name);
        },
        render: (_, row) => <Link to={`/Clients?client=${row.client_id}`}>{row.client_name}</Link>,
      },
      {
        title: 'Activity Type',
        dataIndex: 'automationType',
        key: 'automationType',
        sorter: (a, b) => {
          if (!a.automationType) return -1;
          if (!b.automationType) return 1;
          return a.automationType.localeCompare(b.automationType);
        },
      },
      ...relatedSales,
    ];
  }, [auth]);

  useEffect(() => {
    if (isLoadingChatMessage || isLoadingReminder) {
      setLoadingDescription("Sit tight! We're compiling your report.");
      addLoadingList('automationReport');
    } else {
      removeLoadingList('automationReport');
      setLoadingDescription(null);
    }
  }, [isLoadingChatMessage, isLoadingReminder]);

  const rawData = useMemo(() => {
    return [...(chatMessages?.reportData || []), ...(reminders?.reportData || [])];
  }, [chatMessages, reminders]);

  const statsData = useMemo(() => {
    return {
      chat: chatMessages?.stats || [],
      reminder: reminders?.stats || [],
    };
  }, [chatMessages, reminders]);

  const formatTableData = useCallback(async () => {
    if (rawData.length === 0) {
      return;
    }
    const newReportData = rawData
      .map(act => {
        let automationType;
        if (act.reminder_id) {
          automationType = automationTypes.REMINDER;
        }
        if (act.ai_activity_result_id) {
          automationType = automationTypes.AI_ASSISTANT;
        }
        if (act.auto_message_id) {
          automationType = automationTypes.AUTO_MESSAGE;
        }
        return { ...act, automationType, keyId: `${automationType}-${act.sales_transaction_id}` };
      })
      .filter(item => item.automationType);
    const filteredData = filterList({
      data: newReportData,
      filters,
      startDate: dateRange[0],
      endDate: dateRange[1],
      assocStores: auth.stores,
    });
    const [aiNum, aiVal, amNum, amValue, remNum, remValue, aiCompleted] = filteredData.reduce(
      (acc, curr) => {
        if (curr.ai_activity_result_id) {
          acc[0] += 1;
          if (
            curr.sales_transaction_id &&
            !acc[7]?.includes(curr?.sales_transaction_id) &&
            !curr.sales_transaction_amount?.includes('-')
          ) {
            acc[7] = [...acc[7], curr.sales_transaction_id];
            acc[1] += +curr.sales_transaction_amount;
          }
          if (curr.completed) {
            acc[6] += 1;
          }
        } else if (curr.auto_message_id) {
          acc[2] += 1;
          if (
            curr.sales_transaction_id &&
            !acc[8]?.includes(curr?.sales_transaction_id) &&
            !curr.sales_transaction_amount?.includes('-')
          ) {
            acc[8] = [...acc[8], curr.sales_transaction_id];
            acc[3] += +curr.sales_transaction_amount;
          }
        } else if (curr.reminder_id) {
          acc[4] += 1;
          if (
            curr.sales_transaction_id &&
            !acc[9]?.includes(curr?.sales_transaction_id) &&
            !curr.sales_transaction_amount?.includes('-')
          ) {
            acc[9] = [...acc[9], curr.sales_transaction_id];
            acc[5] += +curr.sales_transaction_amount;
          }
        }
        return acc;
      },
      [0, 0, 0, 0, 0, 0, 0, [], [], []],
    );

    setAiValue(aiVal.toFixed(2));
    setAutoMessagesTotal(amNum);
    setAutoMessagesValue(amValue.toFixed(2));
    setRemindersValue(remValue.toFixed(2));
    setReportData(
      filteredData.filter(item => item?.sales_transaction_id && !item?.sales_transaction_amount?.includes('-')),
    );
  }, [rawData, dateRange, filters, auth.stores]);

  const dataByAutomationId = useMemo(() => {
    const data = [];
    reportData.forEach(item => {
      const findIndex = data.findIndex(
        auto =>
          auto.automationTypeId ===
          `${item.automationType}-${item.ai_activity_result_id || item.auto_message_id || item.reminder_id}`,
      );
      if (findIndex > -1) {
        data[findIndex].related_sales += parseFloat(item.sales_transaction_amount);
        data[findIndex].transactions.push({
          sales_transaction_amount: item.sales_transaction_amount,
          sales_transaction_id: item.sales_transaction_id,
          sales_transaction_date: item.sales_transaction_date,
          sales_transaction_store_id: item.sales_transaction_store_id,
        });
      } else {
        const newData = {
          automationTypeId: `${item.automationType}-${
            item.ai_activity_result_id || item.auto_message_id || item.reminder_id
          }`,
          ...item,
          related_sales: parseFloat(item.sales_transaction_amount),
          transactions: [
            {
              sales_transaction_amount: item.sales_transaction_amount,
              sales_transaction_id: item.sales_transaction_id,
              sales_transaction_date: item.sales_transaction_date,
              sales_transaction_store_id: item.sales_transaction_store_id,
            },
          ],
        };

        delete newData.sales_transaction_amount;
        delete newData.sales_transaction_id;
        delete newData.sales_transaction_date;
        delete newData.sales_transaction_store_id;

        data.push(newData);
      }
    });
    return data;
  }, [reportData]);

  useEffect(() => {
    formatTableData();
  }, [rawData, dateRange, filters.automationType, filters.store, filters.associate]);

  const expandedRowRender = useCallback(record => {
    return <ExpandedRow {...record} />;
  }, []);

  const stats = useMemo(() => {
    const [aiNum, aiCompleted, aiSkipped] = statsData?.chat
      ?.filter(st => {
        const filterStore = filters.store.length === 0 || filters.store.includes(st?.store_id?.toString());
        const filterAssociate =
          filters.associate.length === 0 || filters.associate.includes(st?.associate_id?.toString());
        const filterTimeSpan = moment(st?.date).isBetween(moment(dateRange[0]), moment(dateRange[1]));
        return filterStore && filterAssociate && filterTimeSpan;
      })
      .reduce(
        (acc, curr) => {
          acc[0] += 1;
          if (curr.result === 'COMPLETE') {
            acc[1] += 1;
          } else if (curr.result === 'SKIP') {
            acc[2] += 1;
          }
          return acc;
        },
        [0, 0, 0],
      );

    const [remNum, remCompleted, remSkipped] = statsData?.reminder
      ?.filter(st => {
        const filterStore = filters.store.length === 0 || filters.store.includes(st?.store_id?.toString());
        const filterAssociate =
          filters.associate.length === 0 || filters.associate.includes(st?.associate_id?.toString());
        const filterTimeSpan = moment(st?.date).isBetween(moment(dateRange[0]), moment(dateRange[1]));
        return filterStore && filterAssociate && filterTimeSpan;
      })
      .reduce(
        (acc, curr) => {
          acc[0] += 1;
          if (curr.result === 'COMPLETE') {
            acc[1] += 1;
          } else if (curr.result === 'SKIP') {
            acc[2] += 1;
          }
          return acc;
        },
        [0, 0, 0],
      );

    const hasAi = auth?.launchFlagsForNav?.projectEdison
      ? [
          {
            title: 'AI Insights',
            value: formatNumberAsCurrency(aiValue),
            suffix: <span className="fs-14 mid-gray m-top-3 fw-400">Related sales</span>,
            description: (
              <div className="flex-row-spacebetween-nowrap w-100-P">
                <span className="fs-14 mid-gray m-top-3">{`${aiNum} Total, ${aiCompleted} Done, ${aiSkipped} Skipped`}</span>
              </div>
            ),
          },
        ]
      : [];

    return [
      ...hasAi,
      {
        title: 'Automated Messages',
        value: formatNumberAsCurrency(autoMessagesValue),
        suffix: <span className="fs-14 mid-gray m-top-3 fw-400">Related sales</span>,
        description: (
          <span className="fs-14 mid-gray m-top-3">{`${autoMessagesTotal} Auto Messages Sent`}</span>
        ),
      },
      {
        title: 'Automated Reminders',
        value: formatNumberAsCurrency(remindersValue),
        suffix: <span className="fs-14 mid-gray m-top-3 fw-400">Related sales</span>,
        description: (
          <div className="flex-row-spacebetween-nowrap w-100-P">
            <span className="fs-14 mid-gray m-top-3">{`${remCompleted} Completed Auto Reminders`}</span>
          </div>
        ),
      },
    ];
  }, [aiValue, autoMessagesTotal, autoMessagesValue, remindersValue, auth, filters, dateRange, statsData]);

  const graphData = useMemo(() => {
    const data = {};
    reportData.forEach(item => {
      if (item.automationType) {
        if (!data[item.automationType]) {
          data[item.automationType] = [];
        }
        data[item.automationType].push({
          date: item.activity_date,
          total: 1,
        });
      }
    });
    return data;
  }, [reportData]);

  return (
    <Report
      title="Automations"
      stats={stats}
      options={options}
      stacked
      table={dataByAutomationId}
      columns={columns}
      handleFilterChange={handleFilterChange}
      filters={filters}
      hasCSVExport
      removeFilter={removeFilter}
      onDateRangeChange={handleOnDateRangeChange}
      dataRange={dateRange}
      defaultPeriodValue={getDefaultPeriod}
      tablesProps={{
        rowKey: 'automationTypeId',
        pagination: {
          total: dataByAutomationId.length - 1,
          showSizeChanger: true,
          showTotal: total => `Total ${total} items`,
          onChange: (newPage, pageSize) => {
            setPageSizeTable(pageSize);
          },
          pageSize: pageSizeTable,
        },
        expandable: {
          expandedRowRender,
          expandRowByClick: true,
        },
      }}
      graphData={graphData}
    />
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Automations);
