
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import { getRequest } from '../../core/apiRequests';
import { useQueries, useQuery } from 'react-query';
import { isBefore } from 'date-fns';
import { getSalesOpp } from '../../api/report';
import { ACTIVITIES_TYPES_ASSO_REPORT } from '../../constants';

const CACHE_TIME = 1000 * 60 * 60 * 2; // 2 hours
const STALE_TIME = 1000 * 60 * 60 * 1.5; // 1.5 hours

export const ReportContext = createContext({});

const allAssociatePerformanceTypes = [
  ACTIVITIES_TYPES_ASSO_REPORT.aiInsights,
  ACTIVITIES_TYPES_ASSO_REPORT.activities,
  ACTIVITIES_TYPES_ASSO_REPORT.chatMessages,
  ACTIVITIES_TYPES_ASSO_REPORT.newClients,
  ACTIVITIES_TYPES_ASSO_REPORT.reminders,
  ACTIVITIES_TYPES_ASSO_REPORT.salesOpps
]

function sortByDate(a, b, type) {
  if (type === ACTIVITIES_TYPES_ASSO_REPORT.reminders) {
    return isBefore(new Date(a?.complete_date || a?.due_date), new Date(b?.complete_date || b?.due_date)) ? 1 : -1;
  } else {
    return isBefore(new Date(a?.completed || a?.created_date || a?.close_date), new Date(b?.completed || b?.created_date || b?.close_date)) ? 1 : -1;
  }
}

const ReportContextProvider = ({ children, authenticate }) => {

  const { data: chatMessages, isLoading: isLoadingChatMessage } = useQuery(
    ['chat-message-report', authenticate],
    async () => {
      const reqObj = {
        params: ['report', 'chatMessage'],
        loadingStatusOff: true
      }
      return await getRequest(reqObj)
    },
    {
      cacheTime: CACHE_TIME,
      staleTime: STALE_TIME,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: authenticate,
    }
  )

  const { data: reminders, isLoading: isLoadingReminder } = useQuery(
    ['reminder-report', authenticate],
    async () => {
      const reqObj = {
        params: ['report', 'reminder'],
        loadingStatusOff: true
      }
      const res = await getRequest(reqObj)
      const { reportData } = res
      const formattedData = reportData.map(item => ({
        ...item,
        activity_associate_name: item.associate_name,
        activity_store_name: item.store_name,
        activity_date: item.complete_date,
        activity_associate_id: item.associate_id,
        activity_store_id: item.store_id,
        sales_transaction_amount: item.transaction_amount,
        sales_transaction_store_id: item.transaction_store_id,
        sales_transaction_date: item.sales_transaction_date_time,
      }))
      return {
        reportData: formattedData,
        stats: res.stats,
      }
    },
    {
      cacheTime: CACHE_TIME,
      staleTime: STALE_TIME,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: authenticate,
    }
  )

  const { data: newClients, isLoading: isLoadingNewClients } = useQuery(
    ['new-client-report', authenticate],
    async () => {
      const reqObj = {
        params: ['report', 'newClient'],
        loadingStatusOff: true
      }
      const res = await getRequest(reqObj)
      const { reportData } = res
      const data = reportData.filter(item => item?.sales_transaction_id && item?.sales_transaction_amount && parseFloat(item?.sales_transaction_amount) >= 0)
      return data.filter(item => item.activity_source)

    },
    {
      cacheTime: CACHE_TIME,
      staleTime: STALE_TIME,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: authenticate,
    }
  )

  const { data: newClientsMeta, isLoading: isLoadingNewClientsMeta } = useQuery(
    ['new-client-report-meta', authenticate],
    async () => {
      const reqObj = {
        params: ['report', 'newClientMeta'],
        loadingStatusOff: true
      }
      const res = await getRequest(reqObj)
      const { reportData } = res
      return reportData;

    },
    {
      cacheTime: CACHE_TIME,
      staleTime: STALE_TIME,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: authenticate,
    }
  )

  const { data: salesOpp, isLoading: isLoadingSalesOpp} = useQuery(
    ['sale-opp-report', authenticate],
    async () => {
      const response = await getSalesOpp();

      let overdueDate = new Date();
      response.data.reportData.forEach(item => {
        if (item.salesOppCloseDate && isBefore(new Date(item.salesOppCloseDate), overdueDate)) {
          overdueDate = new Date(item.salesOppCloseDate);
        }
      });

      return {
        opportunityTypes: response.data.opportunityTypes,
        reportData: response.data.reportData,
        overdueDate,
      };
    },
    {
      cacheTime: CACHE_TIME,
      staleTime: STALE_TIME,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: authenticate,
    }
  )

  const allAssociatePerformanceData = useQueries(
    allAssociatePerformanceTypes.map(type => ({
      queryKey: ['associate-report', authenticate, type],
      queryFn: async () => {
        const reqObj = {
          params: ['report', 'associatePerformance', type],
          loadingStatusOff: true
        };
        const response = await getRequest(reqObj);
        return response[type].sort((a, b) => sortByDate(a, b, type));
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: authenticate,
    }
    )
  ))

  const associatesPerformance = useMemo(() => {
    return allAssociatePerformanceData.reduce((acc, assPerf, index) => {
      let newData = assPerf?.data || [];
      const type = allAssociatePerformanceTypes[index];
      acc[type] = [...newData];
      return acc;
    }, {});
  }, [allAssociatePerformanceData])

  const isLoadingAssociatesPerformance = useMemo(() => {
    return allAssociatePerformanceData.filter(({ isLoading }) => isLoading).length > 0;
  }, [allAssociatePerformanceData])

  return (
    <ReportContext.Provider
      value={{
        chatMessages,
        isLoadingChatMessage,
        reminders,
        isLoadingReminder,
        newClients,
        isLoadingNewClients,
        salesOpp,
        isLoadingSalesOpp,
        newClientsMeta,
        isLoadingNewClientsMeta,
        associatesPerformance,
        isLoadingAssociatesPerformance,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export function useReport() {
  const context = useContext(ReportContext);

  if (!context) {
    throw new Error('useReportContext must be used within an ReportContext');
  }
  return context;
}

const mapStateToProps = state => ({ authenticate: state.auth.authenticated });
export default connect(mapStateToProps)(ReportContextProvider);

