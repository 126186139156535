import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import yn from 'yn';
import { connect } from 'react-redux';
import '../chat.scss';
import { msgListDateOrganizer } from '../Helpers/msgListFormatter';
import { getRequest } from '../../../../core/apiRequests';
import {
  setDataCalledFor,
  setMsgSearchText,
  setTriggerRefresh,
  setMsgResetClient,
  setMsgChatMessages,
} from '../../../../reducers/messaging';
import { messageStatusSubject } from '../../../../core/events';

const DEFAULT_LIMIT = 30;

const Messages = props => {
  const [elementLists, setElementList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [storesMap, setStoresMap] = useState({});
  const ref = useRef(null);

  const fetchData = useCallback(async clearMessages => {
    if (!props.messaging.client.chat_id) {
      return true;
    }
    const reqObj = {
      params: ['chatMessages', props.messaging.client.chat_id, 'getChatMessages'],
      query: {
        offset: clearMessages ? 0 : offset,
        type: props.messaging.client.type,
        limit: DEFAULT_LIMIT,
      },
      loadingStatusOff: true,
    };
    const response = await getRequest(reqObj);
    const newMessages = response.data;
    setOffset(response.offset + Math.min(response.limit, response.total));
    setTotalData(response.total);
    const oldMessages = clearMessages ? [] : props.messaging.chatMessages;
    setMsgChatMessages([...oldMessages, ...newMessages]);
  });

  useEffect(() => {
    if (!props.auth.allStoreConversationHistory || (props.messaging.client.type ?? 'C') !== 'C') {
      setFirstLoad(true);
      setOffset(0);
      setTotalData(0);
      fetchData(true);
    }
  }, [props.messaging.client.chat_id, props.messaging.messagesRefresh]);

  const clearSearch = () => {
    setMsgSearchText('');
    setMsgResetClient();
    setTriggerRefresh(!props.messaging.triggerRefresh);
  };

  const scrollToBottom = () => {
    if (ref.current && firstLoad) {
      ref.current.scrollTop = ref.current.scrollHeight;
      setFirstLoad(false);
    }
  };

  const FormatMessageData = () => {
    setElementList(
      msgListDateOrganizer(
        props.messaging.chatMessages,
        props.chatIconColor,
        props.auth.userId,
        props.auth.merchantId,
        storesMap,
        props.messaging.clientPaymentStatus,
        props.type,
        scrollToBottom,
        props.auth.allStoreConversationHistory,
      ),
    );
  };

  useEffect(() => {
    getRequest({ params: ['stores'], loadingStatusOff: true }).then(data => {
      setStoresMap(
        data.reduce((acc, curr) => {
          if (curr.id) {
            Object.assign(acc, { [curr.id]: curr.name });
          }
          return acc;
        }, {}),
      );
    });
    const subscription = messageStatusSubject.subscribe(event => {
      const lastMessage = props.messaging.chatMessages[0];
      if (lastMessage && +event.sent === +lastMessage.sent) {
        lastMessage.status = event.status;
        FormatMessageData();
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    FormatMessageData();
    return () => {
      setDataCalledFor(null);
    };
  }, [props.showLoading, props?.messaging?.dataCalledFor, props.loadingConversation]);

  useEffect(() => {
    FormatMessageData();
  }, [props.messaging.chatMessages, props.messaging.clientPaymentStatus, storesMap]);

  useEffect(() => {
    scrollToBottom();
  }, [props.messaging.chatMessages, props.messaging.clientPaymentStatus, elementLists]);

  if (
    yn(props.auth.merchantConfig.LIMIT_ASSOCIATE_VISIBILITY_ENABLED) &&
    props.auth.role === 'ASSOCIATE' &&
    !props?.messaging?.client?.assignedAssociates?.map(item => +item)?.includes(props.auth.userId)
  ) {
    return (
      <div key="startAConvoPlaceHolder" className="flex-col-center h-100-P">
        <FontAwesomeIcon
          icon="fa-regular fa-lock"
          color="#EBEBEB"
          size="9x"
          // className="color-bdbdbd"
          style={{ paddingBottom: '20px' }}
        />
        <span className="color-bdbdbd fs-14 w-500 p-btm-10">
          You do not have access to view this client. If you need access, please have your manager assign you to this
          client.
        </span>
        {props.messaging.searchText && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <span className="blue-link bold fs-14" onClick={() => clearSearch()}>
            Return to client list
          </span>
        )}
      </div>
    );
  }
  if (elementLists.length > 0) {
    return (
      <div id="clientChatListElement" className="chatBubbleWrapper" ref={ref}>
        <InfiniteScroll
          dataLength={props.messaging.chatMessages.length}
          next={fetchData}
          hasMore={totalData > props.messaging.chatMessages.length}
          /* loader={<LoadingOutlined style={{ fontSize: 24 }} spin />} */
          pullDownToRefreshThreshold={50}
          scrollableTarget="clientChatListElement"
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          inverse
        >
          {elementLists}
        </InfiniteScroll>
      </div>
    );
  }
  return (
    <div key="startAConvoPlaceHolder" className="flex-col-center h-100-P">
      <FontAwesomeIcon
        icon={['fal', 'comment-alt-medical']}
        color="#EBEBEB"
        size="9x"
        // className="color-bdbdbd"
        style={{ paddingBottom: '2px' }}
      />
      <span className="color-bdbdbd fs-12">Start a Conversation</span>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    messaging: state.messaging,
    auth: state.auth,
    showLoading: state.notification.showLoading,
  };
};
export default connect(mapStateToProps, {})(Messages);
