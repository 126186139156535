import React, { useState, useEffect, useMemo, useCallback } from 'react';
import SocketHandler from './components/Layout/SocketHandler';
import TopNavigation from './components/Navigation_V2';
import MobileMenu from './components/Navigation/MobileNav';
import MobileMultiMenu from './components/Navigation/MobileMultiNav';
import SocketNotification from './components/Layout/Notifications/TopNotification';
import SatisfactionSurvey from './components/Layout/SatisfactionSurvey';
import SavingNotification from './components/Layout/Notifications/SavingNotification';
import LoadingNotification from './components/Layout/Notifications/LoadingNotification';
import VersionCheck from './components/Layout/VersionCheck';
import RouterHandler from './core/routerHandler';
import { Redirect, withRouter, useHistory } from 'react-router';
import { ReactQueryDevtools } from 'react-query/devtools'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fass } from '@fortawesome/sharp-solid-svg-icons';
import { resetClientFilters } from './reducers/Filters';
import { wipeReduxState } from './reducers/auth';
import { LoginHandler } from './components/Routes/Login/helpers/loginApi';
import { Alch } from './components/Routes/Login/helpers/Alch';
import { deC, enC } from './components/Routes/Login/helpers/Alch/theCrypt';
import { connect } from 'react-redux';
import './utils/logRocketSetup';
import './App.css';
import ServiceTaskHandler from './components/Layout/ServiceTaskHandler';
import { consoleLoginCheck } from './utils/redirectLogin';
import TopNavigationV2 from './componentsV2/Layout/TopNavigation';
import SidebarV2 from './componentsV2/Layout/SidebarV2';
import NewNavBanner from './componentsV2/Layout/NewNavBanner';
import { useAIAssistant } from './context/AIAssistantContext';
import NoLeadForNow from './components/Navigation_V2/Edison/Components/NoLeadForNow';
import CenteredEdison from './components/Navigation_V2/Edison/Components/LeadComponents/CenteredEdison';
import CenteredEdisonInitial from './components/Navigation_V2/Edison/Components/LeadComponents/CenteredEdisonInitial';
import EdisonHelpMeImprove from './components/Navigation_V2/Edison/Components/EdisonHelpMeImprove';
import EdisonAI from './components/Navigation_V2/Edison/Components/EdisonAI';

library.add(fas, fal, far, fad, fab, fass); // we have the option of using library
// if you add your icon to the library here, you won't have to import it anywhere else
// then use the string name for the icon instead of the variable:
// <FontAwesomeIcon icon="coffee" /> vs <FontAwesomeIcon icon={faCoffee} />
// Also we're bringing in pro font awesome, used as such: <FontAwesomeIcon icon={['fal', 'messages']} /> (gives you light font awesome icon)

const App = withRouter(props => {
  const history = useHistory();
  const [loggedIn, setLoggedIn] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (window.location.hash.length > 0 && window.location.hash.substring(0, 2) === '#!') {
      // This countes an s3 rerouting issue that we had to put in place.
      props.history.replace(window.location.hash.substring(2));
    }
    if (consoleLoginCheck()) {
      gAlch();
    } else {

      const sToken = localStorage.getItem('jwtToken');
      let tokenVal = props.jwtToken;
      if (!tokenVal) {
        tokenVal = sToken;
      }

      if (tokenVal) {
        if (window.location.href.includes('/login')) {
          history.push('/');
          props.selectNav('Home');
        }
        setLoggedIn(true);
        setRedirect(false);
      } else {
        setLoggedIn(false);
        props.logout();
        if (!window.location.href.includes('/Login/reset-password')) {
          setRedirect(true);
        }
      }

      if (!props.jwtToken && tokenVal) {
        tokenLogin(tokenVal);
      }
    }
  }, [props.jwtToken]);

  const tokenLogin = async(tokenVal) => {
    try {
      const newLogin = new LoginHandler('token', tokenVal);
      const stringAcnt = localStorage.getItem('account');
      const account = stringAcnt ? JSON.parse(localStorage.getItem('account')) : '';
      const id = account ? account.id : null;
      const loginResponse = await newLogin.loginHandler({id}, {
        headers: {
          atx: 'f6d31a3a-6bc4-4f54-a7fb',
        },
      });
      if(loginResponse?.error) {
        handleLoginFailure();
      } else {
        console.log("auth: ", props)
        setRedirect(false);
      }
      return;
    }
    catch(e) {
      console.log("[tokenLogin] error: ", e)
      handleLoginFailure();
    }
  }

  const consoleLogin = async ({ tKn, auth }) => {
    setLoggedIn(false);
    const newLogin = new LoginHandler('console');
    const loginResponse = await newLogin.loginHandler({ ...auth, tKn },
      {},
    );

    if(loginResponse?.error) {
      handleLoginFailure();
    } else {
      setLoggedIn(true);
      history.push('/');
    }
    return;
  }

  const handleLoginFailure = () => {
    props.logout();
    setLoggedIn(false);
    setRedirect(true);
  }

  const gAlch = async () => {
    const result = await Alch();
    if (result) {
      const data = deC(result);
      if (data) {
        const params = new URLSearchParams(window.location.search);
        const emailParams = params.get('a');
        const passwordParams = params.get('c');
        if(emailParams && passwordParams) {
          const decodedEmail = atob(emailParams);
          const decodedPassword = atob(passwordParams);
          const auth = {
            username: decodedEmail,
            password: decodedPassword,
          };
          const tkNa = enC({ tKn: data, auth });
          if (tkNa.tKn) {
            consoleLogin(tkNa);
          } else {
            console.log('[gAlch] Error packaging data');
          }
        } else {
          console.log("[App] Missing params: ", {emailParams, passwordParams})
        }
      } else {
        console.log('[gAlch] Error from Alch');
      }
    }
  };


  useEffect(() => {
    if (!window.location.pathname.includes('Clients')) {
      resetClientFilters();
    }
  }, [window.location.pathname]);

  const showSideBar = useMemo(() => {
    const withoutSidebar = [
      '/',
      '/Messaging/inbox',
      '/Clients',
      '/Products',
      '/Reports',
      '/Messaging/mass-messaging',
      '/Messaging/mass-messaging/new-message',
      '/Help/training-videos',
      '/Help/contact-support',
      '/Help/feature-suggestion',
      '/EditProfile',
      '/all-reminders',
      '/all-sales-opp',
      '/all-payments',
    ];

    return !withoutSidebar.includes(window.location.pathname) && !window.location.pathname.includes('/Reports/');
  }, [window.location.pathname]);

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, []);

  const {
    edisonTile,
    setShowLead,
    getLead,
    AIActivity,
    vendorItemViewHandler,
    leadObj,
    setEdisonTile,
    setLeadObj,
    myClient,
    showEllipses,
    setShowEllipses,
    queryEdison,
    showLead
  } = useAIAssistant();

  const edisonTiles = () => {
    switch (edisonTile) {
      case 'noLead':
        return <NoLeadForNow setShowLead={setShowLead} headerText="No activities for now" />;
      case 'loadingComplete':
        return (
          <CenteredEdison
            headerText="Great job!"
            headerSubTxt="Let me look for another one."
            getLead={getLead}
            AIActivity={AIActivity}
            vendorItemViewHandler={vendorItemViewHandler}
          />
        );
      case 'initialLoading':
        return <CenteredEdisonInitial leadObj={leadObj} setEdisonTile={setEdisonTile} />;
      case 'loadingSkipped':
        return (
          <CenteredEdison
            headerText="Thanks for your feedback!"
            headerSubTxt="Let me look for another one.."
            getLead={getLead}
            edisonTile={edisonTile}
            AIActivity={AIActivity}
            vendorItemViewHandler={vendorItemViewHandler}
          />
        );
      case 'helpMeImprove':
        return (
          <EdisonHelpMeImprove
            setShowLead={setShowLead}
            setEdisonTile={setEdisonTile}
            aiActivityId={leadObj.id}
            getLead={getLead}
            setLeadObj={setLeadObj}
            myClient={myClient}
            AIActivity={AIActivity}
          />
        );
      case 'done':
        return <NoLeadForNow setShowLead={setShowLead} headerText="That's all I have for today!" />;
      case 'edisonAI':
        return (
          <EdisonAI
            myClient={myClient}
            merchantId={props.merchantId}
            setShowLead={setShowLead}
            setEdisonTile={setEdisonTile}
            leadObj={leadObj}
            setLeadObj={setLeadObj}
            aiActivityId={leadObj.id}
            getLead={getLead}
            showEllipses={showEllipses}
            setShowEllipses={setShowEllipses}
            queryEdison={queryEdison}
            AIActivity={AIActivity}
            stores={props.stores}
            flags={props.launchFlagsForNav}
          />
        );
      default:
        null;
    }
  };

  return (
    <div className="App">
      {props.navigation.showMobileNav ? <MobileMenu toggleMobileMenu={props.toggleMobileMenu} /> : null}
      {props.navigation.showMobileMultiNav ? <MobileMultiMenu toggleMobileMenu={props.toggleMultiMobileMenu} /> : null}
      <SatisfactionSurvey />
      <SocketNotification />
      <SavingNotification />
      <LoadingNotification />
      <div className="flex-col-start w-100-vw">
        {loggedIn ? <TopNavigationV2 /> : null}
        {showLead && edisonTiles()}
        <div className="flex-row w-100-P h-100-P">
          {(loggedIn && showSideBar) && <SidebarV2 />}
          <div>
            <RouterHandler />
          </div>
        </div>
      </div>
      <SocketHandler />
      <VersionCheck />
      <ServiceTaskHandler merchantId={props.merchantId}/>
      <ReactQueryDevtools initialIsOpen={false} />
      {redirect ? <Redirect to="/login" /> : null}
    </div>
  );
});
const mapStateToProps = state => ({ navigation: state.navigation, notification: state.notification, ...state.auth });
const mapDispatchToProps = dispatch => ({
  selectNav(val) {
    dispatch({
      type: 'SELECT_NAV',
      payload: val,
    });
  },
  toggleMobileMenu(val) {
    dispatch({
      type: 'SHOW_MOBILE_NAV',
      payload: val,
    });
  },
  toggleMultiMobileMenu(val) {
    dispatch({
      type: 'SHOW_MOBILE_MULTI_NAV',
      payload: val,
    });
  },
  logout() {
    dispatch({
      type: 'LOGOUT',
      payload: true,
    });
    wipeReduxState();
    localStorage.setItem('account', '');
    localStorage.setItem('jwtToken', '');
    localStorage.setItem('0391b4dc-aaea-43b8-839a-bd8b4e4e16df', '');
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
