import { getLocalStorageItem } from './getLocalStorageItem';

export const getHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const token = localStorage.getItem('jwtToken');

  if (token) {
    headers.Authorization = token;
    console.log("[getHeaders] Yep token exists");
  } else {
    console.log("[getHeaders] Nope token not exists");
  }

  const account = getLocalStorageItem('account');

  if (account) {
    const USER_ID = account.userId;

    if (USER_ID) {
      headers['x-clientbook-user-id'] = USER_ID;
    }

    const MERCHANT_ID = account.merchantId;

    if (MERCHANT_ID) {
      headers['x-clientbook-merchant-id'] = MERCHANT_ID;
    }
  }

  return headers;
};
