import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getRequest } from '../../../../../core/apiRequests';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';
import Layout from '../../../../Layout/StandardLayout';
import Table from '../../../../Layout/Table';
import Tooltip from '../../../../Layout/ToolTip';
import { setLoadingStatus } from '../../../../../reducers/notification';
import { getTablePaginationData, setTablePagination } from '../../../../../core/actions';

const tableName = 'SALES_TRANS_HISTORY';

const SalesTransHistory = props => {
  const [tableData, setTableData] = useState([]);
  const [first, setFirst] = useState(true);
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    if (props.auth.merchantId) {
      const { page, pageSize } = props.pagination;
      if (first) {
        setTablePagination(
          {
            page: 1,
            pageSize: 50,
          },
          tableName,
        );
        setFirst(false);
      } else {
        setLoadingStatus(true);
        (async () => {
          const reqObj = {
            params: ['salesTrigger', props.auth.merchantId, 'getSalesTransHistory'],
            query: {
              page,
              count: pageSize,
              // selectedStores: JSON.stringify(selectedStores)
            },
          };
          const dataArr = await getRequest(reqObj);
          setLoadingStatus(false);
          const { pageInfo, results } = dataArr;
          if (pageInfo) {
            const { rowCount, pageCount } = pageInfo;
            setTablePagination({ rowCount, pageCount }, tableName);
          }
          if (results && results.length > 0) {
            setTableData(results);
          }
        })();
      }
    }
  }, [props.auth.merchantId, props.pagination.page, props.pagination.pageSize]);

  const processedStatusProcessor = (status, transObj) => {
    const transIdx = `${transObj.transaction_date}${transObj.client_name}${transObj.product_name}${status}`;
    const colorObj = () => {
      switch (status) {
      case 'NOMATCH':
        return {
          tc: '#ff7a7a',
          bgc: '#f9e0e0',
          txt: 'NO MATCH',
          toolTip: 'Sales transaction did not match a trigger rule',
        };
      case 'COMPLETE':
        return {
          tc: '#7fbd31',
          bgc: '#ecf8dd',
          txt: 'MATCH',
          toolTip: 'Sales transaction was processed sucessfully',
        };
      case 'OLD':
        return {
          tc: '#9c9c9c',
          bgc: '#ebebeb',
          txt: 'SKIPPED',
          toolTip: 'Sales transaction was too old at time of processing and was skipped',
        };
      default:
        return { tc: '#9c9c9c', bgc: '#ebebeb', txt: 'PROCESSING', toolTip: 'Sales transaction is being processed' };
      }
    };
    const { bgc, tc, txt, toolTip } = colorObj();
    return (
      <div className="pos-rel">
        <Tooltip
          description={toolTip}
          isShown={isShown}
          idx={transIdx}
          toolTipRight="50px"
          toolTipBottom="30px"
          toolTipWidth="300px"
          arrowLeft="263px"
        />
        <div
          className="w-72 h-22 flex-col-center fs-10 fw-600"
          style={{ backgroundColor: bgc, color: tc }}
          onMouseEnter={() => setIsShown(transIdx)}
          onMouseLeave={() => setIsShown(false)}
        >
          <span>{txt}</span>
        </div>
      </div>
    );
  };

  const tableHeaders = [
    {
      keyName: 'transaction_date',
      title: 'Transaction',
      style: { width: '1%', minWidth: '100px' },
      sortable: false,
    },
    {
      keyName: 'client_name',
      style: { width: '1%', paddingTop: '0px', minWidth: '110px' },
      title: 'Client Name',
      sortable: false,
    },
    {
      keyName: 'description',
      style: { width: '5%', paddingTop: '0px', minWidth: '110px' },
      title: 'Product Name',
      sortable: false,
      render: (t, o) => t || o.product_name,
    },
    {
      keyName: 'category',
      style: { width: '5%', paddingTop: '0px' },
      title: 'Category',
      sortable: false,
    },
    {
      keyName: 'unit_price',
      style: { width: '1%', paddingTop: '0px', minWidth: '78px' },
      title: 'Unit Price',
      sortable: false,
      render: t => {
        return `$${t || 0}`;
      },
    },
    {
      keyName: 'processed_status',
      style: { width: '1%', paddingTop: '0px' },
      title: 'Status',
      sortable: false,
      render: (t, obj) => {
        return processedStatusProcessor(t, obj);
      },
    },
    {
      keyName: 'date_processed',
      style: { width: '1%', paddingTop: '0px', minWidth: '135px' },
      title: 'Processed Time',
      sortable: false,
      render: t => {
        if (t) {
          return moment(t, 'YYYYMMDDHHmmss').format('MM/DD/YYYY @ hh:mm:A');
        }
        return '';
      },
    },
  ];

  const rowClickHandler = () => {
    console.log('Not implemented.');
  };
  return (
    <Layout title="Sales Log" description="View all sales transactions below" classProps="standard-width-with-sidebar">
      {tableData.length > 0 ? (
        <Table
          tableClassName="lg-table-sidebar rem-pad-width"
          data={tableData}
          tableHeaders={tableHeaders}
          searchable={false}
          paginationV2
          firstInc="View 50"
          tableName={tableName}
          onRowClick={rowClickHandler}
        />
      ) : (
        <span className="body-row flex-col-center text-light-gray">No Data Available</span>
      )}
    </Layout>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
  pagination: getTablePaginationData(state, tableName),
});
export default connect(mapStateToProps, {})(SalesTransHistory);
