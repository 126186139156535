import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import associateRouter from './permissionRouters/associateRouter';
import managerRouter from './permissionRouters/managerRouter';
import adminRouter from './permissionRouters/adminRouter';
import NoRouter from './permissionRouters/noRouter';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { addLoadingList, removeLoadingList } from '../reducers/notification';
import { v4 } from 'uuid';
import NotFound from '../components/Routes/NotFound';

const routerHandler = props => {
  const [elRouter, setRouter] = useState(associateRouter);
  const [loading, setLoading] = useState(true);
  const componentMounted = useRef(true);

  const launchDarklyProvider = async router => {
    const uuid = v4();
    addLoadingList(uuid);
    const LDProvider = await asyncWithLDProvider({
      clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
      context: {
        kind: 'merchant',
        key: props?.auth?.licenseKey,
      },
      // options: { /* ... */ }
    });
    removeLoadingList(uuid);
    setLoading(false);
    setRouter(
      <LDProvider>
        {router()}
      </LDProvider>
    );
  };

  useEffect(() => {
    if (props.auth.userId) {
      switch (props.auth.role) {
        case 'ADMIN':
          launchDarklyProvider(adminRouter);
          break;
        case 'MANAGER':
          launchDarklyProvider(managerRouter);
          break;
        case 'ASSOCIATE':
          launchDarklyProvider(associateRouter);
          break;
        default:
          launchDarklyProvider(NoRouter);
          break;
      }
      return;
    }
    return () => {
      componentMounted.current = false;
    };
  }, [props.auth.userId]);

  return (
    <>
        {elRouter}
        {!loading && (
          <Route path="/*" component={NotFound} displayName="NotFound" />
        )}
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(routerHandler);
