import { useQuery } from 'react-query';
import { getClients } from '../../services/clients';

function useGetClients({ query = {}, loadingStatusOff = false, axiosConfig = {}, useQueryOptions = {} }) {

  return useQuery(['clients', query], async () => {

    return await getClients({
      query,
      loadingStatusOff,
      axiosConfig
    })
  }, {
    refetchOnWindowFocus: false,
    initialData: [],
    ...useQueryOptions
  });
}

export default useGetClients;
