import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import StandardLayoutV2 from '../../../Layout/StandardLayoutV2';
import { Button, Col, Form, Input, InputNumber, message, Popconfirm, Row, Switch, Tag } from 'antd-v5';
import { EditOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import { getRequest } from '../../../../core/apiRequests';
import ActionCard from './components/ActionCard';
import CategoryModal from './components/CategoryModal';
import { useHistory, useParams } from 'react-router-dom';
import { createSalesTrigger, deleteSalesTrigger, updateSalesTrigger } from '../../../../services/salesTrigger';
import { Controller, useForm } from 'react-hook-form';
import yn from 'yn';
import { FormItemVertical } from '../../../CustomizedAntDesign/FormItemVertical';

const AddEditSalesTrigger = ({auth}) => {

  const history = useHistory();
  const { triggerId } = useParams();
  const isEdit = useMemo(() => window.location.href.includes('edit-trigger'), [window.location.href]);
  const [selectedAction, setSelectedAction] = useState(null);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [saving, setSaving] = useState(false);

  const showCategoryModal = useCallback(() => {
    setOpenCategoryModal(true);
  }, []);

  const { data: options, isFetching} = useQuery(['options', auth.merchantId], async () => {
    const reqObj = {
      params: ['salesTrigger', auth?.merchantId, 'triggersAndSales']
    }
    const response = await getRequest(reqObj);
    return response.length > 0 ?
      response[0] :
      {
        categories: [],
        actions: [],
        triggers: [],
      };
  }, {
    initialData: {
      categories: [],
      actions: [],
      triggers: [],
    },
    refetchOnWindowFocus: false,
  });

  const { control, setValue, watch, reset, resetField, getValues, handleSubmit, formState: {errors} } = useForm();

  const handleAddActions = useCallback(() => {
    const previousActions = getValues('actions') || [];
    const addNewId = `new-${Math.floor(Math.random() * 1000) + 1}`;
    const firstActionId = options?.actions?.filter(act => act.type === 'REMINDER')[0]?.action_id;
    let newActions = {
      ...previousActions,
      [addNewId]: {
        id: addNewId,
        type: 'REMINDER',
        action_id: firstActionId,
        trigger_action_id: addNewId,
        action_interval: 'DAY',
        action_scale: 3,
        action_repeat: false,
        repeat_interval: null,
        repeat_scale: null,
        action_expires: true,
        expire_interval: 'DAY',
        expire_scale: 4,
        assign_type: 'assignment'
      }
    };
    setValue('actions', newActions);
    setSelectedAction(addNewId);
  }, [options])

  useEffect(() => {
    if(!isFetching) {
      if(triggerId && options?.triggers?.length > 0) {
        const findTrigger = options?.triggers?.find(trig => trig.trigger_id === parseInt(triggerId));
        if(findTrigger?.trigger_id) {
          const actions = Object?.keys(findTrigger.actionConfigs)?.map(key => findTrigger.actionConfigs[key]);
          const formated = {
            name: findTrigger.name,
            active: !!findTrigger.active,
            categories: findTrigger.categories,
            max_price: findTrigger.max_price,
            min_price: findTrigger.min_price,
          }
          let updatedActions = {};
          actions?.forEach(action => {
            updatedActions[action.trigger_action_id] = {
              id: action.trigger_action_id,
              type: action.type,
              action_id: action.action_id,
              trigger_action_id: action.trigger_action_id,
              action_interval: action.action_interval,
              action_scale: action.action_scale,
              description: action.description,
              expire_interval: action.expire_interval,
              expire_scale: action.expire_scale,
              repeat_interval: action.repeat_interval,
              repeat_scale: action.repeat_scale,
              action_repeat: !!action.repeat_scale,
              action_expires: !!action?.expire_scale,
              action_expires_overdue: action.repeat_interval === 'DAY' && action.repeat_scale === 0,
              assign_type: action.assign_type,
            }
          })

          reset({
            ...formated,
            actions: updatedActions
          });
        } else {
          history.push('/Configuration/sales-triggers');
        }
      } else {
        reset({
          active: true,
        })
      }
    }
  }, [triggerId, options, history, isFetching]);

  const handleSave = useCallback(async (data) => {
    let parsedActions = Object.keys(data?.actions || {})?.map(key => data?.actions[key]) || [];
    try {
      setSaving(true);
      if(triggerId) {
        await updateSalesTrigger({
          data: {
            ...data,
            actions: parsedActions?.filter(act => !act.isDeleted),
            id: triggerId,
          },
          loadingStatusOff: true,
        })
        message.success('Trigger updated successfully');
      } else {
        await createSalesTrigger({
          data: {
            ...data,
            actions: parsedActions?.filter(act => !act.isDeleted),
          },
          loadingStatusOff: true,
        })
        message.success('Trigger saved successfully');
      }
      history.push('/Configuration/sales-triggers');
    } catch (error) {
      console.log(error)
      message.error('Failed to save trigger');
    } finally {
      setSaving(false);
    }
  }, [triggerId, errors]);

  const handleCancel = useCallback(() => {
    reset();
    history.push('/Configuration/sales-triggers');
  }, []);

  const actions = useMemo(() => {
    if(!watch('actions')) return [];
    return Object.keys(watch('actions'))?.map(key => watch('actions')[key]).sort((a, b) => {
      let aTime = a.action_interval === 'DAY' ? a.action_scale : a.action_interval === 'WEEK' ? a.action_scale * 7 : a.action_scale * 30;
      let bTime = b.action_interval === 'DAY' ? b.action_scale : b.action_interval === 'WEEK' ? b.action_scale * 7 : b.action_scale * 30;
      return aTime - bTime;
    });
  }, [watch('actions'), selectedAction]);

  const handleDeleteTrigger = useCallback(async () => {
    try {
      setSaving(true);
      await deleteSalesTrigger({
        id: triggerId,
        loadingStatusOff: true,
      })
      message.success('Trigger deleted successfully');
    } catch (error) {
      message.error('Failed to delete trigger');
    } finally {
      setSaving(false);
      history.push('/Configuration/sales-triggers');
    }
  }, [triggerId, saving, history]);

  return (
    <StandardLayoutV2
      title={isEdit ? 'Edit Sales Trigger' : 'Add Sales Trigger'}
      breadcrumb={[
        {
          title: 'Setup',
        },
        {
          path: '/Configuration/sales-triggers',
          title: 'Sales Triggers',
        },
        {
          title: isEdit ? 'Edit Sales Trigger' : 'Add Sales Trigger',
        },
      ]}
      classProps="flex-col-left"
      styleProps={{ justifyContent: 'flex-start' }}
      containerStyles={{ backgroundColor: 'transparent'}}
      actions={
        <>
          <Button
            type='primary'
            onClick={handleSubmit(handleSave)}
            className='m-right-8'
            loading={saving}
            disabled={saving || isFetching || !!selectedAction}
          >
            Save
          </Button>
          <Button
            type='default'
            className='m-right-8'
            onClick={handleCancel}
            disabled={saving}
          >
            Cancel
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this trigger?"
            onConfirm={handleDeleteTrigger}
            okText="Yes"
            cancelText="No"
            icon={<QuestionCircleOutlined />}
            disabled={saving}
            description="This action cannot be undone"
            placement='bottom'
          >
            <Button
              color="danger"
              variant='solid'
              disabled={saving}
            >
              Delete
            </Button>
          </Popconfirm>
        </>
      }
    >
      {!isFetching && (
        <>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            layout="horizontal"
            style={{ width: 540 }}
            colon={false}
            labelWrap={true}
            disabled={saving}
          >
            <Form.Item
              label="Name"
              validateStatus={errors?.name ? 'error' : ''}
              help={errors?.name?.message}
            >
              <Controller
                name="name"
                rules={{ required: 'Name is required' }}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder='Name'
                    status={errors?.name ? 'error' : ''}
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              label="Active"
            >
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Controller
                  name="active"
                  control={control}
                  render={({ field }) => (
                    <Switch defaultChecked defaultValue={true} {...field}/>
                  )}
                />
              </div>
            </Form.Item>
            <Form.Item
              label="Amount" style={{ marginBottom: 0 }}>
              <Form.Item
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              >
                <Controller
                  name="min_price"
                  control={control}
                  render={({ field }) => (
                    <InputNumber addonBefore="$" placeholder='Minimum' {...field}/>
                  )}
                />
              </Form.Item>
              <span
                style={{ display: 'inline-block', width: '16px', textAlign: 'center' }}
              />
              <Form.Item
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                validateStatus={errors?.max_price ? 'error' : ''}
                help={errors?.max_price?.message}
              >
                <Controller
                  name="max_price"
                  control={control}
                  rules={{ validate: value => (!watch('min_price') || !value || (value && value >= watch('min_price'))) || 'Maximum must be greater than minimum' }}
                  render={({ field }) => (
                    <InputNumber
                      addonBefore="$"
                      placeholder='Maximum' {...field}
                      status={errors?.max_price ? 'error' : ''}
                    />
                  )}
                />
              </Form.Item>
            </Form.Item>
            <Row style={{ marginBottom: 16 }}>
              <Col span={4}>Categories</Col>
              <Col span={20} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
              {watch('categories')?.length > 0 && (
                <div className="flex-row m-btm-16 gap-8 w-100-P" style={{ flexWrap: 'wrap' }}>
                  {watch('categories')?.map(category => (
                    <Tag key={category}>{category}</Tag>
                  ))}
                </div>
              )}
                <Button icon={<EditOutlined />} onClick={showCategoryModal}>
                  {`${watch('categories')?.length > 0 ? 'Edit' : 'Add'} Categories`}
                </Button>
              </Col>
            </Row>
            <Row >
              <Col span={4}>Actions</Col>
              <Col span={20} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
              {actions.map((action, index) => (
                <ActionCard
                  action={action}
                  edit={action.id === selectedAction}
                  index={action.id}
                  key={action.id}
                  options={options}
                  setSelectedAction={setSelectedAction}
                  triggerId={triggerId}
                  setValue={setValue}
                  Controller={Controller}
                  control={control}
                  watch={watch}
                  resetField={resetField}
                />
              ))}
                <Button icon={<PlusOutlined />} onClick={handleAddActions}>
                  Add Action
                </Button>
              </Col>
            </Row>
          </Form>
          {openCategoryModal && (
            <CategoryModal
              open={openCategoryModal}
              onClose={() => setOpenCategoryModal(false)}
              allCategories={options.categories}
              previewsSelectedCategories={watch('categories')}
              triggerId={triggerId}
              setValue={setValue}
            />
          )}
        </>
      )}

    </StandardLayoutV2>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
  salesTriggers: state.salesTriggers,
});

export default connect(mapStateToProps)(AddEditSalesTrigger);

