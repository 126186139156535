/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState} from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import QRCode from 'react-qr-code';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import { setLaunchFlagsForNav } from '../../../reducers/auth';
import Footer from './Footer';

import './standardLayout.css';
import '../layout.css';
import StyledLink from '../StyledLink';
// import SmallScreenMenu from './SmallScreenMenu';
import MobileMultiNav from '../../Navigation/MobileMultiNav';
import { setSelectedClientList } from '../../../reducers/client';

const Layout = props => {
  const history = useHistory();
  const isMounted = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const flags = useFlags();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setLaunchFlagsForNav(flags);
  }, [flags?.projectEdison]);

  const [screen, setScreen] = useState(true);

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
      width,
    };
  }

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
  };

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 800) {
      setScreen(false);
    }
  }, [width]);

  const clickFunc = click => {
    if (click === 'client') {
      return setSelectedClientList([]);
    }
  };

  const breadCrumbHandler = () => {
    const elementArr = [];
    if (props.breadCrumbs) {
      props.breadCrumbs.forEach((bc, i) => {
        const lastElement = !(props.breadCrumbs[i + 1] && props.breadCrumbs[i + 1].title);
        const doubleArrow = (
          <span className="bread-crumb-arrows" key={bc.title + i}>
            {'>>'}
          </span>
        );
        const renderElement = bc.to ? (
          <StyledLink styleprops={{ color: '#bdbdbd' }} key={i} to={`${bc.to}`} onClick={() => clickFunc(bc.click)}>
            {bc.title}
          </StyledLink>
        ) : (
          <span style={lastElement ? { color: 'black', fontWeight: 500 } : null} key={i}>
            {bc.title}
          </span>
        ); // the last element shouldn't be a link
        elementArr.push(renderElement);
        if (!lastElement) {
          // if it's not the last element
          elementArr.push(doubleArrow);
        }
      });
    }
    return elementArr;
  };

  return (
    <div
      id="scrollWatchId"
      className={`layout-main scrollbar-y rem-pad-width ${props?.classProps || ''} p-left-30-imp`}
      style={props.styleProps}
    >
      <div
        className="layout-header flex-row-spacebetween-wrap m-btm-28"
        style={{
          alignContent: 'flex-start',
          flexWrap: 'wrap-reverse',
          maxWidth: props.maxWidth ?? '1200px',
          ...props.headerStyles,
        }}
      >
        <div className="layout-header-description flex-col-left w-100-P">
          <div
            className="layout-header flex-row-spacebetween-wrap w-100-P"
            style={{
              alignContent: 'flex-start',
              flexWrap: 'nowrap',
              // flexDirection: 'row-reverse'
            }}
          >
            <span className="layoutTitle m-top-8 mq-fs-20 align-left flex-row-nospacebetween-nowrap">

              {props.backLink && (
                <FontAwesomeIcon
                  size="xs"
                  icon={['fas', 'arrow-left']}
                  onClick={() => history.push(props.backLink)}
                  className="standard-layout__back-btn"
                />
              )}

              {props.titleImage && (
                <img
                  src={props.titleImage}
                  alt={props.title}
                  className={`standard-layout__title-image standard-layout__title-image-${
                    props.titleImageSize || 'md'
                  }`}
                />
              )}

              {props.backArrow ? (
                <StyledLink styleprops={{ color: '#000' }} to={props.backArrow}>
                  <FontAwesomeIcon
                    icon={['fas', 'arrow-left']}
                    className="pointer m-right-10"
                    style={{
                      width: '16px',
                      height: '16px',
                      display: 'flex',
                    }}
                  />
                </StyledLink>
              ) : null}
              <span className="">{props.title}</span>
              <span className="pointer" onClick={() => props.iconAction()}>
                &nbsp; {props.icon ?? ''}
              </span>
            </span>

            {props.actions && <div className="layout-actions">{props.actions}</div>}
            {/* Chriswf commented this out as it seems to create a duplicate UserProfile outside of the black task bar  */}
            {/* <div className="layoutMenus m-left-15 userProfileSmallScreen">
              <UserProfileMenu
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                setShowList={setShowList}
                showQr={showQr}
                setShowQr={setShowQr}
                onClick={() => props.toggleMultiMobileMenu(!props.showMobileMultiNav)}
              />
            </div> */}
          </div>
          <span className="layoutBreadcrumb">{breadCrumbHandler()}</span>
          {props.description ? <span className="layoutDesc">{props.description}</span> : null}
        </div>
        <div className="flex-row-spacebetween-nowrap w-100-P-on-1200" />
        <div className="flex-row-spacebetween-nowrap w-100-P-on-1200" />
        {showMenu ? (
          <MobileMultiNav
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            toggleMobileMenu={props.toggleMultiMobileMenu}
          />
        ) : null}
      </div>
      <div className="w-100-P height-auto" style={{ maxWidth: props.maxWidth ?? '1200px', ...props.contentStyles }}>
        {props.children}
      </div>
      <Footer footerClass={props.footerClass} />
      <Modal open={showQr}>
        <QRCode value="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" />
      </Modal>
    </div>
  );
};
const mapStateToProps = state => ({
  ...state.navigation,
  ...state.auth,
});
const mapDispatchToProps = dispatch => ({
  toggleMobileMenu(val) {
    dispatch({
      type: 'SHOW_MOBILE_NAV',
      payload: val,
    });
  },
  toggleMultiMobileMenu(val) {
    dispatch({
      type: 'SHOW_MOBILE_MULTI_NAV',
      payload: val,
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
