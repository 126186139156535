import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './CBRoute';
//import Home from '../../components/Routes/Home';
import Home from '../../components/Routes/HomeDashboardMetrics';
// import Help from "../components/Routes/Help";
import ContactSupport from '../../components/Routes/Help/ContactSupport';
import TrainingVideos from '../../components/Routes/Help/TrainingVideos';
import Login from '../../components/Routes/Login/MainLogin';
import ConsoleLogin from '../../components/Routes/Login/ConsoleLogin';
import ResetPassword from '../../components/Routes/Login/ResetPassword';
import ServiceLogin from '../../components/Routes/Login/ServiceLogin';


// Today
import Today from '../../components/Routes/Today';
import TodayAllReminders from '../../components/Routes/Today/Reminders';
import TodayAllAllSalesOpps from '../../components/Routes/Today/SalesOpp';
import TodayAllPayments from '../../components/Routes/Today/Payments';

// Payment Activity
import PaymentActivity from '../../components/Routes/Payments/Activity';
import PaymentDetails from '../../components/Routes/Payments/Details';

// products
import Products from '../../components/Routes/Products';

// stores
import Stores from '../../components/Routes/Stores';
import EditStore from '../../components/Routes/Stores/EditStore';
import StoreDashboard from '../../components/Routes/Stores/StoreDashboard';

// associates
import Associates from '../../components/Routes/Associates';
import AddAssociate from '../../components/Routes/Associates/AddAssociate';
import AssociateDetails from '../../components/Routes/Associates/AssociateDetails';

// clients
import Clients from '../../components/Routes/ClientsV2';

// Edit Profile
import EditProfile from '../../components/Routes/EditProfile';
import ChangePassword from '../../components/Routes/EditProfile/ChangePassword';

// Messaging
import Messaging from '../../components/Routes/Messaging';
import MassMessaging from '../../components/Routes/Messaging/MassMessaging';
import MassMessageDetails from '../../components/Routes/Messaging/MassMessaging/MassMessageDetails';
import NewMassMessage from '../../components/Routes/Messaging/MassMessaging/NewMassMessage';
import WhatsApp from '../../components/Routes/Messaging/WhatsApp';
import AllTemplates from '../../components/Routes/Messaging/AllTemplates';
import AutoMessageRuntime from '../../components/Routes/Messaging/AutoMessages/AutoMessageRuntime';
import AutoMessageHistory from '../../components/Routes/Messaging/AutoMessages/AutoMessageHistory';
import AllAutoMessages from '../../components/Routes/Messaging/AutoMessages/AllAutoMessages';
import ChatInbox from '../../components/Routes/Messaging/ChatInbox';

// Reports
import AssociateMessages from '../../components/Routes/Reports/AssociateMessages';
import ClientTagsbyAssoc from '../../components/Routes/Reports/ClientTagsbyAssoc';
import Leaderboard from '../../components/Routes/Reports/Leaderboard';
import ClientEngagementByActivity from '../../components/Routes/Reports/ClientEngagementByActivity';
import SalesPipelineByAssociate from '../../components/Routes/Reports/SalesPipelineByAssociate';
import CreatedOppByAssoc from '../../components/Routes/Reports/CreatedOppByAssoc';
import AppOpensByAssoc from '../../components/Routes/Reports/AppOpensByAssoc';
import AssociateActivity from '../../components/Routes/Reports/AssociateActivity';
import SalesOpportunity from '../../components/Routes/Reports/SalesOpportunity';
import GoogleReviewRequests from '../../components/Routes/Reports/GoogleReviewRequests';
import IncompleteReminders from '../../components/Routes/Reports/IncompleteReminders';
import OutboundVsInbound from '../../components/Routes/Reports/OutboundVsInbound';
import ClientContacts from '../../components/Routes/Reports/ClientContacts';
import AutoMessages from '../../components/Routes/Reports/AutoMessages';
import Conversations from '../../components/Routes/Reports/Conversations';
import AttributedSales from '../../components/Routes/Reports/AttributedSales';
import Timeliness from '../../components/Routes/Reports/Timeliness';
import RecentNotes from '../../components/Routes/Reports/RecentNotes';
import bankTransaction from '../../components/Routes/Reports/BankTransaction';
import AmountProcessed from '../../components/Routes/Reports/AmountProcessed';
import AmountPaid from '../../components/Routes/Reports/AmountPaid';
import TimeToPay from '../../components/Routes/Reports/TimeToPay';
import PaymentRequests from '../../components/Routes/Reports/Payments';
import GoogleReviews from '../../components/Routes/Reports/GoogleReviews';
import Automations from '../../components/Routes/Reports/Automations';
import SalesOpp from '../../components/Routes/Reports/SalesOpp';
import Payments from '../../components/Routes/Reports/Payments';
import NewClients from '../../components/Routes/Reports/NewClients';
import MassMessage from '../../components/Routes/Reports/MassMessage';
import Sales from '../../components/Routes/Reports/Sales';
import AssociatePerformance from '../../components/Routes/Reports/AssociatePerformance';
import LandingPage from '../../components/Routes/Reports/LandingPage';


import NotFound from '../../components/Routes/NotFound';
import FeatureSuggestion from "../../components/Routes/Help/FeatureSuggestion";
import AddEditProducts from '../../components/Routes/Products/AddEditProduct';
import ProductPage from '../../components/Routes/Products/ProductPage';
import ClientRedirect from '../../components/Routes/ClientsV2/ClientRedirect';
import GoogleReviewStore from '../../components/Routes/Reports/GoogleReviewStore';

export default () => (
  <Switch>
    <Route path="/Home" exact component={Home} displayName="Home" />
    <Route path="/Login" exact component={Login} displayName="Login" />
    <Route path="/Login/reset-password" component={ResetPassword} displayName="ResetPassword" />
    <Route
      path="/Login/console-login"
      component={ConsoleLogin}
      displayName="ConsoleLogin"
    />
    <Route path="/service-login/:serviceType/:serviceId" component={ServiceLogin} />
    {/* Associates */}
    <Route path="/Associates" exact component={Associates} displayName="Associates" />
    <Route path="/Associates/add-associate" exact component={AddAssociate} displayName="AddAssociate" />
    <Route path="/Associates/edit-associate/:associateId" exact component={AddAssociate} displayName="AddAssociate" />
    <Route
      path="/Associates/associate-details/:associateId"
      component={AssociateDetails}
      displayName="AssociateDetails"
    />
    {/* Clients */}
    <Route
      path="/Clients/client-details/:clientId/opp-details/:oppId"
      exact
      component={ClientRedirect}
      displayName="OpportunityDetails"
    />
    <Route
      path="/Clients/client-details/:clientId/reminders"
      exact
      component={ClientRedirect}
      displayName="ViewAllReminders"
    />
    <Route
      path="/Clients/client-details/:clientId/salesopps"
      exact
      component={ClientRedirect}
      displayName="ViewAllSalesOpps"
    />
    <Route
      path="/Clients/client-details/:clientId/activities"
      exact
      component={ClientRedirect}
      displayName="ViewAllActivities"
    />
    <Route
      path="/Clients/client-details/:clientId/client-purchases"
      exact
      component={ClientRedirect}
      displayName="ViewAllClientPurchases"
    />
    <Route
      path="/Clients/client-details/:clientId/collection-details/:collectionId"
      exact
      component={ClientRedirect}
      displayName="ViewAllCollections"
    />
    <Route path="/Clients/client-details/:clientId" component={ClientRedirect} displayName="ClientDetails" />
    <Route path="/Clients/edit-client-tags" component={ClientRedirect} displayName="EditClientTags" />
    <Route path="/Clients/edit-client-associates" component={ClientRedirect} displayName="EditClientAssociates" />
    <Route path="/Clients/add-client" exact component={ClientRedirect} />
    <Route path="/Clients/edit-client-info/:clientId" exact component={ClientRedirect} displayName="AddEditClient" />
    <Route path="/Clients" exact component={Clients} displayName="Clients" />

    {/* Edit Profile */}
    <Route path="/EditProfile" exact component={EditProfile} displayName="EditProfile" />
    <Route path="/EditProfile/change-password" exact component={ChangePassword} displayName="ChangePassword" />
    {/* Help */}
    <Route path="/Help/training-videos" exact component={TrainingVideos} displayName="TrainingVideos" />
    <Route path="/Help/contact-support" exact component={ContactSupport} displayName="ContactSupport" />
    <Route path="/Help/feature-suggestion" exact component={FeatureSuggestion} displayName="FeatureSuggestion" />
    {/* Reports */}
    <Route path="/Reports/associate-messages" exact component={AssociateMessages} displayName="AssociateMessages" />
    <Route path="/Reports/by-associate" exact component={ClientTagsbyAssoc} displayName="ClientTagsbyAssoc" />
    <Route path="/Reports/client-engagement" exact component={Leaderboard} displayName="Leaderboard" />
    <Route
      path="/Reports/engagement-by-activity"
      exact
      component={ClientEngagementByActivity}
      displayName="ClientEngagementByActivity"
    />
    <Route
      path="/Reports/sales-pipelines-by-associate"
      exact
      component={SalesPipelineByAssociate}
      displayName="SalesPipelineByAssociate"
    />
    <Route
      path="/Reports/created-opportunity-by-associate"
      exact
      component={CreatedOppByAssoc}
      displayName="CreatedOppByAssoc"
    />
    <Route path="/Reports/app-opens-by-associate" exact component={AppOpensByAssoc} displayName="AppOpensByAssoc" />
    <Route path="/Reports/associate-activity" exact component={AssociateActivity} displayName="AssociateActivity" />
    <Route path="/Reports/sales-opportunity" exact component={SalesOpportunity} displayName="SalesOpportunity" />
    <Route
      path="/Reports/google-review-requests"
      exact
      component={GoogleReviewRequests}
      displayName="GoogleReviewRequests"
    />
    <Route path="/Reports/google-review-store" exact component={GoogleReviewStore} />
    <Route path="/Reports/active-reminders" exact component={IncompleteReminders} displayName="IncompleteReminders" />
    <Route path="/Reports/outbound-vs-inbound" exact component={OutboundVsInbound} displayName="OutboundVsInbound" />
    <Route path="/Reports/client-contacts" exact component={ClientContacts} displayName="ClientContacts" />
    <Route path="/Reports/auto-messages" exact component={AutoMessages} displayName="AutoMessages" />
    <Route path="/Reports/conversations" exact component={Conversations} displayName="Conversations" />
    <Route path="/Reports/attributed-sales" exact component={AttributedSales} displayName="AttributedSales" />
    <Route path="/Reports/timeliness" exact component={Timeliness} displayName="Timeliness" />
    <Route path="/Reports/recent-notes" exact component={RecentNotes} displayName="RecentNotes" />
    <Route path="/Reports/bank-transactions" exact component={bankTransaction} displayName="bankTransaction" />
    <Route path="/Reports/amount-requested" exact component={AmountProcessed} displayName="AmountRequested" />
    <Route path="/Reports/amount-paid" exact component={AmountPaid} displayName="AmountPaid" />
    <Route path="/Reports/time-to-pay" exact component={TimeToPay} displayName="TimeToPay" />
    <Route path="/Reports/payment-requests" exact component={PaymentRequests} displayName="PaymentRequests" />

    {/* New Reports */}
    <Route path="/Reports" exact component={LandingPage} displayName="Reports" />
    <Route path="/Reports/payment-requests" exact component={Payments} displayName="PaymentRequests" />
    <Route path="/Reports/google-review" exact component={GoogleReviews} displayName="GoogleReviews" />
    <Route path="/Reports/automations" exact component={Automations} displayName="Automations" />
    <Route path="/Reports/sales-opp" exact component={SalesOpp} displayName="SalesOpp" />
    <Route path="/Reports/new-clients" exact component={NewClients} displayName="NewClients" />
    <Route path="/Reports/mass-message" exact component={MassMessage} displayName="MassMessage" />
    <Route path="/Reports/sales" exact component={Sales} displayName="Sales" />
    <Route path="/Reports/associate-performance" exact component={AssociatePerformance} displayName="AssociatePerformance" />

    {/* Payment Activity */}
    <Route path="/Payments/activity" exact component={PaymentActivity} displayName="PaymentActivity" />
    <Route path="/Payments/details/:id" exact component={PaymentDetails} displayName="PaymentDetails" />
    {/* Products */}
    <Route path="/Products" exact component={Products} displayName="Products" />
    <Route path="/Products/add-product" exact component={AddEditProducts} displayName="Products"/>
    <Route path="/Products/edit-product/:productId" exact component={AddEditProducts} displayName="Products"/>
    <Route path="/Products/:productId" exact component={ProductPage} displayName="Product Details" />
    {/* Stores */}
    <Route path="/Stores" exact component={Stores} displayName="Stores" />
    <Route path="/Stores/edit-store/:storeId" exact component={EditStore} displayName="EditStore" />
    <Route path="/Stores/store-dashboard/:storeId" exact component={StoreDashboard} displayName="StoreDashboard" />
    {/* Today */}
    <Route path="/" exact component={Today} />
    <Route path="/all-reminders" exact component={TodayAllReminders} displayName="TodayAllReminders" />
    <Route path="/all-sales-opp" exact component={TodayAllAllSalesOpps} displayName="TodayAllAllSalesOpps" />
    <Route path="/all-payments" exact component={TodayAllPayments} displayName="TodayAllPayments" />
    {/* Messaging */}
    <Route path="/Messaging" exact component={Messaging} displayName="Messaging" />
    <Route path="/Messaging/mass-messaging" exact component={MassMessaging} displayName="MassMessaging" />
    <Route
      path="/Messaging/mass-messaging/message-details/:messageId"
      exact
      component={MassMessageDetails}
      displayName="MassMessageDetails"
    />
    <Route path="/Messaging/mass-messaging/new-message" exact component={NewMassMessage} displayName="NewMassMessage" />
    <Route path="/Messaging/WhatsApp" exact component={WhatsApp} displayName="WhatsApp" />
    <Route path="/Messaging/all-templates" exact component={AllTemplates} displayName="AllTemplates" />
    <Route path="/Messaging/auto-messages" exact component={AllAutoMessages} displayName="AllAutoMessages" />
    <Route path="/Messaging/auto-msg-runtime" exact component={AutoMessageRuntime} displayName="AutoMessageRuntime" />
    <Route path="/Messaging/auto-msg-history" exact component={AutoMessageHistory} displayName="AutoMessageHistory" />
    <Route path="/Messaging/inbox" exact component={ChatInbox} displayName="Inbox" />
  </Switch>
);
