import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Table from '../../../../Layout/Table';
import Layout from '../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';

import { getList } from '../../../../../core/apiRequests';
import {
  setTablePaginationSortColumn,
  setTablePaginationAscendColumn,
  notificationError,
  getTablePaginationData,
} from '../../../../../core/actions';
import 'react-datepicker/dist/react-datepicker.css';
import { setPipelineName } from '../../../../../reducers/salesPipeline';
import DropdownSelect from '../../../../Layout/DropdownSelect';
import StyledLink from '../../../../Layout/StyledLink';

const tableName = 'PIPELINE';

const ViewPipeline = props => {
  const todayDate = new Date();
  const [tableData, setTableData] = useState([]);
  const [first, setFirst] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(todayDate.setDate(todayDate.getDate() - 90));
  const [endDate, setEndDate] = useState(new Date());
  const salesOptionsConfig = [
    { value: '', label: 'All Stages' },
    { value: 'open', label: 'All Open' },
    { value: 'closed', label: 'All Closed' },
  ];
  const [stageOptions, setStageOptions] = useState([...salesOptionsConfig]);
  const [createdByOptions, setCreatedByOptions] = useState([{ value: '', label: 'Created By - All' }]);
  const [filterStage, setFilterStage] = useState('');
  const [filterCreatedBy, setFilterCreatedBy] = useState('');

  useEffect(() => {
    searchQuery('');
  }, [
    startDate,
    endDate,
    filterStage,
    filterCreatedBy,
    searchText,
    props.merchantId,
    props.sortColumn,
    props.ascendingColumn,
  ]);

  const searchQuery = () => {
    const { merchantId, sortColumn, ascendingColumn } = props;
    const { pipelineId } = props.match.params;
    if (merchantId && pipelineId) {
      if (first && sortColumn !== 'created_date') {
        setFirst(false);
        setTablePaginationSortColumn('created_date', tableName);
        setTablePaginationAscendColumn(false, tableName);
      } else if (startDate && endDate) {
        const first = createdByOptions.length === 1 && stageOptions.length === 3;
        (async () => {
          const startDtFormatted = moment(startDate).format('YYYYMMDD');
          const endDtFormatted = moment(endDate).format('YYYYMMDD');
          const dataArr = await getList(
            'salesOpp',
            merchantId,
            'pipeline',
            pipelineId,
            `?sortColumn=${sortColumn}&ascendingColumn=${ascendingColumn}&searchText=${searchText}&filterStage=${filterStage}&filterCreatedBy=${filterCreatedBy}&first=${first}&startDate=${startDtFormatted}&endDate=${endDtFormatted}`,
          );
          if (Array.isArray(dataArr)) {
            const responseObj = dataArr[0];
            if (responseObj && responseObj.hasOwnProperty('tableData')) {
              const { tableData, stagesData, createdByData, pipelineData } = responseObj;
              setTableData([...tableData]);

              if (stagesData.length > 0) {
                setStageOptions([...salesOptionsConfig, ...stagesData.map(sD => ({ value: sD.id, label: sD.name }))]);
              }
              if (pipelineData && pipelineData.length > 0) {
                const pipelineObj = pipelineData[0];
                setPipelineName(pipelineObj.name);
              }
              if (createdByData.length > 0) {
                setCreatedByOptions([
                  { value: '', label: 'Created By - All' },
                  ...createdByData.map(cBD => ({
                    value: cBD.id,
                    label: cBD.name,
                  })),
                ]);
              }
            } else {
              // notificationError('Error getting data');
              console.error('Error getting View Pipeline Data, heres response object: ', responseObj);
            }
            // setTableData(dataArr);
          } else {
            // notificationError('Error getting data');
            console.log('Error getting View Pipeline Data: ', dataArr);
          }
        })();
      } else if (!startDate) {
        notificationError('Please Select a Start Date');
      } else if (!endDate) {
        notificationError('Please Select an End Date');
      }
    }
  };

  const tableHeaders = [
    {
      keyName: 'created_date',
      style: { width: '15%', minWidth: '185px', paddingRight: '22px' },
      headerClassName: 'align-left col-p-l-25 rem-pad-width',
      rowClassName: 'align-left col-p-l-25 rem-pad-width',
      title: 'Date Created',
      sortable: true,
      render: (t, o) => {
        if (t) {
          const strDate = t.toString();
          const cutDate = strDate.substr(0, 8);
          const date = moment(cutDate);
          return date.format('MM/DD/YYYY');
        }
        return '';
      },
    },
    {
      keyName: 'associate',
      style: { width: '12%', minWidth: '145px' },
      headerClassName: 'align-left col-p-l-25 rem-pad-width',
      rowClassName: 'align-left col-p-l-25',
      title: 'Created by',
      sortable: true,
      render: (text, obj) => <StyledLink to={`/Associates/associate-details/${obj.associate_id}`}>{text}</StyledLink>,
    },
    {
      keyName: 'client',
      style: { width: '15%', minWidth: '120px' },
      headerClassName: 'align-left col-p-l-25',
      rowClassName: 'col-p-l-25',
      title: 'Client',
      sortable: true,
      render: (text, obj) => <StyledLink to={`/Clients?client=${obj.client_id}`}>{text}</StyledLink>,
    },
    {
      keyName: 'amount',
      style: { width: '12%', minWidth: '145px' },
      headerClassName: 'align-left col-p-l-25 rem-pad-width',
      rowClassName: 'align-left col-p-l-25',
      title: 'Amount',
      sortable: true,
    },
    {
      keyName: 'close_date',
      style: { width: '8%', minWidth: '200px' },
      headerClassName: 'align-left col-p-l-25 rem-pad-width',
      rowClassName: 'align-left col-p-l-25',
      title: 'Expected Close Date',
      sortable: true,
      render: (t, o) => {
        if (t) {
          const date = moment(t.toString());
          return date.format('MM/DD/YYYY');
        }
        return '';
      },
    },
    {
      keyName: 'stage',
      style: { width: '8%', minWidth: '105px' },
      headerClassName: 'align-left col-p-l-25 rem-pad-width',
      rowClassName: 'align-left col-p-l-25',
      title: 'Stage',
      sortable: true,
    },

    // {
    //   keyName: "name",
    //   style: { width: "20%", minWidth: "200px" },
    //   headerClassName: "col-p-l-25 rem-pad-width ",
    //   rowClassName: "align-left col-p-l-25 w-212 rem-pad-width",
    //   title: "Opportunity Name",
    //   sortable: true
    // },
  ];

  const handleSearchChange = (e, cb) => {
    if (e.key === 'Enter') {
      cb(e.target.value);
    }
  };

  return (
    <Layout
      title={`${props.pipelineName}`}
      description={`View all active opportunities for ${props.pipelineName}`}
      // styleProps={{ marginLeft: '265px' }}
      // classProps={"tags-layout"}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Sales Opportunities', to: '/Configuration/sales-pipelines' },
        { title: `${props.pipelineName}` },
      ]}
      classProps="standard-width-with-sidebar"
    >
      <div className="w-100-P">
        <div className="w-100-P flex-row-spacebetween-wrap" style={{ flexWrap: 'wrap-reverse' }}>
          <input
            // onChange={e => setSearchText(e.target.value)}
            className="body-head-search mw-280 fs-13 w-100-P-250px focus-input-outline-blue"
            placeholder="Search Client..."
            // value={searchText}
            onKeyDown={e => handleSearchChange(e, setSearchText)}
          />
          <span className="h-50" style={{ display: 'inline-flex', alignItems: 'center' }}>
            Total Opportunity ${tableData.reduce((acc, cur) => acc + cur.amount, 0)}
          </span>
        </div>
        <div className="w-100-P flex-row-spacebetween-wrap">
          <DropdownSelect
            classProps="m-top-18 w-50vw-175px-to-100-P maw-590 align-left h-50"
            options={stageOptions}
            placeholder="Select a stage..."
            value={filterStage}
            onChange={obj => {
              setFilterStage(obj.value);
            }}
          />
          <DropdownSelect
            classProps="m-top-18 w-50vw-175px-to-100-P maw-590 align-left h-50"
            options={createdByOptions}
            placeholder="Created By..."
            value={filterCreatedBy}
            onChange={obj => {
              setFilterCreatedBy(obj.value);
            }}
          />
        </div>
        <div className="w-100-P flex-row-spacebetween-wrap">
          <div>
            <DatePicker
              type="datetime-local"
              className="unstyledDate m-top-18 common_inputStyle w-50vw-175px-to-100-P inputHeight maw-590 focus-input-outline-blue"
              minDate={new Date('1980', '00', '01')}
              maxDate={new Date()}
              selected={startDate}
              showMonthDropdown
              showYearDropdown
              todayButton="Today"
              dropdownMode="select"
              onKeyDown={e => handleSearchChange(e, searchQuery)}
              onChange={date => setStartDate(date)}
              dateFormat="MMMM d, yyyy"
            />
          </div>
          <div>
            <DatePicker
              type="datetime-local"
              className="unstyledDate m-top-18 common_inputStyle w-50vw-175px-to-100-P inputHeight maw-590 focus-input-outline-blue"
              // minDate={new Date()}
              maxDate={new Date()}
              selected={endDate}
              showMonthDropdown
              showYearDropdown
              todayButton="Today"
              dropdownMode="select"
              onChange={date => setEndDate(date)}
              onKeyDown={e => handleSearchChange(e, searchQuery)}
              // showTimeSelect
              dateFormat="MMMM d, yyyy"
            />
          </div>
        </div>
        <Table
          tableClassName="lg-table-sidebar rem-pad-width"
          data={tableData}
          tableHeaders={tableHeaders}
          searchable={false}
          tableName={tableName}
        />

        <div className="report-cards">
          <Link className="fs-13 no-decoration blue d-block" to="/Reports/client-engagement">
            Sales Pipeline By Associate
          </Link>
          <span>Count of all created sales opportunities by associate over a specified date range</span>
        </div>
        <div className="report-cards">
          <Link className="fs-13 no-decoration blue d-block" to="/Reports/client-engagement">
            Create Opportunities by Associate
          </Link>
          <span>Count of created sales opportunities by associate over the specified date range</span>
        </div>
      </div>
      {/* {!props.pipelineId ? (
        <Redirect to="/Configuration/sales-pipelines" />
      ) : null} */}
    </Layout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  ...state.salesPipeline,
  ...getTablePaginationData(state, tableName),
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ViewPipeline);
