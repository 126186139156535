import React, { useMemo } from 'react';
import AutoMessages from './AutoMessages';
import Tags from './Tags';
import PaymentRequest from './PaymentRequest';
import SalesOpportunities from './SalesOpportunities';
import Reminders from './Reminders';

const QuickView = props => {
  return (
    <>
      <div
        className={`flex-col-left`}
        style={{ alignItems: 'stretch', maxHeight: '100%', gap: '36px', overflowX: 'hidden', paddingLeft: '26px' }}
      >
        {props?.auth?.merchantFlags?.tags?.enabled && (
          <Tags client={props.client} auth={props.auth} getClientData={props.getClientData} />
        )}

        <SalesOpportunities
          client={props.client}
          setViewAllType={props.setViewAllType}
          auth={props.auth}
          setSelectedSalesOpp={props.setSelectedSalesOpp}
          getReminderData={props.getReminderData}
          getClientData={props.getClientData}
          refetchReminders={props.refetchReminders}
          refetchAutoMessages={props.refetchAutoMessages}
        />

        <Reminders
          client={props.client}
          setViewAllType={props.setViewAllType}
          auth={props.auth}
          open={props.showPopConfirm}
          closeChat={props.closeChat}
          showMessageHandler={props.showMessageHandler}
          setDismissPopup={props.setDismissPopup}
          dismissPopup={props.dismissPopup}
          setAllAssoc={props.setAllAssoc}
          allAssoc={props.allAssoc}
          setAssociate={props.setAssociate}
          associate={props.associate}
          refetchReminders={props.refetchReminders}
        />

        <AutoMessages
          client={props.client}
          setViewAllType={props.setViewAllType}
          associate_name={`${props.auth?.firstName || ''} ${props.auth?.lastName || ''}`}
          review_link={props.reviewLink}
        />
        <PaymentRequest
          client={props.client}
          setViewAllType={props.setViewAllType}
          showMessageHandler={props.showMessageHandler}
        />
      </div>
    </>
  );
};
export default QuickView;
