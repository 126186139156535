import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import BorderlessTable from '../../../Layout/BorderlessTable';
import {
  setAddEditTrigger,
  setTriggerTitleAction,
  setTriggerAutoMessage,
  setTriggerAutoMsgId,
  setTriggerActionObj,
} from '../../../../reducers/salesTriggers';
import { getAll, getRequest } from '../../../../core/apiRequests';
import FlaggedViewSalesLogBtn from './FlaggedViewSalesLogBtn';
import FlaggedAddTriggerBtn from './FlaggedAddTriggerBtn';
import { convertMessageTemplate } from '../../../../utils/convertMessageTemplate';

const SalesTriggers = props => {
  const history = useHistory();
  const flags = props.merchantFlags;
  const salesTriggerEnabled = flags?.sales_trigger?.enabled;
  const [type, setType] = useState('');

  const bubbleStyles = {
    padding: '4px 0 2px 0',
  };

  useEffect(() => {
    if (props.merchantId) {
      (async () => {
        const reqObj = {
          params: ['salesTrigger', props.merchantId, 'triggersAndSales'],
        };
        const dataSet = await getRequest(reqObj);
        // const dataSet = await getAll('salesTrigger', props.merchantId, '/triggersAndSales');
        if (dataSet && dataSet.length > 0) {
          const dataObj = dataSet[0];
          const { categories, actions, triggers } = dataObj;
          // setTriggerActions(actions);
          setTriggerActionObj({ actions, triggers, categories });
        }
        // setTriggerActions([...dataSet])
      })();
    }
  }, [props.merchantId]);

  const tableHeaders = [
    {
      keyName: 'name',
      style: { width: '6%', minWidth: '150px' },
      headerClassName: 'align-left rem-pad-width',
      rowClassName: 'align-left',
      title: 'Trigger Name',
    },
    {
      keyName: 'categories',
      style: { width: '35%', minWidth: '150px' },
      headerClassName: 'align-left p-left-21',
      rowClassName: 'p-left-21 align-left rem-pad-width, blue-link',
      title: 'Product Category',
      sortable: true,
      render: t => {
        if (Array.isArray(t)) {
          if (t.length > 0) {
            return t.join(', ');
          }
          return 'Any Product Category';
        }
        return 'Any Product Category'; // is that what this should be? I feel like this should be an error..
      },
    },
    {
      keyName: 'min_price',
      style: { width: '1%', minWidth: '140px' },
      headerClassName: 'align-left rem-pad-width',
      rowClassName: 'align-left',
      title: 'Price Range',
      sortable: true,
      render: (t, o) => {
        if (o.min_price && o.max_price) {
          return `$${o.min_price}-$${o.max_price}`;
        }
        if (o.min_price) {
          return `Over $${o.min_price}`;
        }
        if (o.max_price) {
          return `Under $${o.max_price}`;
        }
      },
    },
    {
      keyName: 'actionConfigs',
      style: { width: '2%', minWidth: '80px' },
      headerClassName: 'align-left',
      rowClassName: 'align-left rem-pad-width p-left-41',
      title: '# of Actions',
      render: t => {
        return Object.values(t).length;
      },
    },
    {
      keyName: 'active',
      style: { width: '2%', minWidth: '50px', alignItems: 'center' },
      cellStyle: {
        alignItems: 'center',
        textAlign: 'center',
        alignContent: 'center',
      },
      headerClassName: 'align-center',
      rowClassName: 'align-center rem-pad-width',
      title: 'Status',
      render: t => {
        if (Number(t) === 1) {
          return (
            <div
              style={{
                ...bubbleStyles,
                backgroundColor: '#ecf8dd',
                color: '#7fbd31',
              }}
              className="rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 blue"
            >
              ACTIVE
            </div>
          );
        }
        return (
          <div
            style={{
              ...bubbleStyles,
              backgroundColor: '#ebebeb',
              color: '#9c9c9c',
            }}
            className="rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 blue"
          >
            DISABLED
          </div>
        );
      },
    },
  ];

  const tableHeadersActions = [
    {
      keyName: 'title',
      style: { width: '12%', minWidth: '140px' },
      headerClassName: 'p-left-21 rem-pad-width',
      rowClassName: 'p-left-21 blue-link',
      title: 'Title',
      sortable: true, // sort allows gives us the ability to sort the table
    },
    {
      keyName: 'type',
      style: { width: '8%', minWidth: '120px' },
      headerClassName: 'align-left ',
      rowClassName: 'align-left rem-pad-width',
      title: 'Type',
      sortable: true,
      render: (type, o) => {
        if (type === 'MESSAGE') {
          if (o.wat_id) {
            return 'Whatsapp Auto Message';
          }
          return 'Auto-Message';
        }
        if (type === 'REMINDER') {
          return 'Auto-Reminder';
        }
        // return type.charAt(0).toUpperCase() + type.slice(1);
      },
    },
    {
      keyName: 'description',
      style: { width: '40%', minWidth: '140px' },
      headerClassName: 'align-left rem-pad-width',
      rowClassName: 'align-left',
      title: 'Description',
      sortable: true,
      render: (t, o) => {
        if ((o.type === 'MESSAGE' && !o.wat_id) || o.type === 'REMINDER') {
          return <div dangerouslySetInnerHTML={{ __html: convertMessageTemplate(t)}} />;
        }
        if (o.type === 'MESSAGE' && o.wat_id) {
          // t = text, o = obj.
          const subsObj = {}; // an obj that will look like {1: client, 2: product, 3: something, 4: whatevz}
          if (o.wat_substitution) {
            // check for null columns
            const substitutes = o.wat_substitution.split(','); // they're comma delimited, make an array
            substitutes.forEach(sub => {
              // loop over that array ^ , each sub looks something like 1=CLIENT or 2=PRODUCT
              const propertyDivide = sub.split('='); // split them up on that equals sign into an array
              if ((propertyDivide.length = 2)) {
                // each propertyDivide looks like this: [1, "CLIENT"] or [2, "PRODUCT"], etc...
                subsObj[propertyDivide[0]] = propertyDivide[1].toLowerCase(); // put them on that subsObj, and lowercase the values... we're rendering them later!
              }
            });
          }
          return o.wat_template.replace(/{{\d}}/g, val => {
            // look for {{#}}
            const theNumber = val.replace(/[{}]/g, ''); // if found, remove all {'s and }'s
            if (subsObj[theNumber]) {
              // using the number, check if the property exists on the substitutes object
              return `[${subsObj[theNumber]}]`; // if so return it
            }
            return '[N/A]'; // else return this lol extreme edge case, I don't know.
          });
        }
      },
    },
  ];

  const rowClickHandler = data => {
    if (salesTriggerEnabled) {
      const { trigger_id, min_price, max_price, name, categories, actionConfigs, action_id } = data;
      setAddEditTrigger('Edit');
      setTriggerActionObj({
        actionId: action_id,
        tiggerId: trigger_id,
        name,
        minPrice: min_price,
        maxPrice: max_price,
        triggerCategories: categories,
        actionConfigs,
      });
      history.push(`/Configuration/sales-triggers/edit-trigger/${trigger_id}`);
    }
  };

  const rowClickHandlerAction = data => {
    const { title, type, action_id, description, wat_id, msgs } = data;
    setType(type);
    setTriggerTitleAction(title);
    setTriggerAutoMessage(description);
    setTriggerAutoMsgId(1);
    const stateObj = {
      actionTitle: title,
      actionDescription: description,
      actionId: action_id,
      actionMsgTemplates: [...msgs],
    };
    setTriggerActionObj(stateObj);

    if (type === 'MESSAGE') {
      if (wat_id) {
        history.push(`/Configuration/sales-triggers/edit-trigger-whatsapp-auto-message/${action_id}`);
      } else {
        history.push(`/Configuration/sales-triggers/edit-trigger-auto-message/${action_id}`);
      }
    }
    if (type === 'REMINDER') {
      history.push(`/Configuration/sales-triggers/edit-trigger-auto-reminder/${action_id}`);
    }
  };

  const clearPref = () => {
    setTriggerActionObj({
      actionId: '',
      tiggerId: '',
      minPrice: '',
      maxPrice: '',
      triggerCategories: [],
      actionConfigs: {},
    });
    setAddEditTrigger('Add');
    // setTrigTitle("product category")
  };

  const goToAddTrigger = () => {
    setTriggerActionObj({
      actionId: '',
      tiggerId: '',
      name: '',
      minPrice: '',
      maxPrice: '',
      triggerCategories: [],
      actionConfigs: [],
    });
    history.push('/Configuration/sales-triggers/add-trigger');
  };

  return (
    <Layout
      title="Triggers"
      description="Create new Sales Triggers and edit automation"
      classProps="standard-width-with-sidebar"
    >
      <div>
        <div
          className="flex-row-spacebetween-wrap m-btm-15"
          style={{
            alignItems: 'center',
          }}
        >
          <span className="fw-500 fs-16">Sales Triggers</span>
          <div>
            <FlaggedViewSalesLogBtn flags={flags} />
            <FlaggedAddTriggerBtn flags={flags} clickHandler={goToAddTrigger} triggers={props.triggers} />
          </div>
        </div>
        <BorderlessTable
          tableHeaders={tableHeaders}
          data={props.triggers}
          onButtonClick={clearPref}
          onRowClick={rowClickHandler}
        />
      </div>
      <div className="m-top-30">
        <BorderlessTable
          title="Actions"
          tableHeaders={tableHeadersActions}
          data={props.actions}
          onRowClick={rowClickHandlerAction}
        />
      </div>
    </Layout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  ...state.salesTriggers,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(SalesTriggers);
