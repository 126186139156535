/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, addDays } from 'date-fns';
import moment from 'moment';
import { connect } from 'react-redux';
import Table from '../../../Layout/Table';
import StyledLink from '../../../Layout/StyledLink';
import { sourceCalc, sourceDateCalc } from './logicFunctions';
import { postRequest } from '../../../../core/apiRequests';
import ToolTip from '../../../Layout/ToolTip';
import WhatsAppTimer from '../../../Layout/WhatsAppTimer';
import { notificationSuccess } from '../../../../core/actions';
import KioskSlider from '../../../Layout/KioskSlider';
import MessageConfigToday from './MessageConfigToday';
import { setTableType } from '../../../../reducers/pagination';
import { associateNameFormat } from '../../../../utils';
import SnoozeModal from '../Reminders/children/SnoozeModal';
import ReminderModal from '../../../Layout/Modals/EditReminderModal';

const TodayAutoReminders = props => {
  const [isShown, setIsShown] = useState('');
  const today = format(new Date(), 'yyyyMMdd');
  const tomorrowNoFormat = addDays(new Date(), 1);
  const tomorrow = format(tomorrowNoFormat, 'yyyyMMdd');
  const fullName = associateNameFormat(props.auth.firstName, props.auth.lastName);
  const [allReminders, setAllReminders] = useState(false);
  const [showSnoozeModal, setShowSnoozeModal] = useState(false);
  const [editReminderModal, setEditReminderModal] = useState(false);
  const [checkCustomReminder, setCheckCustomReminder] = useState(false);

  useEffect(() => {
    if (props.selectedAction.length === 1) {
      const [action] = props.selectedAction;
      if (
        !action.life_event_id &&
        !action.tag_action_config_id &&
        !action.sales_opp_id &&
        !action.custom_event_type_id &&
        !action.sales_action_config_id
      ) {
        setCheckCustomReminder(true);
      }
    } else {
      setCheckCustomReminder(false);
    }
  }, [props.selectedAction]);

  const rowClickHandler = data => {
    props.setSingleRem([data]);
  };

  const checkboxClickHandler = (obj, included) => {
    let tempSel = [...props.selectedAction];
    if (!included) {
      tempSel.push({
        // id : obj.id,
        // client_id: obj.client_id,
        // due_date: obj.due_date,
        // store_id : obj.store_id,
        ...obj,
      });
    } else {
      tempSel = props.selectedAction.filter(arrId => arrId.id !== Number(obj.id));
    }
    props.setSelectedAction(tempSel);
  };

  // 04/05 THIS CURRENTLY USES CLIENT STORE_ID. IT NEEDS TO EVENTUALLY USE THE ASSOCIATE STORE ID
  // associate store_id with more than one associated store is 0. We do not have a way of knowing default store yet for associates.
  const saveSelected = () => {
    const selected = props.selectedAction;
    const reqObj = {
      params: ['today', props.auth.merchantId, 'save'],
      data: { selected },
      query: {
        storeId: props.auth.storeId,
        associateId: props.auth.userId,
        createdBy: `${props.auth.firstName || ''} ${props.auth.lastName || ''}`,
      },
    };

    (async () => {
      const data = await postRequest(reqObj);
      if (data) {
        props.setSelectedAction([]);
        props.getTodayData();
        setAllReminders(false);
        notificationSuccess('Reminder(s) Complete!');
      }
    })();
  };

  const expiresOn = (today, expiresOn) => {
    const a = moment(today, 'YYYYMMDD');
    const b = moment(expiresOn, 'YYYYMMDD');
    const diffDays = b.diff(a, 'days');
    return diffDays;
  };

  const addAll = () => {
    if (allReminders === false) {
      const all = props.data.map(key => key.client_id);
      // let all = props.clientChatList.map(key => key.client_id);
      setAllReminders(true);
      props.setSelectedAction(props.data);
    } else {
      setAllReminders(false);
      props.setSelectedAction([]);
    }
  };

  useEffect(() => {
    if (!props?.messaging?.client?.reminderId) {
      props.getTodayData();
    }
  }, [props?.messaging?.client?.reminderId]);

  const tableHeaders = [
    {
      keyName: 'id',
      title: (
        <div
          value={allReminders}
          className="rem-pad-width"
          style={{
            border: allReminders ? 'none' : '2px solid #b8b8b8',
            backgroundColor: allReminders ? '#1769af' : '#fff',
            width: '16px',
            height: '16px',
            paddingBottom: allReminders ? '3px' : '0px',
            paddingLeft: allReminders ? '1.5px' : '0px',
          }}
          onClick={() => addAll()}
        >
          {allReminders === true ? <FontAwesomeIcon icon={['fas', 'check']} color="#fff" /> : null}
        </div>
      ),
      rowClassName: 'associate-row-image no-text-select',
      isImg: 'image',
      style: { minWidth: '30px', width: '1%', paddingTop: '0px' },
      headerClassName: '',
      sortable: false, // This targets the header's className
      render: (t, o) => {
        if (props.selectedAction.filter(obj => obj.id === t).length < 1) {
          return (
            <FontAwesomeIcon
              onClick={() => checkboxClickHandler(o, false)}
              className="fs-16 gray"
              icon={['far', 'square']}
            />
          );
        }
        return (
          <FontAwesomeIcon
            onClick={() => checkboxClickHandler(o, true)}
            className="fs-16"
            color="#1769af"
            icon={['fas', 'check-square']}
          />
        );
      },
    },
    {
      keyName: 'due_date',
      title: 'Due Date',
      style: { width: '10%', paddingTop: '0px' },
      headerClassName: '',
      rowClassName: '', // we can apply all styles to the header now.
      sortable: true, // This targets the header's className
      render: (t, o) => {
        if (t) {
          if (parseInt(today) === o.expires_on_date) {
            return (
              <div className="flex-col-left">
                {moment(t, 'YYYYMMDD').format('MM/DD/YYYY')}
                <span className="fs-12 fw-400 gray">Expires today</span>
              </div>
            );
          }
          if (parseInt(tomorrow) === o.expires_on_date) {
            return (
              <div className="flex-col-left">
                {moment(t, 'YYYYMMDD').format('MM/DD/YYYY')}
                <span className="fs-12 fw-400 gray">Expires tomorrow</span>
              </div>
            );
          }
          if (o.expires_on_date) {
            return (
              <div className="flex-col-left">
                {moment(t, 'YYYYMMDD').format('MM/DD/YYYY')}
                <span className="fs-12 fw-400 gray">Expires in {expiresOn(today, o.expires_on_date)} days</span>
              </div>
            );
          }
          return moment(t, 'YYYYMMDD').format('MM/DD/YYYY');
        }
      },
    },
    {
      keyName: 'name',
      style: { width: '5%', minWidth: '120px', paddingTop: '0px' },
      title: 'Client Name',
      sortable: true, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        return (
          <StyledLink classprops="blue-link" to={`/Clients?client=${o.client_id}`}>
            {t}
          </StyledLink>
        );
      },
    },
    {
      keyName: 'description',
      style: { width: '15%', minWidth: '120px', paddingTop: '0px' },
      title: 'Description',
      sortable: false, // sort allows gives us the ability to sort the table
    },
    {
      keyName: '',
      style: { width: '5%', paddingTop: '0px', paddingRight: '20px' },
      title: 'Source',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        return sourceCalc(o);
      },
    },
    {
      keyName: 'reassigned_by',
      style: { width: '5%', paddingTop: '0px' },
      headerClassName: 'align-center',
      title: 'Reassign',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        return (
          <div className="align-center" style={{ maxHeight: '34px', maxWidth: '87px' }}>
            <FontAwesomeIcon
              icon={['far', 'pencil']}
              onClick={() => props.setAssignPopup(o, true)}
              className="p-right-8 gray"
            />
        </div>
        );
      },
    },
    {
      keyName: '',
      style: { width: '5%', paddingTop: '0px' },
      headerClassName: 'align-center',
      title: 'Message',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        return (
          <div className="align-center pos-rel" style={{ maxHeight: '34px', maxWidth: '87px' }} key={t}>
            <MessageConfigToday
              keyVar={Math.floor(Math.random() * o.id)}
              msgObj={o}
              setShowPopConfirm={props.setShowPopConfirm}
              initiateTemplates={props.initiateTemplates}
              setIsShown={setIsShown}
              isShown={isShown}
              setSelectedClient={props.setSelectedClient}
            />
          </div>
        );
      },
    },
    {
      keyName: '',
      style: { width: '5%', paddingTop: '0px', textAlign: 'center' },
      title: 'Status',
      render: (t, o) => {
        const bubbleStyles = {
          padding: '4px 0 2px 0',
        };
        const now = moment(new Date());
        if (!o.complete_date) {
          if (moment(now).isAfter(moment(o.due_date, 'YYYYMMDD').add(1, 'day'))) {
            return (
              <div
                style={{
                  ...bubbleStyles,
                  backgroundColor: '#f9e0e0',
                  color: '#ff7a7a',
                }}
                className="align-center border-r3 rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 blue"
              >
                OVERDUE
              </div>
            );
          }
          return (
            <div
              style={{
                ...bubbleStyles,
                backgroundColor: '#ECF8DD',
                color: ' #7FBD31',
              }}
              className="align-center border-r3 rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 blue"
            >
              ACTIVE
            </div>
          );
        }
      },
      sortable: false, // sort allows gives us the ability to sort the table
    },
  ];

  const messageConfigToday = (message, msgObj) => {
    const whatsAppTemplates = [];
    if (msgObj.preferred_contact_type === 'WHATSAPP') {
      if (WhatsAppTimer(msgObj.WA_receive)) {
        whatsAppTemplates.push(
          <FontAwesomeIcon
            className="checkbox fs-19"
            color="#818181"
            icon={['far', 'comment-alt-lines']}
            onClick={() => props.initiateChat(msgObj)}
          />,
        );
        return whatsAppTemplates;
      }
      whatsAppTemplates.push(
        <FontAwesomeIcon
          className="checkbox fs-19"
          color="#818181"
          icon={['far', 'comment-alt-lines']}
          onClick={() => props.initiateTemplates(msgObj)}
        />,
      );
    } else {
      whatsAppTemplates.push(
        <FontAwesomeIcon
          className="checkbox fs-19"
          color="#818181"
          icon={['far', 'comment-alt-lines']}
          onClick={() => props.initiateChat(msgObj)}
          // onClick={() => props.initiateChat(msgObj.client_id)}
        />,
      );
      return whatsAppTemplates;
    }
    return whatsAppTemplates;
  };
  return (
    <div className="align-left" onClick={() => setTableType('TODAY_AUTO_REMINDERS')}>
      <div className="flex-row-spacebetween-nowrap">
        <span className="fs-15 fw-500">Reminders</span>
        <div className="flex-col-right">
          {props.selectedAction.length > 0 ? (
            <div className="add-edit-wrap" style={{ justifyContent: 'flex-end' }}>
              {checkCustomReminder && (
                <button
                  type="button"
                  className="flex-row-nospacebetwee-nowrap border-none pointer"
                  style={{ alignItems: 'center', backgroundColor: '#fff', marginRight: '45px' }}
                  onClick={() => setEditReminderModal(true)}
                >
                  <FontAwesomeIcon icon={['far', 'pencil']} className="fs-13 m-right-8" color="#33AAFC" />
                  <span className="fw-600 fs-13" style={{ color: '#33AAFC' }}>
                    Edit
                  </span>
                </button>
              )}
              <button
                className="flex-row-nospacebetwee-nowrap border-none pointer"
                style={{ alignItems: 'center', backgroundColor: '#fff', marginRight: '45px' }}
                onClick={() => props.setAssignPopup(true)}
              >
                <FontAwesomeIcon icon={['far', 'user']} className="fs-13 m-right-8" color="#1769AF" />
                <span className="fw-600 fs-13" style={{ color: '#1769AF' }}>
                  Assign Associate
                </span>
              </button>
              <button
                className="flex-row-nospacebetwee-nowrap border-none pointer"
                style={{ alignItems: 'center', backgroundColor: '#fff', marginRight: '45px' }}
                onClick={saveSelected}
              >
                <FontAwesomeIcon icon={['far', 'check-circle']} className="fs-16 m-right-8" color="#1769AF" />
                <span className="fw-600 fs-13" style={{ color: '#1769AF' }}>
                  Complete
                </span>
              </button>
              <button
                className="flex-row-nospacebetwee-nowrap border-none pointer"
                style={{ alignItems: 'center', backgroundColor: '#fff', marginRight: '45px' }}
                onClick={() => props.setDismissPopup(true)}
              >
                <FontAwesomeIcon icon={['far', 'eraser']} className="fs-16 m-right-8" color="#1769AF" />
                <span className="fw-600 fs-13" style={{ color: '#1769AF' }}>
                  Dismiss
                </span>
              </button>
              <button
                className="flex-row-nospacebetwee-nowrap border-none pointer"
                style={{ alignItems: 'center', backgroundColor: '#fff', paddingRight: '0px' }}
                onClick={() => setShowSnoozeModal(true)}
              >
                <FontAwesomeIcon
                  icon="fa-sharp fa-regular fa-alarm-clock"
                  className="fs-16 m-right-8"
                  color="#1769AF"
                />
                <span className="fw-600 fs-13" style={{ color: '#1769AF' }}>
                  Snooze
                </span>
              </button>
            </div>
          ) : null}
        </div>
        {/* end */}
      </div>
      {props.data.length > 0 ? (
        <div>
          <Table
            data={props.data}
            tableHeaders={tableHeaders}
            tableClassName="lg-table-sidebar rem-pad-width"
            optionalButton={null}
            onRowClick={rowClickHandler}
            tableName="TODAY_AUTO_REMINDERS"
          />
          <div className="w-100-P flex-col-center m-top-10">
            <StyledLink
              to={`/all-reminders`}
              id="ViewAllReminders"
              classprops="fs-13 blue-link fw-500"
            >
              View All
            </StyledLink>
          </div>
        </div>
      ) : (
        <>
          <div className="align-center h-173 w-100-P">
            <span className="pos-rel gray fs-13" style={{ top: '45%' }}>
              No Reminders
            </span>
          </div>
          <div className="w-100-P flex-col-center m-top-10">
            <StyledLink
              to={`/all-reminders`}
              id="ViewAllReminders"
              classprops="fs-13 blue-link fw-500"
            >
              Load Reminders
            </StyledLink>
          </div>
        </>
      )}
      {editReminderModal && (
        <ReminderModal
          open={editReminderModal}
          reminder={props.selectedAction?.[0] ?? {}}
          allAssoc={props.allAssoc}
          onClose={() => setEditReminderModal(false)}
          setReload={() => {
            props.setSelectedAction([]);
            setAllReminders(false);
            props.getTodayData()
          }}
          setAllReminders={setAllReminders}
        />
      )}
      <SnoozeModal
        openModal={showSnoozeModal}
        setOpenModal={setShowSnoozeModal}
        merchantId={props.auth.merchantId}
        actions={props.selectedAction}
        refresh={() => {
          props.setSelectedAction([]);
          setAllReminders(false);
          props.getTodayData()
        }}
      />
    </div>
  );
};

const mapStateToProps = state => ({ auth: state.auth, messaging: state.messaging });
export default connect(mapStateToProps, {})(TodayAutoReminders);
