/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import yn from 'yn';
import { Input } from 'antd';
import { notificationError } from '../../../../core/actions';
import ReviewLinkCheck from '../../TextAreaWithActions/ReviewLinkCheck';
import { scrollToBottomOfChat } from '../../../../reducers/messaging';
import PreviewCard from '../../TextAreaWithActions/PreviewCard';
import { useDragAndDrop } from '../../../../context/DragDropContext';
import { actionTextAreaFileActions } from '../../TextAreaWithActions/useActionTextAreaFiles';
import { messageStatusSubject } from '../../../../core/events';
import StyledLink from '../../StyledLink';
import InputAIButton from './InputAIButton';
import ChatProductModal from './ChatProductModal';
import ChatCollectionModal from './ChatCollectionModal';
import ChatModal from './ChatModal';

const errorCodes = [30005, 30006];

const MAX_IMAGE_FILE_SIZE = 1600000;
const MAX_IMAGE_FILE_SIZE_HUMAN = '1.5mb';
const MAX_VIDEO_FILE_SIZE = MAX_IMAGE_FILE_SIZE;
const MAX_VIDEO_FILE_SIZE_HUMAN = MAX_IMAGE_FILE_SIZE_HUMAN;

const MessageInputActions = ({
  val: propsVal,
  changeHandler,
  autoMessageOrReminder = false,
  textAreaStyleProps = {},
  placeholder = 'Message...',
  showPaymentsPopup,
  showTemplatePopup,
  client,
  reviewLink,
  templates,
  template = false,
  salesTriggers,
  configObj,
  associate,
  payments,
  sendMessage,
  relationships,
  chatMessages,
  sendAndArchive,
  auth: { merchantId },
  auth: { merchantConfig },
  auth: { merchantFlags },
  auth,
  type,
  selectedProducts,
  selectedCollections,
  handleAddProduct,
  handleDeleteProduct,
  handleAddCollection,
  handleDeleteCollection,
  location,
  messaging,
}) => {
  const isTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0 || window.navigator.maxTouchPoints > 0;
  useEffect(() => {
    const subscription = messageStatusSubject.subscribe(event => {
      if (event.chatId === client.chat_id && errorCodes.includes(event.errorCode)) {
        client.mobile_invalid = 1;
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  const [hovering, setHovering] = useState('');
  const ref = useRef(null);
  const [inputFocus, setInputFocus] = useState(false);
  const [showEllipses, setShowEllipses] = useState('noShowEllipses');
  const [showMediaActions, setShowMediaActions] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showCollections, setShowCollections] = useState(false);
  const [selectedStoreName, setSelectedStoreName] = useState('');
  const flags = useFlags();

  const AI_MESSAGING = merchantFlags?.ai_messaging?.enabled;

  const toggleCollections = useCallback(() => {
    setShowCollections(!showCollections);
  }, [showCollections]);

  const toggleProducts = useCallback(() => {
    setShowProducts(!showProducts);
  }, [showProducts]);

  useEffect(() => {
    if (auth?.selectedStore) {
      const storeName = auth.stores.find(store => store.id === auth.selectedStore)?.name;
      setSelectedStoreName(storeName || '');
    }
  }, [auth?.selectedStore, client, flags]);

  const toggleMediaActions = useCallback(() => {
    setShowMediaActions(!showMediaActions);
  }, [showMediaActions]);

  const { fileData, fileDispatch, imageHandler, setMaxFilesImage, setMaxFilesVideo } = useDragAndDrop();

  useEffect(() => {
    setMaxFilesImage(autoMessageOrReminder ? 1 : 5);
    setMaxFilesVideo(autoMessageOrReminder ? 0 : 1);
  }, [autoMessageOrReminder]);

  useEffect(() => {
    if (!isTouch) {
      ref.current.focus();
    }
  }, []);

  function auto_grow(field) {
    // Reset field height
    field.style.height = 'inherit';
    const computed = window.getComputedStyle(field);
    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      // + parseInt(computed.getPropertyValue('padding-top'), 10)
      field.scrollHeight +
      // + parseInt(computed.getPropertyValue('padding-bottom'), 10)
      parseInt(computed.getPropertyValue('border-bottom-width'), 10);
    field.style.height = `${height}px`;
  }

  const characterCount = () => {
    return (
      <span
        className="fs-10 align-vert-middle h-12"
        style={{
          color: setNotif().color,
          display: 'table-cell',
          verticalAlign: 'middle',
          marginRight: '12px',
        }}
      >
        {propsVal.length} characters{' '}
      </span>
    );
    function setNotif() {
      if (propsVal.length <= 120) {
        return { color: '#818181' };
      }
      return { color: '#ff6363' };
    }
  };
  const buttonStyles = {
    border: '1px solid #31AAFB',
    borderRadius: '50px',
    padding: '7px 13px 7px 11px',
    backgroundColor: '#fff',
    color: '#31AAFB',
  };

  const hoverButtonStyles = {
    border: '1px solid #31AAFB',
    borderRadius: '50px',
    padding: '7px 13px 7px 11px',
    backgroundColor: '#31AAFB',
    color: '#fff',
    cursor: 'pointer',
  };
  const getCursorXY = val => {
    const inp = document.getElementById('textAreaInputWithActionsInbox');
    const cursorLoc = inp.selectionStart;
    const newString = propsVal.substr(0, cursorLoc) + val + propsVal.substr(cursorLoc);
    changeHandler(newString);
    inp.focus();
  };
  const textHandler = val => {
    changeHandler(val);
  };
  const handleImages = e => {
    e.preventDefault();

    const eventImageFiles = [];
    const eventVideoFiles = [];

    for (let i = 0; i < e.target.files.length; i += 1) {
      const file = e.target.files[i];
      if (file.type.includes('image/')) {
        eventImageFiles.push(file);
      } else if (file.type.includes('video/')) {
        eventVideoFiles.push(file);
      }
    }
    processImageFiles(eventImageFiles);
    processVideoFiles(eventVideoFiles);

    function processImageFiles(imageFiles) {
      if (eventImageFiles.length > 0) {
        // this is a prop for auto reminder msgs and auto messages that only allow 1 image
        const maxFiles = autoMessageOrReminder ? 1 : 5;
        fileDispatch({
          type: actionTextAreaFileActions.setImages,
          payload: processFiles({
            files: imageFiles,
            maxFiles,
            maxSize: MAX_IMAGE_FILE_SIZE,
            sizeError: `Image file size exceeds ${MAX_IMAGE_FILE_SIZE_HUMAN}`,
            currentData: fileData.images,
            overflowError: maxFiles === 1 ? '1 image allowed' : `A Maximum of ${maxFiles} Images Allowed.`,
          }),
        });
      }
    }

    async function processVideoFiles(videoFiles) {
      const maxFiles = autoMessageOrReminder ? 0 : 1;
      const videoData = processFiles({
        files: videoFiles,
        maxFiles,
        maxSize: MAX_VIDEO_FILE_SIZE,
        sizeError: `Video file size exceeds ${MAX_VIDEO_FILE_SIZE_HUMAN}`,
        currentData: fileData.videos,
        overflowError: maxFiles === 0 ? 'Video is not supported here.' : `A Maximum of ${maxFiles} Video Is Allowed.`,
      });
      const videoFrames = {
        files: [],
        urls: [],
      };
      let container;
      let canvas;
      let video;
      let ctx;
      if (videoFiles.length > 0) {
        container = document.createElement('div');
        canvas = document.createElement('canvas');
        video = document.createElement('video');
        container.appendChild(canvas);
        container.appendChild(video);
        container.style.visibility = 'hidden';

        document.body.appendChild(container);
        ctx = canvas.getContext('2d');
      }

      for (const videoURL of videoData.urls) {
        const { file, url } = await videoURLToThumbnail(videoURL);
        videoFrames.files.push(file);
        videoFrames.urls.push(url);
      }
      // document.body.removeChild(container)
      function videoURLToThumbnail(url) {
        return new Promise(resolve => {
          const loadedMetaEvent = 'loadedmetadata';
          const loadedEvent = 'seeked';
          const onVideoMetaLoaded = () => {
            video.currentTime = 0.2;
          };
          const onVideoLoaded = () => {
            canvas.height = video.videoHeight;
            canvas.width = video.videoWidth;
            ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            canvas.toBlob(blob => {
              const file = new File([blob], 'videoThumbnail.jpg', { type: 'image/jpeg' });
              resolve({
                file,
                url: URL.createObjectURL(file),
              });
            }, 'image/jpeg');
            video.removeEventListener(loadedMetaEvent, onVideoMetaLoaded);
            video.removeEventListener(loadedEvent, onVideoLoaded);
          };
          video.addEventListener(loadedMetaEvent, onVideoMetaLoaded);
          video.addEventListener(loadedEvent, onVideoLoaded);
          video.src = url;
        });
      }
      fileDispatch({
        type: actionTextAreaFileActions.setVideos,
        payload: {
          ...videoData,
          videoFrames,
        },
      });
    }

    function processFiles({
      files: filesToProcess,
      maxFiles,
      maxSize,
      sizeError,
      currentData = {
        files: [],
        urls: [],
      },
      overflowError,
    }) {
      const [hadLargeFile, files, urls] = filesToProcess.reduce(
        ([hadLargeFile_r, files_r, urls_r], file) => {
          const fileIsLarge = file.size >= maxSize;
          if (files_r.length >= maxFiles) {
            return [hadLargeFile_r || fileIsLarge, files_r, urls_r];
          }
          if (fileIsLarge) {
            return [true, files_r, urls_r];
          }
          files_r.push(file);
          urls_r.push(URL.createObjectURL(file));
          return [hadLargeFile_r, files_r, urls_r];
        },
        [false, [], []],
      );
      if (hadLargeFile) {
        notificationError(sizeError);
      }

      if (files.length + currentData.urls.length > maxFiles) {
        notificationError(overflowError);
      }

      return {
        urls: urls.concat(currentData.urls).slice(0, maxFiles),
        files: files.concat(currentData.files).slice(0, maxFiles),
      };
    }
    imageHandler(e.target.files);

    e.target.value = null;
  };

  const disabledSend =
    (client.mobile_invalid === 1 && client.preferred_contact_type !== 'EMAIL') ||
    (propsVal.length === 0 &&
      fileData.images.urls.length === 0 &&
      fileData.videos.urls.length === 0 &&
      selectedProducts.length === 0 &&
      selectedCollections.length === 0);

  const imageDeleteHandler = useCallback(
    index => fileDispatch({ type: actionTextAreaFileActions.removeImage, payload: index }),
    [fileDispatch],
  );
  const videoDeleteHandler = useCallback(
    index => fileDispatch({ type: actionTextAreaFileActions.removeVideo, payload: index }),
    [fileDispatch],
  );

  const handleKeyDown = e => {
    if (e.key === 'Enter' && e.keyCode === 13 && !e.shiftKey && !disabledSend) {
      e.preventDefault();
      sendMessage();
    }
  };

  const invalidMobileMsg = (
    <div className="invalid-number-text">
      Invalid Phone Number
      <br />
      <StyledLink to={`/Clients?client=${client.client_id}`} classprops="invalid-number-text">
        Please edit the clients phone number in order to send messages{' '}
      </StyledLink>
    </div>
  );

  const inputBoxStyle = useCallback(
    () => ({
      width: 'calc(100% - 30px)',
      padding: '11px 10px 11px 0px',
      marginLeft: '17px',
      maxHeight: '130px',
      transition: 'all 0.3s, height 0s',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      overflowY: 'scroll',
      overflow: 'hidden',
      background: 'transparent',
      // zIndex: '20',
    }),
    [inputFocus],
  );

  return (
    <div className="flex flex-column" style={{ paddingTop: '10px' }}>
      <div>
        <div>
          {/* payments 07/14/21 */}
          {client.mobile_invalid === 1 && client.preferred_contact_type !== 'EMAIL' ? invalidMobileMsg : null}
          <div className="inputWrapper m-left-13">
            {/* INBOX CHAT STYLE HERE */}
            <div style={inputBoxStyle()}>
              <Input.TextArea
                id="textAreaInputWithActionsInbox"
                value={propsVal}
                bordered={false}
                style={{
                  width: '100%',
                  caretColor: 'black',
                  display: 'block',
                  fontFamily: 'Poppins',
                  letterSpacing: 0.5,
                  wordSpacing: 1,
                  whiteSpace: 'pre-wrap',
                  resize: 'none',
                  padding: 0,
                  overflowY: 'scroll',
                  zIndex: 0,
                  position: 'relative',
                  ...textAreaStyleProps,
                }}
                onFocus={() => setInputFocus(true)}
                onBlur={() => setInputFocus(false)}
                autoSize={{ minRows: 1, maxRows: 6 }}
                onChange={e => {
                  textHandler(e.target.value);
                  // auto_grow(e.target);
                }}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                ref={ref}
                autoFocus={!isTouch}
              />
              <div
                style={{ zIndex: '10', backgroundColor: '#fff', width: '74px' }}
                className={`pos-abs align-left ${showEllipses}`}
              >
                <div className="loading" />
              </div>
              {fileData.videos.videoFrames.urls.length + fileData.images.urls.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    gap: '4px',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  {fileData.videos.videoFrames.urls.map((im, i) => {
                    return (
                      <PreviewCard
                        imageUrl={im}
                        key={im}
                        ind={i}
                        onDelete={videoDeleteHandler}
                        hovering={hovering}
                        setHovering={setHovering}
                        isVideo
                      />
                    );
                  })}
                  {fileData.images.urls.map((im, i) => {
                    return (
                      <PreviewCard
                        imageUrl={im}
                        key={im}
                        ind={i}
                        onDelete={imageDeleteHandler}
                        hovering={hovering}
                        setHovering={setHovering}
                      />
                    );
                  })}
                </div>
              )}
              {selectedProducts.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    gap: '4px',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  {selectedProducts.map(product => (
                    <PreviewCard
                      imageUrl={product.image}
                      key={product.id}
                      ind={product.id}
                      onDelete={handleDeleteProduct}
                      hovering={hovering}
                      setHovering={setHovering}
                    />
                  ))}
                </div>
              )}
              {selectedCollections.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    gap: '4px',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  {selectedCollections.map(collection => (
                    <PreviewCard
                      imageUrl={
                        collection.image || 'https://dashboard-v2-images.s3.amazonaws.com/image-placeholder@2x.png'
                      }
                      key={collection.clientCollectionId}
                      ind={collection.clientCollectionId}
                      onDelete={handleDeleteCollection}
                      hovering={hovering}
                      setHovering={setHovering}
                    />
                  ))}
                </div>
              )}
            </div>
            {/* payment, attachment, template buttons */}
            <div className="flex-row-spacebetween-nowrap w-100-P p-btm-6">
              <div>
                {payments && (client.type ?? 'C') === 'C' && (
                  <div
                    title="Send payment request"
                    className="profile_upload_div pointer"
                    onMouseEnter={() => setHovering('payments')}
                    onMouseLeave={() => setHovering('')}
                    id="payments-btn"
                    onClick={() => {
                      showPaymentsPopup(true);
                      scrollToBottomOfChat();
                    }}
                    style={{
                      cursor: 'pointer',
                      marginLeft: '7px',
                      width: '29px',
                      height: '29px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '4px',
                      backgroundColor: hovering === 'payments' ? '#F2F3F6' : '',
                    }}
                  >
                    <FontAwesomeIcon
                      className="fs-15 pointer"
                      icon={['far', 'dollar-sign']}
                      id="payments-icon"
                      style={hovering === 'payments' ? { backgroundColor: '#F2F3F6' } : { color: '#818181' }}
                    />
                  </div>
                )}
                {/* templates */}
                {templates ? (
                  <div
                    title="Templates"
                    className="profile_upload_div pointer"
                    onClick={() => {
                      showTemplatePopup(true);
                    }}
                    onMouseEnter={() => setHovering('template')}
                    onMouseLeave={() => setHovering('')}
                    id="comment-btn"
                    style={{
                      backgroundColor: hovering === 'template' ? '#F2F3F6' : 'transparent',
                      cursor: 'pointer',
                      marginLeft: '7px',
                      width: '29px',
                      height: '29px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '4px',
                    }}
                  >
                    <FontAwesomeIcon
                      className="fs-15 pointer"
                      icon={['far', 'comment-alt-lines']}
                      style={hovering === 'template' ? { backgroundColor: '#F2F3F6' } : { color: '#818181' }}
                    />
                  </div>
                ) : null}
                {/* IMAGES */}
                {client.type !== 'G' && (
                  <div
                    title="Attach files"
                    className="profile_upload_div pointer"
                    id="attacht-btn"
                    onMouseEnter={() => setHovering('paperclip')}
                    onMouseLeave={() => setHovering('')}
                    onClick={() => setShowMediaActions(!showMediaActions)}
                    style={{
                      cursor: 'pointer',
                      marginLeft: '7px',
                      width: '29px',
                      height: '29px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '4px',
                      backgroundColor: hovering === 'paperclip' ? '#F2F3F6' : '',
                    }}
                  >
                    <FontAwesomeIcon
                      className="fs-15 pointer"
                      id="attach-icon"
                      icon={['far', 'paperclip']}
                      style={
                        hovering === 'paperclip'
                          ? { backgroundColor: '#F2F3F6' }
                          : { color: showMediaActions ? '#33AAFC' : '#818181' }
                      }
                    />
                  </div>
                )}
                {AI_MESSAGING ? (
                  <InputAIButton
                    source="inbox"
                    setInputText={textHandler}
                    inputValue={propsVal}
                    merchantId={merchantId}
                    storeId={client.store_id}
                    clientId={client.client_id}
                    chatMessages={chatMessages}
                    setShowEllipses={setShowEllipses}
                    chatType={client.type}
                    chatId={client.chat_id}
                  />
                ) : null}
                {auth?.merchantFlags?.scheduling_link?.enabled && auth.schedulingLink && (client.type ?? 'C') === 'C' && (
                  <div
                    className="profile_upload_div pointer"
                    id="schedule-btn"
                    onMouseEnter={() => setHovering('schedule')}
                    onMouseLeave={() => setHovering('')}
                    onClick={() => {
                      getCursorXY(auth.schedulingLink);
                    }}
                    style={{
                      cursor: 'pointer',
                      marginLeft: '7px',
                      width: '29px',
                      height: '29px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '4px',
                      backgroundColor: hovering === 'schedule' ? '#F2F3F6' : '',
                    }}
                  >
                    <FontAwesomeIcon
                      className="fs-15 pointer"
                      icon="fa-regular fa-calendar-plus"
                      style={hovering === 'schedule' ? { backgroundColor: '#F2F3F6' } : { color: '#818181' }}
                    />
                  </div>
                )}
              </div>
              <div>
                {(!messaging.chatMessages?.length ||
                  (yn(merchantConfig.DECLUTTERED_INBOX) && !!(client.isOpen ?? true))) && (
                  <button
                    title="Send"
                    type="button"
                    style={{
                      color: !disabledSend ? '#33AAFC' : 'gray',
                      border: `1px solid ${!disabledSend ? '#33AAFC' : 'gray'}`,
                      borderRadius: '4px',
                      backgroundColor: 'transparent',
                      padding: '3px 15px',
                    }}
                    disabled={disabledSend}
                    onClick={() => sendAndArchive()}
                  >
                    Send and close
                  </button>
                )}
                {sendMessage ? (
                  <button
                    title="Send"
                    id="send-btn"
                    type="button"
                    style={{
                      marginRight: '15px',
                      marginLeft: '13px',
                      backgroundColor: 'transparent',
                      border: 'none',
                    }}
                    disabled={disabledSend}
                    onClick={sendMessage}
                  >
                    <FontAwesomeIcon
                      // size={'2x'}
                      className="fs-16 "
                      id="send-btn"
                      icon={['fa', 'paper-plane']}
                      style={!disabledSend ? { color: '#33AAFC' } : { color: '#BDBDBD' }}
                    />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          {showMediaActions ? (
            <div className="w-100-P flex-row-nospacebetween-nowrap m-btm-15 m-top-15" id="message-buttons">
              <label
                id="client-name-btn"
                onMouseEnter={() => setHovering('media')}
                onMouseLeave={() => setHovering('')}
                style={{
                  ...buttonStyles,
                  backgroundColor: hovering === 'media' ? '#33AAFC' : '#FFF',
                  color: hovering === 'media' ? '#FFF' : '#33AAFC',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minWidth: '124px',
                }}
                className={`pointer m-left-10 rem-pad-width h-36 fs-12 ${
                  selectedCollections.length > 0 || selectedProducts.length > 0 ? 'disable-label' : ''
                }`}
                htmlFor="media-input"
              >
                Media
              </label>
              <input
                id="media-input"
                type="file"
                accept=".jpg, .jpeg, .png, .mp4"
                multiple
                onChange={event => {
                  handleImages(event);
                }}
                style={{ display: 'none' }}
                disabled={selectedCollections.length > 0 || selectedProducts.length > 0}
              />

              <button
                type="button"
                id="product-btn"
                onClick={toggleProducts}
                onMouseEnter={() => setHovering('product')}
                onMouseLeave={() => setHovering('')}
                style={{
                  ...buttonStyles,
                  backgroundColor: hovering === 'product' ? '#33AAFC' : '#FFF',
                  color: hovering === 'product' ? '#FFF' : '#33AAFC',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minWidth: '124px',
                }}
                className="pointer m-left-10 rem-pad-width h-36 fs-12 disable-button"
                disabled={
                  fileData.videos.videoFrames.urls.length + fileData.images.urls.length > 0 ||
                  selectedCollections.length > 0
                }
              >
                Product
              </button>
              {client.type !== 'F' && (
                <button
                  type="button"
                  id="collection-btn"
                  onClick={toggleCollections}
                  onMouseEnter={() => setHovering('collection')}
                  onMouseLeave={() => setHovering('')}
                  style={{
                    ...buttonStyles,
                    backgroundColor: hovering === 'collection' ? '#33AAFC' : '#FFF',
                    color: hovering === 'collection' ? '#FFF' : '#33AAFC',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '124px',
                  }}
                  className="pointer m-left-10 rem-pad-width h-36 fs-12 disable-button"
                  disabled={
                    fileData.videos.videoFrames.urls.length + fileData.images.urls.length > 0 ||
                    selectedProducts.length > 0
                  }
                >
                  Collections
                </button>
              )}
            </div>
          ) : (
            <div
              className="w-100-P flex-row-nospacebetween-wrap m-btm-15 m-top-15"
              id="message-buttons"
              style={{ rowGap: '10px' }}
            >
              <button
                type="button"
                id="client-name-btn"
                onClick={() =>
                  getCursorXY(
                    client ? (client.preferred_name ? client.preferred_name : client.name.split(' ')[0]) : ':name:',
                  )
                }
                onMouseEnter={() => setHovering('client')}
                onMouseLeave={() => setHovering('')}
                style={hovering === 'client' ? hoverButtonStyles : buttonStyles}
                className="pointer m-left-10 rem-pad-width h-36 fs-12"
              >
                Client Name
              </button>
              <ReviewLinkCheck
                getCursorXY={getCursorXY}
                client={client}
                location={location}
                template={template}
                onMouseEnter={() => setHovering('review')}
                onMouseLeave={() => setHovering('')}
                buttonStyles={
                  hovering === 'review'
                    ? { ...hoverButtonStyles, height: '36px', fontSize: '12px' }
                    : { ...buttonStyles, height: '36px', fontSize: '12px' }
                }
              />
              <button
                type="button"
                onClick={() => getCursorXY(selectedStoreName)}
                onMouseEnter={() => setHovering('store')}
                onMouseLeave={() => setHovering('')}
                style={hovering === 'store' ? hoverButtonStyles : buttonStyles}
                className="pointer m-left-10 rem-pad-width h-36 fs-12"
              >
                Store Name
              </button>
              {salesTriggers ? (
                <button
                  type="button"
                  onClick={() => getCursorXY(':category:')}
                  onMouseEnter={() => setHovering('category')}
                  onMouseLeave={() => setHovering('')}
                  style={hovering === 'category' ? hoverButtonStyles : buttonStyles}
                  className="pointer m-left-10 rem-pad-width h-36 fs-12"
                >
                  Product Category
                </button>
              ) : null}
              {salesTriggers ? (
                <button
                  type="button"
                  onClick={() => getCursorXY(':product:')}
                  onMouseEnter={() => setHovering('product')}
                  onMouseLeave={() => setHovering('')}
                  style={hovering === 'product' ? hoverButtonStyles : buttonStyles}
                  className="pointer m-left-10 rem-pad-width h-36 fs-12"
                >
                  Product Name
                </button>
              ) : null}
              {associate ? (
                <button
                  type="button"
                  id="associate-name-btn"
                  onClick={() => getCursorXY(`${associate}`)}
                  onMouseEnter={() => setHovering('associate')}
                  onMouseLeave={() => setHovering('')}
                  style={hovering === 'associate' ? hoverButtonStyles : buttonStyles}
                  className="pointer m-left-10 rem-pad-width h-36 fs-12"
                >
                  Associate Name
                </button>
              ) : null}
              {relationships
                ? relationships.map(relationship =>
                    relationship ? (
                      <button
                        type="button"
                        key={relationship?.relationship_id}
                        onClick={() => getCursorXY(relationship?.name?.split(' ')[0] || '')}
                        onMouseEnter={() => setHovering(`rel-${relationship.relationship_id}`)}
                        onMouseLeave={() => setHovering('')}
                        style={hovering === `rel-${relationship.relationship_id}` ? hoverButtonStyles : buttonStyles}
                        className="pointer m-left-10 rem-pad-width h-36 fs-12"
                      >
                        {`${relationship?.name?.split(' ')[0]} (${relationship.relationship})`}
                      </button>
                    ) : null,
                  )
                : null}
            </div>
          )}
        </div>
      </div>
      <ChatModal
        type={type}
        drawer={{
          bodyStyle: { padding: 0 },
          push: false,
          width: 600,
        }}
        open={showProducts}
        onClose={() => setShowProducts(false)}
      >
        <ChatProductModal
          className={type === 'full' ? 'chat-templates-modal-container-full' : 'chat-templates-modal-container-right'}
          onClose={() => setShowProducts(false)}
          onSelect={handleAddProduct}
        />
      </ChatModal>
      <ChatModal
        type={type}
        drawer={{
          bodyStyle: { padding: 0 },
          push: false,
          width: 600,
        }}
        open={showCollections}
        onClose={() => setShowCollections(false)}
      >
        <ChatCollectionModal
          className={type === 'full' ? 'chat-templates-modal-container-full' : 'chat-templates-modal-container-right'}
          onClose={() => setShowCollections(false)}
          clientId={client.client_id}
          merchantId={merchantId}
          onSave={handleAddCollection}
          selectedCollections={selectedCollections}
        />
      </ChatModal>
    </div>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
  clientState: state.client,
  messaging: state.messaging,
  chatMessages: state.messaging.chatMessages,
});
export default connect(mapStateToProps, {})(MessageInputActions);
