/* eslint-disable react/button-has-type */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux';
import { notificationError, notificationSuccess } from '../../../../../../../../core/actions';
import { postRequest } from '../../../../../../../../core/apiRequests';
import NumberInput from '../../../../../../../Layout/NumberInput';
import DropdownSelect from '../../../../../../../Layout/DropdownSelect';
import { setClientDataObj } from '../../../../../../../../reducers/client';
import { Modal, Form, Radio } from 'antd-v5';
import { format } from 'date-fns';

const AddSalesOpportunityModal = props => {
  const [priceValue, setPriceValue] = useState('');
  const [expectedCloseDate, setExpectedCloseDate] = useState(new Date(Date.now() + 12096e5));
  const [pipelineName, setPipelineName] = useState('');
  const [openAssociateModal, setOpenAssociateModal] = useState(false);
  const [newAssociateId, setNewAssociateId] = useState(props.client.associate_id);

  useEffect(() => {
    setNewAssociateId(props.client.associate_id);
  }, [props.client.associate_id])


  const pipelineHandler = pipeline => {
    const selectedPipeline = props.salesPipelines.filter(p => Number(p.id) === Number(pipeline));
    if (selectedPipeline.length > 0) {
      console.log(priceValue);
      if (priceValue === '') {
        setPriceValue(selectedPipeline[0].amount);
      }
      setExpectedCloseDate(moment(new Date()).add(selectedPipeline[0].days_to_close, 'days').toDate());
      setPipelineName(pipeline);
    }
  };

  const saveOppHandler = async (associateId) => {
    if (!pipelineName) {
      notificationError('Choose an opportunity');
    } else if (!expectedCloseDate) {
      notificationError('Please set a close date');
    } else {
      const selectedPipeline = props.salesPipelines.filter(p => Number(p.id) === Number(pipelineName));
      const { client_id, store_id } = props.client;
      const { firstName, lastName } = props.auth;
      const { merchantId } = props;
      const bodyData = {
        client_id,
        associateId,
        pipelineName: selectedPipeline[0].name,
        priceValue,
        expectedCloseDate,
        store_id,
        merchantId,
        firstName,
        lastName,
      };

      const reqObj = {
        params: ['clients', merchantId, selectedPipeline[0].id, 'saveAndUpdateSalesOpp'],
        query: { createdBy: 1 },
        data: bodyData,
        delay: 2500,
      };

      try {
        const respData = await postRequest(reqObj);

        if (!respData || !respData.id) {
          throw new Error('Failed To Save Sales Opp');
        }

        const newObj = {
          id: respData.id,
          first_name: firstName,
          last_name: lastName,
          close_date: format(expectedCloseDate, 'yyyyMMddHHmmss'),
          stage_name: respData.name,
          stage: respData.name,
          amount: bodyData.priceValue,
          name: `${bodyData.pipelineName} ${moment(new Date()).format('DD/MM/YYYY')}`,
          client_id,
          associate_id: associateId,
          created_date: moment(new Date()).format('YYYYMMDDHHmmss'),
        };

        setClientDataObj({
          salesOpps: [...props.client.salesOpps, newObj],
        });
        props.setSalesOpportunities(prevState => [...prevState, newObj]);
        props.refetchReminders();
        props.refetchAutoMessages();
        //await props.getClientData(client_id);
        notificationSuccess('Sales Opportunity created');
      } catch (error) {
        console.log(error)
        // notificationError('Failed To Save Sales Opp');
      } finally {
        props.showAddSalesOppHandler();
      }
    }
  };

  const handleSave = useCallback(() => {
    const findSalesOpp = props.salesPipelines.find(sp => sp.id === pipelineName);
    if(
      findSalesOpp &&
      props.client.associate_id &&
      props.associateId !== props.client.associate_id &&
      findSalesOpp?.reminderCount > 0
    ) {
        setOpenAssociateModal(true);
    } else {
      saveOppHandler(props.associateId);
    }
  }, [saveOppHandler]);

  const handleSaveWithNewAssociate = useCallback(() => {
    saveOppHandler(newAssociateId);
    setOpenAssociateModal(false);
  }, [newAssociateId, saveOppHandler]);

  const salesName = useMemo(() => props?.auth?.merchantConfig.SALES_OPP_NAME || 'Sales Opportunities', [props.auth.merchantConfig]);

  return (
    <div
      className="pop-confirm-background"
      id="modal-background"
      // onClick={backgroundCloseHandler}
    >
      <div className="pop-confirm-container rem-pad-width" style={{ width: '404px', minHeight: '542px' }}>
        <div
          className="flex-col-center h-100-P"
          style={{
            justifyContent: 'flex-start',
            width: '295px',
            margin: '0 auto',
          }}
        >
          <div className="flex-row-spacebetween-nowrap w-100-P m-top-15" style={{ position: 'relative' }} />
          <span className="fs-18 fw-500" style={{ marginTop: '15px' }}>
            {`Add ${salesName}`}
          </span>
          <DropdownSelect
            classProps="w-295 m-top-37 p-top-15"
            placeholder="Choose an opportunity..."
            value={pipelineName}
            options={props.salesPipelines.map(pipeline => ({
              value: pipeline.id,
              label: pipeline.name,
            }))}
            onChange={obj => pipelineHandler(obj.value)}
          />
          <div className="w-100-P m-top-15">
            <NumberInput
              decorator="$"
              min="0"
              step={1}
              precision={1}
              placeholder="1000"
              onChange={setPriceValue}
              value={priceValue}
            />
          </div>
          <div style={{ marginBottom: '99px' }}>
            <p
              style={{
                fontWeight: 500,
                fontSize: '14px',
                margin: '0',
                textAlign: 'left',
                width: '100%',
                marginTop: '22px',
              }}
            >
              Expected Close Date
            </p>
            <ReactDatePicker
              selected={expectedCloseDate}
              onChange={date => setExpectedCloseDate(date)}
              strictParsing
              minDate={moment().toDate()}
              customInput={
                <input
                  style={{
                    width: '295px',
                    height: '50px',
                    borderRadius: '3px',
                    border: 'solid 1px #818181',
                    paddingLeft: '16px',
                    marginTop: '13px',
                    boxSizing: 'border-box',
                    fontSize: '14px',
                  }}
                />
              }
            />
          </div>
          <div className="mq-w-100-vw custom-action-save-flex m-top-10">
            <button className="save-button-v2 m-btm-8" onClick={handleSave}>
              Save
            </button>
            <button className="cancel-button-v2" onClick={props.showAddSalesOppHandler}>
              Cancel
            </button>
          </div>
        </div>
        <Modal
          open={openAssociateModal}
          onCancel={() => {
            setOpenAssociateModal(false);
          }}
          centered
          title="There are reminders for this sales opportunity"
          onOk={handleSaveWithNewAssociate}
          okText="Save"
          closable={false}
        >
          <Form.Item
            className='m-top-16'
            label="Who should own the sales opportunity?"
            layout='vertical'
          >
            <Radio.Group onChange={(e) => setNewAssociateId(e.target.value)} value={newAssociateId} defaultValue={props.client.associate_id}>
              <Radio value={props.client.associate_id}>
                {`${props?.client?.associate?.first_name || ''} ${props?.client?.associate?.last_name || ''} (Assigned associate)`}
              </Radio>
              <Radio value={props.associateId}>{`${props?.auth?.firstName || ''} ${props?.auth?.lastName || ''} (you)`}</Radio>
            </Radio.Group>
          </Form.Item>
        </Modal>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  auth: { ...state.auth },
  client: { ...state.client },
});
export default connect(mapStateToProps, {})(AddSalesOpportunityModal);
