import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Table from '../../../Layout/Table';
import StyledLink from '../../../Layout/StyledLink';

const TodaySalesOpportunities = props => {
  const rowClickHandler = () => {};

  const bubbleStyles = {
    padding: '4px 0 2px 0',
  };

  const tableHeaders = [
    {
      keyName: 'close_date',
      title: 'Due Date',
      style: { width: '3%', paddingTop: '0px', midWidth: '120px' },
      headerClassName: '',
      rowClassName: '', // we can apply all styles to the header now.
      sortable: false, // This targets the header's className
      render: t => {
        if (t) {
          return moment(t, 'YYYYMDD').format('MM/DD/YYYY');
        }
      },
    },
    {
      keyName: 'client_name',
      style: { width: '2%', minWidth: '120px', paddingTop: '0px' },
      title: 'Client Name',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        return (
          <StyledLink classprops="blue-link" to={`/Clients?client=${o.client_id}`}>
            {t}
          </StyledLink>
        );
      },
    },
    {
      keyName: 'name',
      style: { width: '20%', minWidth: '120px', paddingTop: '0px' },
      title: 'Description',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        return (
          <StyledLink classprops="blue-link" to={`/Clients?client=${o.client_id}&oppId=${o.id}`}>
            {t}
          </StyledLink>
        );
      },
    },
    {
      keyName: 'amount',
      style: { width: '3%', minWidth: '80px', paddingTop: '0px' },
      title: 'Price Value',
      sortable: false, // sort allows gives us the ability to sort the table
      render: t => {
        if (t) {
          return `$${t}`;
        }
        return '$0';
      },
    },
    {
      keyName: 'stage_name',
      style: { width: '3%', minWidth: '80px', paddingTop: '0px' },
      title: 'Stage',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        if (o.weight === 0 || o.weight === 1) {
          return (
            <div
              style={{ ...bubbleStyles, backgroundColor: '#e8f4ff' }}
              className="align-center rem-pad-width border-r3 align-vert-middle h-24 fs-10 fw-600 w-72 blue"
            >
              {t}
            </div>
          );
        }
        if (green(o) == o.sales_opp_stage_id) {
          return (
            <div
              style={{ ...bubbleStyles, backgroundColor: '#ecf8dd' }}
              className="align-center rem-pad-width border-r3 align-vert-middle h-24 fs-10 fw-600 w-72 green"
            >
              {t}
            </div>
          );
        }
        return (
          <div
            style={{
              ...bubbleStyles,
              backgroundColor: '#ffe5d3',
              color: '#fc8333',
            }}
            className="align-center rem-pad-width border-r3 align-vert-middle h-24 fs-10 fw-600 w-72"
          >
            {t}
          </div>
        );
      },
    },
    {
      keyName: 'created_by',
      style: { width: '2%', paddingTop: '0px', minWidth: '120px' },
      title: 'Created by',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        return <span>{fullNameCheck(o.first_name, o.last_name)}</span>;
      },
    },
    {
      keyName: '',
      style: { width: '3%', paddingTop: '0px' },
      title: '',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        const now = moment(new Date());
        if (!o.complete_date) {
          if (moment(now).isAfter(moment(o.close_date, 'YYYYMMDD'))) {
            return (
              <div
                style={{
                  ...bubbleStyles,
                  backgroundColor: '#f9e0e0',
                  color: '#ff7a7a',
                }}
                className="align-center border-r3 rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 blue"
              >
                OVERDUE
              </div>
            );
          }
        }
      },
    },
  ];

  const green = obj => {
    if (props.data.length > 0) {
      const filteredSalesOpps = props.salesStages.filter(opp => opp.sales_pipeline_id == obj.sales_pipeline_id) || [];
      let small = {};
      if (filteredSalesOpps.length > 0) {
        small = filteredSalesOpps.reduce(function (prev, curr) {
          return prev.weight < curr.weight ? prev : curr;
        });
        return small.id;
      }
    }
  };

  const fullNameCheck = (first, last) => {
    if (last) {
      return `${first} ${last}`.trim();
    }
    return first;
  };



  return (
    <div className="m-top-50">
      <div className="flex-row-spacebetween-nowrap">
        <span className="fs-15 fw-500">{props.salesName}</span>
        <StyledLink to="/all-sales-opp" id="ViewAllOpportunities" classprops="fs-13 blue-link fw-500">
          View All
        </StyledLink>
      </div>
      {props.data.length > 0 ? (
        <Table
          data={props.data}
          tableHeaders={tableHeaders}
          tableClassName="lg-table-sidebar  rem-pad-width"
          optionalButton={null}
          onRowClick={rowClickHandler}
          tableName="SALES_OPP_TODAY"
        />
      ) : (
        <div className="align-center h-173 w-100-P">
          <span className="pos-rel gray fs-13" style={{ top: '45%' }}>
            {`No ${props.salesName}`}
          </span>
        </div>
      )}
    </div>
  );
};
export default TodaySalesOpportunities;
