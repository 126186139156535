import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { deleteRequest, getRequest, postRequest } from '../../../../core/apiRequests';
import { Buttons } from './Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { notificationError, notificationSuccess } from '../../../../core/actions';
import PopConfirm from '../../PopConfirm';
import { setClientAddresses } from '../../../../reducers/client';

const Addresses = props => {
  const clientId = !!props.client.client_id ? props.client.client_id : props.client.id;
  const addresses = props.addresses || [];
  const [openEdit, setOpenEdit] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [newAddress, setNewAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  const {
    register: registerNewAddress,
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset
  } = useForm();

  const handleNewAddressCancel = useCallback(() => {
    setNewAddress(false);
    reset();
    if(addresses.length === 0) {
      setOpenEdit(false);
    }
  }, [addresses])

  const handleNewAddress = useCallback(async (data) => {
    const dataObj = {
      client_id: clientId,
      addresses: [{
        ...data,
        hasChanged: true
      }]
    }
    const reqObj = {
      params: ['clients', clientId, 'editClientAddresses'],
      data: dataObj,
    };
    try {
      const {newAddressId} = await postRequest(reqObj);
      console.log(newAddressId)
      notificationSuccess('Address Added');
      const newAddresses = [...addresses, {...data, id: newAddressId}];
      props.setValue('addresses', newAddresses)
      setClientAddresses(newAddresses);
    } catch (err) {
      console.log(err);
    } finally {
      setNewAddress(false);
      setOpenEdit(false);
      reset();
    }
  }, [clientId, addresses]);

  const setEditButton = useCallback((value) => {
    setOpenEdit(value);
    if(value) {
      if(addresses.length > 0) {
        setNewAddress(false);
      } else {
        setNewAddress(true);
      }
    }
  }, [addresses])

  const openDeleteModal = useCallback((address) => {
    setSelectedAddress(address);
    setOpenDelete(true);
  }, []);

  const handleDeleteAddress = useCallback(async () => {
    const reqObj = {
      params: ['clients', props.merchantId, clientId, selectedAddress.id, 'deleteAddress'],
    };
    try {
      await deleteRequest(reqObj);
      notificationSuccess('Address Removed');
      const filteredAddresses = addresses.filter(address => address.id != selectedAddress.id);
      props.resetField('addresses', {defaultValue: filteredAddresses})
      setClientAddresses(filteredAddresses);
    } catch (err) {
      console.log(err);
      notificationError('Error Removing Address');
    } finally {
      setOpenDelete(false);
      setSelectedAddress(null);
    }
  }, [selectedAddress, addresses]);

  const handleCancel = useCallback(() => {
    setOpenEdit(false);
    props.resetField('addresses');
  }, []);

  const handleSave = useCallback(async () => {
    if(!props.dirtyFields.addresses) return;

    let updatedAddresses = addresses.map((address, index) => {
      if(!!props?.dirtyFields?.addresses[index]) {
        return {
          ...address,
          hasChanged: true
        }
      } else {
        return address;
      }
    });
    const reqObj = {
      params: ['clients', clientId, 'editClientAddresses'],
      data: {
        client_id: clientId,
        addresses: updatedAddresses
      },
    };
    try {
      await postRequest(reqObj);
      notificationSuccess('Addresses Updated');
      props.resetField('addresses', {defaultValue: updatedAddresses})
      setClientAddresses(updatedAddresses);
    } catch (err) {
      console.log(err);
      notificationError('Error Updating Addresses');
    } finally {
      setOpenEdit(false);
      setNewAddress(false);
    }
  }, [props.dirtyFields, clientId, addresses]);


  const handleKeyDown = useCallback((e) => {
    if(e.key === 'Enter'){
      handleSave();
    }
  }, [handleSave])

  return (
    <div
      id="client-details-addresses-box"
      style={{ borderTop: '1px #ebebeb solid' }}
    >
      <div className='flex-row-center' style={{ position: 'relative', gap: '8px' }}>
        <p className="fw-500 gray fs-15 align-left">Addresses</p>
        {openEdit && !newAddress && <FontAwesomeIcon size={18} icon={['far', 'plus']} className='pointer' onClick={() => setNewAddress(true)} />}
        <Buttons
          open={openEdit && !newAddress}
          setOpen={setEditButton}
          onSave={handleSave}
          onCancel={handleCancel}
          avoidHover={newAddress}
          containerStyles={{ top: '12px'}}
          id='client-details-addresses-buttons'
          parentId='client-details-addresses-box'
        />
      </div>
      <div>
        {newAddress ? (
          <div className='flex-col-left'>
            <span className='fs-12 fw-500'>New Address</span>
            <form className='flex-col-left m-top-8' style={{gap: 16}}>
              <AddressForm
                register={registerNewAddress}
                prefix=''
                isNew
                handleKeyDown={handleKeyDown}
              />
              <div className="mq-w-100-vw custom-action-save-flex">
                <button type="button" onClick={handleSubmit(handleNewAddress)} className="save-button-v2 m-btm-8" disabled={!isValid}>
                  {isSubmitting ? "Saving..." : "Save"}
                </button>
                <button type="button" className="cancel-button-v2" onClick={handleNewAddressCancel}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        ) : (
          addresses?.length > 0 ? (
            addresses.map((address, index) =>
              openEdit ? (
                  <AddressForm
                    register={props.register}
                    prefix={`addresses.${index}.`}
                    key={`${address.type}-${index}`}
                    openDeleteModal={openDeleteModal}
                    address={address}
                    handleKeyDown={handleKeyDown}
                  />
                ) : (
                  <AddressValue
                    address={address}
                    key={`${address.type}-${index}`}
                    isNew
                  />
                )
            )
          ) : (
            <span className="gray fs-12">No Addresses</span>
          )
        )}
      </div>
      {openDelete && (
        <PopConfirm
          confirm={`Are you sure you want to delete "${selectedAddress.type}" Address?`}
          description="This will permanently delete this address"
          onCancel={() => setOpenDelete(false)}
          onConfirm={handleDeleteAddress}
        />
      )}
    </div>
  );
};

const AddressValue = ({ address }) => {

  return (
    <div className="align-left m-btm-10 flex-col-left">
      <span className="fs-12 fw-500 gray">{address.type}</span>
      <span className="fs-12 fw-500 black">{`${address.address_1}${address?.address_2 ? `, ${address?.address_2}`: ''}`}</span>
      <span className="fs-12 fw-500 black">
        {address.city ? `${address.city}` : ''}
        {address.state ? `, ${address.state}` : ' '}
        {address.postal_code ? ` ${address.postal_code}` : ''}
      </span>
    </div>
  )
}

const AddressForm = ({register, prefix, isNew, address, openDeleteModal, handleKeyDown}) => {

  return (
    <div className='flex-row-spacebetween-start'>
      <div className="flex-col-left m-btm-12">
        <input
          className="fs-12 outline-none w-100-P client-input fw-500 m-btm-8"
          placeholder="Address Type"
          {...register(`${prefix}type`, { required: true})}
            onKeyDown={!isNew ? handleKeyDown : () => {}}
        />
        <input
          className="fs-12 outline-none w-100-P client-input fw-500 m-btm-8"
          placeholder="Street"
          {...register(`${prefix}address_1`, { required: true})}
            onKeyDown={!isNew ? handleKeyDown : () => {}}
        />
        <input
          className="fs-12 outline-none w-100-P client-input fw-500 m-btm-8"
          placeholder="Apt / Suite #"
          {...register(`${prefix}address_2`)}
            onKeyDown={!isNew ? handleKeyDown : () => {}}
        />
        <input
          className="fs-12 outline-none w-100-P client-input fw-500 m-btm-8"
          placeholder="City"
          {...register(`${prefix}city`, { required: true})}
            onKeyDown={!isNew ? handleKeyDown : () => {}}
        />
        <div className='flex-row-spacebetween-nowrap'>
          <input
            className="fs-12 outline-none w-100-P client-input fw-500 m-btm-8"
            placeholder="State / Province"
            {...register(`${prefix}state`, { required: true})}
            onKeyDown={!isNew ? handleKeyDown : () => {}}
          />
          <input
            className="fs-12 outline-none w-100-P client-input fw-500 m-btm-8"
            placeholder="Zip Code"
            {...register(`${prefix}postal_code`, { required: true})}
            onKeyDown={!isNew ? handleKeyDown : () => {}}
          />
        </div>
      </div>
      {!isNew && (
        <div style={{ width: '40px', marginTop: '4px'}}>
          <FontAwesomeIcon
            icon={['far', 'trash-alt']}
            className='pointer'
            color='#FF7A7A'
            onClick={() => openDeleteModal(address)}
          />
        </div>
      )}
    </div>

  )
}

const mapStateToProps = state => ({
  client: state.client,
});
export default connect(mapStateToProps)(Addresses);
