const initialState = {
  active: false,
  status: 'NONE',
};

// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_PAYMENT_BANNER_STATUS':
      return { ...state, status: 'NONE' };
    case 'SET_PAYMENT_BANNER_ACTIVE':
      return { ...state, active: false };
    default:
      return state;
  }
}
