import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Button from '../../Button';
import StyledLink from '../../StyledLink';
import PopConfirm from '../../PopConfirm';
import CheckboxDesc from '../../CheckboxDesc';
import {
  setMessageCount,
  setMessageInterval,
  setMessageRepeat,
  setMessageId,
  setMessageAddEdit,
  setAutoMessageText,
  setMessageRepeatCount,
  setMessageRepeatInterval,
  setMessageActive,
} from '../../../../reducers/autoMessages';
import { getRequest, postRequest, deleteRequest, getTablePagList } from '../../../../core/apiRequests';
import { notificationSuccess, notificationError, AutoMessagesCheck } from '../../../../core/actions';
import DropdownSelect from '../../DropdownSelect';
import NumberInput from '../../NumberInput';
import KioskSlider from '../../KioskSlider';
import TextAreaWithActions from '../../TextAreaWithActions';
import useActionTextAreaFiles, { actionTextAreaFileActions } from '../../TextAreaWithActions/useActionTextAreaFiles';
import AutoMsgTypedRelationship from './AMsgTypedRelationship';

const AutoMessageSettings = props => {
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const history = useHistory();
  const [showImmediateDD, setShowImmediateDD] = useState(false);
  // IF this is true, set action interval and action scale to 0 days
  const [autoMessageImmediate, setMessageImmediate] = useState(false);
  // reflect database data for interval / scale
  const [tempActionScale, setTempActionScale] = useState(1);
  const [tempActionInterval, setTempActionInterval] = useState('WEEK');
  const [pendingAuto, setPendingAuto] = useState({
    futureOnly: false,
    isDelete: false,
    automationType: '',
    isDisabled: false,
  });
  const [showPendingAutoPop, setShowPendingAutoPop] = useState(false);
  const intervalOptions = [
    { label: 'Days', value: 'DAY' },
    { label: 'Weeks', value: 'WEEK' },
    { label: 'Months', value: 'MONTH' },
  ];
  const [images, setImages] = useState({
    urls: [],
    files: [],
  });
  const [reviewLink, setReviewLink] = useState('[Review Link]');
  const [fileData, fileDispatch] = useActionTextAreaFiles();
  // relationship
  const [selectLoading, setSelectLoading] = useState(false);
  const [typedRelationshipsOptions, setTypedRelationshipsOptions] = useState([]);
  const [relationship, setRelationship] = useState(null);

  useEffect(() => {
    (async () => {
      setSelectLoading(true);
      const dataArr = await getTablePagList({
        tableName: 'CLIENT_PREFERENCES',
        type: 'typed-relationships',
        merchantId: props.merchantId,
        sortColumn: 'name',
        count: 1000,
      });
      setTypedRelationshipsOptions([
        { label: 'Client', value: null },
        ...dataArr.map(item => ({ value: item.id, label: item.name })),
      ]);
      setSelectLoading(false);
    })();
  }, [props.merchantId]);

  useEffect(() => {
    if (window.location.href.includes('add-auto-message')) {
      setMessageImmediate(true);
      setMessageCount(0);
      setMessageInterval('DAY');
      setMessageActive(1);
      fileDispatch({
        type: actionTextAreaFileActions.clear,
      });
    }
  }, []);

  useEffect(() => {
    const {
      type,
      merchantId,
      autoMessages: { autoMessageId },
    } = props;
    if (merchantId) {
      if (window.location.href.includes('add-auto-message')) {
        setMessageAddEdit('Add');
      }
      if (
        (!autoMessageId && window.location.href.includes('edit-auto-message')) ||
        (autoMessageId !== props.match.params.messageId && window.location.href.includes('edit-auto-message'))
      ) {
        setMessageAddEdit('Edit');
        if (props.match.params.listItemId && type) {
          (async () => {
            const reqObj = {
              params: [type, merchantId, props.match.params.listItemId, 'autoMessage'],
              query: {
                messageId: props.match.params.messageId,
              },
            };
            const data = await getRequest(reqObj);
            if (data && data.length > 0) {
              const {
                id,
                msg,
                action_interval,
                action_scale,
                active,
                repeats,
                repeat_interval,
                repeat_scale,
                image_url,
                relationship_type_id,
              } = data[0];
              if (image_url) {
                fileDispatch({
                  type: actionTextAreaFileActions.setToSingleImageUrl,
                  payload: image_url,
                });
              } else {
                fileDispatch({
                  type: actionTextAreaFileActions.clear,
                });
              }
              setMessageId(id);
              setMessageActive(active);
              setAutoMessageText(msg);
              setMessageCount(action_scale);
              setMessageInterval(action_interval);
              setTempActionScale(action_scale);
              setTempActionInterval(action_interval);
              setRelationship(relationship_type_id);
              setMessageRepeat(Number(repeats));
              setMessageRepeatInterval(
                repeat_interval === undefined || repeat_interval === null ? '' : repeat_interval,
              );
              setMessageRepeatCount(repeat_scale === undefined || repeat_scale === null ? '' : repeat_scale);
              if (action_interval == 'DAY' && action_scale === 0) {
                setMessageImmediate(true);
              } else {
                setMessageImmediate(false);
              }
            } else {
              // TODO:  Do anything here?
            }
          })();
        }
      } else {
        // nothing?
      }
    }
    return () => {
      setMessageId('');
      setAutoMessageText('');
      setMessageCount('');
      setMessageInterval('WEEK');
      setTempActionScale(1);
      setTempActionInterval('WEEK');
      setMessageRepeat(Number(0));
      setMessageRepeatInterval('WEEK');
      setMessageRepeatCount(1);
    };
  }, [props.merchantId]);

  useEffect(() => {
    if (autoMessageImmediate) {
      setShowImmediateDD(!showImmediateDD);
    } else {
      setShowImmediateDD(!showImmediateDD);
    }
  }, [autoMessageImmediate]);

  const confirmDelete = () => {
    const { listItemId } = props.match.params;
    const {
      type,
      autoMessages: { autoMessageId },
      firstName,
      lastName,
    } = props;
    const fn = firstName || '';
    const ln = lastName || '';
    const dataObj = { deletedBy: `${fn} ${ln}` };
    if (type !== 'lifeEvent') {
      dataObj.futureOnly = pendingAuto.futureOnly;
    }
    if (autoMessageId) {
      (async () => {
        const reqObj = {
          params: [type, props.merchantId, listItemId, 'autoMessage', autoMessageId],
          query: dataObj,
        };
        const data = await deleteRequest(reqObj);
        if (data) {
          notificationSuccess('Auto Message Removed');
          history.push(`${props.editRoute}`);
        }
      })();
    } else {
      // notificationError('Error: No ID detected');
    }
  };
  const createAutoMessage = () => {
    const { listItemId } = props.match.params;
    const {
      type,
      autoMessages: {
        autoMessageId,
        autoMessageText,
        autoMessageCount,
        autoMessageInterval,
        autoMessageRepeat,
        autoMessageActive,
        // repeat stuff here
        autoMessageRepeatCount,
        autoMessageRepeatInterval,
        // relationship stuff here
      },
      firstName,
      lastName,
    } = props;
    const fn = firstName || '';
    const ln = lastName || '';
    const timeOffset = moment(new Date()).utcOffset();
    const s3_images = fileData.images.urls.filter(image => image.includes('s3.amazonaws.com'));
    const cleanedURLs = fileData.images.urls.filter(image => image.includes('blob'));
    const cleanedImgObj = { urls: cleanedURLs, files: [...fileData.images.files] };
    const formattedText = autoMessageText
      .replace('[Client Name]', ':name:')
      .replace('[Associate Name]', ':associate:')
      .replace('[Review Link]', ':review:')
      .replace('[Relationship Name]', ':relation_name:');
    if (!formattedText) {
      notificationError('Message Text Required');
    } else if (listItemId && type && AutoMessagesCheck()) {
      const dataObj = {
        timeOffset,
        description: formattedText,
        actionScale: autoMessageCount,
        actionInterval: autoMessageInterval,
        active: autoMessageActive,
        // repeat stuff here
        repeats: Number(autoMessageRepeat),
        repeatScale: autoMessageRepeat === 1 ? autoMessageRepeatCount : null,
        repeatInterval: autoMessageRepeat === 1 ? autoMessageRepeatInterval.toUpperCase() : null,
      };
      if (autoMessageId) {
        dataObj.id = autoMessageId;
      }
      dataObj.futureOnly = pendingAuto.futureOnly;
      if (type === 'lifeEvent') {
        dataObj.relationshipId = relationship;
      }
      if (
        (autoMessageRepeat && !autoMessageRepeatCount && type !== 'lifeEvent') ||
        (autoMessageRepeat && !autoMessageRepeatInterval && type !== 'lifeEvent')
      ) {
        notificationError('Please set a repeat schedule');
      } else {
        (async () => {
          const reqObj = {
            params: [type, props.merchantId, listItemId, 'autoMessage'],
            query: { createdBy: `${fn} ${ln}`, s3Images: s3_images },
            data: dataObj,
            images: cleanedImgObj,
          };
          const data = await postRequest(reqObj);
          if (data.id) {
            if (props.autoMessages.autoMessageId) {
              notificationSuccess('Auto Message Updated!');
              // history.push(`${props.editRoute}`)
            } else {
              notificationSuccess('Auto Message Created!');
              // history.push(`${props.editRoute}/edit-auto-message/${data.id}`);
            }
            setMessageId(data.id);
            history.push(`${props.editRoute}`);
            // setMessageAddEdit("Edit");
          } else {
            // TODO: error handling?
            notificationError('Failed to update/create auto message!');
          }
        })();
      }
    }
  };

  const immediateFunction = () => {
    setMessageImmediate(!autoMessageImmediate);
    if (!autoMessageImmediate) {
      setMessageCount(0);
      setMessageInterval('DAY');
    } else {
      setMessageCount(tempActionScale);
      setMessageInterval(tempActionInterval);
    }
  };

  const repeatFunction = () => {
    if (props.autoMessages.autoMessageRepeat === 0) {
      setMessageRepeatCount(props.autoMessages.autoMessageCount);
      setMessageRepeatInterval(props.autoMessages.autoMessageInterval);
      setMessageRepeat(1);
    } else {
      setMessageRepeatCount('');
      setMessageRepeatInterval('');
      setMessageRepeat(0);
    }
    // setActionRepeat(Number(actionRepeat) === 1 ? 0 : 1);
  };

  const AutoMessageNameChange = e => {
    setAutoMessageText(e);
    if (window.location.href.includes('edit-auto-message')) {
      setPendingAuto({ ...pendingAuto, automationType: 'DESCRIPTION' });
    } else setPendingAuto({ ...pendingAuto, automationType: '' });
  };

  const AutomationCheck = () => {
    if (pendingAuto.automationType == 'DESCRIPTION' || pendingAuto.isDisabled == true) {
      setShowPendingAutoPop(true);
    } else {
      createAutoMessage();
    }
  };

  const AutomationSubmit = () => {
    if (pendingAuto.isDelete) {
      setShowPopConfirm(true);
    } else {
      createAutoMessage();
    }
    setShowPendingAutoPop(false);
  };

  return (
    <>
      <div className="w-100-P flex-col-left" style={{ marginBottom: 30 }}>
        {props.type === 'lifeEvent' ? (
          <AutoMsgTypedRelationship
            selectLoading={selectLoading}
            typedRelationshipsOptions={typedRelationshipsOptions}
            relationship={relationship}
            setRelationship={setRelationship}
            flags={props.merchantFlags}
          />
        ) : null}
        <div
          className="max-w-600"
          style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <span style={{ fontSize: '13px', fontWeight: '500' }}>Turn On/Off Automation</span>
            <span style={{ fontSize: '10px', color: '#979797' }}>
              Disabling automation requires the user to send messages manually
            </span>
          </div>
          <KioskSlider
            value={props.autoMessages.autoMessageActive}
            onChange={() => {
              if (props.autoMessages.autoMessageActive === 1) {
                setMessageActive(0);
                setPendingAuto({ ...pendingAuto, isDisabled: true });
              } else {
                setMessageActive(1);
                setPendingAuto({ ...pendingAuto, isDisabled: false });
              }
            }}
          />
        </div>

        <TextAreaWithActions
          val={props.autoMessages.autoMessageText}
          changeHandler={AutoMessageNameChange}
          containerStyleProps={{
            marginTop: '16px',
            maxWidth: '600px',
            width: '100%',
            borderRadius: '3px',
          }}
          styleProps={{
            borderRadius: '3px',
            height: '18vh',
          }}
          autoMessageOrReminder
          reviewLink={null}
          template
          associate=":assigned_associate_name:"
          fileData={fileData}
          fileDispatch={fileDispatch}
          relationshipName={props.type === 'lifeEvent' && relationship !== null ? ':relation_name:' : ''}
        />
        <span className="fs-10 gray d-block m-top-30 m-btm-15">
          {props.selectAutoMessageTime
            ? props.selectAutoMessageTime
            : 'After tagging the client, how soon should the message send?'}
        </span>
        {/* IMMEDIATELY */}
        <CheckboxDesc
          checked={autoMessageImmediate}
          onclick={immediateFunction}
          text="Same Day"
          classProps="fs-13"
          checkButtonProps="m-btm-15 w-85"
        />
        {showImmediateDD ? (
          <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel m-btm-28">
            <div className="w-48-P inputHeight align-left" style={{ position: 'relative' }}>
              <NumberInput
                onChange={setMessageCount}
                min="1"
                max="31"
                step={1}
                precision={1}
                value={props.autoMessages.autoMessageCount}
                classProps=""
                placeholder="1"
              />
            </div>
            <div className=" w-48-P inputHeight" style={{ position: 'relative' }}>
              <DropdownSelect
                classProps="maw-100-P w-100-P align-left"
                options={intervalOptions}
                placeholder="Week"
                value={props.autoMessages.autoMessageInterval}
                onChange={obj => {
                  setMessageInterval(obj.value);
                  // setIntervalObj(obj);
                }}
              />
            </div>
          </div>
        ) : null}
        {/* REPEATS */}
        {!window.location.href.includes('life-events') ? (
          <div className="max-w-600 w-100-P">
            <span className="fs-10 gray d-block m-btm-15 align-left">
              Once the initial message is sent, how often would you like it to repeat?
            </span>
            <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel m-btm-28">
              <CheckboxDesc
                checked={Number(props.autoMessages.autoMessageRepeat)}
                onclick={repeatFunction}
                text="Repeats"
                classProps="fs-13"
                checkButtonProps="m-btm-15"
              />
              {Number(props.autoMessages.autoMessageRepeat) ? (
                <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel">
                  <div className="w-48-P inputHeight align-left" style={{ position: 'relative' }}>
                    <NumberInput
                      onChange={e => {
                        setMessageRepeatCount(e);
                      }}
                      min="1"
                      max="31"
                      step={1}
                      precision={1}
                      value={props.autoMessages.autoMessageRepeatCount || ''}
                      classProps=""
                      placeholder="6"
                    />
                  </div>
                  <div className=" w-48-P inputHeight" style={{ position: 'relative' }}>
                    <DropdownSelect
                      classProps="maw-100-P w-100-P align-left"
                      options={intervalOptions}
                      placeholder="Months"
                      value={props.autoMessages.autoMessageRepeatInterval}
                      onChange={obj => {
                        setMessageRepeatInterval(obj.value);
                        // setRepeatIntervalObj(obj);
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>

      {/* START of save / cancel / delete code  */}
      <div className=" m-top-37 w-600">
        <div className="mq-w-100-vw custom-action-save-flex">
          <button className="save-button-v2 m-btm-8" onClick={AutomationCheck}>
            Save
          </button>
          {window.location.href.includes('edit-auto-message') ? (
            <Button
              classname="deleteButton-v2 "
              onclick={() => {
                setShowPendingAutoPop(true);
                setPendingAuto({ ...pendingAuto, isDelete: true });
              }}
            >
              Delete
            </Button>
          ) : (
            <div />
          )}
          <StyledLink to={props.cancelRoute}>
            <button className="cancel-button-v2 m-btm-8">Cancel</button>
          </StyledLink>
        </div>
      </div>
      {showPendingAutoPop ? (
        <PopConfirm
          confirm="These changes will be applied by default to both scheduled and future automation"
          customButtons
          description={
            <div className="flex-col-left w-100-P align-center">
              <CheckboxDesc
                checked={pendingAuto.futureOnly}
                onclick={() => setPendingAuto({ ...pendingAuto, futureOnly: !pendingAuto.futureOnly })}
                text="Only apply to future automation"
                checkButtonProps="p-top-4 m-left-8"
                classProps="m-top-10"
                checkBoxId="autoMessageAutomationPopupCheckbox"
              />
              <div
                className="w-100-P m-top-15 m-btm-28 flex-row-nospacebetween-nowrap"
                style={{ justifyContent: 'center' }}
              >
                <button
                  className="save-button m-right-15 m-top-15"
                  onClick={AutomationSubmit}
                  id="autoMessageAutomationSubmitBtn"
                >
                  Submit
                </button>
              </div>
            </div>
          }
        />
      ) : null}
      {showPopConfirm ? (
        <PopConfirm
          confirm="Are you sure you want to remove this auto message?"
          description={props.popConfirmDesc}
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={confirmDelete}
        />
      ) : null}
    </>
  );
};
const mapStateToProps = state => ({
  autoMessages: state.autoMessages,
  ...state.auth,
});
export default connect(mapStateToProps, {})(AutoMessageSettings);
