import { Card, Statistic } from 'antd-v5';
import React, { useCallback } from 'react';

const ReportStatics = ({title, value = 0, precision = 0, prefix, description, maxStatsWidth, onClick, ...rest}) => {

  const handleClick = useCallback(() => {
    if(onClick) {
      onClick();
    }
  }, [onClick]);
  return (
    <Card
      bordered={false}
      className='w-100-P flex-row-center align-left'
      style={{ maxWidth: maxStatsWidth, cursor: onClick ? 'pointer' : 'default' }}
      onClick={handleClick}
    >
      <Statistic
        title={title}
        value={value}
        precision={precision}
        className='ff-poppins'
        valueStyle={{ fontWeight: 700}}
        prefix={prefix}
        {...rest}
      />
      {description}
    </Card>
  )
};

export default ReportStatics;
