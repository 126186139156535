import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import StyledLink from '../../StyledLink';
import { getRequest, postRequest } from '../../../../core/apiRequests';
import { setTriggerActionDesc } from '../../../../reducers/salesTriggers';
import TextAreaWithActions from '../../TextAreaWithActions';
import useActionTextAreaFiles, { actionTextAreaFileActions } from '../../TextAreaWithActions/useActionTextAreaFiles';
import PopConfirm from '../../PopConfirm';
import CheckboxDesc from '../../CheckboxDesc';

const AutoMessTriggerSettings = props => {
  const history = useHistory();
  const [fileData, fileDispatch] = useActionTextAreaFiles();
  const [reviewLink, setReviewLink] = useState('[Review Link]');
  const [pendingAuto, setPendingAuto] = useState({ futureOnly: false, automationType: '' });
  const [showPendingAutoPop, setShowPendingAutoPop] = useState(false);

  useEffect(() => {
    if (props.salesTriggers.actionImg) {
      fileDispatch({
        type: actionTextAreaFileActions.setToSingleImageUrl,
        payload: props.salesTriggers.actionImg,
      });
    } else {
      fileDispatch({
        type: actionTextAreaFileActions.clear,
      });
    }
  }, [props.merchantId, props.salesTriggers.actionImg]);

  useEffect(() => {
    let { storeId } = props;
    if (props.merchantId && props.stores.length > 1) {
      if (storeId === 0) {
        storeId = props.stores[1].id;
      }
      (async () => {
        const reqObj = {
          params: ['associates', props.userId, storeId, 'reviewLink'],
        };
        const data = await getRequest(reqObj);
        if (data[0].review_link) {
          setReviewLink(data[0].review_link);
        }
      })();
    }
  }, [props.merchantId, props.userId, props.stores]);

  const createAutoMessage = () => {
    const s3_images = fileData.images.urls.filter(image => image.includes('s3.amazonaws.com'));
    const cleanedURLs = fileData.images.urls.filter(image => image.includes('blob'));
    const cleanedImgObj = { urls: cleanedURLs, files: [...fileData.images.files] };
    const dataObj = {
      description: props.salesTriggers.actionDescription,
      futureOnly: pendingAuto.futureOnly,
      type: 'AUTO_MESSAGE',
    };
    (async () => {
      const reqObj = {
        params: ['salesTrigger', props.merchantId, props.match.params.actionId, 'saveDescription'],
        query: { s3Images: s3_images },
        data: dataObj,
        images: cleanedImgObj,
      };
      const data = await postRequest(reqObj);
      if (data) {
        history.push('/Configuration/sales-triggers');
      }
    })();
  };

  const AutoReminderNameChange = e => {
    setTriggerActionDesc(e);
    if (window.location.href.includes('edit-trigger-auto-message')) {
      setPendingAuto({ ...pendingAuto, automationType: 'DESCRIPTION' });
    } else setPendingAuto({ ...pendingAuto, automationType: '' });
  };

  const AutomationSubmit = () => {
    createAutoMessage();
    setShowPendingAutoPop(false);
  };

  const saveCheck = () => {
    if (pendingAuto.automationType == 'DESCRIPTION') {
      setShowPendingAutoPop(true);
    } else {
      createAutoMessage();
    }
  };

  return (
    <>
      <div className="w-100-P flex-col-left" style={{ marginBottom: 30 }}>
        <TextAreaWithActions
          val={props.salesTriggers.actionDescription}
          changeHandler={AutoReminderNameChange}
          salesTriggers
          template
          associate=":assigned_associate_name:"
          autoMessageOrReminder
          reviewLink={null}
          containerStyleProps={{
            marginTop: '16px',
            width: '100%',
          }}
          styleProps={{
            height: '144px',
          }}
          fileData={fileData}
          fileDispatch={fileDispatch}
        />
      </div>

      {/* START of save / cancel / delete code  */}
      <div className="m-top-37">
        <div className="mq-w-100-vw custom-action-save-flex">
          <button className="save-button-v2 m-btm-8" onClick={saveCheck}>
            Save
          </button>
           <StyledLink to={props.cancelRoute}>
            <button className="cancel-button-v2">Cancel</button>
          </StyledLink>
        </div>
      </div>
      {showPendingAutoPop ? (
        <PopConfirm
          confirm="These changes will be applied by default to both scheduled and future automation"
          customButtons
          description={
            <div className="flex-col-left w-100-P align-center">
              <CheckboxDesc
                checked={pendingAuto.futureOnly}
                onclick={() => setPendingAuto({ ...pendingAuto, futureOnly: !pendingAuto.futureOnly })}
                text="Only apply to future automation"
                checkButtonProps="p-top-4 m-left-8"
                classProps="m-top-10"
                checkBoxId="autoMessageSalesTriggerAutomationPopupCheckbox"
              />
              <div
                className="w-100-P m-top-15 m-btm-28 flex-row-nospacebetween-nowrap"
                style={{ justifyContent: 'center' }}
              >
                <button
                  className="save-button m-right-15 m-top-15"
                  onClick={AutomationSubmit}
                  id="autoMessageSalesTriggerAutomationSubmitBtn"
                >
                  {' '}
                  Submit
                </button>
              </div>
            </div>
          }
        />
      ) : null}
    </>
  );
};
const mapStateToProps = state => ({
  salesTriggers: state.salesTriggers,
  ...state.auth,
});
export default connect(mapStateToProps, {})(AutoMessTriggerSettings);
