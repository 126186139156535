/**
 * Sort an array of objects, by a key
 * @param {array} data - array of objects to be sorted
 * @param {string} key - object.key to use for sorting
 * @param {boolean} isAscending
 * @param {function} [opt.getValue] - optional function for getting value to compare
 * @param {boolean} [opt.isEmptyLast=true] - option to always sort empty values last
 * @returns {array} sorted list
 */
export const sortByKey = (data, key, isAscending, opt = {}) => {
  const defaultGetValue = ({ value }) => value;
  const { getValue = defaultGetValue, isEmptyLast = true } = opt;
  const EMPTY_VALUES = ['', undefined, null];

  return data?.sort((a, b) => {
    const aValue = getValue({ key, value: a[key] });
    const bValue = getValue({ key, value: b[key] });

    // optionally move all empty values to last
    if (isEmptyLast && EMPTY_VALUES.includes(aValue)) {
      return 1;
    }

    if (isEmptyLast && EMPTY_VALUES.includes(bValue)) {
      return -1;
    }

    // sort by value
    if (aValue < bValue) {
      return isAscending ? -1 : 1;
    }

    if (aValue > bValue) {
      return isAscending ? 1 : -1;
    }

    return 0;
  });
};

/**
 * Get navigation (or menu options) from a setup/config & role
 */
export const getNav = ({ ASSOCIATE, MANAGER, ADMIN }, role) => {
  const associateList = ASSOCIATE || [];
  const managerList = ['MANAGER', 'ADMIN'].includes(role) ? MANAGER || [] : [];
  const adminList = ['ADMIN'].includes(role) ? ADMIN || [] : [];
  const list = [...associateList, ...managerList, ...adminList];
  const sortedList = list.sort((a, b) => {
    if (a.order && b.order === undefined) {
      return -1;
    }
    if (a.order === undefined && b.order) {
      return 1;
    }
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });

  return sortedList;
};
