import React, { useState, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import moment from 'moment';
import { getRequest } from '../../../../core/apiRequests';
import { notificationError } from '../../../../core/actions';
import { setLaunchFlagsForNav } from '../../../../reducers/auth';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import ChatListBar from './children/ChatListBar';
import InfoDrawer from '../../../Layout/InfoDrawer';
import ConversationWindow from '../../../Layout/chat/chat';
import AssignChatPopup from './children/ChatListBar/Helpers/AssignChatPopup';
import {
  setClientAssociate,
  setChatReviewLink,
  setMsgResetClient,
  getClientChat,
  resetMessaging,
} from '../../../../reducers/messaging';
import {
  setClientId,
  setName,
  setClientAssig,
  setClientTags,
  setMergeClientDetailsFromAPI,
} from '../../../../reducers/client';
import NewMessage from './children/NewMessage';
import '../../../Layout/chat/chatInbox.css';
import getClientPaymentStatus from '../../../Layout/GetClientPaymentStatus';
import { logRocketEvent } from '../../../../utils/logRocketIdentify';
import { resetToday } from '../../../../reducers/today';

const ChatInbox = props => {
  const [newMessage, setNewMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  // @HERE
  const [toggleDrawer, setDrawerToggle] = useState(false);
  const [chatIconColor, setChatIconColor] = useState('#EBEBEB');
  const timeOffset = moment(new Date()).utcOffset();
  const [assignPopup, setAssignPopup] = useState(false);
  const [tempAssigned, setTempAssigned] = useState(false);
  const [tempAssocs, setTempAssocs] = useState([]);
  const [chatMoreRefresh, setChatMoreRefresh] = useState(false);
  const panels = ['7', '1', '2', '3', '4', '5', '6', '8'];
  const flags = useFlags();

  useEffect(() => {
    if(!props?.messaging?.client?.clickOnSocket) {
      resetMessaging();
      resetToday();
    }
  }, []);

  useEffect(() => {
    logRocketEvent('Inbox');
  }, []);

  const getReviewLink = async storeId => {
    if (storeId) {
      const reqObj = {
        params: ['chats', storeId, 'getReviewLink'],
        loadingStatusOff: true,
      };
      const linkData = await getRequest(reqObj);
      if (linkData.length > 0) {
        const { review_link } = linkData[0];
        setChatReviewLink(review_link);
      }
    } else {
      console.error('Unable to getReviewLink, NO store ID!');
    }
  };

  useEffect(() => {
    if (props.auth.selectedStore) {
      getReviewLink(props.auth.selectedStore);
    }
  }, [props.auth.selectedStore]);

  useEffect(() => {
    setLaunchFlagsForNav(flags);
  }, [flags?.projectEdison]);

  const getClientData = async cli => {
    const {
      auth: { merchantId },
    } = props;
    if (merchantId) {
      await (async () => {
        const reqObj = {
          params: ['clients', 'details', merchantId, cli.client_id],
          query: { limit: 5 },
          loadingStatusOff: true,
        };
        const data = await getRequest(reqObj);
        if (data.id) {
          const { id, name, associate_ref, count, associates, associateIds, relationships, allRelationships } = data;
          const mixedRelationshipObj = {};
          allRelationships?.forEach(rel => {
            mixedRelationshipObj[rel.id] = {
              originalId: null,
              client_id: null,
              client_name: null,
              related_client_name: null,
              relationship_type_id: rel.id,
              related_client_id: null,
              relationship: rel.name,
              relatedTo: false,
            };
            relationships.forEach(clientRel => {
              if (rel.id === clientRel.relationship_type_id) {
                mixedRelationshipObj[rel.id] = {
                  originalId: clientRel.id,
                  client_id: clientRel.client_id,
                  client_name: clientRel.client_name,
                  related_client_name: clientRel.related_client_name,
                  relationship_type_id: clientRel.relationship_type_id,
                  related_client_id: clientRel.related_client_id,
                  relationship: clientRel.relationship,
                  relatedTo: id == clientRel.related_client_id,
                };
              }
              if (!clientRel.relationship_type_id) {
                mixedRelationshipObj[clientRel.id] = {
                  originalId: clientRel.id,
                  client_id: clientRel.client_id,
                  client_name: clientRel.client_name,
                  related_client_name: clientRel.related_client_name,
                  relationship_type_id: clientRel.relationship_type_id,
                  related_client_id: clientRel.related_client_id,
                  relationship: clientRel.relationship,
                  relatedTo: id == clientRel.related_client_id,
                  uniqueRel: true,
                };
              }
            });
          });

          setMergeClientDetailsFromAPI({ ...data, mixedRelationships: mixedRelationshipObj });
          setClientId(id);
          setName(name);
          setClientAssig(associate_ref);
          setClientTags(count);
          setTempAssocs(associates);
          if (associateIds.includes(props.auth.userId)) {
            setTempAssigned(true);
          }
        } else {
          notificationError('Error getting client details');
        }
      })();
    }
  };
  const setSelectedClient = async cli => {
    if (cli.chat_id === props.messaging.client.chat_id) {
      return;
    }
    setLoading(true);
    setNewMessage(false);
    getClientChat(cli);
    getClientPaymentStatus({ client: cli, auth: props.auth });
    setClientAssociate(cli.associateCount);
    setDrawerToggle(false);
    if (cli.client_id) {
      getClientData(cli);
    }
  };

  const setNewMsgHandler = () => {
    setMsgResetClient();
    setNewMessage(true);
  };

  const parentGridClassName = () => {
    let theClassName = 'three-column-parent-grid  rem-pad-width ';

    if (!props.messaging.client.name && newMessage) {
      theClassName += 'three-column-parent-2';
    } else {
      theClassName += 'three-column-parent-3';
    }
    return theClassName;
  };

  const selectArray = () => {
    const dataArr = props?.messaging?.allAsctsList.map(asct => ({
      label: `${asct.first_name || ''} ${asct.last_name || ''}`,
      value: asct.id,
    }));
    dataArr.unshift({ text: 'All Associates', value: '' });
    return dataArr;
  };

  return (
    <div
      id="ChatInboxId"
      className={`rem-pad-width h-100-P p-left-16`}
      style={{ overflow: 'hidden', maxHeight: '100%', scroll: 'none', width: '100%' }}
    >
      <div className={parentGridClassName()}>
        <ChatListBar
          setNewMessage={setNewMsgHandler}
          setSelectedClient={setSelectedClient}
          setChatIconColor={setChatIconColor}
          paymentBanner={props.paymentBanner.active}
          chatMoreRefresh={chatMoreRefresh}
          setChatMoreRefresh={setChatMoreRefresh}
        />
        {!!props?.messaging?.client?.chat_id && (
          <>
            <div className="three-column-child-3 h-100-P" style={{ minWidth: '500px', width: '100%' }}>
              <ConversationWindow
                setDrawerToggle={() => setDrawerToggle(!toggleDrawer)}
                chatIconColor={chatIconColor}
                paymentBanner={props.paymentBanner.active}
                setAssignPopup={setAssignPopup}
                type="full"
                chatMoreRefresh={chatMoreRefresh}
                setChatMoreRefresh={setChatMoreRefresh}
                loading={loading}
              />
            </div>
            {assignPopup ? (
              <AssignChatPopup
                associateList={props.messaging.allAsctsList}
                setAssignPopup={setAssignPopup}
                asctFilterList={selectArray()}
                setChatMoreRefresh={() => {}}
                hoverAssignId={props?.messaging?.client?.chat_id}
                followers={props?.messaging?.client?.followers}
                staticAssoc={props.messaging.client?.assignedAssociates}
                source="chatInbox"
              />
            ) : null}
            {toggleDrawer ? (
              <InfoDrawer chatIconColor={chatIconColor} panels={panels} client={props.client} location="inbox" />
            ) : null}
          </>
        )}
        {newMessage ? (
          <NewMessage
            associateStores={props.auth.stores}
            setNewMessage={setNewMessage}
            setSelectedClient={setSelectedClient}
            selectedStore={props.auth.selectedStore}
            flags={flags}
          />
        ) : null}
        <div style={{ width: '1px' }} />
      </div>
    </div>
  );
};
const mapStateToProps = state => state;

export default connect(mapStateToProps, {})(ChatInbox);
