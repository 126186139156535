/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'antd-v5';
import { InfoCircleOutlined } from '@ant-design/icons';
import KioskSlider from '../../../../../Layout/KioskSlider';
import CustomModal from '../../../../../CustomizedAntDesign/Modal/Modal';
import { postRequest } from '../../../../../../core/apiRequests';
import './styles.css';

const IntegrationsCard = props => {
  const { id, title, name, image, hasSettings, auth, pos_type, type, enabled, pageSettings } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const installed = useMemo(() => {
    if (id === 'facebook') {
      return auth?.meta_integration;
    }

    if (id === 'google') {
      return auth?.google_integration;
    }
    if(id === 'instagram') {
      return auth?.instagram_integration;
    }

    return pos_type && pos_type.toLocaleLowerCase() === id;
  }, [id, pos_type]);
  const [active, setActive] = useState(enabled);
  const [modalVisible, setModalVisible] = useState(false);

  const link = useMemo(() => {
    if (installed && hasSettings) {
      return `/help/integrations/${id}/settings`;
    }

    return `/help/integrations/${id}`;
  }, [id, hasSettings, installed]);

  const toggleIntegration = useCallback(
    async value => {
      setLoading(true);

      try {
        const reqObj = {
          params: ['vendors', 'toggleVendorIntegration'],
          data: {
            merchantId: auth.merchantId,
            vendorId: id,
            enabled: value,
          },
        };
        const response = await postRequest(reqObj);
        setActive(response.enabled);
        setShowDeleteModal(false);
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    },
    [auth.merchantId, id],
  );

  const handleCancel = useCallback(() => {
    if (!loading) {
      setShowDeleteModal(false);
    }
  }, [loading]);

  const confirmDisable = useCallback(
    (value, event) => {
      event.stopPropagation();

      if (value) {
        toggleIntegration(value);
        return;
      }

      setShowDeleteModal(true);
    },
    [toggleIntegration],
  );

  return (
    <>
      {type === 'vendor' ? (
        <>
          <div className="cb-integrations-card" onClick={() => setModalVisible(true)}>
            <div className="cb-integrations-card__header">
              <div className={`cb-integrations-card__image ${!title && 'cb-integrations-card__image-lg'}`}>
                <img src={image} alt={title} />
              </div>

              {title && <span className="cb-integrations-card__title">{title}</span>}
            </div>

            <div className="flex-row-spacebetween-wrap w-100-P">
              <span>Learn More</span>
              <KioskSlider value={active} onChange={confirmDisable} />
            </div>
          </div>
          <CustomModal
            width={800}
            centered
            open={modalVisible}
            footer={null}
            title={null}
            onCancel={() => setModalVisible(false)}
            closeIcon={
              <FontAwesomeIcon
                icon={['far', 'xmark']}
                size="2xl"
                style={{ color: '#000', paddingTop: 30, paddingRight: 40 }}
                onClick={() => setModalVisible(true)}
              />
            }
          >
            <img className="h-50 p-btm-20" src={image} alt={title} />
            <h2>Overview</h2>
            <p>{pageSettings?.overview}</p>
            <h2>Key Features: </h2>
            <p>by enabling this integration you can:</p>
            <ul>
              {pageSettings?.keyFeatures?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </CustomModal>
        </>
      ) : (
        <Link to={link}>
          <div className="cb-integrations-card">
            <div className="cb-integrations-card__header">
              <div className={`cb-integrations-card__image ${!title && 'cb-integrations-card__image-lg'}`}>
                <img src={image} alt={title} />
              </div>

              {title && <span className="cb-integrations-card__title">{title}</span>}
            </div>

            <div className="cb-integrations-card__footer">
              <span>Click here to get started</span>
              <FontAwesomeIcon icon={['far', 'chevron-right']} />
            </div>
          </div>
        </Link>
      )}

      <Modal
        type="warning"
        open={showDeleteModal}
        onCancel={handleCancel}
        title={
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <InfoCircleOutlined style={{ fontSize: '22px', color: '#faad14' }} /> {`Disable ${name}`}
          </div>
        }
        okText={`Disable ${name}`}
        okButtonProps={{ loading, disabled: loading }}
        onOk={() => toggleIntegration(!active)}
        cancelButtonProps={{ disabled: loading }}
        width={400}
        closable
      >
        Turning this integration off will hide the <strong>{name}</strong> catalog and delete <strong>{name}</strong>{' '}
        items from collections.
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  pos_type: state.auth.pos_type,
  auth: state.auth,
});

export default connect(mapStateToProps, {})(IntegrationsCard);
