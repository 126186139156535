import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Button, Card, Table } from 'antd-v5';
import { DownloadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import StandardLayoutV2 from '../StandardLayoutV2';
import ReportStatics from '../../CustomizedAntDesign/ReportStatisc';
import ReportSelectDate from '../../CustomizedAntDesign/ReportSelectDate';
import ReportFilter from '../../CustomizedAntDesign/ReportFilter';
import ReportTagsFilters from '../../CustomizedAntDesign/ReportTagsFilters';
import { getRequest } from '../../../core/apiRequests';
import ReportChartBar from '../../Chart/ReportChartBar';
import { useQuery } from 'react-query';

const Report = ({
  title,
  stats,
  hasCSVExport,
  columns,
  table,
  CSVcolumns,
  CSVtable,
  CSVFilename,
  onDateRangeChange = () => {},
  options = [],
  filters = {},
  handleFilterChange = () => {},
  removeFilter = () => {},
  hasStoreFilter = true,
  hasAssociateFilter = true,
  auth,
  tablesProps = {},
  extraButtons = [],
  graphData,
  stacked = false,
  dataRange = [],
  defaultPeriodValue = '90days',
  overdueDate,
  typeDateOptions = 'past-options',
  customOptions = [],
  maxTicksLimit,
  paymentBannerActive,
  hasBanner = false,
  textBanner = '',
  labelLines = 1,
  maxStatsPerLine,
  getPeriodLabel,
  getAssociatesList = () => []
}) => {
  const history = useHistory();

  useEffect(() => {
    if (auth?.launchFlagsForNav?.reportsPage !== undefined) {
      if (!auth?.launchFlagsForNav?.reportsPage) {
        history.push('/Home');
      }
    }
  }, [auth?.launchFlagsForNav?.reportsPage]);


  const handleDateRangeChange = useCallback(value => {
    onDateRangeChange(value);
  }, []);


  const { data: associates } = useQuery('associates-list', async () => {
      const reqObj = {
        params: ['associates'],
        loadingStatusOff: true,
      };
      const response = await getRequest(reqObj)
      getAssociatesList(response)
      return response;
  }, {
    enabled: !!auth.merchantId && !!hasAssociateFilter,
    refetchOnWindowFocus: false,
    initialData: []
  });

  const filterOptions = useMemo(() => {
    const allStores = auth?.stores?.filter(store => store.id !== 0) || [];
    const stores =
      hasStoreFilter && allStores?.length > 1
        ? [
            {
              title: 'Store',
              key: `0-${options.length + 1}`,
              value: 'store',
              children: allStores.map(store => ({
                title: store.name,
                value: store.id ? `${store.id}` : '',
                key: `0-${options.length + 1}-${store.id}`,
              })),
            },
          ]
        : [];
    const associatesFilters = hasAssociateFilter
      ? [
          {
            title: 'Associate',
            key: `0-${options.length + 2}`,
            value: 'associate',
            children: associates.map(associate => ({
              title: `${associate?.first_name ? `${associate.first_name} ` : ''}${associate?.last_name || ''}`,
              value: associate.id ? `${associate.id}` : '',
              key: `0-${options.length + 2}-${associate.id}`,
            })),
          },
        ]
      : [];
    return [...options, ...stores, ...associatesFilters];
  }, [auth.stores, associates, options]);

  const maxStatsWidth = useMemo(() => {
    const statsDiv = document.getElementById('report-stats');
    if (statsDiv && maxStatsPerLine) {
      return `${(statsDiv.offsetWidth - (maxStatsPerLine - 1) * 16) / maxStatsPerLine}px`;
    }
    return '100%';
  }, [maxStatsPerLine, stats, document.getElementById('report-stats')]);

  return (
    <StandardLayoutV2
      title={title}
      breadcrumb={[
        {
          path: '/Reports',
          title: 'Reports',
        },
        {
          title,
        },
      ]}
      classProps="flex-col-left "
      styleProps={{ justifyContent: 'flex-start' }}
      headerStyles={{
        marginTop: hasBanner ? (auth?.launchFlagsForNav?.newWebNavigation ? '65px' : '45px') : '0',
      }}
    >
      {hasBanner && (
        <div
          style={{
            position: 'absolute',
            top: '48px',
            left: '0',
            width: '100%',
            height: '65px',
            background: '#FFFBE6',
            zIndex: '2',
            display: 'flex',
            alignItems: 'center',
            padding: '0 44px',
            fontWeight: 500,
          }}
        >
          {textBanner}
        </div>
      )}
      <div className="flex-row-spacebetween-nowrap w-100-P  m-top-10">
        <div className="flex-row-center" style={{ gap: '16px' }}>
          <ReportSelectDate
            onChange={value => handleDateRangeChange(value)}
            defaultValue={defaultPeriodValue}
            type={typeDateOptions}
            customOptions={customOptions}
            overdueDate={overdueDate}
            getPeriodLabel={getPeriodLabel}
          />
          <ReportFilter options={filterOptions} handleFilterChange={handleFilterChange} filters={filters} />
        </div>

        <div className="flex-row-center" style={{ gap: '16px' }}>
          {extraButtons?.length > 0 &&
            extraButtons?.map(button => (
              <Button onClick={button?.onClick} icon={button?.icon || null} size="middle">
                {button?.label}
              </Button>
            ))}
          {hasCSVExport && (CSVtable?.length > 0 || table?.length > 0) && (
            <CSVLink
              data={CSVtable || table || 'No data'}
              headers={
                CSVcolumns || columns.map(column => ({ label: column.title, key: column.key_export || column.key }))
              }
              filename={CSVFilename || 'report.csv'}
            >
              <Button>
                <DownloadOutlined />
                Export
              </Button>
            </CSVLink>
          )}
        </div>
      </div>
      <ReportTagsFilters options={filterOptions} filterSelected={filters} removeFilter={removeFilter} />
      <div
        className="flex-row-spacebetween-center w-100-P m-top-16"
        style={{ gap: '16px', flexWrap: maxStatsPerLine ? 'wrap' : 'nowrap' }}
        id="report-stats"
      >
        {stats.filter(stat => !stat?.hidden).map(({ title, value, prefix, precision, description, onClick, ...rest }) => (
          <ReportStatics
            key={title}
            title={title}
            value={value}
            prefix={prefix}
            precision={precision}
            description={description}
            onClick={onClick}
            maxStatsWidth={maxStatsWidth}
            {...rest}
          />
        ))}
      </div>
      {graphData && (
        <ReportChartBar
          graphData={graphData}
          timeSpan={{ startDate: dataRange[0], endDate: dataRange[1] }}
          stacked={stacked}
          maxTicksLimit={maxTicksLimit}
          labelLines={labelLines}
        />
      )}
      {table && (
        <Card style={{ marginTop: '25px' }}>
          <Table columns={columns} dataSource={table} {...tablesProps} />
        </Card>
      )}
    </StandardLayoutV2>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  paymentBannerActive: state.paymentBanner.active,
});

export default connect(mapStateToProps)(Report);
