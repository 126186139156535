import React, { useState } from 'react';
import './satisfaction.css';
import moment from 'moment';
import Axios from 'axios';
import { connect } from 'react-redux';
import { setSurveyStatus } from '../../../reducers/notification';
import { setAuthSurvey } from '../../../reducers/auth';
import { FEEDBACK_API_URL } from '../../../constants';

const SatisfactionSurvey = props => {
  const [firstSlide, setFirstSlide] = useState(true);

  const clickHandler = (event, surveyResp, snooze) => {
    if (
      event.target.id === 'survey' ||
      event.target.id === 'thankYou' ||
      event.target.id === 'happy' ||
      event.target.id === 'ok' ||
      event.target.id === 'sad'
    ) {
      setFirstSlide(false);
      setTimeout(() => {
        setSurveyStatus(false);
        // POST HERE
        setTimeout(() => {
          sendThatRequest(surveyResp, snooze);
        });
      }, 1000);
    } else if (event.target.id === 'outerDiv') {
      setSurveyStatus(false);
      sendThatRequest(surveyResp, snooze);
      //   setSnooze(true)
    }
  };

  const sendThatRequest = (surveyResp, snooze) => {
    setSurveyStatus(false);
    setFirstSlide(true);
    const today = moment(new Date()).format('YYYYMMDDHHmmss');
    const url = FEEDBACK_API_URL;

    const instance = Axios.create({
      transformRequest: (data, headers) => {
        delete headers.common;
        delete headers.atx;

        if (headers.post) {
          delete headers.post.atx;
        }
        if (headers.get) {
          delete headers.get.atx;
        }
      
        return data;
      },
    });

    instance
      .post(
        url,
        JSON.stringify({
          reaction: surveyResp,
          location: window.location.href,
          device: 'WEB',
          snooze: snooze || false,
          msg: props.survey.msg,
        }),
        {
          headers: {
            'x-clientbook-user-id': props.userId,
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_FEEDBACK_API_KEY,
          },
        },
      )
      .then(response => {
        setAuthSurvey({
          lastSnoozed: today,
          lastAnswered: today,
        });
      })
      .catch(err => {
        console.log(`[SATISFACTION SURVEY] Axios error using ${JSON.stringify(err)}`);
        setAuthSurvey({
          lastAnswered: today,
          lastSnoozed: today,
        });
      });
  };
  if (props.notification.showSurvey) {
    return (
      <div className="pop-confirm-background" onClick={e => clickHandler(e, null, true)} id="outerDiv">
        {firstSlide ? (
          <div className="bg-white w-402 h-263 satisfaction_container pos-rel" id="survey">
            <p className="satisfactionText pos-abs">{props.survey.msg}</p>
            <div className="smileyDiv pos-abs">
              <button
                className="m-right-20 border-none bg-white"
                onClick={e => {
                  clickHandler(e, -1);
                  e.stopPropagation();
                }}
              >
                <div className="sadFace face" id="sad" />
              </button>
              <button
                className="m-right-20 border-none bg-white"
                onClick={e => {
                  clickHandler(e, 0);
                  e.stopPropagation();
                }}
              >
                <div className="mehFace face" id="ok" />
              </button>
              <button
                className="m-right-20 border-none bg-white"
                onClick={e => {
                  clickHandler(e, 1);
                  e.stopPropagation();
                }}
              >
                <div className="happyFace face" id="happy" />
              </button>
            </div>
          </div>
        ) : (
          <div className="bg-white w-402 h-263 satisfaction_container pos-rel" id="thankYou">
            <p className="satisfactionText pos-abs">Thank you for your feedback!</p>
            <div className="greenDiv pos-abs">
              <img src="https://dashboard-v2-images.s3.amazonaws.com/greenCheck.png" />
            </div>
          </div>
        )}
      </div>
    );
  }
  return null;
};

const mapStateToProps = state => ({ notification: state.notification, ...state.auth });
export default connect(mapStateToProps, {})(SatisfactionSurvey);
