import React, { useCallback, useMemo } from 'react';
import { Form, Modal, message } from 'antd-v5';
import SaveProductForm from '../SaveProductForm';
import useProduct from '../hooks/useProduct';

const SaveProductModal = props => {
  const { open, setOpen, productId, productData, onSuccess, onError, ...rest } = props;

  const { loading, product, saveProduct } = useProduct({ productId, productData });

  const [form] = Form.useForm();

  const title = useMemo(() => {
    return productId ? 'Edit Product' : 'Add Product';
  }, [productId]);

  const handleFinish = useCallback(async fields => {
    try {
      const response = await saveProduct(fields);
      console.log('response', response);

      const successMessage = productId ? 'Product updated' : 'Product created';

      message.success(successMessage);

      if (onSuccess) {
        onSuccess(response);
      }

      setOpen(false);
    } catch (error) {
      const errorMessage = productId ? 'Error updating product' : 'Error creating product';

      message.error(errorMessage);

      if (onError) {
        onError(error);
      }
    }
  }, [onSuccess]);

  return (
    <Modal
      {...rest}
      open={open}
      title={title}
      okText="Save"
      onOk={() => form.submit()}
      onCancel={() => setOpen(false)}
      okButtonProps={{ loading }}
      cancelButtonProps={{ disabled: loading }}
      destroyOnClose
    >
      <SaveProductForm form={form} product={product} disabled={loading} onFinish={handleFinish} />
    </Modal>
  );
};

export default SaveProductModal;
