import moment from 'moment';
import { statusFilter } from './tableHelpers';

export const filterList = ({ paymentRequests = [], filters, startDate, endDate, dateName = 'sent', assocStores }) => {
  const allStores = assocStores.filter(store => store.id !== 0).map(item => item.id);
  return paymentRequests.filter(paymentRequest => {
    const filterStatus =
      filters?.statuses.length === 0 || filters?.statuses.includes(statusFilter(paymentRequest.status.toString()));
    const filterStore =
      filters.store.length === 0
        ? allStores.includes(paymentRequest?.storeId)
        : filters.store.includes(paymentRequest?.storeId?.toString());
    const filterAssociate =
      filters?.associate.length === 0 || filters?.associate.includes(paymentRequest.sentById.toString());
    const filterBankAccount =
      filters?.bankAccount.length === 0 || filters?.bankAccount.includes(paymentRequest.accountId.toString());
    const filterTimeSpan = moment(paymentRequest[dateName]).isBetween(moment(startDate), moment(endDate));
    return filterStatus && filterStore && filterAssociate && filterBankAccount && filterTimeSpan;
  });
};
