import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  getTablePaginationData,
  setTablePaginationPageNum,
  notificationSuccess,
  resetTablePagination,
  setTablePaginationPageCount,
  setTablePaginationRowCount,
} from '../../../core/actions';
import { getRequest, postRequest } from '../../../core/apiRequests';
import {
  setAllowManagerFeatures,
  setAssocAddEdit,
  setAssociateContactGoal,
  setAssociateId,
  setAssociateImage,
  setAssociateObject,
  setAssociatePin,
  setAssociateRole,
  setAssociateStore,
  setAssociateStoreId,
  setEmailAddress,
  setFirstName,
  setLastName,
  setMobileNum,
  setPOS,
  setSendInvite,
} from '../../../reducers/associate';
import { setAuthStoreId } from '../../../reducers/auth';
import FilterChips from '../../Layout/FilterChips';
import FilterSetter from '../../Layout/FilterSetter';
import GlobalHeader from '../../Layout/GlobalHeader';
import PopConfirm from '../../Layout/PopConfirm';
import SecondaryPanel from '../../Layout/SecondaryPanel';
import Layout from '../../Layout/StandardLayout';
import Table from '../../Layout/Table';
import './associates.css';

const tableName = 'ASSOCIATES';

const AssociateReportSection = ({ withDivider }) => (
  <div className="assoc_bottom">
    <div className="assoc_reports">
      {/* <img src={associates} alt="#"/> */}
      <span className="fs-16 fw-600 ">Associate Reports</span>
    </div>
    <div className="assoc_report_div">
      <span className="assoc_report_heading">
        <Link className="blue-link fs-13 no-decoration" to="/Reports/associate-messages">
          Associate Messages
        </Link>
      </span>
      <span className="fs-10 gray">Count of messages sent by associate grouped by time period.</span>
    </div>
    <div className="assoc_report_div">
      <span className="assoc_report_heading">
        <Link className="blue-link fs-13 no-decoration" to="/Reports/client-engagement">
          Client Engagement / Leaderboard
        </Link>
      </span>
      <span className="fs-10 gray">
        Count of client engagement activity performed by an associate, grouped by time period.
      </span>
    </div>
    <div className="assoc_report_div">
      <span className="assoc_report_heading">
        <Link className="blue-link fs-13 no-decoration" to="/Reports/by-associate">
          Client Tags by Associate
        </Link>
      </span>
      <span className="fs-10 gray">Current count of clients per tag for associates.</span>
    </div>
    <div className="assoc_report_div">
      <span className="assoc_report_heading">
        <Link className="blue-link fs-13 no-decoration" to="/Reports/associate-activity">
          Associate Activity
        </Link>
      </span>
      <span className="fs-10 gray">Total associate engagement activity for a given time period.</span>
    </div>
    {withDivider ? <div className="divider" /> : ''}
  </div>
);

const AssociateTableSection = ({ rowData, onRowClick }) => {
  const tableHeaders = [
    {
      keyName: 'image',
      style: { width: '1%', paddingBottom: '5px' }, // we can apply all styles to the header now.
      headerClassName: 'm-right-15', // This targets the header's className
      render: (text, obj) => {
        if (text) {
          return (
            <div className="Associate_image rem-pad-width">
              <img
                src={text}
                style={{
                  width: '26px',
                  height: '26px',
                  borderRadius: '50%',
                  marginLeft: '20px',
                  marginRight: '10px',
                }}
                alt="#"
              />
            </div>
          );
        }
        const full = `${obj.first_name || ''} ${obj.last_name || ''}`;
        const initials = `${full}`
          .split(' ')
          .map(word => word.substr(0, 1))
          .join(''); // initials icon
        return (
          <div
            style={{
              marginLeft: '20px',
              objectFit: 'cover',
              paddingTop: '4px',
              marginRight: '10px',
            }}
            className=" m-right-15 h-26 w-26 bg-lightgray circle rem-pad-width"
          >
            <span
              style={{
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '.5px',
              }}
              className="white"
            >
              {initials}
            </span>
          </div>
        );
      },
      // text is the value of the key being passed in, object (obj) is the ENTIRE OBJECT
      // render allows us to put something else in the box other than the default string
      title: '',
      rowStyle: { paddingBottom: '4px', paddingTop: '4px' },
      rowClassName: 'associate-row-image',
      isImg: 'image',
    },
    {
      keyName: 'first_name',
      style: { width: '15%', minWidth: '150px' }, // we can apply all styles to the header now.
      headerClassName: '', // This targets the header's className
      title: 'Name',
      sortable: true,
      render: (t, o) => `${o.first_name || ''} ${o.last_name || ''}`,
      rowClassName: 'blue-link',
    },
    {
      keyName: 'location',
      style: { width: '10%', paddingRight: '30px', minWidth: '100px' },
      headerClassName: 'row3',
      title: 'Store',
      sortable: true, // sort allows gives us the ability to sort the table
    },
    {
      keyName: 'mobile',
      style: { width: '5%', paddingRight: '35px', minWidth: '145px' },
      headerClassName: 'row4',
      title: 'Phone Number',
      render: t => formatPhoneNumber(t),
      sortable: true,
    },
    {
      keyName: 'count',
      rowClassname: 'center',
      style: { width: '5%', paddingRight: '10px', minWidth: '160px' },
      headerClassName: 'row6 center',
      title: 'Assigned Clients',
      sortable: true,
    },
  ];

  return (
    <Table
      tableClassName="lg-table-sidebar  rem-pad-width"
      data={rowData}
      tableHeaders={tableHeaders}
      searchable={false}
      paginationV2
      onRowClick={onRowClick}
      tableName={tableName}
    />
  );
};

const Associates = props => {
  const [tableData, setTableData] = useState([]);
  const [first, setFirst] = useState(true);
  const [associateNum, setAssociateNum] = useState('');
  const history = useHistory();
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  const getUpdatedAssociates = () => {
    const { page, pageSize, sortColumn, ascendingColumn } = props.pagination;
    const { merchantId, storeId, stores } = props.auth;
    let selectedStores = `${storeId}` ? [storeId] : [stores.map(st => st.id)];
    if (Number(selectedStores[0]) === 0) {
      selectedStores = [];
      stores.forEach(st => {
        if (Number(st.id) !== 0) {
          selectedStores.push(st.id);
        }
      });
    }

    if (selectedStores.length > 0 && sortColumn !== '') {
      (async () => {
        const reqObj = {
          params: ['associates', merchantId, 'paginatedList'],
          query: {
            page,
            count: pageSize,
            sortColumn,
            ascending: ascendingColumn,
            searchText,
            selectedStores: JSON.stringify(selectedStores),
          },
        };
        const response = await getRequest(reqObj);

        if (response && Array.isArray(response.results)) {
          const {
            results,
            pageInfo: { rowCount, pageCount },
          } = response;

          setTablePaginationRowCount(rowCount, tableName);
          setTablePaginationPageCount(pageCount, tableName);
          setAssociateNum(rowCount);
          const filterOutDeletes = results.filter(asct => {
            if (props.associate.deletedAssociates.includes(asct.id)) {
              return false;
            }
            return true;
          });
          setTableData(filterOutDeletes);
        }
      })();
    }
  };

  useEffect(() => {
    if (props.auth.merchantId) {
      if (first && props.pagination.sortColumn !== 'first_name') {
        resetTablePagination(
          {
            sortColumn: 'first_name',
          },
          tableName,
        );
        setFirst(false);
        setAuthStoreId(0);
      } else if (props.pagination.page === 0) {
        setTablePaginationPageNum(1, tableName);
        setFirst(false);
      } else {
        if (first) {
          setAuthStoreId(0);
          setFirst(false);
        }
        getUpdatedAssociates();
      }
    }
  }, [
    props.auth.stores,
    props.pagination.sortColumn,
    props.pagination.pageSize,
    props.pagination.page,
    props.pagination.ascendingColumn,
    props.auth.storeId,
    first,
  ]);

  const rowClickHandler = data => {
    const {
      first_name,
      last_name,
      mobile,
      id,
      email,
      sendInvit,
      manager,
      location,
      image,
      pin,
      store_id,
      daily_contact_goal,
      role,
      count,
    } = data;
    setFirstName(first_name);
    setLastName(last_name);
    setMobileNum(mobile);
    setAssociateId(id);
    setEmailAddress(email);
    setSendInvite(sendInvit);
    setAllowManagerFeatures(manager);
    setAssociateStore(location);
    setAssociateStoreId(store_id);
    setAssociateImage(image);
    setAssociatePin(pin);
    setAssociateContactGoal(daily_contact_goal);
    setAssociateRole(role);
    setAssociateObject({
      associateAssignedCount: count,
    });
    history.push(`Associates/associate-details/${id}`);
  };

  const clearPref = () => {
    if (props.auth.license_count === null || props.auth.license_count > Number(associateNum)) {
      history.push('Associates/add-associate');
    } else {
      setShowPopConfirm(true);
    }
    // This is new tags
    setAssocAddEdit('Add'); //  Set the Add / Edit page to "Edit" mode.
    setFirstName('');
    setLastName('');
    setMobileNum('');
    setAssociateId('');
    setEmailAddress('');
    setAssociateStoreId('');
    setPOS('');
    //  set list type is required later for requests (props.type)
  };

  const sendAssociateEmail = () => {
    const { merchantId, licenseKey } = props.auth;
    (async () => {
      const reqObj = {
        params: ['associates', merchantId, licenseKey, 'licenseSeatUpgrade'],
      };
      const dataArr = await postRequest(reqObj);
      if (dataArr) {
        notificationSuccess('email sent to billing@clientbook.com');
        setShowPopConfirm(false);
        history.push('Associates/add-associate');
      }
    })();
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const assocAdditionalButton = () => {
    return (
      <div
        id="add-client-button"
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onClick={clearPref}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          cursor: 'pointer',
          color: '#1769AF',
          fontWeight: 600,
          fontSize: 13,
          paddingBottom: '15px',
        }}
      >
        <FontAwesomeIcon className="m-right-8 fs-13" icon={['far', 'user-plus']} />
        Add Associate
      </div>
    );
  };

  const saveFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <Layout
      title="Associates"
      description="Add associates and review key statistics"
      classProps="standard-width-with-sidebar"
    >
      {/* overlay */}
      <div
        onClick={() => setShowFilters(false)}
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDown}
        aria-label="Filters"
        className={showFilters ? 'pos-abs w-100-vw h-100-vh left-0 align-top  nav-z-100' : 'pos-rel '}
      />
      <div className="w-100-P h-100-P">
        <GlobalHeader
          setShowFilters={setShowFilters}
          saveFilters={saveFilters}
          showFilters={showFilters}
          additionalButtonsClass="w-100-P"
          additionalButtonsTop={assocAdditionalButton}
          search={[
            <input
              key={props.auth.userId}
              onChange={e => setSearchText(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  getUpdatedAssociates();
                }
                return false;
              }}
              placeholder="Search..."
              id="AssociateSearchBar"
              className="h-50 w-100-P m-btm-6 body-head-search rem-pad-width focus-input-outline-blue"
              style={{ width: '100%', height: '50px' }}
            />,
          ]}
          filter={<FilterSetter setShowFilters={setShowFilters} showFilters={showFilters} saveFilters={saveFilters} />}
        />

        {/* CHOSEN FILTER SECTION */}
        <div>
          <FilterChips storeFilterList={props.auth.stores} storeId={props.auth.storeId} />
        </div>
        <AssociateTableSection rowData={tableData} onRowClick={rowClickHandler} />
      </div>
      <AssociateReportSection withDivider={props.withDivider} />
      {showPopConfirm ? (
        <PopConfirm
          confirm="Additional license seats are required"
          description="This may result in additional charges"
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={sendAssociateEmail}
        />
      ) : null}
    </Layout>
  );
};

const mapStateToProps = state => ({
  ...state,
  pagination: getTablePaginationData(state, tableName),
});

export default connect(mapStateToProps, {})(Associates);
