/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { message } from 'antd';
import axios from 'axios';
import ViewAll from '../../../ViewAll';
import PaginationV2 from '../../../../../../CustomizedAntDesign/PaginationV2';
import { API_URL } from '../../../../../../../constants';
import SalesOpportunityCard from '../components/SalesOpportunityCard';
import './styles.scss';
import useSearchParams from '../../../../../../../hooks/useSearchParams';

const AllClientSalesOpportunities = props => {
  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const clientId = useMemo(() => urlParams.get('client') || '', [urlParams]);
  const { setValue } = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [salesOpportunities, setSalesOpportunities] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);

  const showPagination = useMemo(() => total > 10, [total]);
  const salesName = useMemo(() => props?.auth?.merchantConfig.SALES_OPP_NAME || 'Sales Opportunities', [props.auth.merchantConfig]);

  const fetchSalesOpportunities = useCallback(async () => {
    setTotal(0);
    setLoading(true);

    try {
      const response = await axios.get(`${API_URL}/xa/clients/${clientId}/sales-opportunities`, {
        params: {
          page,
          pageSize,
          allReminders: true,
        },
      });

      setPage(response.data.page);
      setPageSize(response.data.pageSize);
      setTotal(response.data.total);
      setSalesOpportunities(response.data.results);
    } catch (error) {
      message.error(`Error Fetching ${salesName}`);
    } finally {
      setLoading(false);
    }
  }, [clientId, page, pageSize, salesName]);

  useEffect(() => {
    fetchSalesOpportunities();
  }, [clientId, page, pageSize]);


  return (
    <ViewAll title={`All ${salesName}`} setViewAllType={props.setViewAllType}>
      <div className="cb-all-client-sales-opportunities">
        <div className="cb-all-client-sales-opportunities--container">
          <div className="cb-all-client-sales-opportunities--items">
            {loading && <div className="cb-all-client-sales-opportunities--subtitle">Loading...</div>}

            {!loading && salesOpportunities.length === 0 && (
              <div className="cb-all-client-sales-opportunities--subtitle">{`No ${salesName}`}</div>
            )}

            {/* @TODO: SALES OPPORTUNITY MODAL */}

            {!loading &&
              salesOpportunities.length > 0 &&
              salesOpportunities.map(item => (
                <SalesOpportunityCard
                  key={item.id}
                  salesOpportunity={item}
                  onClick={() => {
                    setValue('oppId', item.id);
                    setValue('oppLocation', 'allClientSalesOpportunities');
                  }}
                />
              ))}
          </div>

          {showPagination && (
            <PaginationV2
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              total={total}
              showSizeChanger={false}
              className="cb-all-client-sales-opportunities--pagination"
            />
          )}
        </div>
      </div>
    </ViewAll>
  );
};
export default AllClientSalesOpportunities;
