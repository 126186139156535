import React, { useState, useEffect } from 'react';
import './navigation_v2.css';
import { connect } from 'react-redux';
import { Modal, Badge } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import UserProfileMenu from '../Layout/StandardLayout/UserProfileMenu';
import PaymentBanner from '../Layout/PaymentBanner';
import OtpInQr from '../Layout/optInQr/opt-in-qr';
import { getNav } from '../utils';
import { getRequest, queryMaker } from '../../core/apiRequests';
import Edison from './Edison';

export const getPaymentsNav = role =>
  getNav(
    {
      ASSOCIATE: [{ title: 'Payment Activity', link: '/Payments/activity' }],
      ADMIN: [
        { title: 'Bank Accounts', link: '/BankAccount/list' },
        { title: 'Payment Settings', link: '/Configuration/payment-settings' },
      ],
      MANAGER: [{ title: 'Bank Transactions', link: '/reports/bank-transactions' }],
    },
    role,
  );

const Navigation_V2 = props => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [current, setCurrent] = useState('dropDown-nav-item');
  const [showList, setShowList] = useState('');
  const [showQr, setShowQr] = useState(false);
  const [showMenu, setShowMenu] = useState('');

  const isSafe = () => {
    const posType = props.pos_type ? props.pos_type.trim() : null;
    const showTags = props?.merchantFlags?.tags?.enabled || false;
    let configMenu = [];
    if (posType || process.env.REACT_APP_API_ENV === 'development' || process.env.REACT_APP_API_ENV === 'localhost') {
      configMenu = [
        { title: 'Sales Triggers', link: '/Configuration/sales-triggers' },
        { title: 'Life Events', link: '/Configuration/life-events' },
        { title: 'Client Activity', link: '/Configuration/client-activity' },
        { title: 'Tags', link: '/Configuration/tags' }, // these links need to be modified
      ];
    } else {
      configMenu = [
        { title: 'Life Events', link: '/Configuration/life-events' },
        { title: 'Client Activity', link: '/Configuration/client-activity' },
        { title: 'Tags', link: '/Configuration/tags' }, // these links need to be modified
      ];
    }
    if (props.pipelineEnabled) {
      configMenu.splice(1, 0, { title: 'Sales Opportunities', link: '/Configuration/sales-pipelines' });
    }
    if (!showTags) {
      configMenu = configMenu.filter(item => item.title !== 'Tags');
    }
    return configMenu;
  };

  const messagingMenuOptions = () => {
    let menuListArr = [
      {
        title: 'Inbox',
        link: '/Messaging/inbox',
        current: 'Inbox',
      },
    ];
    if (props.role === 'ADMIN' || props.role === 'MANAGER') {
      menuListArr.push(
        {
          title: 'Mass Messaging',
          link: '/Messaging/mass-messaging',
          current: 'Mass Messaging',
        },
        {
          title: 'Templates',
          link: '/Messaging/all-templates',
          current: 'Templates',
        },
        {
          title: 'Auto Messages',
          link: '/Messaging/auto-messages',
          current: 'Auto Messages',
        },
      );
    }
    if (props.enabled_whatsapp) {
      menuListArr.push({
        title: 'WhatsApp',
        link: '/Messaging/WhatsApp',
        current: 'WhatsApp',
      });
    }

    const showMassMessagingMenu = props?.merchantFlags?.mass_messaging?.enabled || false;

    if (!showMassMessagingMenu) {
      menuListArr = menuListArr.filter(item => item.title !== 'Mass Messaging');
    }

    return menuListArr;
  };

  const manageMenuOptions = () => {
    const menuListArr = [
      { title: 'Clients', link: '/Clients' },
      { title: 'Products', link: '/Products' },
    ];

    if (props.role == 'ADMIN' || props.role == 'MANAGER') {
      menuListArr.push({ title: 'Associates', link: '/Associates' }, { title: 'Stores', link: '/Stores' });
    }
    return menuListArr;
  };

  return (
    <div className="w-100-vw nav2Container d-inline-block rem-pad-width z-index-999 flex-row-spacebetween-nowrap">
      <div className="add-edit-wrap">
        <Link to="/" id="TodayFeather" onClick={() => setShowList('')}>
          <img
            src="https://dashboard-v2-images.s3.amazonaws.com/horizontal_nav_cb.svg"
            className="d-block m-top-15 left-0 m-left-21 w-26 h-26"
          />
        </Link>
        <div
          className="angleHover h-45 m-left-15 m-right-20"
          onClick={() => {
            setShowList('');
            setShowMenu('');
          }}
        >
          <Link to="/" id="Today" style={{ textDecoration: 'none' }}>
            <p className="navDashboardText d-block left-0 ">Today</p>
          </Link>
        </div>
        {props.role !== 'ASSOCIATE' && (
          <div
            className="angleHover h-45 m-right-24 "
            onClick={() => {
              setShowList('');
              setShowMenu('');
            }}
          >
            <Link
              to={props?.launchFlagsForNav?.reportsPage ? '/Reports' : '/Home'}
              id="DashboardHome"
              style={{ textDecoration: 'none' }}
            >
              <p className="navDashboardText d-block left-0 ">Reports</p>
            </Link>
          </div>
        )}
        {/* Messaging */}
        <NavListComposer
          hasNewMessages={props.hasNewMessages}
          setShowMenu={setShowMenu}
          setOpenDropDown={setOpenDropDown}
          openDropDown={openDropDown}
          title="Messaging"
          dropCarrot
          listItems={messagingMenuOptions()}
          current={current}
          showList={showList}
          setShowList={setShowList}
        />
        {/* Payments */}
        <NavListComposer
          hasNewMessages={props.hasNewMessages}
          setShowMenu={setShowMenu}
          setOpenDropDown={setOpenDropDown}
          openDropDown={openDropDown}
          title="Payments"
          dropCarrot
          listItems={getPaymentsNav(props.role)}
          current={current}
          showList={showList}
          setShowList={setShowList}
        />
        {/* Automation */}
        {props.role == 'ADMIN' ? (
          <NavListComposer
            hasNewMessages={props.hasNewMessages}
            setShowMenu={setShowMenu}
            setOpenDropDown={setOpenDropDown}
            openDropDown={openDropDown}
            title="Automation"
            dropCarrot
            listItems={isSafe()}
            current={current}
            showList={showList}
            setShowList={setShowList}
          />
        ) : null}
        {/* Manage */}
        <NavListComposer
          hasNewMessages={props.hasNewMessages}
          setShowMenu={setShowMenu}
          setOpenDropDown={setOpenDropDown}
          openDropDown={openDropDown}
          title="Manage"
          dropCarrot
          listItems={manageMenuOptions()}
          current={current}
          showList={showList}
          setShowList={setShowList}
        />
        {props.launchFlagsForNav?.projectEdison && (
          <Edison
            merchantId={props.merchantId}
            userId={props.userId}
            edison_view_date={props.edison_view_date}
            selectedStore={props.selectedStore}
            stores={props.stores}
            auth={props}
          />
        )}
      </div>
      <div className="m-right-20">
        <UserProfileMenu
          showList={showList}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
          setShowList={setShowList}
          showQr={showQr}
          setShowQr={setShowQr}
        />
      </div>
      <Modal
        open={showQr}
        centered
        onOk={() => setShowQr(!showQr)}
        onCancel={() => setShowQr(!showQr)}
        footer={null}
        bodyStyle={{ borderRadius: '20px' }}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.95)' }}
      >
        <OtpInQr
          url={`${process.env.REACT_APP_OPT_IN_FORM_URL}${queryMaker({
            merchantUuid: props.uuid,
            associateUuid: props.associateUuid,
          })}`}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state.auth,
  paymentBannerActive: state.paymentBanner.active,
  paymentBannerStatus: state.paymentBanner.status,
});

const mapDispatchToProps = dispatch => ({
  setPaymentBannerStatus(val) {
    dispatch({
      type: 'SET_PAYMENT_BANNER_STATUS',
      payload: val,
    });
  },
  setPaymentBannerActive(val) {
    dispatch({
      type: 'SET_PAYMENT_BANNER_ACTIVE',
      payload: val,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation_V2);

const NavListComposer = props => {
  let linkClass = 'add-edit-wrap';
  if (props.showList === props.title) {
    linkClass += ' angleHover_active';
  }
  return (
    <div className="pos-rel m-right-24" style={{ maxWidth: '90px' }}>
      {/* nav bar */}
      <div
        className={`${linkClass} angleHover pos-rel`}
        style={{ width: 'min-content' }}
        onClick={() => {
          props.setOpenDropDown(true);
          props.setShowList(props.showList === props.title ? '' : props.title);
          props.setShowMenu('');
        }}
      >
        <p className="navDropDown">
          {props.title === 'Messaging' ? (
            <Badge color="#33AAFC" dot={props.hasNewMessages}>
              {props.title}
            </Badge>
          ) : (
            <>{props.title}</>
          )}
        </p>

        <FontAwesomeIcon
          icon={['far', 'angle-down']}
          color="#fff"
          size="1x"
          style={{ margin: '20px 10px 8px 10px' }}
          className="pointer"
        />
      </div>
      {/* drop down portion */}
      {props.showList === props.title ? (
        <div>
          <div
            role="button"
            className="nav-background"
            onClick={() => {
              props.setShowList('');
            }}
          />
          <div className="dropDown-nav" style={{ zIndex: '102' }}>
            <ul className="">
              {props.listItems.map((opt, i) => {
                return (
                  <li
                    className="SPlink"
                    id={opt.title.split(' ').join('')}
                    key={i}
                    onClick={() => props.setShowList('')}
                  >
                    <Link
                      className={props.current}
                      style={{
                        textDecoration: 'none',
                        position: 'absolute',
                        top: '-4px',
                        display: 'inline-block',
                        // width: '100%',
                        width: '145px',
                        padding: '0px 0px 3px 25px',
                      }}
                      to={opt.link}
                    >
                      {opt.title === 'Inbox' ? (
                        <Badge color="#33AAFC" dot={props.hasNewMessages}>
                          {opt.title}
                        </Badge>
                      ) : (
                        <>{opt.title}</>
                      )}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};
