import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Table from '../../../Layout/Table';
import Button from '../../../Layout/Button';
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import { getRequest, postRequest, deleteRequest } from '../../../../core/apiRequests';
import PopMessage from '../../../Layout/PopMessage';
import PopConfirm from '../../../Layout/PopConfirm';
import LargeImage from '../../../Layout/LargeImage';
import DropdownSelect from '../../../Layout/DropdownSelect';
import MultiSelect from '../../../Layout/DropdownMultiSelect';
import { notificationSuccess, notificationError } from '../../../../core/actions';
import TextAreaWithActions from '../../../Layout/TextAreaWithActions';
import { setLoadingStatus } from '../../../../reducers/notification';
import GlobalHeader from '../../../Layout/GlobalHeader';
import useActionTextAreaFiles, {
  actionTextAreaFileActions,
} from '../../../Layout/TextAreaWithActions/useActionTextAreaFiles';
import { convertMessageTemplate } from '../../../../utils/convertMessageTemplate';

const AllTemplates = props => {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [storesOptions, setStores] = useState(props.auth.stores);
  const isMounted = useRef(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [defaultTemplate] = useState({ store: props.auth.role === 'ADMIN' ? [0] : [], scope: 'General' });
  const [template, setTemplate] = useState(defaultTemplate);
  const [configObj, setConfigObj] = useState({});
  const [add, setAdd] = useState(false);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [templateObj, setTemplateObj] = useState({});
  const [onHoverImg, setOnHoverImg] = useState('');
  const [tableRowId, setTableRowId] = useState('');
  const [editDisable, setEditDisable] = useState(false);
  const [storeNamesObj] = useState(
    props.auth.stores.filter(item => item.id).reduce((acc, curr) => ({ [curr.id]: curr.name, ...acc }), {}),
  );
  const [fileData, fileDispatch] = useActionTextAreaFiles();
  const flags = useFlags();
  const [reviewLink, setReviewLink] = useState('[Review Link]');
  const [largeImagePopup, setLargeImagePopup] = useState(false);

  useEffect(() => {
    setStores((storesOptions[0].name = 'Global'));
    let { storeId } = props.auth;
    if (props.auth.merchantId && props.auth.stores.length > 1) {
      if (storeId === 0) {
        storeId = props.auth.stores[1].id;
      }
      (async () => {
        const reqObj = {
          params: ['associates', props.auth.userId, storeId, 'reviewLink'],
        };
        const data = await getRequest(reqObj);
        if (data[0].review_link) {
          setReviewLink(data[0].review_link);
        }
      })();
    }
  }, [props.auth.merchantId, props.auth.userId, props.auth.stores]);
  const searchFunctionality = searchText => {
    if (searchText) {
      const searchArray = searchText.split(' ');
      const filteredArr = [];
      tableData.forEach(tmpl => {
        let hasIt = true;
        searchArray.forEach(sText => {
          const sTextFm = sText.toLowerCase().trim();
          const templateTxt = tmpl.msg || '';
          const subTxt = tmpl.substitution || '';
          if (!templateTxt.toLowerCase().includes(sTextFm) && !subTxt.toLowerCase().includes(sTextFm)) {
            hasIt = false;
          }
        });
        if (hasIt) {
          filteredArr.push(tmpl);
        }
      });
      setFilteredTemplates(filteredArr);
    } else {
      setFilteredTemplates(tableData);
    }
  };
  const sendMessage = () => {
    const s3_images = fileData.images.urls.filter(image => image.includes('s3.amazonaws.com'));
    const cleanedURLs = fileData.images.urls.filter(image => image.includes('blob'));
    const cleanedImgObj = { urls: cleanedURLs, files: [...fileData.images.files] };
    const templateStores = template.store;
    if (props.auth.merchantId) {
      if (!template.scope) {
        notificationError('Please add a scope!');
      } else if (!template.typeId && template.scope !== 'General') {
        notificationError('Please add a type!');
      } else if (!template.autoReminderId && template.scope !== 'General') {
        notificationError('Please add an auto reminder!');
      } else if (!template.msg) {
        notificationError('Please add template text!');
      } else if (flags.generalVsStoreTemplates && (!template.store || !template.store?.length)) {
        notificationError('Please add template stores!');
      } else {
        (async () => {
          const dataObj = {
            scope: template.scope,
            msgId: template.msgId,
            msg: template.msg,
            autoReminderId: template.autoReminderId,
            store_id: templateStores.length === 1 && templateStores[0] === 0 ? null : templateStores,
          };
          template.name && (dataObj.template_name = template.name);
          const reqObj = {
            params: ['chatMessages', props.auth.merchantId, 'updateOrSaveGlobalTemplates'],
            query: { s3Images: s3_images },
            data: dataObj,
            images: cleanedImgObj,
          };
          const data = await postRequest(reqObj);
          if (data.success || data.id) {
            if (template.msgId) {
              notificationSuccess('Message Template Updated');
              setOpenPopup(false);
              setEditDisable(false);
            } else {
              // adding table
              notificationSuccess('Message Template Created');
              setOpenPopup(false);
              setEditDisable(false);
            }
            setLoadingStatus(true);
            fileDispatch({
              type: actionTextAreaFileActions.clear,
            });
            setTimeout(() => {
              getTemplateDetails();
            }, 2000);
          } else {
            console.log('else hit during save');
          }
        })();
      }
    }
  };
  const getTemplateDetails = () => {
    setLoadingStatus(true);
    (async () => {
      const reqObj = {
        params: ['chatMessages', props.auth.merchantId, 'messageTemplates'],
        query: { licenseKey: props.auth.licenseKey },
      };
      const dataArr = await getRequest(reqObj);
      if (dataArr) {
        const dataObj = dataArr.map(obj => {
          let superSecretKey = '';
          if (obj.typeId) {
            superSecretKey = obj.msgId + obj.typeId;
          } else superSecretKey = obj.msgId;
          return {
            ...obj,
            onMouseOver: () => {
              setTableRowId(superSecretKey);
            },
            onMouseLeave: () => {
              setTableRowId('');
            },
          };
        });
        const hasTags = props.auth?.merchantFlags?.tags?.enabled ? { Tag: {} } : {};
        const tmpConfigObj = {
          General: {},
          ...hasTags,
          'Sales Opportunity': {},
          'Life Event': {},
          'Client Activity': {},
        };
        dataObj.forEach(obj => {
          // does this have a scope property?
          if (tmpConfigObj[obj.scope]) {
            if (tmpConfigObj[obj.scope][obj.typeId]) {
              tmpConfigObj[obj.scope][obj.typeId].type = obj.type;
              tmpConfigObj[obj.scope][obj.typeId].typeId = obj.typeId;
              if (tmpConfigObj[obj.scope][obj.typeId].autoReminders) {
                // we confirmed that there is a property auto reminders, now we are creating a list of auto reminders
                if (tmpConfigObj[obj.scope][obj.typeId].autoReminders[obj.autoReminderId]) {
                  tmpConfigObj[obj.scope][obj.typeId].autoReminders[obj.autoReminderId].autoName = obj.autoReminder;
                  tmpConfigObj[obj.scope][obj.typeId].autoReminders[obj.autoReminderId].autoReminderId =
                    obj.autoReminderId;
                  if (tmpConfigObj[obj.scope][obj.typeId].autoReminders[obj.autoReminderId].msg) {
                    tmpConfigObj[obj.scope][obj.typeId].autoReminders[obj.autoReminderId].msg[obj.msgId] = obj.msg;
                  } else {
                    tmpConfigObj[obj.scope][obj.typeId].autoReminders[obj.autoReminderId].msg = {
                      [obj.msgId]: obj.msg,
                    };
                  }
                } else {
                  tmpConfigObj[obj.scope][obj.typeId].autoReminders[obj.autoReminderId] = {
                    autoName: obj.autoReminder,
                    autoReminderId: obj.autoReminderId,
                    msg: {
                      [obj.msgId]: obj.msg,
                    },
                  };
                }
              } else {
                tmpConfigObj[obj.scope][obj.typeId].autoReminders = {
                  [obj.autoReminderId]: {
                    autoName: obj.autoReminder,
                    autoReminderId: obj.autoReminderId,
                    msg: {
                      [obj.msgId]: obj.msg,
                    },
                  },
                };
              }
            } else {
              tmpConfigObj[obj.scope][obj.typeId] = {
                type: obj.type,
                typeId: obj.typeId,
                autoReminders: {
                  [obj.autoReminderId]: {
                    autoName: obj.autoReminder,
                    autoReminderId: obj.autoReminderId,
                    msg: {
                      [obj.msgId]: obj.msg,
                    },
                  },
                },
              };
            }
          } else {
            tmpConfigObj[obj.scope] = {
              [obj.typeId]: {
                type: obj.type,
                typeId: obj.typeId,
                autoReminders: {
                  [obj.autoReminderId]: {
                    autoName: obj.autoReminder,
                    autoReminderId: obj.autoReminderId,
                    msg: {
                      [obj.msgId]: obj.msg,
                    },
                  },
                },
              },
            };
          }
        });
        const msgOnlyDataObj = dataObj
          .filter(data => data.msg !== null)
          .map(item => ({
            ...item,
            disabled: flags.generalVsStoreTemplates && props.auth.role !== 'ADMIN' && !item.store_id,
          }));
        setConfigObj(tmpConfigObj);
        setTableData(msgOnlyDataObj);
        setFilteredTemplates(msgOnlyDataObj);
        setLoadingStatus(false);
      }
    })();
  };
  useEffect(() => {
    isMounted.current = true;
    if (props.auth.merchantId) {
      getTemplateDetails();
    }
    return () => {
      isMounted.current = false;
    };
  }, [props.auth.merchantId]);
  const tableHeaders = [
    {
      keyName: 'scope',
      style: { maxWidth: '10%', minWidth: '100px' },
      rowClassName: 'p-left-6 darkBlue',
      headerClassName: 'p-left-6',
      title: 'Scope',
      sortable: false,
    },
    {
      keyName: 'type',
      style: { minWidth: '160px' },
      headerClassName: 'col-p-l-25 align-left rem-pad-width',
      rowClassName: 'align-left col-p-l-25 rem-pad-width',
      title: 'Type',
      sortable: false,
      render: (text, obj) => {
        if (text) {
          return text;
        }
        return (
          <span className="gray" style={{ fontStyle: 'italic' }}>
            Not Applicable
          </span>
        );
      },
    },
    {
      keyName: 'store_id',
      style: { minWidth: '160px' },
      headerClassName: 'col-p-l-25 align-left rem-pad-width',
      rowClassName: 'align-left col-p-l-25 rem-pad-width',
      title: 'Store',
      sortable: false,
      hidden: !flags.generalVsStoreTemplates,
      render: text => {
        if (text) {
          return storeNamesObj[text];
        }
        return (
          <span className="gray" style={{ fontStyle: 'italic' }}>
            Global
          </span>
        );
      },
    },
    flags.templateLabels
      ? {
        keyName: 'autoReminder',
        style: { minWidth: '150px', paddingRight: '22px' },
        headerClassName: 'align-left rem-pad-width',
        rowClassName: 'align-left col-p-r-70 rem-pad-width',
        title: 'Template Name',
        sortable: false,
        render: (text, obj) => {
          if (text) {
            return text;
          }
          return (
              <span className="gray" style={{ fontStyle: 'italic' }}>
                Not Applicable
              </span>
          );
        },
      }
      : {
        keyName: 'autoReminder',
        style: { minWidth: '150px', paddingRight: '22px' },
        headerClassName: 'align-left rem-pad-width',
        rowClassName: 'align-left col-p-r-70 rem-pad-width',
        title: 'Auto-Reminder',
        sortable: false,
        render: (text, obj) => {
          if (text) {
            return text;
          }
          return (
              <span className="gray" style={{ fontStyle: 'italic' }}>
                Not Applicable
              </span>
          );
        },
      },
    {
      keyName: 'msg',
      style: { width: '50%', minWidth: '130px', paddingRight: '22px' },
      headerClassName: 'align-left rem-pad-width',
      rowClassName: 'align-left col-p-r-25 rem-pad-width',
      title: 'Message Template',
      sortable: false,
      render: (msg, item) => {
        return <div dangerouslySetInnerHTML={{ __html: convertMessageTemplate(msg) }} />;
      },
    },
    {
      keyName: 'image_url',
      style: { width: '30px', paddingRight: '10px' },
      title: 'Attachments',
      headerClassName: 'align-left p-btm-6',
      rowClassName: '',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        if (t) {
          return (
            <img
              style={{ objectFit: 'cover', borderRadius: '2px', width: '32px', height: '32px', marginTop: '4px' }}
              className="m-right-4"
              alt="#"
              src={t}
              onClick={e => {
                e.stopPropagation();
                setLargeImagePopup(true);
                setTemplateObj(o);
              }}
              onError={e => {
                e.target.onerror = null;
                e.target.src = 'https://dashboard-v2-images.s3.amazonaws.com/image-placeholder@2x.png';
              }}
              // onClick={(e) => stopEventBubbling(e.target, msg)}
            />
          );
        }
        return (
          <span className="gray" style={{ fontStyle: 'italic' }}>
            None
          </span>
        );
      },
      isImg: 'image',
    },
    {
      keyName: ' ',
      style: { width: '25px' },
      headerClassName: 'align-left rem-pad-width',
      rowClassName: 'align-left rem-pad-width ',
      title: '',
      sortable: false,
      render: (text, obj) => {
        if (obj.msgId + obj.typeId === tableRowId) {
          return (
            <div
              className="w-33 h-33 bg-white border-r4 pointer"
              style={{ marginRight: '5px' }}
              id="deleteTemplate"
              onMouseEnter={e => {
                e.target.style.backgroundColor = '#e8f6ff';
              }}
              onMouseLeave={e => {
                e.target.style.backgroundColor = '#ffffff';
              }}
              onClick={e => deleteTemplate(e, obj)}
            >
              <FontAwesomeIcon
                icon={['far', 'trash']}
                size="1x"
                className="fs-16 pointer"
                style={{ color: 'black', marginTop: '8px', marginLeft: '10px' }}
              />
            </div>
          );
        }
        return (
          <div
            className="w-33 h-33 bg-white border-r4 pointer"
            style={{ marginRight: '5px', backgroundColor: '#ffffff' }}
          />
        );
      },
    },
  ];


  const rowClickHandler = data => {
    const selectedTemplate = data;
    selectedTemplate.store = data.store_id ? [data.store_id] : [0];
    selectedTemplate.name = data.autoReminder;
    setEditDisable(true);
    setDeletePopup(false);
    setTemplate(selectedTemplate);
    setAdd(false);
    if (selectedTemplate.image_url) {
      fileDispatch({
        type: actionTextAreaFileActions.setToSingleImageUrl,
        payload: data.image_url,
      });
    } else {
      fileDispatch({
        type: actionTextAreaFileActions.clear,
      });
    }
  };

  const deleteTemplate = (event, obj) => {
    event.stopPropagation();
    setOpenPopup(false);
    setDeletePopup(true);
    setTemplateObj(obj);
  };

  const setMessage = message => {
    if (template.msgId) {
      setTemplate({ ...template, msg: message, msgId: template.msgId });
    } else {
      setTemplate({ ...template, msg: message });
    }
  };
  const deleteMessage = msg => {
    const { merchantId } = props.auth;
    const reqObj = {
      params: ['chatMessages', merchantId, 'templateDelete'],
      query: { msgId: msg.msgId, scope: msg.scope },
    };
    (async () => {
      const respData = await deleteRequest(reqObj);
      if (respData) {
        notificationSuccess('Template Successfully Deleted!');
        const tempTemplatesList = filteredTemplates;
        const index = tempTemplatesList.indexOf(msg);
        if (index !== -1) {
          tempTemplatesList.splice(index, 1);
        }
        setFilteredTemplates([...tempTemplatesList]);
      } else {
        notificationError('Failed To Delete Template');
      }
      setDeletePopup(false);
    })();
  };

  const additionalTemplateButtons = () => {
    return (
      <div className="storeInboxHeader m-top-8">
        <div className="newMessage">
          <Button
            classname="transparentButton"
            onclick={() => {
              setOpenPopup(true);
              setAdd(true);
              setEditDisable(false);
              setTemplate(defaultTemplate);
            }}
          >
            <FontAwesomeIcon
              style={{ width: '15px', height: '15px' }}
              size="1x"
              className=" m-right-15"
              icon={['far', 'comment-alt-plus']}
            />{' '}
            Add Template
          </Button>
        </div>
      </div>
    );
  };
  const storeSelectHandler = stores => {
    const templateStores = template.store ?? [];
    if (props.auth.role === 'ADMIN') {
      if (stores.length === 0) {
        setTemplate({ ...template, store: [0] });
      } else if (templateStores.length === 1 && templateStores[0] === 0) {
        setTemplate({ ...template, store: [stores[1]] });
      } else if (stores.includes(0)) {
        setTemplate({ ...template, store: [0] });
      } else {
        setTemplate({ ...template, store: stores });
      }
    } else {
      setTemplate({ ...template, store: stores });
    }
  };
  return (
    <Layout
      title="Templates"
      description="Create and view Templates to quickly send messages"
      classProps="standard-width-with-sidebar rem-pad-width"
      breadCrumbs={[{ title: 'Messaging' }, { title: 'Templates' }]}
    >
      <div className="maw-1100 h-100-P pos-rel">
        <GlobalHeader
          additionalButtonStyle={{ justifyContent: 'flex-end' }}
          search={[
            <input
              key="search-template-key"
              onChange={e => searchFunctionality(e.target.value)}
              placeholder="Search Templates…"
              className="h-50 w-100-P m-btm-6 body-head-search rem-pad-width focus-input-outline-blue"
              style={{ width: '100%', height: '50px' }}
            />,
          ]}
          additionalButtons={additionalTemplateButtons}
        />
        <Table
          tableClassName="lg-table-sidebar rem-pad-width"
          disabledText="If you would like this template changed, please reach out to an account admin to change it."
          data={filteredTemplates}
          tableHeaders={tableHeaders}
          searchable={false}
          onRowClick={rowClickHandler}
          styleProps={{ marginTop: '-8px' }}
          tableName="ALL_TEMPLATES"
        />
      </div>
      {openPopup ? (
        <PopMessage
          popStyle={{ minWidth: '300px', width: '650px', minHeight: '400px', overflow: 'hidden' }}
          confirm={add ? 'Add Template' : 'Edit Template'}
          customContent
          noButtonDisable
          confirmationButtonText="Save"
          onCancel={() => {
            setOpenPopup(false);
            fileDispatch({
              type: actionTextAreaFileActions.clear,
            });
          }}
          onConfirm={() => sendMessage()}
          description={
            <div key="description-key">
              <DropdownSelect
                classProps="maw-100-P w-100-P align-left "
                options={Object.keys(configObj).map((st, i) => ({
                  label: st,
                  value: st,
                }))}
                placeholder="Scope"
                value={template.scope}
                disabled={props.auth.role !== 'ADMIN'}
                onChange={obj => setTemplate({ ...template, scope: obj.value })}
              />
              {template.scope === 'General' && flags.templateLabels && (
                <>
                  <div className="flex-col-left p-top-20 p-btm-6">
                    <span className="fw-500">Template Name</span>
                  </div>
                  <input
                    id="templateName"
                    className="m-btm-15 m-top-15 fs-13 outline-none common_inputStyle max-w-600 w-100-P left-0 input-height "
                    value={template.name}
                    onChange={e => setTemplate({ ...template, name: e.target.value })}
                    placeholder="Template Name"
                  />
                </>
              )}
              {template.scope && flags.generalVsStoreTemplates && (
                <>
                  <div className="flex-col-left p-top-20 p-btm-6">
                    <span className="fw-500">Stores</span>
                  </div>
                  <MultiSelect
                    classProps="maw-100-P w-100-P align-left"
                    defaultValue={props.auth.role === 'ADMIN' ? [0] : []}
                    options={props.auth.stores
                      .filter(item => props.auth.role === 'ADMIN' || item.id)
                      .map(item => ({
                        label: item.name,
                        value: item.id,
                        searchable: !!item.id,
                      }))}
                    placeholder="Store"
                    value={template.store}
                    onChange={obj => storeSelectHandler(obj)}
                    mode="tags"
                  />
                </>
              )}
              {template.scope === 'General' ? (
                <TextAreaWithActions
                  val={template.msg ? template.msg : ''}
                  changeHandler={e => setMessage(e)}
                  fileData={fileData}
                  fileDispatch={fileDispatch}
                  containerStyleProps={{
                    marginTop: '16px',
                    // height: '213px'
                  }}
                  textAreaStyleProps={{ height: '18vh', padding: '15px' }}
                  autoMessageOrReminder
                  template
                  reviewLink={null}
                  associate=":associate:"
                />
              ) : null}
              {template.scope === 'General' || !template.scope ? null : (
                <div>
                  <DropdownSelect
                    classProps="maw-100-P w-100-P align-left m-top-10 "
                    options={Object.values(configObj[template.scope]).map(type => ({
                      label: type.type,
                      value: type.typeId,
                    }))}
                    placeholder="Type"
                    value={template.typeId ? template.typeId : ''}
                    onChange={obj => {
                      setTemplate({
                        ...template,
                        typeId: obj.value,
                        type: obj.label,
                        autoReminderId: '',
                        autoReminder: '',
                      });
                    }}
                    optionalStyles={{
                      menuList: (provided, state) => ({
                        ...provided,
                        height: '180px',
                      }),
                    }}
                  />
                  {/* checking to make sure .autoReminders values are there */}
                  {configObj[template.scope].hasOwnProperty(template.typeId) &&
                  configObj[template.scope][template.typeId].hasOwnProperty('autoReminders') ? (
                    <div>
                      <DropdownSelect
                        classProps="maw-100-P w-100-P align-left m-top-10 "
                        options={Object.values(configObj[template.scope][template.typeId].autoReminders).map(
                          reminder => ({
                            label: reminder.autoName,
                            value: reminder.autoReminderId,
                          }),
                        )}
                        placeholder="Auto Reminder"
                        value={template.autoReminderId}
                        onChange={obj => {
                          setTemplate({ ...template, autoReminderId: obj.value, autoReminder: obj.label });
                        }}
                      />
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {add ? (
                        <div>
                          <TextAreaWithActions
                            val={template.msg ? template.msg : ''}
                            changeHandler={setMessage}
                            fileData={fileData}
                            fileDispatch={fileDispatch}
                            containerStyleProps={{
                              marginTop: '16px',
                              // height: '213px'
                            }}
                            textAreaStyleProps={{ height: '18vh', padding: '15px' }}
                            autoMessageOrReminder
                            template
                            associate=":associate:"
                          />
                        </div>
                      ) : configObj[template.scope].hasOwnProperty(template.typeId) &&
                        configObj[template.scope][template.typeId].autoReminders.hasOwnProperty(
                          template.autoReminderId,
                        ) &&
                        configObj[template.scope][template.typeId].autoReminders[
                          template.autoReminderId
                        ].hasOwnProperty('msg') ? (
                        <div>
                          <TextAreaWithActions
                            val={
                              template.msg
                                ? template.msg
                                : Object.values(
                                  configObj[template.scope][template.typeId].autoReminders[template.autoReminderId]
                                    .msg,
                                )
                            }
                            changeHandler={setMessage}
                            fileDispatch={fileDispatch}
                            fileData={fileData}
                            containerStyleProps={{
                              marginTop: '16px',
                              // height: '213px'
                            }}
                            textAreaStyleProps={{ height: '18vh', padding: '15px' }}
                            autoMessageOrReminder
                            template
                            associate=":associate:"
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          }
        />
      ) : null}
      {editDisable ? (
        <PopMessage
          popStyle={{ minWidth: '300px', width: '650px', minHeight: '400px', overflow: 'hidden' }}
          confirm={add ? 'Add Template' : 'Edit Template'}
          customContent
          noButtonDisable
          confirmationButtonText="Save"
          onCancel={() => {
            setEditDisable(false);
            fileDispatch({
              type: actionTextAreaFileActions.clear,
            });
          }}
          onConfirm={() => sendMessage()}
          description={
            <div>
              <DropdownSelect
                classProps="maw-100-P w-100-P align-left "
                options={Object.keys(configObj).map((st, i) => ({
                  label: st,
                  value: st,
                }))}
                placeholder="Scope"
                value={template.scope}
                onChange={obj => {
                  setTemplate({ ...template, scope: obj.value });
                }}
                disabled={!!editDisable}
              />
              {template.scope === 'General' && flags.templateLabels && (
                <>
                  <div className="flex-col-left p-top-20 p-btm-6">
                    <span className="fw-500">Template Name</span>
                  </div>
                  <input
                    id="templateName"
                    className="m-btm-15 m-top-15 fs-13 outline-none common_inputStyle max-w-600 w-100-P left-0 input-height "
                    value={template.name}
                    onChange={e => setTemplate({ ...template, name: e.target.value })}
                    placeholder="Template Name"
                  />
                </>
              )}
              {flags.generalVsStoreTemplates && (
                <>
                  <div className="flex-col-left p-top-20 p-btm-6">
                    <span className="fw-500">Stores</span>
                  </div>
                  <MultiSelect
                    classProps="maw-100-P w-100-P align-left"
                    options={props.auth.stores.map(item => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    placeholder="Store"
                    value={template.store}
                    onChange={obj => storeSelectHandler(obj)}
                    mode="tags"
                    disabled={!!editDisable}
                  />
                </>
              )}
              {template.scope === 'General' ? (
                <TextAreaWithActions
                  // val={template.msg ? template.msg : Object.values(configObj[template.scope][null]['autoReminders'][null]['msg'])}
                  val={template.msg ? template.msg : ''}
                  changeHandler={setMessage}
                  fileData={fileData}
                  fileDispatch={fileDispatch}
                  containerStyleProps={{
                    marginTop: '16px',
                  }}
                  textAreaStyleProps={{ height: '18vh', padding: '15px' }}
                  autoMessageOrReminder
                  template
                  associate=":associate:"
                />
              ) : null}
              {template.scope === 'General' || !template.scope ? null : (
                <div>
                  <DropdownSelect
                    classProps="maw-100-P w-100-P align-left m-top-10 "
                    options={Object.values(configObj[template.scope]).map(type => ({
                      label: type.type,
                      value: type.typeId,
                    }))}
                    placeholder="Type"
                    value={template.typeId ? template.typeId : ''}
                    onChange={obj => {
                      setTemplate({
                        ...template,
                        typeId: obj.value,
                        type: obj.label,
                        autoReminderId: '',
                        autoReminder: '',
                      });
                    }}
                  />
                  <div>
                    <DropdownSelect
                      classProps="maw-100-P w-100-P align-left m-top-10 "
                      options={Object.values(configObj[template.scope][template.typeId].autoReminders).map(
                        reminder => ({
                          label: reminder.autoName,
                          value: reminder.autoReminderId,
                        }),
                      )}
                      placeholder="Auto Reminder"
                      value={template.autoReminderId ? template.autoReminderId : ''}
                      onChange={obj => {
                        setTemplate({ ...template, autoReminderId: obj.value, autoReminder: obj.label });
                      }}
                    />
                    <div>
                      <TextAreaWithActions
                        val={template.msg ? template.msg : ''}
                        changeHandler={setMessage}
                        fileData={fileData}
                        fileDispatch={fileDispatch}
                        containerStyleProps={{
                          marginTop: '16px',
                          // height: '213px'
                        }}
                        textAreaStyleProps={{ height: '18vh', padding: '15px' }}
                        autoMessageOrReminder
                        template
                        associate=":associate:"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
        />
      ) : null}
      {deletePopup ? (
        <PopConfirm
          customContent={false}
          confirm="Are you sure you want to delete this template?"
          onCancel={() => setDeletePopup(false)}
          onConfirm={() => deleteMessage(templateObj)}
          noButtonDisable
        />
      ) : null}
      {largeImagePopup ? <LargeImage setLargeImagePopup={setLargeImagePopup} templateObj={templateObj} /> : null}
    </Layout>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(AllTemplates);
