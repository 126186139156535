import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import StandardLayout from '../../../../Layout/StandardLayout';
import Button from '../../../../Layout/Button';
import StyledLink from '../../../../Layout/StyledLink';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';
import PopConfirm from '../../../../Layout/PopConfirm';
import { notificationSuccess, notificationError } from '../../../../../core/actions';
import { saveListItem, deleteRequest, getRequest } from '../../../../../core/apiRequests';
import {
  setLifeEvName,
  setLifeEvId,
  setLifeEvAddEdit,
  setLifeCreatedBy,
  setLifeEvEnabled,
  setLifeSystemId,
} from '../../../../../reducers/lifeEvent';
import { setListId, setListType } from '../../../../../reducers/autoRemMsgs';
import AutomationTable from '../../../../Layout/AutomationTable';
import KioskSlider from '../../../../Layout/KioskSlider';
import Input from '../../../../CustomizedAntDesign/Input/Input';

const AddEditLifeEvent = props => {
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [isBirthdayOrAnniversary, setIsBirthdayOrAnniversary] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (window.location.href.includes('edit-life-events')) {
      setIsBirthdayOrAnniversary(props.systemId === 'BIRTHDAY' || props.systemId === 'ANNIVERSARY');
    }
  }, [props.systemId]);

  useEffect(() => {
    if (props.addOrEdit === 'Add' && window.location.href.includes('add-life-events')) {
      if (props.eventId) {
        setLifeEvId('');
        setLifeEvName('');
        setLifeCreatedBy('');
        setIsBirthdayOrAnniversary(false);
      }
    } else if (
      (window.location.href.includes('edit-life-events') && !props.eventId) ||
      (props.match.params.listItemId && Number(props.eventId) !== Number(props.match.params.listItemId))
    ) {
      setLifeEvAddEdit('Edit');
      if (props.merchantId) {
        setLifeEvName('');
        (async () => {
          const reqObj = {
            params: ['lifeEvent', 'details', props.merchantId, props.match.params.listItemId],
          };
          const data = await getRequest(reqObj);
          if (data && data.length > 0) {
            const { name, id, created_by, system_id } = data[0];
            setListId(id);
            setLifeEvId(id);
            setLifeEvName(name);
            setLifeCreatedBy(created_by);
            setLifeSystemId(system_id);
            setLifeEvAddEdit('Edit');
            setListType('lifeEvent');
          } else {
            history.push('/Configuration/life-events');
          }
        })();
      }
      // query pref info
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [props.merchantId]);

  const confirmDelete = () => {
    const { firstName, lastName } = props;
    const fn = firstName || '';
    const ln = lastName || '';
    (async () => {
      const reqObj = {
        params: ['lifeEvent', props.merchantId, 'remove', props.eventId],
        query: {
          deletedBy: `${fn} ${ln}`.trim(),
        },
      };
      const data = await deleteRequest(reqObj);
      if (data) {
        notificationSuccess('Life Event Removed');
        history.push('/Configuration/life-events');
      } else {
        notificationError('Error Deleting Life Event');
      }
    })();
  };

  const saveEvent = () => {
    const { eventName, eventId, merchantId, firstName, lastName, eventEnabled } = props;
    const fn = firstName || '';
    const ln = lastName || '';
    if (!eventName) {
      notificationError('Event Name Required');
    } else {
      (async () => {
        const dataObj = {
          name: eventName,
          eventEnabled,
        };
        if (props.eventId) {
          dataObj.id = props.eventId;
        }
        if (isBirthdayOrAnniversary) {
          dataObj.systemDefined = true;
        }
        const data = await saveListItem('lifeEvent', merchantId, dataObj, `${fn} ${ln}`);
        if (data.id) {
          if (eventId) {
            notificationSuccess('Event Updated');
          } else {
            notificationSuccess('Event Created');
          }

          setListId(data.id);
          setLifeEvId(data.id);
          setLifeEvAddEdit('Edit');
        } else {
          // error handling?
        }
      })();
    }
  };

  const enableLIfeEvent = (
    <div>
      <p>You cannot delete this Life Event. Instead, you can either Enable or Disable the event.</p>
      <p>
        Disabling this Life Event will remove it from your Life Event lists located in the Dashboard and mobile app.
      </p>
    </div>
  );

  return (
    <StandardLayout
      title={`${props.addOrEdit} Life Event`}
      description={
        props.addOrEdit === 'Add'
          ? 'Create a new Life Event ex. Spouse’s Birthday'
          : 'Edit life event details and configure automation'
      }
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Life Events', to: '/Configuration/life-events' },
        { title: `${props.addOrEdit} Life Event` },
      ]}
      classProps="standard-width-with-sidebar"
    >
      <div className="custom-action-wrapper">
        <div className="align-left">
          {isBirthdayOrAnniversary ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                textAlign: 'left',
              }}
              className="m-top-11 m-btm-25"
            >
              <KioskSlider onChange={() => setLifeEvEnabled(!props.eventEnabled)} value={props.eventEnabled} />
              <span className="m-left-10 fw-500 " style={{ marginTop: '2px' }}>
                Enable Life Event
              </span>
              <Tooltip
                placement="bottom"
                title={enableLIfeEvent}
                overlayInnerStyle={{
                  width: '352px',
                  borderRadius: '10px',
                  padding: '19px 17px',
                  backgroundColor: '#000',
                }}
              >
                <InfoCircleOutlined className="m-left-10 m-top-5" />
              </Tooltip>
            </div>
          ) : null}
          <Input
            value={props.eventName}
            disabled={isBirthdayOrAnniversary}
            id="LifeEventEventName"
            placeholder="Life Event Name"
            onChange={e => setLifeEvName(e.target.value)}
            className="m-btm-15 fs-13 outline-none common_inputStyle max-w-600 w-100-P left-0 input-height"
          />
        </div>
        <div className="m-top-37 d-inline-block w-600">
          <div className="mq-w-100-vw custom-action-save-flex">
            <button className="save-button-v2 m-btm-8" onClick={saveEvent}>
              Save
            </button>
            {props.addOrEdit === 'Edit' && !isBirthdayOrAnniversary ? (
              <Button
                classname="deleteButton-v2 "
                style={{ marginBottom: '8px' }}
                onclick={() => setShowPopConfirm(true)}
              >
                Delete
              </Button>
            ) : (
              <div />
            )}
            <StyledLink to="/Configuration/life-events">
              <button className="cancel-button-v2">Cancel</button>
            </StyledLink>
          </div>
        </div>

        {props.addOrEdit === 'Edit' ? (
          <AutomationTable
            automationStyleProps={!props.eventEnabled ? { opacity: '.5', zIndex: '-10', pointerEvents: 'none' } : {}}
            match={props.match}
            redirectToReminderSettings={`/Configuration/life-events/edit-life-events/${props.eventId}`}
            redirectToAutoMsgSettings={`/Configuration/life-events/edit-life-events/${props.eventId}`}
            redirectToAutoWhatsappSettings={`/Configuration/life-events/edit-life-events/${props.eventId}`}
            type="lifeEvent"
          />
        ) : null}
      </div>
      {showPopConfirm ? (
        <PopConfirm
          confirm={`Are you sure you want to remove "${props.eventName}" Life Event?`}
          description="This will permanently delete this Life Event"
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={confirmDelete}
        />
      ) : null}
    </StandardLayout>
  );
};
const mapStateToProps = state => ({ ...state.lifeEvent, ...state.auth });
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AddEditLifeEvent);
