/* eslint-disable react/button-has-type */
import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../../Layout/StandardLayout';
import StyledLink from '../../Layout/StyledLink';
import Button from '../../Layout/Button';
import {
  setAuthLastName,
  setAuthMobile,
  setAuthEmail,
  setAuthFirstName,
  setAuthImage,
  setMessageNotifications,
} from '../../../reducers/auth';
import './ChangePassword/editprofile.css';
import { notificationSuccess, notificationError } from '../../../core/actions';
import 'react-phone-number-input/style.css';
import '../../Layout/layout.css';
import SecondaryPanel from '../../Layout/SecondaryPanel';
import { postRequest } from '../../../core/apiRequests';
import KioskSlider from '../../Layout/KioskSlider';
import './styles.scss';

const EditProfile = props => {
  const [uploadedImg, setImage] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [changeImage, setChangeImage] = useState(false);
  const [newFirstNameValue, setNewFirstNameValue] = useState(props.firstName || '');
  const [newLastNameValue, setNewLastNameValue] = useState(props.lastName || '');
  const [newMobileValue, setNewMobileValue] = useState(props.mobile || '');
  const [newEmailValue, setNewEmailValue] = useState(props.email || '');
  const [newMessageNotificationsValue, setNewMessageNotificationsValue] = useState(
    Boolean(props.message_notifications),
  );

  const emailValidation = email => {
    if (/^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    notificationError('Enter valid email address!');
    return false;
  };

  const mobileValidation = phone => {
    if (!phone) {
      return true;
    }
    if (phone.match(/\d/g).length >= 10) {
      return true;
    }
    notificationError('Enter valid mobile phone number!');
    return false;
  };

  const nameValidator = name => {
    if (name.length < 1) {
      notificationError('Please enter a First Name.');
      return false;
    }
    return true;
  };

  const saveSettings = () => {
    const { userId, merchantId, firstName, lastName, email, image } = props;
    const cleanImg = image == 'null' || image == '' || image == null ? null : image;
    const phoneNumber =
      newMobileValue == 'null' || newMobileValue == '' || newMobileValue == null || newMobileValue == 'undefined'
        ? null
        : newMobileValue;
    if (emailValidation(email) && mobileValidation(newMobileValue) && nameValidator(firstName)) {
      setAuthMobile(phoneNumber);
      const reqObj = {
        params: ['associates', merchantId, userId, 'updateProfile'],
        images: { files: [uploadedImg] },
        data: {
          firstName: newFirstNameValue,
          lastName: newLastNameValue,
          email: newEmailValue.toLowerCase(),
          mobile: newMobileValue,
          messageNotifications: newMessageNotificationsValue,
          image: cleanImg,
        },
      };

      (async () => {
        const saveData = await postRequest(reqObj);

        setAuthFirstName(newFirstNameValue);
        setAuthLastName(newLastNameValue);
        setAuthMobile(newMobileValue);
        setAuthEmail(newEmailValue);
        setMessageNotifications(newMessageNotificationsValue);

        if (saveData && saveData.image) {
          setAuthImage(saveData.image);
        }
        if (saveData && saveData.userId) {
          notificationSuccess('Update Profile Successful');
        } else {
          notificationError('Failed to Save Profile');
        }
      })();
    }
  };

  const full = `${props.firstName || ''} ${props.lastName || ''}`;
  const initials = `${full}`
    .split(' ')
    .map(word => word.substr(0, 1))
    .join(''); // initials icon

  const disabled = useMemo(
    () =>
      (newFirstNameValue || '') === (props.firstName || '') &&
      (newLastNameValue || '') === (props.lastName || '') &&
      (newMobileValue || '') === (props.mobile || '') &&
      (newEmailValue || '') === (props.email || '') &&
      !changeImage &&
      Boolean(newMessageNotificationsValue) === Boolean(props.message_notifications),
    [
      newFirstNameValue,
      newLastNameValue,
      newMobileValue,
      newEmailValue,
      newMessageNotificationsValue,
      changeImage,
      props.firstName,
      props.lastName,
      props.mobile,
      props.email,
      props.message_notifications,
    ],
  );

  useEffect(() => {
    setNewFirstNameValue(props.firstName);
  }, [props.firstName]);

  useEffect(() => {
    setNewLastNameValue(props.lastName);
  }, [props.lastName]);

  useEffect(() => {
    setNewMobileValue(props.mobile);
  }, [props.mobile]);

  useEffect(() => {
    setNewEmailValue(props.email);
  }, [props.email]);

  useEffect(() => {
    setNewMessageNotificationsValue(props.message_notifications);
  }, [props.message_notifications]);

  return (
    <Layout
      title="Edit Profile"
      description="Manage your account settings"
      classProps="standard-width-with-sidebar"
      breadCrumbs={[{ title: 'Edit Profile' }]}
    >
      <div className="w-100-P h-100-P" style={{ maxWidth: '890px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <StyledLink to="/EditProfile/change-password" className="w-100-P align-right">
            <Button id="ChangePassword" classname="blue-link m-right-0 p-right-0" style={{ padding: 0, margin: 0 }}>
              <span className="blue-link" style={{ marginRight: '-50px' }}>
                Change Password
              </span>
            </Button>
          </StyledLink>
        </div>
        <div className="flex-row-nospacebetween-wrap" style={{ position: 'relative', maxWidth: '890px' }}>
          {/* starting here */}
          <div className="profile_upload_div pointer">
            <input
              type="file"
              id="file"
              className="profile_upload"
              accept=".jpg, .png"
              value="" // here's the upload button
              onChange={e => {
                if (e.target.files[0].size < 1600000) {
                  const imagePath = URL.createObjectURL(e.target.files[0]);
                  setImage(e.target.files[0]);
                  setImageUrl(imagePath);
                  setChangeImage(true);
                } else {
                  notificationError('Image file size exceeds 1.5mb');
                }
              }}
              style={{ backgroundColor: 'blue' }}
            />
            {props.image == null && !imageUrl ? (
              <div
                style={{
                  objectFit: 'cover',
                  paddingTop: '4px',
                }}
                className="h-108 w-108 bg-lightgray circle rem-pad-width edit-image-icon"
              >
                <span
                  style={{
                    fontSize: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '15px',
                  }}
                  className="white"
                >
                  {initials}
                </span>
              </div>
            ) : (
              <img className="edit-image-icon" src={imageUrl || props.image} />
            )}
            <button className="upload_img_btn">
              <img src="https://dashboard-v2-images.s3.amazonaws.com/ic-camera.svg" />
            </button>
          </div>
          {/* ending here */}
          <div className="flex-col-left" style={{ marginLeft: '135px' }}>
            <div className="flex-row-nospacebetween-wrap">
              <input
                id="EditProfileFirstNameInput"
                className="input-height w-368 m-right-15 m-btm-15 focus-input-outline-blue p-left-8 rem-pad-width common_dropdownStyle"
                placeholder="First Name"
                value={newFirstNameValue}
                onChange={e => setNewFirstNameValue(e.target.value)}
              />
              <input
                id="EditProfileLastNameInput"
                className="input-height w-368 m-btm-15 focus-input-outline-blue p-left-8 rem-pad-width common_dropdownStyle"
                placeholder="Last Name"
                value={newLastNameValue}
                onChange={e => setNewLastNameValue(e.target.value)}
              />
            </div>
            <div className="flex-row-nospacebetween-wrap ">
              <PhoneInput
                placeholder="Enter phone number"
                value={newMobileValue}
                onChange={setNewMobileValue}
                defaultCountry={props.iso_country_code}
                id="EditProfilePhoneNumberInput"
                className="rem-pad-width border-r3 focus-input-outline-blue common_dropdownStyle"
                style={{ marginRight: '15px', marginBottom: '15px', width: '368px', height: '50px' }}
                countryOptionsOrder={[props.iso_country_code, '|']}
              />
              <input
                id="EditProfileEmailInput"
                className="input-height w-368 focus-input-outline-blue p-left-8 m-btm-15 rem-pad-width common_dropdownStyle"
                placeholder="Email"
                value={newEmailValue}
                onChange={e => setNewEmailValue(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="notifications">
          <div className="notifications-divider" />

          <div className="notifications-header">
            <FontAwesomeIcon icon="fa-regular fa-bell" />
            <span className="notifications-title">Notifications</span>
          </div>

          <div className="notifications-body">
            <span>SMS notification of new client messages.</span>
            <KioskSlider value={newMessageNotificationsValue} onChange={setNewMessageNotificationsValue} />
          </div>
        </div>

        <div className="flex-col-center">
          <button
            onClick={saveSettings}
            id="EditProfileSaveButton"
            className="save-button-v2 m-btm-8"
            disabled={disabled}
          >
            Save
          </button>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => state.auth;

export default connect(mapStateToProps, {})(EditProfile);
