import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from '../../../Layout/Table';
import Button from '../../../Layout/Button';
import Layout from '../../../Layout/StandardLayout';
import StyledLink from '../../../Layout/StyledLink';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import { getRequest } from '../../../../core/apiRequests';
import {
  resetTablePagination,
  setTablePaginationRowCount,
  setTablePaginationPageCount,
  getTablePaginationData,
} from '../../../../core/actions';
import {
  setLifeEvId,
  setLifeEvName,
  setLifeEvAddEdit,
  setLifeCreatedBy,
  setLifeEvEnabled,
  setLifeSystemId,
} from '../../../../reducers/lifeEvent';
import { setListId, setListType, setAutoReminders } from '../../../../reducers/autoRemMsgs';

const tableName = 'LIFE_EVENTS';

const LifeEvents = props => {
  const [tableData, setTableData] = useState([]);
  const [first, setFirst] = useState(true);
  const flags = props.auth.merchantFlags;
  const lifeEventEnabled = flags?.life_events?.enabled;
  const isMounted = useRef(null);
  const history = useHistory();

  useEffect(() => {
    isMounted.current = false;
    if (props.auth.merchantId) {
      const { page, pageSize, sortColumn, ascendingColumn, searchText } = props.pagination;
      let data;
      if (first) {
        resetTablePagination({ sortColumn: 'name' }, tableName);
        setFirst(false);
        data = async () => {
          const reqObj = {
            params: ['lifeEvent', props.auth.merchantId, 'paginatedList'],
            query: {
              page: 1,
              count: 25,
              sortColumn: 'name',
              ascending: true,
              searchText: '',
            },
          };
          const dataArr = await getRequest(reqObj);
          const { pageInfo, results } = dataArr;
          if (pageInfo) {
            const { rowCount, pageCount } = pageInfo;
            setTablePaginationPageCount(pageCount, tableName);
            setTablePaginationRowCount(rowCount, tableName);
          }
          setTableData(results);
        };
      } else {
        data = async () => {
          const reqObj = {
            params: ['lifeEvent', props.auth.merchantId, 'paginatedList'],
            query: {
              page,
              count: pageSize,
              sortColumn,
              ascending: ascendingColumn,
              searchText,
            },
          };
          const dataArr = await getRequest(reqObj);
          const { pageInfo, results } = dataArr;
          if (pageInfo) {
            const { rowCount, pageCount } = pageInfo;
            setTablePaginationPageCount(pageCount, tableName);
            setTablePaginationRowCount(rowCount, tableName);
          }
          setTableData(results);
        };
      }
      data();
      return () => {
        isMounted.current = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.auth.merchantId,
    props.pagination.sortColumn,
    props.pagination.pageSize,
    props.pagination.page,
    props.pagination.ascendingColumn,
    props.pagination.searchText,
  ]);

  const tableHeaders = [
    // this is a configuration array. It specifies how each column will be structured as well as the column header info
    {
      keyName: 'name',
      style: { maxWidth: '50%', minWidth: '105px' },
      rowClassName: 'col-p-l-25 darkBlue',
      headerClassName: 'col-p-l-25',
      title: 'Life Event Name',
      sortable: true, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        if (o.created_by === 'Clientbook') {
          if (o.active === 1) {
            return <span className="italic">{t}</span>;
          }
          return <span className="italic color-bdbdbd">{t}</span>;
        }
        return t;
      },
    },
    {
      keyName: 'created_date',
      style: { width: '10%', minWidth: '150px' },
      headerClassName: 'tags_row4 align-right',
      rowClassName: 'align-right col-p-r-25 rem-pad-width',
      title: 'Created Date',
      sortable: true,
      render: (t, o) => {
        if (o.created_by === 'Clientbook') {
          if (o.active === 1) {
            return <span className="italic">{moment(t, 'YYYYMMDD').format('MM/DD/YYYY')}</span>;
          }
          return <span className="italic color-bdbdbd">{moment(t, 'YYYYMMDD').format('MM/DD/YYYY')}</span>;
        }
        return moment(t, 'YYYYMMDD').format('MM/DD/YYYY');
      },
    },
    {
      keyName: 'created_by',
      style: { width: '10%', minWidth: '150px', paddingRight: '22px' },
      headerClassName: 'align-left',
      rowClassName: 'align-left col-p-r-70 rem-pad-width no-wrap',
      render: (t, o) => {
        if (t) {
          if (o.created_by === 'Clientbook') {
            if (o.active === 1) {
              return <span className="italic">{t}</span>;
            }
            return <span className="italic color-bdbdbd">{t}</span>;
          }
          if (t.length > 20) {
            return `${t.slice(0, 20)}...`;
          }
          return t;
        }
        return '';
      },
      title: 'Created By',
      sortable: true,
    },
  ];

  const rowClickHandler = data => {
    if (lifeEventEnabled) {
      const { id, name, created_by, system_id, active } = data;

      setLifeEvId(id);
      setListId(id);
      setLifeEvName(name);
      setLifeEvAddEdit('Edit');
      setLifeCreatedBy(created_by);
      setLifeSystemId(system_id);
      // setRedirect(true);
      setListType('lifeEvent');
      setAutoReminders([]);
      setLifeEvEnabled(active);
      history.push(`/Configuration/life-events/edit-life-events/${id}`);
    }
  };

  const clearPref = () => {
    setLifeEvAddEdit('Add');
    setLifeEvId('');
    setLifeEvName('');
    setLifeEvEnabled(true);
    setListId('');
    setListType('lifeEvent');
    setAutoReminders([]);
  };

  return (
    <Layout
      title="Life Events"
      description="Customize life events and send personalized messages for anniversaries, birthdays, etc."
      classProps="standard-width-with-sidebar rem-pad-width"
    >
      <Table
        tableClassName="lg-table-sidebar rem-pad-width"
        data={tableData}
        tableHeaders={tableHeaders}
        searchable
        paginationV2
        optionalButton={
          <StyledLink to="/Configuration/life-events/add-life-events">
            <Button onclick={clearPref} classname="transparentButton">
              <FontAwesomeIcon
                style={{ width: '14px', height: '14px', marginBottom: '0px' }}
                icon={['far', 'ballot']}
                className="h-50-P"
                size="1x"
              />{' '}
              Add Life Event
            </Button>
          </StyledLink>
        }
        onRowClick={rowClickHandler}
        tableName={tableName}
      />
      <div style={{ marginTop: '3px' }} />
    </Layout>
  );
};
const mapStateToProps = state => ({
  ...state,
  pagination: getTablePaginationData(state, tableName),
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(LifeEvents);
