import { useCallback, useEffect, useState } from 'react';
import { actionTextAreaFileActions } from '../components/Layout/TextAreaWithActions/useActionTextAreaFiles';
import { getRequest } from '../core/apiRequests';

// If review link is not provided, it will be looked up using stores and storeId
const useMessageTemplates = ({
  togglePopup,
  merchantId,
  licenseKey,
  fileDispatch,
  setMessageText,
  clientName,
  stores,
  storeId,
  associateName,
  reviewLink,
  clientId,
  allTemplates = false,
  autoReminderId,
}) => {
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (!merchantId) return;
    (async () => {
      const req = {
        params:
          allTemplates && clientId
            ? ['chatMessages', merchantId, 'allTemplates', clientId]
            : ['chatMessages', merchantId, 'globalTemplates'],
        query: { licenseKey, storeId },
      };
      const data = await getRequest(req);
      if (data) {
        let table = data.filter(d => {
          if(d.scope === 'General') return true;
          if(autoReminderId) {
            return d.autoReminderId === autoReminderId;
          } else {
            false;
          }
        })
        setFilteredTableData(table);
        setTableData(table);
      }
    })();
  }, [merchantId, clientId, storeId, autoReminderId]);

  const handleSearchChange = useCallback(
    value => {
      if (value.length > 0) {
        const filtered = tableData.filter(data => data.msg.toLowerCase().includes(value.toLowerCase()));
        setFilteredTableData([...filtered]);
      } else {
        setFilteredTableData([...tableData]);
      }
    },
    [tableData, setFilteredTableData],
  );

  const templateReplace = useCallback(
    message => {
      const [clientFirstName] = clientName.split(' ');
      const link = (() => {
        if (reviewLink) return reviewLink;
        if (!stores || stores.length === 0 || !storeId) return null;
        const [store] = stores.filter(st => st.id === storeId);
        return store?.review_link;
      })();
      const replaced = message.replace(':name:', clientFirstName).replace(':associate:', associateName);
      if (!link) return setMessageText(replaced);
      setMessageText(replaced.replace(':review:', link));
    },
    [clientName, stores, storeId, setMessageText, associateName, reviewLink],
  );

  const onUpdateFile = useCallback(
    url => {
      if (!url)
        return fileDispatch({
          type: actionTextAreaFileActions.clear,
        });
      fileDispatch({
        type: actionTextAreaFileActions.setToSingleImageUrl,
        payload: url,
      });
    },
    [fileDispatch],
  );

  const onRowClick = useCallback(
    data => {
      togglePopup();
      templateReplace(data.msg);
      onUpdateFile(data.image_url);
      setFilteredTableData(tableData);
    },
    [togglePopup, templateReplace, onUpdateFile, tableData],
  );

  return {
    tableData: filteredTableData,
    handleSearchChange,
    onRowClick,
  };
};

export default useMessageTemplates;
