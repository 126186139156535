import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import Layout from '../../../Layout/StandardLayout';
import Button from '../../../Layout/Button';
import { getRequest } from '../../../../core/apiRequests';
import TitleDescription from '../../../Layout/DescriptionBox';
import Table from '../../../Layout/Table';
import { setReportAssoc } from '../../../../reducers/reports';
import { setAuthStoreId } from '../../../../reducers/auth';
import DropdownSelect from '../../../Layout/DropdownSelect';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import StyledLink from '../../../Layout/StyledLink';
import RangePicker from '../../../CustomizedAntDesign/RangePicker';
import '../reports.css';

const disabledDate = current => {
  return current && current > moment().endOf('day');
};

const RecentNotes = props => {
  const [dateRange, setDateRange] = useState([moment().subtract(1, 'months'), moment()]);
  const [tableData, setTableData] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [allAssoc, setAllAssoc] = useState([]);
  const associateOptions = allAssoc.map(associate => ({
    label: associate.fullName || `${associate.first_name || ''} ${associate.last_name || ''}`.trim(),
    value: associate.id,
  }));

  useEffect(() => {
    if (props.auth.merchantId) {
      const allAssociateOption = { id: 0, fullName: 'All Associates' };

      const reqObj = {
        params: ['report', props.auth.merchantId, props.auth.storeId || 0, 'clientByAction'],
        query: {
          userId: props.auth.userId,
          role: props.auth.role,
        },
      };

      const assoc_data = async () => {
        const data = await getRequest(reqObj);
        const sortedAscts = data?.sort((a, b) => {
          if (`${a.first_name || ''} ${a.last_name || ''}` > `${b.first_name || ''} ${b.last_name || ''}`) {
            return 1;
          }
          if (`${a.first_name || ''} ${a.last_name || ''}` < `${b.first_name || ''} ${b.last_name || ''}`) {
            return -1;
          }
          return 0;
        });
        setAllAssoc([allAssociateOption, ...sortedAscts]);
        setReportAssoc(0);
      };
      assoc_data();
    }
  }, [props.auth.storeId, props.auth.merchantId]);

  const requestDataSalesOpp = () => {
    setShowReport(false);
    const reqObj = {
      params: ['report', props.auth.merchantId, props.auth.storeId, 'recentNotes'],
      query: {
        startDt: moment(dateRange[0]).format('YYYYMMDD'),
        endDt: moment(dateRange[1]).format('YYYYMMDD'),
        associateId: props.reports.associate || '',
        storeAccess: props.auth.stores.filter(st => st.id !== 0).map(st => st.id),
      },
    };
    if (props.salesPipeline.store) {
      reqObj.query.selected_store_id = props.salesPipeline.store;
    }
    (async () => {
      const res = {};
      res.data = await getRequest(reqObj);
      setTableData(res.data || []);
      setShowReport(true);
    })();
  };

  const tableHeaders = [
    {
      keyName: 'created_date',
      headerClassName: 'row1',
      title: 'Date',
      style: { width: '10%', paddingTop: '80px' },
    },
    {
      keyName: 'note',
      headerClassName: 'row1',
      title: 'Note',
      style: { width: '35%', paddingTop: '80px' },
    },
    {
      keyName: 'activity',
      headerClassName: 'row1',
      title: 'Activity',
      style: { width: '15%', paddingTop: '80px' },
    },
    {
      keyName: 'associate',
      headerClassName: 'row1',
      title: 'Associate',
      style: { width: '20%', paddingTop: '80px' },
      render: (t, o) => (
        <StyledLink to={`/Associates/associate-details/${o.id}`}>
          {t || `${o.first_name || ''} ${o.last_name || ''}`.trim()}
        </StyledLink>
      ),
    },
    {
      keyName: 'client_name',
      headerClassName: 'row1',
      title: 'Client Name',
      style: { width: '20%', paddingTop: '80px' },
      render: (t, o) => <StyledLink to={`/Clients?client=${o.client_id}`}>{t.trim()}</StyledLink>,
    },
  ];

  return (
    <Layout
      title="Recent Notes"
      backArrow="/Home"
      description="Set your parameters and click on 'Run Report.'"
      classProps="standard-width-with-sidebar flex-col-center"
      styleProps={{ justifyContent: 'flex-start' }}
    >
      <div className="report-smaller-description">
        {' '}
        Running a report on Recent Notes allows you to see which associates are adding notes to their clients. Each note
        created by an associate counts as 1 in the report.{' '}
      </div>
      <div className="pos-rel w-100-P " style={{ maxWidth: '866px' }}>
        <TitleDescription
          title="Reporting Location"
          description="What store location would you like to run a report on?"
          input={
            <DropdownSelect
              isSearchable
              classProps="mq-w-200 w-267 align-left"
              options={props.auth.stores.map(st => ({
                label: st.name,
                value: st.id,
              }))}
              placeholder="Select a Store..."
              value={props.auth.storeId}
              onChange={obj => {
                setAuthStoreId(obj.value);
              }}
            />
          }
        />
        <TitleDescription
          title="Associate"
          description="Which associate's report would you like to run?"
          input={
            <DropdownSelect
              classProps="mq-w-200 w-267 align-left"
              options={associateOptions}
              placeholder="Select an Associate"
              value={props.reports.associate}
              onChange={obj => {
                setReportAssoc(obj.value);
              }}
            />
          }
        />

        <TitleDescription
          title="Date Range"
          input={
            <RangePicker
              format="MM/DD/YYYY"
              disabledDate={disabledDate}
              value={dateRange}
              onChange={setDateRange}
              ranges={{
                'Last 7 Days': [moment().subtract(7, 'd'), moment()],
                'Last 30 Days': [moment().subtract(30, 'd'), moment()],
                'Last 60 Days': [moment().subtract(60, 'd'), moment()],
                'Last 90 Days': [moment().subtract(90, 'd'), moment()],
              }}
              showToday
            />
          }
          className="report-date-picker"
        />

        <div className="report-asct-msg-row" style={{ maxWidth: '866px' }}>
          <Button id="RunRecentNotesReport" classname="darkBlueButton" onclick={e => requestDataSalesOpp(e)}>
            Run Report
          </Button>
        </div>
      </div>
      {showReport ? (
        <div className="w-100-P rem-pad-width maw-1200">
          <div
            className="import_export_group w-100-P"
            style={{
              justifyContent: 'flex-end',
              zIndex: '100',
              marginLeft: '0px',
              marginTop: '100px',
            }}
          >
            <img src="https://dashboard-v2-images.s3.amazonaws.com/ic-export.svg" alt="export" />

            <CSVLink
              data={tableData}
              filename="RecentNotes.csv"
              id="DownloadRecentNotesReport"
              className="exportButton m-right-15"
            >
              Download
            </CSVLink>
          </div>

          <Table
            tableClassName="maw-1200 rem-pad-width m-top-15 overflowX hide-scrollbar w-100-P"
            tableContainerClass="maw-1200 w-100-P rem-pad-width "
            style={{ marginTop: '-100px', width: '100%' }}
            data={tableData}
            tableRowClassName="col-p-l-25"
            tableHeaders={tableHeaders}
            tableName="RECENT_NOTES"
          />
        </div>
      ) : null}
      <SecondaryPanel
        overhead_title="Reports"
        title={['CLIENTELING', 'MESSAGING', 'Google Reviews', 'PAYMENTS', 'AUTOMATION', 'SALES OPPORTUNITY']}
        current="Recent Notes"
      />
    </Layout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  reports: state.reports,
  stores: state.stores,
  salesPipeline: state.salesPipeline,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RecentNotes);
