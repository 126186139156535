import React from 'react';
import Carousel from '../../../../../componentsV2/CustomizedAntDesign/Carousel';
import ProductsV2DetailsHeader from '../DetailsHeader';
import './styles.scss';

const ProductsV2Details = props => {
  const {
    loading,
    product,
    showDetailsDrawer,
    setShowDetailsDrawer,
    showClientCollectionModal,
    setShowClientCollectionModal,
    showShareProductModal,
    setShowShareProductModal,
    showDeleteProductModal,
    setShowDeleteProductModal,
    fetchProduct,
    handleOnSuccessSave
  } = props;

  return (
    <div className="products-v2-details">
      {loading && !product ? <div>Loading...</div> : null}

      {!loading && !product ? <div>No Product Selected</div> : null}

      {product ? (
        <>
          <ProductsV2DetailsHeader
            loading={loading}
            product={product}
            showDetailsDrawer={showDetailsDrawer}
            setShowDetailsDrawer={setShowDetailsDrawer}
            showClientCollectionModal={showClientCollectionModal}
            setShowClientCollectionModal={setShowClientCollectionModal}
            showShareProductModal={showShareProductModal}
            setShowShareProductModal={setShowShareProductModal}
            showDeleteProductModal={showDeleteProductModal}
            setShowDeleteProductModal={setShowDeleteProductModal}
            fetchProduct={fetchProduct}
            handleOnSuccessSave={handleOnSuccessSave}
          />

          <div className="products-v2-details__details">
            <div className="products-v2-details__details-left">
              <span className="products-v2-details__details-price">{product.msrp || product.price}</span>
              {product.sku || product.mpn ? (
                <span className="products-v2-details__details-ref">{product.sku || product.mpn}</span>
              ) : null}
            </div>

            <div className="products-v2-details__details-right">
              {product.posRefNum ? (
                <span className="products-v2-details__details-pos-ref-num">POS: {product.posRefNum}</span>
              ) : null}

              {product.qty !== null ? (
                <span className="products-v2-details__details-available">
                  <span className={`products-v2-details__details-${product.qty > 0 ? 'in-stock' : 'out-of-stock'}`}>
                    •
                  </span>

                  {product.qty > 0 ? 'In stock' : 'Out of Stock'}
                </span>
              ) : null}
            </div>
          </div>

          {product.description ? <div className="products-v2-details__description">{product.description}</div> : null}

          <Carousel images={product.images} />
        </>
      ) : null}
    </div>
  );
};

export default ProductsV2Details;
