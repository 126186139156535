import React, { useCallback, useEffect, useState } from 'react';
import { getClientCollections } from '../../../../../../api/collections';
import usePaginatedRequest from '../../../../../../hooks/usePaginatedRequest';
import Select from '../../../../../CustomizedAntDesign/Select';

const SearchClientCollectionsInput = ({ clientId, className, onSelect }) => {
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);

  const handleGetClientCollections = useCallback(
    request => {
      return getClientCollections({ ...request, clientId });
    },
    [clientId],
  );

  const { loading, results, setSearch } = usePaginatedRequest({
    limit: 50,
    mergeResults: true,
    requestMethod: handleGetClientCollections,
  });

  const handleSetOptions = useCallback(() => {
    const newOptions = results.map(item => ({
      value: item?.id?.toString(),
      label: item.name,
    }));

    setOptions(newOptions);
  }, [results]);

  const handleSelect = (_, option) => setValue(option);

  useEffect(() => {
    handleSetOptions();
  }, [results]);

  useEffect(() => {
    const selectedOption = results.find(e => e?.id?.toString() === value?.value);

    onSelect(selectedOption);
  }, [value]);

  const emptyOptions = !loading && !options.length;
  const placeholder = emptyOptions ? 'No Collections' : 'Search collections by name';

  return (
    <Select
      value={value}
      options={options}
      loading={loading}
      filterOption={false}
      onSearch={setSearch}
      onSelect={handleSelect}
      placeholder={placeholder}
      disabled={emptyOptions}
      className={className || ''}
    />
  );
};

export default SearchClientCollectionsInput;
