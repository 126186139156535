import React, { useCallback, useMemo, useState } from 'react';
import { Modal, Timeline, Typography } from "antd-v5";
import { useInfiniteQuery, useQuery } from 'react-query';
import { getRequest } from '../../../../../core/apiRequests';
import TimelineItem from '../../../../../componentsV2/Layout/Report/components/Timeline';
import { BellOutlined, BulbOutlined, UserOutlined, FileTextOutlined } from '@ant-design/icons';
import moment from 'moment';
import { dateNumberToMoment } from '../../../../../utils/dateNumberToMoment';
import { ClientActivity, Message, SalesOpportunity } from '../../../../../componentsV2/Layout/Report/components/Icons';
import { ACTIVITIES_TYPES_ASSO_REPORT } from '../../../../../constants';
import InfiniteScroll from 'react-infinite-scroll-component';

const PAGE_SIZE = 50;

const { Text } = Typography;
const getProps = (type) => {
  switch (type) {
    case ACTIVITIES_TYPES_ASSO_REPORT.chatMessages:
      return {
        title: 'Direct Messages',
        formatData: (data, filteredData) => filteredData.map(item => {
          const client_name = data?.find(row => row?.client_id?.toString() === item?.client_id?.toString())?.client_name || 'a Client';
          return {
            client_name,
            msg: null,
            date: item?.created_date
          }
        }),
        timelineTitle: ({date, client_name}) => (
          <>
            <span className="fw-600"> {client_name}</span> on
            <span className="fw-600"> {moment.utc(dateNumberToMoment(date)).format('MMM DD, YYYY')}</span>
          </>
        ),
        icon: <div style={{ background: "#FFF" }}>
            <Message style={{ color: '#000', fontSize: '18px', opacity: 0.45}} />
          </div>
      }
    case ACTIVITIES_TYPES_ASSO_REPORT.reminders:
      return {
        title: 'Reminders',
        formatData: (data, filteredData, associates) => {
          if(!data) return [];
          return data?.map(item => {
            const findReminder = filteredData[ACTIVITIES_TYPES_ASSO_REPORT.reminders]
              .find(row => row.reminder_id.toString() === item?.id?.toString());
            return {
              client_name: findReminder?.client_name || 'a Client',
              date: findReminder?.complete_date || findReminder?.due_date,
              completed: !!findReminder?.complete_date,
              msg: item.msg
            }
          }
        )},
        timelineTitle: ({client_name, date, completed}) => (
          <>
            <span className="fw-600">{completed ? 'Completed' : 'Skipped'} </span> reminder for
            <span className="fw-600"> {client_name}</span> on
            <span className="fw-600"> {moment.utc(dateNumberToMoment(date)).format('MMM DD, YYYY')}</span>
          </>
        ),
        icon: <BellOutlined style={{ color: '#000', opacity: 0.45, fontSize: '18px'}} />
      }
    case ACTIVITIES_TYPES_ASSO_REPORT.activities:
      return {
        title: 'Client Activities',
        formatData: (data, filteredData, associates) => data.map(item => {
          return {
            type: item?.activity_type,
            client_name: item?.client_name || 'a Client',
            date: item?.created_date,
            msg: item.notes
          }
        }),
        timelineTitle: ({client_name, date, type}) => (
          <>
            <span className="fw-600">{type} </span> for
            <span className="fw-600"> {client_name}</span> on
            <span className="fw-600"> {moment.utc(dateNumberToMoment(date)).format('MMM DD, YYYY')}</span>
          </>
        ),
        icon: <ClientActivity style={{ color: '#000', opacity: 0.45, fontSize: '18px'}} />
      }
    case ACTIVITIES_TYPES_ASSO_REPORT.newClients:
      return {
        title: 'New Clients',
        formatData: (data, filteredData, associates) => filteredData[ACTIVITIES_TYPES_ASSO_REPORT.newClients].map(item => {
          return {
            date: item?.completed || item?.created_date,
            client_name: item?.client_name || 'a Client'
          }
        }),
        timelineTitle: ({date, client_name}) => (
          <>
            Added
            <span className="fw-600"> {client_name}</span> on
            <span className="fw-600"> {moment.utc(dateNumberToMoment(date)).format('MMM DD, YYYY')}</span>
          </>
        ),
        icon: <UserOutlined  style={{ color: '#000', opacity: 0.45, fontSize: '18px'}} />
      }
    case ACTIVITIES_TYPES_ASSO_REPORT.salesOpps:
      return {
        title: 'Sales Opps',
        formatData: (data, filteredData, associates) => filteredData[ACTIVITIES_TYPES_ASSO_REPORT.salesOpps].map(item => {
          return {
            client_name: item?.client_name || 'a Client',
            amount: parseFloat(item?.amount || 0).toFixed(2),
            msg: `${item?.description} opportunity closing on ${moment.utc(dateNumberToMoment(item?.close_date)).format('MMM DD, YYYY')}`,
            date: item?.close_date
          }
        }),
        timelineTitle: ({date, client_name, amount}) => (
          <>
            <span className="fw-600"> {client_name}</span> has a
            <span className="fw-600"> ${amount} </span> sales opportunity
          </>
        ),
        icon: <SalesOpportunity  style={{ color: '#000', opacity: 0.45, fontSize: '18px'}} />
      }
    case ACTIVITIES_TYPES_ASSO_REPORT.aiInsights:
      return {
        title: 'AI Insights',
        formatData: (data, filteredData, associates) => data.map(item => {
          const findAiInsight = filteredData[ACTIVITIES_TYPES_ASSO_REPORT.aiInsights].find(row => row.ai_activity_id.toString() === item.ai_activity_id.toString());
          const associate = associates.find(associate => associate?.id?.toString() === findAiInsight?.associate_id?.toString());
          return {
            associate_name: (associate?.first_name || associate?.last_name) ?
              `${associate?.first_name || ''} ${associate?.last_name || ''}`.trim() :
              'an Associate',
            date: item?.completed || item?.created_date,
            completed: findAiInsight?.result_cd === 'COMPLETE',
            msg: item.msg.replaceAll('{{client_name}}', item?.client_name || 'a Client'),
            client_name: item?.client_name || 'a Client'
          }
        }),
        timelineTitle: ({associate_name, date, completed, client_name}) => (
          <>
            <span className="fw-600">{completed ? 'Completed' : 'Skipped'} </span> an AI Insight for
            <span className="fw-600"> {client_name}</span> on
            <span className="fw-600"> {moment.utc(dateNumberToMoment(date)).format('MMM DD, YYYY')}</span>
          </>
        ),
        icon: <BulbOutlined style={{ color: '#000', opacity: 0.45, fontSize: '18px'}} />
      }
    default:
      return {
        title: 'Unknown',
        timelineTitle: () => '',
        formatData: () => [],
        icon: null
      }
    }
}

const AssociateReportModal = ({open, onClose, type, period, filteredData, associates, associate_id}) => {

  const allData = useMemo(() => {
    return {
      [ACTIVITIES_TYPES_ASSO_REPORT.aiInsights]: filteredData[ACTIVITIES_TYPES_ASSO_REPORT.aiInsights].filter(item => item?.associate_id?.toString() === associate_id?.toString()),
      [ACTIVITIES_TYPES_ASSO_REPORT.activities]: filteredData[ACTIVITIES_TYPES_ASSO_REPORT.activities].filter(item => item?.associate_id?.toString() === associate_id?.toString()),
      [ACTIVITIES_TYPES_ASSO_REPORT.chatMessages]: filteredData[ACTIVITIES_TYPES_ASSO_REPORT.chatMessages].filter(item => item?.associate_id?.toString() === associate_id?.toString()),
      [ACTIVITIES_TYPES_ASSO_REPORT.newClients]: filteredData[ACTIVITIES_TYPES_ASSO_REPORT.newClients].filter(item => item?.associate_id?.toString() === associate_id?.toString()),
      [ACTIVITIES_TYPES_ASSO_REPORT.reminders]: filteredData[ACTIVITIES_TYPES_ASSO_REPORT.reminders].filter(item => item?.associate_id?.toString() === associate_id?.toString()),
      [ACTIVITIES_TYPES_ASSO_REPORT.salesOpps]: filteredData[ACTIVITIES_TYPES_ASSO_REPORT.salesOpps].filter(item => item?.associate_id?.toString() === associate_id?.toString()),
    }
  }, [associate_id, filteredData])

  const {
    title,
    timelineTitle,
    formatData,
    icon
  } = useMemo(() => getProps(type), [type]);

  const [page, setPage] = useState(1);

  const {data, isFetching, fetchNextPage} = useInfiniteQuery(['attributedMeta', allData, type], async ({pageParam = 1}) => {
    if(type === ACTIVITIES_TYPES_ASSO_REPORT.newClients) return [];
    if(type === ACTIVITIES_TYPES_ASSO_REPORT.chatMessages) return [];

    const reqObj = {
      params: ['report', 'attributedMeta'],
      query: {
        chatMessages: JSON.stringify([]),
        reminders: JSON.stringify(type === ACTIVITIES_TYPES_ASSO_REPORT.reminders ? [
          ...allData[ACTIVITIES_TYPES_ASSO_REPORT.reminders].map(item => item.reminder_id)
          .slice((pageParam - 1) * PAGE_SIZE, pageParam * PAGE_SIZE)
        ] : []),
        salesTransactions: JSON.stringify([]),
        aiInsights: JSON.stringify(type === ACTIVITIES_TYPES_ASSO_REPORT.aiInsights ? [
          ...allData[ACTIVITIES_TYPES_ASSO_REPORT.aiInsights].map(item => item.ai_activity_id)
          .slice((pageParam - 1) * PAGE_SIZE, pageParam * PAGE_SIZE)
        ] : []),
        clientActivities: JSON.stringify(type === ACTIVITIES_TYPES_ASSO_REPORT.activities ? [
          ...allData[ACTIVITIES_TYPES_ASSO_REPORT.activities].map(item => item.client_activity_id)
          .slice((pageParam - 1) * PAGE_SIZE, pageParam * PAGE_SIZE)
        ] : []),
      },
      loadingStatusOff: true
    };
    const response = await getRequest(reqObj);
    return response.map(item => ({
      ...item,
      associate_name: item.associate_name,
      date: item.date,
      completed: item.completed
    }))
  }, {
    refetchOnWindowFocus: false,
    getNextPageParam: (pages, ...rest) => {
      const add = rest.length ? rest[0].length : 0;
      return add + 1
    },
  })

  const {data: dataChatMessage, isFetching: isFetchingChatMessage, fetchNextPage: fetchNextPageChatMsg} = useInfiniteQuery(
    ['chatMessage', allData, type],
    async ({pageParam = 1}) => {
      if(type === ACTIVITIES_TYPES_ASSO_REPORT.chatMessages) {
        const reqObj = {
          params: ['report', 'associatePerformance', 'getClientsName'],
          query: {
            clientIds: JSON.stringify(
              allData[ACTIVITIES_TYPES_ASSO_REPORT.chatMessages]
                .map(item => item.client_id)
                .slice((pageParam - 1) * PAGE_SIZE, pageParam * PAGE_SIZE)
                .filter((value, index, self) => self.indexOf(value) === index)
            )
          },
          loadingStatusOff: true
        }
        return await getRequest(reqObj);
      };
      return [];
    },
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (pages, ...rest) => {
        return page + 1
      },
    }
  )


  const getAssociateName = useCallback((id) => {
    const findAssociate = associates.find(associate => associate?.id?.toString() === id.toString());
    return findAssociate ? `${findAssociate?.first_name || ''} ${findAssociate?.last_name || ''}`.trim() : 'an Associate';
  }, [associates])

  const items = useMemo(() => {
    if(type === ACTIVITIES_TYPES_ASSO_REPORT.chatMessages) {
      if(!dataChatMessage) return [];
      const allClients = dataChatMessage.pages.reduce((acc, page) => {
        if(!page) return acc;
        return [...acc, ...page];
      }, [])
      return formatData(allClients, allData[ACTIVITIES_TYPES_ASSO_REPORT.chatMessages]?.slice(0, page * PAGE_SIZE), associates)
    } else {
      if(!data) return [];
      const aggregatedData = data?.pages.reduce((acc, page) => {
        if(!page) return acc;
        return [...acc, ...page];
      }, [])
      return formatData(aggregatedData, allData, associates)
      .sort((a, b) => moment.utc(dateNumberToMoment(b.date)).diff(moment.utc(dateNumberToMoment(a.date))))
    }
  }, [data, allData, associates, dataChatMessage, page])

  const hasMore = useMemo(() => {
    return allData[type].length > items.length
  }, [allData[type], items])

  const nextData = useCallback(() => {
    if(type === ACTIVITIES_TYPES_ASSO_REPORT.chatMessages) {
      setPage(page + 1);
      fetchNextPageChatMsg();
    } else {
      fetchNextPage();
    }
  }, [type, page, fetchNextPage])



  return (
    <Modal
      title={associate_id ? getAssociateName(associate_id) :"Details"}
      open={open}
      onClose={onClose}
      onCancel={onClose}
      footer={null}
      styles={{ body: { maxHeight: '400px' } }}
      classNames={{ body: 'scrollbar-y'}}
    >
      <Text>{`${title}, ${period}`}</Text>
      <InfiniteScroll
        dataLength={items.length}
        height={360}
        next={nextData}
        hasMore={hasMore}
      >
        <Timeline
          className='m-top-16 m-left-4'
          pending={isFetching || isFetchingChatMessage ? 'Loading...' : null}
          items={items.map(item => (
            {
              color: 'gray',
              dot: icon,
              children: (
                <TimelineItem
                  description={item.msg}
                  title={timelineTitle({...item})}
                />
              ),
            }
          ))}
      />
      </InfiniteScroll>
    </Modal>
  );
}

export default AssociateReportModal;
