
import React, { createContext, useCallback, useContext, useState } from 'react';
import Axios from 'axios';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { getRequest, postRequest } from '../../core/apiRequests';
import sendActivityEvent from '../../components/Navigation_V2/Edison/sendActivityEvent';
import { updateVendorItemViewDate } from '../../components/Navigation_V2/Edison/apiRequests';

export const AIAssistantContext = createContext({});

const AIAssistantContextProvider = ({ children, authenticate, auth }) => {
  const [showLead, setShowLead] = useState(false);
  const [hasSeenEdison, setHasSeenEdison] = useState(null);
  const [leadObj, setLeadObj] = useState({});
  const [edisonTile, setEdisonTile] = useState('initialLoading');
  const [showEllipses, setShowEllipses] = useState('noShowEllipses');
  const [myClient, setMyClient] = useState(false);
  const [badgeLight, showBadgeLight] = useState(false);

  const vendorItemViewHandler = () => {
    updateVendorItemViewDate(leadObj?.vendorRecommendations, 'smart_assistant', 'vendor_item_recommended');
  };

  const getHeaderText = useCallback((clientName, type) => {
    switch (type) {
      case 'ready_to_buy':
        return `${clientName} is ready to buy!`;
      case 'anniversary':
        return `${clientName} is celebrating an anniversary!`;
      case 'spouse_birthday':
        return `${clientName} is celebrating a birthday soon!`;
      case 'birthday':
        return `${clientName} is celebrating a birthday soon!`;
      case 'jewelry_bridal_purchase':
        return `${clientName} recently purchased an engagement ring!`;
      case 'sales_opp_overdue':
        return `${clientName} has an overdue sales opportunity!`;
      case 'sales_opp_no_contact':
        return `Did you forget about the open sales opportunity for ${clientName}?`;
      case 'slipping_away':
        return `${clientName} is slipping away!`;
      case 'vip_needs_attention':
        return `${clientName} is a VIP and hasn't heard from you recently!`;
      case 'annual_spend_appreciation':
        return `${clientName} is one of your top customers over the last year!`;
      case 'new_client_purchase':
        return `${clientName} is a high-value, new customer!`;
      default:
        return `${clientName} is ready to buy!`;
    }
  }, []);

  const queryEdison = async (mainObj, prompt) => {
    try {
      if (auth.merchantId) {
        const reqObj = {
          params: ['ai', mainObj.id, 'regenerateEdisonMessage'],
          data: { prompt },
          loadingStatusOff: true,
        };
        const data = await postRequest(reqObj);
        if (Object.keys(data).length > 0) {
          const storeName =  auth?.stores?.find(store => store?.id === mainObj?.storeId)?.name || '';
          setLeadObj({
            ...mainObj,
            storeName,
            type: { ...mainObj.type, headerText: getHeaderText(mainObj.client.name, mainObj.type.code), message: data.message },
          });
          if (data.message !== mainObj?.type.message) {
            setShowEllipses('noShowEllipses');
          }
        } else {
          setShowEllipses('noShowEllipses');
        }
        return true;
      }
    } catch (e) {
      console.error('[queryEDISON] ', e);
      return false;
    }
  };

  // tracking AI
  const AIActivity = ({ eventType, msgRegen, msgEdit, resultCode }) => {
    const multiStore = auth.stores.length > 1;
    const aiBody = sendActivityEvent({
      eventType,
      merchantId: auth.merchantId,
      packageCode: auth.planCode,
      multiStore,
      storeId: auth.selectedStore,
      associateId: auth.userId,
      associateRole: auth.role.toLowerCase(),
      clientId: leadObj?.client?.id,
      activityId: leadObj?.id,
      activityType: leadObj?.type?.code,
      activityCreatedDateTime: leadObj?.createdDate,
      assignmentType: leadObj.assignedAssociate ? 'associate' : 'store',
      msgRegen,
      msgEdit,
      resultCode,
    });
    Axios({
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${auth.jwtToken}`,
      },
      Authorization: `Bearer ${auth.jwtToken}`,
      url: `${process.env.REACT_APP_CLIENTBOOK_API_URL}/api/v1/events/aiactivity`,
      data: aiBody,
    }).catch(err => console.error(err));
  };

  const {refetch: getLead} = useQuery(['getEdisonActivity', auth.selectedStore, auth.merchantId], async () => {
    const { selectedStore, stores } = auth;
    if (selectedStore === 0) {
      selectedStore = stores[1].id;
    }
    const reqObj = {
      params: ['ai', selectedStore, 'getEdisonActivity'],
      loadingStatusOff: true,
    };
    const data = await getRequest(reqObj);
    if (Object.keys(data).length > 0) {
      const edisonMessage = await queryEdison(
        {
          ...data,
          type: { ...data.type, headerText: getHeaderText(data?.client.name, data.type.code), message: '' },
        },
        data.type.prompt,
      );
      if (edisonMessage) {
        showBadgeLight(true);
        setMyClient(data.assignedAssociate);
        if (edisonTile === 'loadingComplete' || edisonTile === 'loadingSkipped') {
          setEdisonTile('edisonAI');
        }
        setHasSeenEdison(true);
      }
    } else {
      showBadgeLight(false);
      if (hasSeenEdison === true) {
        setEdisonTile('done');
      }
      setEdisonTile('noLead');
    }
  }, {
    enabled: authenticate && auth.merchantId && auth.stores.length > 1,
    refetchOnWindowFocus: false,
  });

  return (
    <AIAssistantContext.Provider
      value={{
        edisonTile,
        setShowLead,
        getLead,
        AIActivity,
        vendorItemViewHandler,
        leadObj,
        setEdisonTile,
        setLeadObj,
        myClient,
        showEllipses,
        setShowEllipses,
        queryEdison,
        showLead,
        badgeLight,
      }}
    >
      {children}
    </AIAssistantContext.Provider>
  );
};

export function useAIAssistant() {
  const context = useContext(AIAssistantContext);

  if (!context) {
    throw new Error('useAIAssistantContext must be used within an AIAssistantContext');
  }
  return context;
}

const mapStateToProps = state => ({ authenticate: state.auth.authenticated, auth: state.auth });
export default connect(mapStateToProps)(AIAssistantContextProvider);

