import React, { useCallback, useEffect, useState } from 'react';
import { Input, Modal, Select, Form, App } from "antd-v5";
import { ExclamationCircleOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import PhoneInput from '../../CustomizedAntDesign/PhoneInput';
import { useQuery } from 'react-query';
import { getRequest } from '../../../core/apiRequests';
import { connect } from 'react-redux';
import { createClient, mergeClient } from '../../../services/clients';
import { notificationSuccess } from '../../../core/actions';


function AddClientModal({open, setOpen, initialValues, auth, onSuccess, onError, hiddenFields = {}, requiredFields = ['name', 'storeId']}) {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if(initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
    return () => {
      form.resetFields();
    }
  }, [initialValues]);

  const {data: methods} = useQuery(['contact-methods', form.getFieldValue('storeId')], async () => {
    if(form.getFieldValue('storeId')) {
      const reqObj = {
        params: ['stores', 'contact-methods', auth.merchantId || 0, form.getFieldValue('storeId')],
        loadingStatusOff: true,
      };
      const data = await getRequest(reqObj);
      const filteredMethods = data?.filter(method => method.visible === 1);
      filteredMethods.sort((a, b) => {
        return b.isDefault - a.isDefault;
      });
      filteredMethods.sort((a, b) => {
        return b.enabled - a.enabled;
      });
      if(filteredMethods.length > 0 && !initialValues?.preferredContactType){
        form.setFieldValue('preferredContactType', filteredMethods[0]?.type.toUpperCase());
      }
      return filteredMethods.map(val => {
        return {
          label: `Contact Method: ${val.type}`,
          value: val.type.toUpperCase(),
        };
      });
    }
    return [];
  }, {
    refetchOnWindowFocus: false,
  })

  const handleOnCancel = useCallback(() => {
    form.resetFields();
    setOpen(false);
  }, [form]);

  async function handleMergeClient(newClient, existingClient) {
    const data = {
      id: existingClient.id,
      name: newClient?.name || existingClient?.name,
      mobile: newClient?.mobile || existingClient?.mobile,
      email: newClient?.email || existingClient?.email,
      preferredContactType: newClient?.preferredContactType || existingClient?.preferredContactType,
      storeId: newClient?.storeId || existingClient?.storeId,
    };
    const response = await mergeClient({
      data,
      loadingStatusOff: true,
    })
    if(onSuccess) {
      onSuccess(response);
    }
    notificationSuccess('Client Merged Successfully');
    form.resetFields();
    setLoading(false);
    setOpen(false)
  }

  const handleOnSave = useCallback(async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    try {
      await form.validateFields();
      let store_name = '';
      const store = auth.stores.find(st => st.id === values?.storeId);
      if(store) {
        store_name = store?.name || '';
      }
      const data = {
        ...values,
        mobile: parsePhoneNumber(values.mobile),
        store_name,
        associateId: auth.userId,
        merchantId: auth.merchantId,
      };
      const response = await createClient({
        data,
        loadingStatusOff: true,
        merchantId: auth.merchantId
      })
      if(onSuccess) {
        onSuccess(response);
      }
      notificationSuccess('Client Created Successfully');
      setLoading(false);
      setOpen(false);

    } catch (error) {
      setLoading(false);
      if(error?.response?.status === 409) {
        const existingClient = error?.response?.data?.data?.clients[0];
        modal.confirm({
          title: 'Overwrite existing client info?',
          okText: 'Overwrite existing client',
          icon: <ExclamationCircleOutlined />,
          width: 440,
          cancelText: 'Cancel',
          centered: true,
          async onOk() {
            return await handleMergeClient({
              ...values,
              mobile: values?.mobile ? parsePhoneNumber(values?.mobile) : null,
            }, {
              id: existingClient?.id,
              name: existingClient?.name,
              mobile: existingClient?.mobile,
              email: existingClient?.email,
              preferredContactType: existingClient?.preferredContactType,
              storeId: existingClient?.storeId,
            });
          },
          onCancel() {
            handleOnCancel();
          },
          content: (
            <div className='flex-col-start gap-24 m-btm-24'>
              <span className='fs-14' style={{opacity: 0.65}}>We found a client with the same phone or email as the one you created.</span>
              <ClientInfo
                title="NEW CLIENT"
                client={{
                  ...values,
                  mobile: values?.mobile ? parsePhoneNumber(values?.mobile) : null,
                }}
              />
              <ClientInfo
                title="EXISTING CLIENT"
                client={error?.response?.data?.data?.clients[0]}
              />
              <span className='fs-14' style={{opacity: 0.65}}>If you continue, the existing client’s information will be overwritten with the new client’s info.</span>
            </div>
          ),
        })
      } else {
        if(onError && !error?.errorFields) {
          form.resetFields();
          onError(error);
        }
      }
      setLoading(false);
    }
  }, [form, auth, onSuccess, onError, modal]);

  const isHidden = useCallback((field) => {
    if(hiddenFields[field]) {
      return { display: 'none' };
    }
    return {}
  }, [hiddenFields])

  return (
    <>
    {contextHolder}
    <Modal
      title="New Client"
      open={open}
      onOk={handleOnSave}
      onCancel={handleOnCancel}
      okText="Save"
      centered
      width={425}
      confirmLoading={loading}
      destroyOnClose={true}
    >
      <Form
        labelCol={{ span: 8 }}
        style={{ width: '100%' }}
        autoComplete="off"
        form={form}
        name="add-client-form"
      >
        <Form.Item
          name="storeId"
          validateTrigger="onSubmit"
          rules={[
            {
              required: requiredFields.includes('storeId'),
              message: 'Preferred Store is required',
            },
          ]}
          hidden={hiddenFields?.storeId}
        >
          <Select
            style={{ width: '100%' }}
            options={auth.stores
              .filter(s => s.id !== 0)
              .map((st, i) => ({
                label: `Preferred Store: ${st.name}`,
                value: st.id,
              }))
            }
            disabled={loading}
            placeholder="Preferred Store"
          />
        </Form.Item>
        <Form.Item
          name="name"
          validateTrigger="onSubmit"
          hidden={hiddenFields?.name}
          rules={[
            {
              type: 'string',
              min: 2,
              message: 'Please Enter a Valid Name',
            },
            {
              required: requiredFields.includes('name'),
              message: 'Name is required',
            }
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder='Name'
            disabled={loading}
            autoComplete='off'
            autoFocus={hiddenFields?.storeId}
          />
        </Form.Item>
        <Form.Item
          name="mobile"
          validateTrigger="onSubmit"
          hidden={hiddenFields?.mobile}
          rules={[
            {
              required: requiredFields.includes('mobile'),
              message: 'Phone Number is required',
            },
            () => ({
              validator(rule, value) {
                if(!value) {
                  return Promise.resolve();
                }
                if(value && (!value?.phoneNumber && !value?.areaCode || value?.valid())) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'The inserted phone number  is not valid !'
                );
              },
            }),
          ]}
        >
          <PhoneInput
            style={{
              width: '100%',
              borderRadius: '4px',
            }}
            country="us"
            required={false}
            placeholder="Phone Number"
            disabled={loading}
          />
        </Form.Item>
        <Form.Item
          name="email"
          validateTrigger="onSubmit"
          hidden={hiddenFields?.email}
          rules={[
            {
              type: 'email',
              message: 'Please Enter a Valid Email',
            },
            {
              required: requiredFields.includes('email'),
              message: 'Email is required',
            },
          ]}
        >
          <Input
            prefix={<MailOutlined />}
            placeholder="Email"
            type='email'
            disabled={loading}
          />
        </Form.Item>
        <Form.Item
          name="preferredContactType"
          validateTrigger="onSubmit"
          hidden={hiddenFields?.preferredContactType}
          rules={[
            {
              required: requiredFields.includes('preferredContactType'),
              message: 'Contact Method is required',
            },
          ]}
        >
          <Select
            style={{ width: '100%', ...isHidden('preferredContactType') }}
            options={methods}
            placeholder="Contact Method"
            disabled={loading}
          />
        </Form.Item>
      </Form>
    </Modal>

    </>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(AddClientModal);


function parsePhoneNumber(phoneNumber) {
  if(!phoneNumber) return null;
  if(typeof phoneNumber === 'string') {
    return phoneNumber.includes('+') ? phoneNumber : `+${phoneNumber}`;
  } else {
    return `+${phoneNumber.countryCode}${phoneNumber.areaCode}${phoneNumber.phoneNumber}`;
  }
}

const ClientInfo = ({client, title}) => {
  return (
    <div>
      <div className='fs-12'>{title}</div>
      <div>
        <span className='fs-14 fw-600'>{client?.name}</span>
        {client?.posRefNum && <span className='fs-14' opacity={0.65}>{` (${client?.posRefNum})`}</span>}
      </div>
      <div className='fs-12'>{client?.mobile}</div>
      <div className='fs-12'>{client?.email}</div>
    </div>
  )
}
