

import React, { useState } from 'react';
import { Select as AntDesignSelect } from 'antd-v5';
import './styles.css';

const Select = ({prefixIcon, className, dropdownClassName, style = {}, ...rest}) => {

  const [focus, setFocus] = useState(false);

  const searchClass = prefixIcon ? 'cb2-select__prefix' : '';
  const containerClass = `cb2-select ${searchClass} ${className || ''} `;

  return (
    <div className={`${containerClass} ${focus ? 'cb2-select-focus' : ''}`} style={style}>
      {prefixIcon || null}
      <AntDesignSelect
        style={{ width: '100%' }}
        {...rest}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </div>
  );
};

export default Select;
