import React, { useEffect } from 'react';
import StandardLayoutV2 from "../../../../componentsV2/Layout/StandardLayoutV2";
import { Button, Card } from 'antd-v5';
import ReportCard from './Card';
import { PurchaseOutline, MassMessages, SalesOpportunity, LightningBolt, MessageReviews, NewClient, PaymentRequests } from '../../../../componentsV2/Layout/Report/components/Icons'
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { InfoCircleFilled, TeamOutlined } from '@ant-design/icons';



const reports = ({auth}) =>  {
  return [
    {
      title: 'Sales',
      icon: <PurchaseOutline style={{ fontSize: '22px', marginRight: '8px' }}/>,
      description: 'Report of purchases with related Clientbook activities. Transactions include a timeline of Clientbook activities leading up to purchase.',
      path: '/Reports/sales',
      hidden: !auth?.pos_type
    },
    {
      title: 'Automations',
      icon: <LightningBolt style={{ fontSize: '22px', marginRight: '8px' }}/>,
      description: 'Report of all automated messages, reminders, and AI activities generated by Clientbook. Includes sales related to these activities.',
      path: '/Reports/automations'
    },
    {
      title: 'Mass Messages',
      icon: <MassMessages style={{ fontSize: '22px', marginRight: '8px' }}/>,
      description: 'Report of mass messages sent including size of audience, number of delivered messages and related sales.',
      path: '/Reports/mass-message'
    },
    {
      title: 'New Clients',
      icon: <NewClient style={{ fontSize: '22px', marginRight: '8px' }}/>,
      description: 'Report of all new clients through inbound channels or added by associates. Includes any related purchases for each client.',
      path: '/Reports/new-clients'
    },
    {
      title: 'Sales Pipeline',
      icon: <SalesOpportunity style={{ fontSize: '22px', marginRight: '8px' }}/>,
      description: 'Report of all sales pipeline with projected revenue and win rate. Includes assigned activities associated with Open Opportunities.',
      path: '/Reports/sales-opp'
    },
    {
      title: 'Google Reviews',
      icon: <MessageReviews style={{ fontSize: '22px', marginRight: '8px' }}/>,
      description: 'Report of all Google Review links sent and opened by store and associate. Includes a count of reviews received and average rating.',
      path: '/Reports/google-review'
    },
    {
      title: 'Payment Requests',
      icon: <PaymentRequests style={{ fontSize: '22px', marginRight: '8px' }}/>,
      description: 'Report of all payment links sent and their current status. Includes payment details and ability to export bank transactions for reconciliation.',
      path: '/Reports/payment-requests',
      hidden: !(Number(auth.payments) > 0)
    },
    {
      title: 'Associate Performance',
      icon: <TeamOutlined style={{ fontSize: '22px', marginRight: '8px' }} />,
      description: 'Report of all associate activity including messages sent, reminders completed, and client activities performed.',
      path: '/Reports/associate-performance',
      hidden: !auth?.launchFlagsForNav?.associatePerformanceReport
    },
  ]
}

const LandingPage = (props) => {
  const history = useHistory();

  useEffect(() => {
    if(props?.auth?.launchFlagsForNav?.reportsPage !== undefined) {
      if(!props?.auth?.launchFlagsForNav?.reportsPage) {
        history.push('/Home')
      }
    }
  }, [props?.auth?.launchFlagsForNav?.reportsPage])

  return (
    <StandardLayoutV2
      title="Reports"
      classProps="flex-col-left "
      styleProps={{ justifyContent: 'flex-start' }}
      maxWidth="1200px"
      actions={<Button onClick={() => history.push('/Home')}>View Legacy Reports</Button>}
    >
      <Card
        style={{ width: '100%' }}
        styles={{
          body: {
            padding: '16px 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }
        }}
      >
        <InfoCircleFilled style={{ opacity: 0.45, marginRight: 8, fontSize: 18	}} />
        Reports update daily, so you might see a 24-hour delay for new information. Related sales include any Clientbook activity from the 14 days before each sale.
      </Card>
      <div className='flex-row-nospacebetween-wrap w-100-P m-top-10' style={{ gap: '15.5px'}}>
        {reports({
          auth: props.auth
        }).filter(report => !report?.hidden).map(report => (
          <ReportCard
            key={report.path}
            title={report.title}
            icon={report.icon}
            description={report.description}
            path={report.path}
          />
        ))}
      </div>
    </StandardLayoutV2>
  )
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(LandingPage);
