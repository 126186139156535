/* eslint-disable indent */
import React, { useCallback, useState } from 'react';
import { message } from 'antd-v5';
import { addProductToClientCollection } from '../../../../../../api/collections';
import Modal from '../../../../../CustomizedAntDesign/Modal/Modal';
import SearchClientCollectionsInput from '../SearchClientCollectionsInput';
import ProductDetailsModalFooter from '../ModalFooter';
import { handleCatchError } from '../../shared/catchError';
import ClientCard from '../ClientCard';
import { addVendorItemToClientCollection } from '../../../../../../api/vendors';
import './styles.css';
import SearchClientsInputNew from '../../../../../../componentsV2/CustomizedAntDesign/SearchClientsInput';

const ClientCollectionModal = ({ productId, vendorId, visible, setVisible }) => {
  const [client, setClient] = useState(null);
  const [collection, setCollection] = useState(null);
  const [loading, setLoading] = useState(false);

  const disableSaveButton = !client || !collection;

  const handleClose = () => {
    setVisible(false);
    setClient(null);
    setCollection(null);
  };

  const handleSaveProduct = useCallback(() => {
    const requestData = {
      clientId: client.id,
      collectionId: collection.id,
      data: { productId },
    };

    return addProductToClientCollection(requestData);
  }, [client, collection, productId]);

  const handleSaveVendorItem = useCallback(() => {
    const requestData = {
      vendorItemIds: [productId],
    };

    return addVendorItemToClientCollection(client.id, collection.id, requestData);
  }, [client, collection, productId]);

  const handleSave = useCallback(async () => {
    setLoading(true);

    try {
      if (vendorId) {
        await handleSaveVendorItem();
      } else {
        await handleSaveProduct();
      }

      message.success('Product added to collection');
    } catch (error) {
      message.error('Failed to add product to collection');

      handleCatchError(error);
    } finally {
      handleClose();
      setLoading(false);
    }
  }, [handleSaveProduct, handleSaveVendorItem, vendorId]);

  return (
    <Modal open={visible} footer={null} closable={false} className="cb-client-collection-modal" destroyOnClose>
      <h2 className="cb-client-collection-modal__title">Add to collection</h2>

      {client ? (
        <>
          <ClientCard client={client} />

          <SearchClientCollectionsInput
            clientId={client.id}
            onSelect={setCollection}
            className="cb-client-collection-modal__collection-input"
            requireCollections
          />
        </>
      ) : (
        <SearchClientsInputNew
          onSelect={setClient}
          requireCollections
        />
      )}

      <ProductDetailsModalFooter
        onClose={handleClose}
        onSave={handleSave}
        loading={loading}
        disabled={disableSaveButton}
      />
    </Modal>
  );
};

export default ClientCollectionModal;
