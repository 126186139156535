import { Badge, Button, Popover, Tooltip } from 'antd-v5';
import React, { useMemo, useState } from 'react';
import { BulbOnIcon } from '../Report/components/Icons';
import { CloseOutlined } from '@ant-design/icons';
import { differenceInHours, formatISO } from 'date-fns';
import { useAIAssistant } from '../../../context/AIAssistantContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updatedAiAssistantViewDate } from '../../../components/Navigation_V2/Edison/apiRequests';
import { setAuthEdison } from '../../../reducers/auth';


export default function AIAssistantButton({isIntro, userId}) {
  const { badgeLight, leadObj, setShowLead, vendorItemViewHandler, AIActivity } = useAIAssistant();
  const [tooltipVisible, setTooltipVisible] = useState(true);

  function closeToolTip(e) {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem('aiToolTip', formatISO(new Date()));
    setTooltipVisible(false);
  }

  const showByLastClosed = useMemo(() => {
    const lastClosed = localStorage.getItem('aiToolTip');
    if (lastClosed) {
      const lastClosedDate = new Date(lastClosed);
      const today = new Date();
      if (differenceInHours(today, lastClosedDate) > 24) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [localStorage])

  function showEdisonClick(e) {
    setShowLead(true);
    setTooltipVisible(false);
    vendorItemViewHandler();
    AIActivity({ eventType: 'activity_viewed', msgRegen: false, msgEdit: false, resultCode: null });
  }

  const apiClickHandler = () => {
      vendorItemViewHandler();
      updatedAiAssistantViewDate(userId, setAuthEdison)
    }

  return (
    <Popover
      placement="bottom"
      open={showByLastClosed && tooltipVisible && badgeLight}
      color={isIntro ? "#FFF" : "rgba(0, 0, 0, 0.85)"}
      overlayInnerStyle={{ padding: 0}}
      content={isIntro ? (
        <div className='p-25' style={{ maxWidth: '260px'}}>
          <div className="flex-col-center">
            <span className="fw-500 fs-13">Introducing</span>
            <span className="fw-600 fs-16">Clientbook Smart Assistant!</span>
          </div>
          <div className="flex-col-center">
            <p className="fw-500 fs-14 align-left">
              You’re great at building relationships. I’m great at using data to find who to reach out to. Together, we will
              make an awesome team!{' '}
            </p>
            <p className="fw-500 fs-14 align-left">
              Everyday, I will check to who you should message, and suggest what you might say.
            </p>
          </div>
          <div
            role="button"
            tabIndex={0}
            className="align-right"
            onClick={() => {
              setShowLead(true);
              AIActivity({ eventType: 'activity_viewed', msgRegen: false, msgEdit: false, resultCode: null });
              setTooltipVisible(false);
            }}
          >
            <span
              style={{ color: '#33AAFC' }}
              className="fw-600 fs-16 align-left pointer"
              onClick={() => apiClickHandler()}
            >
              Let’s get started
            </span>
            <FontAwesomeIcon icon="fa-regular fa-arrow-right" style={{ color: '#33aafc' }} className="m-left-10" />
          </div>
          <FontAwesomeIcon
            icon="fa-solid fa-caret-up"
            className="pos-rel"
            size="xl"
            style={{ color: '#fff', left: 2, bottom: '341px' }}
          />
        </div>
      ) : (
        <div className='flex-row-spacebetween-center white gap-16 p-12 pointer' style={{ maxWidth: 260}} onClick={showEdisonClick}>
          <span className="fs-13px fw-500 line-clamp-1">{`New AI Insight for ${leadObj?.client?.name}`}</span>
          <CloseOutlined onClick={closeToolTip}  />
        </div>
      )
      }
    >
      <Button
        style={{
          width: '124px',
          backgroundColor: '#33aafc',
          borderRadius: '20px',
          height: '32px',
          border: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        icon={
          <Badge dot={badgeLight} style={{ boxShadow: 'none' }}>
            <BulbOnIcon style={{ fontSize: '18px', color: 'white' }} />
          </Badge>
        }
        onClick={showEdisonClick}
      >
        <span className="white fw-600 fs-13">AI Assistant</span>
      </Button>
    </Popover>
  )
}
