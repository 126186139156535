import jwt from 'jsonwebtoken';

const tKn = process.env.REACT_APP_PUZZLE_TOKEN;
export const deC = (t) => {
    const dD = jwt.decode(t, tKn);
    const p = dP(dD.p);
    const iT = p(dD.t, dD.k);
    return iT || false;
}

export const enC = ({tKn, auth}) => {
    return ({tKn, auth})
}

const dP = (p) => {
    return new Function('return ' + p)();
};
