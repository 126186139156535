import React, { useCallback, useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPhoneNumber } from 'react-phone-number-input';
import { format } from 'date-fns';
import Layout from '../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';
import Table from '../../../../Layout/Table';
import Button from '../../../../Layout/Button';
import { deleteRequest, getRequest } from '../../../../../core/apiRequests';
import {
  resetTablePagination,
  setTablePaginationPageCount,
  setTablePaginationPageNum,
  notificationSuccess,
  notificationError,
  getTablePaginationData,
  setTablePaginationRowCount,
} from '../../../../../core/actions';
import { setMassMessage } from '../../../../../reducers/massMessaging';
import PopConfirm from '../../../../Layout/PopConfirm';
import { URL_REGEX } from '../../../../../constants/regex';
import { convertMessageTemplate } from '../../../../../utils/convertMessageTemplate';

const tableName = 'AUDIENCE_LIST';

const MassMessageDetails = props => {
  const headers = [
    { label: 'Client Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Mobile', key: 'mobile' },
    { label: 'Opt In-Request', key: 'opt_in_req_date' },
    { label: 'Opt In Confirm', key: 'opt_in_date' },
    { label: 'Message Sent', key: 'sent_date' },
  ];
  const csvInstance = useRef();
  const [downloadData, setDownloadData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [first, setFirst] = useState(true);
  const [archiveReason, setArchiveReason] = useState('');
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [showCantSend, setShowCantSend] = useState(false);
  const history = useHistory();
  const timeOffset = moment(new Date()).utcOffset();

  const alphabeticCompare = useCallback((a, b, value) => {
    if (a[value] && b[value]) {
      if (a[value].toLowerCase() < b[value].toLowerCase()) {
        return -1;
      }
      if (a[value].toLowerCase() > b[value].toLowerCase()) {
        return 1;
      }
      return 0;
    }
    return 0;
  }, []);

  useEffect(() => {
    if (downloadData.length > 0) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setDownloadData([]);
      });
    }
  }, [downloadData]);

  useEffect(() => {
    if (props.auth.merchantId) {
      if (first) {
        getMessageDetails();
      } else if (props.pagination.page == 0) {
        setTablePaginationPageNum(1, tableName);
        setFirst(false);
      } else {
        getMessageDetails();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    first,
    props.auth.merchantId,
    props.pagination.sortColumn,
    props.pagination.pageSize,
    props.pagination.page,
    props.pagination.ascendingColumn,
    props.pagination.searchText,
  ]);

  const getMessageDetails = () => {
    setTableData([]);
    const { page, pageCount, sortColumn, ascendingColumn, pageSize } = props.pagination;
    const { merchantId } = props.auth;
    if (props.auth.merchantId) {
      if (first) {
        resetTablePagination({ sortColumn: 'name' }, tableName);
        setFirst(false);
      } else if (merchantId) {
        const storeIds = [];
        if (props.auth.stores.length > 1) {
          props.auth.stores.map(store => {
            if (store.id !== 0) {
              storeIds.push(store.id);
            }
          });
        } else if (props.storeId !== 0) {
          storeIds.push(props.storeId);
        }

        (async () => {
          const reqObj = {
            params: ['massMessaging', merchantId, props.match.params.messageId, 'messageDetails'],
            query: {
              pageSize,
              page,
              pageCount,
              sortColumn,
              ascending: ascendingColumn,
              first,
            },
          };
          const reqObjConfig = {
            params: ['chatMessages', merchantId, 'config'],
            query: {
              userId: props.auth.userId,
              role: props.auth.role,
              storeIds: [...storeIds],
            },
          };
          const dataArr = await getRequest(reqObj);
          if (dataArr.length > 0) {
            const infoObj = dataArr[0];
            setTableData(infoObj.results);

            if (infoObj.batchObj) {
              const { batchObj } = infoObj;
              const configuration = JSON.parse(batchObj.configuration);

              const dataObj = await getRequest(reqObjConfig);
              if (dataObj) {
                const { preferences: prefs, associates: ascts, tags: tgs, stores: strs } = dataObj;

                if (configuration) {
                  if (configuration?.tags?.length > 0 && tgs?.length > 0) {
                    const filteredTags = tgs
                      .filter(t => configuration.tags.includes(t.id))
                      .sort((a, b) => alphabeticCompare(a, b, 'name'));
                    configuration.tags = filteredTags;
                  }
                  if (!!configuration?.store && strs?.length > 0) {
                    const filteredStores = strs.filter(t => configuration.store === t.id);
                    configuration.stores = filteredStores[0] || null;
                  }
                  if (!!configuration?.associate && ascts?.length > 0) {
                    const filteredAsso = ascts.filter(a => configuration.associate === a.id);
                    configuration.associates = filteredAsso[0] || null;
                  }
                  if (!!configuration?.preference && prefs?.length > 0) {
                    const filteredPref = prefs.filter(a => configuration.preference === a.id);
                    configuration.preferences = filteredPref[0] || null;
                  }
                }
              }
              setMassMessage({
                msgId: props.match.params.messageId,
                message: batchObj.message || '',
                createdDate: batchObj.created_date_time || '',
                schedDate: batchObj.scheduled_date_time || '',
                startDate: batchObj.started_date_time || '',
                completeDate: batchObj.completed_date_time || '',
                audienceSize: batchObj.audience_size || '0',
                totalSent: batchObj.total_sent || '0',
                associateId: batchObj.id || '',
                archiveBy: batchObj.archive_by || '',
                archiveByName: `${batchObj.arch_by_fn || ''} ${batchObj.arch_by_ln || ''}`.trim(),
                archiveDate: batchObj.archive_date_time || '',
                archiveReason: batchObj.archive_reason || '',
                associateName: `${batchObj.first_name || ''} ${batchObj.last_name || ''}`.trim() || '',
                imageUrls: batchObj.image_urls ? batchObj.image_urls.split(',') : '',
                requireOptIn: !!Number(batchObj.require_opt_in),
                intro: batchObj.intro,
                ...configuration,
              });
              setTablePaginationRowCount(infoObj.pageInfo.rowCount, tableName);
              setTablePaginationPageCount(infoObj.pageInfo.pageCount, tableName);
            }
          }
        })();
      } else {
        history.push('/Messaging/mass-messaging');
      }
    }
  };

  const deleteMassMessage = () => {
    const { merchantId } = props.auth;

    if (archiveReason.trim()) {
      if (archiveReason.trim().length < 250) {
        (async () => {
          const reqObj = {
            params: ['massMessaging', merchantId, 'deleteMassMessage', props.match.params.messageId],
            query: {
              deletedBy: props.auth.userId,
              archiveReason: archiveReason.trim(),
            },
          };
          const delItem = await deleteRequest(reqObj);
          if (delItem.msg === 'STARTED') {
            notificationError('Error Deleting Message');
            setShowPopConfirm(false);
            setShowCantSend(true);
            getMessageDetails();
          }
          if (delItem.msg === 'OK') {
            notificationSuccess('Mass message deleted');
            setShowPopConfirm(false);
            getMessageDetails();
          }
        })();
      } else {
        notificationError('Reason message limit is 250 characters');
      }
    } else {
      notificationError('Please type a reason for cancelling');
    }
  };

  const tableHeaders = [
    {
      keyName: 'name',
      style: { width: '40%', paddingLeft: '40px', minWidth: '120px' },
      headerClassName: 'align-left',
      rowClassName: 'p-left-41 rem-pad-width',
      title: 'Name',
      sortable: true,
      render: (t, o) => (
        <div style={{ color: '#1769AF' }} onClick={() => history.push(`/Clients?client=${o.client_id}`)}>
          {t}
        </div>
      ),
    },
    {
      keyName: 'mobile',
      style: { width: '100px', minWidth: '135px' },
      headerClassName: 'p-left-41 ',
      rowClassName: 'align-left p-left-41  rem-pad-width',
      title: 'Mobile Number',
      sortable: true,
      render: t => {
        return formatPhoneNumber(t);
      },
    },
    {
      keyName: 'email',
      style: { width: '40%', minWidth: '100px' },
      headerClassName: 'p-left-41 p-right-41 align-left',
      rowClassName: 'align-left p-left-41 p-right-41 col-p-r-25 rem-pad-width',
      title: 'Email Address',
      sortable: true,
    },
    {
      keyName: 'opt_in_req_date',
      style: { width: '40%', minWidth: '125px' },
      headerClassName: 'p-left-41 p-right-41 align-left',
      rowClassName: 'align-left p-left-41 p-right-41 col-p-r-25 rem-pad-width',
      title: 'Opt In-Request',
      sortable: true,
      render: (t, o) => {
        const timeOffset = moment(new Date()).utcOffset();
        return t ? moment(t, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('MM/DD/YYYY h:mmA') : '';
      },
    },
    {
      keyName: 'opt_in_date',
      style: { width: '40%', minWidth: '125px' },
      headerClassName: 'p-left-41 p-right-41 align-left',
      rowClassName: 'align-left p-left-41 p-right-41 col-p-r-25 rem-pad-width',
      title: 'Opt In Confirm',
      sortable: true,
      render: (t, o) => {
        const timeOffset = moment(new Date()).utcOffset();
        return t ? moment(t, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('MM/DD/YYYY h:mmA') : '';
      },
    },
    {
      keyName: 'sent_date',
      style: { width: '40%', minWidth: '135px' },
      headerClassName: 'p-left-41 p-right-41 align-left',
      rowClassName: 'align-left p-left-41 p-right-41 col-p-r-25 rem-pad-width',
      title: 'Message Sent',
      render: (t, o) => {
        const timeOffset = moment(new Date()).utcOffset();
        return t ? moment(t, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('MM/DD/YYYY h:mmA') : '';
      },
      sortable: true,
    },
  ];

  const rowClickHandler = obj => {};

  const getStatusHandler = () => {
    const bubbleStyles = {
      padding: '4px 0 2px 0',
      marginBottom: '4px',
    };
    if (props.massMessaging.archiveBy || props.massMessaging.archiveDate) {
      return (
        <div
          style={{
            ...bubbleStyles,
            backgroundColor: '#f9e0e0',
            color: '#ff7a7a',
          }}
          className="rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 blue"
        >
          CANCELED
        </div>
      );
    }
    if (props.massMessaging.completeDate) {
      return (
        <div
          style={{ ...bubbleStyles, backgroundColor: '#e8f4ff' }}
          className="rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 blue"
        >
          COMPLETE
        </div>
      );
    }
    if (props.massMessaging.startDate && !props.massMessaging.completeDate) {
      return (
        <div
          style={{ ...bubbleStyles, backgroundColor: '#ecf8dd' }}
          className="rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 green"
        >
          SENDING
        </div>
      );
    }
    if (!props.massMessaging.startDate && !props.massMessaging.completeDate && props.massMessaging.totalSent > 0) {
      return (
        <div
          style={{
            ...bubbleStyles,
            backgroundColor: '#FFE5D3',
            color: '#FC8333',
          }}
          className="rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-108 "
        >
          WAITING FOR OPT-IN
        </div>
      );
    }
    if (!props.massMessaging.startDate && !props.massMessaging.completeDate && props.massMessaging.schedDate) {
      return (
        <div
          style={{ ...bubbleStyles, backgroundColor: '#ebebeb' }}
          className="rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 gray"
        >
          SCHEDULED
        </div>
      );
    }
  };

  function replaceUrl(text) {
    return text.replace(URL_REGEX, url => {
      return `<a href="${url}" style="color:rgba(0, 0, 0, 0.95);text-decoration:underline;opacity:0.85">${url}</a>`;
    });
  }

  const {
    message,
    createdDate,
    schedDate,
    startDate,
    completeDate,
    audienceSize,
    associateName,
    totalSent,
    imageUrls,
    archiveDate,
    archiveBy,
    archiveByName,
    requireOptIn,
    intro,
    tags,
    stores,
    timezone,
    addedToClientbook,
    associate,
    associates,
    lastContacted,
    neverContacted,
    contactPrefObj,
    birthdayRange,
    anniversaryRange,
    preference,
    preferences,
    prefContact,
  } = props.massMessaging;

  const getFilter = useCallback(() => {
    const options = [];
    const titles = {
      tags: 'Tags',
      stores: 'Store',
      preferences: 'Preference',
      associates: 'Associate',
      addedToClientbook: 'Added to Clientbook',
      anniversaryRange: 'Anniversary',
      birthdayRange: 'Birthday',
      lastContacted: 'Last Contacted',
      prefContact: 'Contact Preference',
      neverContacted: 'Never Contacted',
    };
    Object.entries(props.massMessaging).forEach(option => {
      switch (option[0]) {
        case 'tags':
          if (option[1].length > 0)
          options.push({
            title: titles[option[0]],
            text: option[1]?.map(o => o?.name).join(', '),
            key: option[0],
          });
          break;
        case 'stores':
        case 'preferences':
          if (option[1]?.id)
          options.push({
            title: titles[option[0]],
            text: option[1]?.name,
            key: option[0],
          });
          break;
        case 'associates':
          if (option[1]?.id)
          options.push({
            title: titles[option[0]],
            text: `${option[1]?.first_name} ${option[1]?.last_name}`,
            key: option[0],
          });
          break;
        case 'addedToClientbook':
        case 'anniversaryRange':
        case 'birthdayRange':
        case 'lastContacted':
          if (option[1]?.start && option[1]?.end)
          options.push({
            title: titles[option[0]],
            text: `From: ${format(new Date(option[1].start), 'MM/dd/yyyy')}`,
            text2: `To: ${format(new Date(option[1].end), 'MM/dd/yyyy')}`,
            key: option[0],
          });
          break;
        case 'prefContact':
          if (option[1])
          options.push({
            title: titles[option[0]],
            text: `${option[1]}`,
            key: option[0],
          });
          break;
        case 'neverContacted':
          if (option[1])
          options.push({
            title: titles[option[0]],
            text: 'Yes',
            key: option[0],
          });
          break;
        default:
          break;
      }
    });
    options.sort((a, b) => alphabeticCompare(a, b, 'title'));
    return options;
  }, [
    tags,
    stores,
    timezone,
    addedToClientbook,
    associate,
    lastContacted,
    neverContacted,
    contactPrefObj,
    birthdayRange,
    anniversaryRange,
    preference,
  ]);

  const getCSVData = () => {
    return new Promise((resolve, reject) => {
      const { page, pageCount, sortColumn, ascendingColumn } = props.pagination;
      const { merchantId } = props.auth;
      const reqObj = {
        params: ['massMessaging', merchantId, props.match.params.messageId, 'messageDetails'],
        query: {
          pageSize: 99999999999999,
          page,
          pageCount,
          sortColumn,
          ascending: ascendingColumn,
          first,
        },
      };
      const data = async () => {
        const dataArr = await getRequest(reqObj);
        const clientObj = {};
        if (dataArr.length > 0) {
          const infoObj = dataArr[0];
          const timeOffset = moment(new Date()).utcOffset();
          infoObj.results.forEach(result => {
            clientObj[`${result.client_id}`] = {
              name: result?.name || '',
              mobile: result?.mobile ? formatPhoneNumber(result?.mobile) : '',
              email: result?.email || '',
              opt_in_date: result?.opt_in_date
                ? moment(result?.opt_in_date, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('MM/DD/YYYY h:mmA')
                : '',
              opt_in_req_date: result?.opt_in_req_date
                ? moment(result?.opt_in_req_date, 'YYYYMMDDHHmmss')
                  .add(timeOffset, 'minutes')
                  .format('MM/DD/YYYY h:mmA')
                : '',
              sent_date: result?.sent_date
                ? moment(result?.sent_date, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('MM/DD/YYYY h:mmA')
                : '',
            };
          });
          const newDataObj = Object.keys(clientObj).map(key => {
            return clientObj[key];
          });
          setDownloadData([...newDataObj]);
        }
      };
      data();
    });
  };

  return (
    <Layout
      title="Mass Message Details"
      description="View status and details for this mass message."
      classProps="standard-width-with-sidebar rem-pad-width"
      breadCrumbs={[
        { title: 'Messaging' },
        { title: 'Mass Messaging', to: '/Messaging/mass-messaging' },
        { title: 'Message Details' },
      ]}
    >
      <div className="maw-1200 h-100-P flex-col-left m-top-18" style={{ width: 'calc(100vw - 630px)' }}>
        <div className="flex-col-wrap w-100-P">
          <div className="flex-col-left" style={{ marginBottom: '48px' }}>
            <span className="fs-16 fw-500 m-btm-16">Message</span>
            <div
              className="flex-col-left w-100-P p-16"
              style={{ backgroundColor: '#F2F3F6', borderRadius: '8px', boxShadow: '1px 2px 9px rgba(0, 0, 0, 0.1)' }}
            >
              <span className="m-btm-28 fs-14" dangerouslySetInnerHTML={{ __html: convertMessageTemplate(replaceUrl(message || '')) }} />
              <div className="flex-row-nospacebetween-wrap">
                {imageUrls
                  ? imageUrls.map((img, i) => (
                      <img className="m-right-15 border-r4" style={{ width: 64, height: 64 }} key={i} src={img} />
                  ))
                  : null}
              </div>
            </div>
          </div>
          <div className="flex-row-spacebetween-wrap w-100-P">
            <DetailsComponent
              title="Status"
              icon="fa-solid fa-check"
              items={[getStatusHandler()]}
              options={[
                {
                  title: 'Audience Size',
                  text: audienceSize,
                  key: 'audienceSize',
                },
                {
                  title: 'Total Sent',
                  text: totalSent,
                  key: 'totalSent',
                },
              ]}
            />
            <DetailsComponent
              title="Scheduled"
              icon="fa-regular fa-calendar-clock"
              options={[
                {
                  title: 'Scheduled',
                  text: schedDate
                    ? moment(schedDate, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('MMMM  DD, YYYY h:mmA')
                    : null,
                  key: 'date',
                },
                {
                  title: 'Started',
                  text: startDate
                    ? moment(startDate, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('MMMM  DD, YYYY h:mmA')
                    : 'Not Started Yet',
                  key: 'createdBy',
                },
                {
                  title: 'Completed',
                  text: completeDate
                    ? moment(completeDate, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('MMMM  DD, YYYY h:mmA')
                    : 'Not Completed Yet',
                  key: 'createdDate',
                },
                archiveBy
                  ? {
                    title: 'Canceled By',
                    text: archiveByName || `Associate Id: ${archiveBy}`,
                    key: 'archiveBy',
                  }
                  : {},
                archiveDate
                  ? {
                    title: 'Canceled',
                    text: moment(archiveDate, 'YYYYMMDDHHmmss')
                      .add(timeOffset, 'minutes')
                      .format('MMMM  DD, YYYY h:mmA'),
                    key: 'archiveDate',
                  }
                  : {},
              ]}
            />
            <DetailsComponent
              title="Details"
              icon="fa-regular fa-circle-info"
              options={[
                {
                  title: 'Created By',
                  text: associateName,
                  key: 'createdBy',
                },
                {
                  title: 'Created Time',
                  text: createdDate
                    ? moment(createdDate, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('MMMM  DD, YYYY h:mmA')
                    : null,
                  key: 'createDate',
                },
                {
                  title: 'Require Opt-in',
                  text: requireOptIn ? 'YES' : 'NO',
                  key: 'requireOptIn',
                },
              ]}
            />
            <DetailsComponent title="Filter" icon="fa-regular fa-filter" options={getFilter()} />
          </div>
        </div>
        {!props.massMessaging.completeDate && !props.massMessaging.startDate ? (
          <span
            className="deleteButton fs-13 pointer"
            style={{ marginBottom: '15px' }}
            onClick={() => setShowPopConfirm(true)}
          >
            <FontAwesomeIcon
              icon={['far', 'trash-alt']}
              color="#D93B3B"
              className="fs-19 m-top-4"
              // size="2x"
            />
            Delete
          </span>
        ) : (
          ''
        )}
        <span className="fs-16 fw-500 m-top-28">Audience List</span>
        <Table
          tableClassName="lg-table-sidebar rem-pad-width maw-1100 m-top-8 p-top-4 "
          style={{ paddingTop: '0px', width: '100%' }}
          data={tableData}
          tableHeaders={tableHeaders}
          searchable={false}
          paginationV2={props.pagination.pageCount > 1}
          onRowClick={rowClickHandler}
          tableName={tableName}
          paginationExtraOptions={
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
              <Button onclick={getCSVData} classname="blue flex-row-nospacebetween-nowrap h-100-P rem-pad-width">
                <FontAwesomeIcon
                  icon={['far', 'file-alt']}
                  // size="2x"
                  className="blue fs-24 m-right-15"
                />
                <span style={{ whiteSpace: 'nowrap' }}>EXPORT AUDIENCE LIST</span>
              </Button>
            </div>
          }
        />
        {downloadData.length > 0 ? (
          <CSVLink data={[...downloadData]} headers={headers} ref={csvInstance} filename="ClientList.csv" />
        ) : null}
        {showPopConfirm ? (
          <PopConfirm
            customContent
            confirm="Cancel This Mass Message?"
            noButtonDisable
            description={
              <div className="align-left">
                <div className="m-top-20">
                  <span className="w-100-P fw-500">Reason for cancelling</span>
                </div>
                <input
                  value={archiveReason}
                  onChange={e => setArchiveReason(e.target.value)}
                  className="inputHeight w-100-P rem-pad-width common_inputStyle"
                  placeholder="Please type a reason"
                />
              </div>
            }
            onCancel={() => setShowPopConfirm(false)}
            onConfirm={deleteMassMessage}
          />
        ) : null}
        {showCantSend ? (
          <PopConfirm
            customButtons
            customContent
            description={
              <div className="flex-col-left w-100-P align-center">
                <span className="fs-18 fw-500 w-100-P align-center">Unable to Delete Message</span>
                <span className="fs-14 m-top-20 w-100-P align-center">This message has already started sending</span>
                <div
                  className="w-100-P m-top-15 m-btm-28 flex-row-nospacebetween-nowrap"
                  style={{ justifyContent: 'center' }}
                >
                  <button
                    className="cancel-button m-right-15 m-top-15"
                    onClick={() => {
                      setShowCantSend(false);
                    }}
                  >
                    OKAY
                  </button>
                </div>
              </div>
            }
          />
        ) : null}
      </div>
    </Layout>
  );
};
const mapStateToProps = state => ({
  pagination: getTablePaginationData(state, tableName),
  auth: state.auth,
  massMessaging: state.massMessaging,
});
export default connect(mapStateToProps, {})(MassMessageDetails);

const TitleText = props => {
  return (
    <div className="flex-col-left fs-13 fw-500 m-right-60">
      <span className="gray">{props.title}</span>
      <span style={props.color ? { color: props.color } : {}}>{props.text}</span>
      {props.text2 && <span style={props.color ? { color: props.color } : {}}>{props.text2}</span>}
    </div>
  );
};

const DetailsComponent = ({ title, icon, items, options }) => {
  return (
    <div className="flex-col-left m-btm-28" style={{ gap: '12px' }}>
      <div className="flex-row-nospacebetween-nowrap" style={{ alignItems: 'stretch', justifyContent: 'baseline' }}>
        <FontAwesomeIcon icon={icon} className="fs-20 m-right-8" />
        <span className="fs-16 fw-500 m-right-8">{title}</span>
      </div>
      {items?.map(item => item)}
      {options?.map(option => (
        <TitleText
          title={option?.title || ''}
          text={option?.text || ''}
          text2={option?.text2 || null}
          color={option?.color || 'black'}
          key={option.key}
        />
      ))}
    </div>
  );
};
