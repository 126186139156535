import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { differenceInDays, endOfDay, format, formatISO, startOfDay, subDays } from 'date-fns';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Report from '../../../../componentsV2/Layout/Report';
import filterByDate from '../../../../componentsV2/Layout/Report/helpers/filterByDate';
import { addLoadingList, removeLoadingList, setLoadingDescription } from '../../../../reducers/notification';
import { filterList } from './helpers/filterList';
import { colorHelp, statusHelp } from './helpers/tableHelpers';
import {
  getBankTransactionCSV,
  getPaymentActivityCSV,
  getPaymentMerchantData,
  getPaymentReport,
  getPaymentReportGraph,
} from './helpers/paymentRequestsAPI';
import PaymentRequestsExpandableRow from './components/PRTExpandableRow';
import { updateRequestHeaders } from '../../../../utils/paymentHttpRequest';
import useSearchParams from '../../../../hooks/useSearchParams';
import formatNumberAsCurrency from '../../../../utils/formatNumberAsCurrency';

const PaymentRequest = ({ auth }) => {
  const [bankAccounts, setBankAccounts] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [paymentRequestData, setPaymentRequestData] = useState([]);

  const [dateRange, setDateRange] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [pageSizeTable, setPageSizeTable] = useState(20);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    bankAccount: [],
    store: [],
    associate: [],
    statuses: [],
  });

  const { setValue: setSearchParams, getAll } = useSearchParams();
  const params = getAll();

  useEffect(() => {
    const paramsFilters = {
      bankAccount: params?.bankAccount ? params?.bankAccount : [],
      statuses: params?.statuses ? params?.statuses : [],
      store: params?.store ? params?.store : [],
      associate: params?.associate ? params?.associate : [],
    };
    setFilters(paramsFilters);
    if (params?.startDate && params?.endDate) {
      setDateRange([new Date(params.startDate), new Date(params.endDate)]);
    } else {
      setDateRange([startOfDay(subDays(new Date(), 90)), endOfDay(new Date())]);
    }
  }, [window.location.search]);

  const getDefaultPeriod = useMemo(() => {
    if (params?.startDate && params?.endDate) {
      if (differenceInDays(new Date(params.endDate), new Date(params.startDate)) === 90) {
        return '90days';
      }
      return `${format(new Date(params.startDate), 'MM/dd/yyyy')} - ${format(new Date(params.endDate), 'MM/dd/yyyy')}`;
    }
    return '90days';
  }, []);

  const handleOnDateRangeChange = useCallback(dates => {
    setSearchParams('startDate', formatISO(dates[0]));
    setSearchParams('endDate', formatISO(dates[1]));
  }, []);

  const options = useMemo(() => {
    const statusFormatted = statuses
      .map(status => statusHelp(status))
      .filter((st, index, arr) => arr.indexOf(st) === index)
      .filter(st => st);
    return [
      {
        title: 'Bank Account',
        key: '0-0',
        value: 'bankAccount',
        children: bankAccounts?.map(bank => ({
          title:
            bank.bankAccountNickname !== ''
              ? bank.bankAccountNickname
              : `${bank.bankAccountType}-${bank.bankAccountLastFour}`,
          value: bank.paymentsMerchantId,
          key: `0-0-${bank.paymentsMerchantId}`,
        })),
      },
      {
        title: 'Status',
        key: '0-1',
        value: 'statuses',
        children: statusFormatted?.map(bank => ({
          title: bank,
          value: bank,
          key: `0-1-${bank}`,
        })),
      },
    ];
  }, [statuses, bankAccounts]);

  const handleFilterChange = useCallback(
    value => {
      const updatedFilters = {
        bankAccount: [],
        statuses: [],
        store: [],
        associate: [],
      };
      value.forEach(item => {
        if (item[0] && item[1]) {
          updatedFilters[item[0]] = [...updatedFilters[item[0]], item[1]];
        } else {
          updatedFilters[item[0]] = [];
        }
      });
      Object.keys(updatedFilters).forEach(key => {
        setSearchParams(key, updatedFilters[key].join(','));
      });
    },
    [filters],
  );

  const removeFilter = useCallback(
    (key, value) => {
      const updatedFilters = { ...filters };
      updatedFilters[key] = updatedFilters[key].filter(filter => filter !== value);
      Object.keys(updatedFilters).forEach(key => {
        setSearchParams(key, updatedFilters[key].join(','));
      });
    },
    [filters],
  );

  useEffect(() => {
    if (auth.merchantId) {
      handleInitialData();
    }
  }, [auth.merchantId]);

  const handleInitialData = async () => {
    addLoadingList('paymentsReportInitial');
    setLoadingDescription("Sit tight! We're compiling your report.");
    updateRequestHeaders({
      merchantId: auth.merchantId,
      associateId: auth.userId,
      token: auth.token,
    });
    const [bankData, paymentReqs] = await Promise.all([getPaymentMerchantData({}), getPaymentReport({})]);
    removeLoadingList('paymentsReportInitial');
    setLoadingDescription(null);
    const statusSupported = bankData?.data?.statusSupported || [];
    const bankAcnts = bankData?.data?.bankAccounts || [];
    setStatuses([...statusSupported]);
    setBankAccounts([...bankAcnts]);
    const paymentReqsFormatted = paymentReqs?.data?.map(paymentReq => ({
      ...paymentReq,
      account: paymentReq?.account || `${paymentReq?.account_type || ''} - ${paymentReq?.account_last_four || ''}`,
    }));
    setPaymentRequestData(paymentReqsFormatted);
  };

  const filteredData = useMemo(() => {
    return filterList({
      paymentRequests: paymentRequestData,
      filters,
      startDate: dateRange[0],
      endDate: dateRange[1],
      assocStores: auth.stores,
    });
  }, [paymentRequestData, filters, dateRange]);

  const graphData = useMemo(() => {
    const paidData =
      filteredData
        ?.filter(payment =>
          ['PAID_MANUALLY', 'PAID_BY_CC', 'PAID_WITH_APPLE_PAY', 'PAID_WITH_GOOGLE_PAY'].includes(payment.status),
        )
        ?.map(item => ({
          date: item.paid,
          totalAmount: item.amount,
          total: 1,
          status: item.status,
          storeId: item.storeId,
          accountId: item.accountId,
          sentById: item.sentById,
        })) || [];
    const sentData =
      filteredData?.map(item => ({
        date: item.sent,
        totalAmount: item.amount,
        total: 1,
        status: item.status,
        storeId: item.storeId,
        accountId: item.accountId,
        sentById: item.sentById,
      })) || [];
    return {
      Sent: sentData,
      Paid: paidData,
    };
  }, [filteredData, dateRange, filters]);

  const stats = useMemo(() => {
    const totalSent = graphData.Sent;
    const totalPaid = graphData.Paid;
    return [
      {
        title: 'Requests Sent',
        value: formatNumberAsCurrency(totalSent.reduce((acc, curr) => acc + Number(curr.totalAmount), 0).toFixed(2)),
        description: <span className="fs-14 mid-gray m-top-3">{totalSent.length || 0} requests sent</span>,
      },
      {
        title: 'Paid',
        value: formatNumberAsCurrency(totalPaid.reduce((acc, curr) => acc + Number(curr.totalAmount), 0).toFixed(2)),
        description: <span className="fs-14 mid-gray m-top-3">{totalPaid.length || 0} requests paid</span>,
      },
    ];
  }, [graphData, dateRange, filters]);

  const paymentRequestColumns = useMemo(() => {
    const storeColumn =
      auth.stores.filter(st => st.id !== 0).length > 1
        ? [
          {
            title: 'Store',
            dataIndex: 'storeName',
            key: 'storeName',
          },
        ]
        : [];
    return [
      {
        title: 'Sent',
        dataIndex: 'sent',
        key: 'sent',
        sorter: (a, b) => (moment(a?.sent).isBefore(b?.sent) ? -1 : 1),
        render: s => moment(s).format('MMM DD, YYYY'),
        defaultSortOrder: 'descend',
      },
      {
        title: 'Sent by',
        dataIndex: 'sentBy',
        key: 'sentBy',
        render: (s, o) => (!o.sentByFirstName && !o.sentByLastName) ?
        'An Associate' :
        `${o.sentByFirstName || ''} ${o.sentByLastName || ''}`.trim(),
      },
      ...storeColumn,
      {
        title: 'Client',
        dataIndex: 'clientName',
        key: 'clientName',
        sorter: (a, b) => a.clientName.localeCompare(b.clientName),
        render: (_, row) => <Link to={`/Clients?client=${row.clientId}`}>{row.clientName}</Link>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => a.status.localeCompare(b.status),
        render: s => (
          <div className="add-edit-wrap">
            <span
              style={{ margin: '7px 10px 0px 0px', width: '6px', height: '6px', backgroundColor: colorHelp(s) }}
              className="circle"
            />
            <span>{statusHelp(s)}</span>
          </div>
        ),
      },
      {
        title: 'Paid',
        dataIndex: 'paid',
        key: 'paid',
        render: s => (s ? moment(s).format('MMM DD, YYYY') : ''),
      },
      {
        title: 'Account',
        dataIndex: 'account',
        key: 'account',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: s => (s ? formatNumberAsCurrency(s) : '-'),
      },
    ];
  }, [auth.stores]);

  const paymentActivityCSV = async () => {
    const bankAccountsToSend =
      filters.bankAccount.length > 0 ? filters.bankAccount : bankAccounts.map(bank => bank.paymentsMerchantId);
    const storesToSend =
      filters.store.length > 0 ? filters.store : auth.stores.filter(store => store.id !== 0).map(store => store.id);

    const csvParams = {
      sortColumn: 'lastUpdated',
      store: storesToSend,
      associate: filters.associate,
      status: filters.bankStatus,
      dateFrom: format(new Date(dateRange[0]), 'yyyyMMdd'),
      dateTo: format(new Date(dateRange[1]), 'yyyyMMdd'),
      bankAccount: bankAccountsToSend,
    };
    await getPaymentActivityCSV({ params: csvParams });
  };

  const bankTransactionCSV = async () => {
    const bankAccountsToSend =
      filters.bankAccount.length > 0 ? filters.bankAccount : bankAccounts.map(bank => bank.paymentsMerchantId);
    const csvParams = {
      dateFrom: format(new Date(dateRange[0]), 'yyyyMMdd'),
      dateTo: format(new Date(dateRange[1]), 'yyyyMMdd'),
      bankAccount: bankAccountsToSend,
    };
    await getBankTransactionCSV({ params: csvParams });
  };

  const handleExpandRow = useCallback(
    (expand, record) => {
      let updatedExpandedRowKeys = [...expandedRowKeys];
      if (expand) {
        updatedExpandedRowKeys.push(record.paymentRequestId);
        setExpandedRowKeys(updatedExpandedRowKeys);
      } else {
        updatedExpandedRowKeys = updatedExpandedRowKeys.filter(key => key !== record.paymentRequestId);
        setExpandedRowKeys(updatedExpandedRowKeys);
      }
    },
    [expandedRowKeys],
  );

  return (
    <Report
      title="Payment Requests"
      stats={stats}
      options={options}
      hasCSVExport={false}
      handleFilterChange={handleFilterChange}
      filters={filters}
      removeFilter={removeFilter}
      onDateRangeChange={handleOnDateRangeChange}
      dataRange={dateRange}
      defaultPeriodValue={getDefaultPeriod}
      table={filteredData}
      columns={paymentRequestColumns}
      extraButtons={[
        {
          label: 'Export Payment Links',
          onClick: () => paymentActivityCSV(),
          icon: <DownloadOutlined />,
        },
        {
          label: 'Export Bank Transactions',
          onClick: () => bankTransactionCSV(),
          icon: <DownloadOutlined />,
        },
      ]}
      tablesProps={{
        rowKey: 'paymentRequestId',
        pagination: {
          showSizeChanger: true,
          showTotal: total => `Total ${total} items`,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSizeTable(pageSize);
          },
          pageSize: pageSizeTable,
          page,
        },
        expandable: {
          expandedRowRender: record => <PaymentRequestsExpandableRow record={record} />,
          rowExpandable: record => record.name !== 'Not Expandable',
          defaultExpandedRowKeys: ['0'],
          onExpand: (expanded, record) => handleExpandRow(expanded, record),
          expandedRowKeys,
          expandRowByClick: true,
        },
      }}
      graphData={graphData}
    />
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PaymentRequest);
