import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { startSocket } from './socketInit';
import {
  messageHandler,
  notificationHandler,
  connectionHandler,
  modifyMessageStatus,
  facebookHandler,
  googleHandler,
  instagramHandler,
} from './controller/socketRouter';
import { useLayout } from '../../../context/Layout';

let socket;

const SocketHandler = props => {
  const history = useHistory();
  const {addNewMessageId} = useLayout();

  const { userId, token, merchantId, newMessages } = props;

  useEffect(() => {
    async function initSocket() {
      if (!userId && socket) {
        socket.removeEventListener('message');
        socket.removeEventListener('notify');
        socket.removeEventListener('connecting'); // yeah I dono either if this is required, I had to do it for message and notify to go away
        socket.removeEventListener('connect_failed'); // yeah I dono either if this is required, I had to do it for message and notify to go away
        socket.removeEventListener('connect'); // yeah I dono either if this is required, I had to do it for message and notify to go away
        socket.removeEventListener('disconnect'); // yeah I dono either if this is required, I had to do it for message and notify to go away
        socket.removeEventListener('status'); // yeah I dono either if this is required, I had to do it for message and notify to go away
        socket = null; // because setting it to null was NOT enough to wipe the socket object clean

      }

      if (userId && token && !socket) {
        socket = await startSocket(props.userId, merchantId, token);

        /* socket.on('connecting', () => {}); */
        /* socket.on('connect_failed', () => {}); */

        socket.on('connect', () => {
          connectionHandler('success', 'Connected');
          console.log('🚀 ~ connect');
        });

        socket.on('disconnect', () => {
          connectionHandler('warning', 'Connection Lost');
          console.log('🚧 ~ disconnect');
        });

        socket.on('message', data => {
          console.log('📩 ~ message', data);
          messageHandler(data, history, {...props, addNewMessageId}, 'sms');
        });

        socket.on('facebook', data => {
          console.log('📩 ~ facebook', data);
          facebookHandler(data, history, props, 'facebook');
        });

        socket.on('instagram', data => {
          console.log('📩 ~ instagram', data);
          instagramHandler(data, history, props, 'instagram');
        });

        socket.on('google', data => {
          console.log('📩 ~ google');
          googleHandler(data, history, props, 'google');
        });

        socket.on('notify', data => {
          notificationHandler(data);
          console.log('📢 ~ notify', data);
        });

        socket.on('status', data => {
          modifyMessageStatus(data);
          console.log('📊 ~ status', data);
        });

        socket.onAny((event, ...args) => {
          console.log('🔥 ~ onAny', event);
        });
      }
    }

    initSocket();
  }, [userId, merchantId, token, newMessages]);

  return <div />;
};

const mapStateToProps = state => ({
  ...state.auth,
  newMessages: state.navigation.newMessages,
});

export default connect(mapStateToProps)(SocketHandler);
