import React, { useEffect, useRef } from 'react';
import { Route } from 'react-router-dom';
import { postRequest } from '../apiRequests';
import { connect } from 'react-redux';

const CBRoute = props => {
  const isMounted = useRef(null);

  useEffect(() => {
    if (props.hasOwnProperty('displayName')) {
      const displayName = props.displayName;
      if (displayName && displayName !== 'Component' && displayName !== 'Login') {
        const reqObj = {
          params: ['merchant', 'mixpanel', 'locationMetric'],
          query: { location: displayName },
          errors: 'Error sending metrics data',
          loadingStatusOff: true,
        };
        if(props.authenticate) {
          postRequest(reqObj);
        }
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [props.displayName, props.authenticate]);

  return <Route {...props} />;
};
const mapStateToProps = state => ({ authenticate: state.auth.authenticated });
export default connect(mapStateToProps)(CBRoute);
