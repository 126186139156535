import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd-v5';
import { differenceInDays, endOfDay, format, formatISO, isBefore, startOfDay, subDays } from 'date-fns';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import Report from '../../../../componentsV2/Layout/Report';
import usePaginatedRequest from '../../../../hooks/usePaginatedRequest';
import { getGoogleLocationReview, getGoogleReview } from '../../../../api/report';
import StyledLink from '../../../Layout/StyledLink';
import useSearchParams from '../../../../hooks/useSearchParams';
import { useQuery } from 'react-query';
import { getRequest } from '../../../../core/apiRequests';
import moment from 'moment';
import { addLoadingList, removeLoadingList, setLoadingDescription } from '../../../../reducers/notification';

const GoogleReviews = ({ auth }) => {
  const options = useMemo(
    () => [
      {
        title: 'Delivery Method',
        key: '0-0',
        value: 'deliveryMethod',
        children: [
          {
            key: '0-0-0',
            title: 'Sent by Person',
            value: 'associate',
          },
          {
            key: '0-0-1',
            title: 'Sent by Automation',
            value: 'clientbook',
          },
        ],
      },
    ],
    [],
  );
  const [filters, setFilters] = useState({
    deliveryMethod: [],
    store: [],
    associate: [],
  });
  const [dateRange, setDateRange] = useState([subDays(new Date(), 90), new Date()]);
  const [expandedRowKeys, setExpandedRowKeys] = useState(
    auth.stores.filter(st => st.id !== 0).length > 1 ? [] : auth.stores.filter(st => st.id !== 0).map(st => st.id),
  );
  const [pageSizeTable, setPageSizeTable] = useState(10);
  const [page, setPage] = useState(1);
  const [associates, setAssociates] = useState([]);

  const { setValue: setSearchParams, getAll } = useSearchParams();
  const params = getAll();

  useEffect(() => {
    const paramsFilters = {
      deliveryMethod: params?.deliveryMethod ? params?.deliveryMethod : [],
      store: params?.store ? params?.store : [],
      associate: params?.associate ? params?.associate : [],
    };
    setFilters(paramsFilters);
    if (params?.startDate && params?.endDate) {
      setDateRange([new Date(params.startDate), new Date(params.endDate)]);
    } else {
      setDateRange([startOfDay(subDays(new Date(), 90)), endOfDay(new Date())]);
    }
  }, [window.location.search]);

  const getDefaultPeriod = useMemo(() => {
    if (params?.startDate && params?.endDate) {
      if (differenceInDays(new Date(params.endDate), new Date(params.startDate)) === 90) {
        return '90days';
      }
      return `${format(new Date(params.startDate), 'MM/dd/yyyy')} - ${format(new Date(params.endDate), 'MM/dd/yyyy')}`;
    }
    return '90days';
  }, []);

  const handleOnDateRangeChange = useCallback(dates => {
    setSearchParams('startDate', formatISO(dates[0]));
    setSearchParams('endDate', formatISO(dates[1]));
  }, []);

  const handleFilterChange = useCallback(
    value => {
      const updatedFilters = {
        deliveryMethod: [],
        store: [],
        associate: [],
      };
      value.forEach(item => {
        if (item[0] && item[1]) {
          updatedFilters[item[0]] = [...updatedFilters[item[0]], item[1]];
        } else {
          updatedFilters[item[0]] = [];
        }
      });
      Object.keys(updatedFilters).forEach(key => {
        setSearchParams(key, updatedFilters[key].join(','));
      });
    },
    [filters],
  );

  const removeFilter = useCallback(
    (key, value) => {
      const updatedFilters = { ...filters };
      updatedFilters[key] = updatedFilters[key].filter(filter => filter !== value);
      Object.keys(updatedFilters).forEach(key => {
        setSearchParams(key, updatedFilters[key].join(','));
      });
    },
    [filters],
  );

  const { data: sentGoogleReviews, isFetching: isFetchingSentGoogleReviews } = useQuery('sentGoogleReviews', async () => {
    const response = await getRequest({
      params: ['report', 'sentGoogleReviews']
    })
    return response
  }, {
    enabled: auth.merchant_id,
    refetchOnWindowFocus: false,
    initialData: []
  })

  const { data: reportGoogleReviews, isFetching: isFetchingReportGoogleReviews } = useQuery('reportGoogleReviews', async () => {
    const response = await getRequest({
      params: ['report', 'reportGoogleReviews']
    })
    return response
  }, {
    enabled: auth.merchant_id,
    refetchOnWindowFocus: false,
    initialData: []
  });

  useEffect(() => {
    if (isFetchingReportGoogleReviews || isFetchingSentGoogleReviews) {
      setLoadingDescription("Sit tight! We're compiling your report.");
      addLoadingList('googleReport');
    } else {
      removeLoadingList('googleReport');
      setLoadingDescription(null);
    }
  }, [isFetchingReportGoogleReviews, isFetchingSentGoogleReviews]);

  const filteredSentGoogleReviews = useMemo(() => {
    if (sentGoogleReviews) {
      const fixAssociates = sentGoogleReviews.map(review => {
        if(!review?.associate) {
          const associate = associates.find(assoc => assoc.id === review.associate_id);
          return {
            ...review,
            associate: associate ? `${associate?.first_name || ''} ${associate?.last_name || ''}` : 'Unknown',
          }
        } else {
          return review;
        }
      })
      return fixAssociates.filter(review => {
        const deliveryMethod = filters.deliveryMethod.length ? filters.deliveryMethod : ['associate', 'clientbook'];
        const filterTimeSpan = moment(review.created_at).isBetween(
              moment(dateRange[0]),
              moment(dateRange[1]),
              null,
              '[]',
            );
        return (
          deliveryMethod.includes(review.review_request_origin) &&
          (!filters.store.length || filters.store.includes(review.store_id.toString())) &&
          (!filters.associate.length || filters.associate.includes(review.associate_id.toString())) &&
          filterTimeSpan
        );
      });
    }
    return [];
  }, [
    sentGoogleReviews,
    filters.deliveryMethod,
    filters.store,
    filters.associate,
    dateRange,
    associates
  ])

  const filteredReportGoogleReviews = useMemo(() => {
    if (reportGoogleReviews) {
      return reportGoogleReviews.filter(review => {
        const filterTimeSpan = moment(review.review_create_time).isBetween(
          moment(dateRange[0]),
          moment(dateRange[1]),
          null,
          '[]',
        );
        return (
          (!filters.store.length || filters.store.includes(review.store_id.toString())) &&
          filterTimeSpan
        );
      });
    }
    return [];
  }, [
    reportGoogleReviews,
    filters.deliveryMethod,
    filters.store,
    filters.associate,
    dateRange
  ])

  const columns = useMemo(() => {
    return [
      {
        title: 'Sender',
        dataIndex: 'sender',
        key: 'sender',
        sorter: (a, b) => a.sender.localeCompare(b.sender),
      },
      {
        title: 'Sent',
        dataIndex: 'sent',
        key: 'sent',
        sorter: (a, b) => a.sent - b.sent,
      },
      {
        title: 'Clicked',
        dataIndex: 'clicked',
        key: 'clicked',
        sorter: (a, b) => a.clicked - b.clicked,
      },
      {
        title: 'Reviews',
        dataIndex: 'reviews',
        key: 'reviews',
        sorter: (a, b) => a.reviews - b.reviews,
        hidden: !auth.google_integration,
      }
    ];
  }, [auth.google_integration]);

  const table = useMemo(() => {
    const stores = auth.stores.filter(st => st.id !== 0);
    return stores.map(store => {
      const sent = filteredSentGoogleReviews.filter(review => review.store_id === store.id).length;
      const clicked = filteredSentGoogleReviews.filter(review => review.store_id === store.id && review.clicked_at).length;
      const reviews = filteredReportGoogleReviews.filter(review => review.store_id === store.id).length;
      return {
        key: store.id,
        store_id: store.id,
        sender: store.name,
        sent,
        clicked,
        reviews,
      };
    });
  }, [auth.stores, filteredSentGoogleReviews, filteredReportGoogleReviews]);

  const stats = useMemo(() => {

    const averageRating = filteredReportGoogleReviews.reduce((acc, location) => acc + location.rating, 0) / filteredReportGoogleReviews.length;
    const averageRatingTotal = reportGoogleReviews.reduce((acc, location) => acc + location.rating, 0) / reportGoogleReviews.length;
    const ratingVariation = averageRating - averageRatingTotal;

    return [
      {
        title: 'Sent',
        value: filteredSentGoogleReviews.length,
      },
      {
        title: 'Clicked',
        value: filteredSentGoogleReviews.filter(review => review.clicked_at).length,
      },
      {
        title: 'Reviews',
        value: filteredReportGoogleReviews.length,
        prefix: 0 > 0 && <ArrowUpOutlined style={{ opacity: 0.45 }} />,
        hidden: !auth.google_integration
      },
      {
        title: 'Avg Rating',
        value: averageRating || 0,
        precision: 1,
        hidden: !auth.google_integration,
        prefix:
        ratingVariation >= 0 ? (
            <ArrowUpOutlined style={{ opacity: 0.45 }} />
          ) : (
            <ArrowDownOutlined style={{ opacity: 0.45 }} />
          ),
      },
    ];
  }, [auth.google_integration, filteredSentGoogleReviews, filteredReportGoogleReviews, reportGoogleReviews]);

  const expandedRowRender = useCallback(
    record => {
      const columns = [
        {
          title: 'Client',
          dataIndex: 'client',
          key: 'client_id',
          render: (_, row) => <StyledLink to={`/Clients?client=${row.client_id}`}>{row.client}</StyledLink>,
        },
        { title: 'Sent by', dataIndex: 'associate', key: 'associate_id' },
        {
          title: 'Sent',
          dataIndex: 'created_at',
          key: 'created_at',
          render: (_, row) => (row.created_at ? format(new Date(row.created_at), 'MMM dd, yyyy') : '-'),
          sorter: (a, b) => (isBefore(new Date(a.created_at), new Date(b.created_at)) ? -1 : 1),
          defaultSortOrder: 'descend',
        },
        {
          title: 'Clicked',
          dataIndex: 'clicked_at',
          key: 'clicked_at',
          render: (_, row) => (row.clicked_at ? format(new Date(row.clicked_at), 'MMM dd, yyyy') : '-'),
        },
      ];

      const data = [];
      if (record.store_id) {
        filteredSentGoogleReviews?.forEach(result => {
          if (result.store_id === record.store_id) {
            data.push({
              key: result.id,
              client: result.client,
              client_id: result.client_id,
              associate: result.associate,
              associate_id: result.associate_id,
              created_at: result.created_at,
              clicked_at: result.clicked_at,
            });
          }
        });
      }
      return (
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            total: data.length,
          }}
        />
      );
    },
    [filteredSentGoogleReviews],
  );

  const handleExpandRow = useCallback(
    (expand, record) => {
      let updatedExpandedRowKeys = [...expandedRowKeys];
      if (expand) {
        updatedExpandedRowKeys.push(record.store_id);
        setExpandedRowKeys(updatedExpandedRowKeys);
      } else {
        updatedExpandedRowKeys = updatedExpandedRowKeys.filter(key => key !== record.store_id);
        setExpandedRowKeys(updatedExpandedRowKeys);
      }
    },
    [expandedRowKeys],
  );

  const graphData = useMemo(() => {
    const data = {};
    data.Sent = filteredSentGoogleReviews.map(result => ({
      date: result.created_at,
      total: 1,
    }));
    data.Clicked = filteredSentGoogleReviews.map(result => ({
      date: result.clicked_at,
      total: 1,
    }));
    if (auth?.google_integration) {
      data.Reviews = filteredReportGoogleReviews.map(result => ({
        date: result.review_create_time,
        total: 1,
      }));
    }
    return data;
  }, [auth, filteredSentGoogleReviews, filteredReportGoogleReviews]);

  return (
    <Report
      title="Google Reviews"
      stats={stats}
      options={options}
      hasCSVExport
      CSVFilename="google-reviews.csv"
      table={table}
      columns={columns}
      handleFilterChange={handleFilterChange}
      filters={filters}
      removeFilter={removeFilter}
      onDateRangeChange={handleOnDateRangeChange}
      dataRange={dateRange}
      defaultPeriodValue={getDefaultPeriod}
      tablesProps={{
        pagination: {
          total: auth.stores.length - 1,
          showSizeChanger: true,
          showTotal: total => `Total ${total} items`,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSizeTable(pageSize);
          },
          pageSize: pageSizeTable,
        },
        expandable: {
          expandedRowRender: record => expandedRowRender(record),
          defaultExpandedRowKeys: ['0'],
          rowExpandable: record => record.sent > 0,
          onExpand: (expanded, record) => handleExpandRow(expanded, record),
          expandedRowKeys,
          expandRowByClick: true,
        },
      }}
      graphData={graphData}
      getAssociatesList={assoc => setAssociates(assoc)}
    />
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(GoogleReviews);
