import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import Layout from '../../Layout/StandardLayout';
import Table from '../../Layout/Table';
import { getRequest } from '../../../core/apiRequests';
import {
  getTablePaginationData,
  notificationSuccess,
  resetTablePagination,
  setTablePaginationPageCount,
  setTablePaginationRowCount,
} from '../../../core/actions';
import { setSelectedStore } from '../../../reducers/stores';
import { setAuthStoreId } from '../../../reducers/auth';
import { setPageNum } from '../../../reducers/pagination';
import SecondaryPanel from '../../Layout/SecondaryPanel';
import GlobalHeader from '../../Layout/GlobalHeader';
import Button from '../../CustomizedAntDesign/Button';
import { storeWithoutPaymentHeaders, storeWithPaymentHeaders } from './storeListTableHeaders';

const tableName = 'STORES';

const Stores = props => {
  const [tableData, setTableData] = useState([]);
  const [first, setFirst] = useState(true);
  const history = useHistory();
  const isMounted = useRef(null);
  const [searchText, setSearchText] = useState('');

  const tableHeaders =
    props?.auth?.payments && tableData.length > 1 ? storeWithPaymentHeaders : storeWithoutPaymentHeaders;

  // const getFirstStores = useCallback(() => {
  const getFirstStores = () => {
    isMounted.current = true;
    const { merchantId, userId, role } = props.auth;
    resetTablePagination({ sortColumn: 'name' }, tableName);

    // here
    const reqObj = {
      params: ['stores', merchantId, 'paginatedList'],
      query: {
        page: 1,
        count: 25,
        sortColumn: 'name',
        ascending: true,
        searchText,
        userId,
        role,
      },
    };

    (async () => {
      const response = await getRequest(reqObj);

      if (response?.results) {
        const {
          results,
          pageInfo: { rowCount, pageCount },
        } = response;

        setTablePaginationRowCount(rowCount, tableName);
        setTablePaginationPageCount(pageCount, tableName);
        setTableData(results);
      }
    })();
    // end
    setFirst(false);
    // }, [props.auth]);
  };

  const getStoresOnUpdate = useCallback(() => {
    const { page, pageSize, sortColumn, ascendingColumn, searchText } = props.pagination;

    const { merchantId, userId, role } = props.auth;

    const reqObj = {
      params: ['stores', merchantId, 'paginatedList'],
      query: {
        page,
        count: pageSize,
        sortColumn,
        ascending: ascendingColumn,
        searchText,
        userId,
        role,
      },
    };
    (async () => {
      const dataArr = await getRequest(reqObj);
      if (dataArr) {
        setTableData(dataArr.results);
      }
    })();
  }, [props.auth, props.pagination]);

  const handleCopy = () => {
    notificationSuccess('Copied to clipboard');
  };

  useEffect(() => {
    if (props.auth.merchantId) {
      if (first) {
        getFirstStores();
      } else if (props.pagination.page == 0) {
        setPageNum(1);
        setFirst(false);
      } else {
        getStoresOnUpdate();
      }
    }
  }, [
    props.auth.merchantId,
    props.pagination.sortColumn,
    props.pagination.pageSize,
    props.pagination.page,
    props.pagination.ascendingColumn,
    props.pagination.searchText,
  ]);

  const rowClickHandler = (data, e) => {
    const {
      id,
      name,
      address_1,
      address_2,
      phone_number,
      postal_code,
      city,
      timezone,
      state,
      review_link,
      daily_contact_goal,
      sms_number,
      weekly_contact_goal,
    } = data;
    const newDataObj = {
      id,
      name,
      address_1,
      address_2,
      phone_number,
      postal_code,
      city,
      timezone,
      state,
      review_link,
      daily_contact_goal,
      sms_number,
      weekly_contact_goal,
    };
    if (e.target.tagName === 'TD') {
      // to make sure they're not clicking the Action button
      setAuthStoreId(id);
      setSelectedStore(newDataObj);
      history.push(`/Stores/store-dashboard/${id}`);
    }
  };

  return (
    <Layout
      title="Stores"
      description="Edit store information and view key statistics"
      classProps="standard-width-with-sidebar"
    >
      <div className="w-100-P h-100-P">
        <GlobalHeader
          search={[
            <input
              key={props.auth.userId}
              onChange={e => setSearchText(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  getFirstStores();
                }
                return false;
              }}
              placeholder="Search..."
              id="StoreSearchInput"
              className="h-50 w-100-P m-btm-6 body-head-search rem-pad-width focus-input-outline-blue"
              style={{ width: '100%', height: '50px' }}
            />,
          ]}
        />
        <Table
          tableClassName="lg-table-sidebar  rem-pad-width"
          data={tableData}
          tableHeaders={tableHeaders}
          searchable={false}
          paginationV2
          optionalButton={null}
          onRowClick={rowClickHandler}
          tableName={tableName}
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  stores: state.stores,
  pagination: getTablePaginationData(state, tableName),
});

export default connect(mapStateToProps, {})(Stores);
