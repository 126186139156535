import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToolTipBottom from '../../../Layout/ToolTip/ToolTipBottom';
import { Input, message, Modal, Tooltip } from 'antd-v5';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import AddClientModal from '../../../../componentsV2/Layout/AddClientModal';
import yn from 'yn';

const ClientHeader = props => {
  const [newClient, setNewClient] = useState(false);
  const [hovering, setHovering] = useState('');
  const [modal, contextHolder] = Modal.useModal();
  const limitAssociateVisibility = yn(props.auth.merchantConfig.LIMIT_ASSOCIATE_VISIBILITY_ENABLED) && props.auth.role === 'ASSOCIATE';

  const handleError = useCallback((error) => {
    if(error.status === 409 && error?.response?.data?.message === "This client already has an assigned associate.") {
      setNewClient(false);
      const id = error?.response?.data?.data?.id;
      modal.info({
        title: "Client already exists",
        content: "This client already has an assigned associate.",
        onOk: () => (id && !limitAssociateVisibility) ? props.getClientData(id) : {},
      })
    } else {
      setNewClient(false);
      message.error(error?.response?.data?.message || 'An error occurred');
    }
  }, [props.getClientData]);

  const handleSuccess = useCallback((data) => {
    if(data?.isMergedClient) {
      modal.info({
        title: "Client already exists",
        content: "This client already exists and has been updated",
        onOk: () => props.getClientData(data.id)
      })
    } else {
      props.getClientData(data.id);
    }
  }, [props.getClientData]);

  return (
    <div style={{ padding: '0px 20px' }}>
      <div className="flex-row-spacebetween-nowrap">
        <span className="layoutTitle m-top-8 mq-fs-20 align-left flex-row-nospacebetween-nowrap">Clients</span>
        <div className="m-top-10 add-edit-wrap">
          {props.auth.role !== 'ASSOCIATE' && (
            <Tooltip
              title="Export Client List"
              placement='bottom'
            >
              <FontAwesomeIcon
                icon={['far', 'file-alt']}
                // size="2x"
                className="fs-16 pointer m-top-5 m-right-15"
                onClick={() => props.getCSVData()}
              />
            </Tooltip>
          )}
          <Tooltip
            title="Client Filters"
            placement='bottom'
          >
            {!props.hasFilter ? (
                <FontAwesomeIcon
                  icon={['far', 'filter']}
                  size="2x"
                  className="fs-16 pointer m-top-5 m-right-15"
                  onClick={() => props.setClientFilterOpen(!props.clientFilterOpen)}
                  onKeyDown={() => props.setClientFilterOpen(!props.clientFilterOpen)}
                />
              ) : (
                <img
                  src="https://dashboard-v2-images.s3.amazonaws.com/FilterActive.svg"
                  alt="non-default filter icon"
                  className="m-top-5 m-right-15 pointer"
                  style={{ height: '16px', width: '16px' }}
                  onClick={() => props.setClientFilterOpen(!props.clientFilterOpen)}
                  onKeyDown={() => props.setClientFilterOpen(!props.clientFilterOpen)}
                />
              )}
          </Tooltip>
          <Tooltip
            title="Add Client"
            placement='bottom'
          >
            <FontAwesomeIcon
              icon="fa-regular fa-user-plus"
              className="fs-16 pointer m-top-5"
              onClick={() => setNewClient(true)}
              onKeyDown={() => setNewClient(true)}
            />
          </Tooltip>
          {/* <div
            className="clientIcon border-r4 pointer pos-rel"
            onClick={() => props.setClientFilterOpen(!props.clientFilterOpen)}
            onKeyDown={() => props.setClientFilterOpen(!props.clientFilterOpen)}
            role="button"
            tabIndex={0}
            id="new-msg-btn"
            onMouseEnter={() => setHovering('ClientFilters')}
            onMouseLeave={() => setHovering('')}
          >
            <ToolTipBottom
              isShown="ClientFilters"
              idx={hovering}
              description="Client Filters"
              toolTipWidth="125px"
              toolTipHeight="40px"
              toolTipTop="30px"
              toolTipRight="-35px"
              arrowLeft="9px"
              arrowBottom="51px"
            />

          </div> */}
          {/* <div
            className="clientIcon border-r4 pointer pos-rel"
            onClick={() => setNewClient(true)}
            onKeyDown={() => setNewClient(true)}
            role="button"
            tabIndex={0}
            id="new-msg-btn"
            onMouseEnter={() => setHovering('AddClient')}
            onMouseLeave={() => setHovering('')}
          >
            <ToolTipBottom
              isShown="AddClient"
              idx={hovering}
              description="Add Client"
              toolTipWidth="125px"
              toolTipHeight="40px"
              toolTipTop="30px"
              toolTipRight="-35px"
              arrowLeft="6px"
              arrowBottom="51px"
            />
            <FontAwesomeIcon icon="fa-regular fa-user-plus" className="fs-16 pointer m-top-5" />
          </div> */}
        </div>
      </div>

      <div className="" />
      <Input
        className="h-45 bg-offwhite fs-13 w-100-P"
        id="client-search"
        placeholder="Name search..."
        style={{ border: 0 }}
        prefix={<SearchOutlined />}
        onChange={e => props.setSearch(e.target.value)}
        value={props.search}
        styles={{ prefix: { fontSize: '18px', marginRight: '12px', color: '#9C9C9C' }}}
        suffix={props?.loadingSearch && <LoadingOutlined />}
      />
      {/* newClient && (
        <PopMessageAddClient
          onClose={() => setNewClient(false)}
          newClient={newClient}
          client={props.client}
          stores={props.stores}
          iso_country_code={props.auth.iso_country_code}
          getClientData={props.getClientData}
          auth={{
            merchantId: props.auth.merchantId,
            role: props.auth.role,
            userId: props.auth.userId,
            stores: props.auth.stores,
            storeId: props.client.store_id,
            createdBy: `${props.auth.firstName || ''} ${props.auth.lastName || ''}`.trim(),
          }}
        />
      ) */}
      <AddClientModal
        open={newClient}
        setOpen={setNewClient}
        initialValues={{
          storeId: props.auth.stores.filter(st => st.id !== 0).length
            ? props.auth.stores.filter(st => st.id !== 0)[0].id
            : null
        }}
        onSuccess={data => handleSuccess(data)}
        onError={error => handleError(error)}
      />
      {contextHolder}
    </div>
  );
};

export default ClientHeader;
