import moment from "moment";


export function dateNumberToMoment (date) {
  if(typeof date === 'number') {
    switch (date.toString().length) {
      case 8:
        return moment(date, 'YYYYMMDD');
      case 14:
        return moment(date, 'YYYYMMDDHHmmss');
      case 12:
        return moment(date, 'YYYYMMDDHHmm');
      default:
        return moment(date);
    }
  } else {
    return moment(date);
  }
}
