import React from 'react';
import StandardLayout from '../../../../../../Layout/StandardLayout';
import { connect } from 'react-redux';
import SecondaryPanel from '../../../../../../Layout/SecondaryPanel';
import WhatasppReminderTemplates from '../../../../../../Layout/AutoReminders/AutoReminderSettings/WhatasppReminderTemplates';

const lifeEventAutoWhatsappTemplate = props => {
  const listItemId = props.match.params.listItemId;
  const reminderId = props.match.params.reminderId;

  return (
    <StandardLayout
      title={`${
        window.location.href.includes('add-auto-whatsapp') ? 'Add' : 'Edit'
      } Whatsapp Template for Auto Reminder`}
      description={'Sales associates will be able to choose to send this message instead of typing their own'}
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Life Events', to: '/Configuration/life-events' },
        {
          title: 'Edit Life Event',
          to: `/Configuration/life-events/edit-life-events/${listItemId}`,
        },
        {
          title: `Auto Reminder`,
          to: `/Configuration/life-events/edit-life-events/${listItemId}/edit-auto-reminder/${reminderId}`,
        },
        { title: `${window.location.href.includes('add-auto-whatsapp') ? 'Add' : 'Edit'} Whatsapp Message Template` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <WhatasppReminderTemplates
        cancelRoute={`/Configuration/life-events/edit-life-events/${listItemId}/edit-auto-reminder/${reminderId}`}
        popConfirmDesc={'This will remove the template from this Reminder'}
        type={'lifeEvent'} //// REMEMBER THIS
        match={props.match}
      />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  ...state.autoRemMsgs,
});
export default connect(mapStateToProps, {})(lifeEventAutoWhatsappTemplate);
