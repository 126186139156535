import { getRequest, patchRequest, postRequest, putRequest } from "../core/apiRequests";


export const getClients = async ({ query = {}, loadingStatusOff = false, axiosConfig = {} }) => {

  return await getRequest({
    params: ['clients', 0, 'elasticSearchPaginatedList'],
    query,
    loadingStatusOff,
    axiosConfig
  })
};


export const createClient = async ({ data, loadingStatusOff = false, axiosConfig = {}, query = {} }) => {
  const reqObj = {
    params: ['clients', 'create'],
    data,
    loadingStatusOff,
    axiosConfig,
    query
  };

  return await postRequest(reqObj);
};


export const updateClient = async({ data, clientId, loadingStatusOff = false, axiosConfig = {}, query = {} }) => {
  const reqObj = {
    params: ['clients', 'update', clientId],
    data,
    loadingStatusOff,
    axiosConfig,
    query
  };

  return await patchRequest(reqObj);
}

export const mergeClient = async ({ data, loadingStatusOff = false, axiosConfig = {}, query = {} }) => {
  const reqObj = {
    params: ['clients', 'merge', data.id],
    data,
    loadingStatusOff,
    axiosConfig,
    query
  };

  return await putRequest(reqObj);
}
