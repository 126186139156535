import moment from 'moment';

export const filterList = ({ massMessages = [], filters, startDate, endDate, assocStores }) => {
  const allStores = assocStores.filter(store => store.id !== 0).map(item => item.id.toString());
  return massMessages?.filter(massMessage => {
    const filterStore =
      filters.store.length === 0
        ? allStores.includes(massMessage?.activity_store_id.toString())
        : filters.store.includes(massMessage?.activity_store_id?.toString());
    const filterAssociate =
      filters.associate.length === 0 || filters.associate.includes(massMessage.activity_associate_id.toString());
    const filterTimeSpan = moment(massMessage.activity_date).isBetween(moment(startDate), moment(endDate));
    return filterStore && filterAssociate && filterTimeSpan;
  });
};

export const filterMassMsgMeta = (massMessages, { startDate, endDate }, associates = []) => {
  return massMessages?.filter(massMessage => {
    const filterAssociate = associates.length === 0 || associates.includes(massMessage?.associate_id?.toString());
    const filterTimeSpan = moment(new Date(massMessage.scheduled_date_time)).isBetween(
      moment(startDate),
      moment(endDate),
    );
    return filterAssociate && filterTimeSpan;
  });
};
