import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import Layout from '../../../../Layout/StandardLayout';
import StyledLink from '../../../../Layout/StyledLink';
import PopConfirm from '../../../../Layout/PopConfirm';
import Button from '../../../../Layout/Button';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';
import {
  saveListItem,
  deleteListItem,
  getSingleDetails,
  postRequest,
  getRequest,
  deleteRequest,
} from '../../../../../core/apiRequests';
import '../configuration.css';
import CheckboxDesc from '../../../../Layout/CheckboxDesc';
import { notificationSuccess, notificationError } from '../../../../../core/actions';
import { setListId, setListType } from '../../../../../reducers/autoRemMsgs';
import {
  setTagColor,
  setTagKiosk,
  setTagName,
  setTagAddEdit,
  setTagId,
  setTagObj,
} from '../../../../../reducers/addEditTag';
import { setMsgListId } from '../../../../../reducers/autoMessages';
import AutomationTable from '../../../../Layout/AutomationTable';

const AddEditTag = props => {
  const [defaultColors, setDefaultColors] = useState([
    '#EF709D',
    '#D95D39',
    '#EFA340',
    '#346FAB',
    '#469C83',
    '#479C83',
    '#CB48B7',
    '#774FA4',
  ]);
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (props.addOrEdit === 'Add' && props.tagId && window.location.href.includes('add-tag')) {
      if (props.tagId) {
        setTagColor(defaultColors[0]);
        setTagKiosk(false);
        setTagName('');
        setTagColor('');
        setTagAddEdit('Add');
      }
    } else if (
      (window.location.href.includes('edit-tag') && !props.tagId) ||
      (props.match.params.listItemId && Number(props.tagId) !== Number(props.match.params.listItemId))
    ) {
      setTagAddEdit('Edit');
      if (props.merchantId) {
        (async () => {
          const reqObj = {
            params: ['tags', 'details', props.merchantId, props.match.params.listItemId],
          };
          const data = await getRequest(reqObj);
          if (data && data.length > 0) {
            const { color, inc_kiosk, id, name } = data[0];
            const defCol = defaultColors;
            setListType('tags'); //  set list type is required later for requests (props.type)
            setTagName(name);
            setTagId(id);
            setListId(id);
            setMsgListId(id);
            if (color && !defaultColors.includes(color)) {
              defCol.push(color);
              setDefaultColors([...defCol]);
            }
            setTagColor(color);
            setTagKiosk(inc_kiosk);
          } else {
            history.push('/Configuration/tags');
          }
        })();
      }
    } else {
      console.log('oh no');
    }

    return () => {
      // setTagKiosk(false);      // TODO: not sure I want to do this...
      // setTagName('');
      // setTagColor('');
      // setTagAddEdit('Add');
    };
  }, [props.merchantId]);

  const saveTag = () => {
    const { tagName, tagId, tagColor, kioskMode, merchantId, firstName, lastName } = props;
    const fn = firstName || '';
    const ln = lastName || '';
    if (!tagName) {
      notificationError('Tag Name Required');
    } else {
      (async () => {
        const dataObj = {
          name: tagName,
          color: tagColor,
          inc_kiosk: kioskMode,
          createdBy: `${fn} ${ln}`.trim(),
        };
        const reqObj = {
          params: ['tags', merchantId, 'save'],
          data: dataObj,
          errors: {
            duplicateTags: 'Tag already exists',
          },
        };
        if (tagId) {
          dataObj.id = tagId;
        }
        const data = await postRequest(reqObj);
        if (data.id) {
          if (tagId) {
            notificationSuccess('Tag Updated');
          } else {
            notificationSuccess('Tag Created');
          }
          setTagId(data.id); // Now we can update this component
          setListId(data.id); // Auto Reminder settings will need this
          setMsgListId(data.id); // Auto Message settings will need this
          setTagAddEdit('Edit'); // Convert this component to 'Edit' mode
          history.push('/Configuration/tags');
        } else {
          // error handling?
          setShowPopConfirm(false);
        }
      })();
    }
  };

  const confirmDelete = () => {
    const { merchantId, tagId, firstName, lastName } = props;
    const fn = firstName || '';
    const ln = lastName || '';
    (async () => {
      const reqObj = {
        params: ['tags', merchantId, 'remove', tagId],
        query: {
          deletedBy: `${fn} ${ln}`.trim(),
        },
      };
      const data = await deleteRequest(reqObj);
      if (data) {
        setTagObj({ deletedTags: [tagId] });
        notificationSuccess('Tag Removed');
        history.push('/Configuration/tags');
      } else {
        notificationError('Error Removing Tag');
      }
    })();
  };

  return (
    <Layout
      title={`${props.addOrEdit} Tag`}
      description={
        props.addOrEdit === 'Add'
          ? 'Add a tag and organize your client base'
          : 'Edit tag details and configure automation'
      }
      // styleProps={{ marginLeft: '265px', width: '866px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Tags', to: '/Configuration/tags' },
        { title: `${props.addOrEdit} Tag` },
      ]}
      classProps="standard-width-with-sidebar"
    >
      <div className="custom-action-wrapper">
        <div className="align-left">
          <input
            className={`m-btm-15 m-top-15 fs-13 outline-none common_inputStyle  max-w-600 w-100-P  left-0 input-height

            `}
            // ${props.addOrEdit === "Add" ? "w-510" : "w-440"}
            value={props.tagName}
            // style={{ width: "98%" }}
            onChange={e => setTagName(e.target.value)}
            placeholder="Tag Name"
          />
        </div>
        <div className="tag-sort-color-div hide-scrollbar max-w-600" style={{ justifyContent: 'center' }}>
          {defaultColors.map((color, i) => {
            return (
              <div key={i} className={props.tagColor === color ? 'tag-selected-color-ring rem-pad-width' : ''}>
                <div
                  className="SortColor pointer"
                  style={{ backgroundColor: color }}
                  onClick={() => setTagColor(color)}
                />
              </div>
            );
          })}
          {/* ::::::WAITING ON ADD COLOR ABILITY:::::: */}
          {/* <div className="SortColor tag-add-color pointer">
            <img src={ic_plus} />
          </div> */}
        </div>
        <CheckboxDesc
          classProps="mq-padding-left-15 fs-13"
          checked={props.kioskMode}
          onclick={setTagKiosk}
          text="Show in Kiosk  Mode"
        />
        <p className="fs-10 gray align-left mq-padding-left-15">
          Enable this tag to be seen by your customers while in Kiosk Mode
        </p>
        {/* START of save / cancel / delete code  */}
        <div className="d-inline-block w-600 m-top-30">
          <div className="mq-w-100-vw custom-action-save-flex">
            <button className="save-button-v2 m-btm-8" onClick={saveTag}>
              Save
            </button>
            {props.addOrEdit === 'Edit' ? (
              <Button
                id="DeleteClientActivityButton"
                classname="deleteButton-v2 "
                style={{ marginBottom: '8px' }}
                onclick={() => setShowPopConfirm(true)}
              >
                Delete
              </Button>
            ) : (
              <div />
            )}
            <StyledLink to="/Configuration/tags">
              <button id="AddClientActivityCancelButton" className="cancel-button-v2">
                Cancel
              </button>
            </StyledLink>
          </div>
        </div>
        {/* END BLOCK */}
        {props.addOrEdit === 'Edit' ? (
          <AutomationTable
            match={props.match}
            redirectToReminderSettings={`/Configuration/tags/edit-tag/${props.tagId}`}
            redirectToAutoMsgSettings={`/Configuration/tags/edit-tag/${props.tagId}`}
            redirectToAutoWhatsappSettings={`/Configuration/tags/edit-tag/${props.tagId}`}
            type="tags"
          />
        ) : null}
      </div>
      {showPopConfirm ? (
        <PopConfirm
          confirm={`Are you sure you want to remove "${props.tagName}" tag?`}
          description="This will remove the tag from all associated clients"
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={confirmDelete}
        />
      ) : null}
    </Layout>
  );
};
const mapStateToProps = state => {
  return {
    ...state.addEditTag,
    ...state.auth,
    ...state.notification,
    ...state.autoRemMsgs,
  };
};
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AddEditTag);
