import React from 'react';
import StandardLayout from '../../../../../../Layout/StandardLayout';
import { connect } from 'react-redux';
import SecondaryPanel from '../../../../../../Layout/SecondaryPanel';
import WhatasppReminderTemplates from '../../../../../../Layout/AutoReminders/AutoReminderSettings/WhatasppReminderTemplates';

const CustomAutoWhatsappTemplate = props => {
  return (
    <StandardLayout
      title={`${
        window.location.href.includes('add-auto-whatsapp') ? 'Add' : 'Edit'
      } Whatsapp Template for Auto Reminder`}
      description={`Create message templates that appear with the auto reminder`}
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Client Activity', to: '/Configuration/client-activity' },
        {
          title: 'Edit Client Activity',
          to: `/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}`,
        }, // DON'T FORGET THESE TWO
        {
          title: `Auto Reminder`,
          to: `/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}/edit-auto-reminder/${props.match.params.reminderId}`,
        },
        { title: `${window.location.href.includes('add-auto-whatsapp') ? 'Add' : 'Edit'} Whatsapp Message Template` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <WhatasppReminderTemplates
        popConfirmDesc={'This will remove the reminder from this client activity'}
        match={props.match}
        type={'clientEvent'}
        cancelRoute={`/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}/edit-auto-reminder/${props.match.params.reminderId}`}
      />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  ...state.autoRemMsgs,
});
export default connect(mapStateToProps, {})(CustomAutoWhatsappTemplate);
