import { Alert, Input, message, Modal, Radio } from "antd-v5";
import { TeamOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { getRequest, putRequest } from "../../../../../core/apiRequests";
import { useQuery } from "react-query";
import { useCallback, useMemo, useState } from "react";
import { setClientAssociateId } from "../../../../../reducers/client";


export const BulkAssignedAssociateModal = ({
  open,
  onClose,
  auth,
  selected_client_list,
  clientAscts = [],
  clientList,
  client,
  setTableData
}) => {

  const [search, setSearch] = useState('');
  const [selectedAssociate, setSelectedAssociate] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const { data } = useQuery('associates-list', async () => {
    const reqObj = {
      params: ['associates'],
      loadingStatusOff: true,
    };
    return await getRequest(reqObj);
  }, {
    enabled: !!auth.merchantId,
    refetchOnWindowFocus: false,
    initialData: []
  });

  const associates = useMemo(() => {
    const clientObjectList = clientList.reduce((acc, client) => {
      acc[client.id] = client;
      return acc;
    }, {});
    let unassigned = 0;
    selected_client_list.forEach(client => {
      if(!clientObjectList[client]) return;
      let assocId = data.filter(a => clientObjectList[client].associate_ref.includes(a.id));
      if(clientObjectList[client].associate_ref.length === 0
        || clientObjectList[client].associate_ref.includes(0)
        || clientObjectList[client].associate_ref.includes(null)
        || assocId.length === 0
      ) {
        unassigned++;
      }
    })
    let associatesList =  data.map(asct => {
      let cnt = 0;
      if(asct.id === 0) {
        ;
      } else {
        selected_client_list.forEach(client => {
          if(!clientObjectList[client]) return;
          if(clientObjectList[client].associate_ref.includes(asct.id)) {
            cnt++;
          }
        });
      }
      return {
        ...asct,
        cnt,
      }
    }).sort((a, b) => b.cnt - a.cnt);
    return ([
      {
        id: 0,
        first_name: 'Unassigned',
        last_name: '',
        cnt: unassigned,
      },
      ...associatesList
    ])
  }, [data, clientList, selected_client_list]);

  const filteredAssociates = useMemo(() => {
    if(!associates) return [];
    if(search === '') return associates;
    return associates.filter(asct => asct?.first_name?.toLowerCase()?.includes(search.toLowerCase()) || asct?.last_name?.toLowerCase()?.includes(search.toLowerCase()));
  }, [associates, search]);

  const handleClose = useCallback(() => {
    setConfirmLoading(false);
    setSelectedAssociate(null);
    setShowError(false);
    setSearch('');
    onClose();
  }, [])

  const handleSave = useCallback(async () => {
    if(selectedAssociate === null) {
      setShowError(true);
      return;
    }
    try {
      setConfirmLoading(true);
      const reqObj = {
        params: ['clients', 'bulkAssignAssociate'],
        data: {
          clientList: selected_client_list,
          associateId: selectedAssociate === 0 ? null : selectedAssociate,
        },
      }
      await putRequest(reqObj);
      if(client.selected_client_list.includes(parseInt(client.client_id))) {
        setClientAssociateId(selectedAssociate === 0 ? null : selectedAssociate);
      }
      let updatedClientList = clientList.map(client => {
        if(selected_client_list.includes(client.id)) {
          return {
            ...client,
            associate_ref: selectedAssociate === 0 ? [] : [selectedAssociate],
          }
        }
        return client;
      })
      setTableData(updatedClientList);
      message.success('Successfully saved');
    } catch (error) {
      console.log(error);
      message.error('Failed to save');
    } finally {
      handleClose();
    }
  }, [selectedAssociate, selected_client_list, setTableData, clientList]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onCancel={handleClose}
      closable={false}
      okText="Save"
      title="Edit Assigned Associate"
      styles={{
        header: {
          textAlign: 'center'
        }
      }}
      onOk={handleSave}
      confirmLoading={confirmLoading}
    >
      <div className="flex-col-start gap-16" style={{ maxHeight: '60vh', minHeight: '50vh', overflow: 'hidden' }}>
        <div className="flex-row-center-center gap-8">
          <span className="fw-500">{`Clients Selected: ${selected_client_list?.length || 0}`}</span>
        </div>
        <div className="flex-row gap-8">
          <ExclamationCircleOutlined />
          <span className="fs-12">
            All selected clients will be reassigned to the associate you choose.
          </span>
        </div>
        {showError && (
          <Alert
            message="Please select an associate"
            type="error"
            showIcon
            closable
            onClick={() => setShowError(false)}
          />
        )}
        <Input.Search onChange={e => setSearch(e.target.value)} value={search}/>
        <Radio.Group
          style={{width: '100%', maxHeight: '100%', overflowX: 'hidden'}}
          className="scrollbar-y"
          value={selectedAssociate}
          onChange={e => setSelectedAssociate(e.target.value)}
        >
            {filteredAssociates?.map(({id, first_name, last_name, cnt}) => (
              <div className="p-top-14 p-btm-13 w-full p-left-8 flex-row-spacebetween-nowrap" style={{ borderBottom: '1px solid #CAC4D0'}}>
                <Radio
                  key={id}
                  value={id}
                  className="w-100-P"
                >
                  {`${first_name || ''} ${last_name || ''}`}
                </Radio>
                <span
                  className="w-100-P"
                  style={{
                      color: "#000",
                      opacity: 0.5,
                      fontSize: '14px',
                      textAlign: 'right',
                      marginRight: '4px',
                    }}
                >
                  {`${cnt} of ${selected_client_list.length} currently assigned`}
                </span>
              </div>
            ))}
        </Radio.Group>
      </div>
    </Modal>
  )
};
