export const FEEDBACK_API_URL = process.env.REACT_APP_FEEDBACK_API_URL;
export const COLLECTION_API_URL = process.env.REACT_APP_COLLECTION_API_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const PAYMENTS_API_URL = process.env.REACT_APP_PAYMENTS_API_URL;
export const CLIENTBOOK_API_URL = process.env.REACT_APP_CLIENTBOOK_API_URL;

export const ACTIVITIES_TYPES_ASSO_REPORT = {
  chatMessages: 'clientContact',
  aiInsights: 'aiActivity',
  reminders: 'reminders',
  activities: 'clientActivity',
  newClients: 'clientCreation',
  salesOpps: 'salesOpp'
}
