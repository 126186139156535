import React from 'react';
import { array, string } from 'prop-types';

import moment from 'moment';
import LayoutTable from '../../../Layout/Table';
import Bubble from './Bubble';
import { TRANSACTION_COLUMN_KEY, TRANSACTION_STATUS_LABEL } from './const';

const Table = ({ data, bankAccount, tableName }) => {
  const columnProps = {
    style: { whiteSpace: 'nowrap', minWidth: 80, paddingRight: 10 },
    rowStyle: { paddingRight: 20 },
  };

  const tableHeaders = [
    {
      keyName: TRANSACTION_COLUMN_KEY.DATE,
      title: 'Date',
      ...columnProps,
      // render: (date) => convertDateToEST(date),
      render: date => moment(date).format('L'),
    },
    {
      keyName: TRANSACTION_COLUMN_KEY.STATUS,
      title: 'Status',
      ...columnProps,
    },
    {
      keyName: TRANSACTION_COLUMN_KEY.BANK_ACCOUNT,
      title: 'Bank Account',
      ...columnProps,
      render: account => (account = bankAccount),
    },
    {
      keyName: TRANSACTION_COLUMN_KEY.GROSS_AMOUNT,
      title: 'Gross',
      ...columnProps,
      render: grossAmount =>
        Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(grossAmount),
    },
    {
      keyName: TRANSACTION_COLUMN_KEY.NET_AMOUNT,
      title: 'Net',
      ...columnProps,
      render: net =>
        Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(net),
      // ),
    },
    {
      keyName: TRANSACTION_COLUMN_KEY.TOTAL_DISBURSEMENT,
      title: 'Total Payout',
      ...columnProps,
      render: fee => (
        <div className="bold">
          {Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(fee)}
        </div>
      ),
    },
  ];

  return (
    <LayoutTable
      tableName={tableName}
      data={data.map(item => {
        return {
          ...item,
          status: <Bubble {...TRANSACTION_STATUS_LABEL[item.status]} />,
        };
      })}
      tableHeaders={tableHeaders}
    />
  );
};

Table.propTypes = {
  data: array,
  bankAccount: string,
};

export default Table;
