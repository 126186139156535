import React, { useState, useEffect } from 'react';
import { string, bool, array } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// redux & api
import { getTablePaginationData, resetTablePagination } from '../../../../core/actions';
import { getBankAccountsList } from '../apiRequests';

// layout & images
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';

// local
import Table from './Table';
import { AddAccountIntro } from '../Dialogs';
import './styles.css';
import Button from '../../../Layout/Button';

const tableName = 'BANK_ACCOUNTS';

const DEFAULT_SORT_COLUMN_KEY = 'status';

const ListAccounts = ({
  data = [],
  activityLog = [],
  merchantId,
  sortColumn = DEFAULT_SORT_COLUMN_KEY,
  ascendingColumn,
}) => {
  const [showAddAccountIntro, setShowAddAccountIntro] = useState(false);

  useEffect(() => {
    resetTablePagination({ sortColumn: DEFAULT_SORT_COLUMN_KEY }, tableName);

    if (merchantId) {
      getBankAccountsList(merchantId);
    }
  }, [merchantId]);

  return (
    <Layout title="Bank Accounts" classProps="standard-width-with-sidebar">
      <div className="bank-accounts-list__header-actions">
        {/* If there are bank acounts, then there is an audit log */}
        {data.length ? (
          <Link to="/BankAccount/activity" className="fs-12 no-decoration">
            Recent Activity
          </Link>
        ) : (
          <span className="fs-12 gray">Recent Activity</span>
        )}
        <Button
          id="AddBankAccountButton"
          classname="transparentButton m-left-23"
          to="/BankAccount/add"
          onClick={() => setShowAddAccountIntro(true)}
        >
          <FontAwesomeIcon size="1x" icon={['far', 'building-columns']} className=" m-btm-6 p-btm-3" />
          Add Bank Account
        </Button>
      </div>
      {data.length ? (
        <Table
          data={data}
          merchantId={merchantId}
          sortColumn={sortColumn}
          ascendingColumn={ascendingColumn}
          tableName={tableName}
        />
      ) : (
        <div className="bank-accounts-list__empty">
          <img
            src="https://dashboard-v2-images.s3.amazonaws.com/empty-bank-acct.svg"
            alt=""
            role="presentation"
            style={{ width: 240 }}
          />
          <h3 className="fs-16 fw-600">No bank accounts yet</h3>
          <p className="fs-13 gray">When you add your first bank account, it will appear here</p>
        </div>
      )}

      {showAddAccountIntro ? <AddAccountIntro onCancel={() => setShowAddAccountIntro(false)} data={data} /> : null}
    </Layout>
  );
};

ListAccounts.propTypes = {
  data: array,
  activityLog: array,
  sortColumn: string,
  ascendingColumn: bool,
};

const mapStateToProps = state => ({
  data: state.bankAccounts.list,
  activityLog: state.bankAccounts.activityLog,
  ...getTablePaginationData(state, tableName),
  merchantId: state.auth.merchantId,
});

export default connect(mapStateToProps)(ListAccounts);
