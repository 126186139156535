/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getRequest } from '../../../core/apiRequests';
import useSearchParams from '../../../hooks/useSearchParams';
import FilterItemList from './FilterItemList';
import BirthdayFilter from './BirthdayFilter';
import AnniversaryFilter from './AnniversaryFilter';
import CreatedDateFilter from './CreatedDateFilter';
import LastMessageSentFilter from './LastMessageSentFilter';
import './styles.css';
import LastMessageReceivedFilter from './LastMessageReceivedFilter';
import { useOnce } from '../../../hooks/useOnce';

export const filters = [
  'filterBirthdayDateRange',
  'filterHasBirthday',
  'filterHasDoesNotHaveBirthday',
  'filterHasDoesNotHaveAnniversary',
  'filterHasAnniversary',
  'filterAnniversaryDateRange',
  'filterLastMessageNeverSent',
  'filterLastMessageSentDateRange',
  'filterLastMessageNeverReceived',
  'filterLastMessageReceivedDateRange',
  'filterCreatedDateRange',
  'filterContactMethods',
  'stores',
  'associates',
  'tags',
]

const ClientsFilter = ({ merchantId, userId, role, open, stores, associates, tags, contactMethodOptions, setOpen, setHasFilter }) => {
  const [layoutClass, setLayoutClass] = useState('modal-layout-main');
  const { getTotalValues, resetValues } = useSearchParams();
  const flags = useFlags();

  const handleResetFilters = () => {
    resetValues(filters)
  };

  const total = getTotalValues(filters);

  const showClearButton = useMemo(() => total > 0, [total]);

  useEffect(() => {
    if(setHasFilter){
      setHasFilter(total > 0)
    }
  }, [total])

  /* const getPaymentBannerClientFilter = useCallback(() => {
    if (merchantId) {
      (async () => {
        const reqObj = {
          params: ['login', userId, merchantId, 'paymentBannerStatus'],
        };
        const data = await getRequest(reqObj);
        if (data.paymentCheck?.length > 0) {
          if (data.paymentCheck[0].status === 'ACTIVE') {
            if (data.paymentSentRequest.length > 0) {
              // payment is active and they have used it. turn off banner
              setLayoutClass('modal-layout-main');
            } else {
              setLayoutClass('modal-layout-main');
            }
          } else if (data.paymentCheck[0].status === 'INACTIVE') {
            setLayoutClass('modal-layout-main');
            if (role !== 'ADMIN') {
              setLayoutClass('modal-layout-main');
            }
          }
        } else {
          setLayoutClass('modal-layout-main');
          if (role !== 'ADMIN') {
            setLayoutClass('modal-layout-main');
          }
        }
      })();
    }
  }, []);

  const { call } = useOnce(getPaymentBannerClientFilter);
  useEffect(() => {
    call();
  }, [merchantId]); */

  return open ? (
    <div className={`left-modal ${layoutClass}`}>
      <div style={{ padding: '28px 18px', display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ fontWeight: 700, fontSize: '26px' }}>Filters</div>
          <div onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={['far', 'close']} className="black fs-24" />
          </div>
        </div>

        <div className="left-modal-content">
          {flags.clientSegmentation && (
            <>
              <BirthdayFilter />
              <AnniversaryFilter />
            </>
          )}
          <LastMessageSentFilter />
          <LastMessageReceivedFilter />
          <CreatedDateFilter />
          <FilterItemList
            title="Contact Methods"
            options={contactMethodOptions}
            labelKey="label"
            valueKey="value"
            paramValue="filterContactMethods"
            hasSearch={false}
            hasSelectAll={false}
          />

          {stores.length > 1 && (
            <FilterItemList title="Stores" options={stores} labelKey="name" valueKey="id" paramValue="stores" />
          )}
          <FilterItemList
            title="Associates"
            options={associates}
            labelKey="label"
            valueKey="value"
            paramValue="associates"
          />
          <FilterItemList
            title="Tags"
            options={tags}
            labelKey="label"
            valueKey="value"
            paramValue="tags"
            renderItem={item => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    minWidth: '8px',
                    background: item.tagColor,
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    marginRight: '8px',
                  }}
                />
                <div
                  style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis',
                    wordBreak: 'break-word',
                    WebkitLineClamp: 1,
                    overflow: 'hidden',
                  }}
                >
                  {item.label}
                </div>
              </div>
            )}
          />
        </div>
        {showClearButton && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <div onClick={handleResetFilters} className="filter-item-clear" style={{ marginRight: 0 }}>
              Clear All
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default ClientsFilter;
