
import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { notification  } from 'antd-v5';
import { ToastMessage, ToastTitle, ToastActionBtn } from './components/ToastComponents';
import io from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NotificationsContext = createContext({});

const NotificationsContextProvider = ({ children, auth }) => {

  const [api, contextHolder] = notification.useNotification();
  /* const socket = io('https://dev-socket-server.clbk.app', {
    // transports: ["websocket"],
    transportOptions: {
      polling: {
        extraHeaders: {
          'x-clientbook-user-id': 'userId',
          'x-clientbook-merchant-id': 'merchantId',
          Authorization: 'token',
        },
      },
    },
  });

  useEffect(() => {
    socket.connect();
    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      socket.disconnect();
    };
  }, []) */

  const openNotification = useCallback(({type, message, client_name, rating}) => {
    api.open({
      message: <ToastTitle type={type} client_name={client_name} />,
      icon: <FontAwesomeIcon className="fs-16 pointer" color="#33AAFC" icon={['far', 'comment-alt-lines']} />,
      description: <ToastMessage type={type} msg={message} rating={rating} />,
      closeIcon: <ToastActionBtn />,
      closable: false,
      style: {
        width: '460px'
      },
      duration: 0,
    });
  }, [api]);

  useEffect(() => {
    /* openNotification({type: 'sms', message: 'Hello, this is a test message', client_name: 'John Doe'});
    openNotification({type: 'facebook', message: 'Hello, this is a test message', client_name: 'John Doe'});
    openNotification({type: 'google', message: 'Hello, this is a test message', client_name: 'John Doe', rating: 2.5});
    openNotification({type: 'webchat', message: 'Hello, this is a test message', client_name: 'John Doe'}); */
  }, []);



  return (
    <NotificationsContext.Provider
      value={{
      }}
    >
      {contextHolder}
      {children}
    </NotificationsContext.Provider>
  );
};

export function useNotification() {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error('useNotificationsContext must be used within an NotificationsContext');
  }
  return context;
}

const mapStateToProps = state => ({ auth: state.auth });
export default connect(mapStateToProps)(NotificationsContextProvider);

