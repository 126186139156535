import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useId, useState } from 'react'

export const Buttons = ({
  id,
  parentId,
  open,
  setOpen,
  onSave,
  onCancel,
  isSubmitting,
  containerStyles = {},
  avoidHover = false,
  customButtonOpened,
  customButtonClosed,
  customButtonOpenedClassName,
  customButtonClosedClassName,
  customButtonOpenedAction = () => {},
  customButtonClosedAction = () => {},
}) => {

  const [isHover, setIsHover] = useState(false);

  const flags = useFlags();

  const handleCancel = useCallback(() => {
    onCancel();
    setOpen(false);
  }, [onCancel]);

  const handleSave = useCallback(() => {
    onSave();
    setOpen(false);
  }, [onSave]);

  const el = document.getElementById(parentId);
  el?.addEventListener('mouseenter', () => {
    const getButtonsElement = document.getElementById(id);
    if(getButtonsElement) {
      if(checkParentElement(getButtonsElement, parentId)){
        setIsHover(true);
      }
    }
  })
  el?.addEventListener('mouseleave', () => {
    const getButtonsElement = document.getElementById(id);
    if(getButtonsElement) {
      if(checkParentElement(getButtonsElement, parentId)){
        setIsHover(false);
      }
    }
  })

  function checkParentElement(element, id) {
    if (element?.id === id) {
      return true;
    }
    if (element?.parentElement) {
      return checkParentElement(element?.parentElement, id);
    }
    return false;
  }

  return (
    flags.inboxEditClientDetails ? (
      <div id={id} className='pos-abs right-8 top-0' style={{...containerStyles}}>
        {open ? (
          <div className='flex-row-center'>
            {customButtonOpened ? (
                <button
                  className={`secondary-button ${customButtonOpenedClassName || ''}`}
                  onClick={customButtonOpenedAction}
                  disabled={isSubmitting}
                >
                  {customButtonOpened}
                </button>
            ) : (
              <>
                <button className='error-button' onClick={handleCancel} disabled={isSubmitting}>
                  Cancel
                </button>
                <button className='secondary-button w-72' onClick={handleSave} disabled={isSubmitting}>
                  {isSubmitting ? 'Saving...' : 'Save'}
                </button>
              </>
            )}
          </div>
        ) : (
          (isHover && !avoidHover) && (
            customButtonClosed ? (
              <button
                className={`secondary-button ${customButtonClosedClassName || ''}`}
                onClick={customButtonClosedAction}
              >
                {customButtonClosed}
              </button>
            ) : (
              <button className='secondary-button w-72' onClick={() => setOpen(true)}>
                Edit
              </button>
            )
          )
        )}
      </div>
    ) : null
  )
};
