/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, parse, set } from 'date-fns';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import './styles.scss';
import { Dropdown, Image, Tooltip } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import NewActivityModal from './NewActivityModal';
import CheckboxDesc from '../../../../../Layout/CheckboxDesc';
import { getRequest, postRequest } from '../../../../../../core/apiRequests';
import { notificationError, notificationSuccess } from '../../../../../../core/actions';
import { addRecentActivity, setRecentActivity } from '../../../../../../reducers/client';
import useSearchParams from '../../../../../../hooks/useSearchParams';
import truncateText from '../../../../../../utils/truncateText';
import EditActivityModal from './EditActivityModal';
import { Form, Modal, Radio } from 'antd-v5';

const BagIcon = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M4.50733 4.99707V3.4956C4.50733 1.56012 6.06745 0 8.00293 0C9.93842 0 11.4985 1.56012 11.4985 3.4956V4.99707H13.4927C14.3255 4.99707 14.9941 5.66569 14.9941 6.49854V12.9971C14.9941 14.651 13.6569 16 11.9912 16H4.00293C2.34897 16 1 14.6628 1 12.9971V6.49854C1 5.66569 1.66862 4.99707 2.50147 4.99707H4.4956H4.50733ZM6.0088 4.99707H10.0088V3.4956C10.0088 2.39296 9.1173 1.50146 8.01466 1.50146C6.91202 1.50146 6.02053 2.39296 6.02053 3.4956V4.99707H6.0088ZM2.5132 6.49854V12.9971C2.5132 13.8299 3.18182 14.4985 4.01466 14.4985H12.0147C12.8475 14.4985 13.5161 13.8299 13.5161 12.9971V6.49854H11.522V8.24633C11.522 8.65689 11.1935 8.99707 10.7713 8.99707C10.349 8.99707 10.0205 8.66862 10.0205 8.24633V6.49854H6.02053V8.24633C6.02053 8.65689 5.69208 8.99707 5.26979 8.99707C4.84751 8.99707 4.51906 8.66862 4.51906 8.24633V6.49854H2.5132Z"
      fill="#9C9C9C"
    />
  </svg>
);

// activities
// purchases
const ClientActivity = ({ activityList, activities, client, auth, purchases, associateRefObj, refetchReminders, refetchAutoMessages }) => {
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [formattedList, setFormattedList] = useState([]);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [clientActivity, setClientActivity] = useState([]);
  const { getValue, setValue } = useSearchParams();
  const activityFilter = getValue('activityFilter');
  const clientTab = getValue('clientTab');
  const [openAssociateModal, setOpenAssociateModal] = useState(false);
  const [newAssociate, setNewAssociate] = useState(null);
  const [notes, setNotes] = useState(null);
  const [openNotesModal, setOpenNotesModal] = useState(false);

  function parseDate(dateString) {
    if(dateString.length === 8) {
      return dateString;
    }
    return format(
      parse(
        `${String(dateString).padEnd(14, '0')}+00`,
        'yyyyMMddHHmmssx',
        new Date()
    ), 'yyyyMMddHHmmss');
  }

  useEffect(() => {
    /* ERROR HERE !!! */
    const activitiesFiltered = activities?.map(act => ({
      id: act.id,
      activityType: 'activity',
      title: act.type,
      subTitle: act.created_by_name,
      notes: act.notes,
      description: act.store_name,
      created_date: parseDate(act.created_date),
    }));
    const purchasesFiltered = purchases?.map(pur => ({
      id: pur.id,
      activityType: 'purchased',
      title: pur.productName,
      subTitle: pur.unit_price,
      description: pur?.associate_ref,
      created_date: pur.transaction_date_time,
      product: {
        name: pur.name,
        image_url: pur.image_url,
        sku: pur.sku,
        pos_ref_num: pur?.pos_ref_num || pur?.product_pos_ref_num,
      },
    }));
    const orderActivities = activities.sort((a, b) => {
      return (
        activities.filter(act => act.type === b.type).length - activities.filter(act => act.type === a.type).length
      );
    });
    const newActivity = orderActivities.reduce((acc, curr) => {
      if (acc.some(a => a.type === curr.type)) {
        return acc;
      }
      return [...acc, curr];
    }, []);
    setClientActivity(newActivity);
    setList([...activitiesFiltered, ...purchasesFiltered]);
    setFilteredList([...activitiesFiltered, ...purchasesFiltered]);
  }, [activities, purchases]);

  useEffect(() => {
    const datesObj = {};
    filteredList?.forEach(activity => {
      const date = +`${activity.created_date}`.slice(0, 8);
      if (!Array.isArray(datesObj[date])) {
        datesObj[date] = [];
      }
      datesObj[date].push(activity);
    });
    const dates = Object.entries(datesObj).map(([key, value]) => {
      return { date: moment(key, 'YYYYMMDD').toDate(), activities: value };
    });
    dates.forEach(activity => {
      activity.activities.sort((a, b) => {
        return b.created_date - a.created_date;
      });
    });
    dates.sort((a, b) => {
      return b.date - a.date;
    });
    setFormattedList(dates);
  }, [filteredList]);

  const selectItem = useCallback(
    value => {
      const selectedFilter = activityFilter.split(',');
      if (selectedFilter.includes(value.toString())) {
        const selectedItems = selectedFilter.filter(item => item !== value.toString());
        setValue('activityFilter', selectedItems);
      } else {
        const selectedItems = [...selectedFilter, value.toString()];
        setValue('activityFilter', selectedItems);
      }
    },
    [activities, activityFilter],
  );

  const saveActivityHandler = useCallback(
    (activity, notes, associateId, associateName) => {
      const scoredActivity = activity.scored || 0;
      const { client_id, store_id, store_name, preferred_contact_type } = client;
      const noteCheck = notes?.length > 0 ? notes : null;
      const bodyData = {
        client_id,
        activityName: activity.description,
        associateId,
        associateName: associateName || `${auth?.firstName || ''} ${auth?.lastName || ''}`.trim(),
        store_id,
        store_name,
        notes: noteCheck,
        merchantId: auth?.merchantId,
        activityID: activity.id,
        preferred_contact_type,
        scoredActivity,
      };
      const reqObj = {
        params: ['clients', 'saveClientActivity'],
        data: bodyData,
      };
      (async () => {
        const respData = await postRequest(reqObj);
        if (respData) {
          notificationSuccess('Activity Saved!');
          addRecentActivity({
            client_id,
            created_by_id: associateId,
            created_by_name: associateName,
            created_date: parseInt(moment(new Date()).utc().format('YYYYMMDDHHmmss')),
            id: Math.floor(Math.random() * 1000000000),
            notes: noteCheck,
            store_id,
            store_name,
            type: activity.description,
          });
          refetchReminders();
          refetchAutoMessages();
        } else {
          notificationError('Failed To Save Activity');
        }
      })();
      setOpenAddNew(false);
    },
    [activities, client, auth, postRequest],
  );

  const handleClose = useCallback(() => {
    setOpenAddNew(false);
    setOpenNotesModal(false);
    setSelectedActivity(null);
    setNotes(null);
    setOpenAssociateModal(false);
    setNewAssociate(null);
  }, [])

  const handleActivityClick = useCallback(
    act => {
      if (act.inc_notes !== 1) {
        if(act.remindersCount > 0 && (client.associate_id && client.associate_id !== auth.userId)) {
          setOpenAddNew(false);
          setOpenAssociateModal(true);
          setSelectedActivity(act);
          setNotes(null);
        } else {
          saveActivityHandler(act, null, auth.userId, `${auth?.firstName || ''} ${auth?.lastName || ''}`);
          handleClose();
        }
      } else {
        setOpenAddNew(false);
        setSelectedActivity(act);
        setOpenNotesModal(true);
      }
    },
    [saveActivityHandler, client, auth],
  );

  const items = useMemo(() => {
    return activityList.map(act => ({
      key: act.id,
      label:
        act.description.length > 20 ? (
          <Tooltip
            title={act.description}
            overlayInnerStyle={{ borderRadius: '10px', padding: '8px 12px 0px 12px', backgroundColor: '#454545' }}
          >
            {truncateText(act.description, 20, { suffix: '...' })}
          </Tooltip>
        ) : (
          act.description
        ),
      onClick: () => handleActivityClick(act),
    }));
  }, [activityList]);

  const itemsFilter = useMemo(() => {
    const newItems = clientActivity.map(act => ({
      key: act.id,
      disabled: false,
      label: (
        <CheckboxDesc
          key={act.id}
          checked={!!activityFilter.split(',').find(i => i.toString() === act.type.toString())}
          onclick={() => selectItem(act.type)}
          text={<div className="checkboxItem">{act.type}</div>}
          classProps="w-100-P"
          checkButtonProps="m-top-4 m-btm-4 w-100-P"
        />
      ),
    }));

    return [
      ...newItems,
      {
        key: 'purchase',
        disabled: false,
        label: (
          <CheckboxDesc
            key="purchase"
            checked={!!activityFilter.split(',').find(i => i.toString() === 'purchased')}
            onclick={() => selectItem('purchased')}
            text={<div className="checkboxItem">Purchase history</div>}
            classProps="w-100-P"
            checkButtonProps="m-top-4 m-btm-4 w-100-P"
          />
        ),
      },
    ];
  }, [clientActivity, activityFilter]);

  const handleApplyFilter = useCallback(
    ({ clear = false }) => {
      const selectedFilter = activityFilter.split(',');
      setOpenFilter(false);
      let filtered = [];
      if (selectedFilter.length === 0 || clear || activityFilter === '') {
        filtered = list;
        setValue('activityFilter', null);
      } else {
        filtered = list.filter(act => {
          return selectedFilter.includes(act.activityType) || selectedFilter.includes(act.title);
        });
      }
      setFilteredList(filtered);
    },
    [activityFilter, list],
  );

  const handleClearAll = useCallback(() => {
    setValue('activityFilter', null);
    handleApplyFilter({ clear: true });
  }, [handleApplyFilter]);

  useEffect(() => {
    if (activityFilter && list?.length > 0) {
      handleApplyFilter({ clear: false });
    } else {
      handleApplyFilter({ clear: true });
    }
  }, [list, clientTab]);

  const handleSaveByNotesModal = useCallback((notes) =>  {
    if(selectedActivity.remindersCount > 0 && (client.associate_id && client.associate_id !== auth.userId)) {
      setOpenAssociateModal(true);
      setNotes(notes);
    } else {
      saveActivityHandler(selectedActivity, notes, auth.userId, `${auth?.firstName || ''} ${auth?.lastName || ''}`);
      handleClose();
    }
  }, [saveActivityHandler, selectedActivity, auth, client]);

  const handleSave = useCallback(() => {
    saveActivityHandler(selectedActivity, notes, newAssociate?.id, newAssociate?.name);
    handleClose();
  }, [
    saveActivityHandler,
    selectedActivity,
    notes,
    newAssociate,
  ]);

  const handleNewAssociate = useCallback((value) => {
    if(value === auth.userId) {
      setNewAssociate({ id: auth.userId, name: `${auth?.firstName || ''} ${auth?.lastName || ''}` });
    } else {
      setNewAssociate({ id: client.associate_id, name: `${client?.associate?.first_name || ''} ${client?.associate?.last_name || ''}` });
    }
  }, [auth, client]);

  useEffect(() => {
    setNewAssociate({ id: client.associate_id, name: `${client?.associate?.first_name || ''} ${client?.associate?.last_name || ''}` })
  }, [client.associate_id])

  return (
    <div
      className="fs-15 fw-500 m-btm-15 m-top-35 flex-col-left p-left-21 p-right-33"
      style={{ alignItems: 'stretch', overflow: 'hidden' }}
    >
      <div className="flex-row-spacebetween-nowrap m-btm-8" style={{ alignItems: 'center', width: '100%' }}>
        <div className="flex-row-center">
          <p>Client Activity</p>
          <Dropdown
            menu={{ items }}
            placement="bottomRight"
            trigger={['click']}
            onOpenChange={e => setOpenAddNew(e)}
            open={openAddNew}
            arrow
            align={{
              offset: [16, 0],
            }}
          >
            <FontAwesomeIcon icon="fa-regular fa-plus" className="m-left-13 pointer" />
          </Dropdown>
        </div>
        <div className="flex-row-center">
          <Dropdown
            menu={{ items: itemsFilter }}
            placement="bottomRight"
            trigger={['click']}
            open={openFilter}
            onOpenChange={e => setOpenFilter(e)}
            dropdownRender={menu => (
              <div className="ant-dropdown-menu cd-dropdown-menu-filter scrollbar-y">
                <div className="cd-dropdown-menu-filter-header">
                  <p>Filter by</p>
                  {!!activityFilter && activityFilter.split(',')?.length > 0 && (
                    <span className="pointer" onClick={handleClearAll}>
                      Clear All
                    </span>
                  )}
                </div>
                {React.cloneElement(menu, { style: { boxShadow: 'none', border: 0 } })}
                <button
                  style={{ width: '153px', height: '42px' }}
                  className="save-button-v2 m-btm-8 m-top-8"
                  onClick={handleApplyFilter}
                >
                  APPLY FILTER
                </button>
              </div>
            )}
            arrow
            align={{
              offset: [16, 0],
            }}
          >
            {activityFilter?.length === 0 ? (
              <FontAwesomeIcon icon="fa-regular fa-filter" className="pointer" />
            ) : (
              <img
                src="https://dashboard-v2-images.s3.amazonaws.com/FilterActive.svg"
                alt="non-default filter icon"
                className="pointer"
                style={{ height: '16px', width: '16px' }}
              />
            )}
          </Dropdown>
        </div>
      </div>
      {filteredList?.length > 0 ? (
        <div className="flex-col-left scrollbar-y" style={{ alignItems: 'stretch' }}>
          {formattedList.map(date =>
            date.activities.length > 0 ? (
              <div key={format(date.date, 'yyyyMMdd')}>
                <div className="fs-14 fw-500 align-left m-btm-15">
                  <FontAwesomeIcon icon="fa-light fa-calendar" className="m-right-8" />
                  {format(date.date, 'MMMM dd')}
                </div>
                {date.activities.map(activity => (
                  <ActivityItem
                    key={activity.id}
                    role={auth.role}
                    recentActivity={client.recentActivity}
                    client_id={client.client_id}
                    associateRefObj={associateRefObj}
                    {...activity}
                  />
                ))}
              </div>
            ) : null,
          )}
        </div>
      ) : (
        <div className="fw-400 fs-12 align-left m-top-4" style={{ color: '#818181' }}>
          No Client Activity
        </div>
      )}
      {openNotesModal && (
        <NewActivityModal
          activity={selectedActivity}
          onClose={handleClose}
          associateId={auth?.userId}
          associateName={`${auth?.firstName || ''} ${auth?.lastName || ''}`}
          merchantId={auth?.merchantId}
          activities={activities}
          onSaveActivity={handleSaveByNotesModal}
        />
      )}
      <Modal
        open={openAssociateModal}
        onCancel={() => {
          setOpenAssociateModal(false);
        }}
        centered
        title="There are reminders for this client activity"
        onOk={handleSave}
        okText="Save"
        closable={false}
      >
        <Form.Item
          className='m-top-16'
          label="Who should own the client activity?"
          layout='vertical'
        >
          <Radio.Group onChange={(e) => handleNewAssociate(e.target.value)} value={newAssociate?.id} defaultValue={client.associate_id}>
            <Radio value={client.associate_id} className='w-100-P'>
              {`${client?.associate?.first_name || ''} ${client?.associate?.last_name || ''} (Assigned associate)`}
            </Radio>
            <Radio value={auth.userId}>{`${auth?.firstName || ''} ${auth?.lastName || ''} (you)`}</Radio>
          </Radio.Group>
        </Form.Item>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({ auth: state.auth });

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ClientActivity);

const ActivityItem = props => {
  const {
    id,
    title,
    client_id,
    subTitle,
    description,
    associateRefObj,
    created_date,
    notes,
    activityType,
    recentActivity,
    product,
    role,
  } = props;
  const [openPurchaseDetails, setOpenPurchaseDetails] = useState(false);
  const [showEditModal, toggleEditModal] = useState(false);

  const togglePurchaseDetails = useCallback(() => {
    setOpenPurchaseDetails(!openPurchaseDetails);
  }, [openPurchaseDetails]);

  const handleSuccess = useCallback(item => {
    const newRecentActivity = recentActivity.map(i => {
      if (i.id === item.id) {
        return { ...i, notes: item.notes };
      }

      return i;
    });

    setRecentActivity(newRecentActivity);
    toggleEditModal(false);
  }, []);

  return (
    <>
      <div className="cb-activity-item-container m-right-4 m-left-4">
        <div
          className="cb-activity-strip"
          style={{ background: activityType === 'purchased' ? '#33AAFC' : '#9C9C9C' }}
        />
        <div className="flex-row-spacebetween-start">
          <div className="cb-activity-item-details">
            <p className="fs-16 fw-500 m-btm-8">
              {title}{' '}
              {role === 'ADMIN' || role === 'MANAGER' ? (
                <span className="cb-activity-item--edit-button" onClick={() => toggleEditModal(true)}>
                  Edit
                </span>
              ) : null}
            </p>
            {notes && <p className="fs-14 fw-400">{notes}</p>}
            <div>
              <p className="fs-15 fw-500 m-btm-8">
                {activityType === 'purchased' ? (
                  <>
                    <BagIcon className="m-right-8" />
                    {`$${subTitle}`}
                  </>
                ) : (
                  subTitle
                )}
              </p>
              <p className="fs-13 fw-400">{associateRefObj[description] || description}</p>
            </div>
          </div>
          <div className="cb-activity-item-date">
            <span>
              {moment(created_date, 'YYYYMMDDHHmmss').format('MM/DD/yyyy')}
            </span>
            {activityType === 'purchased' && (
              <FontAwesomeIcon
                icon={`fa-light ${openPurchaseDetails ? 'fa-chevron-down' : 'fa-chevron-right'}`}
                className="pointer"
                onClick={togglePurchaseDetails}
              />
            )}
          </div>
        </div>
        {activityType === 'purchased' && (
          <div className={`cb-activity-purchase-details ${openPurchaseDetails ? 'show-effect' : 'hide-effect hide'}`}>
            <Image
              src={product?.image_url || 'https://dashboard-v2-images.s3.amazonaws.com/not-found.png'}
              width={150}
              height={150}
              style={{ minWidth: '150px', borderRadius: '6px' }}
              fallback="https://dashboard-v2-images.s3.amazonaws.com/not-found.png"
            />
            <div className="flex-col-left">
              <p className="line-clamp-1">{product?.name}</p>
              <div className="cb-activity-purchase-details-grade">
                <div className="cb-activity-purchase-details-label">POS ID</div>
                <div className="cb-activity-purchase-details-value">{product?.pos_ref_num}</div>
                <div className="cb-activity-purchase-details-label">SKU</div>
                <div className="cb-activity-purchase-details-value">{product?.sku}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      {showEditModal ? (
        <EditActivityModal
          id={id}
          notes={notes}
          clientId={client_id}
          showEditModal={showEditModal}
          toggleEditModal={toggleEditModal}
          onSuccess={handleSuccess}
        />
      ) : null}
    </>
  );
};
