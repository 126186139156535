import React, { useState } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd-v5';
import Modal from '../../../../../CustomizedAntDesign/Modal/Modal';
import ClientCard from '../ClientCard';
import ProductDetailsModalFooter from '../ModalFooter';
import SearchClientsInputNew from '../../../../../../componentsV2/CustomizedAntDesign/SearchClientsInput';
import { postRequest } from '../../../../../../core/apiRequests';
import './styles.css';

const SendProductModal = ({ posType, productId, vendorId, visible, setVisible }) => {
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setVisible(false);
    setClient(null);
  };

  const handleGetClientName = () => {
    let clientName = client.name || client.preferred_name;

    if (!clientName && posType && client.pos_ref_num) {
      clientName = client.pos_ref_num;
    }

    return clientName || client.mobile;
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const requestData = {
        clientId: client.id,
        storeId: client.store_id,
      };

      if (vendorId) {
        requestData.vendorItemIds = [productId];
      } else {
        requestData.productIds = [productId];
      }

      const reqObj = {
        params: ['chats', 'sendChatMessage'],
        query: {},
        data: requestData,
        images: {
          urls: [],
          files: [],
        },
        videos: {
          urls: [],
          files: [],
          videoFrames: [],
        },
      };

      await postRequest(reqObj);

      message.success(`Product sent to ${handleGetClientName()}`);
    } catch (error) {
      message.error(error);
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  return (
    <Modal open={visible} footer={null} closable={false} className="cb-send-product-modal" destroyOnClose>
      <h2 className="cb-send-product-modal__title">Send Product</h2>

      {!client && <SearchClientsInputNew onSelect={setClient}/>}

      {client && <ClientCard client={client} />}

      <ProductDetailsModalFooter
        buttonLabel="Send"
        onClose={handleClose}
        onSave={handleSave}
        loading={loading}
        disabled={!client}
      />
    </Modal>
  );
};

const mapStateToProps = state => ({
  posType: state.auth.pos_type,
});

export default connect(mapStateToProps, {})(SendProductModal);
