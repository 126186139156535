import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import Modal from '../../../../../../../CustomizedAntDesign/Modal/Modal';
import Input from '../../../../../../../CustomizedAntDesign/Input/Input';
import Button from '../../../../../../../CustomizedAntDesign/Button';
import './styles.css';

const IntegrationsShopifyModal = ({ visible, setVisible, setPosType, merchantUUID }) => {
  const [requestError, setRequestError] = useState(false);

  const [form] = Form.useForm();

  const storeURL = Form.useWatch('storeURL', form);
  const [submittable, setSubmittable] = useState(false);
  const [skipCount, setSkipCount] = useState(true);

  const storeURLPlaceholder = useMemo(() => (requestError ? 'Invalid Store URL' : 'Store URL'), [requestError]);

  useEffect(() => {
    if (!skipCount && storeURL) {
      form
        .validateFields()
        .then(() => {
          setSubmittable(true);
        })
        .catch(() => {
          setSubmittable(false);
        });
    } else {
      setSkipCount(false);
    }
  }, [storeURL]);

  const handleInstallShopify = async () => {
    setRequestError(false);
    const scopes =
      'write_customers,read_customers,read_inventory,read_orders,read_product_listings,read_products,read_all_orders,read_locations';

    setPosType('SHOPIFY');
    setVisible(false);
    window.location.replace(
      `https://${storeURL}.myshopify.com/admin/oauth/authorize?client_id=${process.env.REACT_APP_SHOPIFY_CLIENT_ID}&scope=${scopes}&redirect_uri=${process.env.REACT_APP_CLIENTBOOK_API_URL}/api/v1/oauth2/shopify/callback&state=${merchantUUID}`,
    );
  };

  return (
    <Modal open={visible} footer={null} closable={false} width={500} destroyOnClose className="cb-integrations-modal">
      <h2 className="cb-integrations-modal__title">SHOPIFY API Credentials</h2>

      <h3 className="cb-integrations-modal__subtitle">Please enter the Store URL</h3>

      <Form
        colon={false}
        form={form}
        layout="vertical"
        autoComplete="off"
        className={requestError ? 'cb-integrations-modal__input-error' : ''}
      >
        <Form.Item label="Store URL">
          <Form.Item
            name="storeURL"
            noStyle
            rules={[
              { required: true, message: 'Store URL is required' },
              { pattern: /^(?!.*\.myshopify\.com).*$/gm, message: "Don't include .myshopify.com" },
            ]}
          >
            <Input placeholder={storeURLPlaceholder} style={{ width: '72%' }} />
          </Form.Item>
          <span className="ant-form-text" style={{ marginLeft: 8 }}>
            .myshopify.com
          </span>
        </Form.Item>

        <span className="cb-integrations-modal__error">{requestError && 'Check your information and try again'}</span>

        <Form.Item className="cb-integrations-details__form-footer">
          <Button onClick={() => setVisible(false)} block>
            Cancel
          </Button>

          <Button type="primary" disabled={!storeURL || !submittable} onClick={handleInstallShopify} block>
            Continue
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  setPosType(val) {
    dispatch({
      type: 'SET_POS_TYPE',
      payload: val,
    });
  },
  setStores(val) {
    dispatch({
      type: 'SET_STORES',
      payload: val,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsShopifyModal);
