import { Form } from 'antd-v5'
import React from 'react'

export const FormItemVertical = ({ label, children, styles = {}, ...rest }) => {

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        ...styles
      }}
    >
      {label &&
        <Form.Item labelCol={12} label={label} style={{ marginBottom: 0, fontWeight: 500}} {...rest} />
      }
      {children}
    </div>
  )
}
