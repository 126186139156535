import React from 'react';
import PhoneInputAnt from "antd-phone-input";
import './styles.scss';

function PhoneInput({...rest}) {

  return (
    <PhoneInputAnt
      className='ant5-phone-input'
      {...rest}
    />
  )
}

export default PhoneInput;
